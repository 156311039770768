import React, {useCallback} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {
    InteractiveElement,
    SvgImage,
    Text,
    ThemeConsumer,
} from '@pexip/components';
import type {ColorScheme} from '@pexip/components';

import {VideoLayoutOptionValues} from '../../types';

import adaptiveComposition from './svgs/AdaptiveComposition.svg';
import highlight from './svgs/Highlight.svg';
import speakerFocus from './svgs/SpeakerFocus.svg';
import singleTheatre from './svgs/SingleTheatre.svg';
import doubleTheatre from './svgs/DoubleTheatre.svg';
import tiled from './svgs/Tiled.svg';

import styles from './LayoutThumbnail.module.scss';

/*
t('settings.layout[adaptive-composition]', 'Adaptive Composition')
t('settings.layout[highlight]', 'Highlight')
t('settings.layout[single-theatre]', 'Theatre')
t('settings.layout[double-theatre]', 'Theatre')
t('settings.layout[tiled]', 'Tiled')
t('settings.layout[speaker-focus]', 'Speaker Focus')
*/

type LayoutDetails = {
    source: React.ElementType;
    key: string;
};

function getLayoutDetails(layout: VideoLayoutOptionValues): LayoutDetails {
    switch (layout) {
        case VideoLayoutOptionValues.AdaptiveComposition:
            return {
                source: adaptiveComposition,
                key: 'adaptive-composition',
            };
        case VideoLayoutOptionValues.Highlight:
            return {source: highlight, key: 'highlight'};
        case VideoLayoutOptionValues.SingleTheatre:
            return {source: singleTheatre, key: 'single-theatre'};
        case VideoLayoutOptionValues.DoubleTheatre:
            return {source: doubleTheatre, key: 'double-theatre'};
        case VideoLayoutOptionValues.Tiled:
            return {source: tiled, key: 'tiled'};
        case VideoLayoutOptionValues.SpeakerFocus:
        default:
            return {source: speakerFocus, key: 'speaker-focus'};
    }
}

export const LayoutThumbnail: React.FC<
    React.ComponentProps<'svg'> & {
        active?: boolean;
        colorScheme?: ColorScheme;
        layout: VideoLayoutOptionValues;
        selectLayout: (layout: VideoLayoutOptionValues) => void;
    }
> = ({active, className, colorScheme, layout, selectLayout, ...props}) => {
    const {t} = useTranslation();
    const {source, key} = getLayoutDetails(layout);
    const selectThisLayout = useCallback(
        () => selectLayout(layout),
        [layout, selectLayout],
    );
    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <div className={cx(styles.container, className)}>
                    <InteractiveElement
                        onClick={selectThisLayout}
                        className="mb-2"
                    >
                        <SvgImage
                            source={source}
                            className={cx(
                                styles.thumbnail,
                                styles[colorScheme ?? defaultColorScheme],
                                {
                                    [styles.active]: !!active,
                                },
                            )}
                            {...props}
                        />
                    </InteractiveElement>
                    <Text
                        colorScheme={colorScheme ?? defaultColorScheme}
                        htmlTag="span"
                    >
                        {t(`settings.layout[${key}]`)}
                    </Text>
                </div>
            )}
        </ThemeConsumer>
    );
};
