import {useEffect} from 'react';

import {createSignal} from '@pexip/signal';

export const keydownSignal = createSignal<KeyboardEvent>({
    allowEmittingWithoutObserver: true,
    name: 'useHotKey:keydownEvent',
});

export const excludedElements = ['INPUT', 'TEXTAREA'];

const emitKeydownSignal = (event: KeyboardEvent) => keydownSignal.emit(event);

export interface DisallowedKeys {
    metaKey?: boolean;
    ctrlKey?: boolean;
}

const defaultDisallowedKeys: DisallowedKeys = {metaKey: true, ctrlKey: true};

export const useHotKey = (
    key: string,
    handler: () => void,
    disallowedKeys: DisallowedKeys = defaultDisallowedKeys,
) => {
    if (key.length !== 1) {
        throw new Error('Hotkey length is limited to 1 currently');
    }

    useEffect(() => {
        document.addEventListener('keydown', emitKeydownSignal);

        return () => document.removeEventListener('keydown', emitKeydownSignal);
    }, []);

    useEffect(
        () =>
            keydownSignal.add(event => {
                if (event.repeat) {
                    return;
                }

                if (
                    excludedElements.includes(
                        document.activeElement?.tagName || '',
                    )
                ) {
                    return;
                }

                let k: keyof DisallowedKeys;
                for (k in disallowedKeys) {
                    if (disallowedKeys[k] && event[k]) {
                        return;
                    }
                }

                const capsLockIsOn = event.getModifierState('CapsLock');

                if (capsLockIsOn) {
                    const pressedKey = event.shiftKey
                        ? event.key.toUpperCase()
                        : event.key.toLowerCase();
                    if (key === pressedKey) {
                        return handler();
                    }
                } else if (key === event.key) {
                    return handler();
                }
            }),
        [key, handler, disallowedKeys],
    );
};
