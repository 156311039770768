import React from 'react';

import {findCurrentAudioOutputId} from '@pexip/media-control';
import {useToggleFullscreen} from '@pexip/hooks';
import type {DraggableMeetingVideoProps} from '@pexip/media-components';
import {DraggableMeetingVideo} from '@pexip/media-components';

import {useConfig} from '../config';
import {useDevices} from '../services/Media.service';

export const InMeetingMainStream: React.FC<DraggableMeetingVideoProps> = ({
    ...props
}) => {
    const [selectedAudioOutput] = useConfig('audioOutput');
    const devices = useDevices();

    const selectedAudioOutputDeviceId = findCurrentAudioOutputId(
        selectedAudioOutput,
        devices,
    );

    const toggleFullScreen = useToggleFullscreen();

    return (
        <DraggableMeetingVideo
            {...props}
            onDoubleClick={toggleFullScreen}
            selectedAudioOutputDeviceId={selectedAudioOutputDeviceId}
        />
    );
};
