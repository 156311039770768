import React from 'react';
import cx from 'classnames';

import {Box} from '@pexip/components';

import styles from './MicrophoneStepTip.module.scss';

export const MicrophoneStepTip: React.FC<{
    children?: React.ReactNode;
    className?: string;
}> = ({className, children}) => (
    <Box className={cx('p-3', styles.microphoneStepTip, className)}>
        {children}
    </Box>
);

export type MicrophoneStepTipProps = React.ComponentProps<
    typeof MicrophoneStepTip
>;
