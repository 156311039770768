import {captureException, withScope} from '@sentry/hub';

import type {BasePeerConnection} from '@pexip/peer-connection';
import {createMainPeerConnection} from '@pexip/peer-connection';
import type {AudioQualityStats} from '@pexip/peer-connection-stats';
import {createStatsCollector} from '@pexip/peer-connection-stats';
import {isEmpty} from '@pexip/utils';

import {
    audioInboundStatsSignals,
    audioOutboundStatsSignals,
    callLivenessSignals,
    combinedCallQualitySignal,
    combinedCallQualityStatsSignal,
    combinedRtcStatsSignal,
    pcMainSignals,
    videoInboundStatsSignals,
    videoOutboundStatsSignals,
} from './signals';
import {logger} from './logger';
import type {
    Call,
    CallOptions,
    Client,
    DataChannelEvent,
    IceCandidate,
    PresoState,
    StatsCollectors,
    Turn,
} from './types';
import {getDirection, toIceCandidate} from './utils';
import {eventSignals} from './eventSource';

export const createCall = (options: CallOptions): Call => {
    const presoState = new Proxy(
        {
            send: 'new',
            recv: 'new',
        } as PresoState,
        {
            set: (target, p: keyof PresoState, value) => {
                const didUpdate = Reflect.set(target, p, value);
                if (didUpdate) {
                    options.callSignals.onPresentationConnectionChange.emit(
                        target,
                    );
                }
                return didUpdate;
            },
        },
    );
    const pc = createPCCall(options);

    const disconnect = () => {
        pc.cleanup();
        pc.close();

        presoState.recv = 'disconnected';
        presoState.send = 'disconnected';
    };

    const setStream = (stream: MediaStream) => {
        pcMainSignals.onOfferRequired.emit(stream);
    };

    const setBandwidth = (bandwidth: number) => {
        pc.peer.bandwidth = bandwidth;
    };

    const present = async (stream?: MediaStream) => {
        if (!stream) {
            return;
        }

        try {
            presoState.send = 'connecting';
            presoState.recv = 'disconnected';

            await options.takeFloor({});
            await pc.peer.setLocalStream(stream, 'preso');

            presoState.send = 'connected';
        } catch {
            presoState.send = 'failed';
        }
    };

    const stopPresenting = () => {
        void options.releaseFloor({});
        presoState.send = 'disconnected';
    };

    const receivePresentation = async () => {
        if (['connecting', 'connected'].includes(presoState.recv)) {
            return;
        }

        presoState.recv = 'connecting';

        if (presoState.send === 'connected') {
            await options.releaseFloor({});
            presoState.send = 'disconnected';
        }

        const presoTransceiver = pc.peer.getTransceiver('video', 'preso');
        if (presoTransceiver) {
            options.callSignals.onRemotePresentationStream.emit(
                new MediaStream([presoTransceiver.receiver.track]),
            );
            presoState.recv = 'connected';
        } else {
            presoState.recv = 'failed';
        }
    };

    const stopReceivingPresentation = () => {
        if (
            presoState.recv === 'connected' ||
            presoState.recv === 'connecting'
        ) {
            presoState.recv = 'disconnected';
        }
    };

    const sendDataChannelEvent = (event: DataChannelEvent) => {
        if (!pc.dataChannel || pc.dataChannel.readyState !== 'open') {
            logger.warn(
                {eventType: event.type},
                'DataChannel not open. Cannot send event.',
            );
            return;
        }
        pc.dataChannel.send(JSON.stringify(event));
    };

    return {
        get presoState() {
            return presoState;
        },
        disconnect,
        present,
        receivePresentation,
        setBandwidth,
        setStream,
        stopPresenting,
        stopReceivingPresentation,
        sendDataChannelEvent,
    };
};

const createPCCall = ({
    ack,
    sendOffer,
    update,
    newCandidate,
    getCurrentCallUuid,
    peerOptions,
    signals,
    mediaStream,
    callSignals,
    dataChannelId,
}: CallOptions) => {
    let callEstablished = false;
    let sdpExchanged = false;
    let turn443 = false;
    let apiOneTimeCallbacks: Partial<Record<keyof Client, Array<() => void>>> =
        {};
    let videoPacketsReceived = 0;
    let incomingCandidates: RTCIceCandidate[] = [];
    let outgoingCandidates: IceCandidate[] = [];
    let dataChannel: RTCDataChannel | undefined;

    const statsCollectors: StatsCollectors = {inbound: {}, outbound: {}};
    const createPC = (rtcConfig = peerOptions.rtcConfig) => {
        const peer = createMainPeerConnection(pcMainSignals, {
            ...peerOptions,
            rtcConfig,
            transceiversDirection: {
                main: {
                    // FIXME: does this mean we loose lazy escalation if for example
                    // joins blocked and then switches to device that is working during a call?
                    // We might need to change the direction in `setStream`
                    audio: getDirection(mediaStream?.getAudioTracks()),
                    video: getDirection(mediaStream?.getVideoTracks()),
                },
                preso: {
                    video: 'sendrecv',
                },
            },
        });
        if (dataChannelId) {
            try {
                dataChannel = peer.createDataChannel('pexChannel', {
                    negotiated: true,
                    id: dataChannelId,
                });
                dataChannel.onmessage = event => {
                    try {
                        const msg = JSON.parse(event.data) as DataChannelEvent;
                        if (msg.type === 'message') {
                            eventSignals.onMessage.emit(msg.body);
                        }
                    } catch (error) {
                        logger.error(
                            {error, eventType: event.type},
                            'Failed to parse DataChannel event data from server.',
                        );
                        withScope(scope => {
                            scope.setContext('DataChannel event', {
                                eventType: event.type,
                            });
                            captureException(error);
                        });
                    }
                };
            } catch (error) {
                logger.error({error}, 'Failed to create DataChannel');
                captureException(error);
            }
        }
        return peer;
    };

    let peer = createPC();

    pcMainSignals.onOfferRequired.emit(mediaStream);

    const addIceServers = (iceServers: RTCIceServer[]) => {
        const rtcConfig = peer.getConfiguration();
        return isEmpty(iceServers)
            ? rtcConfig
            : {
                  ...rtcConfig,
                  iceServers: [...(rtcConfig.iceServers ?? []), ...iceServers],
              };
    };

    const executeOneTimeApiCallback = (func: keyof Client) => {
        const callbacks = apiOneTimeCallbacks[func];
        if (callbacks) {
            callbacks.forEach(cb => cb());
            apiOneTimeCallbacks[func] = undefined;
        }
    };

    const addOnceApiCallback = (func: keyof Client, cb: () => void) => {
        const cbArray = apiOneTimeCallbacks[func];
        if (cbArray) {
            cbArray.push(cb);
        } else {
            apiOneTimeCallbacks[func] = [cb];
        }
    };

    const createStatsCollectors = () => {
        const audioReceiver = peer.getTransceiver('audio')?.receiver;
        if (audioReceiver) {
            statsCollectors.inbound.audio = createStatsCollector({
                input: audioReceiver,
                signals: audioInboundStatsSignals,
            });
        }
        const audioSender = peer.getTransceiver('audio')?.sender;
        if (audioSender) {
            statsCollectors.outbound.audio = createStatsCollector({
                input: audioSender,
                signals: audioOutboundStatsSignals,
            });
        }

        const videoReceiver = peer.getTransceiver('video')?.receiver;
        if (videoReceiver) {
            statsCollectors.inbound.video = createStatsCollector({
                input: videoReceiver,
                signals: videoInboundStatsSignals,
            });
        }
        const videoSender = peer.getTransceiver('video')?.sender;
        if (videoSender) {
            statsCollectors.outbound.video = createStatsCollector({
                input: videoSender,
                signals: videoOutboundStatsSignals,
            });
        }
    };

    const cleanupStats = () => {
        statsCollectors.inbound.audio?.cleanup();
        statsCollectors.outbound.audio?.cleanup();
        statsCollectors.inbound.video?.cleanup();
        statsCollectors.outbound.video?.cleanup();
    };

    const restartIce = (targetPeer: BasePeerConnection) => {
        if (!['new', 'closed'].includes(targetPeer.connectionState)) {
            targetPeer.restartIce();
        }
    };

    const handleAck = (turn?: Turn) => () => {
        sdpExchanged = true;

        incomingCandidates.forEach(pcMainSignals.onReceiveIceCandidate.emit);
        incomingCandidates = [];

        turn443 = false;
        if (!turn) {
            return;
        }

        const rtcConfig = addIceServers(turn);
        if (peer.setConfiguration) {
            peer.setConfiguration(rtcConfig);
            peer.restartIce();
        } else {
            peer.close();
            peer = createPC(rtcConfig);
            pcMainSignals.onOfferRequired.emit(mediaStream);
        }
    };

    const sendCandidate = (candidate: IceCandidate) =>
        void newCandidate({
            candidate,
        }).then(() => executeOneTimeApiCallback('newCandidate'));

    const bufferOutgoingCandidate = (candidate: IceCandidate) =>
        outgoingCandidates.push(candidate);

    let detachApiSignals = [
        signals.onAnswer.add(({sdp, turn}) => {
            const answer = new RTCSessionDescription({
                sdp,
                type: 'answer',
            });
            turn443 = Boolean(turn);

            outgoingCandidates.forEach(candidate => {
                void newCandidate({
                    candidate,
                }).then(() => executeOneTimeApiCallback('newCandidate'));
            });
            outgoingCandidates = [];

            pcMainSignals.onReceiveAnswer.emit(answer);

            void ack({}).then(handleAck(turn));
        }),
        signals.onNewOffer.add(sdp => {
            if (!sdp) {
                return;
            }
            const offer = new RTCSessionDescription({
                sdp,
                type: 'offer',
            });
            pcMainSignals.onReceiveOffer.emit(offer);
        }),
        signals.onUpdateSdp.add(sdp => {
            if (!sdp) {
                return;
            }
            const offer = new RTCSessionDescription({
                sdp,
                type: 'offer',
            });
            pcMainSignals.onReceiveOffer.emit(offer);
        }),
        signals.onIceCandidate.add(candidate => {
            if (sdpExchanged) {
                pcMainSignals.onReceiveIceCandidate.emit(candidate);
            } else {
                incomingCandidates.push(candidate);
            }
        }),
    ];

    let detachPCSignals = [
        pcMainSignals.onOffer.add(({sdp}) => {
            if (!sdp) {
                return;
            }

            if (sdpExchanged) {
                void update({sdp});
            } else {
                void sendOffer({sdp});
            }
        }),

        pcMainSignals.onAnswer.add(({sdp}) => {
            void ack({sdp}).then(handleAck());
        }),

        pcMainSignals.onRemoteStreams.add(streams => {
            logger.debug({streams}, 'Received streams');
            streams.forEach(stream => {
                if (peer.isRemoteMainStream(stream)) {
                    // FIXME: mcu bundles all 3 tracks together in ssrcs, we dont want that so we remove presentation
                    // ideally in sdp main tracks and preso track should be associated separately
                    // now they are treated as one thing. Hopefully we can remove this when backend gets updated.
                    const mainVideoTracks = stream
                        .getVideoTracks()
                        .filter(peer.isRemoteMainTrack());
                    const streamWithoutPreso = new MediaStream([
                        ...stream.getAudioTracks(),
                        ...mainVideoTracks,
                    ]);
                    logger.debug(
                        {
                            stream,
                            tracks: stream.getTracks(),
                            streamWithoutPreso,
                            tracksWithoutPreso: streamWithoutPreso.getTracks(),
                        },
                        'Emits remote stream',
                    );
                    callSignals.onRemoteStream.emit(streamWithoutPreso);
                }
            });
        }),

        pcMainSignals.onIceCandidate.add(event => {
            if (turn443) {
                // no need to send dead candidates as we will be doing a restart on TURN443 in answer
                return;
            }
            const candidate = toIceCandidate(event);
            if (getCurrentCallUuid()) {
                sendCandidate(candidate);
            } else {
                bufferOutgoingCandidate(candidate);
            }
        }),

        pcMainSignals.onConnectionStateChange.add(connectionState => {
            if (connectionState === 'connected') {
                callEstablished = true;
            } else if (connectionState === 'closed') {
                signals.onError.emit({
                    error: 'WebRTC connection closed',
                    errorCode: '#pex117',
                });
            }
            if (!callEstablished) {
                if (connectionState === 'connected') {
                    callSignals.onCallConnected.emit();
                }
                if (connectionState === 'failed') {
                    signals.onError.emit({
                        error: 'WebRTC connection failed',
                        errorCode: '#pex128',
                    });
                }
            }
        }),

        pcMainSignals.onIceConnectionStateChange.add(iceConnectionState => {
            if (iceConnectionState === 'failed') {
                signals.onError.emit({
                    error: 'Could not find ICE candidates',
                    errorCode: '#pex196',
                });
            }
        }),

        pcMainSignals.onError.add(reason => {
            logger.error(reason);
            captureException(reason);
        }),

        pcMainSignals.onTransceiverChange.add(() => {
            cleanupStats();
            createStatsCollectors();
        }),

        combinedRtcStatsSignal.add(([audioIn, audioOut, videoIn, videoOut]) => {
            callSignals.onRtcStats.emit({
                inbound: {audio: audioIn, video: videoIn},
                outbound: {audio: audioOut, video: videoOut},
            });
        }),

        combinedCallQualityStatsSignal.add(([audioIn, audioOut]) => {
            callSignals.onCallQualityStats.emit({
                inbound: {audio: audioIn as AudioQualityStats}, // FIXME: stats can be either AudioQualityStats | VideoQualityStats
                outbound: {audio: audioOut as AudioQualityStats},
            });
        }),

        combinedCallQualitySignal.add(callSignals.onCallQuality.emit),

        videoInboundStatsSignals.onRtcStats.add(stats => {
            if (stats) {
                const update = stats.packetsTransmitted;
                if (update < videoPacketsReceived) {
                    // likely a stale update from just recovered connection
                    return;
                }
                if (
                    videoPacketsReceived !== 0 &&
                    videoPacketsReceived === update
                ) {
                    logger.info(
                        'Triggering ICE restart due to packet stagnation',
                    );
                    const resumeStatsUpdates = [
                        statsCollectors.inbound.video?.resetStats(),
                        statsCollectors.outbound.video?.resetStats(),
                        statsCollectors.inbound.audio?.resetStats(),
                        statsCollectors.outbound.audio?.resetStats(),
                    ];

                    addOnceApiCallback('newCandidate', () => {
                        /**
                         * Stats could start flowing before new packets.
                         * Increment the received packets in order to avoid false negatives
                         */
                        videoPacketsReceived++;
                        resumeStatsUpdates.forEach(resume => resume?.());
                        callLivenessSignals.onReconnected.emit();
                    });
                    callLivenessSignals.onReconnecting.emit();
                    restartIce(peer);
                }
                videoPacketsReceived = update;
            }
        }),
    ];

    const cleanup = () => {
        detachApiSignals = detachApiSignals.flatMap(detach => {
            detach();
            return [];
        });
        detachPCSignals = detachPCSignals.flatMap(detach => {
            detach();
            return [];
        });
        cleanupStats();
        apiOneTimeCallbacks = {};
    };

    const close = () => {
        callEstablished = false;
        sdpExchanged = false;
        if (peer.connectionState === 'closed') {
            return;
        }
        peer.close();
    };

    return {
        get peer() {
            return peer;
        },
        get dataChannel() {
            return dataChannel;
        },
        cleanup,
        close,
    };
};
