import React, {useCallback} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Text,
    withTransition,
    FontVariant,
    ThemeProvider,
    useTouchDevice,
} from '@pexip/components';
import type {InMeetingParticipant, InMeetingUI} from '@pexip/media-components';
import {
    ParticipantsPanelFooter,
    ParticipantPanelWrapper,
    PanelHeaderWrapper,
    ParticipantSidePanelWrapper,
} from '@pexip/media-components';
import type {Participant as InfinityParticipant} from '@pexip/infinity';

import {ParticipantListWrapper} from '../views/ParticipantLIstWrapper/ParticipantListWrapper.view';
import {TestId} from '../../test/testIds';
import {useParticipants} from '../hooks/useParticipants';
import {useConferenceStatus} from '../hooks/useConferenceStatus';
import {infinityService} from '../services/InfinityClient.service';
import {
    transferParticipantSignal,
    removeParticipantSignal,
} from '../signals/Participant.signals';

import {Participant} from './Participant.viewModel';

const AnimatedParticipantPanel = withTransition(
    ParticipantSidePanelWrapper,
    300,
);

export const Participants: React.FC<{
    me?: InfinityParticipant;
    inMeetingUI: InMeetingUI;
    isNetworkAlertDisplayed?: boolean;
    openMeetingSettings: () => void;
}> = ({
    me,
    inMeetingUI: {panelsState},
    isNetworkAlertDisplayed = false,
    openMeetingSettings,
}) => {
    const {t} = useTranslation();
    const isTouchDevice = useTouchDevice();

    const {raisedHand, waitingInLobby, inMeeting, external} = useParticipants();
    const conferenceStatus = useConferenceStatus();

    const handleRemoveUserRequest = useCallback(
        (participant: InMeetingParticipant, displayName: string) => {
            removeParticipantSignal.emit({
                isOpen: true,
                userName: displayName,
                onConfirm: participant?.kick,
            });
        },
        [],
    );

    const handleTransfer = useCallback((identity: string) => {
        transferParticipantSignal.emit(identity);
    }, []);

    const toggleGuestsMute = useCallback(() => {
        void infinityService.muteAllGuests({
            mute: !conferenceStatus?.guestsMuted,
        });
    }, [conferenceStatus?.guestsMuted]);

    const getParticipant = (participant: InMeetingParticipant) => (
        <Participant
            key={participant.identity}
            participant={participant}
            currentUserId={me?.uuid}
            isHost={me?.isHost}
            canControl={me?.canControl}
            handleRemoveUserRequest={handleRemoveUserRequest}
            handleTransfer={handleTransfer}
        />
    );

    return (
        <ThemeProvider colorScheme="light">
            <AnimatedParticipantPanel
                panelsState={panelsState}
                isNetworkAlertDisplayed={isNetworkAlertDisplayed}
                isVisible={panelsState.openParticipantPanel}
            >
                <PanelHeaderWrapper>
                    <Text fontVariant={FontVariant.H5}>
                        <Trans t={t} i18nKey="meeting.participants">
                            Participants
                        </Trans>
                    </Text>
                </PanelHeaderWrapper>
                <ParticipantPanelWrapper>
                    {raisedHand.length > 0 && (
                        <ParticipantListWrapper
                            data-testid={TestId.ParticipantPanelHandRaised}
                            meta={raisedHand.length.toString()}
                            title={t('meeting.raised-hand', 'Raised a hand')}
                        >
                            {raisedHand.map(getParticipant)}
                        </ParticipantListWrapper>
                    )}
                    {waitingInLobby.length > 0 && (
                        <ParticipantListWrapper
                            data-testid={TestId.ParticipantPanelWaitingInLobby}
                            meta={waitingInLobby.length.toString()}
                            title={t(
                                'meeting.waiting-in-lobby',
                                'Waiting in lobby',
                            )}
                            canOnlyToggleWithTitle
                        >
                            {waitingInLobby.map(getParticipant)}
                        </ParticipantListWrapper>
                    )}
                    {inMeeting.length > 0 && (
                        <ParticipantListWrapper
                            data-testid={TestId.ParticipantPanelInMeeting}
                            meta={inMeeting.length.toString()}
                            title={t(
                                'meeting.in-this-meeting',
                                'In this meeting',
                            )}
                        >
                            {inMeeting.map(getParticipant)}
                        </ParticipantListWrapper>
                    )}
                    {external.length > 0 && (
                        <ParticipantListWrapper
                            data-testid={TestId.ParticipantPanelExternal}
                            meta={external.length.toString()}
                            title={t(
                                'meeting.external-participants',
                                'External participants',
                            )}
                        >
                            {external.map(getParticipant)}
                        </ParticipantListWrapper>
                    )}
                </ParticipantPanelWrapper>
                {me?.canControl && !isTouchDevice && (
                    <ParticipantsPanelFooter
                        guestsMuted={conferenceStatus?.guestsMuted}
                        toggleGuestsMute={toggleGuestsMute}
                        openMeetingSettings={openMeetingSettings}
                    />
                )}
            </AnimatedParticipantPanel>
        </ThemeProvider>
    );
};
