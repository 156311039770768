import React, {useCallback, useEffect, useRef, useState} from 'react';

import {useCountDown} from '@pexip/hooks';
import type {ColorScheme} from '@pexip/components';
import {TestYourMicStages} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {WAIT_TIME_BEFORE_PLAYBACK} from '../constants';

import {TestYourMicrophoneModal} from './TestYourMicrophoneModal.viewModel';

const startInterval = (
    callback: (value: React.SetStateAction<number>) => void,
) => {
    const intervalId = window.setInterval(() => {
        callback(count => count + 1);
    }, 1000);

    return intervalId;
};

export const TestYourMicrophoneModalSteps: React.FC<{
    devices: MediaDeviceInfoLike[];
    closeOnOutsideClick?: boolean;
    iconColorScheme?: ColorScheme;
    isModalOpen: boolean;
    onAudioInputChange: (device: MediaDeviceInfoLike) => void;
    onAudioOutputChange: (device: MediaDeviceInfoLike) => void;
    selectedAudioInput?: MediaDeviceInfoLike;
    selectedAudioOutput?: MediaDeviceInfoLike;
    onClose: () => void;
    testYourMicStages: TestYourMicStages;
    setTestYourMicStages: React.Dispatch<
        React.SetStateAction<TestYourMicStages>
    >;
}> = ({
    devices,
    isModalOpen,
    closeOnOutsideClick,
    onAudioInputChange,
    onAudioOutputChange,
    selectedAudioInput,
    selectedAudioOutput,
    onClose,
    testYourMicStages,
    setTestYourMicStages,
}) => {
    const [currentRecordingCount, setCurrentRecordingCount] = useState(0);
    const [currentPlaybackCount, setCurrentPlaybackCount] = useState(0);
    const waitBeforePlaybackTimeout = useRef<number>();
    const playbackTimeout = useRef<number>();
    const recordingTimeout = useRef<number>();

    const startPlayback = useCallback(() => {
        setTestYourMicStages(TestYourMicStages.RecordingSuccess);
        setCurrentPlaybackCount(0);

        waitBeforePlaybackTimeout.current = window.setTimeout(() => {
            setTestYourMicStages(TestYourMicStages.PlayingBack);

            playbackTimeout.current = startInterval(setCurrentRecordingCount);
        }, WAIT_TIME_BEFORE_PLAYBACK);
    }, [setTestYourMicStages]);

    const {
        startCountDown: startRecordingCounter,
        cleanup: cleanupRecordingCounter,
    } = useCountDown({enabled: true}, startPlayback);

    const startRecordingMicrophone = useCallback(() => {
        setTestYourMicStages(TestYourMicStages.Recording);
        startRecordingCounter();
        recordingTimeout.current = startInterval(setCurrentPlaybackCount);
    }, [setTestYourMicStages, startRecordingCounter]);

    const {
        startCountDown: startGetReadyCountDown,
        cleanup: cleanupGetReadyCountDown,
    } = useCountDown(
        {enabled: true, startCountFrom: 3},
        startRecordingMicrophone,
    );

    const startTesting = useCallback(() => {
        clearInterval(playbackTimeout.current);
        clearInterval(recordingTimeout.current);
        setTestYourMicStages(TestYourMicStages.GetReady);
        startGetReadyCountDown();
    }, [setTestYourMicStages, startGetReadyCountDown]);

    const resetAll = useCallback(() => {
        clearInterval(playbackTimeout.current);
        clearInterval(recordingTimeout.current);
        clearTimeout(waitBeforePlaybackTimeout.current);
        setCurrentPlaybackCount(0);
        setCurrentRecordingCount(0);
        cleanupRecordingCounter();
        cleanupGetReadyCountDown();
    }, [cleanupGetReadyCountDown, cleanupRecordingCounter]);

    const tryAgain = useCallback(() => {
        resetAll();
        startTesting();
    }, [startTesting, resetAll]);

    const replay = () => {
        resetAll();
        startPlayback();
    };

    useEffect(() => {
        if (currentRecordingCount >= 5) {
            clearInterval(recordingTimeout.current);
        }

        if (currentPlaybackCount >= 5) {
            clearInterval(recordingTimeout.current);
        }
    }, [currentPlaybackCount, currentRecordingCount]);

    useEffect(
        () => () => {
            if (waitBeforePlaybackTimeout.current) {
                clearTimeout(waitBeforePlaybackTimeout.current);
            }

            if (playbackTimeout.current) {
                clearInterval(playbackTimeout.current);
            }

            if (recordingTimeout.current) {
                clearInterval(recordingTimeout.current);
            }
        },
        [],
    );

    return (
        <TestYourMicrophoneModal
            devices={devices}
            isModalOpen={isModalOpen}
            closeOnOutsideClick={closeOnOutsideClick}
            onAudioInputChange={onAudioInputChange}
            onAudioOutputChange={onAudioOutputChange}
            selectedAudioInput={selectedAudioInput}
            selectedAudioOutput={selectedAudioOutput}
            startTest={startTesting}
            tryAgain={tryAgain}
            replay={replay}
            testYourMicStages={testYourMicStages}
            onClose={onClose}
            setTestYourMicStages={setTestYourMicStages}
            currentPlaybackCount={currentPlaybackCount}
            currentRecordingCount={currentRecordingCount}
        />
    );
};
