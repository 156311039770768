import {getBrowserName} from '@pexip/media-components';

import type {BlockedBrowserPermissionsInfo, BrowserType} from '../types';

import {getBlockedBrowserPermissionsInfo} from './getBlockedBrowserPermissionsInfo';

const videoFiles: Record<BrowserType, {vp9: string; mp4: string}> = {
    android: {
        vp9: new URL(
            '../assets/blocked-permissions-gifs/blocked-android.webm',
            import.meta.url,
        ).toString(),
        mp4: new URL(
            '../assets/blocked-permissions-gifs/blocked-android.mp4',
            import.meta.url,
        ).toString(),
    },
    chrome: {
        vp9: new URL(
            '../assets/blocked-permissions-gifs/blocked-chrome.webm',
            import.meta.url,
        ).toString(),
        mp4: new URL(
            '../assets/blocked-permissions-gifs/blocked-chrome.mp4',
            import.meta.url,
        ).toString(),
    },
    edge: {
        vp9: new URL(
            '../assets/blocked-permissions-gifs/blocked-edge.webm',
            import.meta.url,
        ).toString(),
        mp4: new URL(
            '../assets/blocked-permissions-gifs/blocked-edge.mp4',
            import.meta.url,
        ).toString(),
    },
    firefox: {
        vp9: new URL(
            '../assets/blocked-permissions-gifs/blocked-firefox.webm',
            import.meta.url,
        ).toString(),
        mp4: new URL(
            '../assets/blocked-permissions-gifs/blocked-firefox.mp4',
            import.meta.url,
        ).toString(),
    },
    'safari-ios': {
        vp9: new URL(
            '../assets/blocked-permissions-gifs/blocked-safari-ios.webm',
            import.meta.url,
        ).toString(),
        mp4: new URL(
            '../assets/blocked-permissions-gifs/blocked-safari-ios.mp4',
            import.meta.url,
        ).toString(),
    },
    'safari-ipados': {
        vp9: new URL(
            '../assets/blocked-permissions-gifs/blocked-safari-ipados.webm',
            import.meta.url,
        ).toString(),
        mp4: new URL(
            '../assets/blocked-permissions-gifs/blocked-safari-ipados.mp4',
            import.meta.url,
        ).toString(),
    },
    'safari-macosx': {
        vp9: new URL(
            '../assets/blocked-permissions-gifs/blocked-safari-macosx.webm',
            import.meta.url,
        ).toString(),
        mp4: new URL(
            '../assets/blocked-permissions-gifs/blocked-safari-macosx.mp4',
            import.meta.url,
        ).toString(),
    },
};

export const buildUrl = (browser: BrowserType): {vp9: string; mp4: string} => {
    return videoFiles[browser];
};

export const browserSpecificHelpers = (
    userAgent: string,
): BlockedBrowserPermissionsInfo => {
    return getBlockedBrowserPermissionsInfo(
        getBrowserName(userAgent),
        buildUrl,
    );
};
