import React, {useEffect, useRef, useState} from 'react';

import type {NetworkState, usePresentation} from '@pexip/media-components';
import {
    togglePanel,
    createInMeetingUIHook,
    StealPresentationModal,
} from '@pexip/media-components';
import {useAutoHideUIInterfaces, useFullscreenApi} from '@pexip/hooks';
import type {Layout, SplashScreen} from '@pexip/infinity';

import {useSettings} from '../hooks/useSettings';
import {useLiveCaptions} from '../hooks/useLiveCaptions';
import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {useMe} from '../hooks/useMe';
import {infinityService} from '../services/InfinityClient.service';
import {enableInMeetingUIAutoHide} from '../signals/InMeeting.signals';

import {Participants} from './Participants.viewModel';
import {Chat} from './Chat.viewModel';
import {MeetingFooter} from './MeetingFooter.viewModel';
import {MeetingStage} from './MeetingStage.viewModel';
import {InMeetingLocalStream} from './InMeetingLocalStream.viewModel';
import {InMeetingSettings} from './InMeetingSettings.viewModel';
import {AddParticipant} from './AddParticipant.viewModel';
import {TransferParticipant} from './TransferParticipant.viewModel';
import {MeetingHeader} from './MeetingHeader.viewModel';

const useInMeetingUI = createInMeetingUIHook({
    enableInMeetingUIAutoHide,
    togglePanel,
    useFullscreenApi,
    useAutoHideUIInterfaces,
});

export const MeetingRoom: React.FC<{
    disconnectAll: () => void;
    leave: () => void;
    lock: (lock: boolean) => void;
    presentation: ReturnType<typeof usePresentation>;
    raiseHand: (raise: boolean) => void;
    setLayout: (layout: Layout) => void;
    networkState: NetworkState;
    splashScreen?: SplashScreen;
    stream?: MediaStream;
}> = ({
    disconnectAll,
    leave,
    lock,
    presentation,
    raiseHand,
    setLayout,
    networkState,
    splashScreen,
    stream,
}) => {
    const {
        openModal,
        openEffectsModal,
        openMeetingSettings,
        closeModal,
        isOpen,
        activeTab,
    } = useSettings();

    const [showSelfviewTooltip, setShowSelfviewTooltip] = useState(false);
    const me = useMe();

    const stageRef = useRef<HTMLDivElement>(null);

    const captions = useLiveCaptions();
    const inMeetingUI = useInMeetingUI();

    const [currentHostLayout, setCurrentHostLayout] = useState<Layout>();

    useEffect(
        () =>
            infinityClientSignals.onRequestedLayout.add(layout =>
                setCurrentHostLayout(layout.primaryScreen.hostLayout),
            ),
        [],
    );

    return (
        <>
            <StealPresentationModal
                isOpen={presentation.isShowSteal}
                presenterName={presentation.presenterName}
                onConfirm={() => {
                    void presentation.startPresentation();
                    presentation.setShowSteal(false);
                }}
                onCancel={() => presentation.setShowSteal(false)}
            />
            <MeetingHeader
                inMeetingUI={inMeetingUI}
                openSettings={openModal}
                presentation={presentation}
            />
            <MeetingStage
                stream={stream}
                presentation={presentation}
                inMeetingUI={inMeetingUI}
                captions={captions}
                ref={stageRef}
                splashScreen={splashScreen}
            />
            <InMeetingLocalStream
                inMeetingUI={inMeetingUI}
                meetingStageRef={stageRef}
                showSelfviewTooltip={showSelfviewTooltip}
                networkState={networkState}
                setShowSelfviewTooltip={setShowSelfviewTooltip}
                openSettings={openModal}
            />
            <Participants
                me={me}
                inMeetingUI={inMeetingUI}
                openMeetingSettings={openMeetingSettings}
            />
            {infinityService.conferenceFeatureFlags?.chatEnabled && me && (
                <Chat me={me} inMeetingUI={inMeetingUI} />
            )}
            <AddParticipant inMeetingUI={inMeetingUI} />
            <TransferParticipant />
            <MeetingFooter
                me={me}
                inMeetingUI={inMeetingUI}
                leave={leave}
                openEffectsModal={openEffectsModal}
                presentation={presentation}
                raiseHand={raiseHand}
                captions={captions}
            />
            <InMeetingSettings
                activeTab={activeTab}
                closeModal={closeModal}
                disconnectAll={disconnectAll}
                isOpen={isOpen}
                lock={lock}
                currentHostLayout={currentHostLayout}
                setLayout={setLayout}
            />
        </>
    );
};
