import React from 'react';

import {CoreHeader, Group} from '@pexip/components';

export const HeaderView: React.FC<
    React.ComponentProps<typeof CoreHeader> & {
        userMenu: React.ReactElement;
    }
> = ({userMenu, ...props}) => (
    <CoreHeader
        enhancerFixedWidth
        enhancerEnd={
            <Group spacing="small" className="mr-4">
                {userMenu}
            </Group>
        }
        hasBottomBorder={false}
        {...props}
    />
);

export type HeaderViewProps = React.ComponentProps<typeof HeaderView>;
