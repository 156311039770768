import React, {useCallback, useEffect, useRef, useState} from 'react';

import {
    CenterLayout,
    Spinner,
    notificationToastSignal,
} from '@pexip/components';
import type {RouteMatch} from '@pexip/router';
import {isDefined} from '@pexip/utils';
import type {
    DisconnectReason,
    ExtendedInfinityErrorMessage,
    Layout,
    SplashScreen,
} from '@pexip/infinity';
import {callLivenessSignals} from '@pexip/infinity';
import {
    browserVersion,
    isFirefox,
    useNetworkState,
    usePresentation,
} from '@pexip/media-components';

import {callSignals} from '../signals/Call.signals';
import {infinityService} from '../services/InfinityClient.service';
import {config, initialUrl, updateInitialUrl} from '../config';
import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {mediaService, setCurrentDisplayMedia} from '../services/Media.service';
import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import type {Idp} from '../types';
import {MeetingFlow} from '../types';
import {logger as log} from '../logger';
import {Header} from '../viewModels/Header.viewModel';
import {mediaSignals} from '../signals/Media.signals';
import {push} from '../router';
import {useOnFailedInfinityRequest} from '../hooks/useOnFailedInfinityRequest';
import {
    MeetingFlowCards,
    FlowCards,
} from '../views/MeetingFlowCards/MeetingFlowCards.view';
import {EXPRESS_PATH} from '../constants';

import {InMeeting} from './InMeeting.page';

const logger = log.child({name: 'Meeting page'});

export const Meeting: React.FC<{
    match: RouteMatch;
}> = ({
    match: {
        params: {id},
        url: {
            state: {token},
        },
    },
}) => {
    const popupRef = useRef<Window | null>(null);
    const pollingIdRef = useRef<number>();

    const [step, setStep] = useState(MeetingFlow.Loading);
    const [idps, setIdps] = useState<Idp[]>([]);
    const [splashScreen, setSplashScreen] = useState<SplashScreen>();

    const [error, setError] = useState<ExtendedInfinityErrorMessage>();

    const [stream, setStream] = useState<MediaStream>();

    const networkState = useNetworkState(
        callLivenessSignals.onReconnecting,
        callLivenessSignals.onReconnected,
    );

    const conferenceAlias = isDefined(id);

    const call = useCallback(
        ({
            pin,
            chosenIdp,
            ssoToken,
            token,
            conferenceExtension,
        }: {
            pin?: string;
            chosenIdp?: string;
            ssoToken?: string;
            token?: string;
            conferenceExtension?: string;
        } = {}) => {
            logger.debug('Call');
            setStep(MeetingFlow.Loading);
            void infinityService.call({
                bandwidth: Number(config.get('bandwidth')),
                chosenIdp,
                conferenceAlias,
                displayName: config.get('displayName'),
                mediaStream: mediaService.media.stream,
                node: config.get('node'),
                packetizationMode: isFirefox && Number(browserVersion) >= 97,
                pin,
                ssoToken,
                token,
                conferenceExtension,
            });
        },
        [conferenceAlias],
    );

    useOnFailedInfinityRequest(networkState);

    useEffect(() => {
        logger.debug('Mounts');
        // redirect to preflight if participant (re)loads in-meeting page
        if (window.location.href === initialUrl) {
            logger.debug('Redirect to preflight page');
            updateInitialUrl(`${initialUrl}${EXPRESS_PATH}`);
            push(`/m/${conferenceAlias}${EXPRESS_PATH}`);
            return;
        }

        logger.debug('Subscribe to signals');
        const detachSignals = [
            callSignals.onRemoteStream.add(stream => {
                // FIXME: #2511
                setStream(stream);
                setStep(MeetingFlow.InMeeting);
            }),
            infinityClientSignals.onPinRequired.add(
                ({hasHostPin, hasGuestPin}) => {
                    if (hasHostPin && hasGuestPin) {
                        setStep(MeetingFlow.EnterPin);
                    } else {
                        setStep(MeetingFlow.AreYouHost);
                    }
                },
            ),
            infinityClientSignals.onIdp.add(idps => {
                setIdps(idps);
                setStep(MeetingFlow.Idp);
            }),
            infinityClientSignals.onRedirect.add(
                ({redirectIdp, redirectUrl}) => {
                    if (popupRef.current) {
                        popupRef.current.location.href = redirectUrl;
                    } else {
                        setIdps([
                            {
                                redirectUrl,
                                ...redirectIdp,
                            },
                        ]);
                        setStep(MeetingFlow.Idp);
                    }
                },
            ),
            infinityClientSignals.onError.add(({error}) => {
                if (error === 'Invalid PIN') {
                    setError(error);
                    setStep(MeetingFlow.EnterPin);
                }
            }),
            infinityClientSignals.onTransfer.add(({alias, token}) => {
                setSplashScreen(undefined);
                push(`/m/${alias}`, {
                    state: {
                        token,
                    },
                });
            }),
            infinityClientSignals.onRaiseHand.add(
                ({displayName, raisedHand}) => {
                    if (raisedHand) {
                        notificationToastSignal.emit([
                            {
                                message: `${displayName} raised hand`,
                                timeout: 5000,
                            },
                        ]);
                    }
                },
            ),
            infinityClientSignals.onSplashScreen.add(splashScreen => {
                setSplashScreen(splashScreen);
                setStep(MeetingFlow.InMeeting);
            }),
            infinityClientSignals.onPeerDisconnect.add(() => {
                void infinityService.restartCall({
                    bandwidth: Number(config.get('bandwidth')),
                    mediaStream: mediaService.media.stream,
                    packetizationMode:
                        isFirefox && Number(browserVersion) >= 97,
                });
            }),
            infinityClientSignals.onExtension.add(_ => {
                setStep(MeetingFlow.EnterExtension);
            }),
        ];

        logger.debug('Initial call');
        call(typeof token === 'string' ? {token} : undefined);

        const disconnectWithReason = (reason: DisconnectReason) => () => {
            logger.debug('Disconnects', {reason});
            detachSignals.flatMap(detach => {
                detach();
                return [];
            });
            void infinityService?.disconnect({reason});
        };

        const userInitiatedDisconnect = disconnectWithReason(
            'User initiated disconnect',
        );
        const browserClosedDisconnect = disconnectWithReason('Browser closed');
        window.addEventListener('beforeunload', browserClosedDisconnect);

        return () => {
            userInitiatedDisconnect();
            window.removeEventListener('beforeunload', browserClosedDisconnect);
        };
    }, [call, conferenceAlias, token]);

    const presentation = usePresentation({
        presentationReceiveStreamSignal: callSignals.onRemotePresentationStream,
        presentationConnectionStateChangeSignal:
            callSignals.onPresentationConnectionChange,
        present,
        stopPresentation,
        presentationStreamCleanup,
    });

    useEffect(() => {
        const [audioTrack] =
            presentation.localMediaStream?.getAudioTracks() || [];
        if (audioTrack) {
            setCurrentDisplayMedia(presentation.localMediaStream);
        }
    }, [presentation.localMediaStream]);

    useEffect(
        () =>
            config.subscribe('bandwidth', bandwidth => {
                logger.debug({bandwidth}, 'setBandwidth');
                void infinityService.setBandwidth(Number(bandwidth));
            }),
        [],
    );

    useEffect(
        () =>
            mediaSignals.onMediaChanged.add(({stream}) => {
                if (stream) {
                    logger.debug({stream}, 'setStream');
                    void infinityService.setStream(stream);
                }
            }),
        [],
    );

    const handleMessage = useCallback(
        (e: MessageEvent<{token: string}>) => {
            if (e.origin !== window.location.origin || !e.data.token) {
                return;
            }

            if (pollingIdRef.current) {
                clearInterval(pollingIdRef.current);
                pollingIdRef.current = undefined;
            }
            popupRef.current?.close();

            logger.debug('remove handleMessage listener');
            window.removeEventListener('message', handleMessage);

            call({ssoToken: e.data.token});
        },
        [call],
    );

    const handleIdp = useCallback(
        (chosenIdp: string, redirectUrl?: string) => {
            popupRef.current?.close();
            popupRef.current = window.open(
                redirectUrl,
                '',
                'width=800,height=600',
            );
            if (!redirectUrl) {
                call({chosenIdp});
            }
            if (!pollingIdRef.current) {
                pollingIdRef.current = window.setInterval(() => {
                    if (popupRef.current?.closed) {
                        if (pollingIdRef.current) {
                            clearInterval(pollingIdRef.current);
                            pollingIdRef.current = undefined;
                        }
                        window.removeEventListener('message', handleMessage);
                        setStep(MeetingFlow.Idp);
                    }
                }, 1000);
            }

            logger.debug('add handleMessage listener');
            window.addEventListener('message', handleMessage);
        },
        [call, handleMessage],
    );

    useEffect(
        () => () => {
            logger.debug('Cleanup idp login');
            if (pollingIdRef.current) {
                clearInterval(pollingIdRef.current);
                pollingIdRef.current = undefined;
            }
            window.removeEventListener('message', handleMessage);
        },
        [handleMessage],
    );

    const raiseHand = useCallback((raise: boolean) => {
        void infinityService.raiseHand({raise});
    }, []);

    const setLayout = useCallback((layout: Layout) => {
        void infinityService.setLayout({layout});
    }, []);

    const lock = useCallback((lock: boolean) => {
        void infinityService.lock({lock});
    }, []);

    const disconnectAll = useCallback(() => {
        void infinityService.disconnectAll({});
    }, []);

    const leave = useCallback(() => {
        push(`/m/${conferenceAlias}/post-meeting`);
    }, [conferenceAlias]);

    return (
        <BrandedFullSizeWindow
            padding="none"
            background="secondary"
            flexDirection="column"
            justifyContent="flexStart"
        >
            {step !== MeetingFlow.InMeeting && <Header />}
            <CenterLayout isFullWidth>
                {step === MeetingFlow.Loading && <Spinner colorScheme="dark" />}
                {FlowCards.includes(step) && (
                    <MeetingFlowCards
                        call={call}
                        error={error}
                        handleIdp={handleIdp}
                        idps={idps}
                        setStep={setStep}
                        step={step}
                    />
                )}
                {step === MeetingFlow.InMeeting && (
                    <InMeeting
                        disconnectAll={disconnectAll}
                        leave={leave}
                        lock={lock}
                        presentation={presentation}
                        raiseHand={raiseHand}
                        setLayout={setLayout}
                        networkState={networkState}
                        splashScreen={splashScreen}
                        stream={stream}
                    />
                )}
            </CenterLayout>
        </BrandedFullSizeWindow>
    );
};

const present = (stream?: MediaStream) => {
    infinityService.present(stream);
};

const presentationStreamCleanup = () => {
    void mediaService.media.applyConstraints({
        audio: {mixWithAdditionalMedia: false},
    });
};

const stopPresentation = () => {
    logger.info('Stop presentation.');
    presentationStreamCleanup();
    infinityService.stopPresenting();
};
