import React from 'react';

import {LiveCaptions} from './LiveCaptions.view';

import styles from './LiveCaptions.module.scss';

export const LiveCaptionsWrapper: React.FC<{
    captions?: string;
}> = ({captions}) => (
    <LiveCaptions
        className={styles.liveCaptionsPositioner}
        captions={captions}
    />
);
