import React from 'react';
import cx from 'classnames';

import type {ButtonProps} from '@pexip/components';
import {Button, onEnter} from '@pexip/components';

import styles from './JoinMeetingButton.module.scss';

export const JoinMeetingButton: React.FC<
    ButtonProps & {
        isDisabled?: boolean;
        spacing?: 'large' | 'medium';
        onClick: () => void;
        text: string;
    }
> = ({isDisabled, onClick, text, className, spacing = 'medium', ...props}) => (
    <Button
        className={cx(
            styles.joinMeetingButtonWrapper,
            spacing === 'medium' ? 'mt-2' : 'mt-3',
            className,
        )}
        isDisabled={isDisabled}
        onClick={onClick}
        onKeyDown={onEnter(onClick)}
        type="submit"
        {...props}
    >
        {text}
    </Button>
);
