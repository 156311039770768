/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface SilentVideoDetectionMap {
    '200': Status200;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    /**
     * The result is true if successful, false otherwise.
     */
    result: boolean;
}
export interface TopLevel {
    config: Config;
}
export interface Config {
    /**
     * Determines whether silent video detection (no faces or movement) is disabled (false) or enabled (true).
     */
    enable?: boolean;
    /**
     * The minimum number of seconds the participant's video has to silent before it is considered for removal from the video mix (it may take longer than this before the video is actually removed). Values from 1-120.
     */
    silent_after?: number;
    /**
     * Determines whether or not detected faces in the video are taken into consideration:
     * true: a participant cannot be marked as silent if a face is detected.
     * false: face-detection is ignored.
     */
    require_no_faces?: boolean;
    /**
     * The minimum number of seconds of moving video that is required before marking the participant as no longer silent. Values 1-5.
     */
    reactivate_after?: number;
}

import {validateBody, validate200} from './silentVideoDetectionValidation';
type SilentVideoDetectionResponse = {
    status: 200;
    data: SilentVideoDetectionMap['200'];
};
interface SilentVideoDetectionParams {
    conferenceAlias: string;
}
export async function silentVideoDetection({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: SilentVideoDetectionParams;
    body: SilentVideoDetectionMap['Body'];
    host: string;
}): Promise<SilentVideoDetectionResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/silent_video_detection`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
