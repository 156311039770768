import type {Signal} from '@pexip/signal';

import type {TransceiverMediaType, TransceiverContentType} from './constants';

export type DetachFn = ReturnType<Signal<unknown>['add']>;

export type ReferenceValue = string;
export type References = Record<string, ReferenceValue>;
export interface LogFn {
    <T extends Record<string, unknown>>(msg: string, context?: T): void;
}

// Event handlers
export type EventHandler = (event: Event) => void;
export type OnNegotiationNeededHandler = () => void;
export type OnTranceiverChangeHandler = () => void;
export type OnTrackEventHandler = (event: RTCTrackEvent) => void;
export type OnRemoteStreamsEventHandler = (
    remoteStreams: MediaStream[],
) => void;
export type OnIceCandidateHandler = (event: RTCPeerConnectionIceEvent) => void;

export interface ExtendedRTCPeerConnection extends RTCPeerConnection {
    /**
     * Typescript DOM type definition has not included this method for
     * `RTCPeerConnection`, thus patch it manually.
     *
     * Allow a web application to easily request that ICE candidate gathering be
     * redone on both ends of the connection. This simplifies the process by
     * allowing the same method to be used by either the caller or the receiver
     * to trigger an ICE restart.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/restartIce
     */
    restartIce(): void;
    peerIdentity: Promise<unknown>;
}

export interface BasePeerConnection {
    // States
    connectionState: RTCPeerConnection['connectionState'];
    iceGatheringState: RTCPeerConnection['iceGatheringState'];
    iceConnectionState: RTCPeerConnection['iceConnectionState'];
    signalingState: RTCPeerConnection['signalingState'];

    // Properties
    localStream: MediaStream | undefined;
    remoteStreams: MediaStream[];
    senders: RTCRtpSender[];
    receivers: RTCRtpReceiver[];

    bandwidth: number;

    hasICECandidates: boolean;
    /**
     * Reference of any logical associations to the peer connection for logging
     */
    references: References;

    offerOptions: RTCOfferOptions | undefined;
    answerOptions: RTCAnswerOptions | undefined;

    getTransceiver: (
        type: TransceiverMediaType,
        contentType?: TransceiverContentType,
    ) => RTCRtpTransceiver | undefined;

    // Methods
    setLocalStream(
        stream: MediaStream,
        contentType?: TransceiverContentType,
    ): Promise<void>;
    getStats(selector?: MediaStreamTrack | null): Promise<RTCStatsReport>;

    createDataChannel: RTCPeerConnection['createDataChannel'];
    /**
     * Key/Value pair for referencing logical associations for logging
     *
     * @param key - The key for the reference
     * @param value - The value for the reference
     */
    setReference(key: string, value: ReferenceValue): void;
    /**
     * Only recently supported on some browser: https://caniuse.com/?search=setconfiguration
     */
    setConfiguration: RTCPeerConnection['setConfiguration'] | undefined;
    getConfiguration: RTCPeerConnection['getConfiguration'];

    isRemoteMainStream(stream: MediaStream): boolean;
    isRemoteMainTrack: (
        transceivers?: RTCRtpTransceiver[],
    ) => (track: MediaStreamTrack) => boolean;

    close: RTCPeerConnection['close'];
    restartIce: () => void;
}

export interface PeerConnection extends BasePeerConnection {
    // Properties
    currentLocalDescription: RTCPeerConnection['currentLocalDescription'];
    /**
     * It represents a local description that is in the process of being
     * negotiated plus any local candidates that have been generated by the ICE
     * Agent since the offer or answer was created. If the `RTCPeerConnection`
     * is in the stable state, the value is `null`.
     *
     * https://w3c.github.io/webrtc-pc/#dom-peerconnection-pendinglocaldesc
     */
    pendingLocalDescription?: RTCSessionDescriptionInit | RTCSessionDescription;
    currentRemoteDescription: RTCPeerConnection['currentRemoteDescription'];
    /**
     * It represents a remote description that is in the process of being
     * negotiated, complete with any remote candidates that have been supplied
     * via `addIceCandidate()` since the offer or answer was created. If the
     * `RTCPeerConnection` is in the stable state, the value is `null`.
     *
     * https://w3c.github.io/webrtc-pc/#dom-peerconnection-pendingremotedesc
     */
    pendingRemoteDescription: RTCPeerConnection['pendingRemoteDescription'];

    contentSlides: boolean;
    negotiationNeeded: boolean;

    // Methods
    createOffer(options?: RTCOfferOptions): Promise<RTCSessionDescriptionInit>;
    createAnswer(
        options?: RTCAnswerOptions,
    ): Promise<RTCSessionDescriptionInit>;

    receiveIceCandidate(
        candidate: RTCIceCandidate | RTCIceCandidateInit,
    ): Promise<void>;

    receiveAnswer(answer: RTCSessionDescriptionInit): Promise<void>;
    receiveOffer(offer: RTCSessionDescriptionInit): Promise<void>;

    // Event handlers
    onConnectionStateChange?: EventHandler;
    onDataChannel?: RTCPeerConnection['ondatachannel'];
    onIceCandidate?: OnIceCandidateHandler;
    onIceCandidateError?: RTCPeerConnection['onicecandidateerror'];
    onIceConnectionStateChange?: EventHandler;
    onIceGatheringStateChange?: EventHandler;
    onNegotiationNeeded?: OnNegotiationNeededHandler;
    onRemoteStreams?: OnRemoteStreamsEventHandler;
    onSignalingStateChange?: EventHandler;
    onTrack?: OnTrackEventHandler;
    onTransceiverChange?: OnTranceiverChangeHandler;
}

export type MainPeerConnection = BasePeerConnection;
export type PresentationPeerConnection = BasePeerConnection;

export type GetSignalTypeFromInterface<
    T,
    K extends keyof T,
> = T[K] extends Signal<infer S> ? S : never;

export interface PeerConnectionSignals {
    onConnectionStateChange: Signal<RTCPeerConnectionState>;
    onDataChannel: Signal<RTCDataChannel>;
    onIceCandidate: Signal<RTCIceCandidate | null>;
    onIceCandidateError: Signal<RTCPeerConnectionIceErrorEvent>;
    onIceConnectionStateChange: Signal<RTCIceConnectionState>;
    onIceGatheringStateChange: Signal<RTCIceGatheringState>;
    onNegotiationNeeded: Signal<undefined>;
    onRemoteStreams: Signal<MediaStream[]>;
    onSignalingStateChange: Signal<RTCSignalingState>;
    onTrack: Signal<RTCTrackEvent>;
    onTransceiverChange: Signal<undefined>;
}

export interface PeerConnectionCommandSignals {
    onOfferRequired: Signal<MediaStream | undefined>;
    onReceiveAnswer: Signal<RTCSessionDescriptionInit>;
    onReceiveIceCandidate?: Signal<RTCIceCandidate | RTCIceCandidateInit>;
    onReceiveOffer: Signal<RTCSessionDescriptionInit>;
}

export interface CorePeerConnectionSignals {
    onAnswer: Signal<RTCSessionDescriptionInit>;
    onOffer: Signal<RTCSessionDescriptionInit>;
    onError: Signal<Error>;
}

export type PCRequiredSignals = CorePeerConnectionSignals &
    Omit<PeerConnectionCommandSignals, 'onReceiveIceCandidate'>;

export type PCOptionalsSignals = Partial<PeerConnectionSignals> &
    Pick<PeerConnectionCommandSignals, 'onReceiveIceCandidate'>;

export type PCSignals = PCRequiredSignals & PCOptionalsSignals;

export type CallTransceivers = {[x in TransceiverContentType]: Transceivers};
export type Transceivers = {[x in TransceiverMediaType]?: RTCRtpTransceiver};

export type CallTransceiversDirection = {
    [x in TransceiverContentType]?: TransceiversDirection;
};
export type TransceiversDirection = {
    [x in TransceiverMediaType]?: RTCRtpTransceiverDirection;
};
export interface PeerConnectionOptions {
    rtcConfig?: RTCConfiguration;
    bandwidth?: number;
    packetizationMode?: boolean;
    offerOptions?: RTCOfferOptions;
    answerOptions?: RTCAnswerOptions;

    transceiversDirection?: CallTransceiversDirection;
    vp9Disabled?: boolean;
    allow1080p?: boolean;
    allow4kPreso?: boolean;
}

export type MainPeerConnectionOptions = PeerConnectionOptions;
export type PresentationPeerConnectionOptions = PeerConnectionOptions;

export enum RecoveryTimeout {
    ConnectionState = 5000,
    IceConnectionState = 2000,
}
