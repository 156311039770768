import * as sdpTransform from 'sdp-transform';

interface SdpOptions {
    contentSlides?: boolean;
    packetizationMode?: boolean;
    videoAS?: number;
    videoTIAS?: number;
    vp9Disabled?: boolean;
    allow1080p?: boolean;
    allow4kPreso?: boolean;
}

export interface PexipMediaLine extends sdpTransform.MediaDescription {
    type: string;
    port: number;
    protocol: string;
    payloads?: string | undefined;
    content?: string;
}

enum Codec {
    VP8 = 'VP8',
    VP9 = 'VP9',
    H264 = 'H264',
}

export interface SdpManager {
    setSdp: (
        sdp: RTCSessionDescriptionInit,
        enrichOptions?: SdpOptions,
    ) => void;
    getSdp: () => RTCSessionDescriptionInit;
    enrichSdp: (options: SdpOptions) => void;
}

// https://tools.ietf.org/html/draft-holmer-rmcat-transport-wide-cc-extensions-01
export const TWCCExtensionUrl =
    'http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions';

export class SdpTransformManager implements SdpManager {
    constructor(
        private sdp: RTCSessionDescriptionInit,
        enrichOptions?: SdpOptions,
    ) {
        this.setSdp(sdp, enrichOptions);
    }

    setSdp(sdp: RTCSessionDescriptionInit, enrichOptions?: SdpOptions) {
        this.sdp = sdp;
        if (enrichOptions) {
            this.enrichSdp(enrichOptions);
        }
    }

    getSdp() {
        return this.sdp;
    }

    enrichSdp(options: SdpOptions) {
        let modifiedSdp = this.sdp;
        if (this.sdp?.sdp) {
            let transformSdp = sdpTransform.parse(this.sdp.sdp);
            if (options.contentSlides) {
                transformSdp = this.addVideoSlidesContentLine(transformSdp);
            }
            if (options.videoAS) {
                transformSdp = this.addBandwidthLine(
                    transformSdp,
                    options.videoAS,
                    options.videoTIAS,
                );
            }
            if (options.vp9Disabled) {
                transformSdp = this.stripCodecs(transformSdp, [Codec.VP9]);
            }
            if (this.shouldAddSupportForHighQualityStream(options)) {
                transformSdp = this.addSupportForHighQualityStream(
                    transformSdp,
                    options.allow4kPreso,
                );
            }

            if (options.packetizationMode) {
                transformSdp =
                    this.removeFmtpWithoutPacketization(transformSdp);
            }

            modifiedSdp = {
                sdp: sdpTransform.write(transformSdp),
                type: this.sdp.type,
            };
        }
        this.sdp = modifiedSdp;
    }

    isTWCCsupported = () => this.sdp.sdp?.includes(TWCCExtensionUrl);

    addMsidToMline = (mid: string, msid: string) => {
        if (!this.sdp.sdp) {
            return;
        }
        let modifiedSdp = this.sdp;
        const transformSdp = sdpTransform.parse(this.sdp.sdp);
        const mLine = transformSdp.media.find(
            ({mid: currentMid}) => String(currentMid) === mid,
        );
        if (mLine) {
            const msids = mLine.msid?.split(' ');
            if (msids?.[0] === '-') {
                msids[0] = msid;
            }
            mLine.msid = msids?.join(' ');
        }

        modifiedSdp = {
            sdp: sdpTransform.write(transformSdp),
            type: this.sdp.type,
        };
        this.sdp = modifiedSdp;
    };

    private shouldAddSupportForHighQualityStream(options: SdpOptions) {
        return (
            options.contentSlides ||
            (options?.allow1080p && options.videoAS && options.videoAS >= 2564)
        );
    }

    private addVideoSlidesContentLine(sdp: sdpTransform.SessionDescription) {
        // FIXME: we assume the presentation is always last, I changed it because for bundle we have 2 video lines
        // and before it was adding to the wrong one, probably we need to do it differently
        const videoLine = this.getLastVideoLine(sdp.media);
        if (videoLine) {
            videoLine.content = 'slides';
        }
        return sdp;
    }

    private addBandwidthLine(
        sdp: sdpTransform.SessionDescription,
        videoAS: number,
        videoTIAS?: number,
    ) {
        const videoLines = this.getVideoLines(sdp.media);
        videoLines.forEach(videoLine => {
            if (!videoLine.bandwidth) {
                videoLine.bandwidth = [];
            }
            videoLine.bandwidth.push({
                type: 'AS',
                limit: videoAS,
            });
            if (videoTIAS) {
                // For FF we should include this to the media line
                // (required only for outgoing stream)
                videoLine.bandwidth.push({
                    type: 'TIAS',
                    limit: videoTIAS,
                });
            }
        });
        return sdp;
    }

    private addSupportForHighQualityStream(
        sdp: sdpTransform.SessionDescription,
        allow4kPreso = false,
    ) {
        const videoLines = this.getVideoLines(sdp.media);
        videoLines.forEach(videoLine => {
            const codecs = this.getCodecs(videoLine.rtp);

            videoLine.fmtp = videoLine.fmtp.map(fmtp => {
                if (fmtp.config.includes('max-fs')) {
                    return fmtp;
                }

                const codec = codecs[fmtp.payload];
                const is4kPreso = isPreso(videoLine) && allow4kPreso;
                if (codec === Codec.VP8 || codec === Codec.VP9) {
                    fmtp.config += this.getVPXConfigOverrides(is4kPreso);
                } else if (codec === Codec.H264) {
                    fmtp.config += this.getH264ConfigOverrides(is4kPreso);
                }

                return fmtp;
            });
        });

        return sdp;
    }

    private getVPXConfigOverrides(is4kEnabled = false) {
        return `;max-fs=${is4kEnabled ? '36864' : '8160'};max-fr=30`;
    }

    private getH264ConfigOverrides(is4kEnabled = false) {
        return is4kEnabled
            ? ';max-br=32768;max-mbps=2073600;max-fs=36864;max-smbps=2073600;max-fps=6000;max-fr=30'
            : ';max-br=3732;max-mbps=245760;max-fs=8192;max-smbps=245760;max-fps=3000;max-fr=30';
    }

    /**
     * The "OpenH264 Video Codec provided by Cisco Systems" plugin in Firefox \>= 97 seems to not encode video.
     * We can re-order the priority, but Alan Ford thinks just removing the profile __without__ packetization-mode=1 does the same trick (and is much simpler).
     *
     * @see https://gitlab.com/pexip/zoo/-/issues/2575
     */
    private removeFmtpWithoutPacketization(
        sdp: sdpTransform.SessionDescription,
    ) {
        const videoLines = this.getVideoLines(sdp.media);
        videoLines.forEach(videoLine => {
            const codecs = this.getCodecs(videoLine.rtp);

            videoLine.fmtp = videoLine.fmtp.filter(
                fmtp =>
                    codecs[fmtp.payload] !== Codec.H264 ||
                    (codecs[fmtp.payload] === Codec.H264 &&
                        fmtp.config.includes('packetization-mode=1')),
            );
        });

        return sdp;
    }

    private stripCodecs(
        sdp: sdpTransform.SessionDescription,
        disableCodecs: Codec[],
    ) {
        const videoLines = this.getVideoLines(sdp.media);
        for (const videoLine of videoLines) {
            if (videoLine) {
                const removePayloads = videoLine.rtp
                    .filter(({codec}) => disableCodecs.includes(codec as Codec))
                    .map(({payload}) => payload);

                if (removePayloads.length > 0) {
                    const rtxApts = removePayloads.map(item => `apt=${item}`);
                    const rtxPayloads = videoLine.fmtp.filter(item =>
                        rtxApts.includes(item.config),
                    );

                    removePayloads.push(
                        ...rtxPayloads.map(item => item.payload),
                    );
                }
                if (videoLine.payloads) {
                    for (const payload of removePayloads) {
                        videoLine.payloads = videoLine.payloads.replace(
                            `${payload} `,
                            '',
                        );
                    }
                }
                videoLine.rtp = videoLine.rtp.filter(
                    rtp => !removePayloads.includes(rtp.payload),
                );
                videoLine.fmtp = videoLine.fmtp.filter(
                    fmtp => !removePayloads.includes(fmtp.payload),
                );
                if (videoLine.rtcpFb) {
                    videoLine.rtcpFb = videoLine.rtcpFb.filter(
                        rtcpFb => !removePayloads.includes(rtcpFb.payload),
                    );
                }
            }
        }

        return sdp;
    }

    private getVideoLines(
        media: sdpTransform.SessionDescription['media'],
    ): PexipMediaLine[] {
        return media.filter(line => line.type === 'video');
    }

    private getVideoLine(
        media: sdpTransform.SessionDescription['media'],
    ): PexipMediaLine | undefined {
        return media.find(line => line.type === 'video');
    }

    private getLastVideoLine(
        media: sdpTransform.SessionDescription['media'],
    ): PexipMediaLine | undefined {
        let index: number | undefined;
        media.forEach((line, i) => {
            if (line.type === 'video') {
                index = i;
            }
        });

        return typeof index !== 'undefined' ? media[index] : undefined;
    }

    private getCodecs(rtp: PexipMediaLine['rtp']) {
        return rtp.reduce((acc, {codec, payload}) => {
            acc[payload] = codec;
            return acc;
        }, {} as {[x: string]: string});
    }
}

export const hasICECandidates = (sdp?: string) => {
    if (!sdp) {
        return false;
    }
    const transformedSDP = sdpTransform.parse(sdp);
    return transformedSDP.media.some(
        m => m.candidates && m.candidates.length > 0,
    );
};

export const getMediaLines = (sdp?: string) => {
    if (!sdp) {
        return [];
    }
    return sdpTransform.parse(sdp).media;
};

export const isPreso = (media: PexipMediaLine) => media.content === 'slides';
