import React from 'react';

import {NotificationToast} from '@pexip/components';

import {UserName} from './pages/UserName/UserName.page';
import {MobileGetStarted} from './pages/MobileGetStarted/MobileGetStarted.page';
import {useRouter} from './router';
import {BrandingContext} from './branding/Context';
import {useBrandingLoader} from './branding/useBrandingLoader';
import {getShouldRenderUserNamePageHook} from './hooks/useShouldRenderUserNamePage';
import {useMainRoutes} from './hooks/useMainRoutes';
import {
    mobileGetStartedNextSignal,
    renderUserNamePageSignal,
} from './signals/MeetingFlow.signals';
import {getShouldRenderMobileGetStartedPageHook} from './hooks/useShouldRenderMobileGetStartedPage';

const useShouldRenderUserNamePage = getShouldRenderUserNamePageHook(
    renderUserNamePageSignal,
);
const useShouldRenderMobileGetStartedPage =
    getShouldRenderMobileGetStartedPageHook(mobileGetStartedNextSignal);

export const App: React.FC = () => {
    useRouter();
    const brand = useBrandingLoader();
    const matchedRoutes = useMainRoutes();
    const is404 = matchedRoutes.some(route => route.props.match.fallback);
    const shouldRenderUserNamePage = useShouldRenderUserNamePage();
    const shouldRenderMobileGetStartedPage =
        useShouldRenderMobileGetStartedPage();

    const renderPage = () => {
        if (!is404 && shouldRenderMobileGetStartedPage) {
            return <MobileGetStarted />;
        }

        if (!is404 && shouldRenderUserNamePage) {
            return <UserName />;
        }

        return matchedRoutes;
    };

    if (!brand) {
        return null;
    }

    return (
        <main>
            <BrandingContext.Provider value={brand}>
                <>
                    {renderPage()}
                    <NotificationToast />
                </>
            </BrandingContext.Provider>
        </main>
    );
};
