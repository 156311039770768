import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useHotKey} from '@pexip/hooks';
import {
    isInitialPermissionsNotGranted,
    toDeniedDevices,
    areBothGranted,
    isGrantedOnlyAudio,
} from '@pexip/media';
import type {InMeetingUI, usePresentation} from '@pexip/media-components';
import {
    ResponsiveButton,
    currentBrowserName,
    getLearnAboutRequestPermissionsUrl,
    isScreenShareSupported,
    useDeviceStatusInfo,
    InMeetingInputControls,
    Footer,
    MuteState,
    InMeetingInputControl,
    MeetingScrim,
    createPushToTalkHook,
} from '@pexip/media-components';
import type {ButtonProps} from '@pexip/components';
import {
    Group,
    Icon,
    IconTypes,
    useTouchDevice,
    useSmUp,
    useSmDown,
    useMdUp,
} from '@pexip/components';
import type {Participant} from '@pexip/infinity';

import {useConfig} from '../config';
import {mediaService, useStreamStatus} from '../services/Media.service';
import {infinityService} from '../services/InfinityClient.service';
import {useEffectsApplied} from '../hooks/useEffectsApplied';
import {useMuteToasts} from '../hooks/useMuteToasts';
import {pushToTalkSignal} from '../signals/Media.signals';
import type {LiveCaptionsAPI} from '../hooks/useLiveCaptions';
import {useLiveCaptionsEnabled} from '../hooks/useLiveCaptions';
import {useConferenceStatus} from '../hooks/useConferenceStatus';
import {TestId} from '../../test/testIds';

import {LiveCaptionsToggle} from './LiveCaptionsToggle.viewModel';
import {ParticipantsPanelToggle} from './ParticipantsPanelToggle.viewModel';
import {ChatPanelToggle} from './ChatPanelToggle.viewModel';

const usePushToTalk = createPushToTalkHook(
    areBothGranted,
    isGrantedOnlyAudio,
    pushToTalkSignal,
);

export const MeetingFooter: React.FC<{
    me?: Participant;
    inMeetingUI: InMeetingUI;
    leave: () => void;
    openEffectsModal: () => void;
    presentation: ReturnType<typeof usePresentation>;
    raiseHand: (raise: boolean) => void;
    captions: LiveCaptionsAPI;
}> = ({
    me,
    inMeetingUI,
    presentation,
    leave,
    openEffectsModal,
    raiseHand,
    captions,
}) => {
    const {t} = useTranslation();
    const conferenceStatus = useConferenceStatus();
    const isTouchDevice = useTouchDevice();
    const isSmUp = useSmUp();
    const isSmDown = useSmDown();
    const isMdUp = useMdUp();
    const isLiveCaptionsEnabled = useLiveCaptionsEnabled();

    const leaveButton = (
        <ResponsiveButton
            iconSource={IconTypes.IconLeave}
            onClick={leave}
            variant="danger"
            data-testid={TestId.ButtonLeave}
            {...inMeetingUI.autoHideProps}
        >
            {t('meeting.leave', 'Leave')}
        </ResponsiveButton>
    );

    const shouldEnableChat =
        infinityService.conferenceFeatureFlags?.chatEnabled &&
        me &&
        !me.isGateway;

    const shouldEnableRaiseHand =
        !infinityService.conferenceFeatureFlags?.isDirectMedia &&
        me &&
        !me.isGateway;

    return (
        <MeetingScrim position="bottom">
            <Footer
                start={
                    isMdUp ? (
                        <>
                            {shouldEnableChat && (
                                <ChatPanelToggle inMeetingUI={inMeetingUI} />
                            )}
                            <ParticipantsPanelToggle
                                inMeetingUI={inMeetingUI}
                                myIdentity={me?.identity}
                            />
                        </>
                    ) : (
                        <></>
                    )
                }
                middle={
                    <MeetingFooterMedia
                        me={me}
                        openEffectsModal={openEffectsModal}
                        presentation={presentation}
                        inMeetingUI={inMeetingUI}
                        enhancerEnd={
                            <>
                                {shouldEnableRaiseHand && (
                                    <RaiseHandToggle
                                        me={me}
                                        {...inMeetingUI.autoHideProps}
                                        isDisabled={!conferenceStatus?.started}
                                        raiseHand={raiseHand}
                                    />
                                )}
                                {isLiveCaptionsEnabled && (
                                    <LiveCaptionsToggle
                                        {...inMeetingUI.autoHideProps}
                                        captions={captions}
                                    />
                                )}
                                {presentation.isPresenting &&
                                    !presentation.poppedOut
                                        .isPresentationPoppedOut &&
                                    !isTouchDevice &&
                                    isSmUp && (
                                        <PresentationModeToggle
                                            isExpanded={
                                                presentation.isPrimaryExpanded
                                            }
                                            toggleExpanded={() =>
                                                presentation.setExpandPrimary(
                                                    !presentation.isPrimaryExpanded,
                                                )
                                            }
                                            {...inMeetingUI.autoHideProps}
                                        />
                                    )}
                                {isSmDown && leaveButton}
                            </>
                        }
                    />
                }
                end={
                    isSmUp ? (
                        <Group spacing="small">{leaveButton}</Group>
                    ) : (
                        <></>
                    )
                }
            />
        </MeetingScrim>
    );
};

const MeetingFooterMedia: React.FC<{
    me?: Participant;
    enhancerEnd?: React.ReactChild;
    presentation: ReturnType<typeof usePresentation>;
    inMeetingUI: InMeetingUI;
    openEffectsModal: () => void;
}> = ({
    me,
    enhancerEnd,
    inMeetingUI: {autoHideProps},
    presentation: {
        localIsPresenting,
        stopPresentation,
        handlePresentationRequest,
    },
    openEffectsModal,
}) => {
    const [videoProcessingEnabled] = useConfig('videoProcessing');
    const [isAudioInputMuted, setAudioInputMuted] =
        useConfig('isAudioInputMuted');
    const [isVideoInputMuted, setVideoInputMuted] =
        useConfig('isVideoInputMuted');

    const effectsApplied = useEffectsApplied();

    const streamStatus = useStreamStatus();
    const inputStatusInfo = useDeviceStatusInfo(streamStatus);
    const isTouchDevice = useTouchDevice();
    const deniedDevices = toDeniedDevices(streamStatus);

    const initialPermissionsNotGranted =
        isInitialPermissionsNotGranted(streamStatus);

    useEffect(() => {
        void infinityService.muteVideo({
            muteVideo: isVideoInputMuted,
        });
    }, [isVideoInputMuted]);

    const onToggleAudioClick = useCallback(
        () => setAudioInputMuted(isMuted => !isMuted),
        [setAudioInputMuted],
    );
    const onToggleVideoClick = useCallback(
        () => setVideoInputMuted(isMuted => !isMuted),
        [setVideoInputMuted],
    );

    useHotKey('m', onToggleAudioClick);
    useHotKey('c', onToggleVideoClick);

    usePushToTalk(isAudioInputMuted, setAudioInputMuted, streamStatus);
    useMuteToasts();

    const canPresent =
        isScreenShareSupported &&
        Boolean(
            me?.canControl ||
                infinityService.conferenceFeatureFlags?.guestsCanPresent,
        ) &&
        !isTouchDevice;

    return (
        <InMeetingInputControls
            segmentationEnabled={videoProcessingEnabled && !isTouchDevice}
            isBlurred={effectsApplied}
            toggleBlur={openEffectsModal}
            audioMuteState={getMuteState(initialPermissionsNotGranted)(
                isAudioInputMuted,
            )}
            videoMuteState={getMuteState(initialPermissionsNotGranted)(
                isVideoInputMuted,
            )}
            inputStatusInfo={inputStatusInfo}
            localIsPresenting={localIsPresenting}
            handleAudioInput={
                initialPermissionsNotGranted
                    ? () =>
                          mediaService.getUserMedia({
                              audio: true,
                              video: true,
                          })
                    : onToggleAudioClick
            }
            handleVideoInput={
                initialPermissionsNotGranted
                    ? () =>
                          mediaService.getUserMedia({
                              audio: true,
                              video: true,
                          })
                    : onToggleVideoClick
            }
            handlePresentation={
                localIsPresenting ? stopPresentation : handlePresentationRequest
            }
            isScreenShareSupported={canPresent}
            deniedDevices={deniedDevices}
            learnHowToGrantAccessURL={getLearnAboutRequestPermissionsUrl(
                currentBrowserName,
            )}
            onTryAgain={() =>
                mediaService.getUserMedia({audio: true, video: true})
            }
            enhancerEnd={enhancerEnd && enhancerEnd}
            {...autoHideProps}
        />
    );
};

const RaiseHandToggle: React.FC<
    ButtonProps & {
        me?: Participant;
        raiseHand: (raise: boolean) => void;
    }
> = ({me, raiseHand, ...props}) => {
    const {t} = useTranslation();
    const raisedHand = Boolean(me?.raisedHand);
    const isTouchDevice = useTouchDevice();

    return (
        <InMeetingInputControl
            icon={
                <Icon
                    source={IconTypes.IconRaiseHand}
                    size={isTouchDevice ? 'medium' : 'small'}
                />
            }
            aria-label={
                raisedHand
                    ? t('common.lower-hand', 'Lower hand')
                    : t('common.raise-hand', 'Raise hand')
            }
            tooltipText={
                raisedHand
                    ? t('common.lower-hand', 'Lower hand')
                    : t('common.raise-hand', 'Raise hand')
            }
            onClick={() => raiseHand(!raisedHand)}
            isActive={raisedHand}
            data-testid={
                raisedHand ? TestId.ButtonLowerHand : TestId.ButtonRaiseHand
            }
            {...props}
        />
    );
};

const PresentationModeToggle: React.FC<
    ButtonProps & {
        isExpanded: boolean;
        toggleExpanded: () => void;
    }
> = ({toggleExpanded, isExpanded, ...props}) => {
    const {t} = useTranslation();
    const isTouchDevice = useTouchDevice();
    return (
        <InMeetingInputControl
            icon={
                <Icon
                    source={
                        isExpanded
                            ? IconTypes.IconFullscreenOff
                            : IconTypes.IconFullscreenOn
                    }
                    size={isTouchDevice ? 'medium' : 'small'}
                />
            }
            aria-label={
                isExpanded
                    ? t('meeting.presentation.collapse', 'Collapse')
                    : t('meeting.presentation.expand', 'Expand')
            }
            tooltipText={
                isExpanded
                    ? t('meeting.presentation.collapse', 'Collapse')
                    : t('meeting.presentation.expand', 'Expand')
            }
            onClick={toggleExpanded}
            {...props}
        />
    );
};

// FIXME: remove lighthouse dependency here
const getMuteState =
    (initialPermissionsNotGranted: boolean) => (isInputMuted: boolean) =>
        initialPermissionsNotGranted || isInputMuted
            ? MuteState.Muted
            : MuteState.Unmuted;
