import React from 'react';
import cx from 'classnames';

import {Accordion} from '@pexip/components';

export const ParticipantListWrapper: React.FC<
    React.PropsWithChildren<{
        accordionContentClassName?: string;
        canOnlyToggleWithTitle?: boolean;
        className?: string;
        enhancerEnd?: React.ReactNode;
        meta: string;
        title: string;
    }>
> = ({
    accordionContentClassName,
    canOnlyToggleWithTitle,
    className,
    children,
    enhancerEnd,
    meta,
    title,
    ...props
}) => (
    <Accordion
        buttonClassName="pl-2"
        canOnlyToggleWithTitle={canOnlyToggleWithTitle}
        className={cx('pt-0 mr-4 mb-5', className)}
        contentClassName={accordionContentClassName}
        enhancerEnd={enhancerEnd}
        isExpanded
        meta={meta}
        title={title}
        {...props}
    >
        <div>{children}</div>
    </Accordion>
);
