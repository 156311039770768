import React from 'react';

import type {ExtendedInfinityErrorMessage} from '@pexip/infinity';

import type {Idp} from '../../types';
import {MeetingFlow} from '../../types';
import {AreYouHost} from '../AreYouHost/AreYouHost.view';
import {EnterPin} from '../EnterPin/EnterPin.view';
import {ResponsiveBox} from '../ResponsiveBox/ResponsiveBox.view';
import {SelectIdp} from '../SelectIdp/SelectIdp.view';
import {EnterExtension} from '../EnterExtension/EnterExtension.view';

import styles from './MeetingFlowCards.module.scss';

export const FlowCards = [
    MeetingFlow.AreYouHost,
    MeetingFlow.EnterHostPin,
    MeetingFlow.EnterPin,
    MeetingFlow.EnterExtension,
    MeetingFlow.Idp,
];

export const MeetingFlowCards: React.FC<{
    call: ({
        pin,
        conferenceExtension,
    }: {
        pin?: string;
        conferenceExtension?: string;
    }) => void;
    error?: ExtendedInfinityErrorMessage;
    idps: Idp[];
    step: MeetingFlow;
    setStep: React.Dispatch<React.SetStateAction<MeetingFlow>>;
    handleIdp: (chosenIdp: string, redirectUrl?: string) => void;
}> = ({call, error, handleIdp, idps, setStep, step}) => (
    <ResponsiveBox className={styles.box}>
        {step === MeetingFlow.AreYouHost && (
            <AreYouHost
                yes={() => setStep(MeetingFlow.EnterHostPin)}
                no={() => call({pin: 'none'})}
            />
        )}
        {(step === MeetingFlow.EnterHostPin ||
            step === MeetingFlow.EnterPin) && (
            <EnterPin
                errorText={error}
                isHost={step === MeetingFlow.EnterHostPin}
                onSubmit={call}
            />
        )}
        {step === MeetingFlow.EnterExtension && (
            <EnterExtension errorText={error} onSubmit={call} />
        )}
        {step === MeetingFlow.Idp && (
            <SelectIdp idps={idps} handleIdp={handleIdp} />
        )}
    </ResponsiveBox>
);

export type MeetingFlowCardsProps = React.ComponentProps<
    typeof MeetingFlowCards
>;
