import React from 'react';

import type {PreviewStreamController} from '@pexip/media';
import {
    MediaControlHandler,
    usePreviewControllerHandler,
    usePreviewErrorHandling,
    useAnalyzer,
} from '@pexip/media-components';

import {useConfig} from '../config';
import {
    useDevices,
    usePreviewAudioInput,
    usePreviewAudioOutput,
    usePreviewDenoise,
    usePreviewStreamQuality,
    usePreviewVideoInput,
    useStreamStatus,
} from '../services/Media.service';

import {OutputAudioTester} from './OutputAudioTester.viewModel';

export const MediaControl: React.FC<{
    closeModal: () => void;
    controller: PreviewStreamController;
}> = ({closeModal, controller}) => {
    const devices = useDevices();
    const streamStatus = useStreamStatus();

    const {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    } = usePreviewErrorHandling(controller, streamStatus);

    const audioInput = usePreviewAudioInput({
        error: audioInputError,
        controller,
        setError: setAudioInputError,
    });

    const videoInput = usePreviewVideoInput({
        error: videoInputError,
        controller,
        setError: setVideoInputError,
    });

    const audioOutput = usePreviewAudioOutput(
        devices,
        useConfig('audioOutput')[0],
    );

    const streamQuality = usePreviewStreamQuality();
    const denoise = usePreviewDenoise();

    const previewController = usePreviewControllerHandler({
        close: closeModal,
        controller,
        applyChanges: [
            streamQuality.applyChanges,
            denoise.applyChanges,
            videoInput.applyChanges,
            audioInput.applyChanges,
            audioOutput.applyChanges,
        ],
    });

    const analyzer = useAnalyzer(controller.media);

    return (
        <MediaControlHandler
            audioInput={audioInput}
            audioOutput={audioOutput}
            closeModal={closeModal}
            denoise={denoise}
            devices={devices}
            previewController={previewController}
            streamQuality={streamQuality}
            videoInput={videoInput}
            audioAnalyzer={analyzer}
        >
            <OutputAudioTester sinkId={audioOutput.preview?.deviceId || ''} />
        </MediaControlHandler>
    );
};
