import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Bar, Button, FontVariant, Text} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const AreYouHost: React.FC<{no: () => void; yes: () => void}> = ({
    no,
    yes,
}) => {
    const {t} = useTranslation();
    return (
        <>
            <Text fontVariant={FontVariant.H4}>
                <Trans t={t} i18nKey="pin.are-you-host">
                    Are you a Host?
                </Trans>
            </Text>
            <Bar className="mt-5">
                <Button
                    className="mr-1"
                    modifier="fullWidth"
                    variant="tertiary"
                    onClick={no}
                    data-testid={TestId.ButtonAreYouHostNo}
                >
                    <Trans t={t} i18nKey="common.no">
                        No
                    </Trans>
                </Button>
                <Button
                    className="ml-1"
                    modifier="fullWidth"
                    onClick={yes}
                    data-testid={TestId.ButtonAreYouHostYes}
                >
                    <Trans t={t} i18nKey="common.yes">
                        Yes
                    </Trans>
                </Button>
            </Bar>
        </>
    );
};
