import React from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {Media, UserMediaStatus} from '@pexip/media';

import {ReadyToJoin} from '../views/ReadyToJoin/ReadyToJoin.view';
import {PreflightJoinSteps} from '../enums';
import {FlowType} from '../types';

import {Speaker} from './Speaker.viewModel';
import {Microphone} from './Microphone.viewModel';
import {Camera} from './Camera.viewModel';
import {PreflightJoin} from './PreflightJoin.viewModel';

export const DeviceSelectionSteps: React.FC<{
    call: () => void;
    currentStepIndex: PreflightJoinSteps;
    devices: MediaDeviceInfoLike[];
    media: Media;
    next: (step: PreflightJoinSteps) => () => void;
    streamStatus: UserMediaStatus;
    no: () => void;
    help: () => void;
    accessibilityHelpExpanded: boolean;
}> = ({
    call,
    currentStepIndex,
    devices,
    media,
    next,
    streamStatus,
    no,
    help,
    accessibilityHelpExpanded,
}) => {
    switch (currentStepIndex) {
        case PreflightJoinSteps.Camera:
            return (
                <Camera
                    devices={devices}
                    media={media}
                    next={next(PreflightJoinSteps.Speaker)}
                    streamStatus={streamStatus}
                    no={no}
                    help={help}
                    accessibilityHelpExpanded={accessibilityHelpExpanded}
                />
            );

        case PreflightJoinSteps.Speaker:
            return (
                <Speaker
                    devices={devices}
                    next={next(PreflightJoinSteps.Microphone)}
                    streamStatus={streamStatus}
                    no={no}
                    help={help}
                    accessibilityHelpExpanded={accessibilityHelpExpanded}
                />
            );

        case PreflightJoinSteps.Microphone:
            return (
                <Microphone
                    devices={devices}
                    media={media}
                    next={next(PreflightJoinSteps.ReadyToJoin)}
                    streamStatus={streamStatus}
                    no={no}
                    help={help}
                    accessibilityHelpExpanded={accessibilityHelpExpanded}
                />
            );

        case PreflightJoinSteps.ReadyToJoin:
            return (
                <ReadyToJoin>
                    <PreflightJoin call={call} flowType={FlowType.StepByStep} />
                </ReadyToJoin>
            );
    }
};
