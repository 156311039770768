import React from 'react';
import {useTranslation} from 'react-i18next';

import type {DeviceStatusInfo} from '@pexip/media-components';
import {Button, FontVariant, Icon, IconTypes, Text} from '@pexip/components';

import type {BlockedBrowserPermissionsInfo} from '../../types';
import {MediaModal} from '../MediaModal/MediaModal.view';

import styles from './PermissionsRejected.module.scss';

export const UnblockHelpMessage: React.FC<{
    statusInfo: DeviceStatusInfo;
    setShowHelpVideo: (
        showHelpVideo: (showHelpVideo: boolean) => boolean,
    ) => void;
    showHelpVideo: boolean;
    browserSpecificHelpers: BlockedBrowserPermissionsInfo;
}> = ({
    statusInfo,
    setShowHelpVideo,
    showHelpVideo,
    browserSpecificHelpers,
}) => {
    const {t} = useTranslation();

    const mediaSources = {
        video: {
            vp9: browserSpecificHelpers.vp9,
            mp4: browserSpecificHelpers.mp4,
        },
    };

    return (
        <div className={styles.messageContainer}>
            <div className={'mb-4'}>
                <Icon source={IconTypes.IconAlert} size="medium" />
            </div>

            <Text fontVariant={FontVariant.Body} className={'mb-4'}>
                {statusInfo.message}
            </Text>
            <div className="mt-8 px-4">
                <Button
                    onClick={() =>
                        setShowHelpVideo(
                            (showHelpVideo: boolean) => !showHelpVideo,
                        )
                    }
                    noBorderRadius={false}
                >
                    {t('media.show-me-how', 'Show me how')}
                </Button>
                <MediaModal
                    isOpen={showHelpVideo}
                    onClose={() => setShowHelpVideo(() => false)}
                    src={mediaSources}
                    videoTextOverlaySteps={
                        browserSpecificHelpers.videoTextOverlaySteps
                    }
                    browserName={browserSpecificHelpers.browserName}
                    altTag={t(
                        `media.how-to-unblock-access`,
                        `An animation with instructions for giving permission to access media devices.`,
                    )}
                ></MediaModal>
            </div>
        </div>
    );
};
