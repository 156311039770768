import React from 'react';

import {UserMediaStatus} from '@pexip/media/src/types';

import type {PreflightJoinSteps} from '../../enums';
import {PreflightTips} from '../PreflightTips/PreflightTips.view';

import {PermissionsRejectedVideoInput} from './PermissionsRejectedVideoInput.view';
import {AllPermissionsRejected} from './AllPermissionsRejected.view';
import {VideoDeviceNotFound} from './VideoDeviceNotFound.view';
import {PermissionsRejectedAudioInput} from './PermissionsRejectedAudioInput.view';

export const PermissionsRejected: React.FC<{
    streamStatus: UserMediaStatus;
    currentStepIndex: PreflightJoinSteps;
    next?: () => void | undefined;
    setHideHelp: () => void;
    closeTips: () => void;
    setScrollTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    streamStatus,
    currentStepIndex,
    next,
    setHideHelp,
    closeTips,
    setScrollTrigger,
}) => {
    switch (streamStatus) {
        case UserMediaStatus.PermissionsRejected:
            return (
                <AllPermissionsRejected
                    next={next}
                    streamStatus={streamStatus}
                    setScrollTrigger={setScrollTrigger}
                />
            );

        case UserMediaStatus.PermissionsRejectedAudioInput:
            return <PermissionsRejectedAudioInput next={next} />;

        case UserMediaStatus.PermissionsRejectedVideoInput:
            return <PermissionsRejectedVideoInput next={next} />;

        case UserMediaStatus.VideoDeviceNotFound:
            return <VideoDeviceNotFound next={next} />;

        default:
            return (
                <PreflightTips
                    currentStepIndex={currentStepIndex}
                    next={setHideHelp}
                    close={closeTips}
                />
            );
    }
};

export type PermissionsRejectedProps = React.ComponentProps<
    typeof PermissionsRejected
>;
