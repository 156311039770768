import {createPCSignals} from '@pexip/peer-connection';
import {createStatsSignals} from '@pexip/peer-connection-stats';
import {createSignal, combine} from '@pexip/signal';

const callPrefix = 'call:pc';

export const pcMainSignals = createPCSignals(
    [
        'onIceCandidate',
        'onIceGatheringStateChange',
        'onIceConnectionStateChange',
        'onReceiveIceCandidate',
        'onRemoteStreams',
        'onConnectionStateChange',
        'onTransceiverChange',
    ],
    `${callPrefix}:main`,
);

export const callLivenessSignals = {
    onReconnecting: createSignal({
        name: `${callPrefix}:reconnecting`,
    }),
    onReconnected: createSignal({name: `${callPrefix}:reconnected`}),
};

export const audioInboundStatsSignals = createStatsSignals('audio:inbound');
export const audioOutboundStatsSignals = createStatsSignals('audio:outbound');

export const videoInboundStatsSignals = createStatsSignals('video:inbound');
export const videoOutboundStatsSignals = createStatsSignals('video:outbound');

export const combinedRtcStatsSignal = combine(
    audioInboundStatsSignals.onRtcStats,
    audioOutboundStatsSignals.onRtcStats,
    videoInboundStatsSignals.onRtcStats,
    videoOutboundStatsSignals.onRtcStats,
);

export const combinedCallQualitySignal = combine(
    audioInboundStatsSignals.onCallQuality,
    audioOutboundStatsSignals.onCallQuality,
);

export const combinedCallQualityStatsSignal = combine(
    audioInboundStatsSignals.onCallQualityStats,
    audioOutboundStatsSignals.onCallQualityStats,
);
