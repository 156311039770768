import React from 'react';
import {useTranslation} from 'react-i18next';

import type {UserMediaStatus} from '@pexip/media';
import {Button, Icon, IconTypes} from '@pexip/components';

import type {DeviceStatusInfo} from '../../types';
import {PreflightInputControls} from '../InputControls/PreflightInputControls.view';
import {TestId} from '../../../test/testIds';

import {PreflightControlsWrapper} from './PreflightControlsWrapper.view';

export const PreflightMediaControls: React.FC<{
    audioInputControl?: React.ReactNode;
    inputStatusInfo: DeviceStatusInfo;
    isAudioInputMuted: boolean;
    isBlurred?: boolean;
    isMobileDevice: boolean;
    isVideoInputMuted: boolean;
    requestMediaPermissions: () => void;
    segmentationEnabled?: boolean;
    shouldCenter: boolean;
    shouldShowVideoStatus?: boolean;
    showSettingsButton?: boolean;
    silentTooltipInfo?: DeviceStatusInfo;
    streamStatus?: UserMediaStatus;
    miniButtons: boolean;
    toggleAudioInput?: () => void;
    toggleBlur?: () => void;
    toggleVideoInput?: () => void;
    triggerSettingsModal?: () => void;
    toggleFacingMode: () => void;
}> = ({
    audioInputControl,
    inputStatusInfo,
    isAudioInputMuted,
    isBlurred,
    isMobileDevice,
    isVideoInputMuted,
    requestMediaPermissions,
    segmentationEnabled,
    shouldCenter,
    shouldShowVideoStatus,
    showSettingsButton,
    silentTooltipInfo,
    streamStatus,
    miniButtons,
    toggleAudioInput,
    toggleBlur,
    toggleVideoInput,
    triggerSettingsModal,
    toggleFacingMode,
}) => {
    const {t} = useTranslation();

    return (
        <PreflightControlsWrapper
            position={shouldShowVideoStatus ? 'relative' : 'absoluteBottom'}
            isPhone={isMobileDevice}
            shouldCenter={shouldCenter}
        >
            {showSettingsButton && !isMobileDevice && (
                <Button
                    aria-label={t(
                        'settings.device-settings',
                        'Device Settings',
                    )}
                    data-testid={TestId.ButtonDeviceSettings}
                    modifier="square"
                    onClick={triggerSettingsModal}
                    size={isMobileDevice ? 'none' : 'medium'}
                    variant="translucent"
                >
                    <Icon
                        source={IconTypes.IconSettings}
                        size={isMobileDevice ? 'mini' : 'medium'}
                    />
                </Button>
            )}
            <PreflightInputControls
                inputStatusInfo={inputStatusInfo}
                isAudioInputMuted={isAudioInputMuted}
                isVideoInputMuted={isVideoInputMuted}
                requestMediaPermissions={requestMediaPermissions}
                silentTooltipInfo={silentTooltipInfo}
                streamStatus={streamStatus}
                toggleAudioInput={toggleAudioInput}
                toggleVideoInput={toggleVideoInput}
                audioInputControl={audioInputControl}
                spacing="compact"
                segmentationEnabled={segmentationEnabled}
                toggleBlur={toggleBlur}
                isBlurred={isBlurred}
                isMobileDevice={isMobileDevice}
                toggleFacingMode={toggleFacingMode}
                miniButtons={miniButtons}
            />
        </PreflightControlsWrapper>
    );
};

export type PreflightMediaControlsProps = React.ComponentProps<
    typeof PreflightMediaControls
>;
