import React, {useCallback} from 'react';

import {AspectRatio, Audio, useTouchDevice} from '@pexip/components';
import {
    TestYourMicStages,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    useSelectAudioOutput,
} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {findAudioOutputDevices} from '@pexip/media-control';
import type {UserMediaStatus} from '@pexip/media';

import {useConfig} from '../config';
import {SpeakerStep} from '../views/SpeakerStep/SpeakerStep.view';
import {useMediaElementAnalyzer} from '../hooks/useMediaElementAnalyzer';
import {
    useAnimateFrequencyBars,
    useByteFrequencyData,
} from '../hooks/useAnimateFrequencyBars';
import {BARS_NUMBER} from '../constants';
import {FrequencyBarsAudioMeterWithContent} from '../views/OneTimeDeviceSelection/FrequencyBarsAudioMeterWithContent.view';
import {SpeakerToggleButton} from '../views/SpeakerToggleButton/SpeakerToggleButton.view';

const BARS_WRAPPER_ID = 'step-by-step-speaker-test-bars-wrapper-id';

export const Speaker: React.FC<{
    devices: MediaDeviceInfoLike[];
    next: () => void;
    streamStatus: UserMediaStatus;
    no: () => void;
    help: () => void;
    accessibilityHelpExpanded: boolean;
}> = ({devices, next, streamStatus, no, help, accessibilityHelpExpanded}) => {
    const [savedAudioOutput, setSavedAudioOutput] = useConfig('audioOutput');
    const isTouchDevice = useTouchDevice();

    const {selectedAudioOutput} = useSelectAudioOutput(
        devices,
        savedAudioOutput,
    );
    const {setVideoInputError, audioInputError, setAudioInputError} =
        useDeviceErrorMessageState();
    useDeviceErrorMessage(setAudioInputError, setVideoInputError, streamStatus);

    const {analyzer, outputStream, toggle, mediaElement} =
        useMediaElementAnalyzer();
    const playbackFrequencyData = useByteFrequencyData(analyzer, BARS_NUMBER);

    useAnimateFrequencyBars({
        currentStage: TestYourMicStages.PlayingBack,
        playbackFrequencyData,
        barsWrapperId: BARS_WRAPPER_ID,
    });

    const onAudioOutputChange = useCallback(
        (device: MediaDeviceInfoLike) => {
            setSavedAudioOutput(device, true);
        },
        [setSavedAudioOutput],
    );

    return (
        <>
            <SpeakerStep
                help={help}
                device={selectedAudioOutput}
                deviceError={audioInputError}
                devices={findAudioOutputDevices(devices)}
                no={no}
                onDeviceChange={onAudioOutputChange}
                yes={next}
                isTouchDevice={isTouchDevice}
                accessibilityHelpExpanded={accessibilityHelpExpanded}
            >
                <AspectRatio>
                    <Audio
                        srcObject={outputStream}
                        sinkId={selectedAudioOutput?.deviceId || ''}
                        loop
                        autoPlay
                    />
                    <FrequencyBarsAudioMeterWithContent
                        barsWrapperId={BARS_WRAPPER_ID}
                    >
                        <SpeakerToggleButton
                            toggle={toggle}
                            paused={mediaElement.paused}
                        />
                    </FrequencyBarsAudioMeterWithContent>
                </AspectRatio>
            </SpeakerStep>
        </>
    );
};
