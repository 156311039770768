import {useTranslation} from 'react-i18next';
import React, {useEffect} from 'react';

import type {ButtonProps} from '@pexip/components';
import {Icon, IconTypes} from '@pexip/components';
import {isMobileDevice, InMeetingInputControl} from '@pexip/media-components';

import type {LiveCaptionsAPI} from '../hooks/useLiveCaptions';
import {initLiveCaptionsPreference} from '../hooks/useLiveCaptions';
import {TestId} from '../../test/testIds';

export const LiveCaptionsToggle: React.FC<
    ButtonProps & {
        captions: LiveCaptionsAPI;
    }
> = ({captions, ...props}) => {
    const {t} = useTranslation();
    const toggleLiveCaptionsLabel = captions.isEnabled
        ? t('common.live-captions-hide', 'Hide live captions')
        : t('common.live-captions-show', 'Show live captions');

    useEffect(() => {
        // Enable Live Captions on init if 'prefersLiveCaptions' config is true
        initLiveCaptionsPreference();
    }, []);

    return (
        <InMeetingInputControl
            icon={
                <Icon
                    source={IconTypes.IconLiveCaption}
                    size={isMobileDevice() ? 'medium' : 'small'}
                />
            }
            tooltipText={toggleLiveCaptionsLabel}
            aria-label={toggleLiveCaptionsLabel}
            data-testid={TestId.ToggleLiveCaptions}
            onClick={() => captions.toggleCaptions()}
            isActive={captions.isEnabled}
            {...props}
        />
    );
};
