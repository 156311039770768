import React, {useMemo} from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {
    Box,
    Button,
    FontVariant,
    ThemeProvider,
    ToggleSwitch,
    Text,
    TextHeading,
    useMdDown,
} from '@pexip/components';

import styles from './AccessibilityDeviceDetection.module.scss';

export const AccessibilityDeviceDetectionView: React.FC<{
    currentStepIndex: number;
    next: () => () => void;
    handleSwitchToggle: () => void;
    prefersLiveCaptionsOn: boolean;
    isSelectSupported?: boolean;
}> = ({
    currentStepIndex,
    next,
    handleSwitchToggle,
    prefersLiveCaptionsOn,
    isSelectSupported,
}) => {
    const {t} = useTranslation();

    const cameraContent = useMemo(
        () => ({
            key: 'camera',
            title: 'Camera detected',
            message: [
                t(
                    'preflight.accessibility.camera-detected-1',
                    'We can detect that your camera is capturing image. However, we are unable to confirm that you are in the frame.',
                ),
            ],
        }),
        [t],
    );
    const speakerContent = useMemo(
        () => ({
            key: 'speaker',
            title: 'Speakers detected',
            message: [
                isSelectSupported
                    ? t(
                          'preflight.accessibility.speakers-detected-1-chrome',
                          'Your device is connected to speakers. You can check that the correct speakers are selected in the dropdown above.',
                      )
                    : t(
                          'preflight.accessibility.speakers-detected-1',
                          'Your device is connected to speakers.',
                      ),
                t(
                    'preflight.accessibility.speakers-detected-2',
                    'Live captions can be turned on by default if your meeting supports this feature. You can turn live captions on or off at anytime during your meeting.',
                ),
            ],
        }),
        [t, isSelectSupported],
    );

    const microphoneContent = useMemo(
        () => ({
            key: 'microphone',
            title: 'Microphone detected',
            message: [
                t(
                    'preflight.accessibility.microphone-detected-1',
                    'We can detect sound from your microphone.',
                ),
                t(
                    'preflight.accessibility.microphone-detected-2',
                    'Live captions can be turned on by default if your meeting supports this feature. You can turn live captions on or off at anytime during your meeting.',
                ),
            ],
        }),
        [t],
    );

    const stepContent = () => {
        switch (currentStepIndex) {
            case 0:
                return (
                    <Content
                        text={cameraContent}
                        showToggleSwitch={false}
                        handleSwitchToggle={handleSwitchToggle}
                        prefersLiveCaptionsOn={prefersLiveCaptionsOn}
                    />
                );

            case 1:
                return (
                    <Content
                        text={speakerContent}
                        showToggleSwitch={true}
                        handleSwitchToggle={handleSwitchToggle}
                        prefersLiveCaptionsOn={prefersLiveCaptionsOn}
                    />
                );

            case 2:
                return (
                    <Content
                        text={microphoneContent}
                        showToggleSwitch={true}
                        handleSwitchToggle={handleSwitchToggle}
                        prefersLiveCaptionsOn={prefersLiveCaptionsOn}
                    />
                );
        }
    };

    return (
        <ThemeProvider colorScheme="light">
            <Box
                isWithShadow={false}
                padding="none"
                className={cx('mt-8', styles.heightFull)}
            >
                <div className={cx([useMdDown() ? styles.mobile : 'block'])}>
                    {stepContent()}
                    <Button
                        className="mt-10"
                        modifier="fullWidth"
                        onClick={next()}
                    >
                        <Trans t={t} i18nKey="common.next">
                            Next
                        </Trans>
                    </Button>
                </div>
            </Box>
        </ThemeProvider>
    );
};

const Content: React.FC<{
    text: {title: string; message: string[]; key: string};
    showToggleSwitch: boolean;
    handleSwitchToggle: () => void;
    prefersLiveCaptionsOn: boolean;
}> = ({text, showToggleSwitch, prefersLiveCaptionsOn, handleSwitchToggle}) => {
    const {t} = useTranslation();

    return (
        <div
            className={cx([
                styles.borderRadius,
                useMdDown() ? '' : styles.desktopContainer,
            ])}
        >
            <TextHeading htmlTag="h5" fontVariant={FontVariant.BodyBold}>
                {t(`media.${text.key}-detected`, text.title)}
            </TextHeading>

            {text?.message?.map((message, index) => (
                <Text
                    className="mt-3"
                    htmlTag="p"
                    fontVariant={FontVariant.Body}
                    key={index}
                >
                    {t(`media.${text.key}-detected-message`, message)}
                </Text>
            ))}

            {showToggleSwitch && (
                <div className={cx('mt-7', styles.borderTop)}>
                    <ToggleSwitch
                        className="mt-7"
                        label={t(
                            'common.live-captions-default-on',
                            'Turn on live captions by default',
                        )}
                        labelPosition="start"
                        labelModifier="spaced"
                        labelFontWeight="bold"
                        checked={prefersLiveCaptionsOn}
                        name="live-captions"
                        onChange={handleSwitchToggle}
                    />
                </div>
            )}
        </div>
    );
};
