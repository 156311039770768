import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Bar,
    Button,
    FontVariant,
    Form,
    Input,
    Text,
    TextHeading,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

// TODO: should this be more generic as similar to FreeDial and EnterPin
export const EnterExtension: React.FC<{
    errorText?: string;
    onSubmit: ({conferenceExtension}: {conferenceExtension: string}) => void;
}> = ({errorText, onSubmit}) => {
    const {t} = useTranslation();
    const [conferenceExtension, setConferenceExtension] = useState('');

    return (
        <>
            <TextHeading
                htmlTag="h2"
                fontVariant={FontVariant.H4}
                className="mb-2"
            >
                <Trans t={t} i18nKey="common.welcome">
                    Welcome
                </Trans>
            </TextHeading>
            <Text fontVariant={FontVariant.H6} variant="tinted">
                <Trans t={t} i18nKey="free-dial.how-to-find">
                    You can find the Meeting ID in the meeting invite in your
                    calendar or email
                </Trans>
            </Text>
            <Form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    onSubmit({conferenceExtension});
                }}
            >
                <Input
                    autoComplete="number"
                    className="my-4"
                    hasError={Boolean(errorText)}
                    errorText={errorText}
                    label={t('free-dial.meeting-id', 'Meeting ID')}
                    name="extension"
                    placeholder={t('pin.placeholder', `e.g. '1234'`)}
                    type="text"
                    value={conferenceExtension}
                    onValueChange={setConferenceExtension}
                    data-testid={TestId.InputExtension}
                />

                <Bar>
                    <Button
                        modifier="fullWidth"
                        type="submit"
                        size="large"
                        data-testid={TestId.ButtonSetExtension}
                    >
                        <Trans t={t} i18nKey="common.next">
                            Next
                        </Trans>
                    </Button>
                </Bar>
            </Form>
        </>
    );
};
