import React from 'react';

import {Divider} from '@pexip/components';

import styles from './PanelHeaderWrapper.module.scss';

export const PanelHeaderWrapper: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => (
    <div>
        <div className={styles.title}>{children}</div>
        <Divider className="mt-4" />
    </div>
);
