import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {Button, Icon, IconTypes, useMobileDevice} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {DeviceError} from '@pexip/media-components';
import {
    AspectRatioSelfview,
    currentBrowserName,
    getLearnAboutRequestPermissionsUrl,
    TestId as MediaTestId,
    MissingDeviceAlert,
    DeviceSelect,
} from '@pexip/media-components';
import {
    UserMediaStatus,
    areBothGranted,
    isGrantedOnlyVideo,
} from '@pexip/media';

import {TestId} from '../../../test/testIds';
import {StepActions} from '../StepActions/StepActions.view';
import {StepLayout} from '../StepLayout/StepLayout.view';

import styles from './CameraStep.module.scss';

export const CameraStep: React.FC<{
    device?: MediaDeviceInfoLike;
    deviceError: DeviceError;
    devices: MediaDeviceInfoLike[];
    isTouchDevice?: boolean;
    isMirrored: boolean;
    yes: () => void;
    no: () => void;
    help: () => void;
    onDeviceChange: (device: MediaDeviceInfoLike) => void;
    stream?: MediaStream;
    streamStatus?: UserMediaStatus;
    accessibilityHelpExpanded: boolean;
    toggleFacingMode: () => void;
}> = ({
    device,
    deviceError,
    devices,
    isTouchDevice,
    isMirrored,
    yes,
    no,
    help,
    onDeviceChange,
    stream,
    streamStatus,
    accessibilityHelpExpanded,
    toggleFacingMode,
}) => {
    const {t} = useTranslation();
    const [showErrorTooltip, setShowErrorTooltip] = useState(false);

    const isMobileDevice = useMobileDevice();

    const shouldShowUserAvatar =
        (!!streamStatus &&
            (areBothGranted(streamStatus) ||
                isGrantedOnlyVideo(streamStatus))) ||
        streamStatus !== UserMediaStatus.InitialPermissionsNotGranted;

    const learnHowToGrantAccessURL =
        getLearnAboutRequestPermissionsUrl(currentBrowserName);

    return (
        <StepLayout
            startContent={
                <>
                    <div className={cx('mb-3', styles.selfViewWrapper)}>
                        <AspectRatioSelfview
                            isVideoInputMuted={false}
                            shouldShowUserAvatar={shouldShowUserAvatar}
                            localMediaStream={stream}
                            isMobileDevice={isTouchDevice || false}
                            username=""
                            aspectRatioType="camera-step"
                            isMirrored={isMirrored}
                        >
                            {isMobileDevice && (
                                <div className="d-flex justify-content-center mb-3">
                                    <Button
                                        aria-label={t(
                                            'media.flip-camera',
                                            'Flip camera',
                                        )}
                                        data-testid={TestId.ButtonFlipCamera}
                                        modifier="square"
                                        onClick={toggleFacingMode}
                                        size={
                                            isMobileDevice ? 'small' : 'medium'
                                        }
                                        variant="translucent"
                                        colorScheme="dark"
                                    >
                                        <Icon
                                            source={IconTypes.IconFlipCamera}
                                            size={
                                                isMobileDevice
                                                    ? 'small'
                                                    : 'medium'
                                            }
                                        />
                                    </Button>
                                </div>
                            )}
                        </AspectRatioSelfview>
                    </div>
                    {!isTouchDevice ? (
                        deviceError.deniedDevice ||
                        (deviceError.title && !devices.length) ? (
                            <MissingDeviceAlert
                                className="mb-1"
                                title={deviceError.title}
                                tooltip={deviceError.description}
                                deniedDevice={deviceError.deniedDevice}
                                learnHowToGrantAccessURL={
                                    learnHowToGrantAccessURL
                                }
                                data-testid={
                                    MediaTestId.MissingVideoDeviceAlert
                                }
                                setIsTooltipVisible={setShowErrorTooltip}
                                isTooltipVisible={showErrorTooltip}
                                icon={IconTypes.IconVideoOn}
                            />
                        ) : (
                            <DeviceSelect
                                isDisabled={false}
                                label={t(
                                    'settings.device-select.select-camera',
                                    'Select camera',
                                )}
                                data-testid={MediaTestId.SelectVideoInput}
                                errorText={deviceError.title}
                                errorTextTestId={
                                    MediaTestId.SelectVideoInputErrorText
                                }
                                hasError={!!deviceError.title}
                                onDeviceChange={onDeviceChange}
                                iconType={IconTypes.IconVideoOn}
                                mediaDeviceInfoLike={device}
                                devices={devices}
                            />
                        )
                    ) : null}
                </>
            }
            endContent={
                <StepActions
                    yes={yes}
                    no={no}
                    help={help}
                    headerTranslation={
                        <Trans t={t} i18nKey="camera.do-you-see-yourself">
                            Do you see yourself?
                        </Trans>
                    }
                    headerTestid={TestId.StepHeaderCamera}
                    helpButtonTranslation={
                        <Trans t={t} i18nKey="camera.cant-see">
                            I can&apos;t see myself due to a sight disability
                        </Trans>
                    }
                    noTestId={TestId.ButtonCameraStepNo}
                    yesTestId={TestId.ButtonCameraStepYes}
                    helpTestId={TestId.ButtonCameraStepCantSee}
                    accessibilityHelpExpanded={accessibilityHelpExpanded}
                />
            }
        />
    );
};

export type CameraStepProps = React.ComponentProps<typeof CameraStep>;
