import type {RenderEffects, SegmentationModel} from '@pexip/media-processor';

/**
 * Video Processor Params
 */
export const FRAME_RATE = 30;
export const FOREGROUND_THRESHOLD = 0.5; // Between 0 and 1
export const BACKGROUND_BLUR_AMOUNT = 3; // Between 1 and 20
export const EDGE_BLUR_AMOUNT = 3; // Between 0 and 20
export const FLIP_HORIZONTAL = false;
export const PROCESSING_WIDTH = 768;
export const PROCESSING_HEIGHT = 432;
export const SETTINGS_PROCESSING_WIDTH = 256;
export const SETTINGS_PROCESSING_HEIGHT = 144;
export const RENDER_EFFECTS: RenderEffects = 'none';
export const SEGMENTATION_MODEL: SegmentationModel = 'mediapipeSelfie';
export const BG_IMAGE_URL = 'assets/background.jpg';
export const SELFIE_JS_FILE_NAME = 'selfie_segmentation.js';

/**
 * Audio Processor Params
 */
export const UPDATE_FREQUENCY_HZ = 0.5;
export const VAD_THROTTLE_MS = 3000;
export const SILENT_DETECTION_DURATION_S = 4.0;

/**
 * Fast Fourier transform
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/fftSize
 */
export const FFT_SIZE = 2048;
export const VISUALIZER_FFT_SIZE = 1024;

/**
 * Bandwidth
 */
export const INITIAL_BANDWIDTH = '';
export const BANDWIDTHS = ['576', '1264', '2464', '6144'] as const;

/**
 * Test your microphone
 */
export const WAIT_TIME_BEFORE_PLAYBACK = 1000;
export const BARS_NUMBER = 20;

/**
 * Chat
 */
export const CHARACTER_LIMIT = 10_000;

/**
 * Version
 */
export const VERSION = process.env.BUILD_HASH ?? 'BUILD_HASH missing';
export const DIRTY = process.env.BUILD_DIRTY ? '*' : '';

/**
 * Docs
 */
export const ERROR_MESSAGES_URL =
    'https://docs.pexip.com/end_user/guide_for_admins/error_messages.htm';

/** Pexip URLs
 */
export const PEXIP_HELP_PAGE_URL = 'https://help.pexip.com/';
export const TERMS_URL = 'https://www.pexip.com/terms';

/**
 * Custom branding
 */
export const SHADES = [
    50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
] as const;

export const EXPRESS_PATH = '/express';
export const STEP_BY_STEP_PATH = '/step-by-step';
