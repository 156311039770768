import React from 'react';
import type {SyntheticEvent} from 'react';

import type {ModalProps} from '../Modal/Modal';
import {Modal} from '../Modal/Modal';
import {List} from '../List/List';
import {Row} from '../List/Row';
import {TextHeading} from '../../elements/TextHeading/TextHeading';
import {TextLink} from '../../elements/TextLink/TextLink';
import {Divider} from '../../elements/Divider/Divider';
import {Text} from '../../elements/Text/Text';
import {withColorScheme} from '../../../themes/ThemeContext';
import {FontVariant} from '../../../../design-tokens/constants';
import {TestId} from '../../../utils/testIds';

export const AboutText: React.FC<{
    enhancerEnd?: React.ReactChild;
    version: string;
}> = ({enhancerEnd, version}) => (
    <List className="ml-3 mr-3 mb-2">
        <Row>
            <TextHeading
                className="mb-3 mt-2"
                fontVariant={FontVariant.H5}
                htmlTag="h1"
            >
                About Pexip
            </TextHeading>
        </Row>

        <Row spacing="small">
            <TextLink
                href="https://www.pexip.com/privacy"
                target="_blank"
                data-testid={TestId.PrivacyPolicyLink}
            >
                Privacy Policy
            </TextLink>
        </Row>

        <Row spacing="small">
            <TextLink
                href="https://www.pexip.com/terms"
                target="_blank"
                data-testid={TestId.TermsOfServiceLink}
            >
                Terms of Service
            </TextLink>
        </Row>

        <Divider className="mt-5 mb-3" />
        <Text data-testid={TestId.Version}>Version: {version}</Text>
        {enhancerEnd}
    </List>
);

const ModalLight = withColorScheme(Modal, 'light');

export const AboutModal: React.FC<
    ModalProps & {
        isOpen: boolean;
        onClose: (e: SyntheticEvent) => void;
        version: string;
    }
> = ({className, isOpen = false, onClose, version, ...props}) => {
    return (
        <ModalLight
            padding="none"
            onClose={onClose}
            isOpen={isOpen}
            withCloseButton
            className={className}
            {...props}
        >
            <AboutText version={version} />
        </ModalLight>
    );
};
