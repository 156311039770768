export const validate200 = validate13;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema12.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath: '#/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
export const validate403 = validate14;
const schema18 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {type: 'string', enum: ['Invalid token']},
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [{$ref: '#/properties/403/definitions/403'}],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {type: 'string', enum: ['Invalid token']},
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate14(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema13.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (!(data1 === 'Invalid token')) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema13.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath: '#/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate14.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate14.errors = vErrors;
    return errors === 0;
}
export const validateBody = validate15;
const schema20 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                action: {
                    type: 'string',
                    enum: ['start', 'stop', 'continue'],
                    description: 'Either `start`, `stop`, or `continue`.',
                },
                target: {
                    type: 'string',
                    description:
                        'UUID of the target participant (from the participant list). Leave undefined for a gateway call.',
                },
                movement: {
                    type: 'array',
                    items: {$ref: '#/properties/Body/definitions/Movement'},
                },
            },
            required: ['action', 'target', 'movement'],
            title: 'TopLevel',
        },
        Movement: {
            type: 'object',
            additionalProperties: false,
            properties: {
                axis: {
                    type: 'string',
                    enum: ['pan', 'tilt', 'zoom'],
                    description: 'Either `pan`, `tilt`, or `zoom`.',
                },
                direction: {
                    type: 'string',
                    enum: ['left', 'right', 'up', 'down', 'in', 'out'],
                    description:
                        'Use `left`, `right` for pan; `up`, `down` for tilt; or `in`, `out` for zoom.',
                },
                timeout: {
                    type: 'number',
                    description:
                        'The duration for which to send the signal. Recommended values are 1000 (1 second) for initial `start` message; 200 for `continue` messages.',
                },
            },
            required: ['axis', 'direction'],
            title: 'Endpoint',
        },
    },
    oneOf: [{$ref: '#/properties/Body/definitions/TopLevel'}],
};
const schema14 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        action: {
            type: 'string',
            enum: ['start', 'stop', 'continue'],
            description: 'Either `start`, `stop`, or `continue`.',
        },
        target: {
            type: 'string',
            description:
                'UUID of the target participant (from the participant list). Leave undefined for a gateway call.',
        },
        movement: {
            type: 'array',
            items: {$ref: '#/properties/Body/definitions/Movement'},
        },
    },
    required: ['action', 'target', 'movement'],
    title: 'TopLevel',
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        axis: {
            type: 'string',
            enum: ['pan', 'tilt', 'zoom'],
            description: 'Either `pan`, `tilt`, or `zoom`.',
        },
        direction: {
            type: 'string',
            enum: ['left', 'right', 'up', 'down', 'in', 'out'],
            description:
                'Use `left`, `right` for pan; `up`, `down` for tilt; or `in`, `out` for zoom.',
        },
        timeout: {
            type: 'number',
            description:
                'The duration for which to send the signal. Recommended values are 1000 (1 second) for initial `start` message; 200 for `continue` messages.',
        },
    },
    required: ['axis', 'direction'],
    title: 'Endpoint',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.action === undefined && (missing0 = 'action')) ||
                (data.target === undefined && (missing0 = 'target')) ||
                (data.movement === undefined && (missing0 = 'movement'))
            ) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'action' ||
                            key0 === 'target' ||
                            key0 === 'movement'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.action !== undefined) {
                        let data0 = data.action;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/action',
                                    schemaPath: '#/properties/action/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (
                            !(
                                data0 === 'start' ||
                                data0 === 'stop' ||
                                data0 === 'continue'
                            )
                        ) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/action',
                                    schemaPath: '#/properties/action/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema14.properties.action.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.target !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.target !== 'string') {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath + '/target',
                                        schemaPath: '#/properties/target/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.movement !== undefined) {
                                let data2 = data.movement;
                                const _errs6 = errors;
                                if (errors === _errs6) {
                                    if (Array.isArray(data2)) {
                                        var valid1 = true;
                                        const len0 = data2.length;
                                        for (let i0 = 0; i0 < len0; i0++) {
                                            let data3 = data2[i0];
                                            const _errs8 = errors;
                                            const _errs9 = errors;
                                            if (errors === _errs9) {
                                                if (
                                                    data3 &&
                                                    typeof data3 == 'object' &&
                                                    !Array.isArray(data3)
                                                ) {
                                                    let missing1;
                                                    if (
                                                        (data3.axis ===
                                                            undefined &&
                                                            (missing1 =
                                                                'axis')) ||
                                                        (data3.direction ===
                                                            undefined &&
                                                            (missing1 =
                                                                'direction'))
                                                    ) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/movement/' +
                                                                    i0,
                                                                schemaPath:
                                                                    '#/properties/Body/definitions/Movement/required',
                                                                keyword:
                                                                    'required',
                                                                params: {
                                                                    missingProperty:
                                                                        missing1,
                                                                },
                                                                message:
                                                                    "must have required property '" +
                                                                    missing1 +
                                                                    "'",
                                                            },
                                                        ];
                                                        return false;
                                                    } else {
                                                        const _errs11 = errors;
                                                        for (const key1 in data3) {
                                                            if (
                                                                !(
                                                                    key1 ===
                                                                        'axis' ||
                                                                    key1 ===
                                                                        'direction' ||
                                                                    key1 ===
                                                                        'timeout'
                                                                )
                                                            ) {
                                                                delete data3[
                                                                    key1
                                                                ];
                                                            }
                                                        }
                                                        if (
                                                            _errs11 === errors
                                                        ) {
                                                            if (
                                                                data3.axis !==
                                                                undefined
                                                            ) {
                                                                let data4 =
                                                                    data3.axis;
                                                                const _errs12 =
                                                                    errors;
                                                                if (
                                                                    typeof data4 !==
                                                                    'string'
                                                                ) {
                                                                    validate11.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/movement/' +
                                                                                    i0 +
                                                                                    '/axis',
                                                                                schemaPath:
                                                                                    '#/properties/Body/definitions/Movement/properties/axis/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                if (
                                                                    !(
                                                                        data4 ===
                                                                            'pan' ||
                                                                        data4 ===
                                                                            'tilt' ||
                                                                        data4 ===
                                                                            'zoom'
                                                                    )
                                                                ) {
                                                                    validate11.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/movement/' +
                                                                                    i0 +
                                                                                    '/axis',
                                                                                schemaPath:
                                                                                    '#/properties/Body/definitions/Movement/properties/axis/enum',
                                                                                keyword:
                                                                                    'enum',
                                                                                params: {
                                                                                    allowedValues:
                                                                                        schema15
                                                                                            .properties
                                                                                            .axis
                                                                                            .enum,
                                                                                },
                                                                                message:
                                                                                    'must be equal to one of the allowed values',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid3 =
                                                                    _errs12 ===
                                                                    errors;
                                                            } else {
                                                                var valid3 =
                                                                    true;
                                                            }
                                                            if (valid3) {
                                                                if (
                                                                    data3.direction !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data3.direction;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        typeof data5 !==
                                                                        'string'
                                                                    ) {
                                                                        validate11.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/movement/' +
                                                                                        i0 +
                                                                                        '/direction',
                                                                                    schemaPath:
                                                                                        '#/properties/Body/definitions/Movement/properties/direction/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    if (
                                                                        !(
                                                                            data5 ===
                                                                                'left' ||
                                                                            data5 ===
                                                                                'right' ||
                                                                            data5 ===
                                                                                'up' ||
                                                                            data5 ===
                                                                                'down' ||
                                                                            data5 ===
                                                                                'in' ||
                                                                            data5 ===
                                                                                'out'
                                                                        )
                                                                    ) {
                                                                        validate11.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/movement/' +
                                                                                        i0 +
                                                                                        '/direction',
                                                                                    schemaPath:
                                                                                        '#/properties/Body/definitions/Movement/properties/direction/enum',
                                                                                    keyword:
                                                                                        'enum',
                                                                                    params: {
                                                                                        allowedValues:
                                                                                            schema15
                                                                                                .properties
                                                                                                .direction
                                                                                                .enum,
                                                                                    },
                                                                                    message:
                                                                                        'must be equal to one of the allowed values',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data3.timeout !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs16 =
                                                                            errors;
                                                                        if (
                                                                            !(
                                                                                typeof data3.timeout ==
                                                                                'number'
                                                                            )
                                                                        ) {
                                                                            validate11.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/movement/' +
                                                                                            i0 +
                                                                                            '/timeout',
                                                                                        schemaPath:
                                                                                            '#/properties/Body/definitions/Movement/properties/timeout/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'number',
                                                                                        },
                                                                                        message:
                                                                                            'must be number',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs16 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    validate11.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/movement/' +
                                                                i0,
                                                            schemaPath:
                                                                '#/properties/Body/definitions/Movement/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'object',
                                                            },
                                                            message:
                                                                'must be object',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                            var valid1 = _errs8 === errors;
                                            if (!valid1) {
                                                break;
                                            }
                                        }
                                    } else {
                                        validate11.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/movement',
                                                schemaPath:
                                                    '#/properties/movement/type',
                                                keyword: 'type',
                                                params: {type: 'array'},
                                                message: 'must be array',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
