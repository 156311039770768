import {useEffect} from 'react';

import {TestYourMicStages, useAnalyzer} from '@pexip/media-components';

import {useByteFrequencyData} from '../hooks/useAnimateFrequencyBars';
import {BARS_NUMBER, FFT_SIZE, WAIT_TIME_BEFORE_PLAYBACK} from '../constants';
import {useLocalMedia} from '../services/Media.service';
import {useMediaElementAnalyzer} from '../hooks/useMediaElementAnalyzer';

export const useTestMicrophoneAnalyzers = (
    setTestYourMicStages: (
        value: React.SetStateAction<TestYourMicStages>,
    ) => void,
    setRecordedStream: React.Dispatch<
        React.SetStateAction<MediaStream | undefined>
    >,
    objectURL?: string,
) => {
    const {
        analyzer: playbackAnalyzer,
        outputStream,
        mediaElement,
    } = useMediaElementAnalyzer({
        source: objectURL,
        loop: false,
        waitTimeBeforePlayback: WAIT_TIME_BEFORE_PLAYBACK,
    });

    useEffect(() => {
        if (outputStream) {
            setRecordedStream(outputStream);
        }
    }, [outputStream, setRecordedStream]);

    useEffect(() => {
        let timeout: number;

        if (objectURL !== '') {
            timeout = window.setTimeout(() => {
                setTestYourMicStages(TestYourMicStages.PlayingBack);
                clearTimeout(timeout);
            }, WAIT_TIME_BEFORE_PLAYBACK);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [objectURL, setTestYourMicStages]);

    useEffect(() => {
        if (mediaElement.ended === true) {
            setTestYourMicStages(TestYourMicStages.PlaybackFinished);
        }
    }, [mediaElement.ended, setTestYourMicStages]);

    const media = useLocalMedia();

    const microphoneAnalyzer = useAnalyzer(media, FFT_SIZE);

    const microphoneFrequencyData = useByteFrequencyData(
        microphoneAnalyzer,
        BARS_NUMBER,
    );
    const playbackFrequencyData = useByteFrequencyData(
        playbackAnalyzer,
        BARS_NUMBER,
    );

    return {
        microphoneFrequencyData,
        playbackFrequencyData,
    };
};
