type LogMethod = (meta: unknown, message?: string) => void;

/**
 * Copied and modified from \@pexip/logger
 */
interface Logger {
    trace: LogMethod;
    debug: LogMethod;
    info: LogMethod;
    warn: LogMethod;
    error: LogMethod;
}

// TODO: Log to console by default?
const noopLogger: Logger = Object.freeze({
    trace() {},
    debug() {},
    info() {},
    warn() {},
    error() {},
});

let logger = noopLogger;

/**
 * @internal
 */
export {logger};

/**
 * Set the global logger for the media-control module
 * @param newLogger - The logger
 * @returns The old logger
 * @beta
 */
export function setLogger(newLogger: Logger): Logger {
    const oldLogger = logger;
    logger = newLogger;
    return oldLogger;
}
