import type {Signal} from '@pexip/signal';
import type {Backoff} from '@pexip/utils';
import type {
    MessageMap,
    ConferenceStatusMap,
    ParticipantsMap,
    TransformLayoutMap,
    FeccParticipantMap,
    DialMap,
    InfinityErrorMessage,
    InfinityErrorCode,
    TransferParticipantMap,
    unlockParticipant,
    disconnect,
    disconnectParticipant,
    dial,
    muteParticipant,
    unmuteParticipant,
    muteguests,
    unmuteguests,
    videoMuteParticipant,
    videoUnmuteParticipant,
    lock,
    unlock,
    transformLayout,
    message,
    buzzParticipant,
    clearbuzzParticipant,
    spotlightonParticipant,
    spotlightoffParticipant,
    ack,
    update,
    newCandidate,
    callsWebrtcParticipant,
    takeFloor,
    releaseFloor,
    transferParticipant,
    CallsWebrtcParticipantMap,
} from '@pexip/infinity-api';
import type {
    AudioQualityStats,
    InboundAudioMetrics,
    InboundVideoMetrics,
    OutboundAudioMetrics,
    OutboundVideoMetrics,
    Quality,
    StatsCollector,
} from '@pexip/peer-connection-stats';
import type {
    Idp,
    RedirectIdp,
    requestToken,
    RequestTokenMap,
} from '@pexip/infinity-api/dist/token/requestToken';
import type {MainPeerConnectionOptions} from '@pexip/peer-connection';

export type MessageBody = MessageMap['Body'];
export type ConferenceStateEvent = ConferenceStatusMap['200']['result'];
export type RTCParticipantEvent = ParticipantsMap['200']['result'][0];
export type Layout = TransformLayoutMap['Body']['transforms']['layout'];
export type FeccEvent = FeccParticipantMap['Body'];
export type Role = TransferParticipantMap['Body']['role'];
export type Stun = RequestTokenMap['200']['result']['stun'];
export type Turn = RequestTokenMap['200']['result']['turn'];

export interface RequestClient {
    token: string;
    expires: number;

    fetcher: typeof fetch;
    refreshToken: () => Promise<boolean>;
    cleanup: (reason?: DisconnectReason) => Promise<void>;
}

export interface RequestClientOptions {
    conferenceAlias: string;
    backoff?: Backoff;
    expires?: number;
    fetcher?: typeof fetch;
    token: string;
    host: string;
    tokenExpiredCb?: () => void;
}

export interface Client {
    participants: Participant[];
    conferenceStatus?: ConferenceStatus;
    conferenceFeatureFlags?: ConferenceFeatureFlags;
    me?: Participant;

    admit: (opt: {
        participantUuid: string;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof unlockParticipant>>>;

    call: (opt: {
        conferenceAlias: string;
        displayName: string;
        bandwidth: number;
        packetizationMode?: boolean;
        mediaStream?: MediaStream;
        node?: string;
        host?: string;
        pin?: string;
        chosenIdp?: string;
        ssoToken?: string;
        token?: string;
        conferenceExtension?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof requestToken>>>;

    disconnect: (opt: {
        callUuid?: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        reason?: DisconnectReason;
        callback?: () => void;
        release?: () => Promise<void>;
    }) => Promise<void>;

    kick: (opt: {
        participantUuid: string;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<
        undefined | Awaited<ReturnType<typeof disconnectParticipant>>
    >;

    dial: (opt: {
        destination: string;
        role: DialMap['Body']['role'];
        protocol?: DialMap['Body']['protocol'];
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof dial>>>;

    transfer: (opt: {
        destination: string;
        pin: string;
        role: Role;
        participantUuid: string;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof transferParticipant>>>;

    mute: (opt: {
        mute: boolean;
        participantUuid?: string;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<ReturnType<typeof muteParticipant | typeof unmuteParticipant>>
    >;

    muteAllGuests: (opt: {
        mute: boolean;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<
        undefined | Awaited<ReturnType<typeof muteguests | typeof unmuteguests>>
    >;

    muteVideo: (opt: {
        muteVideo: boolean;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  typeof videoMuteParticipant | typeof videoUnmuteParticipant
              >
          >
    >;

    lock: (opt: {
        lock: boolean;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof lock | typeof unlock>>>;

    disconnectAll: (opt: {
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof disconnect>>>;

    setLayout: (opt: {
        layout: Layout;
        callUuid?: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof transformLayout>>>;

    sendMessage: (opt: {
        payload: string;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof message>>>;

    raiseHand: (opt: {
        raise: boolean;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<typeof buzzParticipant | typeof clearbuzzParticipant>
          >
    >;

    spotlight: (opt: {
        enable: boolean;
        participantUuid: string;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  typeof spotlightonParticipant | typeof spotlightoffParticipant
              >
          >
    >;

    ack: (opt: {
        callUuid?: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        sdp?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof ack>>>;

    update: (opt: {
        sdp: string;
        callUuid?: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof update>>>;

    newCandidate: (opt: {
        candidate: IceCandidate;
        callUuid?: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof newCandidate>>>;

    sendOffer: (opt: {
        sdp: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<
        undefined | Awaited<ReturnType<typeof callsWebrtcParticipant>>
    >;

    takeFloor: (opts: {
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof takeFloor>>>;

    releaseFloor: (opts: {
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof releaseFloor>>>;

    present: (stream?: MediaStream) => void;
    restartCall: (opts: {
        bandwidth: number;
        packetizationMode?: boolean;
        mediaStream?: MediaStream;
    }) => Promise<void>;
    stopPresenting: () => void;
    setStream: (stream: MediaStream) => void;
    setBandwidth: (bandwidth: number) => void;

    liveCaptions: (opt: {
        enable: boolean;
        conferenceAlias?: string;
        participantUuid?: string;
    }) => Promise<void>;

    setConferenceExtension: (conferenceExtension?: string) => void;
    setPin: (pin?: string) => void;
}

export type InfinityClient = Omit<
    Client,
    | 'ack'
    | 'update'
    | 'newCandidate'
    | 'sendOffer'
    | 'takeFloor'
    | 'releaseFloor'
>;

export type GetEndpointParams<K extends keyof Client> = Client[K] extends (
    ...args: never
) => unknown
    ? Parameters<Client[K]>
    : never;

export type GetEndpointResponse<K extends keyof Client> = Client[K] extends (
    ...args: never
) => infer R
    ? Awaited<R>
    : unknown;

export type EndpointResponse<K = keyof Client> = K extends keyof Client
    ? GetEndpointResponse<K>
    : never;

export interface CallOptions {
    sendOffer: (opt: {
        sdp: string;
    }) => Promise<GetEndpointResponse<'sendOffer'>>;

    ack: Client['ack'];

    newCandidate: (opt: {
        candidate: IceCandidate;
    }) => Promise<GetEndpointResponse<'newCandidate'>>;

    update: (opt: {sdp: string}) => Promise<GetEndpointResponse<'update'>>;

    takeFloor: Client['takeFloor'];
    releaseFloor: Client['releaseFloor'];

    getCurrentCallUuid: () => CallUuid;

    signals: InfinitySignals;
    eventSignals: EventSignals;
    callSignals: CallSignals;

    peerOptions: MainPeerConnectionOptions;
    dataChannelId?: number;

    mediaStream?: MediaStream;
}

export interface Call {
    presoState: PresoState;
    disconnect: () => void;
    present: (stream?: MediaStream) => Promise<void>;
    receivePresentation: () => Promise<void>;
    stopPresenting: () => void;
    stopReceivingPresentation: () => void;
    setStream: (stream: MediaStream) => void;
    setBandwidth: (bandwidth: number) => void;
    sendDataChannelEvent: (msg: DataChannelEvent) => void;
}

export interface PresoConnectionChangeEvent {
    send: RTCPeerConnectionState;
    recv: RTCPeerConnectionState;
}

export type ExtendedInfinityErrorCode =
    | InfinityErrorCode
    | '#pex128'
    | '#pex117';

export type ErrorSignal = Signal<{
    error: ExtendedInfinityErrorMessage;
    errorCode: ExtendedInfinityErrorCode;
}>;

export interface ClientSignals {
    onConnected: Signal<undefined>;
    onDisconnected: ErrorSignal;
    onAnswer: Signal<CallsWebrtcParticipantMap['200']['result']>;
    onPresentationAnswer: Signal<{
        sdp: string;
        callUuid: string;
        present: string;
    }>;
    onIceCandidate: Signal<RTCIceCandidate>;
    onFailedRequest: Signal<keyof Client>;
    onRetryQueueFlushed: Signal<undefined>;
}
export interface InfinitySignals extends ClientSignals {
    onError: ErrorSignal;
    onPinRequired: Signal<{hasHostPin: boolean; hasGuestPin: boolean}>;
    onIdp: Signal<Idp[]>;
    onRedirect: Signal<{redirectUrl: string; redirectIdp: RedirectIdp}>;
    onParticipants: Signal<Participant[]>;
    onParticipantJoined: Signal<Participant[]>;
    onParticipantLeft: Signal<Participant[]>;
    onMessage: Signal<Message>;
    onMe: Signal<Participant>;
    onRequestedLayout: Signal<{
        primaryScreen: {hostLayout: Layout; guestLayout: Layout};
    }>;
    onStage: Signal<Stage[]>;
    onConferenceStatus: Signal<ConferenceStatus>;
    onTransfer: Signal<{alias: string; token: string}>;
    onRaiseHand: Signal<Participant>;
    onLiveCaptions: Signal<{data: string; isFinal: boolean}>;
    onSplashScreen: Signal<SplashScreen>;
    onNewOffer: Signal<string>;
    onUpdateSdp: Signal<string>;
    onPeerDisconnect: Signal<undefined>;
    onExtension: Signal<string>;
}

export type InfinitySignalsOptional = Pick<
    Partial<InfinitySignals>,
    'onPinRequired' | 'onParticipants' | 'onError'
>;
export type InfinitySignalsRequired = Pick<
    InfinitySignals,
    'onConnected' | 'onAnswer' | 'onPresentationAnswer' | 'onDisconnected'
>;

export interface Stats {
    inbound: {
        audio?: InboundAudioMetrics;
        video?: InboundVideoMetrics;
    };
    outbound: {
        audio?: OutboundAudioMetrics;
        video?: OutboundVideoMetrics;
    };
}
export interface CallSignals {
    onRemoteStream: Signal<MediaStream>;
    onRemotePresentationStream: Signal<MediaStream>;
    onCallConnected: Signal<undefined>;
    onPresentationConnectionChange: Signal<PresoConnectionChangeEvent>;
    onRtcStats: Signal<Stats>;
    onCallQualityStats: Signal<{
        inbound: {audio: AudioQualityStats};
        outbound: {audio: AudioQualityStats};
    }>;
    onCallQuality: Signal<[Quality, Quality]>;
}

export type InfinityCallSignalsOptional = Pick<
    Partial<CallSignals>,
    'onCallConnected'
>;
export type InfinityCallRequired = Pick<
    CallSignals,
    | 'onRemoteStream'
    | 'onRemotePresentationStream'
    | 'onPresentationConnectionChange'
    | 'onRtcStats'
    | 'onCallQualityStats'
    | 'onCallQuality'
>;
export interface SplashScreen {
    text?: string;
    image?: string;
    audio?: string;
}
export interface EventSignals {
    onPresentationStart: Signal<string>;
    onPresentationStop: Signal<undefined>;
    onPresentationEnded: Signal<undefined>;
    onPresentationFrame: Signal<string>;
    onParticipantCreate: Signal<Participant>;
    onParticipantUpdate: Signal<Participant>;
    onParticipantDelete: Signal<string>;
    onMessage: Signal<MessageEvent>;
    onConferenceUpdate: Signal<ConferenceStatus>;
    onStageUpdate: Signal<StageEvent[]>;
    onLayoutUpdate: Signal<LayoutEvent>;
    onCallDisconnected: Signal<{call_uuid: string; reason: DisconnectEvent}>;
    onDisconnect: Signal<DisconnectEvent>;
    onParticipantSyncBegin: Signal<undefined>;
    onParticipantSyncEnd: Signal<undefined>;
    onRefer: Signal<{alias: string; token: string}>;
    onHold: Signal<boolean>;
    onFecc: Signal<FeccEvent>;
    onRefreshToken: Signal<undefined>;
    onLiveCaptions: Signal<LiveCaptionsEvent>;
    onPeerDisconnect: Signal<undefined>;
    onNewOffer: Signal<{sdp: string}>;
    onUpdateSdp: Signal<{sdp: string}>;
    onNewCandidate: Signal<IceCandidate>;
    onSplashScreen: Signal<SplashScreen>;
}

export type InfinityEventSignalsOptional = Pick<
    Partial<EventSignals>,
    'onHold' | 'onRefer' | 'onFecc' | 'onRefreshToken'
>;

export type SignalName =
    | keyof CallSignals
    | keyof EventSignals
    | keyof InfinitySignals;

export interface IceCandidate {
    candidate: string;
    mid: string;
    ufrag?: string;
}

export type ClientSideErrorMessage =
    | 'Could not reconnect to the meeting'
    | 'Could not execute critical network action'
    | 'Could not find ICE candidates'
    | 'WebRTC connection closed'
    | 'WebRTC connection failed';

export type ExtendedInfinityErrorMessage =
    | InfinityErrorMessage
    | ClientSideErrorMessage;

export interface DisconnectEvent {
    reason: InfinityErrorMessage;
}

export interface MessageEvent extends MessageBody {
    origin: string;
    uuid: string;
}

export interface StageEvent {
    participant_uuid: string;
    stage_index: number;
    vad: number;
}

export interface LayoutEvent {
    requested_layout: {
        primary_screen: {
            chair_layout: Layout;
            guest_layout: Layout;
        };
    };
    view: Layout;
    participants: string[];
}

export interface PresentationEvent {
    status?: 'start' | 'stop' | 'newframe';
    presenter_name?: string;
    presenter_uri?: string;
    lastEventId?: string;
}

export interface LiveCaptionsEvent {
    data: string;
    is_final: boolean;
}

export type EventsSourceType =
    | DisconnectEvent
    | MessageEvent
    | ConferenceStateEvent
    | PresentationEvent
    | RTCParticipantEvent
    | FeccEvent;

export enum CallType {
    audio = 'audio',
    video = 'video',
    api = 'api',
}
export interface Participant {
    callType: CallType;
    canControl: boolean;
    canDisconnect: boolean;
    canMute: boolean;
    canTransfer: boolean;
    canFecc: boolean;
    canRaiseHand: boolean;
    canSpotlight: boolean;
    displayName: string;
    handRaisedTime: number;
    identity: string;
    isCameraMuted: boolean;
    isEndpoint?: boolean;
    isExternal: boolean;
    isGateway: boolean;
    isHost: boolean;
    isMainVideoDroppedOut: boolean;
    isMuted: boolean;
    isPresenting: boolean;
    isSpotlight: boolean;
    isStreaming: boolean;
    isVideoSilent: boolean;
    isWaiting: boolean;
    needsPresentationInMix: boolean;
    protocol: RTCParticipantEvent['protocol'];
    raisedHand: boolean;
    role: RTCParticipantEvent['role'];
    rxPresentation: boolean;
    serviceType: RTCParticipantEvent['service_type'];
    spotlightOrder: number;
    startAt: Date;
    startTime: number;
    uri: string;
    uuid: string;
}

export interface Message {
    at: Date;
    id: string;
    message: string;
    displayName: string;
    userId: string;
}

export interface Stage {
    userId: string;
    stageIndex: number;
    vad: number;
}

export interface ConferenceStatus {
    locked: boolean;
    guestsMuted: boolean;
    started: boolean;
    liveCaptionsAvailable: boolean;
}

export interface ConferenceFeatureFlags {
    chatEnabled: boolean;
    guestsCanPresent: boolean;
    allow1080p: boolean;
    isDirectMedia: boolean;
    vp9Disabled: boolean;
}

export type CallUuid = string | undefined;

export interface PresoState {
    send: RTCPeerConnectionState;
    recv: RTCPeerConnectionState;
}

export interface StatsCollectors {
    inbound: {
        audio?: StatsCollector;
        video?: StatsCollector;
    };
    outbound: {
        audio?: StatsCollector;
        video?: StatsCollector;
    };
}

export type DataChannelEvent =
    | {
          type: 'message';
          body: MessageEvent;
      }
    | {
          type: 'fecc';
          body: unknown;
      };

export type DisconnectReason = 'Browser closed' | 'User initiated disconnect';
