import type {InfinityClient, Participant} from '@pexip/infinity';
import type {InMeetingParticipant} from '@pexip/media-components';

export const sortRaisedHands = (
    raisedHandParticipants: InMeetingParticipant[],
) =>
    raisedHandParticipants.sort(
        (p1, p2) =>
            (p1.handRaisedTime || Number.MAX_VALUE) -
            (p2.handRaisedTime || Number.MAX_VALUE),
    );

export const getParticipantListGroups = (
    participants: Participant[],
    infinityService: InfinityClient,
    selfActions: {toggleCamera: () => void},
) => {
    return participants.reduce(
        (groups, participant) => {
            const inMeetingParticipant = {
                ...participant,
                // FIXME: can't we just pass those fn directly instead of adding them for each participant (?)
                mute: () =>
                    void infinityService.mute({
                        participantUuid: participant.uuid,
                        mute: !participant.isMuted,
                    }),
                muteVideo: selfActions.toggleCamera,
                kick: () =>
                    void infinityService.kick({
                        participantUuid: participant.uuid,
                    }),
                admit: () =>
                    void infinityService.admit({
                        participantUuid: participant.uuid,
                    }),
                spotlight: () =>
                    void infinityService.spotlight({
                        enable: !participant.isSpotlight,
                        participantUuid: participant.uuid,
                    }),
                active: true,
            } as InMeetingParticipant;

            if (participant.raisedHand) {
                inMeetingParticipant.participantType = 'raise-hand';
                groups.raisedHand.push(inMeetingParticipant);
                sortRaisedHands(groups.raisedHand);
            } else if (participant.isWaiting) {
                inMeetingParticipant.participantType = 'admit';
                groups.waitingInLobby.unshift(inMeetingParticipant);
            } else if (participant.isExternal) {
                inMeetingParticipant.participantType = 'external';
                groups.external.unshift(inMeetingParticipant);
            } else {
                inMeetingParticipant.participantType = 'in-the-meeting';
                groups.inMeeting.unshift(inMeetingParticipant);
            }

            return groups;
        },
        {
            inMeeting: [] as InMeetingParticipant[],
            waitingInLobby: [] as InMeetingParticipant[],
            raisedHand: [] as InMeetingParticipant[],
            external: [] as InMeetingParticipant[],
        },
    );
};
