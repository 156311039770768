/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface ParticipantsMap {
    '200': Status200;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    result: Participant[];
}
export interface Participant {
    api_url?: string;
    /**
     * A Unix timestamp of when this participant raised their hand, otherwise zero.
     */
    buzz_time: number;
    /**
     * Either `in` or `out` as to whether this is an inbound or outbound call.
     */
    call_direction: 'in' | 'out';
    /**
     * An optional call tag that is assigned to this participant.
     */
    call_tag?: string;
    /**
     * Set to `YES` if the participant can be disconnected, `NO` if not.
     */
    disconnect_supported: 'YES' | 'NO';
    /**
     * The display name of the participant.
     */
    display_name: string;
    /**
     * `On` or `Off` as to whether this participant is connected via encrypted media.
     */
    encryption: 'On' | 'Off';
    /**
     * The UUID of an external node e.g. a Skype for Business / Lync meeting associated with an external participant. This allows grouping of external participants as the UUID will be the same for all participants associated with that external node.
     */
    external_node_uuid: string;
    /**
     * Set to `YES` if this participant can be sent FECC messages; `NO` if not.
     */
    fecc_supported: 'YES' | 'NO';
    /**
     * Boolean indicating whether the user has media capabilities.
     */
    has_media: boolean;
    /**
     * Set to `YES` if the call is audio only.
     */
    is_audio_only_call: 'YES' | 'NO';
    /**
     * Boolean indicating if it is an external participant, e.g. coming in from a Skype for Business / Lync meeting.
     */
    is_external: boolean;
    /**
     * Set to `YES` if the participant is administratively audio muted.
     */
    is_muted: 'YES' | 'NO';
    /**
     * Set to `YES` if the participant is the current presenter.
     */
    is_presenting: 'YES' | 'NO';
    /**
     * Boolean indicating whether this is a streaming/recording participant.
     */
    is_streaming_conference: boolean;
    /**
     * Set to `YES` if the call has video capability.
     */
    is_video_call: 'YES' | 'NO';
    /**
     * Boolean indicating whether this participant is administratively video muted.
     */
    is_video_muted: boolean;
    /**
     * When participant is considered silent. Eg when frozen, black or outside of the frame.
     */
    is_video_silent: boolean;
    is_main_video_dropped_out?: boolean;
    /**
     * The calling or `from` alias. This is the alias that the recipient would use to return the call.
     */
    local_alias: string;
    /**
     * Set to `YES` if the participant can be muted, `NO` if not.
     */
    mute_supported: 'YES' | 'NO';
    needs_presentation_in_mix?: boolean;
    /**
     * Text that may be used as an alternative to display_name as the participant name overlay text.
     */
    overlay_text: string;
    /**
     * Set to `YES` if the presentation is supported, `NO` if not.
     */
    presentation_supported?: 'YES' | 'NO';
    /**
     * The call protocol. (Note that the protocol is always reported as `api` when an Infinity Connect client dials in to Pexip Infinity.)
     */
    protocol: 'api' | 'webrtc' | 'sip' | 'rtmp' | 'h323' | 'mssip';
    /**
     * The level of privileges the participant has in the conference
     */
    role: 'chair' | 'guest';
    /**
     * Set to `ALLOW` if the participant is administratively allowed to receive presentation, or `DENY` if disallowed.
     */
    rx_presentation_policy: 'ALLOW' | 'DENY';
    service_type?:
        | 'connecting'
        | 'waiting_room'
        | 'ivr'
        | 'conference'
        | 'lecture'
        | 'gateway'
        | 'test_call';
    /**
     * A Unix timestamp of when this participant was spotlighted, if spotlight is used.
     */
    spotlight: number;
    /**
     * A Unix timestamp of when this participant joined (UTC).
     */
    start_time: number;
    /**
     * Set to `YES` if this participant can be transferred into another VMR; `NO` if not.
     */
    transfer_supported: 'YES' | 'NO';
    /**
     * The UUID of this participant, to use with other operations.
     */
    uuid: string;
    /**
     * The URI of the participant. Empty for Guest participants.
     */
    uri?: string;
    /**
     * The vendor identifier of the browser/endpoint with which the participant is connecting. Empty for Guest participants.
     */
    vendor?: string;
}

import {validate200} from './participantsValidation';
type ParticipantsResponse = {status: 200; data: ParticipantsMap['200']};
interface ParticipantsParams {
    conferenceAlias: string;
}
export async function participants({
    fetcher,
    params,
    host,
}: {
    fetcher: typeof fetch;
    params: ParticipantsParams;
    host: string;
}): Promise<ParticipantsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/participants`,
        {
            method: 'get',
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
