import {useCallback, useEffect, useState, useRef} from 'react';

import {isDefined} from '@pexip/utils';

/*
t('media.permissions-unblock-video-overlay-step-[1]-[android]', 'Look for this icon in your browser')
t('media.permissions-unblock-video-overlay-step-[2]-[android]', 'Tap it')
t('media.permissions-unblock-video-overlay-step-[3]-[android]', 'Scroll down to settings')
t('media.permissions-unblock-video-overlay-step-[4]-[android]', 'Tap it')
t('media.permissions-unblock-video-overlay-step-[5]-[android]', 'Tap to turn the microphone and camera on')
t('media.permissions-unblock-video-overlay-step-[6]-[android]', 'Pull down to refresh the page')

t('media.permissions-unblock-video-overlay-step-[1]-[chrome]', 'Look for this icon in your browser')
t('media.permissions-unblock-video-overlay-step-[2]-[chrome]', 'Click it')
t('media.permissions-unblock-video-overlay-step-[3]-[chrome]', 'Change the settings to "always allow"')
t('media.permissions-unblock-video-overlay-step-[4]-[chrome]', '...then refresh the page')

t('media.permissions-unblock-video-overlay-step-[1]-[edge]', 'Look for this icon in your browser')
t('media.permissions-unblock-video-overlay-step-[2]-[edge]', 'Click it')
t('media.permissions-unblock-video-overlay-step-[3]-[edge]', 'Change the settings to "always allow"')
t('media.permissions-unblock-video-overlay-step-[4]-[edge]', '...then refresh the page')

t('media.permissions-unblock-video-overlay-step-[1]-[firefox]', 'Look for these icons')
t('media.permissions-unblock-video-overlay-step-[2]-[firefox]', 'Click them')
t('media.permissions-unblock-video-overlay-step-[3]-[firefox]', 'Click on the "X" to remove the blockage')
t('media.permissions-unblock-video-overlay-step-[4]-[firefox]', '...then refresh the page')

t('media.permissions-unblock-video-overlay-step-[1]-[safari-ios]', 'Go in to settings on your device')
t('media.permissions-unblock-video-overlay-step-[2]-[safari-ios]', 'Scroll down to locate Safari (or whichever browser you are using)')
t('media.permissions-unblock-video-overlay-step-[3]-[safari-ios]', 'Tap it')
t('media.permissions-unblock-video-overlay-step-[4]-[safari-ios]', 'Then locate "Camera" and "Microphone"')
t('media.permissions-unblock-video-overlay-step-[5]-[safari-ios]', 'Make sure they are enabled or set to "allow"')
t('media.permissions-unblock-video-overlay-step-[6]-[safari-ios]', 'Return to this browser ...then refresh the page')

t('media.permissions-unblock-video-overlay-step-[1]-[safari-ipados]', 'Go in to settings on your device')
t('media.permissions-unblock-video-overlay-step-[2]-[safari-ipados]', 'Scroll down to locate Safari (or whichever browser you are using)')
t('media.permissions-unblock-video-overlay-step-[3]-[safari-ipados]', 'Tap it')
t('media.permissions-unblock-video-overlay-step-[4]-[safari-ipados]', 'Then locate "Camera" and "Microphone"')
t('media.permissions-unblock-video-overlay-step-[5]-[safari-ipados]', 'Make sure they are enabled or set to "allow"')
t('media.permissions-unblock-video-overlay-step-[6]-[safari-ipados]', 'Return to this browser ...then refresh the page')

t('media.permissions-unblock-video-overlay-step-[1]-[safari-macosx]', 'Go in to settings under Safari')
t('media.permissions-unblock-video-overlay-step-[2]-[safari-macosx]', 'Click it')
t('media.permissions-unblock-video-overlay-step-[3]-[safari-macosx]', 'Click on "settings for {{hostName}}"')
t('media.permissions-unblock-video-overlay-step-[4]-[safari-macosx]', 'Hover your mouse over the camera and microphone options')
t('media.permissions-unblock-video-overlay-step-[5]-[safari-macosx]', 'Change the setting to "Allow"')
t('media.permissions-unblock-video-overlay-step-[6]-[safari-macosx]', '...then refresh the page')
*/

export const useVideoTextOverlay = ({
    isOpen,
    videoTextOverlaySteps,
}: {
    isOpen: boolean;

    videoTextOverlaySteps: {step: number; text: string; duration: number}[];
}) => {
    const timeoutID = useRef<number>();
    const videoRef = useRef<HTMLVideoElement>(null);
    const [index, setIndex] = useState(0);
    const currentStep = isDefined(videoTextOverlaySteps[index]);
    const maxLength = videoTextOverlaySteps.length - 1;

    const runVideoTextOverlay = useCallback(() => {
        const duration = currentStep.duration;
        if (typeof duration === 'number' && isDefined(timeoutID)) {
            timeoutID.current = window.setTimeout(() => {
                setIndex(currentIndex => currentIndex + 1);
            }, duration);
        }
    }, [currentStep, timeoutID]);

    useEffect(() => {
        if (isOpen && index < maxLength) {
            runVideoTextOverlay();
        } else if (!isOpen) {
            setIndex(0);
        }
        return () => {
            clearTimeout(timeoutID.current);
        };
    }, [isOpen, index, runVideoTextOverlay, maxLength]);

    return {
        index,
        setIndex,
        videoRef,
        currentStep,
    };
};
