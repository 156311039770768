import React from 'react';
import cx from 'classnames';

import type {DeniedDevices} from '@pexip/media';
import type {
    StandardSizeModifier,
    VideoWrapperSizeModifier,
} from '@pexip/components';
import {CenterLayout, Avatar, AvatarType} from '@pexip/components';

import {PermissionsRequestMessage} from '../PermissionsRequestMessage/PermissionsRequestMessage.view';

import styles from './Selfview.module.scss';

const UserAvatar: React.FC<
    React.PropsWithChildren<{
        displayName?: string;
        size: StandardSizeModifier;
    }>
> = ({displayName = 'Guest', size}) => (
    <CenterLayout>
        <Avatar
            type={AvatarType.Initials}
            displayName={displayName}
            sizeModifier={size}
        />
    </CenterLayout>
);

export const SelfviewOverlay: React.FC<
    React.PropsWithChildren<{
        showPlaceholder: boolean;
        sizeModifier?: VideoWrapperSizeModifier;
        username?: string;
        learnHowToGrantAccessURL?: string;
        dismissPermissionsLinkMessage?: boolean;
        setDismissPermissionsLinkMessage?: React.Dispatch<
            React.SetStateAction<boolean>
        >;
        deniedDevice?: DeniedDevices;
        onTryAgain?: (deniedDevice: DeniedDevices) => void;
        isVideoInitiallyShown?: boolean;
        isPhone?: boolean;
        isFlipped?: boolean;
        isMobileDevice?: boolean;
        showVideo?: boolean;
        onTriggerMediaModal?: () => void;
    }>
> = ({
    children,
    isPhone,
    learnHowToGrantAccessURL,
    dismissPermissionsLinkMessage,
    deniedDevice,
    showVideo,
    isMobileDevice,
    isVideoInitiallyShown,
    isFlipped,
    showPlaceholder,
    username,
    sizeModifier,
    onTriggerMediaModal,
}) => (
    <div className={cx(styles.overlayWrapper)}>
        {showVideo && isMobileDevice && isVideoInitiallyShown && (
            <div
                className={cx(styles.flipper, {
                    [styles.flipped]: isFlipped,
                })}
            />
        )}

        <div
            className={cx(
                styles.childWrapper,
                'd-flex flex-direction-column align-items-stretch',
                {
                    'justify-content-center': isPhone,
                    'justify-content-end': !isPhone,
                },
            )}
        >
            {showPlaceholder && (
                <UserAvatar
                    displayName={username}
                    size={sizeModifier === 'small' ? sizeModifier : 'medium'}
                />
            )}
            {children}
            {deniedDevice && !dismissPermissionsLinkMessage && (
                <PermissionsRequestMessage
                    url={learnHowToGrantAccessURL}
                    onTriggerMediaModal={onTriggerMediaModal}
                    deniedDevice={deniedDevice}
                    className={cx('pt-9', styles.permissionsRequestMessage)}
                />
            )}
        </div>
    </div>
);
