import {useMemo} from 'react';

import type {FullSizeWindowOverlayColorOpacity} from '../components/foundation/FullSizeWindow/FullSizeWindow.constants';
import type {BackgroundImageSrc, BlendMode} from '../types/images';
import {
    getBackgroundImageCSSVariablesFromBreakpointImageSet,
    isBackgroundImageSrcBreakpointImageSet,
    isBackgroundImageSrcEmpty,
    isBackgroundImageSrcUrl,
} from '../utils/imageSrc';

export const useFullSizeWindowBackground = ({
    background,
    backgroundImageSrc,
    backgroundBlendMode,
    overlayColorOpacity,
}: {
    background: 'image' | 'none' | 'primary' | 'secondary' | 'tertiary';
    backgroundImageSrc?: BackgroundImageSrc;
    backgroundBlendMode: BlendMode;
    overlayColorOpacity: FullSizeWindowOverlayColorOpacity;
}) =>
    useMemo(() => {
        const isBackgroundImage =
            background === 'image' &&
            !isBackgroundImageSrcEmpty(backgroundImageSrc);
        const isBackgroundImageBreakpointImageSet =
            isBackgroundImage &&
            isBackgroundImageSrcBreakpointImageSet(backgroundImageSrc);
        const isBackgroundImageBlend =
            isBackgroundImage && backgroundBlendMode !== 'normal';
        const isBackgroundNone = background === 'none';
        const isBackgroundPrimary = background === 'primary';
        const isBackgroundSecondary =
            background === 'secondary' ||
            (background === 'image' && !isBackgroundImage);

        let backgroundStyleProps: Record<string, string> = {};
        if (isBackgroundImage) {
            if (isBackgroundImageSrcUrl(backgroundImageSrc)) {
                backgroundStyleProps = {
                    ...backgroundStyleProps,
                    backgroundImage: `url("${backgroundImageSrc}")`,
                };
            }
            if (isBackgroundImageSrcBreakpointImageSet(backgroundImageSrc)) {
                const backgroundImageCSSVariables =
                    getBackgroundImageCSSVariablesFromBreakpointImageSet(
                        backgroundImageSrc,
                    );
                backgroundStyleProps = {
                    ...backgroundStyleProps,
                    ...backgroundImageCSSVariables,
                };
            }
        }

        if (isBackgroundImageBlend) {
            backgroundStyleProps = {
                ...backgroundStyleProps,
                '--blend-mode': backgroundBlendMode,
                '--background-color-opacity': `${overlayColorOpacity}`,
            };
        }

        return {
            isBackgroundNone,
            isBackgroundPrimary,
            isBackgroundSecondary,
            isBackgroundImage,
            isBackgroundImageBreakpointImageSet,
            isBackgroundImageBlend,
            backgroundStyleProps,
        };
    }, [
        background,
        backgroundImageSrc,
        backgroundBlendMode,
        overlayColorOpacity,
    ]);
