import React from 'react';
import cx from 'classnames';

import {FrequencyBarsAudioMeter} from '@pexip/media-components';

import styles from './OneTimeDeviceSelection.module.scss';

export const FrequencyBarsAudioMeterWithContent: React.FC<{
    barsWrapperId?: string;
    children?: React.ReactNode;
    shouldDismiss?: boolean;
    onClick?: () => void;
}> = ({barsWrapperId, children, shouldDismiss = false, onClick}) => (
    <FrequencyBarsAudioMeter
        barsWrapperId={barsWrapperId}
        onClick={onClick}
        className={styles.frequencyBarsAudioMeterWithTip}
        barsWrapperClassName="p-0"
        showBackground
    >
        <div
            className={cx(
                styles.microphoneStepTip,
                shouldDismiss && styles.fadeInAnimation,
            )}
        >
            {children}
        </div>
    </FrequencyBarsAudioMeter>
);
