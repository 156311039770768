import {v4 as uuid} from 'uuid';
import {captureException} from '@sentry/hub';

import * as API from '@pexip/infinity-api';
import type {Detach} from '@pexip/signal';
import {Backoff} from '@pexip/utils';
import type {withToken, InfinityErrorMessage} from '@pexip/infinity-api';
import {getErrorCode} from '@pexip/infinity-api';

import type {
    Call,
    CallSignals,
    Client,
    InfinityClient,
    InfinitySignals,
    Participant,
    RequestClient,
    CallUuid,
    ConferenceStatus,
    ExtendedInfinityErrorMessage,
    GetEndpointResponse,
    EndpointResponse,
    ConferenceFeatureFlags,
    Stun,
    Turn,
    DisconnectReason,
} from './types';
import {createRequestClient} from './requestClient';
import {createCall} from './call';
import {createEventSource, eventSignals} from './eventSource';
import {logger} from './logger';
import {backoffBaseOptions, NONE} from './constants';

import type {GetEndpointParams} from '.';

export const createInfinityClient = (
    signals: InfinitySignals,
    callSignals: CallSignals,
): InfinityClient => {
    let requestClient: RequestClient | undefined;
    let eventSource: EventSource | undefined;
    let currentCall: Call | undefined;
    let currentCallUuid: CallUuid;
    let currentConferenceAlias: string;
    let currentParticipantUuid: string;
    let currentHost: string;
    let currentPin: string | undefined;
    let currentConferenceExtension: string | undefined;
    let currentChosenIdp = NONE;
    let currentSsoToken = NONE;
    let detachSignals: Detach[] = [];
    let participants: {[userId: string]: Participant} = {};
    let conferenceStatus: ConferenceStatus | undefined;
    let conferenceFeatureFlags: ConferenceFeatureFlags | undefined;
    let stun: Stun;
    let turn: Turn;
    let dataChannelId: number | undefined;
    let useRelayCandidatesOnly: boolean | undefined;
    let disconnectPromise: ReturnType<typeof disconnectRequest> | undefined;
    let restartCallPromise: ReturnType<typeof restartCallRequest> | undefined;

    let retryList: Array<() => Promise<undefined | EndpointResponse>> = [];

    const timerIDs = {
        eventSrcReconnect: -1,
        update: -1,
    };

    const captureNoRequestClient = () => {
        const errorMsg =
            'Attempted to create EventSource before RequestClient, or RequestClient has been already cleaned up. Aborting.';
        logger.warn(errorMsg);
        return;
    };

    const isCriticalAction = (funcName: keyof Client) => {
        return ['call', 'sendOffer', 'ack', 'disconnect'].includes(funcName);
    };

    const getCurrentCallUuid = () => currentCallUuid;

    type GenerateEndpointParams<T extends keyof Client> =
        | {
              func: (
                  reqParams: GetEndpointParams<T>,
              ) => Promise<GetEndpointResponse<T>>;
              funcName: keyof Client;
              retriable?: boolean;
              requiresToken: false;
          }
        | {
              func: (
                  reqParams: GetEndpointParams<T>,
                  fetcher: ReturnType<typeof withToken>,
              ) => Promise<GetEndpointResponse<T>>;
              funcName: T;
              retriable?: boolean;
              requiresToken: true;
          };
    function generateEndpoint<T extends keyof Client>(
        opts: GenerateEndpointParams<T>,
    ) {
        const wrappedFunc = async (...args: GetEndpointParams<T>) => {
            logger.debug({opts}, `${opts.funcName} called`);
            const {func, funcName, requiresToken, retriable} = opts;

            const flushRetryQueue = async () => {
                /**
                 * Although it is generally not a great idea to make retries dependant on another request being successful as it might never happen,
                 * because of our unique context (video as main feature) we should always try to renegotiate the call on network changes.
                 * Without A/V the app effectively has no use.
                 * This renegotiation after network changes is therefore a reliable proxy for network availability as long as it is implemented.
                 */
                if (
                    retryList.length > 0 &&
                    (await requestClient?.refreshToken())
                ) {
                    const promise = Promise.allSettled(
                        retryList.map(func => func()),
                    );
                    retryList = [];
                    const res = await promise;
                    const hasFailure = res.find(
                        settled =>
                            settled.status === 'fulfilled' &&
                            settled.value === undefined,
                    );
                    if (!hasFailure) {
                        signals.onRetryQueueFlushed.emit();
                    }
                }
            };

            let endpointPromise;
            try {
                if (requiresToken) {
                    if (!requestClient) {
                        captureNoRequestClient();
                        return;
                    }
                    await flushRetryQueue();
                    endpointPromise = await func(args, requestClient.fetcher);
                } else {
                    endpointPromise = await func(args);
                }
            } catch (e) {
                if (retriable) {
                    // if anything fails again it will be added back to the queue. Order is lost
                    logger.error(
                        {error: e},
                        `Adding failed request '${funcName}' to retry queue.`,
                    );
                    retryList.push(() => wrappedFunc(...args));
                } else {
                    logger.error(
                        {error: e},
                        `Request '${funcName}' threw an Error.`,
                    );
                    if (navigator.onLine) {
                        captureException(e);
                    }
                    if (isCriticalAction(funcName)) {
                        cleanupAndDisconnect(
                            'Could not execute critical network action',
                        );
                        return endpointPromise;
                    }
                }
                signals.onFailedRequest.emit(funcName);
            }
            return endpointPromise;
        };
        return wrappedFunc;
    }

    const openEventSource = (
        host: string,
        conferenceAlias: string,
        backoff = new Backoff(backoffBaseOptions),
    ) => {
        if (!requestClient) {
            captureNoRequestClient();
            return;
        }

        clearTimeout(timerIDs.eventSrcReconnect);
        eventSource?.close();

        eventSource = createEventSource({
            conferenceAlias,
            host,
            token: requestClient.token,
        });
        eventSource.onopen = () => {
            logger.debug('Event Source opened');
            backoff.reset();
        };
        eventSource.onerror = error => {
            if (!eventSource) {
                // the call was disconnected cleanly
                return;
            }
            eventSource.close();
            logger.debug('EventSource error, Trying to reconnect', error);

            timerIDs.eventSrcReconnect = window.setTimeout(() => {
                if (requestClient?.token) {
                    openEventSource(host, conferenceAlias, backoff);
                }
            }, backoff.duration());
        };
    };

    const startCall = ({
        bandwidth,
        packetizationMode,
        mediaStream,
    }: Pick<
        GetEndpointParams<'call'>[0],
        'bandwidth' | 'packetizationMode' | 'mediaStream'
    >) => {
        currentCall = createCall({
            sendOffer,
            ack,
            newCandidate,
            update,
            takeFloor,
            releaseFloor,
            getCurrentCallUuid,
            signals,
            eventSignals,
            callSignals,
            peerOptions: {
                rtcConfig: {
                    bundlePolicy: 'max-bundle',
                    iceServers: [
                        ...(stun
                            ? [
                                  {
                                      urls: stun.map(stun => stun.url),
                                  },
                              ]
                            : []),
                        ...(turn ? turn : []),
                    ],
                    ...(useRelayCandidatesOnly && {
                        iceTransportPolicy: 'relay',
                    }),
                },
                bandwidth,
                packetizationMode,
                vp9Disabled: conferenceFeatureFlags?.vp9Disabled,
                allow1080p: conferenceFeatureFlags?.allow1080p,
                allow4kPreso: conferenceFeatureFlags?.isDirectMedia,
            },
            dataChannelId,
            mediaStream,
        });
    };

    const restartCallRequest = async (
        opt: Parameters<InfinityClient['restartCall']>[0],
    ) => {
        await disconnectRequest({
            /**
             * There is a specific case for direct media call when
             * one peer disconnects we need to restart webrtc call
             * and wait for another participant to arrive
             * so we need to keep EventSource open to wait for the
             * ping from mcu for that.
             */
            callback: () => {
                currentCall?.disconnect();
                currentCall = undefined;
                currentCallUuid = undefined;
            },
            // Reuse exisitng token for this usecase
            release: () => {
                return Promise.resolve();
            },
        });
        startCall(opt);
    };

    const restartCall = async (
        ...params: Parameters<typeof restartCallRequest>
    ) => {
        restartCallPromise = restartCallRequest(...params);
        return restartCallPromise;
    };

    const call = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'call'>) => {
            const {
                bandwidth,
                conferenceAlias,
                displayName,
                node = window.location.host,
                host = `${window.location.protocol}//${node}`,
                mediaStream,
                packetizationMode,
                pin,
                chosenIdp,
                ssoToken,
                token,
                conferenceExtension,
            } = reqParams[0];

            if (pin) {
                currentPin = pin;
            }
            if (chosenIdp) {
                currentChosenIdp = chosenIdp;
            }
            if (ssoToken) {
                currentSsoToken = ssoToken;
            }
            if (conferenceExtension) {
                currentConferenceExtension = conferenceExtension;
            }

            if (restartCallPromise) {
                await restartCallPromise;
            }
            if (disconnectPromise) {
                await disconnectPromise;
            }

            const res = await API.requestToken({
                fetcher: currentPin
                    ? API.withPin(window.fetch, currentPin)
                    : window.fetch,
                body: {
                    display_name: displayName,
                    chosen_idp: currentChosenIdp,
                    direct_media: true,
                    sso_token: currentSsoToken,
                    node,
                    token,
                    conference_extension: currentConferenceExtension,
                },
                params: {
                    conferenceAlias,
                },
                host,
            });

            switch (res.status) {
                case 200:
                    conferenceFeatureFlags = {
                        chatEnabled: Boolean(res.data.result.chat_enabled),
                        isDirectMedia: Boolean(res.data.result.direct_media),
                        guestsCanPresent: Boolean(
                            res.data.result.guests_can_present,
                        ),
                        allow1080p: Boolean(res.data.result.allow_1080p),
                        vp9Disabled: !res.data.result.vp9_enabled,
                    };
                    currentConferenceAlias = conferenceAlias;
                    currentParticipantUuid = res.data.result.participant_uuid;
                    currentHost = host;

                    requestClient = createRequestClient({
                        conferenceAlias,
                        token: res.data.result.token,
                        expires: Number(res.data.result.expires),
                        host,
                        tokenExpiredCb: () => {
                            cleanupAndDisconnect(
                                'Could not reconnect to the meeting',
                            );
                        },
                    });

                    openEventSource(host, conferenceAlias);

                    if (detachSignals.length > 0) {
                        cleanupSignals();
                    }

                    detachSignals = subscribeSignals();
                    stun = res.data.result.stun;
                    turn = res.data.result.turn;
                    dataChannelId = res.data.result.pex_datachannel_id;
                    useRelayCandidatesOnly =
                        res.data.result.use_relay_candidates_only;

                    logger.debug({currentCall}, 'Creates a new call');
                    startCall({
                        bandwidth,
                        mediaStream,
                        packetizationMode,
                    });

                    break;

                case 403:
                case 415:
                    // For this response string is likely considered as error
                    if (typeof res.data.result === 'string') {
                        signals.onError.emit({
                            error: res.data.result,
                            errorCode: getErrorCode(res.data.result),
                        });
                    } else if (
                        'pin' in res.data.result &&
                        'guest_pin' in res.data.result
                    ) {
                        signals.onPinRequired.emit({
                            hasHostPin: res.data.result.pin === 'required',
                            hasGuestPin:
                                res.data.result.guest_pin === 'required',
                        });
                    } else if ('idp' in res.data.result) {
                        signals.onIdp.emit(res.data.result.idp);
                    } else if (
                        'redirect_url' in res.data.result &&
                        'redirect_idp' in res.data.result
                    ) {
                        signals.onRedirect.emit({
                            redirectUrl: res.data.result.redirect_url,
                            redirectIdp: res.data.result.redirect_idp,
                        });
                    } else if (
                        'conference_extension' in res.data.result &&
                        'conference_extension_type' in res.data.result
                    ) {
                        signals.onExtension.emit(
                            res.data.result.conference_extension_type,
                        );
                    }
                    break;
                case 404:
                    signals.onError.emit({
                        error: res.data.result,
                        errorCode: getErrorCode(res.data.result),
                    });
                    break;
                case 502:
                case 504:
                    signals.onError.emit({
                        error: res.data as InfinityErrorMessage,
                        errorCode: getErrorCode(
                            res.data as InfinityErrorMessage,
                        ),
                    });
                    break;
                case 529:
                    {
                        const error = (res.data ||
                            'Out of resource') as InfinityErrorMessage;
                        signals.onError.emit({
                            error,
                            errorCode: getErrorCode(error),
                        });
                    }
                    break;
            }
            return res;
        },
        funcName: 'call',
        requiresToken: false,
    });

    const sendOffer = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'sendOffer'>, fetcher) => {
            const {
                sdp,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
            } = reqParams[0];

            const res = await API.callsWebrtcParticipant({
                fetcher,
                body: {
                    call_type: 'WEBRTC',
                    sdp,
                },
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });

            if (res.status === 200) {
                currentCallUuid = res.data.result.call_uuid;
                if (res.data.result.sdp) {
                    signals.onAnswer.emit(res.data.result);
                }
            }

            return res;
        },
        funcName: 'sendOffer',
        requiresToken: true,
    });

    const ack = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'ack'>, fetcher) => {
            const {
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
                sdp,
            } = reqParams[0];

            if (!callUuid) {
                return;
            }

            return API.ack({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                    callUuid,
                },
                body: {sdp},
                host,
            });
        },
        funcName: 'ack',
        requiresToken: true,
    });

    const newCandidate = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'newCandidate'>, fetcher) => {
            const {
                candidate,
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
            } = reqParams[0];

            if (!callUuid) {
                return;
            }

            return API.newCandidate({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                    callUuid,
                },
                body: candidate,
                host,
            });
        },
        funcName: 'newCandidate',
        requiresToken: true,
    });

    const update = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'update'>, fetcher) => {
            const {
                sdp,
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
            } = reqParams[0];

            const doUpdate = async () => {
                if (!callUuid) {
                    clearTimeout(timerIDs.update);
                    return;
                }

                try {
                    const res = await API.update({
                        fetcher,
                        body: {
                            sdp,
                        },
                        params: {
                            conferenceAlias,
                            participantUuid,
                            callUuid,
                        },
                        host,
                    });

                    if (res.status === 200) {
                        clearTimeout(timerIDs.update);
                        // agressively refresh token and reconnect event source after successful update
                        // as it could be a result of connectivity issues.
                        // This works around the fact that backoff retry could be too big at this point and cause us to expire the token
                        await requestClient?.refreshToken();
                        if (!present && eventSource?.readyState === 2) {
                            openEventSource(host, conferenceAlias);
                        }

                        if (typeof res.data.result === 'string') {
                            signals.onAnswer.emit({
                                sdp: res.data.result,
                                call_uuid: callUuid,
                            });
                        } else {
                            signals.onAnswer.emit({
                                sdp: res.data.result.sdp,
                                call_uuid: res.data.result.call_uuid,
                            });
                        }
                    }
                    return res;
                } catch (reason) {
                    logger.error({reason}, 'Failed /update attempt');
                    timerIDs.update = window.setTimeout(() => {
                        void doUpdate();
                    }, 1000);
                }
            };

            clearTimeout(timerIDs.update);
            return doUpdate();
        },
        funcName: 'update',
        requiresToken: true,
    });

    const takeFloor = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'takeFloor'>, fetcher) => {
            const {
                participantUuid = currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.takeFloor({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'takeFloor',
        requiresToken: true,
    });

    const releaseFloor = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'releaseFloor'>, fetcher) => {
            const {
                participantUuid = currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.releaseFloor({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'releaseFloor',
        requiresToken: true,
    });

    const cleanupSignals = () => {
        detachSignals.forEach(detach => detach());
        detachSignals = [];
    };

    const cleanup = () => {
        logger.debug('Cleanup');
        currentCall?.disconnect();
        currentCall = undefined;
        eventSource?.close();
        eventSource = undefined;
        currentCallUuid = undefined;
        dataChannelId = undefined;
        useRelayCandidatesOnly = undefined;
        participants = {};
        conferenceStatus = undefined;
        currentPin = undefined;
        currentChosenIdp = NONE;
        currentSsoToken = NONE;
        currentConferenceExtension = undefined;
        cleanupSignals();
        Object.values(timerIDs).map(timer => clearTimeout(timer));
    };

    const releaseToken = async (reason?: DisconnectReason) => {
        await requestClient?.cleanup(reason);
        requestClient = undefined;
    };

    const cleanupAndDisconnect = (error: ExtendedInfinityErrorMessage) => {
        signals.onDisconnected.emit({
            error,
            errorCode: getErrorCode(error as InfinityErrorMessage), // Match API error or get a default errorCode
        });
        cleanup();
        void releaseToken();
    };

    const disconnectRequest = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'disconnect'>, fetcher) => {
            const {
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
                reason,
                callback = cleanup,
                release = releaseToken,
            } = reqParams[0];

            callback();

            if (callUuid && reason !== 'Browser closed') {
                try {
                    await API.disconnectCall({
                        fetcher,
                        params: {
                            conferenceAlias,
                            participantUuid,
                            callUuid,
                        },
                        host,
                    });
                } catch (reason) {
                    logger.warn({reason}, 'Unable to disconnect a call');
                }
            }

            await release(reason);
        },
        funcName: 'disconnect',
        requiresToken: true,
    });

    const disconnect = async (
        ...params: Parameters<typeof disconnectRequest>
    ) => {
        if (restartCallPromise) {
            await restartCallPromise;
        }
        disconnectPromise = disconnectRequest(...params);
        return disconnectPromise;
    };

    const subscribeSignals = () => [
        eventSignals.onPresentationStart.add(() => {
            void currentCall?.receivePresentation();
        }),
        eventSignals.onPresentationStop.add(() => {
            currentCall?.stopReceivingPresentation();
        }),
        eventSignals.onCallDisconnected.add(({call_uuid: callUuid}) => {
            logger.debug('onCallDisconnected', {callUuid, currentCallUuid});
            currentCall?.disconnect();
            currentCallUuid = undefined;
        }),
        eventSignals.onParticipantCreate.add(participant => {
            if (participant.uuid === currentParticipantUuid) {
                signals.onMe.emit(participant);
            }
            participants[participant.uuid] = participant;
            signals.onParticipantJoined.emit(Object.values(participants));
            signals.onParticipants.emit(Object.values(participants));
        }),
        eventSignals.onParticipantUpdate.add(participant => {
            if (participant.uuid === currentParticipantUuid) {
                signals.onMe.emit(participant);
            } else if (
                participants[participant.uuid] &&
                participant.raisedHand !==
                    participants[participant.uuid]?.raisedHand
            ) {
                signals.onRaiseHand.emit(participant);
            }
            participants[participant.uuid] = participant;
            signals.onParticipants.emit(Object.values(participants));
        }),
        eventSignals.onParticipantDelete.add(uuid => {
            delete participants[uuid];
            signals.onParticipantLeft.emit(Object.values(participants));
            signals.onParticipants.emit(Object.values(participants));
        }),
        eventSignals.onParticipantSyncBegin.add(() => {
            participants = {};
        }),
        eventSignals.onMessage.add(({uuid: userId, payload: message}) => {
            signals.onMessage.emit({
                at: new Date(),
                id: uuid(),
                displayName:
                    (userId && participants[userId]?.displayName) ?? 'User',
                userId,
                message,
            });
        }),
        eventSignals.onLayoutUpdate.add(({requested_layout}) => {
            signals.onRequestedLayout.emit({
                primaryScreen: {
                    hostLayout: requested_layout?.primary_screen.chair_layout,
                    guestLayout: requested_layout?.primary_screen.guest_layout,
                },
            });
        }),
        eventSignals.onStageUpdate.add(stages =>
            signals.onStage.emit(
                stages.map(stage => ({
                    userId: stage.participant_uuid,
                    stageIndex: stage.stage_index,
                    vad: stage.vad,
                })),
            ),
        ),
        eventSignals.onDisconnect.add(({reason}) => {
            cleanupAndDisconnect(reason);
        }),
        eventSignals.onConferenceUpdate.add(status => {
            conferenceStatus = status;
            signals.onConferenceStatus.emit(status);
        }),
        eventSignals.onRefer.add(signals.onTransfer.emit),
        eventSignals.onLiveCaptions.add(captions => {
            signals.onLiveCaptions.emit({
                data: captions.data,
                isFinal: captions.is_final,
            });
        }),
        eventSignals.onSplashScreen.add(splashScreen => {
            signals.onSplashScreen.emit(splashScreen);
        }),
        eventSignals.onNewOffer.add(({sdp}) => {
            signals.onNewOffer.emit(sdp);
        }),
        eventSignals.onUpdateSdp.add(({sdp}) => {
            signals.onUpdateSdp.emit(sdp);
        }),
        eventSignals.onNewCandidate.add(
            ({candidate, mid: sdpMid, ufrag: usernameFragment}) => {
                signals.onIceCandidate.emit(
                    new RTCIceCandidate({candidate, sdpMid, usernameFragment}),
                );
            },
        ),
        eventSignals.onPeerDisconnect.add(signals.onPeerDisconnect.emit),
    ];

    const present = (stream?: MediaStream) => {
        void currentCall?.present(stream);
    };

    const stopPresenting = () => {
        currentCall?.stopPresenting();
    };

    const setStream = (stream: MediaStream) => {
        currentCall?.setStream(stream);
    };

    const setBandwidth = (bandwidth: number) => {
        currentCall?.setBandwidth(bandwidth);
    };

    const setLayout = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'setLayout'>, fetcher) => {
            const {
                layout,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.transformLayout({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
                body: {
                    transforms: {
                        layout,
                    },
                },
            });
        },
        funcName: 'setLayout',
        requiresToken: true,
    });

    const raiseHand = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'raiseHand'>, fetcher) => {
            const {
                raise,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
            } = reqParams[0];

            return API[raise ? 'buzzParticipant' : 'clearbuzzParticipant']({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'raiseHand',
        requiresToken: true,
    });

    const spotlight = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'spotlight'>, fetcher) => {
            const {
                enable,
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API[
                enable ? 'spotlightonParticipant' : 'spotlightoffParticipant'
            ]({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'spotlight',
        requiresToken: true,
    });

    const sendMessage = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'sendMessage'>, fetcher) => {
            const {
                payload,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            if (currentCall && conferenceFeatureFlags?.isDirectMedia) {
                currentCall.sendDataChannelEvent({
                    type: 'message',
                    body: {
                        type: 'text/plain',
                        origin:
                            participants[currentParticipantUuid]?.displayName ||
                            'User',
                        uuid: currentParticipantUuid,
                        payload,
                    },
                });
                return Promise.resolve({
                    status: 200,
                    data: {status: 'success', result: true},
                } as GetEndpointResponse<'sendMessage'>);
            } else {
                return API.message({
                    fetcher,
                    params: {
                        conferenceAlias,
                    },
                    host,
                    body: {
                        payload,
                        type: 'text/plain',
                    },
                });
            }
        },
        funcName: 'sendMessage',
        requiresToken: true,
        retriable: true,
    });

    const admit = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'admit'>, fetcher) => {
            const {
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.unlockParticipant({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'admit',
        requiresToken: true,
    });

    const mute = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'mute'>, fetcher) => {
            const {
                mute,
                participantUuid = currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API[mute ? 'muteParticipant' : 'unmuteParticipant']({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'mute',
        requiresToken: true,
    });

    const muteAllGuests = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'muteAllGuests'>,
            fetcher,
        ) => {
            const {
                mute,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API[mute ? 'muteguests' : 'unmuteguests']({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'muteAllGuests',
        requiresToken: true,
    });

    const muteVideo = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'muteVideo'>, fetcher) => {
            const {
                muteVideo,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API[
                muteVideo ? 'videoMuteParticipant' : 'videoUnmuteParticipant'
            ]({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid: currentParticipantUuid,
                },
                host,
            });
        },
        funcName: 'muteVideo',
        requiresToken: true,
    });

    const lock = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'lock'>, fetcher) => {
            const {
                lock,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API[lock ? 'lock' : 'unlock']({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'lock',
        requiresToken: true,
    });

    const disconnectAll = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'disconnectAll'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.disconnect({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'disconnectAll',
        requiresToken: true,
    });

    const kick = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'kick'>, fetcher) => {
            const {
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.disconnectParticipant({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'kick',
        requiresToken: true,
    });

    const dial = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'dial'>, fetcher) => {
            const {
                destination,
                role,
                protocol = 'auto',
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.dial({
                fetcher,
                params: {
                    conferenceAlias,
                },
                body: {
                    destination,
                    role,
                    protocol,
                },
                host,
            });
        },
        funcName: 'dial',
        requiresToken: true,
    });

    const transfer = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'transfer'>, fetcher) => {
            const {
                destination,
                pin,
                role,
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.transferParticipant({
                fetcher,
                body: {
                    conference_alias: destination,
                    pin,
                    role,
                },
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'transfer',
        requiresToken: true,
    });

    const liveCaptions = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'liveCaptions'>, fetcher) => {
            const {
                enable,
                conferenceAlias = currentConferenceAlias,
                participantUuid = currentParticipantUuid,
            } = reqParams[0];

            await API[enable ? 'showLiveCaptions' : 'hideLiveCaptions']({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host: currentHost,
            });
        },
        funcName: 'liveCaptions',
        requiresToken: true,
    });

    const setPin = (pin = NONE) => {
        currentPin = pin;
    };

    const setConferenceExtension = (conferenceExtension?: string) => {
        currentConferenceExtension = conferenceExtension;
    };

    return {
        get participants() {
            return Object.values(participants);
        },
        get conferenceStatus() {
            return conferenceStatus;
        },
        get conferenceFeatureFlags() {
            return conferenceFeatureFlags;
        },
        get me() {
            return participants[currentParticipantUuid];
        },
        admit,
        call,
        dial,
        disconnect,
        disconnectAll,
        kick,
        lock,
        mute,
        muteAllGuests,
        muteVideo,
        present,
        raiseHand,
        restartCall,
        sendMessage,
        setBandwidth,
        setConferenceExtension,
        setLayout,
        setPin,
        setStream,
        spotlight,
        stopPresenting,
        transfer,
        liveCaptions,
    };
};
