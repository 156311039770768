import {Home} from '../pages/Home.page';
import {MeetingFlow} from '../pages/MeetingFlow.page';
import {PageNotFound} from '../pages/PageNotFound.page';
import {createRoutesHook} from '../router';

export const useMainRoutes = createRoutesHook([
    {
        path: '/m',
        node: MeetingFlow,
    },
    {
        exact: true,
        path: '/',
        node: Home,
    },
    {
        path: '',
        fallback: true,
        node: PageNotFound,
    },
]);
