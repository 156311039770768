import {useState, useCallback} from 'react';

import {config} from '../config';
import {infinityService} from '../services/InfinityClient.service';

export const useSettings = () => {
    const [isOpen, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const openModal = useCallback(() => {
        setActiveTab(0);
        setOpen(true);
    }, []);
    const openEffectsModal = useCallback(() => {
        setActiveTab(1);
        setOpen(true);
    }, []);
    const openMeetingSettings = useCallback(() => {
        setActiveTab(
            config.get('videoProcessing') &&
                !infinityService.conferenceFeatureFlags?.isDirectMedia
                ? 3
                : 2,
        );
        setOpen(true);
    }, []);
    const closeModal = useCallback(() => setOpen(false), []);

    return {
        openModal,
        openEffectsModal,
        openMeetingSettings,
        closeModal,
        isOpen,
        activeTab,
    };
};
