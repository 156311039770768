type LogMethod = (meta: unknown, message?: string) => void;

export interface Logger {
    trace: LogMethod;
    debug: LogMethod;
    info: LogMethod;
    warn: LogMethod;
    error: LogMethod;
}

const noopLogger: Logger = Object.freeze({
    trace() {},
    debug() {},
    info() {},
    warn() {},
    error() {},
});

export let logger = noopLogger;

/**
 * Set the global logger for the media-control module
 * @param newLogger - The logger
 * @returns The old logger
 */
export function setLogger(newLogger: Logger): Logger {
    const oldLogger = logger;
    logger = newLogger;
    return oldLogger;
}

/**
 * A logger wrapper to always include some meta base data to the log
 *
 * @param metaBase - The meta data which will always be included into the log
 */
export const createModuleLogger = (metaBase: unknown): Logger => {
    return Object.keys(logger).reduce((log, key) => {
        const k = key as keyof Logger;
        log[k] = (meta: unknown, msg?: string) => {
            if (typeof meta === 'string') {
                return logger[k](metaBase, meta);
            }
            if (typeof meta === 'object') {
                return logger[k]({...meta, meta: metaBase}, msg);
            }
            return logger[k]({meta: metaBase, context: meta}, msg);
        };
        return log;
    }, {} as Logger);
};

export const createLogProxyHandler = <T extends object>(
    logger: Logger,
    name: string,
    scope: string,
): ProxyHandler<T> => {
    return {
        /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return --- from lib.es2015 and log */
        get: (target, p, receiver) => {
            const value = Reflect.get(target, p, receiver);
            logger.debug(
                {scope, name, prop: p, value},
                `called get ${name}[${String(p)}]`,
            );
            return value;
        },
        // eslint-disable-next-line max-params --- from lib.es2015 and log
        set: (target, p, value, receiver) => {
            logger.debug(
                {scope, name, prop: p, value},
                `called set ${name}[${String(p)}]`,
            );
            return Reflect.set(target, p, value, receiver);
        },
        /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return --- from lib.es2015 and log */
    };
};

export const proxyWithLog =
    (logger: Logger, scope: string) =>
    <T extends object>(obj: T, name: string) => {
        return new Proxy(obj, createLogProxyHandler(logger, name, scope));
    };
