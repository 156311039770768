import React from 'react';

import {
    TestYourMicStages,
    FrequencyBarsAudioMeter,
    useFrequencyBarsBackgroundProgress,
} from '@pexip/media-components';

import {useTestMicrophoneAnalyzers} from '../hooks/useTestMicrophoneAnalyzers';
import {useAnimateFrequencyBars} from '../hooks/useAnimateFrequencyBars';

const PLAYBACK_LENGTH = 5;
const BARS_WRAPPER_ID = 'test-your-mic-frequency-bars-wrapper-id';
const PROGRESS_BACKGROUND_ID = 'progress-background';

export const TestYouMicAudioMeter: React.FC<{
    currentPlaybackCount?: number;
    currentRecordingCount?: number;
    currentStage: TestYourMicStages;
    setTestYourMicStages: (
        value: React.SetStateAction<TestYourMicStages>,
    ) => void;
    setRecordedStream: React.Dispatch<
        React.SetStateAction<MediaStream | undefined>
    >;
    objectURL?: string;
}> = ({
    currentStage,
    currentPlaybackCount = 0,
    currentRecordingCount = 0,
    setTestYourMicStages,
    setRecordedStream,
    objectURL,
}) => {
    const {microphoneFrequencyData, playbackFrequencyData} =
        useTestMicrophoneAnalyzers(
            setTestYourMicStages,
            setRecordedStream,
            objectURL,
        );

    useFrequencyBarsBackgroundProgress(
        PLAYBACK_LENGTH,
        currentStage === TestYourMicStages.PlayingBack
            ? currentRecordingCount
            : currentPlaybackCount,
        PROGRESS_BACKGROUND_ID,
    );

    useAnimateFrequencyBars({
        currentStage,
        microphoneFrequencyData,
        playbackFrequencyData,
        barsWrapperId: BARS_WRAPPER_ID,
    });

    return (
        <FrequencyBarsAudioMeter
            barsWrapperId={BARS_WRAPPER_ID}
            progressBackgroundId={PROGRESS_BACKGROUND_ID}
            showBorder={currentStage === TestYourMicStages.RecordingSuccess}
        />
    );
};
