import React from 'react';

import {FullSizeWindow, isBackgroundImageSrcEmpty} from '@pexip/components';
import type {
    FullSizeWindowCommonProps,
    FullSizeWindowColorBackgroundProps,
    FullSizeWindowImageBackgroundProps,
} from '@pexip/components';

import {useBrandImage} from '../hooks/useBrandImage';

type InfinityConnectFullSizeWindowBackgroundProps =
    | FullSizeWindowColorBackgroundProps
    | Omit<
          FullSizeWindowImageBackgroundProps,
          'backgroundImageSrc' | 'backgroundImagePath'
      >;

type InfinityConnectFullSizeWindowProps = FullSizeWindowCommonProps &
    InfinityConnectFullSizeWindowBackgroundProps;

export const BrandedFullSizeWindow: React.FC<
    React.ComponentProps<'div'> &
        InfinityConnectFullSizeWindowProps & {
            fallbackBackground?: FullSizeWindowColorBackgroundProps['background'];
        }
> = ({
    background: backgroundProp,
    backgroundBlendMode = 'multiply',
    fallbackBackground = 'secondary',
    overlayColorOpacity,
    ...props
}) => {
    const backgroundImageSrc = useBrandImage('background');
    const background =
        backgroundProp === 'image' &&
        isBackgroundImageSrcEmpty(backgroundImageSrc)
            ? fallbackBackground
            : backgroundProp;

    if (background === 'image' && backgroundImageSrc) {
        return (
            <FullSizeWindow
                {...props}
                background={background}
                backgroundImageSrc={backgroundImageSrc}
                backgroundBlendMode={backgroundBlendMode}
                overlayColorOpacity={overlayColorOpacity}
            />
        );
    } else if (background !== 'image') {
        return <FullSizeWindow {...props} background={background} />;
    }
    return null;
};
