import React from 'react';
import {useTranslation} from 'react-i18next';

import {Bar, CenterLayout, Section, TextLink} from '@pexip/components';
import type {
    ExtendedInfinityErrorCode,
    ExtendedInfinityErrorMessage,
} from '@pexip/infinity';

import {ERROR_MESSAGES_URL} from '../constants';
import {TestId} from '../../test/testIds';
import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {Header} from '../viewModels/Header.viewModel';
import {RejoinChooseAnotherMeeting} from '../views/RejoinChooseAnotherMeeting/RejoinChooseAnotherMeeting.view';

/*
t('error.code[#pex100]', 'The PIN you entered is invalid - please try again.')
t('error.code[#pex101]', 'The PIN you entered is invalid - please try again.')
t('error.code[#pex109]', 'PEX109 user facing error message')
t('error.code[#pex110]', 'The system you are trying to reach is temporarily unavailable. Please try again shortly.')
t('error.code[#pex111]', 'There is no connection. Please try again.')
t('error.code[#pex112]', 'There is no connection available.')
t('error.code[#pex113]', 'Your connection was lost. Please try again.')
t('error.code[#pex114]', 'The system you are trying to reach is over capacity.')
t('error.code[#pex115]', 'Transfer failed.')
t('error.code[#pex116]', 'Call failed - please contact your administrator.')
t('error.code[#pex117]', 'The call failed. Please try again.')
t('error.code[#pex118]', 'The server cannot be reached.')
t('error.code[#pex119]', 'Call failed: failed to forward request.')
t('error.code[#pex120]', 'A Host ended the meeting.')
t('error.code[#pex121]', 'A Host ended the meeting.')
t('error.code[#pex122]', 'An administrator ended the meeting.')
t('error.code[#pex123]', 'An administrator disconnected you from the meeting.')
t('error.code[#pex124]', 'Another participant in the meeting disconnected you.')
t('error.code[#pex125]', 'A Host ended the meeting.')
t('error.code[#pex126]', 'The meeting Host has not joined or unlocked the meeting.')
t('error.code[#pex127]', 'Message shown to users when a `Call Failed: Disabled` error is encountered')
t('error.code[#pex128]', 'Call failed: a firewall maybe blocking access.')
t('error.code[#pex129]', 'Something went wrong with the meeting. Please try to connect again.')
t('error.code[#pex130]', 'Something went wrong with the meeting. Please try to connect again.')
t('error.code[#pex131]', 'Something went wrong with the meeting. Please try to connect again.')
t('error.code[#pex132]', 'Something went wrong with the meeting. Please try to connect again.')
t('error.code[#pex140]', 'The meeting has ended.')
t('error.code[#pex141]', 'The meeting ended because the Host(s) left.')
t('error.code[#pex142]', 'You were the only participant left in the meeting.')
t('error.code[#pex143]', 'The test call has finished.')
t('error.code[#pex150]', 'The person you are trying to call did not answer or could not be reached.')
t('error.code[#pex151]', 'The other participant has disconnected.')
t('error.code[#pex152]', 'The call could not be placed.')
t('error.code[#pex153]', 'The call could not be placed. Please contact your administrator.')
t('error.code[#pex154]', 'Cannot connect to "{{alias}}". Check this address and try again.')
t('error.code[#pex155]', 'Could not join {{host}}.')
t('error.code[#pex156]', 'The call could not be placed.')
t('error.code[#pex157]', 'Could not join {{host}}.')
t('error.code[#pex170]', 'Call failed: Please disable any privacy extensions on your browser.')
t('error.code[#pex171]', 'Your camera and/or microphone are not available. Please make sure they are not being actively used by another app.')
t('error.code[#pex180]', 'The presentation ended.')
t('error.code[#pex181]', 'The presentation stream was disconnected.')
t('error.code[#pex182]', 'The presentation stream is unavailable.')
t('error.code[#pex183]', 'The screenshare was cancelled.')
t('error.code[#pex184]', 'Something went wrong with screenshare. Please try again.')
t('error.code[#pex185]', 'The screenshare was disconnected.')
t('error.code[#pex190]', 'Error connecting to the meeting.')
t('error.code[#pex191]', 'Error connecting to the meeting.')
t('error.code[#pex192]', 'Too many PIN entry attempts.')
t('error.code[#pex193]', 'Error connecting to the meeting. Please contact your administrator.')
t('error.code[#pex194]', 'This meeting has reached the maximum number of participants.')
t('error.code[#pex195]', 'Error connecting to the meeting. Please contact your administrator.')
t('error.code[#pex196]', 'A firewall is preventing you from joining')
t('error.code[#pex197]', "Sorry, we couldn't connect your call. Please close this window and try again.")
t('error.code[#pex200]', 'SSO authentication failed.')
t('error.code[#pex201]', 'SSO enabled but no Identity Providers configured.')
t('error.code[#pex202]', 'Unable to open window for SSO authentication. This may have been prevented by a pop-up blocker.')
t('error.code[#pex203]', 'SSO authentication failed. The system is in maintenance mode.')
t('error.code[#pex204]', 'SSO authentication failed. SSO is not available from this domain.')
*/
export const MeetingError: React.FC<{
    alias?: string;
    chooseAnotherMeeting: () => void;
    error?: ExtendedInfinityErrorMessage;
    errorCode?: ExtendedInfinityErrorCode;
    host?: string;
    rejoin: () => void;
}> = ({
    alias,
    chooseAnotherMeeting,
    error,
    errorCode,
    host = window.location.host,
    rejoin,
}) => {
    const {t} = useTranslation();

    const shouldShowRejoin = errorCode !== '#pex154';

    return (
        <BrandedFullSizeWindow
            padding="small"
            background="secondary"
            flexDirection="column"
            justifyContent="center"
        >
            <Header />
            <CenterLayout>
                <Section
                    className="text-center"
                    subtitle={error}
                    title={t(
                        [`error.code[${errorCode}]`, 'error.smth-went-wrong'],
                        {
                            defaultValue: 'Something went wrong',
                            alias,
                            host,
                        },
                    )}
                >
                    <RejoinChooseAnotherMeeting
                        className="mt-7 mb-7"
                        shouldShowRejoin={shouldShowRejoin}
                        onRejoinClick={rejoin}
                        onChooseAnotherMeetingClick={chooseAnotherMeeting}
                    />
                </Section>
            </CenterLayout>
            {errorCode && (
                <Bar
                    className="mb-7"
                    data-testid={TestId.ErrorScreenCode}
                    justifyContent="center"
                    position="absoluteBottom"
                >
                    <TextLink
                        href={`${ERROR_MESSAGES_URL}${errorCode}`}
                        target="_blank"
                    >
                        {errorCode}
                    </TextLink>
                </Bar>
            )}
        </BrandedFullSizeWindow>
    );
};
