import React, {useCallback, useEffect, useState} from 'react';

import {
    TestYourMicStages,
    MicrophoneMonitorModal,
} from '@pexip/media-components';
import type {ColorScheme} from '@pexip/components';
import {Audio} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {useAudioRecording} from '../hooks/useAudioRecording';
import {mediaService} from '../services/Media.service';
import {useSelectAudioDevices} from '../hooks/useSelectAudioDevices';
import {RecordingStates} from '../hooks/useAnimateFrequencyBars';

import {TestYouMicAudioMeter} from './TestYouMicAudioMeter.viewModel';

export const TestYourMicrophoneModal: React.FC<{
    devices: MediaDeviceInfoLike[];
    isModalOpen: boolean;
    iconColorScheme?: ColorScheme;
    closeOnOutsideClick?: boolean;
    currentPlaybackCount?: number;
    currentRecordingCount?: number;
    onAudioInputChange: (device: MediaDeviceInfoLike) => void;
    onAudioOutputChange: (device: MediaDeviceInfoLike) => void;
    selectedAudioInput?: MediaDeviceInfoLike;
    selectedAudioOutput?: MediaDeviceInfoLike;
    startTest: () => void;
    tryAgain: () => void;
    replay: () => void;
    testYourMicStages: TestYourMicStages;
    onClose: () => void;
    setTestYourMicStages: React.Dispatch<
        React.SetStateAction<TestYourMicStages>
    >;
}> = ({
    devices,
    isModalOpen,
    closeOnOutsideClick,
    currentPlaybackCount = 0,
    currentRecordingCount = 0,
    onAudioInputChange,
    onAudioOutputChange,
    selectedAudioInput,
    selectedAudioOutput,
    startTest,
    tryAgain,
    replay,
    testYourMicStages,
    onClose,
    setTestYourMicStages,
}) => {
    const [recordedStream, setRecordedStream] = useState<MediaStream>();
    const {objectURL, mediaRecorder, cleanup} = useAudioRecording(
        mediaService.media.stream,
    );

    const {audioInputs, audioOutputs} = useSelectAudioDevices(devices);

    useEffect(() => {
        const stopRecording = () => {
            if (mediaRecorder?.state !== 'inactive') {
                mediaRecorder?.stop();
            }
        };

        if (testYourMicStages === TestYourMicStages.Recording) {
            mediaRecorder?.start();
        } else if (testYourMicStages === TestYourMicStages.RecordingSuccess) {
            stopRecording();
        }

        return () => stopRecording();
    }, [testYourMicStages, mediaRecorder]);

    const onCloseWithCleanUp = useCallback(() => {
        cleanup();
        onClose();
    }, [onClose, cleanup]);

    const onTryAgain = useCallback(() => {
        cleanup();
        tryAgain();
    }, [cleanup, tryAgain]);

    const onReplay = () => {
        replay();
        setTestYourMicStages(TestYourMicStages.PlayingBack);
    };

    return (
        <>
            <MicrophoneMonitorModal
                audioInputs={audioInputs}
                audioOutputs={audioOutputs}
                isOpen={isModalOpen}
                currentStage={testYourMicStages}
                onClose={onCloseWithCleanUp}
                onAudioInputChange={onAudioInputChange}
                onAudioOutputChange={onAudioOutputChange}
                selectedAudioInput={selectedAudioInput}
                selectedAudioOutput={selectedAudioOutput}
                startTest={startTest}
                tryAgain={onTryAgain}
                replay={onReplay}
                closeOnOutsideClick={closeOnOutsideClick}
                audioMeter={
                    [
                        ...RecordingStates,
                        TestYourMicStages.PlayingBack,
                        TestYourMicStages.PlaybackFinished,
                    ].includes(testYourMicStages) ? (
                        <TestYouMicAudioMeter
                            currentStage={testYourMicStages}
                            setTestYourMicStages={setTestYourMicStages}
                            setRecordedStream={setRecordedStream}
                            objectURL={objectURL}
                            currentPlaybackCount={currentPlaybackCount}
                            currentRecordingCount={currentRecordingCount}
                        />
                    ) : undefined
                }
            />
            <Audio
                sinkId={selectedAudioOutput?.deviceId || ''}
                autoPlay
                loop={false}
                srcObject={recordedStream}
            />
        </>
    );
};
