import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Group,
    Icon,
    IconTypes,
    useTouchDevice,
    useMdDown,
} from '@pexip/components';
import type {InMeetingUI, usePresentation} from '@pexip/media-components';
import {ResponsiveButton} from '@pexip/media-components';

import {TestId} from '../../test/testIds';
import {MeetingHeaderView} from '../views/MeetingHeader/MeetingHeader.view';
import {useMe} from '../hooks/useMe';
import {infinityService} from '../services/InfinityClient.service';

import {UserMenu} from './UserMenu.viewModel';
import {Header} from './Header.viewModel';
import {ParticipantsPanelToggle} from './ParticipantsPanelToggle.viewModel';
import {ChatPanelToggle} from './ChatPanelToggle.viewModel';

export const MeetingHeader: React.FC<{
    inMeetingUI: InMeetingUI;
    openSettings: () => void;
    presentation: ReturnType<typeof usePresentation>;
}> = ({inMeetingUI, openSettings, presentation}) => {
    const me = useMe();
    const isMdDown = useMdDown();
    const {t} = useTranslation();
    const isTouchDevice = useTouchDevice();
    return (
        <MeetingHeaderView
            header={
                <Header
                    enhancerStart={
                        isMdDown ? (
                            <Group
                                spacing="compact"
                                className="ml-4 flex-shrink-0"
                            >
                                {infinityService.conferenceFeatureFlags
                                    ?.chatEnabled &&
                                    me && (
                                        <ChatPanelToggle
                                            inMeetingUI={inMeetingUI}
                                        />
                                    )}
                                <ParticipantsPanelToggle
                                    inMeetingUI={inMeetingUI}
                                    myIdentity={me?.identity}
                                />
                            </Group>
                        ) : (
                            <></>
                        )
                    }
                    enhancerEnd={
                        <Group spacing="small" className="mr-4 flex-shrink-0">
                            {presentation.isPresenting && !isTouchDevice && (
                                <ResponsiveButton
                                    enhancerStart={
                                        <Icon
                                            source={IconTypes.IconDualScreen}
                                            size="compact"
                                        />
                                    }
                                    onClick={
                                        presentation.poppedOut
                                            .toggleIsPresentationPoppedOut
                                    }
                                    isActive={
                                        presentation.poppedOut
                                            .isPresentationPoppedOut
                                    }
                                    {...inMeetingUI.autoHideProps}
                                >
                                    <Trans
                                        t={t}
                                        i18nKey="media.presentation.dual-screen-mode"
                                    >
                                        Dual-screen mode
                                    </Trans>
                                </ResponsiveButton>
                            )}
                            {!isTouchDevice && (
                                <ResponsiveButton
                                    data-testid={
                                        TestId.ButtonMeetingDeviceSettings
                                    }
                                    enhancerStart={
                                        <Icon
                                            source={IconTypes.IconSettings}
                                            size="compact"
                                        />
                                    }
                                    onClick={openSettings}
                                    variant="translucent"
                                    {...inMeetingUI.autoHideProps}
                                >
                                    <Trans t={t} i18nKey="settings.settings">
                                        Settings
                                    </Trans>
                                </ResponsiveButton>
                            )}
                            <UserMenu
                                isInMeeting
                                inMeetingUI={inMeetingUI}
                                autoHideProps={inMeetingUI.autoHideProps}
                                variant="bordered"
                            />
                        </Group>
                    }
                />
            }
        />
    );
};
