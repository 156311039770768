import type {Tensor3D} from '@tensorflow/tfjs-core';

import type {Canvas, Transform} from '../types';

export const RENDER_EFFECTS = [
    /**
     * Pass through, and thus there is no effects applied
     */
    'none',
    /**
     * Background blur effects
     */
    'blur',
    /**
     * Background overlay effects
     */
    'overlay',
] as const;

export const SEG_MODELS = [
    /**
     * Mediapipe's Selfie Segmentation model
     */
    'mediapipeSelfie',
    /**
     * Personify's Segmentation model
     */
    'personify',
] as const;

/**
 * Interfaces from "tensorflow-models/body-segmentation" interfaces
 */
export type MaskUnderlyingType = 'canvasimagesource' | 'imagedata' | 'tensor';
export interface Mask {
    toCanvasImageSource(): Promise<CanvasImageSource>;
    toImageData(): Promise<ImageData>;
    toTensor(): Promise<Tensor3D>;
    getUnderlyingType(): MaskUnderlyingType;
}
/**
 * Interfaces from "tensorflow-models/body-segmentation" interfaces
 */
export interface Segmentation {
    maskValueToLabel: (maskValue: number) => string;
    mask: Mask;
}

export type Color = {
    r: number;
    g: number;
    b: number;
    a: number;
};

export type SegmentationModel = typeof SEG_MODELS[number];

export type RenderEffects = typeof RENDER_EFFECTS[number];

export type ProcessInputType =
    | ImageData
    | HTMLVideoElement
    | HTMLImageElement
    | OffscreenCanvas
    | HTMLCanvasElement
    | ImageBitmap;

export type InputFrame = CanvasImageSource | VideoFrame;
export type ImageType = CanvasImageSource | ProcessInputType | VideoFrame;
/**
 * A keypoint that contains coordinate information.
 */
export interface Keypoint {
    x: number;
    y: number;
    z?: number;
    score?: number; // The probability of a keypoint's visibility.
    name?: string;
}

export interface BoundingBox {
    xMin: number;
    yMin: number;
    xMax: number;
    yMax: number;
    width: number;
    height: number;
}

export interface Face {
    keypoints: Keypoint[]; // Points of mesh in the detected face.
    // MediaPipeFaceMesh has 468 keypoints.
    box: BoundingBox; // A bounding box around the detected face.
}

interface ProcessingSize {
    /**
     * Processing Width size
     */
    width: number;
    /**
     * Processing height size
     */
    height: number;
}

export interface RenderParams extends ProcessingSize {
    /**
     * Range in between 0 and 1
     * @defaultValue `0.5`
     */
    foregroundThreshold: number;
    /**
     * Range in between 0 and 20
     * @defaultValue `3`
     */
    backgroundBlurAmount: number;
    /**
     * Range in between 0 and 20
     * @defaultValue `3`
     */
    edgeBlurAmount: number;
    /**
     * @defaultValue `false`
     */
    flipHorizontal: boolean;
    /**
     * @defaultValue `none`
     */
    effects: RenderEffects;
    /**
     * Background image used for background replacement
     */
    backgroundImage: Canvas | undefined;
}

export interface AsyncAssets {
    tfjsCoreLoaded: boolean;
    tfjsBackendLoaded: boolean;
    glueLoaded: boolean;
}

export type ProcessStatus =
    | 'created'
    | 'opened'
    | 'opening'
    | 'processing'
    | 'idle'
    | 'closed'
    | 'destroying'
    | 'destroyed';

export interface Process {
    status: ProcessStatus;
    open(): Promise<void>;
    close(): void;
    destroy(): Promise<void>;
}

export interface Segmenter extends Process, ProcessingSize {
    model: SegmentationModel;
    process(input: ProcessInputType): Promise<Segmentation[]>;
}

export interface Detector<T> extends Process {
    detect(input: ProcessInputType): Promise<T>;
}

export interface SegmentationParams extends RenderParams {
    segmenter: Segmenter;
    loadBackgroundImage(url: string): Promise<void>;
    backgroundImageUrl?: string;
}
export type SegmentationTransform = Transform<InputFrame, InputFrame> &
    SegmentationParams &
    Omit<Process, 'open'>;
