import {useEffect} from 'react';

import {notificationToastSignal} from '@pexip/components';
import {NetworkState} from '@pexip/media-components';

import {infinityClientSignals} from '../signals/InfinityClient.signals';

export const useOnFailedInfinityRequest = (networkState: NetworkState) => {
    useEffect(() => {
        if (networkState !== NetworkState.Reconnecting) {
            return infinityClientSignals.onFailedRequest.add(request => {
                notificationToastSignal.emit([
                    {
                        message: `Failed to send '${request}'. Please check your network.`,
                        timeout: 5000,
                        isDanger: true,
                        isInterrupt: true,
                    },
                ]);
            });
        }
    }, [networkState]);
};
