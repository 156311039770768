import {useCallback, useEffect, useState} from 'react';

import {
    createAnalyzerGraphNode,
    createAudioGraph,
    createStreamDestinationGraphNode,
    createMediaElementSourceGraphNode,
} from '@pexip/media-processor';
import type {AnalyzerNodeInit, AudioGraph} from '@pexip/media-processor';

import test from '../assets/test.flac';
import {logger} from '../logger';
import {VISUALIZER_FFT_SIZE} from '../constants';

type MediaElementSetup = {
    source?: string;
    fftSize?: number;
    loop?: boolean;
    waitTimeBeforePlayback?: number;
};

export const useMediaElementAnalyzer = (
    mediaElementSetup: MediaElementSetup = {},
) => {
    const {
        source = test,
        fftSize = VISUALIZER_FFT_SIZE,
        loop = true,
        waitTimeBeforePlayback = 0,
    } = mediaElementSetup;
    const [mediaElement, setMediaElement] = useState(() => new Audio(source));
    const [audioGraph, setAudioGraph] = useState<AudioGraph>();
    const [analyzer, setAnalyzer] = useState<AnalyzerNodeInit>();
    const [outputStream, setOutputStream] = useState<MediaStream>();

    useEffect(() => {
        const graph = createAudioGraph([]);
        setAudioGraph(graph);
        return () => {
            setAudioGraph(undefined);
            void graph.release();
        };
    }, []);

    useEffect(() => {
        let timeout: number;

        const play = () => {
            timeout = window.setTimeout(() => {
                mediaElement.play().catch(() => {
                    logger.warn(`Can't play audio`);
                });
            }, waitTimeBeforePlayback);
        };

        mediaElement.loop = loop;

        mediaElement.addEventListener('canplaythrough', play);

        return () => {
            mediaElement.removeEventListener('canplaythrough', play);
            mediaElement?.pause();
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [mediaElement, loop, waitTimeBeforePlayback]);

    useEffect(() => {
        if (!mediaElement || !audioGraph) {
            return;
        }

        const sourceNode = createMediaElementSourceGraphNode(mediaElement);
        const analyzerNode = createAnalyzerGraphNode({fftSize});
        const destinationNode = createStreamDestinationGraphNode();
        const connection = [sourceNode, analyzerNode, destinationNode];
        audioGraph.connect(connection);

        setAnalyzer(analyzerNode);
        setOutputStream(destinationNode.node?.stream);

        return () => {
            audioGraph?.disconnect(connection);
            setAnalyzer(undefined);
            setOutputStream(undefined);
        };
    }, [mediaElement, fftSize, audioGraph]);

    useEffect(() => {
        const newElement = new Audio(source);
        setMediaElement(newElement);
    }, [source]);

    const toggle = useCallback(() => {
        if (!mediaElement) {
            return;
        }
        mediaElement.paused ? void mediaElement.play() : mediaElement.pause();
    }, [mediaElement]);

    return {
        toggle,
        analyzer,
        outputStream,
        mediaElement,
    };
};
