import {useEffect, useState} from 'react';

import type {Signal} from '@pexip/signal';

export const getShouldRenderUserNamePageHook =
    (renderUserNamePageSignal: Signal<boolean>) => () => {
        const [shouldRenderUserNamePage, setShouldRenderUserNamePage] =
            useState(true);

        useEffect(() => {
            renderUserNamePageSignal.add(shouldRender => {
                setShouldRenderUserNamePage(shouldRender);
            });
        }, []);

        return shouldRenderUserNamePage;
    };
