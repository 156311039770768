import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icon, IconTypes} from '@pexip/components';

import styles from './SpeakerToggleButton.module.scss';

/*
  t('media.play', 'Play')
  t('media.stop-playing', 'Stop playing')
*/

export const SpeakerToggleButton: React.FC<{
    toggle: () => void;
    paused: boolean;
}> = ({toggle, paused}) => {
    const {t} = useTranslation();

    return (
        <Button
            className={styles.startStopButton}
            variant="secondary"
            onClick={toggle}
            enhancerStart={
                <Icon
                    source={
                        paused
                            ? IconTypes.IconPlayRound
                            : IconTypes.IconStopRound
                    }
                />
            }
        >
            {t(`media.${paused ? 'play' : 'stop-playing'}`, 'Stop playing')}
        </Button>
    );
};

export type SpeakerToggleButton = React.ComponentProps<
    typeof SpeakerToggleButton
>;
