import React from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {
    TextHeading,
    FullSizeWindow,
    PexipLogo,
    FontVariant,
    AspectRatio,
} from '@pexip/components';

import {useWindowOrientation} from '../../hooks/useWindowOrientation';

import styles from './PreflightContent.mobile.module.scss';

export const portraitDimensions = {width: 9, height: 16};
export const landscapeDimensions = {width: 16, height: 9};

export const PreflightContentMobile: React.FC<
    React.PropsWithChildren<{
        isPhone: boolean;
        media: React.ReactElement;
        shouldShowAspectRatio: boolean;
    }>
> = ({children, isPhone, media, shouldShowAspectRatio}) => {
    const {t} = useTranslation();
    const {isPortrait} = useWindowOrientation();

    return (
        <FullSizeWindow
            background="secondary"
            flexDirection="column"
            justifyContent={isPhone ? 'flexStart' : 'center'}
            padding="none"
        >
            <PexipLogo
                aria-label={t('common.label-pexip-logo', 'Pexip logo')}
                className="mb-5 mt-4"
                colorScheme="dark"
                size="small"
            />
            <TextHeading
                className={isPhone ? 'mb-4' : 'mb-5'}
                fontVariant={FontVariant.H4}
                htmlTag="h1"
            >
                <Trans t={t} i18nKey="preflight.get-ready">
                    Get ready for your meeting
                </Trans>
            </TextHeading>
            <div
                className={cx(styles.selfView, {
                    [styles.portrait]: isPortrait,
                    [styles.withVideo]: shouldShowAspectRatio,
                })}
            >
                <AspectRatio
                    className="justify-content-center"
                    dimensions={
                        isPortrait ? portraitDimensions : landscapeDimensions
                    }
                    isActive={shouldShowAspectRatio}
                >
                    {media}
                </AspectRatio>
            </div>
            {children}
        </FullSizeWindow>
    );
};

export type PreflightContentMobileProps = React.ComponentProps<
    typeof PreflightContentMobile
>;
