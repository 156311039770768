import React, {useMemo, useLayoutEffect, useState} from 'react';

import type {
    RouteMatchProp,
    MatchComponentType,
    Router,
    RouterHooks,
    Routes,
    CreateRoutesHook,
} from '../types';
import {
    matchRoutes,
    completeRoutePaths,
    matchRoutesWithSubroutes,
    createRouteElementCreator,
    //findRoute,
} from '../matcher';
import type {Url} from '../url';
import {buildUrl, isInternalUrl, joinPath} from '../url';

export const createRouteElements = createRouteElementCreator<
    MatchComponentType,
    React.ReactElement<RouteMatchProp>
>((route, children) => {
    return React.createElement(
        route.route.node,
        {key: route.route.path, match: route.match},
        children,
    );
});

interface RouterProps<T extends MatchComponentType> {
    routesList: Routes<T>[];
    subroutesMap: Record<string, Routes<T>[]>;
}

export const createRouterHooks = <T extends MatchComponentType>(
    router: Router,
): RouterHooks => {
    const props: RouterProps<T> = {
        routesList: [],
        subroutesMap: {},
    };

    const useRouter = () => {
        useLayoutEffect(() => {
            const handlePopstate = (event: PopStateEvent) => {
                const href = router.currentHref;
                const url = buildUrl(href, {
                    state: event.state as Url['state'],
                });
                router.urlChangedSignal.emit(url);
            };

            addEventListener('popstate', handlePopstate);

            return () => {
                removeEventListener('popstate', handlePopstate);
            };
        }, []);

        useLayoutEffect(() => {
            const handleLoad = () => {
                const href = router.currentHref;
                const url = buildUrl(href, {
                    state: router.history.state,
                });
                router.urlChangedSignal.emit(url);
            };

            addEventListener('load', handleLoad);

            return () => {
                removeEventListener('load', handleLoad);
            };
        }, []);
    };

    const createRoutesHook: CreateRoutesHook = (
        routes,
        match = matchRoutes,
    ) => {
        return (
            currentUrl = buildUrl(router.currentHref, {
                state: router.history.state,
            }),
        ): React.ReactElement<RouteMatchProp>[] => {
            const [url, setUrl] = useState<Url>(currentUrl);

            /**
             * We want this effect is fired in the same phase as
             * `componentDidMount` and `componentDidUpdate` since we want to
             * catch the signals from the predefined routes to re-render the
             * component
             */
            useLayoutEffect(() => {
                const handleUrlRequest = (urlRequest: Url) => {
                    if (isInternalUrl(urlRequest)) {
                        setUrl(urlRequest);
                    }
                };

                const removeUrlChangedSignal =
                    router.urlChangedSignal.add(setUrl);
                const removeUrlRequestSignal =
                    router.urlRequestSignal.add(handleUrlRequest);

                return () => {
                    removeUrlChangedSignal();
                    removeUrlRequestSignal();
                };
            }, [setUrl]);

            // Memo the recursive functions depends on the `routes` and url.path
            const matchedRoutes = useMemo(() => match(routes, url), [url]);
            const elements = useMemo(
                () => createRouteElements(matchedRoutes),
                [matchedRoutes],
            );

            return elements;
        };
    };

    const createMainRoutesHook = (partialRoutes: Routes<T>) => {
        const routes = completeRoutePaths(partialRoutes, router.baseUri);
        props.routesList.push(routes);
        const match = matchRoutesWithSubroutes(
            parentPath => props.subroutesMap[parentPath] ?? [],
        );

        return createRoutesHook(routes, match);
    };

    const createSubRoutesHook = (
        partialSubRoutes: Routes<T>,
        parentPath: string,
    ) => {
        const keyPath = joinPath(parentPath, router.baseUri);
        const subroutes = completeRoutePaths(partialSubRoutes, keyPath);
        if (!props.subroutesMap[keyPath]) {
            props.subroutesMap[keyPath] = [subroutes];
        } else {
            props.subroutesMap[keyPath]?.push(subroutes);
        }
        return createRoutesHook(subroutes);
    };

    return {
        useRouter,
        createRoutesHook: createMainRoutesHook,
        createSubRoutesHook,
    };
};
