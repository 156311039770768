import {createSignal} from '@pexip/signal';
import type {RemoveParticipantSignal} from '@pexip/media-components';

export const transferParticipantSignal = createSignal<string>({
    name: 'participant:transfer',
});

export const removeParticipantSignal = createSignal<RemoveParticipantSignal>({
    name: 'participant:remove',
});
