import React, {Fragment} from 'react';
import cx from 'classnames';

import {Box} from '../../elements/Box/Box';
import {Text} from '../../elements/Text/Text';
import {ListLink} from '../../elements/ListLink/ListLink';
import {Divider} from '../../elements/Divider/Divider';
import {List} from '../List/List';
import {Row} from '../List/Row';
import {Icon} from '../../elements/Icon/Icon';
import {ThemeProvider} from '../../../themes/ThemeContext';
import type {ColorScheme} from '../../../types/variants';

import type {MenuContent, MenuItem} from './types';

import styles from './Menu.module.scss';

export const MenuEntry: React.FC<{item: MenuItem}> = ({item}) => {
    const hasWrapper = item.linkWrapper;

    if (item.url || item.clickAction || item.isDisabled) {
        return (
            <ListLink
                aria-checked={item.isAriaChecked}
                aria-label={
                    !hasWrapper && item.target === '_blank'
                        ? `${item.label} (Opens in a new tab)`
                        : undefined
                }
                aria-selected={item.isAriaSelected}
                data-testid={item.testId}
                href={!hasWrapper ? item.url : undefined}
                icon={item.icon}
                iconColor={item.iconColor}
                isDisabled={item.isDisabled}
                isFixedIcon={item.isFixedIcon}
                onClick={item.clickAction}
                role={item.role}
                target={!hasWrapper ? item.target : undefined}
                variant={item.variant ?? 'inherit'}
            >
                {item.label}
            </ListLink>
        );
    }

    return (
        <Row
            aria-checked={item.isAriaChecked}
            aria-selected={item.isAriaSelected}
            role={item.role}
        >
            <Text className="pr-1 pl-1">{item.label}</Text>
            {item.icon && (
                <Icon
                    source={item.icon}
                    size="compact"
                    color={item.iconColor}
                />
            )}
        </Row>
    );
};

const sectionKey = (section: MenuItem[]) =>
    section.map(item => item.label).join(':');

export const Menu: React.FC<{
    className?: string;
    colorScheme?: ColorScheme;
    isAriaMultiSelectable?: boolean;
    menuContent: MenuContent;
    role?: React.AriaRole;
    tabIndex?: number;
    testId?: string;
}> = ({
    className,
    colorScheme = 'light',
    isAriaMultiSelectable,
    menuContent,
    role,
    tabIndex,
    testId,
}) => {
    return (
        <ThemeProvider colorScheme={colorScheme}>
            <Box data-testid={testId} className={cx(styles.menu, className)}>
                {menuContent.map((section, i) => {
                    const lastSection = menuContent.length - 1 === i;
                    return (
                        <Fragment key={sectionKey(section)}>
                            <List
                                aria-multiselectable={isAriaMultiSelectable}
                                className="p-1"
                                role={role}
                                spacing="compact"
                                tabIndex={tabIndex}
                            >
                                {section.map((item, index) => {
                                    const Wrapper = item.linkWrapper;

                                    return Wrapper ? (
                                        <Wrapper
                                            key={`${item.label}-wrapper-${index}`}
                                        >
                                            <MenuEntry item={item} />
                                        </Wrapper>
                                    ) : (
                                        <MenuEntry
                                            key={`${item.label}-entry-${index}`}
                                            item={item}
                                        />
                                    );
                                })}
                            </List>
                            {!lastSection && <Divider />}
                        </Fragment>
                    );
                })}
            </Box>
        </ThemeProvider>
    );
};
