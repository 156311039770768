import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import type {UserMediaStatus} from '@pexip/media';
import {
    isDeviceInUse,
    isGrantedOnlyAudio,
    isGrantedOnlyVideo,
} from '@pexip/media';
import type {ExtendedSizeModifier, GroupProps} from '@pexip/components';
import {Button, Icon, IconTypes, Group} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import type {DeviceStatusInfo} from '../../types';
import type {TooltipPosition} from '../InputControl/PreflightInputControl.view';
import {PreflightInputControl} from '../InputControl/PreflightInputControl.view';
import {useInputsState} from '../../hooks/useInputsState';
import {BackgroundBlurInputControl} from '../InputControl/InputControl.view';

import styles from './PreflightInputControls.module.scss';

export const PreflightInputControls: React.FC<
    GroupProps & {
        audioInputControl?: React.ReactNode;
        enhancerEnd?: React.ReactNode;
        inputStatusInfo: DeviceStatusInfo;
        isAudioInputMuted: boolean;
        isBlurred?: boolean;
        isMobileDevice?: boolean;
        isVideoInputMuted: boolean;
        requestMediaPermissions: () => void;
        segmentationEnabled?: boolean;
        silentTooltipInfo?: DeviceStatusInfo;
        streamStatus?: UserMediaStatus;
        miniButtons?: boolean;
        toggleAudioInput?: () => void;
        toggleBlur?: () => void;
        toggleVideoInput?: () => void;
        toggleFacingMode?: () => void;
    }
> = ({
    audioInputControl,
    enhancerEnd,
    inputStatusInfo,
    isAudioInputMuted,
    isBlurred,
    isMobileDevice = false,
    isVideoInputMuted,
    requestMediaPermissions,
    segmentationEnabled,
    silentTooltipInfo,
    streamStatus,
    miniButtons,
    toggleAudioInput,
    toggleBlur,
    toggleVideoInput,
    toggleFacingMode,
    ...props
}) => {
    const {t} = useTranslation();

    const {
        audioProps,
        videoProps,
        warning,
        isAudioIconMuted,
        isVideoIconMuted,
    } = useInputsState({
        isAudioInputMuted,
        isVideoInputMuted,
        inputStatusInfo,
        isMobileDevice,
    });

    const [isAudioTooltipVisible, setIsAudioTooltipVisible] = useState(
        warning === 'audio',
    );
    const [isVideoTooltipVisible, setIsVideoTooltipVisible] = useState(
        warning === 'audio-video',
    );

    useEffect(() => {
        if (silentTooltipInfo && !isVideoTooltipVisible) {
            setIsAudioTooltipVisible(true);
        } else {
            setIsAudioTooltipVisible(false);
        }
    }, [isVideoTooltipVisible, silentTooltipInfo]);

    useEffect(() => {
        setIsVideoTooltipVisible(
            warning === 'video' || warning === 'audio-video',
        );
    }, [warning]);

    useEffect(() => {
        setIsAudioTooltipVisible(warning === 'audio');
    }, [warning]);

    const shouldShowAudioControl =
        !!streamStatus && !isGrantedOnlyVideo(streamStatus);
    const shouldShowVideoControl =
        !!streamStatus && !isGrantedOnlyAudio(streamStatus);

    const buttonClassName = cx(
        isMobileDevice && miniButtons ? styles.miniButton : undefined,
    );

    const coreInputProps: {
        className: string;
        size: ExtendedSizeModifier;
        tooltipPosition: TooltipPosition;
    } = {
        className: buttonClassName,
        size: isMobileDevice ? 'compact' : 'medium',
        tooltipPosition: isMobileDevice ? 'topRight' : 'topLeft',
    };

    return (
        <Group
            spacing={isMobileDevice ? 'compact' : 'small'}
            justifyContent="center"
            {...props}
        >
            {shouldShowAudioControl && (
                <PreflightInputControl
                    {...audioProps}
                    {...coreInputProps}
                    inputStatusInfo={silentTooltipInfo ?? inputStatusInfo}
                    isTooltipVisible={isAudioTooltipVisible}
                    setIsTooltipVisible={setIsAudioTooltipVisible}
                    toolTipTestId={TestId.NotificationToolTipAudioProblem}
                    data-testid={
                        isAudioIconMuted
                            ? TestId.ButtonAudioMeterMuted
                            : TestId.ButtonAudioMeter
                    }
                    onClick={toggleAudioInput}
                    requestMediaPermissions={requestMediaPermissions}
                    shouldShowRetry={
                        !!streamStatus &&
                        !silentTooltipInfo &&
                        isDeviceInUse(streamStatus)
                    }
                    testIdCloseButton={
                        TestId.NotificationToolTipAudioCloseButton
                    }
                    variant={isMobileDevice ? 'tertiary' : undefined}
                    audioInputControl={audioInputControl}
                />
            )}
            {shouldShowVideoControl && (
                <PreflightInputControl
                    {...videoProps}
                    {...coreInputProps}
                    inputStatusInfo={inputStatusInfo}
                    isTooltipVisible={isVideoTooltipVisible}
                    setIsTooltipVisible={setIsVideoTooltipVisible}
                    toolTipTestId={TestId.NotificationToolTipVideoProblem}
                    data-testid={
                        isVideoIconMuted
                            ? TestId.ButtonVideoinputMuted
                            : TestId.ButtonVideoInput
                    }
                    onClick={toggleVideoInput}
                    requestMediaPermissions={requestMediaPermissions}
                    testIdCloseButton={
                        TestId.NotificationToolTipVideoCloseButton
                    }
                    shouldShowRetry={
                        !!streamStatus && isDeviceInUse(streamStatus)
                    }
                    variant={isMobileDevice ? 'translucent' : undefined}
                />
            )}
            {!isMobileDevice &&
                !!segmentationEnabled &&
                !videoProps.hasError && (
                    <BackgroundBlurInputControl
                        variant={isMobileDevice ? 'translucent' : undefined}
                        size={isMobileDevice ? 'compact' : 'medium'}
                        isActive={isBlurred}
                        isDisabled={isVideoInputMuted}
                        onClick={toggleBlur}
                        iconSize={isMobileDevice ? 'mini' : 'small'}
                        data-testid={
                            isBlurred
                                ? TestId.ButtonBackgroundEffectsOn
                                : TestId.ButtonBackgroundEffectsOff
                        }
                    />
                )}
            {isMobileDevice && shouldShowVideoControl && !videoProps.hasError && (
                <Button
                    className={buttonClassName}
                    aria-label={t('media.flip-camera', 'Flip camera')}
                    data-testid={TestId.ButtonFlipCamera}
                    modifier="square"
                    onClick={toggleFacingMode}
                    size={isMobileDevice ? 'compact' : 'medium'}
                    variant="translucent"
                    disabled={isVideoInputMuted}
                >
                    <Icon
                        source={IconTypes.IconFlipCamera}
                        size={isMobileDevice ? 'compact' : 'small'}
                    />
                </Button>
            )}
            {enhancerEnd && enhancerEnd}
        </Group>
    );
};

export type PreflightInputControlsProps = React.ComponentProps<
    typeof PreflightInputControls
>;
