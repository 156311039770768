export const validate200 = validate15;
const schema17 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {$ref: '#/properties/200/definitions/Result'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {
                    type: 'string',
                    description: '`call_uuid` is used to control the call',
                },
                sdp: {type: 'string', description: 'SDP answer from the mcu'},
                turn: {
                    type: 'array',
                    items: {$ref: '#/properties/200/definitions/Turn'},
                },
            },
            required: ['call_uuid', 'sdp'],
            title: 'Result',
        },
        Turn: {
            type: 'object',
            additionalProperties: false,
            properties: {
                urls: {type: 'array', items: {type: 'string'}},
                username: {type: 'string'},
                credential: {type: 'string'},
            },
            required: ['urls', 'username', 'credential'],
            title: 'Turn',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {$ref: '#/properties/200/definitions/Result'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {
            type: 'string',
            description: '`call_uuid` is used to control the call',
        },
        sdp: {type: 'string', description: 'SDP answer from the mcu'},
        turn: {
            type: 'array',
            items: {$ref: '#/properties/200/definitions/Turn'},
        },
    },
    required: ['call_uuid', 'sdp'],
    title: 'Result',
};
const schema14 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        urls: {type: 'array', items: {type: 'string'}},
        username: {type: 'string'},
        credential: {type: 'string'},
    },
    required: ['urls', 'username', 'credential'],
    title: 'Turn',
};
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_uuid === undefined && (missing0 = 'call_uuid')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                validate12.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_uuid' ||
                            key0 === 'sdp' ||
                            key0 === 'turn'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.call_uuid !== undefined) {
                        const _errs2 = errors;
                        if (typeof data.call_uuid !== 'string') {
                            validate12.errors = [
                                {
                                    instancePath: instancePath + '/call_uuid',
                                    schemaPath: '#/properties/call_uuid/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.sdp !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.sdp !== 'string') {
                                validate12.errors = [
                                    {
                                        instancePath: instancePath + '/sdp',
                                        schemaPath: '#/properties/sdp/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.turn !== undefined) {
                                let data2 = data.turn;
                                const _errs6 = errors;
                                if (errors === _errs6) {
                                    if (Array.isArray(data2)) {
                                        var valid1 = true;
                                        const len0 = data2.length;
                                        for (let i0 = 0; i0 < len0; i0++) {
                                            let data3 = data2[i0];
                                            const _errs8 = errors;
                                            const _errs9 = errors;
                                            if (errors === _errs9) {
                                                if (
                                                    data3 &&
                                                    typeof data3 == 'object' &&
                                                    !Array.isArray(data3)
                                                ) {
                                                    let missing1;
                                                    if (
                                                        (data3.urls ===
                                                            undefined &&
                                                            (missing1 =
                                                                'urls')) ||
                                                        (data3.username ===
                                                            undefined &&
                                                            (missing1 =
                                                                'username')) ||
                                                        (data3.credential ===
                                                            undefined &&
                                                            (missing1 =
                                                                'credential'))
                                                    ) {
                                                        validate12.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/turn/' +
                                                                    i0,
                                                                schemaPath:
                                                                    '#/properties/200/definitions/Turn/required',
                                                                keyword:
                                                                    'required',
                                                                params: {
                                                                    missingProperty:
                                                                        missing1,
                                                                },
                                                                message:
                                                                    "must have required property '" +
                                                                    missing1 +
                                                                    "'",
                                                            },
                                                        ];
                                                        return false;
                                                    } else {
                                                        const _errs11 = errors;
                                                        for (const key1 in data3) {
                                                            if (
                                                                !(
                                                                    key1 ===
                                                                        'urls' ||
                                                                    key1 ===
                                                                        'username' ||
                                                                    key1 ===
                                                                        'credential'
                                                                )
                                                            ) {
                                                                delete data3[
                                                                    key1
                                                                ];
                                                            }
                                                        }
                                                        if (
                                                            _errs11 === errors
                                                        ) {
                                                            if (
                                                                data3.urls !==
                                                                undefined
                                                            ) {
                                                                let data4 =
                                                                    data3.urls;
                                                                const _errs12 =
                                                                    errors;
                                                                if (
                                                                    errors ===
                                                                    _errs12
                                                                ) {
                                                                    if (
                                                                        Array.isArray(
                                                                            data4,
                                                                        )
                                                                    ) {
                                                                        var valid4 =
                                                                            true;
                                                                        const len1 =
                                                                            data4.length;
                                                                        for (
                                                                            let i1 = 0;
                                                                            i1 <
                                                                            len1;
                                                                            i1++
                                                                        ) {
                                                                            const _errs14 =
                                                                                errors;
                                                                            if (
                                                                                typeof data4[
                                                                                    i1
                                                                                ] !==
                                                                                'string'
                                                                            ) {
                                                                                validate12.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/turn/' +
                                                                                                i0 +
                                                                                                '/urls/' +
                                                                                                i1,
                                                                                            schemaPath:
                                                                                                '#/properties/200/definitions/Turn/properties/urls/items/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid4 =
                                                                                _errs14 ===
                                                                                errors;
                                                                            if (
                                                                                !valid4
                                                                            ) {
                                                                                break;
                                                                            }
                                                                        }
                                                                    } else {
                                                                        validate12.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/turn/' +
                                                                                        i0 +
                                                                                        '/urls',
                                                                                    schemaPath:
                                                                                        '#/properties/200/definitions/Turn/properties/urls/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'array',
                                                                                    },
                                                                                    message:
                                                                                        'must be array',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                }
                                                                var valid3 =
                                                                    _errs12 ===
                                                                    errors;
                                                            } else {
                                                                var valid3 =
                                                                    true;
                                                            }
                                                            if (valid3) {
                                                                if (
                                                                    data3.username !==
                                                                    undefined
                                                                ) {
                                                                    const _errs16 =
                                                                        errors;
                                                                    if (
                                                                        typeof data3.username !==
                                                                        'string'
                                                                    ) {
                                                                        validate12.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/turn/' +
                                                                                        i0 +
                                                                                        '/username',
                                                                                    schemaPath:
                                                                                        '#/properties/200/definitions/Turn/properties/username/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs16 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data3.credential !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs18 =
                                                                            errors;
                                                                        if (
                                                                            typeof data3.credential !==
                                                                            'string'
                                                                        ) {
                                                                            validate12.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/turn/' +
                                                                                            i0 +
                                                                                            '/credential',
                                                                                        schemaPath:
                                                                                            '#/properties/200/definitions/Turn/properties/credential/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'string',
                                                                                        },
                                                                                        message:
                                                                                            'must be string',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs18 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    validate12.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/turn/' +
                                                                i0,
                                                            schemaPath:
                                                                '#/properties/200/definitions/Turn/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'object',
                                                            },
                                                            message:
                                                                'must be object',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                            var valid1 = _errs8 === errors;
                                            if (!valid1) {
                                                break;
                                            }
                                        }
                                    } else {
                                        validate12.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/turn',
                                                schemaPath:
                                                    '#/properties/turn/type',
                                                keyword: 'type',
                                                params: {type: 'array'},
                                                message: 'must be array',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                        }
                    }
                }
            }
        } else {
            validate12.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema12.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            const _errs4 = errors;
                            if (
                                !validate12(data.result, {
                                    instancePath: instancePath + '/result',
                                    parentData: data,
                                    parentDataProperty: 'result',
                                    rootData,
                                })
                            ) {
                                vErrors =
                                    vErrors === null
                                        ? validate12.errors
                                        : vErrors.concat(validate12.errors);
                                errors = vErrors.length;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
export const validate400 = validate17;
const schema18 = {type: 'string'};
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate17.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validate403 = validate18;
const schema19 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {type: 'string', enum: ['Invalid token']},
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [{$ref: '#/properties/403/definitions/403'}],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {type: 'string', enum: ['Invalid token']},
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (!(data1 === 'Invalid token')) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath: '#/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate18.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
export const validate500 = validate19;
const schema21 = {type: 'string'};
function validate19(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate19.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate19.errors = vErrors;
    return errors === 0;
}
export const validateBody = validate20;
const schema22 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_type: {
                    type: 'string',
                    enum: ['WEBRTC', 'RTMP'],
                    description:
                        '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
                },
                sdp: {
                    type: 'string',
                    description: 'Contains the SDP of the sender.',
                },
                present: {
                    type: 'string',
                    enum: ['send', 'receive'],
                    description:
                        'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
                },
                fecc_supported: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Set to true if this participant can be sent FECC messages; false if not.',
                },
            },
            required: ['call_type', 'sdp'],
            title: 'TopLevel',
        },
    },
    oneOf: [{$ref: '#/properties/Body/definitions/TopLevel'}],
};
const schema16 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_type: {
            type: 'string',
            enum: ['WEBRTC', 'RTMP'],
            description:
                '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
        },
        sdp: {type: 'string', description: 'Contains the SDP of the sender.'},
        present: {
            type: 'string',
            enum: ['send', 'receive'],
            description:
                'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
        },
        fecc_supported: {
            type: 'boolean',
            default: false,
            description:
                'Set to true if this participant can be sent FECC messages; false if not.',
        },
    },
    required: ['call_type', 'sdp'],
    title: 'TopLevel',
};
function validate20(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_type === undefined && (missing0 = 'call_type')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_type' ||
                            key0 === 'sdp' ||
                            key0 === 'present' ||
                            key0 === 'fecc_supported'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.call_type !== undefined) {
                        let data0 = data.call_type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/properties/Body/definitions/TopLevel/properties/call_type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'WEBRTC' || data0 === 'RTMP')) {
                            const err2 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema16.properties.call_type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.sdp !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.sdp !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/sdp',
                                    schemaPath:
                                        '#/properties/Body/definitions/TopLevel/properties/sdp/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.present !== undefined) {
                                let data2 = data.present;
                                const _errs9 = errors;
                                if (typeof data2 !== 'string') {
                                    const err4 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/properties/Body/definitions/TopLevel/properties/present/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                if (
                                    !(data2 === 'send' || data2 === 'receive')
                                ) {
                                    const err5 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/properties/Body/definitions/TopLevel/properties/present/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema16.properties.present
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.fecc_supported !== undefined) {
                                    const _errs11 = errors;
                                    if (
                                        typeof data.fecc_supported !== 'boolean'
                                    ) {
                                        const err6 = {
                                            instancePath:
                                                instancePath +
                                                '/fecc_supported',
                                            schemaPath:
                                                '#/properties/Body/definitions/TopLevel/properties/fecc_supported/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err7 = {
                instancePath,
                schemaPath: '#/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err7];
            } else {
                vErrors.push(err7);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err8 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err8];
        } else {
            vErrors.push(err8);
        }
        errors++;
        validate20.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate20.errors = vErrors;
    return errors === 0;
}
