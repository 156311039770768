import React from 'react';
import cx from 'classnames';

import type {BackgroundImageSrc, BlendMode} from '../../../types/images';
import {useFullSizeWindowBackground} from '../../../hooks/useFullSizeWindowBackground';

import {FullSizeWindowOverlayColorOpacity} from './FullSizeWindow.constants';

import styles from './FullSizeWindow.module.scss';

export interface FullSizeWindowCommonProps {
    padding?: 'small' | 'none';
    flexDirection?: 'row' | 'rowReverse' | 'column' | 'columnReverse';
    justifyContent?: 'flexStart' | 'center';
}

export type FullSizeWindowColorBackgroundProps = {
    background?: 'none' | 'primary' | 'secondary' | 'tertiary';
    backgroundImageSrc?: never;
    backgroundBlendMode?: never;
    overlayColorOpacity?: never;
};

export type FullSizeWindowImageBackgroundProps = {
    background: 'image';
    backgroundImageSrc: BackgroundImageSrc;
    backgroundBlendMode?: BlendMode;
    overlayColorOpacity?: FullSizeWindowOverlayColorOpacity;
};

type FullSizeWindowBackgroundProps =
    | FullSizeWindowColorBackgroundProps
    | FullSizeWindowImageBackgroundProps;

export type FullSizeWindowProps = FullSizeWindowCommonProps &
    FullSizeWindowBackgroundProps;

export const FullSizeWindow: React.FC<
    React.ComponentProps<'div'> & FullSizeWindowProps
> = ({
    children,
    className,
    padding = 'small',
    background = 'none',
    flexDirection = 'row',
    justifyContent = 'flexStart',
    backgroundImageSrc,
    backgroundBlendMode = 'normal',
    overlayColorOpacity = FullSizeWindowOverlayColorOpacity.Strong,
    style,
    ...props
}) => {
    const isPaddingSmall = padding === 'small';
    const isPaddingNone = padding === 'none';

    const {
        isBackgroundNone,
        isBackgroundPrimary,
        isBackgroundSecondary,
        isBackgroundImage,
        isBackgroundImageBreakpointImageSet,
        isBackgroundImageBlend,
        backgroundStyleProps,
    } = useFullSizeWindowBackground({
        background,
        backgroundImageSrc,
        backgroundBlendMode,
        overlayColorOpacity,
    });

    return (
        <div
            style={{...style, ...backgroundStyleProps}}
            className={cx(
                styles.fullSizeWindow,
                {
                    [styles.paddingNone]: isPaddingNone,
                    [styles.paddingSmall]: isPaddingSmall,
                    [styles.backgroundNone]: isBackgroundNone,
                    [styles.backgroundPrimary]: isBackgroundPrimary,
                    [styles.backgroundSecondary]: isBackgroundSecondary,
                    [styles.backgroundImage]: isBackgroundImage,
                    [styles.backgroundImageBreakpointImageSet]:
                        isBackgroundImageBreakpointImageSet,
                    [styles.backgroundBlend]:
                        isBackgroundImage && isBackgroundImageBlend,
                },
                styles[flexDirection],
                styles[justifyContent],
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
};
