export const validate200 = validate15;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema12.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath: '#/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
export const validateBody = validate16;
const schema18 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                transforms: {$ref: '#/properties/Body/definitions/Transforms'},
            },
            required: ['transforms'],
            title: 'TopLevel',
        },
        Transforms: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                        '5:7',
                        'ac_presentation_in_mix',
                        'ac_presentation_in_mix_group',
                    ],
                    description:
                        'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
                },
                host_layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                        '5:7',
                        'ac_presentation_in_mix',
                        'ac_presentation_in_mix_group',
                    ],
                    description:
                        'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
                },
                guest_layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                        '5:7',
                        'ac_presentation_in_mix',
                        'ac_presentation_in_mix_group',
                    ],
                    description:
                        'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
                },
                enable_extended_ac: {
                    type: 'boolean',
                    description:
                        'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
                },
                streaming_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
                },
                recording_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
                },
                enable_active_speaker_indication: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                enable_overlay_text: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                streaming: {$ref: '#/properties/Body/definitions/Streaming'},
                free_form_overlay_text: {
                    type: 'array',
                    description:
                        'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
                    items: {type: 'string'},
                },
            },
            required: [],
            title: 'Transforms',
        },
        Streaming: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                    ],
                    description:
                        'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
                },
                waiting_screen_enabled: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
                },
                plus_n_pip_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
                },
                indicators_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
                },
            },
            required: [],
            title: 'Streaming',
        },
    },
    oneOf: [{$ref: '#/properties/Body/definitions/TopLevel'}],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        transforms: {$ref: '#/properties/Body/definitions/Transforms'},
    },
    required: ['transforms'],
    title: 'TopLevel',
};
const schema14 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
                '5:7',
                'ac_presentation_in_mix',
                'ac_presentation_in_mix_group',
            ],
            description:
                'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
        },
        host_layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
                '5:7',
                'ac_presentation_in_mix',
                'ac_presentation_in_mix_group',
            ],
            description:
                'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
        },
        guest_layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
                '5:7',
                'ac_presentation_in_mix',
                'ac_presentation_in_mix_group',
            ],
            description:
                'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
        },
        enable_extended_ac: {
            type: 'boolean',
            description:
                'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
        },
        streaming_indicator: {
            type: 'boolean',
            description:
                'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
        },
        recording_indicator: {
            type: 'boolean',
            description:
                'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
        },
        enable_active_speaker_indication: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        enable_overlay_text: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        streaming: {$ref: '#/properties/Body/definitions/Streaming'},
        free_form_overlay_text: {
            type: 'array',
            description:
                'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
            items: {type: 'string'},
        },
    },
    required: [],
    title: 'Transforms',
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
            ],
            description:
                'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
        },
        waiting_screen_enabled: {
            type: 'boolean',
            default: false,
            description:
                'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
        },
        plus_n_pip_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
        },
        indicators_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
        },
    },
    required: [],
    title: 'Streaming',
};
const func2 = Object.prototype.hasOwnProperty;
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs1 = errors;
            for (const key0 in data) {
                if (!func2.call(schema14.properties, key0)) {
                    delete data[key0];
                }
            }
            if (_errs1 === errors) {
                if (data.layout !== undefined) {
                    let data0 = data.layout;
                    const _errs2 = errors;
                    if (typeof data0 !== 'string') {
                        validate12.errors = [
                            {
                                instancePath: instancePath + '/layout',
                                schemaPath: '#/properties/layout/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            },
                        ];
                        return false;
                    }
                    if (
                        !(
                            data0 === '1:0' ||
                            data0 === '1:7' ||
                            data0 === '1:21' ||
                            data0 === '2:21' ||
                            data0 === '1:33' ||
                            data0 === '4:0' ||
                            data0 === '9:0' ||
                            data0 === '16:0' ||
                            data0 === '25:0' ||
                            data0 === '5:7' ||
                            data0 === 'ac_presentation_in_mix' ||
                            data0 === 'ac_presentation_in_mix_group'
                        )
                    ) {
                        validate12.errors = [
                            {
                                instancePath: instancePath + '/layout',
                                schemaPath: '#/properties/layout/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema14.properties.layout.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            },
                        ];
                        return false;
                    }
                    var valid0 = _errs2 === errors;
                } else {
                    var valid0 = true;
                }
                if (valid0) {
                    if (data.host_layout !== undefined) {
                        let data1 = data.host_layout;
                        const _errs4 = errors;
                        if (typeof data1 !== 'string') {
                            validate12.errors = [
                                {
                                    instancePath: instancePath + '/host_layout',
                                    schemaPath: '#/properties/host_layout/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (
                            !(
                                data1 === '1:0' ||
                                data1 === '1:7' ||
                                data1 === '1:21' ||
                                data1 === '2:21' ||
                                data1 === '1:33' ||
                                data1 === '4:0' ||
                                data1 === '9:0' ||
                                data1 === '16:0' ||
                                data1 === '25:0' ||
                                data1 === '5:7' ||
                                data1 === 'ac_presentation_in_mix' ||
                                data1 === 'ac_presentation_in_mix_group'
                            )
                        ) {
                            validate12.errors = [
                                {
                                    instancePath: instancePath + '/host_layout',
                                    schemaPath: '#/properties/host_layout/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema14.properties.host_layout
                                                .enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs4 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.guest_layout !== undefined) {
                            let data2 = data.guest_layout;
                            const _errs6 = errors;
                            if (typeof data2 !== 'string') {
                                validate12.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_layout',
                                        schemaPath:
                                            '#/properties/guest_layout/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            if (
                                !(
                                    data2 === '1:0' ||
                                    data2 === '1:7' ||
                                    data2 === '1:21' ||
                                    data2 === '2:21' ||
                                    data2 === '1:33' ||
                                    data2 === '4:0' ||
                                    data2 === '9:0' ||
                                    data2 === '16:0' ||
                                    data2 === '25:0' ||
                                    data2 === '5:7' ||
                                    data2 === 'ac_presentation_in_mix' ||
                                    data2 === 'ac_presentation_in_mix_group'
                                )
                            ) {
                                validate12.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_layout',
                                        schemaPath:
                                            '#/properties/guest_layout/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema14.properties.guest_layout
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs6 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.enable_extended_ac !== undefined) {
                                const _errs8 = errors;
                                if (
                                    typeof data.enable_extended_ac !== 'boolean'
                                ) {
                                    validate12.errors = [
                                        {
                                            instancePath:
                                                instancePath +
                                                '/enable_extended_ac',
                                            schemaPath:
                                                '#/properties/enable_extended_ac/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs8 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.streaming_indicator !== undefined) {
                                    const _errs10 = errors;
                                    if (
                                        typeof data.streaming_indicator !==
                                        'boolean'
                                    ) {
                                        validate12.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/streaming_indicator',
                                                schemaPath:
                                                    '#/properties/streaming_indicator/type',
                                                keyword: 'type',
                                                params: {type: 'boolean'},
                                                message: 'must be boolean',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs10 === errors;
                                } else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (
                                        data.recording_indicator !== undefined
                                    ) {
                                        const _errs12 = errors;
                                        if (
                                            typeof data.recording_indicator !==
                                            'boolean'
                                        ) {
                                            validate12.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/recording_indicator',
                                                    schemaPath:
                                                        '#/properties/recording_indicator/type',
                                                    keyword: 'type',
                                                    params: {type: 'boolean'},
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs12 === errors;
                                    } else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (
                                            data.enable_active_speaker_indication !==
                                            undefined
                                        ) {
                                            const _errs14 = errors;
                                            if (
                                                typeof data.enable_active_speaker_indication !==
                                                'boolean'
                                            ) {
                                                validate12.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/enable_active_speaker_indication',
                                                        schemaPath:
                                                            '#/properties/enable_active_speaker_indication/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'boolean',
                                                        },
                                                        message:
                                                            'must be boolean',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs14 === errors;
                                        } else {
                                            var valid0 = true;
                                        }
                                        if (valid0) {
                                            if (
                                                data.enable_overlay_text !==
                                                undefined
                                            ) {
                                                const _errs16 = errors;
                                                if (
                                                    typeof data.enable_overlay_text !==
                                                    'boolean'
                                                ) {
                                                    validate12.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/enable_overlay_text',
                                                            schemaPath:
                                                                '#/properties/enable_overlay_text/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid0 = _errs16 === errors;
                                            } else {
                                                var valid0 = true;
                                            }
                                            if (valid0) {
                                                if (
                                                    data.streaming !== undefined
                                                ) {
                                                    let data8 = data.streaming;
                                                    const _errs18 = errors;
                                                    const _errs19 = errors;
                                                    if (errors === _errs19) {
                                                        if (
                                                            data8 &&
                                                            typeof data8 ==
                                                                'object' &&
                                                            !Array.isArray(
                                                                data8,
                                                            )
                                                        ) {
                                                            const _errs21 =
                                                                errors;
                                                            for (const key1 in data8) {
                                                                if (
                                                                    !(
                                                                        key1 ===
                                                                            'layout' ||
                                                                        key1 ===
                                                                            'waiting_screen_enabled' ||
                                                                        key1 ===
                                                                            'plus_n_pip_enabled' ||
                                                                        key1 ===
                                                                            'indicators_enabled'
                                                                    )
                                                                ) {
                                                                    delete data8[
                                                                        key1
                                                                    ];
                                                                }
                                                            }
                                                            if (
                                                                _errs21 ===
                                                                errors
                                                            ) {
                                                                if (
                                                                    data8.layout !==
                                                                    undefined
                                                                ) {
                                                                    let data9 =
                                                                        data8.layout;
                                                                    const _errs22 =
                                                                        errors;
                                                                    if (
                                                                        typeof data9 !==
                                                                        'string'
                                                                    ) {
                                                                        validate12.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/streaming/layout',
                                                                                    schemaPath:
                                                                                        '#/properties/Body/definitions/Streaming/properties/layout/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    if (
                                                                        !(
                                                                            data9 ===
                                                                                '1:0' ||
                                                                            data9 ===
                                                                                '1:7' ||
                                                                            data9 ===
                                                                                '1:21' ||
                                                                            data9 ===
                                                                                '2:21' ||
                                                                            data9 ===
                                                                                '1:33' ||
                                                                            data9 ===
                                                                                '4:0' ||
                                                                            data9 ===
                                                                                '9:0' ||
                                                                            data9 ===
                                                                                '16:0' ||
                                                                            data9 ===
                                                                                '25:0'
                                                                        )
                                                                    ) {
                                                                        validate12.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/streaming/layout',
                                                                                    schemaPath:
                                                                                        '#/properties/Body/definitions/Streaming/properties/layout/enum',
                                                                                    keyword:
                                                                                        'enum',
                                                                                    params: {
                                                                                        allowedValues:
                                                                                            schema15
                                                                                                .properties
                                                                                                .layout
                                                                                                .enum,
                                                                                    },
                                                                                    message:
                                                                                        'must be equal to one of the allowed values',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid2 =
                                                                        _errs22 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                                if (valid2) {
                                                                    if (
                                                                        data8.waiting_screen_enabled !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs24 =
                                                                            errors;
                                                                        if (
                                                                            typeof data8.waiting_screen_enabled !==
                                                                            'boolean'
                                                                        ) {
                                                                            validate12.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/streaming/waiting_screen_enabled',
                                                                                        schemaPath:
                                                                                            '#/properties/Body/definitions/Streaming/properties/waiting_screen_enabled/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'boolean',
                                                                                        },
                                                                                        message:
                                                                                            'must be boolean',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid2 =
                                                                            _errs24 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid2 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid2
                                                                    ) {
                                                                        if (
                                                                            data8.plus_n_pip_enabled !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs26 =
                                                                                errors;
                                                                            if (
                                                                                typeof data8.plus_n_pip_enabled !==
                                                                                'boolean'
                                                                            ) {
                                                                                validate12.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/streaming/plus_n_pip_enabled',
                                                                                            schemaPath:
                                                                                                '#/properties/Body/definitions/Streaming/properties/plus_n_pip_enabled/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'boolean',
                                                                                            },
                                                                                            message:
                                                                                                'must be boolean',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid2 =
                                                                                _errs26 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid2 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid2
                                                                        ) {
                                                                            if (
                                                                                data8.indicators_enabled !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs28 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data8.indicators_enabled !==
                                                                                    'boolean'
                                                                                ) {
                                                                                    validate12.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/streaming/indicators_enabled',
                                                                                                schemaPath:
                                                                                                    '#/properties/Body/definitions/Streaming/properties/indicators_enabled/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'boolean',
                                                                                                },
                                                                                                message:
                                                                                                    'must be boolean',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid2 =
                                                                                    _errs28 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid2 =
                                                                                    true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            validate12.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/streaming',
                                                                        schemaPath:
                                                                            '#/properties/Body/definitions/Streaming/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'object',
                                                                        },
                                                                        message:
                                                                            'must be object',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                    }
                                                    var valid0 =
                                                        _errs18 === errors;
                                                } else {
                                                    var valid0 = true;
                                                }
                                                if (valid0) {
                                                    if (
                                                        data.free_form_overlay_text !==
                                                        undefined
                                                    ) {
                                                        let data13 =
                                                            data.free_form_overlay_text;
                                                        const _errs30 = errors;
                                                        if (
                                                            errors === _errs30
                                                        ) {
                                                            if (
                                                                Array.isArray(
                                                                    data13,
                                                                )
                                                            ) {
                                                                var valid3 =
                                                                    true;
                                                                const len0 =
                                                                    data13.length;
                                                                for (
                                                                    let i0 = 0;
                                                                    i0 < len0;
                                                                    i0++
                                                                ) {
                                                                    const _errs32 =
                                                                        errors;
                                                                    if (
                                                                        typeof data13[
                                                                            i0
                                                                        ] !==
                                                                        'string'
                                                                    ) {
                                                                        validate12.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/free_form_overlay_text/' +
                                                                                        i0,
                                                                                    schemaPath:
                                                                                        '#/properties/free_form_overlay_text/items/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs32 ===
                                                                        errors;
                                                                    if (
                                                                        !valid3
                                                                    ) {
                                                                        break;
                                                                    }
                                                                }
                                                            } else {
                                                                validate12.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/free_form_overlay_text',
                                                                            schemaPath:
                                                                                '#/properties/free_form_overlay_text/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'array',
                                                                            },
                                                                            message:
                                                                                'must be array',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                        }
                                                        var valid0 =
                                                            _errs30 === errors;
                                                    } else {
                                                        var valid0 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate12.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.transforms === undefined && (missing0 = 'transforms')) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'transforms')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.transforms !== undefined) {
                        if (
                            !validate12(data.transforms, {
                                instancePath: instancePath + '/transforms',
                                parentData: data,
                                parentDataProperty: 'transforms',
                                rootData,
                            })
                        ) {
                            vErrors =
                                vErrors === null
                                    ? validate12.errors
                                    : vErrors.concat(validate12.errors);
                            errors = vErrors.length;
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate16(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate16.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate16.errors = vErrors;
    return errors === 0;
}
