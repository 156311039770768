import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useDeviceStatusInfo} from '@pexip/media-components';
import type {UserMediaStatus} from '@pexip/media';
import {Button} from '@pexip/components';

import {browserSpecificHelpers} from '../../utils/getBrowserSpecificVideoFiles';

import {UnblockHelpMessage} from './UnblockHelpMessage.view';
import {UnblockHelpSteps} from './UnblockHelpSteps.view';

import permissionsStyles from './PermissionsRejected.module.scss';
export interface AllPermissionsRejectedProps {
    next?: () => void;
    streamStatus: UserMediaStatus;
    setScrollTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AllPermissionsRejected: React.FC<AllPermissionsRejectedProps> = ({
    next,
    streamStatus,
    setScrollTrigger,
}) => {
    const [showHelpVideo, setShowHelpVideo] = useState(false);
    const statusInfo = useDeviceStatusInfo(streamStatus);
    const {t} = useTranslation();

    return (
        <div className={permissionsStyles.container}>
            <UnblockHelpMessage
                statusInfo={statusInfo}
                setShowHelpVideo={setShowHelpVideo}
                showHelpVideo={showHelpVideo}
                browserSpecificHelpers={browserSpecificHelpers(
                    navigator.userAgent,
                )}
            />

            <UnblockHelpSteps
                browserSpecificHelpers={browserSpecificHelpers(
                    navigator.userAgent,
                )}
                setScrollTrigger={setScrollTrigger}
            />

            <div className={permissionsStyles.proceedButton}>
                <Button size="small" variant="secondary" onClick={next}>
                    {t(`media.proceed-anyway`, `Proceed anyway`)}
                </Button>
            </div>
        </div>
    );
};
