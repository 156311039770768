import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import type {DeniedDevices} from '@pexip/media';
import {
    CenterLayout,
    FontVariant,
    Row,
    Text,
    TextLink,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

/*
t('media.permission-info.[microphone].title', 'microphone')
t('media.permission-info.[camera].title', 'camera')
t('media.permission-info.[microphone-and-camera].title', 'camera and microphone')
*/

export const PermissionsRequestMessage: React.FC<{
    className?: string;
    deniedDevice: DeniedDevices;
    onTriggerMediaModal?: () => void;
    url?: string;
}> = ({className, deniedDevice, onTriggerMediaModal, url}) => {
    const {t} = useTranslation();
    return (
        <CenterLayout
            className={className}
            data-testid={TestId.PermissionsRequestMessageWrapper}
            position="absolute"
        >
            <Row className="pt-4 text-center px-4">
                <Text fontVariant={FontVariant.H5} colorScheme="light">
                    <Trans t={t} i18nKey="media.you-blocked-access">
                        You blocked access to your{' '}
                        {{
                            seeOrHearYou: t(
                                `media.permission-info.[${deniedDevice}].title`,
                            ),
                        }}
                    </Trans>
                </Text>
            </Row>
            <Row className="text-center px-4">
                <Text colorScheme="light">
                    <Trans t={t} i18nKey="media.to-grant-access-follow-steps">
                        To grant access please follow the steps in{' '}
                    </Trans>
                    <TextLink
                        data-testid={TestId.TextLinkUnblockPermissionsVideo}
                        onClick={onTriggerMediaModal}
                        isUnderlined
                        colorScheme="light"
                    >
                        <Trans
                            t={t}
                            i18nKey="media.unblock-permissions-video-link"
                        >
                            this short video
                        </Trans>
                    </TextLink>{' '}
                    <Trans t={t} i18nKey="media.or-go-to-the">
                        or go to the{' '}
                    </Trans>
                    <TextLink
                        data-testid={TestId.TextLinkLearnRequestPermissions}
                        href={url}
                        target="_blank"
                        isUnderlined
                        colorScheme="light"
                    >
                        <Trans
                            t={t}
                            i18nKey="media.browser-privacy-settings-link"
                        >
                            privacy settings
                        </Trans>
                    </TextLink>{' '}
                    <Trans t={t} i18nKey="media.of-your-browser">
                        of your browser
                    </Trans>
                </Text>
            </Row>
        </CenterLayout>
    );
};
