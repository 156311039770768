import React, {useState} from 'react';

import {TestYourMicStages, TestYourMicLink} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {TestYourMicrophoneModalSteps} from './TestYourMicrophoneModalSteps.viewModel';

export const TestYourMicrophone: React.FC<{
    devices: MediaDeviceInfoLike[];
    closeOnOutsideClick?: boolean;
    onAudioInputChange: (device: MediaDeviceInfoLike) => void;
    onAudioOutputChange: (device: MediaDeviceInfoLike) => void;
    selectedAudioInput?: MediaDeviceInfoLike;
    selectedAudioOutput?: MediaDeviceInfoLike;
}> = ({
    devices,
    closeOnOutsideClick,
    onAudioInputChange,
    onAudioOutputChange,
    selectedAudioInput,
    selectedAudioOutput,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [testYourMicStages, setTestYourMicStages] =
        useState<TestYourMicStages>(TestYourMicStages.BeforeTesting);

    const onClose = () => {
        setIsModalOpen(false);
        setTestYourMicStages(TestYourMicStages.BeforeTesting);
    };

    return (
        <>
            {isModalOpen && (
                <TestYourMicrophoneModalSteps
                    devices={devices}
                    isModalOpen={isModalOpen}
                    closeOnOutsideClick={closeOnOutsideClick}
                    onAudioInputChange={onAudioInputChange}
                    onAudioOutputChange={onAudioOutputChange}
                    selectedAudioInput={selectedAudioInput}
                    selectedAudioOutput={selectedAudioOutput}
                    testYourMicStages={testYourMicStages}
                    onClose={onClose}
                    setTestYourMicStages={setTestYourMicStages}
                />
            )}
            <TestYourMicLink
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />
        </>
    );
};
