import {useCallback, useEffect, useRef, useState} from 'react';

import {Color} from '@pexip/components';
import {getClampLevelFn, TestYourMicStages} from '@pexip/media-components';
import type {AnalyzerNodeInit} from '@pexip/media-processor';
import {useAnimationFrame} from '@pexip/hooks';

import {FFT_SIZE} from '../constants';

export const RecordingStates = [
    TestYourMicStages.Recording,
    TestYourMicStages.RecordingSuccess,
];

const successFixedLevels = [
    5, 20, 60, 80, 100, 60, 30, 25, 10, 5, 5, 20, 60, 80, 100, 60, 30, 25, 10,
    5,
];

const clampLevel = getClampLevelFn();

const getAnimateFn = (barsWrapperId: string) => {
    const bars = document.querySelectorAll<HTMLElement>(
        `#${barsWrapperId} div`,
    );

    bars.forEach((_, index) => {
        const el = bars[index];

        if (el) {
            el.style.backgroundColor = 'transparent';
        }

        return el;
    });

    return (levels: number[], currentStage: TestYourMicStages) =>
        levels.forEach((level, index) => {
            const el = bars[index];

            if (el) {
                el.style.transform = `scaleY(${clampLevel(level)})`;
                el.style.backgroundColor = RecordingStates.includes(
                    currentStage,
                )
                    ? Color.Blue90
                    : Color.Green90;
            }
        });
};

const asPercentage = (max: number) => Math.round((max / 255) * 100);

const getArray = (frequencyData: Uint8Array) =>
    Array.from(frequencyData).map(max => asPercentage(max));

export const useAnimateFrequencyBars = ({
    currentStage,
    microphoneFrequencyData = [],
    playbackFrequencyData = [],
    barsWrapperId,
}: {
    currentStage: TestYourMicStages;
    microphoneFrequencyData?: number[];
    playbackFrequencyData?: number[];
    barsWrapperId: string;
}) => {
    const animationRef = useRef<number>();
    const animate =
        useRef<(levels: number[], _currentStage: TestYourMicStages) => void>();

    useEffect(() => {
        animate.current = getAnimateFn(barsWrapperId);
    }, [barsWrapperId]);

    useEffect(() => {
        const getLevels = () => {
            if (currentStage === TestYourMicStages.RecordingSuccess) {
                return successFixedLevels;
            }

            return currentStage === TestYourMicStages.Recording
                ? microphoneFrequencyData
                : playbackFrequencyData;
        };

        const animateCallback = () =>
            animate.current?.(getLevels(), currentStage);

        animationRef.current = requestAnimationFrame(animateCallback);
    }, [currentStage, microphoneFrequencyData, playbackFrequencyData]);

    useEffect(
        () => () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        },
        [],
    );
};

export const useByteFrequencyData = (
    analyzer?: AnalyzerNodeInit,
    bufferLength = FFT_SIZE / 2,
) => {
    const [byteFrequencyData, setByteFrequencyData] = useState<number[]>([]);
    const bufferRef = useRef<Uint8Array>();

    useEffect(() => {
        if (!bufferRef.current) {
            bufferRef.current = new Uint8Array(bufferLength);
        }
    }, [bufferLength]);

    const updateAudioLevel = useCallback(() => {
        if (analyzer?.node && bufferRef.current) {
            analyzer.node.getByteFrequencyData(bufferRef.current);
            setByteFrequencyData(getArray(bufferRef.current));
        }
    }, [analyzer]);

    useAnimationFrame(updateAudioLevel);

    return byteFrequencyData;
};
