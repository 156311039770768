import React from 'react';

import {IconTypes} from '../../elements/Icon/Icon';
import type {DropContentProps} from '../DropContent/DropContent';
import {DropContent} from '../DropContent/DropContent';
import {Button} from '../../elements/Button/Button';
import type {ButtonVariant} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {RoundIndicatorBaseTypeProps} from '../RoundIndicator/RoundIndicator';
import {RoundIndicator} from '../RoundIndicator/RoundIndicator';
import {getInitials} from '../../../utils/string';

export const UserMenuButton: React.FC<
    DropContentProps & {
        displayName?: string;
        variant?: ButtonVariant;
        title?: string;
    }
> = ({
    className,
    contentClassName,
    displayName,
    variant = 'tertiary',
    title = 'User menu',
    ...dropContentProps
}) => {
    const roundIndicatorProperties: RoundIndicatorBaseTypeProps = displayName
        ? {
              type: 'text',
              text: getInitials(displayName),
          }
        : {
              type: 'icon',
              iconType: IconTypes.IconMoreVertical,
              title,
          };

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <DropContent
                    className={className}
                    contentClassName={contentClassName}
                    buttonLabel="Toggle user menu"
                    title={title}
                    interactiveElement={(buttonProps, isContentVisible) => (
                        <Button
                            {...buttonProps}
                            isActive={isContentVisible}
                            modifier="disc"
                            size="medium"
                            variant={variant}
                            colorScheme={defaultColorScheme}
                        >
                            <RoundIndicator
                                modifier="noBackground"
                                // We want to inherit the styling from the Button
                                textVariant="inherit"
                                {...roundIndicatorProperties}
                            />
                        </Button>
                    )}
                    {...dropContentProps}
                />
            )}
        </ThemeConsumer>
    );
};
