import {useEffect, useState} from 'react';

import type {ChatMessage} from '@pexip/media-components';
import {toTime} from '@pexip/media-components';

import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {CHARACTER_LIMIT} from '../constants';
import {logger} from '../logger';

export const useChatMessages = () => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);

    useEffect(
        () =>
            infinityClientSignals.onMessage.add(message => {
                if (message.message.length > CHARACTER_LIMIT) {
                    logger.warn(
                        `Message received from the server is too big. Length: ${message.message.length}`,
                    );
                    return;
                }
                setMessages(messages => [
                    ...messages,
                    {
                        ...message,
                        timestamp: toTime(message.at),
                        type: 'user-message',
                    },
                ]);
            }),
        [],
    );

    return [messages, setMessages] as const;
};
