export enum PreflightJoinDetailsToRender {
    BlockedDeviceSelection = 'blocked-device-selection',
    DeviceSelection = 'device-selection',
    Spinner = 'spinner',
}

export interface Idp {
    name: string;
    uuid: string;
    redirectUrl?: string;
    img?: string;
}

export enum FlowType {
    Express = 'express',
    StepByStep = 'step-by-step',
}

export interface BlockedBrowserPermissionsInfo {
    // vp9 is a webm file format
    vp9: string;
    mp4: string;
    browserName: string;
    link: string;
    textHelpSteps: {step: number; text: string}[];
    videoTextOverlaySteps: {step: number; text: string; duration: number}[];
}

export type BrowserType =
    | 'android'
    | 'chrome'
    | 'edge'
    | 'firefox'
    | 'safari-ios'
    | 'safari-ipados'
    | 'safari-macosx';

export interface PreflightTipsInfo {
    step: number;
    title: string;
    text: React.ReactNode;
}

export enum MeetingFlow {
    Loading,
    AreYouHost,
    EnterPin,
    EnterHostPin,
    EnterExtension,
    Idp,
    InMeeting,
    Error,
}
