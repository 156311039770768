import React from 'react';

import {useConfig} from '../config';
import {renderUserNamePageSignal} from '../signals/MeetingFlow.signals';
import {UserNameCard} from '../views/UserNameCard/UserNameCard.view';

export const DisplayName: React.FC = () => {
    const [displayName, setDisplayName] = useConfig('displayName');

    const onSubmit = (displayName: string) => {
        if (displayName.length) {
            renderUserNamePageSignal.emit(false);
            setDisplayName(displayName, true);
        }
    };

    return <UserNameCard defaultName={displayName} onSubmit={onSubmit} />;
};
