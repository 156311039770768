import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useMobileDevice} from '@pexip/components';
import {
    currentBrowserName,
    getLearnAboutRequestPermissionsUrl,
    shouldShowErrorBorder,
    PreflightSelfview,
} from '@pexip/media-components';
import {toDeniedDevices, isRejected} from '@pexip/media';

import {useConfig} from '../config';
import {
    disableAudioSignalDetection,
    enableAudioSignalDetection,
    mediaService,
    useLocalMedia,
    useStreamStatus,
} from '../services/Media.service';
import {useSettings} from '../hooks/useSettings';
import {FlowType} from '../types';
import {useToggleFaceMode} from '../hooks/useToggleFaceMode';
import {MediaModal} from '../views/MediaModal/MediaModal.view';
import {browserSpecificHelpers} from '../utils/getBrowserSpecificVideoFiles';

import {PreflightSettings} from './PreflightSettings.viewModel';
import {SelfviewControls} from './SelfviewControls.viewModel';

export const PreflightMedia: React.FC<{
    shouldShowBorder?: boolean;
    flowType: FlowType;
}> = ({shouldShowBorder = false, flowType}) => {
    const [displayName] = useConfig('displayName');
    const [isVideoMediaMuted] = useConfig('isVideoInputMuted');
    const [videoProcessingEnabled] = useConfig('videoProcessing');
    const [isMirrored, toggleFacingMode] = useToggleFaceMode();

    const {openModal, openEffectsModal, closeModal, isOpen, activeTab} =
        useSettings();

    const [dismissPermissionsLinkMessage, setDismissPermissionsLinkMessage] =
        useState(false);
    const {t} = useTranslation();

    const media = useLocalMedia();
    const streamStatus = useStreamStatus();

    useEffect(() => {
        enableAudioSignalDetection();
        return disableAudioSignalDetection;
    }, []);

    const showRedBorder = useMemo(
        () => shouldShowErrorBorder(streamStatus),
        [streamStatus],
    );
    const learnHowToGrantAccessURL = showRedBorder
        ? getLearnAboutRequestPermissionsUrl(currentBrowserName)
        : undefined;

    const isMobileDevice = useMobileDevice();

    const videoHelpers = browserSpecificHelpers(navigator.userAgent);
    const mediaSources = {
        video: {
            vp9: videoHelpers.vp9,
            mp4: videoHelpers.mp4,
        },
    };
    const [showHelpVideo, setShowHelpVideo] = useState(false);
    const deniedDevices = toDeniedDevices(streamStatus);

    return (
        <>
            <PreflightSelfview
                aspectRatioType={
                    deniedDevices
                        ? 'force-landscape'
                        : flowType === FlowType.StepByStep
                        ? 'ready-to-join-step'
                        : 'ready-to-join-express'
                }
                isVideoInputMuted={isVideoMediaMuted}
                segmentationEnabled={videoProcessingEnabled}
                streamStatus={streamStatus}
                localMediaStream={media?.stream}
                requestMediaPermissions={() =>
                    mediaService.getUserMedia({audio: true, video: true})
                }
                learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                shouldShowBorder={shouldShowBorder}
                username={displayName}
                shouldShowErrorBorder={showRedBorder}
                dismissPermissionsLinkMessage={dismissPermissionsLinkMessage}
                setDismissPermissionsLinkMessage={
                    setDismissPermissionsLinkMessage
                }
                onTryAgain={() =>
                    mediaService.getUserMedia({audio: true, video: true})
                }
                isMobileDevice={isMobileDevice}
                isMirrored={isMirrored}
                preflightControls={
                    !!streamStatus && !isRejected(streamStatus) ? (
                        <SelfviewControls
                            flowType={flowType}
                            streamStatus={streamStatus}
                            triggerSettingsModal={openModal}
                            toggleBlur={openEffectsModal}
                            toggleFacingMode={toggleFacingMode}
                        />
                    ) : undefined
                }
                triggerMediaModal={() => setShowHelpVideo(true)}
            />
            <PreflightSettings
                activeTab={activeTab}
                isOpen={isOpen}
                closeModal={closeModal}
            />
            <MediaModal
                isOpen={showHelpVideo}
                onClose={() => setShowHelpVideo(prevVal => !prevVal)}
                src={mediaSources}
                videoTextOverlaySteps={videoHelpers.videoTextOverlaySteps}
                browserName={videoHelpers.browserName}
                altTag={t(
                    `media.how-to-unblock-access`,
                    `An animation with instructions for giving permission to access media devices.`,
                )}
            ></MediaModal>
        </>
    );
};
