import {useMemo} from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';

export const useSelectAudioDevices = (devices: MediaDeviceInfoLike[]) => {
    const {audioInputs, audioOutputs} = useMemo(
        () =>
            devices.reduce(
                (
                    audioDevices: {
                        audioInputs: MediaDeviceInfoLike[];
                        audioOutputs: MediaDeviceInfoLike[];
                    },
                    device,
                ) => {
                    if (device.kind === 'audioinput') {
                        return {
                            ...audioDevices,
                            audioInputs: [...audioDevices.audioInputs, device],
                        };
                    }
                    if (device.kind === 'audiooutput') {
                        return {
                            ...audioDevices,
                            audioOutputs: [
                                ...audioDevices.audioOutputs,
                                device,
                            ],
                        };
                    }
                    return audioDevices;
                },
                {audioInputs: [], audioOutputs: []},
            ),
        [devices],
    );

    return {audioInputs, audioOutputs};
};
