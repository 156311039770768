import React, {useState} from 'react';

import {AudioOutputTestButton} from '@pexip/media-components';

import {TestId} from '../../test/testIds';

import {PlaySound} from './PlaySound.viewModel';

export const OutputAudioTester: React.FC<{
    sinkId: string;
}> = ({sinkId}) => {
    const [shouldPlay, setShouldPlay] = useState(false);

    const handleClick = () => {
        setShouldPlay(!shouldPlay);
    };

    return (
        <>
            {shouldPlay && (
                <PlaySound
                    data-testid={TestId.AudioTestOutput}
                    loop
                    sinkId={sinkId}
                    sound="test"
                />
            )}
            <AudioOutputTestButton
                onClick={handleClick}
                shouldPlay={shouldPlay}
            />
        </>
    );
};
