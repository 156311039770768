import type {RequestTokenMap} from './token';

export function withToken(fetcher: typeof fetch, token: string): typeof fetch {
    return (
        requestInfo: RequestInfo | URL,
        requestInit: RequestInit | undefined,
    ) => {
        if (!token) {
            throw new Error('No token provided');
        }
        const headers = new Headers(requestInit?.headers);
        headers.set('token', `${token}`);
        return fetcher(requestInfo, {...requestInit, headers});
    };
}

export function withPin(fetcher: typeof fetch, pin: string): typeof fetch {
    return (
        requestInfo: RequestInfo | URL,
        requestInit: RequestInit | undefined,
    ) => {
        const headers = new Headers(requestInit?.headers);
        headers.set('pin', `${pin}`);
        return fetcher(requestInfo, {...requestInit, headers});
    };
}

export type InfinityErrorMessage = RequestTokenMap['403']['result'] & string;

export function getErrorCode(error: InfinityErrorMessage) {
    switch (error) {
        case 'Invalid role':
            return '#pex100';
        case 'Invalid PIN':
            return '#pex101';
        case 'Out of proxying resource':
            return '#pex109';
        case 'System in maintenance mode':
            return '#pex110';
        case '502 Bad Gateway':
            return '#pex111';
        case '503 Service Unavailable':
            return '#pex112';
        case 'Invalid token':
            return '#pex113';
        case 'Timer expired awaiting token refresh':
            return '#pex113';
        case 'Out of resource':
            return '#pex114';
        case 'Out of transcoding resource':
            return '#pex114';
        case 'transfer failed':
            return '#pex115';
        case 'Unexpected Response: 503':
            return '#pex116';
        case 'Failed to forward request':
            return '#pex119';
        case 'Conference host ended the conference with a DTMF command':
            return '#pex120';
        case 'API initiated conference termination':
            return '#pex121';
        case 'API initiated participant disconnect':
            return '#pex122';
        case 'Conference terminated by an administrator':
            return '#pex122';
        case 'Disconnected by an administrator':
            return '#pex123';
        case 'Disconnected by another participant':
            return '#pex124';
        case 'Conference terminated by another participant':
            return '#pex125';
        case 'Timeout waiting for conference host to join or permit access to locked conference':
            return '#pex126';
        case 'Disabled':
            return '#pex127';
        case 'Signaling node disconnected':
            return '#pex129';
        case 'Media process disconnected':
            return '#pex130';
        case 'Media node disconnected':
            return '#pex131';
        case 'Proxied participant disconnected':
            return '#pex132';
        case 'No participants can keep conference alive':
            return '#pex140';
        case 'All conference hosts departed hosted conference':
            return '#pex141';
        case 'Last remaining participant removed from conference after timeout':
            return '#pex142';
        case 'Test call finished':
            return '#pex143';
        case 'Call rejected':
            return '#pex150';
        case 'Call disconnected':
            return '#pex151';
        case 'Gateway dial out failed':
            return '#pex152';
        case 'invalid gateway routing rule transform':
            return '#pex153';
        case 'Neither conference nor gateway found':
            return '#pex154';
        case 'No direct route between Edge and Transcoding':
            return '#pex156';
        case '404 Not Found':
            return '#pex157';
        case 'Resource unavailable':
            return '#pex191';
        case 'Participant exceeded PIN entry retries':
            return '#pex192';
        case 'Invalid license':
            return '#pex193';
        case 'Participant limit reached':
            return '#pex194';
        case 'Insufficient licenses':
            return '#pex195';
        case 'Ice failure':
            return '#pex196';
        case 'Telehealth Call failure':
            return '#pex197';
        case 'SSO Authentication Failed':
            return '#pex200';
        case 'SSO enabled but no Identity Providers configured':
            return '#pex201';
        case 'SSO Authentication Failed. The system is in Maintenance mode':
            return '#pex203';
        case 'SSO Authentication Failed. SSO is not available from this domain':
            return '#pex204';
        default:
            return '#pex117';
    }
}

export type InfinityErrorCode = ReturnType<typeof getErrorCode>;
