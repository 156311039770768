import React from 'react';
import {useTranslation} from 'react-i18next';

import {
    Bar,
    CallToAction,
    CenterLayout,
    Group,
    Icon,
    IconTypes,
    Section,
    Text,
    Wrapper,
} from '@pexip/components';

import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {TestId} from '../../test/testIds';
import {Header} from '../viewModels/Header.viewModel';

export const Error: React.FC<{errorCode?: number; reload: () => void}> = ({
    errorCode,
    reload,
}) => {
    const {t} = useTranslation();
    return (
        <BrandedFullSizeWindow
            background="secondary"
            data-testid={TestId.ErrorScreen}
            flexDirection="column"
            padding="none"
        >
            <Header />
            <Wrapper modifier="fullWidth">
                <CenterLayout>
                    <Section
                        subtitle={t(
                            'error.smth-on-our-end',
                            'Oops - looks like an error on our end.',
                        )}
                        title={t(
                            'error.smth-went-wrong',
                            'Something went wrong',
                        )}
                    >
                        <Group className="mt-7 mb-7">
                            <CallToAction
                                data-testid={TestId.ButtonReloadPage}
                                enhancerStart={
                                    <Icon source={IconTypes.IconArrowRight} />
                                }
                                onClick={reload}
                                style={{minWidth: 260}}
                                title={t(
                                    'error.reload-page',
                                    'Reload the page',
                                )}
                            />
                        </Group>
                    </Section>
                </CenterLayout>
                {errorCode && (
                    <Bar
                        className="mb-7"
                        data-testid={TestId.ErrorScreenCode}
                        justifyContent="center"
                        position="absoluteBottom"
                    >
                        <Text>{errorCode}</Text>
                    </Bar>
                )}
            </Wrapper>
        </BrandedFullSizeWindow>
    );
};
