import type {
    AudioGraphOptions,
    AudioNodeInit,
    ThrottleOptions,
    DenoiseWorkletNodeInit,
    AnalyzerNodeInit,
    AudioGraph,
} from '@pexip/media-processor';
import type {MediaDeviceRequest} from '@pexip/media-control';
import {
    stopMediaStream,
    extractConstraintsWithKeys,
    muteStreamTrack,
} from '@pexip/media-control';
import {createQueue, isEmpty} from '@pexip/utils';
import {
    createAudioGraph,
    createAudioGraphProxy,
    createStreamSourceGraphNode,
    createStreamDestinationGraphNode,
    createAnalyzerSubscribableGraphNode,
    createDenoiseWorkletGraphNode,
    createAudioSignalDetector,
    createVADetector,
    createVoiceDetectorFromTimeData,
    createVoiceDetectorFromProbability,
    avg,
} from '@pexip/media-processor';

import type {Process, Media, DenoiseParams} from './types';
import {logger} from './logger';
import {shallowCopy, wrapToJSON, applyExtendedConstraints} from './utils';

type AudioNodeInits = AudioNodeInit[];
/**
 * A function to be called to create the AudioNodes needed for the graph
 * creation
 *
 * @param media - Media to be used for the AudioGraph creation
 */
type CreateNodes = (media: Media) => AudioNodeInits;

interface AudioProcessOptions {
    /**
     * An option is being passed to AnalyserNode creation when used
     * @see https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/fftSize
     *
     * @defaultValue 2048
     */
    fftSize?: number;
    /**
     * Params needed for setting up noise suppression WebAssembly and
     * AudioWorklet
     */
    denoiseParams?: DenoiseParams;
    /**
     * Update frequency for analyzer per second
     *
     * @defaultValue 0.5
     */
    analyzerUpdateFrequency?: number;
    /**
     * Audio Signal Detection duration in second
     *
     * @defaultValue 4.0
     */
    audioSignalDetectionDuration?: number;
    /**
     * Callback when Voice Activity detected
     */
    onVoiceActivityDetected?: () => void;
    /**
     * Callback when Audio Signal detected
     */
    onAudioSignalDetected?: (silent: boolean) => void;
    /**
     * @see AudioGraphOptions
     */
    audioGraphOptions?: AudioGraphOptions;
    /**
     * Whether or to enable this processor
     */
    shouldEnable: () => boolean;
    /**
     * Insert additional nodes between the source and destination
     */
    createNodes?: CreateNodes;
    /**
     * Silent threshold, how large the value of the sample is considered as
     * silent in FFTed time domain
     */
    silentThreshold?: number;
    scope?: string;
}

/**
 * Fetch the wasm when it doesn't exist from the provided, otherwise do nothing
 *
 * @param denoiseWasm - The wasm if it exists
 * @param wasmURL - The URL for the fetching
 */
const fetchDenoiseWasm = async (
    denoiseWasm: ArrayBuffer | undefined,
    wasmURL: string | undefined,
) => {
    if (!wasmURL || denoiseWasm) {
        return denoiseWasm;
    }
    return await (await fetch(wasmURL)).arrayBuffer();
};

interface AudioStreamProcessorProps {
    audioGraphOptions?: AudioGraphOptions;
    denoiseWasm?: ArrayBuffer;
    vad?: boolean;
    asd?: boolean;
    denoise?: boolean;
    analyzerBuffer?: Float32Array;
    denoiseNode?: DenoiseWorkletNodeInit;
    additionalAudioSourceNode?: AudioNodeInit<
        MediaStreamAudioSourceNode,
        MediaStreamAudioSourceNode
    >;
    mixerNode?: AudioNodeInit<ChannelMergerNode, ChannelMergerNode>;
    analyzer?: AnalyzerNodeInit;
    audioGraph?: AudioGraph;
}

const FEATURE_KEYS: ['denoise', 'vad', 'asd'] = ['denoise', 'vad', 'asd'];
type FeaturePropKeys = typeof FEATURE_KEYS[number];
type FeatureProps = Pick<AudioStreamProcessorProps, FeaturePropKeys>;

const getAudioConstraints = extractConstraintsWithKeys(FEATURE_KEYS);

export const updateFeatureProps = (
    constraints: MediaDeviceRequest['audio'],
    props: FeatureProps,
) => {
    const extracted = getAudioConstraints(constraints);
    return FEATURE_KEYS.reduce((accm, key) => {
        const [feature] = extracted[key];
        if (feature !== undefined && props[key] !== feature) {
            props[key] = feature;
            return {...accm, [key]: feature};
        }
        return accm;
    }, {} as FeatureProps);
};

/**
 * Create a Audio Stream Processor and will own the stream passed-in
 */
export const createAudioStreamProcess = ({
    analyzerUpdateFrequency = 0.5, // 0.5 Hz
    audioGraphOptions,
    audioSignalDetectionDuration = 4.0, // 4 seconds
    clock,
    createNodes,
    denoiseParams,
    fftSize = 2048, // FFT size
    onAudioSignalDetected,
    onVoiceActivityDetected,
    shouldEnable,
    silentThreshold = 10.0 / 32767, // At least one LSB 16-bit data (compare is on absolute value).
    throttleMs = 3000, // 3 seconds
    scope = 'media',
}: AudioProcessOptions & ThrottleOptions): Process<Promise<Media>> => {
    const props: AudioStreamProcessorProps = {
        audioGraphOptions,
        vad: false,
        asd: false,
        denoise: false,
    };

    const detectAudio =
        onAudioSignalDetected &&
        createAudioSignalDetector(() => !!props.asd, onAudioSignalDetected);

    const detectVA =
        onVoiceActivityDetected &&
        createVADetector(onVoiceActivityDetected, () => !!props.vad, {
            throttleMs,
            clock,
        });
    const detectVAFromTimeData = detectVA?.(createVoiceDetectorFromTimeData());

    const createDenoiseNode = async (denoise: boolean | undefined) => {
        if (!denoise) {
            return undefined;
        }
        if (props.denoiseNode) {
            return props.denoiseNode;
        }
        if (denoiseParams?.workletModule) {
            try {
                await props.audioGraph?.addWorklet(
                    denoiseParams?.workletModule,
                    denoiseParams?.workletOptions,
                );
            } catch (error: unknown) {
                logger.error(
                    {
                        scope,
                        error,
                        moduleURL: denoiseParams?.workletModule,
                        options: denoiseParams?.workletOptions,
                    },
                    'Failed add worklet',
                );
                return undefined;
            }
        }
        try {
            props.denoiseWasm = await fetchDenoiseWasm(
                props.denoiseWasm,
                denoiseParams?.wasmURL,
            );
        } catch (error: unknown) {
            logger.error(
                {
                    scope,
                    error,
                    url: denoiseParams?.wasmURL,
                    prevWasm: props.denoiseWasm,
                },
                'Failed to fetch denoise wasm',
            );
            return undefined;
        }
        const detectVAFromProbability = detectVA?.(
            createVoiceDetectorFromProbability(),
        );
        if (props.denoiseWasm) {
            const denoise = createDenoiseWorkletGraphNode(
                props.denoiseWasm,
                vads => {
                    detectVAFromProbability?.(avg(vads));
                },
            );
            props.denoiseNode = denoise;
            return denoise;
        }
    };

    const createAnalyzer = () => {
        const shouldUseAnalyzer = () =>
            !!((props.vad && !props.denoise) || props.asd) &&
            (detectAudio || detectVA);
        if (!shouldUseAnalyzer()) {
            return;
        }
        if (props.analyzer) {
            return props.analyzer;
        }
        const detectSilentAudio = detectAudio?.(
            createQueue<number[]>(
                audioSignalDetectionDuration / analyzerUpdateFrequency,
            ),
            silentThreshold,
        );
        const analyzer = createAnalyzerSubscribableGraphNode({
            updateFrequency: analyzerUpdateFrequency,
            messageHandler: analyzer => {
                if (shouldUseAnalyzer()) {
                    if (!props.analyzerBuffer) {
                        // Only Create the buffer when needed
                        props.analyzerBuffer = new Float32Array(fftSize);
                    }
                    analyzer.getFloatTimeDomainData(props.analyzerBuffer);
                    const data = Array.from(props.analyzerBuffer);
                    detectSilentAudio?.(data);
                    !props.denoise && detectVAFromTimeData?.(data);
                }
            },
            fftSize,
        });
        props.analyzer = analyzer;
        return analyzer;
    };

    return async mediaP => {
        const media = await mediaP;
        updateFeatureProps(media.constraints?.audio, props);
        const shouldProcessAudio =
            shouldEnable() &&
            !!media.stream?.getAudioTracks().length &&
            (!!onVoiceActivityDetected ||
                !!onAudioSignalDetected ||
                props.asd ||
                props.vad ||
                props.denoise ||
                !!createNodes);
        if (!media.stream?.getAudioTracks().length || !shouldProcessAudio) {
            return media;
        }
        try {
            const source = createStreamSourceGraphNode(media.stream);

            const destination = createStreamDestinationGraphNode();

            const otherNodes = createNodes?.(media) ?? [];
            const analyzer = createAnalyzer();
            const initialAudioNodeConnection = [
                [source, ...otherNodes, destination],
                [source, analyzer],
            ];
            logger.debug(
                {initialAudioNodeConnection, scope},
                'Initial AudioNodeConnection',
            );
            const audioGraph = createAudioGraphProxy(
                createAudioGraph(
                    initialAudioNodeConnection,
                    props.audioGraphOptions,
                ),
                {
                    connect: (target, args) => {
                        logger.debug({scope, target, args}, 'connect nodes');
                    },
                    disconnect: (target, args) => {
                        logger.debug({scope, target, args}, 'disconnect nodes');
                    },
                },
            );

            props.audioGraph = audioGraph;
            const denoiseNode = await createDenoiseNode(props.denoise);
            const connectDenoise = (
                node: DenoiseWorkletNodeInit | undefined,
            ) => {
                if (node) {
                    audioGraph.disconnect([source, ...otherNodes, destination]);
                    audioGraph.connect([
                        source,
                        node,
                        ...otherNodes,
                        destination,
                    ]);
                }
            };
            connectDenoise(denoiseNode);

            const tracks = [
                ...(destination?.node?.stream.getAudioTracks() ?? []),
                ...(media.stream?.getVideoTracks().map(t => t.clone()) ?? []),
            ];

            const stream = new MediaStream(tracks);

            const applyConstraints: Media['applyConstraints'] =
                applyExtendedConstraints(media, async constraints => {
                    if (isEmpty(constraints.audio)) {
                        return;
                    }
                    const features = updateFeatureProps(
                        constraints.audio,
                        props,
                    );
                    logger.debug(
                        {scope, constraints: constraints.audio, features},
                        'apply audio constraints',
                    );
                    if (
                        isEmpty(features) ||
                        ['closed', 'closing'].includes(audioGraph.state)
                    ) {
                        return;
                    }
                    try {
                        const denoiseNode = await createDenoiseNode(
                            props.denoise,
                        );
                        if (denoiseNode) {
                            if (!source.hasConnectedTo(denoiseNode)) {
                                connectDenoise(denoiseNode);
                            }
                        } else {
                            if (props.denoiseNode) {
                                audioGraph.disconnect([
                                    source,
                                    props.denoiseNode,
                                    ...otherNodes,
                                    destination,
                                ]);
                                audioGraph.connect([
                                    source,
                                    ...otherNodes,
                                    destination,
                                ]);
                                audioGraph.releaseInit(props.denoiseNode);
                                props.denoiseNode = undefined;
                            }
                        }
                        const analyzer = createAnalyzer();
                        if (analyzer) {
                            audioGraph.connect([source, analyzer]);
                        } else {
                            if (props.analyzer) {
                                audioGraph.disconnect([source, props.analyzer]);
                                audioGraph.releaseInit(props.analyzer);
                                props.analyzer = undefined;
                            }
                        }
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            logger.error(
                                {
                                    scope,
                                    constraints: constraints.audio,
                                    features,
                                },
                                'failed to apply audio constraints',
                            );
                        }
                    }
                });

            const release = async () => {
                logger.debug({scope}, 'Release Media');
                stopMediaStream(stream);
                // Release Props
                await audioGraph.release();
                await media.release();
                props.denoiseNode = undefined;
                props.analyzer = undefined;
                props.audioGraph = undefined;
            };
            const muteAudio = (mute: boolean) => {
                media.muteAudio(mute);
                muteStreamTrack(stream)(mute, 'audio');
            };
            const muteVideo = (mute: boolean) => {
                media.muteVideo(mute);
                muteStreamTrack(stream)(mute, 'video');
            };

            const prevGetSettings = media.getSettings;
            return wrapToJSON(
                shallowCopy(media, {
                    stream,
                    applyConstraints,
                    muteAudio,
                    muteVideo,
                    release,
                    getSettings: () => {
                        const {audio, video} = prevGetSettings();
                        const denoise =
                            !!props.denoiseNode &&
                            source.hasConnectedTo(props.denoiseNode);
                        const asd = !!props.asd;
                        const vad = !!props.vad;
                        const audioSettings = {
                            denoise,
                            asd,
                            vad,
                        };
                        const settings = {
                            audio: audio.map(settings => ({
                                ...settings,
                                ...audioSettings,
                            })),
                            video,
                        };
                        logger.debug(
                            {scope, settings: audioSettings},
                            'get audio processor settings',
                        );
                        return settings;
                    },
                }),
            );
        } catch (error: unknown) {
            logger.error(
                {scope, error},
                'Unable to use WebAudio, return the raw media instead',
            );
            return media;
        }
    };
};
