import React from 'react';

import type {InMeetingUI} from '@pexip/media-components';

import type {UserMenuViewProps} from '../views/UserMenu/UserMenu.view';
import {UserMenuView} from '../views/UserMenu/UserMenu.view';
import {DIRTY, VERSION} from '../constants';
import {useUserMenuContent} from '../hooks/useUserMenuContent';

export const UserMenu: React.FC<
    Pick<UserMenuViewProps, 'autoHideProps' | 'variant'> & {
        isInMeeting?: boolean;
        inMeetingUI?: InMeetingUI;
    }
> = ({isInMeeting = false, inMeetingUI, ...props}) => {
    const {menuContent, isAboutOpen, setAboutOpen} = useUserMenuContent(
        isInMeeting,
        inMeetingUI,
    );
    return (
        <UserMenuView
            isAboutOpen={isAboutOpen}
            version={`${VERSION}${DIRTY}`}
            menuContent={menuContent}
            setAboutOpen={setAboutOpen}
            {...props}
        />
    );
};
