import React from 'react';
import cx from 'classnames';

import type {DeniedDevices, UserMediaStatus} from '@pexip/media';
import {toDeniedDevices, isInitialPermissionsNotGranted} from '@pexip/media';

import {TestId} from '../../../test/testIds';
import {VideoStatus} from '../VideoStatus/VideoStatus.view';
import {useDeviceStatusInfo} from '../../hooks/useDeviceStatusInfo';
import type {SelfviewAspectRatios} from '../../types';

import {isVideoMuted} from './SelfView.utils';
import type {SelfviewProps} from './Selfview.view';
import {AspectRatioSelfview} from './AspectRatioSelfview';

import styles from './Selfview.module.scss';

export const PreflightSelfview: React.FC<
    Omit<SelfviewProps, 'shouldShowUserAvatar'> & {
        aspectRatioType?: SelfviewAspectRatios;
        dismissPermissionsLinkMessage?: boolean;
        isMobileDevice?: boolean;
        learnHowToGrantAccessURL?: string;
        onTryAgain?: (deniedDevice: DeniedDevices) => void;
        requestMediaPermissions: () => void;
        segmentationEnabled?: boolean;
        setDismissPermissionsLinkMessage?: React.Dispatch<
            React.SetStateAction<boolean>
        >;
        shouldShowBorder: boolean;
        streamStatus: UserMediaStatus | undefined;
        preflightControls?: React.ReactElement;
        triggerMediaModal?: () => void;
    }
> = props => {
    const {
        aspectRatioType = 'camera-step',
        dismissPermissionsLinkMessage,
        isMobileDevice = false,
        isVideoInputMuted,
        learnHowToGrantAccessURL,
        setDismissPermissionsLinkMessage,
        shouldShowErrorBorder,
        streamStatus,
        preflightControls,
        triggerMediaModal,
    } = props;
    const inputStatusInfo = useDeviceStatusInfo(props.streamStatus);
    const shouldShowUserAvatar = isVideoMuted(isVideoInputMuted, streamStatus);
    const shouldShowVideoStatus =
        streamStatus && isInitialPermissionsNotGranted(streamStatus);
    const deniedDevices = toDeniedDevices(streamStatus);

    return (
        <div className={styles.preflightSelfViewWrapper}>
            <AspectRatioSelfview
                {...props}
                shouldShowUserAvatar={shouldShowUserAvatar}
                shouldShowErrorBorder={shouldShowErrorBorder}
                learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                dismissPermissionsLinkMessage={dismissPermissionsLinkMessage}
                setDismissPermissionsLinkMessage={
                    setDismissPermissionsLinkMessage
                }
                onTriggerMediaModal={triggerMediaModal}
                deniedDevice={deniedDevices}
                className={cx({
                    [styles.hideBackground]:
                        isMobileDevice && shouldShowVideoStatus,
                })}
                aspectRatioType={aspectRatioType}
            >
                {shouldShowVideoStatus && (
                    <VideoStatus
                        title={inputStatusInfo.title}
                        body={inputStatusInfo.message}
                        data-testid={TestId.VideoStatus}
                        isPhone={isMobileDevice}
                    />
                )}
                {preflightControls}
            </AspectRatioSelfview>
        </div>
    );
};

export type PreflightSelfviewProps = React.ComponentProps<
    typeof PreflightSelfview
>;
