import React from 'react';
import cx from 'classnames';

import {TestId} from '../../../test/testIds';

import styles from './FrequencyBarsAudioMeter.module.scss';

export const FrequencyBar: React.FC<{
    className?: string;
}> = ({className}) => <div className={cx(styles.frequencyBar, className)} />;

export const FrequencyBars: React.FC<{
    barClassName?: string;
    barsNumber?: number;
}> = ({barClassName, barsNumber = 20}) => (
    <>
        {Array.from({length: barsNumber}).map((_, index) => (
            <FrequencyBar key={index} className={barClassName} />
        ))}
    </>
);

export const FrequencyBarsAudioMeter: React.FC<
    React.ComponentProps<'div'> & {
        barClassName?: string;
        barsWrapperClassName?: string;
        barsNumber?: number;
        className?: string;
        barsWrapperId?: string;
        progressBackgroundId?: string;
        showBorder?: boolean;
        showBackground?: boolean;
    }
> = ({
    barClassName,
    barsWrapperClassName,
    barsNumber = 20,
    className,
    barsWrapperId,
    progressBackgroundId,
    showBorder = false,
    showBackground = false,
    children,
    ...props
}) => (
    <div
        className={cx(
            styles.boxWrapper,
            showBorder && styles.border,
            showBackground && styles.background,
            className,
        )}
        id={progressBackgroundId}
        data-testid={TestId.TestAudioInputIndicator}
        {...props}
    >
        <div
            className={cx(styles.barsWrapper, barsWrapperClassName)}
            id={barsWrapperId}
        >
            <FrequencyBars
                barsNumber={barsNumber}
                barClassName={barClassName}
            />
        </div>
        {children}
    </div>
);

export type FrequencyBarsAudioMeterProps = React.ComponentProps<
    typeof FrequencyBarsAudioMeter
>;
