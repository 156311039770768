/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface TransformLayoutMap {
    '200': Status200;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    /**
     * The result is true if successful, false otherwise.
     */
    result: boolean;
}
export interface TopLevel {
    transforms: Transforms;
}
export interface Transforms {
    /**
     * In VMRs the layout for Hosts and Guests is controlled by the layout parameter.
     */
    layout?:
        | '1:0'
        | '1:7'
        | '1:21'
        | '2:21'
        | '1:33'
        | '4:0'
        | '9:0'
        | '16:0'
        | '25:0'
        | '5:7'
        | 'ac_presentation_in_mix'
        | 'ac_presentation_in_mix_group';
    /**
     * In Virtual Auditoriums the Host layout is controlled by the host_layout parameter
     */
    host_layout?:
        | '1:0'
        | '1:7'
        | '1:21'
        | '2:21'
        | '1:33'
        | '4:0'
        | '9:0'
        | '16:0'
        | '25:0'
        | '5:7'
        | 'ac_presentation_in_mix'
        | 'ac_presentation_in_mix_group';
    /**
     * In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter
     */
    guest_layout?:
        | '1:0'
        | '1:7'
        | '1:21'
        | '2:21'
        | '1:33'
        | '4:0'
        | '9:0'
        | '16:0'
        | '25:0'
        | '5:7'
        | 'ac_presentation_in_mix'
        | 'ac_presentation_in_mix_group';
    /**
     * This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.
     */
    enable_extended_ac?: boolean;
    /**
     * Determines whether the streaming indicator icon is disabled (false) or enabled (true).
     */
    streaming_indicator?: boolean;
    /**
     * Determines whether the recording indicator icon is disabled (false) or enabled (true).
     */
    recording_indicator?: boolean;
    /**
     * Determines whether active speaker indication is disabled (false) or enabled (true).
     */
    enable_active_speaker_indication?: boolean;
    /**
     * Determines whether active speaker indication is disabled (false) or enabled (true).
     */
    enable_overlay_text?: boolean;
    streaming?: Streaming;
    /**
     * Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.
     */
    free_form_overlay_text?: string[];
}
export interface Streaming {
    /**
     * Sets the layout seen by the streaming participant (regardless of Host or Guest role).
     */
    layout?:
        | '1:0'
        | '1:7'
        | '1:21'
        | '2:21'
        | '1:33'
        | '4:0'
        | '9:0'
        | '16:0'
        | '25:0';
    /**
     * Determines whether the stream_waiting splash screen is displayed (true) or not (false).
     */
    waiting_screen_enabled?: boolean;
    /**
     * Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).
     */
    plus_n_pip_enabled?: boolean;
    /**
     * Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).
     */
    indicators_enabled?: boolean;
}

import {validateBody, validate200} from './transformLayoutValidation';
type TransformLayoutResponse = {status: 200; data: TransformLayoutMap['200']};
interface TransformLayoutParams {
    conferenceAlias: string;
}
export async function transformLayout({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: TransformLayoutParams;
    body: TransformLayoutMap['Body'];
    host: string;
}): Promise<TransformLayoutResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/transform_layout`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
