import {getInputDevicePermissionState} from '@pexip/media-control';

import {UserMediaStatus, DeniedDevices} from './types';

export type UserMediaValidator = (status: UserMediaStatus) => boolean;

export const isFallbackVideo: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsOnlyVideoinputFallback,
        UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices,
        UserMediaStatus.PermissionsGrantedFallback,
        UserMediaStatus.PermissionsGrantedFallbackVideoinput,
    ].includes(status);

export const isFallbackAudio: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsOnlyAudioinputFallback,
        UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices,
        UserMediaStatus.PermissionsGrantedFallback,
        UserMediaStatus.PermissionsGrantedFallbackAudioinput,
    ].includes(status);

export const isFallback: UserMediaValidator = status =>
    status === UserMediaStatus.PermissionsGrantedFallback ||
    isFallbackAudio(status) ||
    isFallbackVideo(status);

export const hasNoAudioDevices: UserMediaValidator = status =>
    [
        UserMediaStatus.NoAudioDevicesFound,
        UserMediaStatus.NoDevicesFound,
    ].includes(status) || isGrantedOnlyVideoNoAudioDevices(status);

export const hasNoVideoDevices: UserMediaValidator = status =>
    [
        UserMediaStatus.NoVideoDevicesFound,
        UserMediaStatus.NoDevicesFound,
    ].includes(status) || isGrantedOnlyAudioNoVideoDevices(status);

export const isGrantedOnlyVideoNoAudioDevices: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsOnlyVideoinputNoAudioDevices,
        UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices,
    ].includes(status);

export const isGrantedOnlyVideo: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsOnlyVideoinput,
        UserMediaStatus.PermissionsOnlyVideoinputFallback,
        UserMediaStatus.PermissionsRejectedAudioInput,
        UserMediaStatus.AudioDeviceInUse,
    ].includes(status);

export const isGrantedOnlyAudioNoVideoDevices: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsOnlyAudioinputNoVideoDevices,
        UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices,
    ].includes(status);

export const isGrantedOnlyAudio: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsOnlyAudioinput,
        UserMediaStatus.PermissionsOnlyAudioinputFallback,
        UserMediaStatus.PermissionsRejectedVideoInput,
        UserMediaStatus.VideoDeviceInUse,
    ].includes(status);

export const areBothGranted: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsGranted,
        UserMediaStatus.PermissionsGrantedFallback,
        UserMediaStatus.PermissionsGrantedFallbackAudioinput,
        UserMediaStatus.PermissionsGrantedFallbackVideoinput,
    ].includes(status);

export const isGranted: UserMediaValidator = status =>
    areBothGranted(status) ||
    isGrantedOnlyAudio(status) ||
    isGrantedOnlyAudioNoVideoDevices(status) ||
    isGrantedOnlyVideoNoAudioDevices(status) ||
    isGrantedOnlyVideo(status);

export const isOnlyAudioError: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsRejectedAudioInput,
        UserMediaStatus.AudioDeviceInUse,
        UserMediaStatus.AudioOverconstrained,
        UserMediaStatus.InvalidAudioConstraints,
        UserMediaStatus.NotSupportedErrorOnlyAudioInput,
    ].includes(status);

export const isOnlyVideoError: UserMediaValidator = status =>
    [
        UserMediaStatus.PermissionsRejectedVideoInput,
        UserMediaStatus.VideoDeviceInUse,
        UserMediaStatus.VideoOverconstrained,
        UserMediaStatus.InvalidVideoConstraints,
        UserMediaStatus.NotSupportedErrorOnlyVideoInput,
    ].includes(status);

export const isRejected: UserMediaValidator = status =>
    UserMediaStatus.PermissionsRejected === status;

export const isOverConstrained: UserMediaValidator = status =>
    [
        UserMediaStatus.Overconstrained,
        UserMediaStatus.VideoOverconstrained,
        UserMediaStatus.AudioOverconstrained,
    ].includes(status);

export const isUnknownError: UserMediaValidator = status =>
    [
        UserMediaStatus.UnknownError,
        UserMediaStatus.UnknownErrorOnlyVideoinput,
        UserMediaStatus.UnknownErrorOnlyAudioinput,
    ].includes(status);

export const isInitial: UserMediaValidator = status =>
    status === UserMediaStatus.Initial;

export const isInitialPermissions: UserMediaValidator = status =>
    [
        UserMediaStatus.InitialPermissionsAudioInputDenied,
        UserMediaStatus.InitialPermissionsAudioInputGranted,
        UserMediaStatus.InitialPermissionsGranted,
        UserMediaStatus.InitialPermissionsGrantedAudioInputDenied,
        UserMediaStatus.InitialPermissionsGrantedVideoInputDenied,
        UserMediaStatus.InitialPermissionsNotGranted,
        UserMediaStatus.InitialPermissionsVideoInputDenied,
        UserMediaStatus.InitialPermissionsVideoInputGranted,
    ].includes(status);

export const isInitialPermissionsNotGranted: UserMediaValidator = status =>
    [
        UserMediaStatus.InitialPermissionsAudioInputDenied,
        UserMediaStatus.InitialPermissionsAudioInputGranted,
        UserMediaStatus.InitialPermissionsNotGranted,
        UserMediaStatus.InitialPermissionsVideoInputDenied,
        UserMediaStatus.InitialPermissionsVideoInputGranted,
    ].includes(status);

export const isInitialPermissionsGranted: UserMediaValidator = status =>
    [
        UserMediaStatus.InitialPermissionsGranted,
        UserMediaStatus.InitialPermissionsGrantedAudioInputDenied,
        UserMediaStatus.InitialPermissionsGrantedVideoInputDenied,
    ].includes(status);

export const isAudioDeviceInUse: UserMediaValidator = status =>
    status === UserMediaStatus.AudioDeviceInUse;

export const isDeviceInUse: UserMediaValidator = status =>
    [
        UserMediaStatus.DevicesInUse,
        UserMediaStatus.AudioDeviceInUse,
        UserMediaStatus.VideoDeviceInUse,
    ].includes(status);

export const isVideoDeviceInUse: UserMediaValidator = status =>
    status === UserMediaStatus.VideoDeviceInUse;

export const toDeniedDevices = (
    status?: UserMediaStatus,
): DeniedDevices | undefined => {
    if (!status) {
        return undefined;
    }
    if (isGrantedOnlyAudio(status)) {
        return DeniedDevices.Camera;
    }
    if (isGrantedOnlyVideo(status)) {
        return DeniedDevices.Microphone;
    }
    if (isRejected(status)) {
        return DeniedDevices.Both;
    }
};

export const getPermissionStatus = async (
    getPermissionState = getInputDevicePermissionState,
) => {
    const {audio, video} = await getPermissionState();
    if (audio === 'denied') {
        if (video === 'denied') {
            return UserMediaStatus.PermissionsRejected;
        }
        if (video === 'granted') {
            return UserMediaStatus.InitialPermissionsGrantedAudioInputDenied;
        }
        if (video === 'prompt') {
            return UserMediaStatus.InitialPermissionsAudioInputDenied;
        }
    }
    if (audio === 'granted') {
        if (video === 'denied') {
            return UserMediaStatus.InitialPermissionsGrantedVideoInputDenied;
        }
        if (video === 'granted') {
            return UserMediaStatus.InitialPermissionsGranted;
        }
        if (video === 'prompt') {
            return UserMediaStatus.InitialPermissionsAudioInputGranted;
        }
    }
    if (audio === 'prompt') {
        if (video === 'denied') {
            return UserMediaStatus.InitialPermissionsVideoInputDenied;
        }
        if (video === 'granted') {
            return UserMediaStatus.InitialPermissionsVideoInputGranted;
        }
        if (video === 'prompt') {
            return UserMediaStatus.InitialPermissionsNotGranted;
        }
    }
    return UserMediaStatus.Initial;
};
