import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    FontVariant,
    Icon,
    IconTypes,
    Text,
    TextLink,
    ThemeProvider,
} from '@pexip/components';

import styles from './CallQualityAlert.module.scss';

export const CallQualityAlert: React.FC<
    React.ComponentProps<'button'> & {href?: string; onClose?: () => void}
> = ({onClick}) => {
    const {t} = useTranslation();

    return (
        <ThemeProvider colorScheme="light">
            <div className={cx('d-flex align-items-center', styles.wrapper)}>
                <Icon source={IconTypes.IconWarningRound} className="mr-1" />{' '}
                <Text fontVariant={FontVariant.Body}>
                    <Trans t={t} i18nKey="quality.poor-connection-detected">
                        Poor connection detected
                    </Trans>
                </Text>{' '}
                {onClick && (
                    <TextLink
                        fontVariant={FontVariant.Body}
                        onClick={onClick}
                        className="ml-1"
                    >
                        <Trans t={t} i18nKey="quality.change-quality">
                            Change quality
                        </Trans>
                    </TextLink>
                )}
            </div>
        </ThemeProvider>
    );
};

export type CallQualityAlertProps = React.ComponentProps<
    typeof CallQualityAlert
>;
