import {useRef, useLayoutEffect, useState, useEffect} from 'react';

import type {Media} from '@pexip/media';
import {getCreateLoopbackConnectionFn} from '@pexip/peer-connection';
import type {AnalyzerNodeInit, AudioGraph} from '@pexip/media-processor';
import {
    createStreamDestinationGraphNode,
    createDelayGraphNode,
    createAnalyzerGraphNode,
    createAudioGraph,
    createStreamSourceGraphNode,
    isAnalyzerNodeInit,
} from '@pexip/media-processor';
import type {Signal} from '@pexip/signal';
import {currentBrowserName} from '@pexip/media-components';

import {VISUALIZER_FFT_SIZE} from '../constants';

/**
 *  Creates and returns `AnalyzerNodeInit` based on the main media's `rawStream`.
 *
 *  Note: The `main media` is used here and not the `preview media`, that used in Settings modal.
 *  `rawStream` is used here and not `mediaService.media.stream`, because the audio meter should
 *  keep measuring the sound, regardless if the main audio track is muted or not.
 */
export const useMicrophoneMonitorAnalyzer = ({
    media,
    mediaChangeSubscribe,
    isEnabled = true,
    fftSize = VISUALIZER_FFT_SIZE,
}: {
    media: Media;
    mediaChangeSubscribe: Signal<Media>['add'];
    isEnabled: boolean;
    fftSize?: number;
}) => {
    const audioGraph = useRef<AudioGraph | undefined>();
    const [analyzer, setAnalyzer] = useState<AnalyzerNodeInit>();
    const [outputStream, setOutputStream] = useState<MediaStream>();

    useLayoutEffect(() => {
        const updateState = (media: Media) => {
            if (!media.rawStream?.getAudioTracks().length) {
                return;
            }
            const {graph, outputStream} = createGraphAnalyzer(
                media.rawStream,
                fftSize,
            );
            audioGraph.current = graph;
            const analyzerNode =
                audioGraph.current?.inits.find(isAnalyzerNodeInit);
            setAnalyzer(analyzerNode);
            setOutputStream(outputStream);
        };

        const cleanUp = () => {
            void audioGraph.current?.release?.();
            setAnalyzer(undefined);
        };

        const removeSignal = mediaChangeSubscribe(media => {
            cleanUp();
            updateState(media);
        });

        if (isEnabled) {
            updateState(media);
        } else {
            cleanUp();
        }

        return () => {
            removeSignal();
            cleanUp();
        };
    }, [fftSize, isEnabled, media, mediaChangeSubscribe]);

    return {analyzer, outputStream};
};

const createGraphAnalyzer = (stream: MediaStream, fftSize: number) => {
    const sourceNode = createStreamSourceGraphNode(stream);
    const delayNode = createDelayGraphNode({delayTime: 1});
    const analyzerNode = createAnalyzerGraphNode({
        fftSize,
    });
    const destinationNode = createStreamDestinationGraphNode();
    const graph = createAudioGraph([
        [sourceNode, delayNode, analyzerNode, destinationNode],
    ]);

    return {graph, outputStream: destinationNode.node?.stream};
};

/**
 * Applies a local `webRTC` loopback connection echo cancellation to a `WebAudio` streams.
 *
 * Addresses https://bugs.chromium.org/p/chromium/issues/detail?id=687574 issue.
 *
 * Note: None Chromium browsers are excluded and work properly with the given `MediaStream`.
 */
export const useChromiumLoopbackConnection = (stream?: MediaStream) => {
    const [srcObject, setSrcObject] = useState<MediaStream>();

    useEffect(() => {
        const createLoopbackConnection = getCreateLoopbackConnectionFn();

        if (currentBrowserName === 'Chrome' && stream) {
            void createLoopbackConnection(stream).then(loopbackStream =>
                setSrcObject(loopbackStream),
            );
        } else {
            setSrcObject(stream);
        }
    }, [stream]);

    return srcObject;
};
