import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Text,
    TextHeading,
    FontVariant,
    List,
    Icon,
    IconTypes,
    TextLink,
} from '@pexip/components';
import {useDeviceStatusInfo} from '@pexip/media-components';
import {UserMediaStatus} from '@pexip/media';

export const PermissionsRejectedAudioInput: React.FC<{
    next?: () => void;
}> = ({next}) => {
    const {t} = useTranslation();
    const statusInfo = useDeviceStatusInfo(
        UserMediaStatus.PermissionsRejectedAudioInput,
    );

    return (
        <div
            className={cx(
                'flex',
                'text-center',
                'flex-direction-column',
                'justify-content-space-between',
                'p-5',
            )}
        >
            <List>
                <TextHeading
                    htmlTag={FontVariant.H5}
                    className={'text-center pl-5 pr-5'}
                >
                    {statusInfo.title}
                </TextHeading>
                <Icon
                    colorScheme="light"
                    source={IconTypes.IconMicrophoneOff}
                    size="medium"
                    className={'mx-auto my-10'}
                />
                <Text fontVariant={FontVariant.Body} className="mb-4">
                    {statusInfo.message}
                </Text>
                <TextLink className={'text-center mt-4'} onClick={next}>
                    {t(
                        'media.help-proceed-without-microphone',
                        'Proceed without microphone',
                    )}
                </TextLink>
            </List>
        </div>
    );
};
