import {useEffect, useState} from 'react';

import type {UserMediaStatus} from '@pexip/media';
import {isInitial, isInitialPermissionsNotGranted} from '@pexip/media';

import {PreflightJoinDetailsToRender} from '../types';

export const usePreflightJoinDetailsToRender = (
    streamStatus?: UserMediaStatus,
) => {
    const [joinDetailsToRender, setJoinCallDetailsToRender] = useState(
        PreflightJoinDetailsToRender.Spinner,
    );

    useEffect(() => {
        if (
            streamStatus &&
            (isInitial(streamStatus) ||
                isInitialPermissionsNotGranted(streamStatus))
        ) {
            setJoinCallDetailsToRender(
                PreflightJoinDetailsToRender.BlockedDeviceSelection,
            );
        } else {
            setJoinCallDetailsToRender(
                PreflightJoinDetailsToRender.DeviceSelection,
            );
        }
    }, [streamStatus]);

    return [joinDetailsToRender, setJoinCallDetailsToRender] as const;
};
