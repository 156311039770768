const SUPPORTED = ((key: string, value: string) => {
    try {
        localStorage.setItem(key, value);
        if (localStorage.getItem(key) !== value) {
            throw new Error('local storage not supported');
        }
        localStorage.removeItem(key);
        return true;
    } catch (_error) {
        return false;
    }
})('test-key', 'test-value');

const fallback: {[key: string]: string} = {};

export const setItem = (key: string, value: string) => {
    if (SUPPORTED) {
        try {
            // While it might be supported it can still throw a `QuotaExceededError`
            localStorage.setItem(key, value);
        } catch (error) {
            // log ?
            return false;
        }
    } else {
        fallback[key] = value;
    }
    return true;
};

export const getItem = (key: string) => {
    if (SUPPORTED) {
        return localStorage.getItem(key);
    } else {
        return fallback[key];
    }
};

export const deleteItem = (key: string) => {
    if (SUPPORTED) {
        localStorage.removeItem(key);
    } else {
        delete fallback[key];
    }
};
