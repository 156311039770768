import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Bar, Button} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const EffectsControls: React.FC<{
    cancel: () => void;
    isSaving: boolean;
    save: () => void;
    updatingPreview: boolean;
}> = ({cancel, isSaving, save, updatingPreview}) => {
    const {t} = useTranslation();
    return (
        <Bar>
            <Button
                onClick={cancel}
                variant="tertiary"
                size="medium"
                modifier="fullWidth"
                data-testid={TestId.ButtonSettingsCancel}
                isDisabled={isSaving || updatingPreview}
            >
                <Trans t={t} i18nKey="settings.cancel-changes">
                    Cancel changes
                </Trans>
            </Button>

            <Button
                onClick={save}
                type="submit"
                modifier="fullWidth"
                className="ml-2"
                isLoading={isSaving || updatingPreview}
                isDisabled={isSaving}
                data-testid={TestId.ButtonSettingsSave}
            >
                <Trans t={t} i18nKey="settings.save-changes">
                    Save changes
                </Trans>
            </Button>
        </Bar>
    );
};
