import type {
    MediaDeviceInfoLike,
    MediaInput,
    MediaDeviceRequest,
    InputDeviceConstraint,
    InputConstraintSet,
    InputDevicePermission,
} from './types';
import {MediaDeviceKinds} from './types';
import {
    extractConstrainNumber,
    extractConstrainString,
    normalizeDeviceConstraint,
    toArray,
    relaxInputConstraint,
    satisfyConstrainNumber,
    extractConstraintsWithKeys,
} from './constraints';
import {
    isMediaDeviceInfo,
    isConstraintSetDevice,
    isInputConstraintSet,
    isConstrainDOMStringParameters,
    isConstraintDOMString,
    isMediaStreamTrack,
} from './typeGuards';
import {findDevice, compareDevices} from './utils';

const SEPARATOR = ':';

/**
 * DeviceChange Event
 *
 * ```
 * devices (raw)
 * |--- authorized
 * |        |--- found
 * |        |--- lost
 * |--- unauthorized
 * ```
 *
 * @beta
 */
export type DeviceChangedChanges = {
    authorized: MediaDeviceInfoLike[];
    unauthorized: MediaDeviceInfoLike[];
    found: MediaDeviceInfoLike[];
    lost: MediaDeviceInfoLike[];
    devices: MediaDeviceInfoLike[];
};

interface ToKeyOptions {
    id: string;
    kind: string;
    label: string;
}

/**
 * Convert provided info to key for Map
 *
 * @beta
 */
export const toKey = ({id, kind, label}: ToKeyOptions): string =>
    [kind, id, label].join(SEPARATOR);

/**
 * Future proofing: in case we want to alter the values or type returned
 * by {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices | enumerateDevices}
 *
 * @returns
 * a list of currently available {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo | devices}
 *
 * @example
 * ```javascript
 * import {getDevices} from "media-control";
 *
 * const devices = await getDevices();
 * // return MediaDeviceInfo[]
 * ```
 *
 * @beta
 */
export const getDevices = async () => {
    if (
        'mediaDevices' in navigator &&
        'enumerateDevices' in navigator.mediaDevices
    ) {
        const devices = await navigator.mediaDevices.enumerateDevices();

        return devices;
    }
    // Return empty list as there is no enumerateDevices
    return [];
};

export const toDeviceKey = (device: MediaDeviceInfoLike) =>
    toKey({id: device.deviceId, kind: device.kind, label: device.label});

export const toDeviceTuple = (device: MediaDeviceInfoLike) =>
    [toDeviceKey(device), device] as const;

export const toDevicesMap = (devices: MediaDeviceInfoLike[]) => {
    return new Map(devices.map(toDeviceTuple));
};

export const toUniqueDevices = (devices: MediaDeviceInfoLike[]) => {
    const map = toDevicesMap(devices);
    return Array.from(map.values());
};

export const createTrackDevicesChanges = (
    prevDevices: MediaDeviceInfoLike[] = [],
) => {
    let seen = toDevicesMap(prevDevices);
    return (devices: MediaDeviceInfoLike[]) => {
        const found = [];
        const lost = [];
        // Remove duplicated
        const uniqueDevices = toUniqueDevices(devices);
        const {authorized, unauthorized} = uniqueDevices.reduce(
            (ds, d) => {
                // Use label to differentiate un/authorized
                if (d.label) {
                    ds.authorized.push(d);
                } else {
                    ds.unauthorized.push(d);
                }
                return ds;
            },
            {authorized: [], unauthorized: []} as {
                authorized: MediaDeviceInfoLike[];
                unauthorized: MediaDeviceInfoLike[];
            },
        );

        for (const device of authorized) {
            if (!seen.delete(toDeviceKey(device))) {
                found.push(device);
            }
        }

        for (const device of seen.values()) {
            lost.push(device);
        }
        seen = new Map(authorized.map(toDeviceTuple));

        return {unauthorized, authorized, found, lost, devices: uniqueDevices};
    };
};

/**
 * Unified interface for subscribing {@link DeviceChangedChanges} Event
 *
 * @beta
 */
export const deviceChanged = (fn: (event: DeviceChangedChanges) => void) => {
    const trackChanges = createTrackDevicesChanges();

    const odc = async () => {
        const devices = await getDevices();
        const changes = trackChanges(devices);
        fn(changes);
    };

    // Add extra checking to avoid calling unavailable feature
    // There is `ondevicechange` property in `navigator.mediaDevices` in Safari
    // 11 & 12 but it does nothing and there is no event listener functions
    // available since Safari does not support this API
    //
    // Ref. https://caniuse.com/#search=ondevicechange
    if (navigator.mediaDevices && 'ondevicechange' in navigator.mediaDevices) {
        navigator.mediaDevices.ondevicechange = odc;
        return () => {
            navigator.mediaDevices.ondevicechange = null;
        };
    }
    const it = window.setInterval(() => void odc(), 5000);
    return () => {
        window.clearInterval(it);
    };
};

export const extractDeviceInfo = (track: MediaStreamTrack) => {
    const {deviceId, groupId, ...settings} = track.getSettings();
    const kind =
        track.kind === 'audio'
            ? MediaDeviceKinds.AUDIOINPUT
            : MediaDeviceKinds.VIDEOINPUT;
    return {
        kind,
        deviceId: deviceId ?? '',
        groupId: groupId ?? '',
        label: track.label,
        settings,
    };
};

/**
 * Convert `MediaStreamTrack` to `MediaDeviceInfoLike`
 *
 * @beta
 */
export const toMediaDeviceInfoLike = (
    track: MediaStreamTrack,
): MediaDeviceInfoLike | undefined => {
    const {deviceId, label, kind, groupId} = extractDeviceInfo(track);

    if (!deviceId) {
        return;
    }
    return {deviceId, label, kind, groupId};
};

/**
 * Compare the provide the MediaStreamTrack and MediaDeviceInfo to see if they
 * are the same with best efforts
 *
 * @param track - `MediaStreamTrack` used for the comparison
 * @param device - `MediaDeviceInfo` used for the comparison
 *
 * @returns `true` if they are the same else `false`
 *
 * @remarks
 * Old browser may not have `deviceId` from getSettings()
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings
 * The `label` attribute will then be used for the comparison instead of the
 * `deviceId`
 *
 * @beta
 */
export const compareMediaDeviceToMediaTrack =
    (track: Pick<MediaStreamTrack, 'kind' | 'getSettings' | 'label'>) =>
    (device: MediaDeviceInfoLike) => {
        const {deviceId} = track.getSettings();

        const sameLabel = device.label === track.label;
        const sameKind = device.kind.includes(track.kind);
        // Old browser may not have deviceId from getSettings()
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings
        if (deviceId) {
            return sameKind && deviceId === device.deviceId;
        }
        return sameKind && sameLabel;
    };

const toMediaDeviceInfoLikeJSON = (info: MediaDeviceInfoLike) => ({
    deviceId: info.deviceId,
    groupId: info.groupId,
    kind: info.kind,
    label: info.label,
    settings: info.settings,
});

/**
 * Find the MediaDeviceInfo from provided MediaDeviceInfo[] by comparing with
 * provided MediaStreamTrack
 *
 * @param devices - A device list used for the searching
 * @param track - A track for the searching criteria
 *
 * @returns `MediaDeviceInfo` if found, otherwise `undefined`
 *
 * @beta
 */
export const findMediaInputFromMediaStreamTrack =
    (devices: MediaDeviceInfoLike[]) =>
    (track?: MediaStreamTrack | undefined): MediaDeviceInfoLike | undefined => {
        if (!devices.length || !track) {
            return undefined;
        }
        const settings = track.getSettings();
        // There is no need to compare since it is only device
        const onlyInput = devices.filter(d => d.kind === `${track.kind}input`);
        const onlyDevice = onlyInput[0];
        if (onlyInput.length === 1 && onlyDevice) {
            onlyDevice.settings = settings;
            onlyDevice.toJSON = () => toMediaDeviceInfoLikeJSON(onlyDevice);
            return onlyDevice;
        }
        const device = devices
            .filter(device =>
                ['audioinput', 'videoinput'].includes(device.kind),
            )
            .find(compareMediaDeviceToMediaTrack(track));
        if (device) {
            device.settings = settings;
            device.toJSON = () => toMediaDeviceInfoLikeJSON(device);
            return device;
        }
        return device;
    };

/**
 * Find media input from media stream
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 * @param stream - A media stream used for the search criteria
 *
 * @returns A object may contains the devices
 *
 * @beta
 */
export const findMediaInputFromStream =
    (devices: MediaDeviceInfoLike[]) =>
    (stream: MediaStream | undefined): MediaInput => {
        if (!stream || !devices.length) {
            return {};
        }
        const findMediaInput = findMediaInputFromMediaStreamTrack(devices);
        return {
            audioInput: findMediaInput(...stream.getAudioTracks()),
            videoInput: findMediaInput(...stream.getVideoTracks()),
        };
    };

export const compareDeviceConstraintAndTrackDevice =
    (device: MediaDeviceInfoLike | undefined) =>
    (constraint: InputConstraintSet['device']) => {
        if (!device || !constraint) {
            return Boolean(device) === Boolean(constraint);
        }
        const normalized = normalizeDeviceConstraint(constraint);
        if (normalized) {
            const requestingDevices = Object.values(
                normalized as Record<string, MediaDeviceInfoLike[]>,
            ).flatMap(t => (Array.isArray(t) ? t : [t]));
            const compare = compareDevices(
                device,
                device.label ? 'label' : 'deviceId',
            );
            return requestingDevices.some(compare);
        }
        return false;
    };

export const isRequestedResolution = (
    request: InputDeviceConstraint | undefined,
    response: MediaDeviceInfoLike | undefined,
) => {
    if (!response || !request) {
        return Boolean(response) === Boolean(request);
    }
    if (isInputConstraintSet(request)) {
        const [width] = extractConstrainNumber('width')(request);
        const [height] = extractConstrainNumber('height')(request);

        const sameWidth = satisfyConstrainNumber(
            width,
            response.settings?.width,
        );
        const sameHeight = satisfyConstrainNumber(
            height,
            response.settings?.height,
        );
        return sameWidth && sameHeight;
    }
    return true;
};

export const isRequestedInputDevice = (
    request: InputDeviceConstraint | undefined,
    response: MediaDeviceInfoLike | undefined,
) => {
    if (!response || !request) {
        return Boolean(response) === Boolean(request);
    }
    const compareDevice = compareDeviceConstraintAndTrackDevice(response);
    if (isInputConstraintSet(request)) {
        if (isConstraintSetDevice(request.device)) {
            const isSameDevice = compareDevice(request.device);
            return isSameDevice;
        }
        if (request.deviceId) {
            const {deviceId} = response;
            if (!deviceId) {
                return true;
            }
            if (isConstraintDOMString(request.deviceId)) {
                const ids = toArray(request.deviceId);
                return ids.some(id => deviceId === id);
            }
            if (isConstrainDOMStringParameters(request.deviceId)) {
                return Object.values(request.deviceId)
                    .flatMap(toArray)
                    .some(id => id === deviceId);
            }
        }
        const facingMode = response.settings?.facingMode;
        if (request.facingMode && facingMode) {
            const [facingModes] = extractConstrainString('facingMode')(request);
            return facingModes?.some(fm => fm === facingMode) ?? true;
        }
        return true;
    }
    if (isConstraintSetDevice(request)) {
        return compareDevice(request);
    }
    return !!response;
};

/**
 * Compare request and the input to see if the request has been fulfilled
 */
export const isRequestedInputTrack = (
    request: InputDeviceConstraint | undefined,
    current: MediaStreamTrack | MediaDeviceInfoLike | undefined,
): boolean => {
    if (!request || !current) {
        return Boolean(request) === Boolean(current);
    }

    if (isMediaDeviceInfo(current)) {
        return isRequestedInputDevice(request, current);
    }
    if (isMediaStreamTrack(current)) {
        const device = extractDeviceInfo(current);
        return isRequestedInputDevice(request, device);
    }
    return true;
};

const extractDevice = extractConstraintsWithKeys(['device', 'deviceId']);
export const hasRequestingDevice = (
    request: InputDeviceConstraint | undefined,
    kind: MediaDeviceKind,
    currentDevices: MediaDeviceInfoLike[],
): boolean => {
    if (!request) {
        return false;
    }
    const {
        device: [devices],
        deviceId: [deviceIds],
    } = extractDevice(request);
    // No device specified
    if (
        (!devices || devices.length === 0) &&
        (!deviceIds || deviceIds.length === 0)
    ) {
        return currentDevices.some(device => device.kind === kind);
    }
    // Has device specified
    const find = (device: MediaDeviceInfoLike) =>
        findDevice(device)(currentDevices);
    return (
        devices?.some(device => find(device)) ??
        deviceIds?.some(deviceId =>
            find({kind, deviceId, label: '', groupId: ''}),
        ) ??
        false
    );
};

/**
 * Decide if we should send a new gUM request based on the inputs
 *
 * @param request - Requesting input device constraint
 * @param tracks - The current media stream tracks
 * @param currentDevices - The current list of device of the same kind of the
 * request
 *
 * @returns `true` means the request should be conducted, otherwise `false`.
 */
export const shouldRequestDevice = (
    request: InputDeviceConstraint | undefined,
    tracks: MediaStreamTrack[],
    currentDevices: MediaDeviceInfoLike[],
): boolean => {
    if (!request || currentDevices.length === 0) {
        return false;
    }
    const {kind} = currentDevices[0] ?? {};
    if (!kind || currentDevices.some(device => device.kind !== kind)) {
        throw new Error('Expect a single kind of device');
    }
    const [track] = tracks;
    if (
        tracks.length === 0 ||
        tracks.some(track => track.readyState === 'ended')
    ) {
        return true;
    }
    if (isRequestedInputTrack(request, track)) {
        return false;
    }
    if (hasRequestingDevice(request, kind, currentDevices)) {
        return true;
    }
    return false;
};

export const resolveInputDevice = (
    tracksOrDevice: MediaDeviceInfoLike | MediaStreamTrack[] | undefined,
    findInput: ReturnType<typeof findMediaInputFromMediaStreamTrack>,
) => {
    if (isMediaDeviceInfo(tracksOrDevice)) {
        return tracksOrDevice;
    }
    if (Array.isArray(tracksOrDevice)) {
        const [track] = tracksOrDevice;
        if (track?.readyState === 'live') {
            return findInput(track) ?? track;
        }
    }
    return undefined;
};

export const isStreamingRequestedDevicesBase = (
    request: MediaDeviceRequest,
    tracksOrDevices: {
        audio?: MediaDeviceInfoLike | MediaStreamTrack[];
        video?: MediaDeviceInfoLike | MediaStreamTrack[];
    },
    devices: MediaDeviceInfoLike[],
) => {
    const audioRequest = relaxInputConstraint(request.audio, devices);
    const videoRequest = relaxInputConstraint(request.video, devices);
    const findInput = findMediaInputFromMediaStreamTrack(devices);
    const audioInput = resolveInputDevice(tracksOrDevices.audio, findInput);
    const videoInput = resolveInputDevice(tracksOrDevices.video, findInput);
    const audio = isRequestedInputTrack(audioRequest, audioInput);
    const video = isRequestedInputTrack(videoRequest, videoInput);
    return {audio, video};
};

/**
 * Check if provided request has already been fulfilled
 *
 * @param request - A media request constraints
 * @param stream - Current media stream
 *
 * @returns
 * audio - The stream is using the same device as requested if `true`
 * video - The stream is using the same device as requested if `true`
 */
export const isStreamingRequestedDevices = (
    request: MediaDeviceRequest,
    stream: MediaStream | undefined,
    devices: MediaDeviceInfoLike[],
) =>
    isStreamingRequestedDevicesBase(
        request,
        {
            audio: stream?.getAudioTracks(),
            video: stream?.getVideoTracks(),
        },
        devices,
    );

/**
 * Find in the list of devices which has permissions granted
 *
 * From MDN:
 * For security reasons,the label field is always blank unless an active media stream
 * exists or the user has granted persistent permission for media device access.
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns List of devices that has permission granted
 *
 * @beta
 */
export const findPermissionGrantedDevices = (devices: MediaDeviceInfoLike[]) =>
    devices.filter(d => d.label !== '');

/**
 * Use provided devices to guess the permission state. When there is no active
 * stream and there is no device with label, 'prompt' will be returned
 * otherwise, 'denied'.
 *
 * @param devices - The current devices
 * @param anyActiveStream - Has ever got an active stream to help the fallback to
 * guess the state more accurately
 */
export const toPermissionState = (
    devices: MediaDeviceInfoLike[],
    anyActiveStream = false,
): PermissionState => {
    const granted = devices.some(device => device.label);
    if (anyActiveStream) {
        return granted ? 'granted' : 'denied';
    }
    return granted ? 'granted' : 'prompt';
};

/**
 * A wrapper for `navigator.permissions.query` with fallback to use
 * `navigator.mediaDevices.enumerateDevices` to guess the `PermissionState`
 *
 * @param anyActiveStream - Has ever got an active stream to help the fallback to
 * guess the state more accurately
 */
export const getInputDevicePermissionState = async (
    anyActiveStream = false,
): Promise<InputDevicePermission> => {
    try {
        // @ts-expect-error -- Typings/dom.d.ts change blocks upgrade to typescript 4.4
        const video = await navigator.permissions.query({name: 'camera'});
        // @ts-expect-error -- Typings/dom.d.ts change blocks upgrade to typescript 4.4
        const audio = await navigator.permissions.query({name: 'microphone'});
        return {video: video.state, audio: audio.state};
    } catch {
        const devices = await getDevices();
        return (['videoinput', 'audioinput'] as const).reduce(
            (permission, kind) => {
                const state = toPermissionState(
                    devices.filter(device => device.kind === kind),
                    anyActiveStream,
                );
                permission[kind === 'audioinput' ? 'audio' : 'video'] = state;
                return permission;
            },
            {video: 'prompt', audio: 'prompt'} as InputDevicePermission,
        );
    }
};

/**
 * Find current audio output id to be used to set as sinkId
 *
 * If you set the stale deviceId to setSink it will throw exception.
 * So we want to check if audio output id still exist.
 *
 * @param audioOutput - Audio output as `MediaDeviceInfo`
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns Audio output id or empty string if couldn't find it.
 *
 * @beta
 */
export const findCurrentAudioOutputId = (
    audioOutput?: MediaDeviceInfoLike,
    devices?: MediaDeviceInfoLike[],
) => {
    let selectedAudioOutputDeviceId = '';

    if (audioOutput?.deviceId && devices) {
        const latestSelectedAudioInput = findDevice(audioOutput)(devices);

        if (latestSelectedAudioInput) {
            selectedAudioOutputDeviceId = latestSelectedAudioInput.deviceId;
        }
    }

    return selectedAudioOutputDeviceId;
};

/**
 * Find videoinput device id in the stream
 *
 * @param stream - Media stream to do the lookup
 *
 * @returns A object may contains the devices
 *
 * @beta
 */
export const findCurrentVideoInputDeviceIdFromStream = (
    stream: MediaStream,
) => {
    const [videoTrack] = stream.getVideoTracks();
    return videoTrack && toMediaDeviceInfoLike(videoTrack)?.deviceId;
};

/**
 * Finds device with given deviceId
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 * @param deviceId - id that represents desired device
 *
 * @returns device with given deviceId
 *
 * @beta
 */
export const findDeviceWithDeviceId = (
    devices: MediaDeviceInfoLike[],
    deviceId: string,
) => devices.find(d => d.deviceId === deviceId);

/**
 * Find in the list of devices with given MediaDeviceKind
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 * @param kind - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns List of devices that have required MediaDeviceKind
 *
 * @beta
 */
export const findDevicesByKind = (
    devices: MediaDeviceInfoLike[],
    kind: MediaDeviceKind,
) => devices.filter(d => d.kind === kind);

/**
 * Find in the list of devices only the audio input ones
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns List of devices that are audio inputs
 *
 * @beta
 */
export const findAudioInputDevices = (devices: MediaDeviceInfoLike[]) =>
    findDevicesByKind(devices, MediaDeviceKinds.AUDIOINPUT);

/**
 * Find in the list of devices only the video input ones
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns List of devices that are video inputs
 *
 * @beta
 */
export const findVideoInputDevices = (devices: MediaDeviceInfoLike[]) =>
    findDevicesByKind(devices, MediaDeviceKinds.VIDEOINPUT);

/**
 * Find in the list of devices only the audio output ones
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns List of devices that are audio outputs
 *
 * @beta
 */
export const findAudioOutputDevices = (devices: MediaDeviceInfoLike[]) =>
    findDevicesByKind(devices, MediaDeviceKinds.AUDIOOUTPUT);

/**
 * Set `MediaStreamTrack['enabled']` according to `mute` param for the provided `stream`
 *
 * @param stream - Media stream
 * @param mute - disable or enable the audio stream
 * @param mediaType - Can either be 'audio', 'video' or 'all'
 * @defaultValue
 * 'all'
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/enabled
 *
 * @beta
 */
export const muteStreamTrack =
    (stream?: MediaStream) =>
    (mute: boolean, mediaType: 'audio' | 'video' | 'all' = 'all') => {
        switch (mediaType) {
            case 'audio':
                stream?.getAudioTracks().forEach(track => {
                    track.enabled = !mute;
                });
                break;
            case 'video':
                stream?.getVideoTracks().forEach(track => {
                    track.enabled = !mute;
                });
                break;
            default:
                stream?.getTracks().forEach(track => {
                    track.enabled = !mute;
                });
                break;
        }
    };

/**
 * Stops all tracks in the given stream
 *
 * Immediately after calling stop(), the readyState property is set to `ended`.
 * Note that the `ended` event will not be fired in this situation
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/stop#description
 *
 * @param stream - `MediaStream` which we mutate
 * @param onStopped - callback to be called when the track is stopped
 */
export const stopMediaStream = (
    stream: MediaStream | undefined,
    onStopped?: (track: MediaStreamTrack) => void,
) => {
    if (!stream) {
        return;
    }
    stream.getTracks().forEach(track => {
        track.stop();
        onStopped?.(track);
    });
};

/**
 * Checks that tracks with a given type are enabled in the stream
 *
 * @param stream - `MediaStream` used for comparison
 * @param type - `MediaInput` used for comparison
 *
 * @returns Return true when all the tracks are enabled false otherwise
 *
 * @beta
 */
export const areTracksEnabled = (
    stream: MediaStream | undefined,
    type: 'audio' | 'video',
) => {
    if (!stream) {
        return false;
    }
    const tracks: MediaStreamTrack[] =
        type === 'audio' ? stream.getAudioTracks() : stream.getVideoTracks();
    return tracks.length > 0 && tracks.every(track => track.enabled);
};

/**
 * Check if list contains media inputs
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns True when some media inputs false otherwise
 *
 * @beta
 */

export const hasAudioOrVideoInputs = (devices: MediaDeviceInfoLike[]) =>
    devices.some(d => d.kind !== MediaDeviceKinds.AUDIOOUTPUT);

/**
 * Check if list contains audio inputs
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns True when some audio inputs false otherwise
 *
 * @beta
 */

export const hasAudioInputs = (devices: MediaDeviceInfoLike[]) =>
    devices.some(({kind}) => kind === MediaDeviceKinds.AUDIOINPUT);

/**
 * Check if list contains video inputs
 *
 * @param devices - A list of media devices from
 * `navigator.mediaDevices.enumerateDevices()`. Will be used for search target
 *
 * @returns True when some video inputs false otherwise
 *
 * @beta
 */

export const hasVideoInputs = (devices: MediaDeviceInfoLike[]) =>
    devices.some(({kind}) => kind === MediaDeviceKinds.VIDEOINPUT);

export const hasChangedInput = (
    oldInput: MediaDeviceInfoLike | undefined,
    newInput: MediaDeviceInfoLike | undefined,
) => {
    if (isMediaDeviceInfo(newInput)) {
        if (
            (isMediaDeviceInfo(oldInput) &&
                !compareDevices(oldInput)(newInput)) ||
            !oldInput
        ) {
            return true;
        }
    } else if (oldInput) {
        return true;
    }
    return false;
};
