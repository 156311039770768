import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {
    ButtonProps,
    IconSource,
    TooltipPosition,
    NotificationTooltipPosition,
} from '@pexip/components';
import {
    Box,
    Button,
    Icon,
    IconTypes,
    Tooltip,
    NotificationTooltip,
} from '@pexip/components';
import type {Quality} from '@pexip/peer-connection-stats';

import {TestId} from '../../../test/testIds';
import {CallQualityIndicatorButton} from '../CallQualityIndicator/CallQualityIndicatorButton.view';
import type {AutoHideButtonCallbacks} from '../../types';
import {stopMouseEventPropagation} from '../../utils/domEventsHandles';

import styles from './Selfview.module.scss';

export const SelfviewButton: React.FC<
    ButtonProps & {
        icon: IconSource;
        iconClassName?: string;
        onClick?: () => void;
        tooltipPosition?: TooltipPosition;
        tooltipText?: string;
        tooltipClassName?: string;
        tooltipContainerClassName?: string;
    }
> = ({
    icon,
    iconClassName,
    isDisabled,
    onClick,
    tooltipPosition = 'bottom',
    tooltipText,
    variant = 'transparent',
    tooltipClassName = styles.selfviewBtnTooltip,
    tooltipContainerClassName = styles.selfviewBtnTooltipContainer,
    className,
    ...props
}) => {
    const button = (
        <Button
            onClick={onClick}
            className={cx(
                styles.selfviewButton,
                isDisabled && styles.disabled,
                className,
            )}
            variant={variant}
            modifier="square"
            {...props}
        >
            <Icon
                source={icon}
                className={cx(isDisabled && styles.disabledIcon, iconClassName)}
            />
        </Button>
    );

    if (isDisabled || !tooltipText) {
        return button;
    }

    return (
        <Tooltip
            text={tooltipText}
            position={tooltipPosition}
            onPointerDownCapture={stopMouseEventPropagation(
                onClick !== undefined,
            )}
            className={tooltipClassName}
            tooltipContainerClassName={tooltipContainerClassName}
        >
            {button}
        </Tooltip>
    );
};

export const FoldedSelfview: React.FC<{
    autoHideProps: AutoHideButtonCallbacks;
    isAudioInputMuted: boolean;
    isVideoInputMuted: boolean;
    isAudioInputMissing?: boolean;
    isVideoInputMissing?: boolean;
    onCallQualityClick: () => void;
    onExpandSelfview: () => void;
    onToggleVideoClick: () => void;
    onToggleAudioClick: () => void;
    quality: Quality;
    showSelfviewTooltip: boolean;
    setShowSelfviewTooltip: (shouldShow: boolean) => void;
    notificationTooltipPos?: NotificationTooltipPosition;
    selfviewBtnTooltipPos?: TooltipPosition;
}> = ({
    autoHideProps,
    isAudioInputMuted,
    isVideoInputMuted,
    isAudioInputMissing,
    isVideoInputMissing,
    onToggleVideoClick,
    onCallQualityClick,
    onExpandSelfview,
    onToggleAudioClick,
    quality,
    showSelfviewTooltip,
    setShowSelfviewTooltip,
    notificationTooltipPos = 'bottomLeft',
    selfviewBtnTooltipPos = 'bottom',
}) => {
    const {t} = useTranslation();

    const muteMicText = isAudioInputMuted
        ? t('media.unmute-microphone', 'Unmute microphone')
        : t('media.mute-microphone', 'Mute microphone');
    const connectivityText = t(
        'meeting.connection-quality',
        'Connection quality',
    );
    const showSelfviewText = t('meeting.show-self-view', 'Show self-view');
    const turnOnCameraText = t('media.turn-on-camera', 'Turn on camera');
    const dragText = t('meeting.folded-selfview.drag', 'Move self-view');

    const selfviewBtnTooltipClassName = cx(styles.selfviewBtnTooltip, {
        [styles.leftHalf]:
            notificationTooltipPos === 'topRight' ||
            notificationTooltipPos === 'bottomRight',
    });

    return (
        <NotificationTooltip
            controlledIsVisible={showSelfviewTooltip}
            controlledOnChange={setShowSelfviewTooltip}
            header={t('settings.selfview-hidden', 'Self-view hidden')}
            position={notificationTooltipPos}
            testIdInner={TestId.NotificationTooltipSelfviewHidden}
            text={t(
                'settings.collapsed-selfview-tooltip-description',
                'You’re still sending video to other people in the meeting',
            )}
            closeButtonAriaLabel={t('common.close-tooltip', 'Close tooltip')}
            stopPointerDownPropagation
            className={styles.notificationTooltipInner}
        >
            <Box
                className={styles.foldedSelfview}
                data-testid={TestId.FoldedSelfview}
            >
                <SelfviewButton
                    data-testid={TestId.ButtonDragFoldedSelfview}
                    icon={IconTypes.IconDraggable}
                    aria-label={dragText}
                    className={styles.inheritCursor}
                />
                <SelfviewButton
                    onClick={onToggleAudioClick}
                    data-testid={TestId.ButtonToggleAudioSelfview}
                    tooltipText={muteMicText}
                    tooltipPosition={selfviewBtnTooltipPos}
                    aria-label={muteMicText}
                    isDisabled={isAudioInputMissing}
                    icon={
                        isAudioInputMuted || isAudioInputMissing
                            ? IconTypes.IconMicrophoneOff
                            : IconTypes.IconMicrophoneOn
                    }
                    tooltipClassName={selfviewBtnTooltipClassName}
                    {...autoHideProps}
                />
                <CallQualityIndicatorButton
                    quality={quality}
                    onClick={onCallQualityClick}
                    tooltipText={connectivityText}
                    tooltipPosition={selfviewBtnTooltipPos}
                    onPointerDownCapture={stopMouseEventPropagation()}
                    data-testid={TestId.ButtonSelfviewQuality}
                    {...autoHideProps}
                />

                {isVideoInputMuted || isVideoInputMissing ? (
                    <SelfviewButton
                        onClick={onToggleVideoClick}
                        data-testid={TestId.ButtonToggleVideoSelfview}
                        tooltipText={turnOnCameraText}
                        tooltipPosition={selfviewBtnTooltipPos}
                        aria-label={turnOnCameraText}
                        icon={IconTypes.IconVideoOff}
                        isDisabled={isVideoInputMissing}
                        tooltipClassName={selfviewBtnTooltipClassName}
                        {...autoHideProps}
                    />
                ) : (
                    <SelfviewButton
                        onClick={onExpandSelfview}
                        data-testid={TestId.ButtonExpandSelfview}
                        tooltipText={showSelfviewText}
                        tooltipPosition={selfviewBtnTooltipPos}
                        aria-label={showSelfviewText}
                        icon={IconTypes.IconFullscreenOn}
                        tooltipClassName={selfviewBtnTooltipClassName}
                        {...autoHideProps}
                    />
                )}
            </Box>
        </NotificationTooltip>
    );
};

export type FoldedSelfviewProps = React.ComponentProps<typeof FoldedSelfview>;
