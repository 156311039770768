import React from 'react';
import {useTranslation} from 'react-i18next';

import {JoinMeetingButton} from '@pexip/media-components';
import {isGranted, isRejected} from '@pexip/media';

import {FlowType} from '../types';
import {TestId} from '../../test/testIds';
import {useStreamStatus} from '../services/Media.service';

export const JoinMeeting: React.FC<{
    call: () => void;
    flowType: FlowType;
}> = ({call, flowType, ...props}) => {
    const {t} = useTranslation();
    const streamStatus = useStreamStatus();

    return (
        <JoinMeetingButton
            isDisabled={!isGranted(streamStatus) && !isRejected(streamStatus)}
            onClick={call}
            text={t('common.join', 'Join now')}
            data-testid={TestId.ButtonJoinMeeting}
            size="large"
            spacing={flowType === FlowType.StepByStep ? 'large' : 'medium'}
            variant="success"
            {...props}
        />
    );
};
