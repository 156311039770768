import {v4 as uuid} from 'uuid';

import type {
    ChatActivityType,
    ChatActivityMessageParticipants,
    ChatMessage,
} from '../types';

export const appendGroupedParticipants = (
    previousMessage: ChatMessage,
    newMessage: ChatMessage,
): ChatActivityMessageParticipants[] | undefined => {
    if (previousMessage.groupedParticipants?.length) {
        return [
            ...previousMessage.groupedParticipants,
            {
                identity: newMessage.identity,
                displayName: newMessage.displayName,
                timestamp: newMessage.timestamp,
            },
        ];
    }

    return [
        {
            identity: previousMessage.identity,
            displayName: previousMessage.displayName,
            timestamp: previousMessage.timestamp,
        },
        {
            identity: newMessage.identity,
            displayName: newMessage.displayName,
            timestamp: newMessage.timestamp,
        },
    ];
};

export const getGroupedMessageDisplayName = (
    previousMessage: ChatMessage,
    newMessage: ChatMessage,
) => {
    if (previousMessage?.groupedParticipants) {
        if (previousMessage.groupedParticipants?.length === 2) {
            return `${newMessage.displayName}, ${previousMessage.groupedParticipants[1]?.displayName} and ${previousMessage.groupedParticipants[0]?.displayName}`;
        }

        if (previousMessage.groupedParticipants?.length > 2) {
            return `${newMessage.displayName}, ${
                previousMessage.groupedParticipants[
                    previousMessage.groupedParticipants.length - 1
                ]?.displayName
            }`;
        }
    }

    return `${newMessage.displayName} and ${previousMessage.displayName}`;
};

export const getGroupedMessageText = (
    chatActivityType: ChatActivityType,
    groupedParticipants?: (ChatActivityMessageParticipants | undefined)[],
) => {
    if (groupedParticipants && groupedParticipants?.length > 2) {
        return ` and ${
            groupedParticipants?.length - 1
        } others ${chatActivityType} the meeting`;
    }

    return `${chatActivityType} the meeting`;
};

export const buildGroupedActivityMessage = (
    activityType: ChatActivityType,
    previousMessage: ChatMessage,
    newMessage: ChatMessage,
): ChatMessage => ({
    ...newMessage,
    activityType,
    displayName: getGroupedMessageDisplayName(previousMessage, newMessage),
    message: getGroupedMessageText(
        activityType,
        previousMessage.groupedParticipants,
    ),
    groupedParticipants: appendGroupedParticipants(previousMessage, newMessage),
});

export const buildRemoveActivityMessage = ({
    meetingId,
    myIdentity,
    member,
    getDisplayName,
}: {
    meetingId: string;
    myIdentity: string;
    member: {identity: string; actor: string}; // FIXME: make it more generic
    getDisplayName: (identity: string) => string;
}): ChatMessage => ({
    id: uuid(),
    topic: meetingId,
    displayName: getDisplayName(member.actor || ''),
    message: ' removed ',
    type: 'activity',
    activityType: 'removed',
    identity: uuid(),
    timestamp: new Date().toLocaleString(),
    removedParticipant: {
        identity: member.identity,
        displayName:
            myIdentity === member.identity
                ? 'You'
                : getDisplayName(member.identity),
    },
});
