import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {Button, TextHeading} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './GetStarted.module.scss';

export const GetStarted: React.FC<
    React.ComponentProps<'div'> & {start: () => void}
> = ({start, className}) => {
    const {t} = useTranslation();
    return (
        <div className={cx('text-center', className, styles.wrapper)}>
            <TextHeading htmlTag="h2">
                {t(
                    'media.lets-check',
                    'Let’s check that your video and sound are working properly',
                )}
            </TextHeading>
            <Button
                className={cx('mt-8', styles.button)}
                size="large"
                onClick={start}
                modifier="fullWidth"
                data-testid={TestId.ButtonGetStarted}
            >
                {t('media.lets-go', 'Let’s go')}
            </Button>
        </div>
    );
};
