import React from 'react';
import cx from 'classnames';

import {
    FontVariant,
    Text,
    useMobileDevice,
    useTabletDevice,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './LiveCaptions.module.scss';

export const LiveCaptions: React.FC<{
    captions?: string;
    className?: string;
}> = ({captions, className}) => (
    <div
        className={cx(styles.liveCaptionsWrapper, className)}
        data-testid={TestId.LiveCaptions}
    >
        {captions && (
            <LiveCaptionsText
                captions={captions}
                className={styles.showTwoLines}
            />
        )}
    </div>
);

const LiveCaptionsText: React.FC<{
    captions?: string;
    className?: string;
}> = ({captions, className}) => {
    const isTabletDevice = useTabletDevice();
    const isMobileDevice = useMobileDevice();
    const fontVariant = isMobileDevice
        ? FontVariant.SmallBold
        : isTabletDevice
        ? FontVariant.BodyBold
        : FontVariant.H4;
    return captions ? (
        <div className={cx(styles.textWrapper, className)}>
            <Text
                fontVariant={fontVariant}
                variant="inherit"
                className={styles.liveCaptionsText}
            >
                {captions}
            </Text>
        </div>
    ) : null;
};

export type LiveCaptionsProps = React.ComponentProps<typeof LiveCaptions>;
