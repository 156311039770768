import React, {useCallback, useEffect, useRef} from 'react';
import {v4 as uuid} from 'uuid';

import {withTransition} from '@pexip/components';
import type {InMeetingUI} from '@pexip/media-components';
import {
    toTime,
    PanelAnimationTypes,
    useChat,
    ChatPanelView,
    ChatPanelContentWrapper,
    withScrollToLastMessage,
} from '@pexip/media-components';
import type {Participant} from '@pexip/infinity';

import {infinityService} from '../services/InfinityClient.service';
import {useChatMessages} from '../hooks/useChatMessages';
import {useActivityChatMessages} from '../hooks/useActivityChatMessages';
import {infinityClientSignals} from '../signals/InfinityClient.signals';

const AnimatedChatPanel = withTransition(ChatPanelContentWrapper, 300);
export const ChatPanelWithScrollToLastMessage =
    withScrollToLastMessage(ChatPanelView);

export const Chat: React.FC<{
    me: Participant;
    inMeetingUI: InMeetingUI;
}> = ({me, inMeetingUI: {panelsState}}) => {
    const ignore = useRef(false);

    const [messages, setMessages] = useChatMessages();
    useActivityChatMessages(setMessages);

    useEffect(
        () => () => {
            ignore.current = true;
        },
        [],
    );

    const sendMessage = useCallback(
        async (message: string) => {
            const chatMessage = {
                displayName: me.displayName,
                id: uuid(),
                message,
                timestamp: toTime(new Date()),
                type: 'user-message',
                userId: me.uuid,
            } as const;

            setMessages(messages => [
                ...messages,
                {...chatMessage, pending: true},
            ]);

            const result = await infinityService.sendMessage({
                payload: message,
            });

            if (ignore.current) {
                return;
            }

            const setMsgSuccess = () =>
                setMessages(messages => [
                    ...messages.filter(({id}) => chatMessage.id !== id),
                    chatMessage,
                ]);

            if (result) {
                setMsgSuccess();
            } else {
                infinityClientSignals.onRetryQueueFlushed.addOnce(() => {
                    if (!ignore.current) {
                        setMsgSuccess();
                    }
                });
            }
        },
        [me, setMessages],
    );

    const chat = useChat({
        messages,
        currentUserId: me?.uuid ?? '',
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Should probably decide on unified types
        sendMessage,
    });

    return (
        <AnimatedChatPanel
            isVisible={panelsState.openChatPanel}
            isShown={panelsState.openChatPanel}
            animateIn={
                panelsState.animationType === PanelAnimationTypes.CHAT_IN
            }
            animateOut={
                panelsState.animationType === PanelAnimationTypes.CHAT_OUT
            }
        >
            <ChatPanelWithScrollToLastMessage
                shouldScrollToLstMessage={panelsState.openChatPanel}
                messages={messages}
                {...chat}
            />
        </AnimatedChatPanel>
    );
};
