import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import type {UserMediaStatus} from '@pexip/media';
import type {MultistepItem} from '@pexip/components';
import {
    useMultistepIndicator,
    useXlDown,
    useMdDown,
    useTouchDevice,
} from '@pexip/components';
import {noop} from '@pexip/utils';
import {useDeviceErrorMessageState} from '@pexip/media-components';

import {PreflightJoinSteps} from '../enums';
import {useDevices, useLocalMedia} from '../services/Media.service';
import {push} from '../router';
import {OneTimeDeviceSelectionView} from '../views/OneTimeDeviceSelection/OneTimeDeviceSelection.view';
import {useScrollElementToBottom} from '../hooks/useScrollElementToBottom';
import {config} from '../config';

import {DeviceSelectionSteps} from './DeviceSelectionSteps.viewModel';
import {AccessibilityDeviceDetection} from './AccessibilityDeviceDetection.viewModel';

const initialSteps: MultistepItem[] = [
    {label: 'Camera', isActive: true},
    {label: 'Speaker'},
    {label: 'Microphone'},
];

/*
  t('common.[camera]', 'Camera')
  t('common.[speaker]', 'Speaker')
  t('common.[Microphone]', 'Microphone')
*/

/**
 * Responsible for making sure that in the one-time flow the camera is not muted.
 *
 * Remarks: The camera always goes on once the `OneTimeDeviceSelection`is mounted (one-time flow starts) .
 * Notice that the config update doesn't change the user preferred selection in the local storage.
 */
const useMakeSureVideoUnmuted = () => {
    useEffect(() => {
        config.set({key: 'isVideoInputMuted', value: false});
    }, []);
};

export const OneTimeDeviceSelection: React.FC<{
    streamStatus: UserMediaStatus;
    conferenceAlias: string;
}> = ({streamStatus, conferenceAlias}) => {
    const {t} = useTranslation();
    const [
        {steps, currentStepIndex},
        {updateStepActive, moveToStep, updateStepCompleted},
    ] = useMultistepIndicator({
        initialSteps,
    });
    const [started, setStarted] = useState(false);
    const [showProceedAnywayModal, setShowProceedAnywayModal] = useState(false);
    const [showPreflightTips, setShowPreflightTips] = useState(false);
    const [showAccessibilityTips, setShowAccessibilityTips] = useState(false);

    const isTouchDevice = useTouchDevice();
    const isXlDown = useXlDown();
    const isMdDown = useMdDown();
    const displayInnerStepper = (isTouchDevice && isXlDown) || isMdDown;

    const media = useLocalMedia();
    const devices = useDevices();
    const start = useCallback(() => setStarted(true), []);

    const [showTipsScrollTrigger, setShowTipsScrollTrigger] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const scrollToBottomSectionRef = useScrollElementToBottom(
        ref,
        showTipsScrollTrigger,
    );

    const hideAllTips = useCallback(() => {
        setShowPreflightTips(false);
        setShowAccessibilityTips(false);
        setShowTipsScrollTrigger(false);
    }, []);

    const showPreflightTipsFn = useCallback(() => {
        setShowPreflightTips(true);
        setShowTipsScrollTrigger(true);
    }, []);

    const hidePreflightTipsFn = useCallback(() => {
        setShowPreflightTips(false);
        setShowTipsScrollTrigger(false);
    }, []);

    const toggleShowAccessibilityTips = useCallback(() => {
        setShowAccessibilityTips(prevVal => !prevVal);
        if (!showPreflightTips) {
            // only scroll to the bottom if the preflight tips are not shown else the user will see the preflight tips instead of the accessibility tips
            setShowTipsScrollTrigger(prevVal => !prevVal);
            setTimeout(() => {
                // flip the scroll trigger value back to false after the timeout so it still scrolls preflight tips into view... bit hacky?
                setShowTipsScrollTrigger(prevVal => !prevVal);
            }, 100);
        }
    }, [showPreflightTips]);

    const next = useCallback(
        (next: PreflightJoinSteps) => () => {
            hideAllTips();
            moveToStep(next, {
                forceStep: next === PreflightJoinSteps.ReadyToJoin,
            });
            updateStepActive(next);
            updateStepCompleted(next - 1, {activateStep: true});
        },
        [moveToStep, updateStepActive, updateStepCompleted, hideAllTips],
    );

    const call = useCallback(() => {
        push(`/m/${conferenceAlias}`);
    }, [conferenceAlias]);

    const hideHelpAndGoToNextStep = () => {
        const nextStepIndex = currentStepIndex + 1;
        next(nextStepIndex)();
        setShowProceedAnywayModal(false);
        hideAllTips();
    };

    const onMoveToStep = useCallback(
        (stepIndex: number) => {
            showPreflightTips ? hideAllTips() : null;
            moveToStep(stepIndex);
        },
        [moveToStep, showPreflightTips, hideAllTips],
    );

    const calculateNext = () => {
        if (currentStepIndex === PreflightJoinSteps.Camera) {
            return next(PreflightJoinSteps.Speaker);
        }

        if (currentStepIndex === PreflightJoinSteps.Speaker) {
            return next(PreflightJoinSteps.Microphone);
        }

        if (currentStepIndex === PreflightJoinSteps.Microphone) {
            return next(PreflightJoinSteps.ReadyToJoin);
        }

        return noop;
    };

    useMakeSureVideoUnmuted();

    const currentTitleTranslation = `common.[${PreflightJoinSteps[currentStepIndex]}]`;

    const proceedAnywayModalContentOptions: Partial<Record<number, string>> =
        useMemo(
            () => ({
                0: t(
                    'preflight.camera-missing',
                    'Others won’t be able to see you',
                ),
                1: t(
                    'preflight.speaker-missing',
                    'You won’t be able to hear others',
                ),
                2: t(
                    'preflight.microphone-missing',
                    'Others won’t be able to hear you',
                ),
            }),
            [t],
        );
    const {audioInputError} = useDeviceErrorMessageState();

    const accessibilityTips = (
        <AccessibilityDeviceDetection
            currentStepIndex={currentStepIndex}
            next={calculateNext}
            devices={devices}
            deviceError={audioInputError}
        />
    );

    return (
        <OneTimeDeviceSelectionView
            accessibilityTips={accessibilityTips}
            currentStepIndex={currentStepIndex}
            calculateNext={calculateNext}
            deviceSelectionSteps={
                <DeviceSelectionSteps
                    call={call}
                    currentStepIndex={currentStepIndex}
                    devices={devices}
                    media={media}
                    next={next}
                    streamStatus={streamStatus}
                    no={showPreflightTipsFn}
                    help={toggleShowAccessibilityTips}
                    accessibilityHelpExpanded={showAccessibilityTips}
                />
            }
            hideHelpAndGoToNextStep={hideHelpAndGoToNextStep}
            moveToStep={onMoveToStep}
            next={next}
            start={start}
            started={started}
            steps={steps}
            streamStatus={streamStatus}
            displayInnerStepper={displayInnerStepper}
            isDisplayInnerStepperDesktop={displayInnerStepper && !isTouchDevice}
            titleTranslation={t(
                currentTitleTranslation,
                PreflightJoinSteps[currentStepIndex],
            )}
            showProceedAnywayModal={showProceedAnywayModal}
            setShowProceedAnywayModal={setShowProceedAnywayModal}
            proceedAnywayModalContent={
                proceedAnywayModalContentOptions[currentStepIndex] ??
                'No content'
            }
            scrollToBottomSectionRef={scrollToBottomSectionRef}
            setShowPermissionsTipsScrollTrigger={setShowTipsScrollTrigger}
            tryAgain={hidePreflightTipsFn}
            closeAccessibilityTips={toggleShowAccessibilityTips}
            setShowPreflightTips={hideAllTips}
            showPreflightTips={showPreflightTips}
            showAccessibilityTips={showAccessibilityTips}
        />
    );
};
