/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface FeccParticipantMap {
    '200': Status200;
    '403': Status403;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    /**
     * The result is true if successful, false otherwise.
     */
    result: boolean;
}
export interface Status403 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result: 'Invalid token';
}
export interface TopLevel {
    /**
     * Either `start`, `stop`, or `continue`.
     */
    action: 'start' | 'stop' | 'continue';
    /**
     * UUID of the target participant (from the participant list). Leave undefined for a gateway call.
     */
    target: string;
    movement: Endpoint[];
}
export interface Endpoint {
    /**
     * Either `pan`, `tilt`, or `zoom`.
     */
    axis: 'pan' | 'tilt' | 'zoom';
    /**
     * Use `left`, `right` for pan; `up`, `down` for tilt; or `in`, `out` for zoom.
     */
    direction: 'left' | 'right' | 'up' | 'down' | 'in' | 'out';
    /**
     * The duration for which to send the signal. Recommended values are 1000 (1 second) for initial `start` message; 200 for `continue` messages.
     */
    timeout?: number;
}

import {
    validateBody,
    validate200,
    validate403,
} from './feccParticipantValidation';
type FeccParticipantResponse =
    | {status: 200; data: FeccParticipantMap['200']}
    | {status: 403; data: FeccParticipantMap['403']};
interface FeccParticipantParams {
    conferenceAlias: string;
    participantUuid: string;
}
export async function feccParticipant({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: FeccParticipantParams;
    body: FeccParticipantMap['Body'];
    host: string;
}): Promise<FeccParticipantResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/participants/${params.participantUuid}/fecc`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate403(data)) {
            throw new Error(JSON.stringify(validate403.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
