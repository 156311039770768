import React, {useCallback, useEffect, useState} from 'react';

import {useLogTracks, useMedia} from '@pexip/media-components';
import type {
    ExtendedInfinityErrorCode,
    ExtendedInfinityErrorMessage,
} from '@pexip/infinity';
import type {RouteMatch} from '@pexip/router';
import {createPathMatch} from '@pexip/router';

import {createSubRoutesHook, push} from '../router';
import {PostMeeting} from '../pages/PostMeeting.page';
import {Preflight} from '../pages/Preflight.page';
import {OneTime} from '../pages/OneTime.page';
import {Meeting} from '../pages/Meeting.page';
import {Home} from '../pages/Home.page';
import {mediaService} from '../services/Media.service';
import {mediaSignals} from '../signals/Media.signals';
import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {logger} from '../logger';
import {EXPRESS_PATH, STEP_BY_STEP_PATH} from '../constants';

import {MeetingError} from './MeetingError.page';

export const useMeetingFlowWithMediaRoutes = createSubRoutesHook(
    [
        {
            path: `/:id${EXPRESS_PATH}`,
            node: Preflight,
        },
        {
            path: `/:id${STEP_BY_STEP_PATH}`,
            node: OneTime,
        },
        {
            exact: true,
            path: '/:id',
            node: Meeting,
        },
        {
            exact: true,
            path: '/',
            node: Home,
        },
    ],
    '/m',
);

export const MeetingFlowWithMedia: React.FC = () => {
    useMedia(mediaService, mediaSignals.onDevicesChanged.add);
    useLogTracks(mediaSignals);

    return <>{useMeetingFlowWithMediaRoutes()}</>;
};

const useMeetingFlowRoutes = createSubRoutesHook(
    [
        {
            exact: true,
            path: '/:id/post-meeting',
            node: PostMeeting,
        },
        {
            path: '',
            fallback: true,
            node: MeetingFlowWithMedia,
        },
    ],
    '/m',
);

const MeetingFlowRoutes = () => <>{useMeetingFlowRoutes()}</>;

const getConferenceAlias = (match: RouteMatch) => {
    const matchPath = createPathMatch(match.url.path.slice(match.path.length));
    const {
        params: {id: conferenceAlias},
    } = matchPath('/:id');

    return conferenceAlias;
};

export const MeetingFlow: React.FC<{
    match: RouteMatch;
}> = ({match}) => {
    const conferenceAlias = getConferenceAlias(match);

    const [error, setError] = useState<ExtendedInfinityErrorMessage>();
    const [errorCode, setErrorCode] = useState<ExtendedInfinityErrorCode>();

    const chooseAnotherMeeting = useCallback(() => {
        push(`/`);
    }, []);

    const rejoin = useCallback(() => {
        setError(undefined);
        setErrorCode(undefined);
        if (conferenceAlias) {
            push(`/m/${conferenceAlias}${EXPRESS_PATH}`);
        } else {
            push(`/`);
        }
    }, [conferenceAlias]);

    useEffect(
        () =>
            infinityClientSignals.onError.add(({error, errorCode}) => {
                if (error !== 'Invalid PIN') {
                    setError(error);
                    setErrorCode(errorCode);
                }
            }),
        [],
    );
    useEffect(
        () =>
            infinityClientSignals.onDisconnected.add(({error, errorCode}) => {
                logger.warn({error}, 'Call disconnected with');
                if (error) {
                    setError(error);
                    setErrorCode(errorCode);
                } else {
                    push(`/m/${conferenceAlias}/post-meeting`);
                }
            }),
        [conferenceAlias],
    );

    if (error) {
        return (
            <MeetingError
                alias={conferenceAlias}
                chooseAnotherMeeting={chooseAnotherMeeting}
                error={error}
                errorCode={errorCode}
                rejoin={rejoin}
            />
        );
    }

    return <MeetingFlowRoutes />;
};
