import {useEffect, useState} from 'react';

import {infinityClientSignals} from '../signals/InfinityClient.signals';

export const useSpeaking = (userId: string) => {
    const [isSpeaking, setSpeaking] = useState(false);

    useEffect(
        () =>
            infinityClientSignals.onStage.add(participantPips => {
                const user = participantPips.find(
                    participantPip => participantPip.userId === userId,
                );
                if (user) {
                    setSpeaking(user.vad > 0);
                }
            }),
        [userId],
    );

    return isSpeaking;
};
