import {
    MediaDeviceFailure,
    applyConstraints,
    findMediaInputFromMediaStreamTrack,
    getDevices,
    getUserMedia,
    hasAudioInputs,
    hasVideoInputs,
    isExactDeviceConstraint,
    isRequestedResolution,
    isStreamingRequestedDevices,
    muteStreamTrack,
    stopMediaStream,
} from '@pexip/media-control';
import type {
    MediaDeviceRequest,
    MediaDeviceInfoLike,
} from '@pexip/media-control';

import type {Process, Unsubscribe, Media} from './types';
import {UserMediaStatus} from './types';
import {
    shallowCopy,
    makeDeriveDeviceStatus,
    createMemorizedGetExpectedInput,
    isAudioInput,
    isVideoInput,
    isMuted,
    wrapToJSON,
} from './utils';
import {logger} from './logger';
import {isUnknownError} from './status';

export type UserMedia = [MediaStream | undefined, UserMediaStatus];

export type GetUserMedia<
    T extends Promise<Iterable<unknown>> = Promise<UserMedia>,
> = (constraints: MediaDeviceRequest) => T;

export const toErrorDeviceStatus = (
    error: Error,
    deriveDeviceStatus: ReturnType<typeof makeDeriveDeviceStatus>,
) => {
    switch (error.message) {
        case MediaDeviceFailure.AudioInputDeviceNotFoundError:
            return UserMediaStatus.AudioDeviceNotFound;
        case MediaDeviceFailure.VideoInputDeviceNotFoundError:
            return UserMediaStatus.VideoDeviceNotFound;
        case MediaDeviceFailure.AudioAndVideoDeviceNotFoundError:
            return UserMediaStatus.AudioVideoDevicesNotFound;
        case MediaDeviceFailure.NotAllowedError:
        case MediaDeviceFailure.NotFoundError:
        case MediaDeviceFailure.SecurityError:
        case MediaDeviceFailure.PermissionDeniedError:
            return deriveDeviceStatus(
                UserMediaStatus.PermissionsRejectedAudioInput,
                UserMediaStatus.PermissionsRejectedVideoInput,
                UserMediaStatus.PermissionsRejected,
            );
        case MediaDeviceFailure.NotReadableError:
        case MediaDeviceFailure.TrackStartError:
        case MediaDeviceFailure.AbortError:
            return deriveDeviceStatus(
                UserMediaStatus.AudioDeviceInUse,
                UserMediaStatus.VideoDeviceInUse,
                UserMediaStatus.DevicesInUse,
            );
        case MediaDeviceFailure.MissingConstraintsError:
            return deriveDeviceStatus(
                UserMediaStatus.NoAudioDevicesFound,
                UserMediaStatus.NoVideoDevicesFound,
                UserMediaStatus.NoDevicesFound,
            );
        case MediaDeviceFailure.OverconstrainedError:
            return deriveDeviceStatus(
                UserMediaStatus.AudioOverconstrained,
                UserMediaStatus.VideoOverconstrained,
                UserMediaStatus.Overconstrained,
            );
        case MediaDeviceFailure.TypeError:
            return deriveDeviceStatus(
                UserMediaStatus.InvalidAudioConstraints,
                UserMediaStatus.InvalidVideoConstraints,
                UserMediaStatus.InvalidConstraints,
            );
        case MediaDeviceFailure.NotSupportedError:
            return deriveDeviceStatus(
                UserMediaStatus.NotSupportedErrorOnlyAudioInput,
                UserMediaStatus.NotSupportedErrorOnlyVideoInput,
                UserMediaStatus.NotSupportedError,
            );
        default:
            return deriveDeviceStatus(
                UserMediaStatus.UnknownErrorOnlyAudioinput,
                UserMediaStatus.UnknownErrorOnlyVideoinput,
                UserMediaStatus.UnknownError,
            );
    }
};

export const mapErrorStatus =
    (status: UserMediaStatus) => (constraints: MediaDeviceRequest) => {
        const toStatus = makeDeriveDeviceStatus(constraints);
        switch (status) {
            case UserMediaStatus.AudioVideoDevicesNotFound:
                return toStatus(
                    UserMediaStatus.AudioDeviceNotFound,
                    UserMediaStatus.VideoDeviceNotFound,
                    status,
                );
            case UserMediaStatus.DevicesInUse:
                return toStatus(
                    UserMediaStatus.AudioDeviceInUse,
                    UserMediaStatus.VideoDeviceInUse,
                    status,
                );
            case UserMediaStatus.InvalidConstraints:
                return toStatus(
                    UserMediaStatus.InvalidAudioConstraints,
                    UserMediaStatus.InvalidVideoConstraints,
                    status,
                );
            case UserMediaStatus.NoDevicesFound:
                return toStatus(
                    UserMediaStatus.NoAudioDevicesFound,
                    UserMediaStatus.NoVideoDevicesFound,
                    status,
                );
            case UserMediaStatus.NotSupportedError:
                return toStatus(
                    UserMediaStatus.NotSupportedErrorOnlyAudioInput,
                    UserMediaStatus.NotSupportedErrorOnlyVideoInput,
                    status,
                );
            case UserMediaStatus.Overconstrained:
                return toStatus(
                    UserMediaStatus.AudioOverconstrained,
                    UserMediaStatus.VideoOverconstrained,
                    status,
                );
            case UserMediaStatus.PermissionsRejected:
                return toStatus(
                    UserMediaStatus.PermissionsRejectedAudioInput,
                    UserMediaStatus.PermissionsRejectedVideoInput,
                    status,
                );
            case UserMediaStatus.UnknownError:
                return toStatus(
                    UserMediaStatus.UnknownErrorOnlyAudioinput,
                    UserMediaStatus.UnknownErrorOnlyVideoinput,
                    status,
                );
            default:
                return status;
        }
    };

export const toSameDeviceStatus = ({
    audio,
    video,
}: {
    audio: boolean;
    video: boolean;
}) => {
    if (audio) {
        if (video) {
            return UserMediaStatus.PermissionsGranted;
        }
        return UserMediaStatus.PermissionsGrantedFallbackVideoinput;
    }
    if (video) {
        return UserMediaStatus.PermissionsGrantedFallbackAudioinput;
    }
    return UserMediaStatus.PermissionsGrantedFallback;
};

export const toOnlyDeviceStatus = (
    kind: Extract<MediaDeviceKind, 'audioinput' | 'videoinput'>,
    matched: boolean,
    devices: MediaDeviceInfoLike[],
) => {
    const hasRelatedDevices = devices.some(d => {
        if (kind === 'audioinput') {
            return d.kind === 'videoinput';
        }
        return d.kind === 'audioinput';
    });
    if (matched) {
        if (hasRelatedDevices) {
            return kind === 'audioinput'
                ? UserMediaStatus.PermissionsOnlyAudioinput
                : UserMediaStatus.PermissionsOnlyVideoinput;
        }
        return kind === 'audioinput'
            ? UserMediaStatus.PermissionsOnlyAudioinputNoVideoDevices
            : UserMediaStatus.PermissionsOnlyVideoinputNoAudioDevices;
    }
    if (hasRelatedDevices) {
        return kind === 'audioinput'
            ? UserMediaStatus.PermissionsOnlyAudioinputFallback
            : UserMediaStatus.PermissionsOnlyVideoinputFallback;
    }
    return kind === 'audioinput'
        ? UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices
        : UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices;
};

/**
 * Try to come up with an error level according to provided UserMediaStatus and
 * MediaDeviceRequest
 *
 * @param error - The error thrown from media request
 * @param status - The status in result
 * @param constraints - The constraints used for the request
 */
export const deriveErrorLevel = (
    error: Error,
    status: UserMediaStatus,
    constraints: MediaDeviceRequest,
) => {
    // Only log to error level when we should pay attention, e.g.
    // UnknownError
    const errorMsg = error.message;
    return [
        (errorMessage: string) => MediaDeviceFailure.TypeError === errorMessage,
        (errorMessage: string) =>
            MediaDeviceFailure.NotSupportedError === errorMessage,
        (errorMessage: string) =>
            MediaDeviceFailure.OverconstrainedError === errorMessage &&
            ![constraints.audio, constraints.video].some(constraint =>
                isExactDeviceConstraint(constraint),
            ),
        () => isUnknownError(status),
    ].some(shouldUseError => shouldUseError(errorMsg))
        ? 'error'
        : 'warn';
};

export const requestUserMedia =
    (
        currentDevices?: MediaDeviceInfoLike[],
        getMedia = getUserMedia,
    ): GetUserMedia =>
    async constraints => {
        const deriveDeviceStatus = makeDeriveDeviceStatus(constraints);
        const devices = currentDevices ?? (await getDevices());
        try {
            const stream = await getMedia(constraints);
            const grantedDevices = devices.some(device => device.label)
                ? devices
                : await getDevices();
            const {audio, video} = isStreamingRequestedDevices(
                constraints,
                stream,
                grantedDevices,
            );
            const onlyAudioStatus = toOnlyDeviceStatus(
                'audioinput',
                audio,
                grantedDevices,
            );
            const onlyVideoStatus = toOnlyDeviceStatus(
                'videoinput',
                video,
                grantedDevices,
            );
            const status = deriveDeviceStatus(
                onlyAudioStatus,
                onlyVideoStatus,
                toSameDeviceStatus({audio, video}),
            );

            return [stream, status];
        } catch (error: unknown) {
            if (error instanceof Error) {
                const status = toErrorDeviceStatus(error, deriveDeviceStatus);
                return [undefined, status];
            }
            throw error;
        }
    };

export const mergeNoDeviceStatus = (
    constraints: MediaDeviceRequest,
    anyDevices: {audio: boolean; video: boolean},
    status: UserMediaStatus,
): UserMediaStatus => {
    if (
        status !== UserMediaStatus.NoDevicesFound ||
        (!anyDevices.audio &&
            !anyDevices.video &&
            constraints.audio &&
            constraints.video)
    ) {
        return status;
    }
    if (!anyDevices.audio && constraints.audio) {
        return UserMediaStatus.NoAudioDevicesFound;
    }
    if (!anyDevices.video && constraints.video) {
        return UserMediaStatus.NoVideoDevicesFound;
    }
    return status;
};

export const requestUserMediaWithRetry =
    (
        currentDevices?: MediaDeviceInfoLike[],
        createRequestUserMedia = requestUserMedia,
    ): GetUserMedia =>
    async constraints => {
        const devices = currentDevices ?? (await getDevices());
        const anyAudioDevices = hasAudioInputs(devices);
        const anyVideoDevices = hasVideoInputs(devices);
        const request = createRequestUserMedia(devices);
        if (anyAudioDevices && anyVideoDevices) {
            const [stream, status] = await request(constraints);
            if (stream) {
                return [stream, status];
            }
            const mapStatus = mapErrorStatus(status);
            if (constraints.video) {
                const [audioStream] = await request({
                    ...constraints,
                    video: false,
                });
                if (audioStream) {
                    // When success, we know it is video related error
                    const videoStatus = mapStatus({video: true});
                    return [audioStream, videoStatus];
                }
            }
            if (constraints.audio) {
                const [videoStream] = await request({
                    ...constraints,
                    audio: false,
                });
                if (videoStream) {
                    // When success, we know it is audio related error
                    return [videoStream, mapStatus({audio: true})];
                }
            }
            return [stream, status];
        } else {
            const [stream, status] = await request({
                audio: anyAudioDevices && constraints.audio,
                video: anyVideoDevices && constraints.video,
            });
            return [
                stream,
                mergeNoDeviceStatus(
                    constraints,
                    {audio: anyAudioDevices, video: anyVideoDevices},
                    status,
                ),
            ];
        }
    };
interface Options {
    initialMedia: Media;
    scope?: string;
}
type CreateGetUserMediaProcess = (
    getUserMedia: GetUserMedia,
    getCurrentDevices: () => MediaDeviceInfoLike[],
    options: Options,
) => Process<MediaDeviceRequest>;

/**
 * A process to get user media
 */
export const createGetUserMediaProcess: CreateGetUserMediaProcess = (
    getUserMedia,
    getCurrentDevices,
    {initialMedia, scope = 'media'},
) => {
    const props = {
        unsubscribe: undefined as Unsubscribe | undefined,
        cachedDevices: getCurrentDevices(),
        cachedAudioTrackId: '',
        cachedVideoTrackId: '',
        cachedAudioInput: undefined as MediaDeviceInfoLike | undefined,
        cachedVideoInput: undefined as MediaDeviceInfoLike | undefined,
    };
    const getExpectedAudioInput = createMemorizedGetExpectedInput();
    const getExpectedVideoInput = createMemorizedGetExpectedInput();

    /**
     * Memorized get media input
     */
    const getInput = ([track]: MediaStreamTrack[] = []) => {
        if (!track) {
            return undefined;
        }
        const isAudio = track.kind === 'audio';
        const cacheKey = isAudio ? 'cachedAudioInput' : 'cachedVideoInput';
        const cachedTrackId = isAudio
            ? 'cachedAudioTrackId'
            : 'cachedVideoTrackId';
        const trackId = track.label;
        const {width, height} = track.getSettings();

        if (
            trackId === props[cachedTrackId] &&
            track.readyState === 'live' &&
            (isAudio ||
                (!isAudio &&
                    isRequestedResolution({width, height}, props[cacheKey])))
        ) {
            return props[cacheKey];
        }
        const devices = getCurrentDevices();
        const findTrack = findMediaInputFromMediaStreamTrack(devices);
        const input = findTrack(track);
        // Update cache
        props[cacheKey] = input;
        props[cachedTrackId] = trackId;
        return input;
    };

    return async (constraints: MediaDeviceRequest) => {
        const [stream, status] = await getUserMedia(constraints);
        logger.debug({scope, stream, status}, 'getUserMedia');

        const deriveDeviceStatus = makeDeriveDeviceStatus(constraints);
        const muteTrack = muteStreamTrack(stream);

        const muteAudio = (mute: boolean) => muteTrack(mute, 'audio');
        const muteVideo = (mute: boolean) => muteTrack(mute, 'video');

        return wrapToJSON(
            shallowCopy(initialMedia ?? {}, {
                constraints,
                devices: getCurrentDevices(),
                rawStream: stream,
                stream,
                status,
                get audioInput() {
                    return getInput(stream?.getAudioTracks());
                },
                get videoInput() {
                    return getInput(stream?.getVideoTracks());
                },
                get expectedAudioInput() {
                    return getExpectedAudioInput(constraints.audio, () => ({
                        devices: getCurrentDevices().filter(isAudioInput),
                        input: getInput(stream?.getAudioTracks()),
                    }));
                },
                get expectedVideoInput() {
                    return getExpectedVideoInput(constraints.video, () => ({
                        devices: getCurrentDevices().filter(isVideoInput),
                        input: getInput(stream?.getVideoTracks()),
                    }));
                },
                muteAudio,
                muteVideo,
                get audioMuted() {
                    return isMuted(stream?.getAudioTracks());
                },
                get videoMuted() {
                    return isMuted(stream?.getVideoTracks());
                },
                applyConstraints: async constraints => {
                    try {
                        logger.debug(
                            {scope, constraints: constraints},
                            'apply native constraints',
                        );
                        return await applyConstraints(
                            stream?.getTracks(),
                            constraints,
                        );
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            const status = toErrorDeviceStatus(
                                error,
                                deriveDeviceStatus,
                            );
                            logger.error(
                                {
                                    scope,
                                    status,
                                    error,
                                    constraints: constraints,
                                },
                                'fail to apply native constraints',
                            );
                            initialMedia.status = status;
                        }
                        throw error;
                    }
                },
                getSettings: () => {
                    const settings = {
                        audio:
                            stream
                                ?.getAudioTracks()
                                .map(track => track.getSettings()) ?? [],
                        video:
                            stream
                                ?.getVideoTracks()
                                .map(track => track.getSettings()) ?? [],
                    };
                    logger.debug({settings, scope}, 'get native settings');
                    return settings;
                },
                release: () => {
                    stopMediaStream(stream);
                    if (props.unsubscribe) {
                        props.unsubscribe();
                        props.unsubscribe = undefined;
                    }
                    return Promise.resolve();
                },
            }),
        );
    };
};
