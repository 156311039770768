import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {Bar, FontVariant, Icon, Text} from '@pexip/components';

import {NetworkState} from '../../types';

import {stateToProps} from './utils';

import styles from './NetworkAlert.module.scss';

export const NetworkAlert: React.FC<{
    networkState: NetworkState;
}> = ({networkState}) => {
    const {t} = useTranslation();
    const {iconSrc, testId} = stateToProps[networkState];
    const alertText = (
        <Text colorScheme="light" fontVariant={FontVariant.BodyBold}>
            {networkState === NetworkState.Reconnecting
                ? t(
                      'meeting.network-reconnecting',
                      'Lost network connection. Reconnecting...',
                  )
                : t('meeting.network-fixed', 'Fixed!')}
        </Text>
    );

    return (
        <Bar
            className={cx(styles.main, {
                [styles.shown]: networkState !== NetworkState.Connected,
                [styles.hidden]: networkState === NetworkState.Connected,
            })}
            data-testid={testId}
        >
            <Icon
                source={iconSrc}
                size="compact"
                className={cx('mr-2', {
                    [styles.alert]: networkState === NetworkState.Reconnecting,
                    [styles.check]: networkState !== NetworkState.Reconnecting,
                })}
            ></Icon>
            {alertText}
        </Bar>
    );
};
