import React from 'react';
import {useTranslation} from 'react-i18next';

import {Modal, Tab, Tabs, ThemeProvider} from '@pexip/components';

import {usePreviewController} from '../services/Media.service';
import {TestId} from '../../test/testIds';
import {useConfig} from '../config';

import {MediaControl} from './MediaControl.viewModel';
import {Effects} from './Effects.viewModel';

export const PreflightSettings: React.FC<{
    activeTab: number;
    closeModal: () => void;
    isOpen: boolean;
}> = ({activeTab, closeModal, isOpen}) => {
    const {t} = useTranslation();

    const [videoProcessingEnabled] = useConfig('videoProcessing');
    const controller = usePreviewController(isOpen);

    return (
        <Modal
            data-testid={TestId.ModalSettings}
            isOpen={isOpen}
            onClose={closeModal}
            padding="none"
            sizeModifier="medium"
            uniqueTitle="Settings"
        >
            <ThemeProvider colorScheme="light">
                <Tabs initialActiveTabIndex={activeTab}>
                    <Tab title={t('settings.personal', 'Personal settings')}>
                        {controller && (
                            <MediaControl
                                controller={controller}
                                closeModal={closeModal}
                            />
                        )}
                    </Tab>
                    {videoProcessingEnabled && (
                        <Tab title={t('settings.effects', 'Effects')}>
                            {controller && (
                                <Effects
                                    controller={controller}
                                    closeModal={closeModal}
                                />
                            )}
                        </Tab>
                    )}
                </Tabs>
            </ThemeProvider>
        </Modal>
    );
};
