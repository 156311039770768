import React from 'react';

import type {InMeetingUI} from '@pexip/media-components';
import {ParticipantsPanelToggleView} from '@pexip/media-components';

import {useParticipants} from '../hooks/useParticipants';

export const ParticipantsPanelToggle: React.FC<{
    inMeetingUI: InMeetingUI;
    myIdentity?: string;
}> = ({inMeetingUI, myIdentity}) => {
    const {inMeeting, waitingInLobby, raisedHand, external} = useParticipants();
    const shouldShow =
        inMeeting.length > 0 ||
        waitingInLobby.length > 0 ||
        raisedHand.length > 0 ||
        external.length > 0;

    if (!shouldShow) {
        return null;
    }

    const raisedHandCount = raisedHand.filter(
        ({identity}) => identity !== myIdentity,
    );

    return (
        <ParticipantsPanelToggleView
            inMeetingCount={
                inMeeting.length + raisedHand.length + external.length
            }
            onClick={() => inMeetingUI.togglePanels('participants')}
            isPanelOpen={inMeetingUI.panelsState.openParticipantPanel}
            participantsActionCount={
                waitingInLobby.length + raisedHandCount.length
            }
            {...inMeetingUI.autoHideProps}
        />
    );
};
