import Pino from 'pino';

const REDACTED_REPLACEMENT = '[REDACTED]';

const logEvents: string[] = [];
const sensitiveValues = new Set<string>();
let redactionRegex: RegExp | undefined = undefined;

window.pexDebug = {...window.pexDebug, logEvents};

/**
 * Get the log file as a blob
 */
export function getLogs() {
    return new Blob(
        logEvents,
        // Technically application/ld+json
        {type: 'text/plain; charset=utf-8'},
    );
}

/**
 * Get the log file as a string.
 */
export function getLogFile(): string {
    return logEvents.join('');
}

/**
 * Get a file name based on (app) name, current date/time, and extension
 *
 * @param name -- Name to prefix the file with
 * @param ext -- Extension (without the dot) to use
 */
export function getFileName(name = 'pexip', ext = 'log') {
    return `${name}-${new Date().toISOString().replace(/:/g, '-')}.${ext}`;
}

/**
 * Create a log file based on the log until now, and trigger a download
 *
 * @param fileName -- File name of the downloaded file
 */
export function downloadLog(fileName = getFileName()) {
    const blobUrl = URL.createObjectURL(getLogs());
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    link.click();
    // Wait half a second, as reportedly some browsers will fail if it's revoked too quickly
    setTimeout(() => URL.revokeObjectURL(blobUrl), 500);
}

window.pexDebug.dumpLog = downloadLog;

function escapeRegexp(segment: string) {
    // While we don't expect values to contain any special characters, escape them (e.g. . => \.) to avoid blowing up if that happens.
    return segment.replace(/"/g, '\\$&').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Adds a value to the redaction set, which makes it replaced by [REDACTED] when logged to file.
 *
 * @remarks
 * The redaction set is applied globally, and only applies to the log file, not console logs.
 *
 * @param value - the string to redact
 */
export function addRedactedValue(value: string) {
    // Doing raw string operations with json is fragile, try to detect some potential issues early.
    if (value === '') {
        throw new Error('Tried to add empty string for redaction');
    }
    if (/[\\\n]/.exec(value)) {
        throw new Error(
            'Trying to add value for redaction that is likely to break the log',
        );
    }
    // Probably overkill, but try to catch some possible non-string values that can break the log (null, undefined, booleans), or are likely to be unintended ([Object object]).
    if (
        ['null', 'undefined', 'true', 'false', '[Object object]'].includes(
            String(value),
        )
    ) {
        throw new Error(
            `Tried to add ${value} for redaction, which is almost certainly an error`,
        );
    }

    // avoid regenerating the RegExp if the value has already been added
    if (sensitiveValues.has(value)) {
        return;
    }
    sensitiveValues.add(value);
    redactionRegex = new RegExp(
        Array.from(sensitiveValues).map(escapeRegexp).join('|'),
        'g',
    );
}

/**
 * Resets the global redaction set. Mostly exposed for completeness.
 */
export function resetRedactionSet() {
    sensitiveValues.clear();
    redactionRegex = undefined;
}

/**
 * @internal
 */
export function redactLogEvent(logEvent: string) {
    if (!redactionRegex) {
        return logEvent;
    }
    return logEvent.replace(redactionRegex, REDACTED_REPLACEMENT);
}

function replacer(_key: string, value: unknown) {
    if (value instanceof HTMLElement) {
        const innerHTML = value.innerHTML;
        if (innerHTML !== '') {
            return value.outerHTML.replace(innerHTML, '{...}');
        } else {
            return value.outerHTML;
        }
    } else if (value instanceof Function) {
        return `[Function ${value.name}]`;
    } else if (value instanceof MediaStream) {
        return {
            __type: 'MediaStream',
            id: value.id,
            active: value.active,
        };
    } else if (value instanceof MediaStreamTrack) {
        return {
            __type: 'MediaStreamTrack',
            kind: value.kind,
            id: value.id,
            enabled: value.enabled,
            muted: value.muted,
            readyState: value.readyState,
        };
    } else if (value instanceof Error) {
        return {
            name: value.toString(),
            message: value.message,
        };
    } else if (value instanceof AnalyserNode) {
        return {
            __type: 'AnalyserNode',
            frequencyBinCount: value.frequencyBinCount,
            fftSize: value.fftSize,
            minDecibels: value.minDecibels,
            maxDecibels: value.maxDecibels,
            smoothingTimeConstant: value.smoothingTimeConstant,
        };
    } else if (value instanceof AudioContext) {
        return {
            __type: 'AudioContext',
            audioWorklet: value.audioWorklet,
            state: value.state,
            sampleRate: value.sampleRate,
        };
    } else if (value instanceof MediaStreamAudioSourceNode) {
        return {
            __type: 'MediaStreamAudioSourceNode',
            mediaStream: value.mediaStream,
        };
    } else if (value instanceof MediaStreamAudioDestinationNode) {
        return {
            __type: 'MediaStreamAudioDestinationNode',
            stream: value.stream,
        };
    } else if (value instanceof ChannelMergerNode) {
        return {__type: 'ChannelMergerNode'};
    } else if (value instanceof GainNode) {
        return {__type: 'GainNode', gain: value.gain};
    } else if (value instanceof MediaElementAudioSourceNode) {
        return {
            __type: 'MediaElementAudioSourceNode',
            mediaElement: value.mediaElement,
        };
    } else if (
        'AudioWorkletNode' in window &&
        value instanceof AudioWorkletNode
    ) {
        return {
            __type: 'AudioWorkletNode',
            parameters: value.parameters,
        };
    } else if (value instanceof ChannelSplitterNode) {
        return {
            __type: 'ChannelSplitterNode',
        };
    } else if (value instanceof DelayNode) {
        return {
            __type: 'DelayNode',
            delayTime: value.delayTime,
        };
    }
    return value;
}

// HACK: Stupid solution, but seems to be the best way to transform
//       transmit format to what e.g. pino-pretty expects.
const pinoStore = Pino({
    browser: {
        write: (evt: unknown) => {
            if (typeof evt !== 'object' || !evt) {
                return;
            }
            try {
                const logEvent = JSON.stringify(evt, replacer);
                logEvents.push(redactLogEvent(logEvent));
                logEvents.push('\n');
            } catch {
                const logEvent = JSON.stringify(
                    Object.fromEntries(
                        Object.entries(evt).map(([k, v]) => [
                            k,
                            typeof v === 'object' ? `${v}` : v,
                        ]),
                    ),
                );
                logEvents.push(redactLogEvent(logEvent));
                logEvents.push('\n');
            }
        },
    },
    level: 'debug',
});

/**
 * @internal
 */
export interface LogMethod {
    (obj: unknown, fmt?: string, ...args: unknown[]): void;

    (fmt: string, ...args: unknown[]): void;
}

/**
 * Declare an interface here so dependencies of this package doesn't need \@types/pino
 */
export interface Logger {
    child(bindings: Record<string, unknown>): Logger;

    trace: LogMethod;
    debug: LogMethod;
    info: LogMethod;
    warn: LogMethod;
    error: LogMethod;
}

const pino: Logger = Pino({
    browser: {
        // asObject: true,
        transmit: {
            send: (level, event) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- semantically identical to passing directly
                const [first, ...rest] = event.messages; // work around ts typechecking
                event.bindings
                    .reduce<Pino.Logger>((ch, bi) => ch.child(bi), pinoStore)
                    // eslint-disable-next-line no-unexpected-multiline -- prettier is doing something weird
                    [level](first, ...rest);
            },
        },
    },
    level: process.env.NODE_ENV === 'test' ? 'silent' : 'debug',
});

export default pino;
