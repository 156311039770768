import type {
    AnyStats,
    AudioQualityStats,
    CacheStats,
    CallPacketsStats,
    CallQualityStats,
    InboundAudio,
    InboundAudioMetrics,
    InboundVideo,
    InboundVideoMetrics,
    Metrics,
    NormalizedRTCStats,
    OutboundAudio,
    OutboundAudioMetrics,
    OutboundVideo,
    OutboundVideoMetrics,
    PacketsStats,
    RTCStats,
    StatsCollector,
    StatsCollectorOptions,
    VideoQualityStats,
} from './statsCollector.types';
import {Quality} from './statsCollector.types';

export const STATS_SIZE = 60;

/**
 * The WebRTC stats are given to us as a flat structure (an array of objects
 * that contain id-fields pointing to other objects in the same array).
 *
 * This class takes such an array as input and can expand an entry so that
 * references become nested objects.
 *
 * https://www.w3.org/TR/webrtc-stats/#summary
 * https://www.w3.org/TR/webrtc-stats/#rtctatstype-*
 */
export const createResolver = (statsReport: Array<AnyStats>) => {
    const idLookup = statsReport.reduce(
        (map, entry) => map.set(entry.id, entry),
        new Map<string, AnyStats>(),
    );

    const expandTail = (stats: AnyStats, seenIds: Array<string> = []) =>
        Object.keys(stats).reduce((expanded, key) => {
            const id = stats[key];
            expanded[key] = id;

            if (!id || typeof id !== 'string' || seenIds.includes(id)) {
                return expanded;
            }

            switch (key) {
                case 'codecId': {
                    const codec = idLookup.get(id);
                    if (codec) {
                        expanded.codec = expandTail(codec, [...seenIds, id]);
                    }
                    break;
                }
                case 'remoteCandidateId': {
                    const remoteCandidate = idLookup.get(id);
                    if (remoteCandidate) {
                        expanded.remoteCandidate = expandTail(remoteCandidate, [
                            ...seenIds,
                            id,
                        ]);
                    }
                    break;
                }
                case 'remoteId': {
                    const remote = idLookup.get(id);
                    if (remote) {
                        expanded.remote = expandTail(remote, [...seenIds, id]);
                    }
                    break;
                }
                case 'selectedCandidatePairId': {
                    const selectedCandidatePair = idLookup.get(id);
                    if (selectedCandidatePair) {
                        expanded.selectedCandidatePair = expandTail(
                            selectedCandidatePair,
                            [...seenIds, id],
                        );
                    }
                    break;
                }
                case 'trackId': {
                    const track = idLookup.get(id);
                    if (track) {
                        expanded.track = expandTail(track, [...seenIds, id]);
                    }
                    break;
                }
                case 'trackIds': {
                    if (Array.isArray(id)) {
                        const ids: Array<string> = id;
                        const tracks = ids.flatMap(id => {
                            const track = idLookup.get(id);
                            return !track
                                ? []
                                : [expandTail(track, [...seenIds, ...ids])];
                        });

                        expanded.tracks = tracks;
                    }
                    break;
                }
                case 'transportId': {
                    const transport = idLookup.get(id);
                    if (transport) {
                        expanded.transport = expandTail(transport, [
                            ...seenIds,
                            id,
                        ]);
                    }
                    break;
                }
                case 'localCandidateId':
                case 'localCertificateId':
                case 'localId':
                case 'mediaSourceId':
                case 'remoteCertificateId':
                default:
                    break;
            }

            return expanded;
        }, {} as AnyStats);

    function expand(audioIn: InboundAudio): InboundAudio;
    function expand(audioOut: OutboundAudio): OutboundAudio;
    function expand(videoIn: InboundVideo): InboundVideo;
    function expand(videoOut: OutboundVideo): OutboundVideo;
    function expand(rawStats: AnyStats): AnyStats {
        return expandTail(rawStats);
    }

    return {
        expand,
    };
};

/**
 * Normalize inbound audio stats
 *
 * @param statsData - Raw inbound audio stats @see InboundAudio
 *
 * @returns normalized stats for inbound audio from PeerConnection
 */
export const inboundAudio = (statsData: InboundAudio): InboundAudioMetrics => {
    const packetsReceived = statsData.packetsReceived ?? 0;
    const packetsLost = statsData.packetsLost ?? 0;
    const totalPackets = packetsReceived + packetsLost;

    return {
        type: statsData.type,
        kind: statsData.kind,
        jitter: statsData.jitter ?? 0,
        timestamp: statsData.timestamp,
        packetsTransmitted: packetsReceived,
        packetsLost,
        bytesTransmitted: statsData.bytesReceived,
        codec: statsData.codec?.mimeType,
        roundTripTime:
            statsData.transport?.selectedCandidatePair?.currentRoundTripTime,
        totalPercentageLost: totalPackets && packetsLost / totalPackets,
    };
};

/**
 * Normalize outbound audio stats
 *
 * @param statsData - Raw outbound audio stats @see OutboundAudio
 *
 * @returns normalized stats for outbound audio from PeerConnection
 */
export const outboundAudio = (
    statsData: OutboundAudio,
): OutboundAudioMetrics => {
    const packetsSent = statsData.packetsSent ?? 0;
    const packetsLost = statsData.remote?.packetsLost ?? 0;
    const totalPackets = packetsSent + packetsLost;

    // firefox typically uses remote.roundTripTime and chrome uses currentRoundTripTime
    const roundTripTime =
        statsData.remote?.roundTripTime ??
        statsData.transport?.selectedCandidatePair?.currentRoundTripTime;

    return {
        type: statsData.type,
        kind: statsData.kind,
        jitter: statsData?.remote?.jitter ?? 0,
        timestamp: statsData.timestamp,
        packetsTransmitted: packetsSent,
        packetsLost,
        bytesTransmitted: statsData.bytesSent,
        codec: statsData.codec?.mimeType,
        roundTripTime,
        totalPercentageLost: totalPackets && packetsLost / totalPackets,
    };
};

/**
 * Normalize inbound video stats
 *
 * @param statsData - Raw inbound video stats @see InboundVideo
 *
 * @returns normalized stats for inbound video from PeerConnection
 */
export const inboundVideo = (statsData: InboundVideo): InboundVideoMetrics => {
    const packetsReceived = statsData.packetsReceived ?? 0;
    const packetsLost = statsData.packetsLost ?? 0;
    const totalPackets = packetsReceived + packetsLost;

    return {
        type: statsData.type,
        kind: statsData.kind,
        timestamp: statsData.timestamp,
        packetsTransmitted: packetsReceived,
        packetsLost,
        bytesTransmitted: statsData.bytesReceived,
        // firefox typically uses bitrateMean while chrome does not
        bitrate: statsData.bitrateMean,
        codec: statsData.codec?.mimeType,
        resolutionWidth: statsData.frameWidth,
        resolutionHeight: statsData.frameHeight,
        resolution:
            statsData.frameWidth && statsData.frameHeight
                ? `${statsData.frameWidth}x${statsData.frameHeight}`
                : undefined,
        // firefox typically uses framerateMean while chrome does not
        framesPerSecond: statsData.framerateMean ?? statsData.framesPerSecond,
        roundTripTime:
            statsData.transport?.selectedCandidatePair?.currentRoundTripTime,
        totalPercentageLost: totalPackets && packetsLost / totalPackets,
    };
};

/**
 * Normalize outbound video stats
 *
 * @param statsData - Raw outbound video stats @see OutboundVideo
 *
 * @returns normalized stats for outbound video from PeerConnection
 */
export const outboundVideo = (
    statsData: OutboundVideo,
): OutboundVideoMetrics => {
    const totalPacketSendDelay = statsData?.totalPacketSendDelay ?? 0;
    const packetsSent = statsData.packetsSent ?? 0;
    const packetsLost = statsData.remote?.packetsLost ?? 0;
    const totalPackets = packetsSent + packetsLost;

    // firefox typically uses remote.roundTripTime and chrome uses currentRoundTripTime
    const roundTripTime =
        statsData.remote?.roundTripTime ??
        statsData.transport?.selectedCandidatePair?.currentRoundTripTime;

    return {
        type: statsData.type,
        kind: statsData.kind,
        timestamp: statsData.timestamp,
        packetsTransmitted: packetsSent,
        packetsLost,
        bytesTransmitted: statsData.bytesSent,
        totalPacketSendDelay: statsData.totalPacketSendDelay,
        averagePacketSendDelay:
            totalPacketSendDelay && totalPacketSendDelay / packetsSent,
        // firefox typically uses bitrateMean while chrome does not
        bitrate: statsData.bitrateMean,
        codec: statsData.codec?.mimeType,
        resolutionWidth: statsData?.frameWidth,
        resolutionHeight: statsData?.frameHeight,
        resolution:
            statsData.frameWidth && statsData.frameHeight
                ? `${statsData.frameWidth}x${statsData.frameHeight}`
                : undefined,
        // firefox typically uses framerateMean while chrome does not
        framesPerSecond: statsData.framerateMean ?? statsData.framesPerSecond,
        roundTripTime,
        totalPercentageLost: totalPackets && packetsLost / totalPackets,
    };
};

const isInbound = (entry: AnyStats) => entry.type === 'inbound-rtp';
const isOutbound = (entry: AnyStats) => entry.type === 'outbound-rtp';
const isAudio = (entry: AnyStats) => entry.kind === 'audio';
const isVideo = (entry: AnyStats) => entry.kind === 'video';

const isAudioInbound = (entry: AnyStats): entry is InboundAudio =>
    isInbound(entry) && isAudio(entry);
const isAudioOutbound = (entry: AnyStats): entry is OutboundAudio =>
    isOutbound(entry) && isAudio(entry);
const isVideoInbound = (entry: AnyStats): entry is InboundVideo =>
    isInbound(entry) && isVideo(entry);
const isVideoOutbound = (entry: AnyStats): entry is OutboundVideo =>
    isOutbound(entry) && isVideo(entry);

/**
 * Normalize stats into inbound and outbound video and audio stats
 *
 * @param statsReports - reports to for maping
 *
 * @returns normalized stats for inbound, outbound audio and video
 */
export const statsFrom = (statsReports: Array<AnyStats>) => {
    const resolver = createResolver(statsReports);

    // TODO: support them combined if we pass peer-connection obj instead of transceiver
    // as now we return first found
    const audioIn = statsReports.find(isAudioInbound);
    if (audioIn) {
        return inboundAudio(resolver.expand(audioIn));
    }

    const audioOut = statsReports.find(isAudioOutbound);
    if (audioOut) {
        return outboundAudio(resolver.expand(audioOut));
    }

    const videoIn = statsReports.find(isVideoInbound);
    if (videoIn) {
        return inboundVideo(resolver.expand(videoIn));
    }

    const videoOut = statsReports.find(isVideoOutbound);
    if (videoOut) {
        return outboundVideo(resolver.expand(videoOut));
    }
};

/**
 * Gets raw stats from peerConnection and normalized them
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_Statistics_API
 *
 * A RTCPeerConnection has getStats()
 * - getStats() returns promise which resolve to a RTCStatsReport
 * - RTCStatsReport behaves like an array of RTCStats objects, or more
 *   specific RTCRtpStreamStats objects
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/RTCStatsReport
 * https://developer.mozilla.org/en-US/docs/Web/API/RTCStats
 * https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpStreamStats
 *
 */

export const statsFromRTCPeer = async (rtcPeer: {
    getStats: (selector?: MediaStreamTrack | null) => Promise<RTCStatsReport>;
}): Promise<NormalizedRTCStats> => {
    const stats = await rtcPeer?.getStats(null);
    const reports: Array<RTCStats> = [];
    stats.forEach(report => reports.push(report));
    return (
        statsFrom(reports) || {
            type: 'inbound-rtp',
            kind: 'audio',
            packetsLost: 0,
            packetsTransmitted: 0,
        }
    );
};

const getRecentPacketsLost = (oldMetrics: Metrics, newMetrics: Metrics) =>
    Math.max(newMetrics.packetsLost - oldMetrics.packetsLost, 0);

const getRecentTotalPackets = (oldMetrics: Metrics, newMetrics: Metrics) => {
    const totalPacketsDelta =
        newMetrics.packetsTransmitted +
        newMetrics.packetsLost -
        (oldMetrics.packetsTransmitted + oldMetrics.packetsLost);
    return Math.max(totalPacketsDelta, 1);
};

const getPacketStats = (oldMetrics: Metrics, newMetrics: Metrics) =>
    [getRecentPacketsLost, getRecentTotalPackets].map(fn =>
        fn(oldMetrics, newMetrics),
    ) as [number, number];

const removeObsolete = (
    metric: Array<unknown>,
    qualityHistorySize = STATS_SIZE,
) => {
    if (metric.length === qualityHistorySize) {
        metric.pop();
    }
};

const addPacketsStats = (metric: PacketsStats, data: PacketsStats[0]) => {
    removeObsolete(metric);
    metric.unshift(data);
};

const recordCallPacketsStats = (
    oldStats: NormalizedRTCStats,
    newStats: NormalizedRTCStats,
    callPacketsStats: CallPacketsStats,
) => {
    if (oldStats && newStats) {
        if (!callPacketsStats) {
            callPacketsStats = [];
        }
        addPacketsStats(callPacketsStats, getPacketStats(oldStats, newStats));
    }

    return callPacketsStats;
};

const addAudioQualityMetric = (
    metric: AudioQualityStats,
    data: AudioQualityStats[0],
) => {
    removeObsolete(metric);
    metric.unshift(data);
};

const addVideoQualityMetric = (
    metric: VideoQualityStats,
    data: VideoQualityStats[0],
) => {
    removeObsolete(metric);
    metric.unshift(data);
};

const recordCallQualityStats = (
    prevStats: NormalizedRTCStats,
    stats: NormalizedRTCStats,
    callQualityStats: CallQualityStats,
) => {
    const calcRecentPacketLoss = ({pT = 0, prevPT = 0, pL = 0, prevPL = 0}) => {
        return pT ? (pL - prevPL) / (pT - prevPT) : 0;
    };

    if (stats) {
        if (!callQualityStats) {
            callQualityStats = [];
        }

        if (stats.kind === 'audio') {
            addAudioQualityMetric(
                (callQualityStats || []) as AudioQualityStats,
                [
                    calcRecentPacketLoss({
                        pT: stats.packetsTransmitted,
                        prevPT: prevStats?.packetsTransmitted,
                        pL: stats.packetsLost,
                        prevPL: prevStats?.packetsLost,
                    }),
                    stats.jitter ?? 0,
                ],
            );
        }

        if (stats.kind === 'video') {
            addVideoQualityMetric(
                (callQualityStats || []) as VideoQualityStats,
                calcRecentPacketLoss({
                    pT: stats.packetsTransmitted,
                    prevPT: prevStats?.packetsTransmitted,
                    pL: stats.packetsLost,
                    prevPL: prevStats?.packetsLost,
                }),
            );
        }
    }

    return callQualityStats;
};

export const getQuality = (stats: CallQualityStats) => {
    const qualityOverTime = stats.map(stats => calculateQuality(stats));

    const goodOrOk = qualityOverTime.filter(
        stat => stat === Quality.GOOD || stat === Quality.OK,
    );

    const qualitySum = qualityOverTime.reduce((acc, val) => acc + val, 0);

    return {
        quality: Math.round(qualitySum / qualityOverTime.length) as Quality,
        goodOrOkQuality: goodOrOk.length / qualityOverTime.length,
        qualityOverTime,
    };
};

export const addDeltaStats = (
    newStats: NormalizedRTCStats,
    cache: CacheStats,
) => {
    const oldStats = cache.previous ?? newStats;

    const deltaStats = {
        ...newStats,
    };

    const callPacketsStats = recordCallPacketsStats(
        oldStats,
        newStats,
        cache.callPacketsStats,
    );

    const metrics = [
        [newStats, oldStats, deltaStats, callPacketsStats ?? []],
    ] as const;

    metrics.forEach(([newMetrics, oldMetrics, deltaMetrics, packets]) => {
        if (
            newMetrics?.bytesTransmitted &&
            newMetrics?.timestamp &&
            oldMetrics?.bytesTransmitted &&
            oldMetrics?.timestamp &&
            deltaMetrics // && deltaMetrics.bitrate == null
        ) {
            const dMs = newMetrics.timestamp - oldMetrics.timestamp;

            const dBytes =
                newMetrics.bytesTransmitted - oldMetrics.bytesTransmitted;

            if (dMs !== 0) {
                deltaMetrics.bitrate = Math.round((dBytes * 8) / (dMs / 1000));
            }
        }

        if (deltaMetrics) {
            const [recentPacketsLost, recentTotalPackets] = packets.reduce(
                (acc, [lost, total]) => {
                    acc[0] += lost;
                    acc[1] += total;
                    return acc;
                },
                [0, 0],
            );

            deltaMetrics.recentPercentageLost =
                recentTotalPackets === 0
                    ? 0
                    : recentPacketsLost / recentTotalPackets;
        }
    });

    const callQualityStats = recordCallQualityStats(
        oldStats,
        deltaStats,
        cache.callQualityStats,
    );

    return [
        deltaStats,
        getQuality(callQualityStats ?? []).quality,
        callQualityStats,
    ] as const;
};

// https://docs.pexip.com/admin/media_statistics.htm
export const calculateQuality = (stats: number | [number, number]) => {
    let packetLoss;
    let jitter;

    if (typeof stats === 'number') {
        packetLoss == stats;
    } else {
        [packetLoss, jitter] = stats;
    }

    let callQuality = Quality.OK;

    if (typeof packetLoss === 'number') {
        if (packetLoss < 0.01) {
            callQuality = Quality.GOOD;
        } else if (packetLoss < 0.03) {
            callQuality = Quality.OK;
        } else if (packetLoss < 0.1) {
            callQuality = Quality.BAD;
        } else {
            callQuality = Quality.TERRIBLE;
        }
    }

    if (jitter && jitter > 0.04) {
        if (callQuality === Quality.GOOD) {
            callQuality = Quality.OK;
        } else if (callQuality === Quality.OK) {
            callQuality = Quality.BAD;
        } else if (callQuality === Quality.BAD) {
            callQuality = Quality.TERRIBLE;
        }
    }

    return callQuality;
};

/**
 * Creates stats collector
 *
 * @param input - input to get stats from
 * @param signal - Signal which distributes stats
 * @param interval - how often signal with fire with new stats
 *
 * @returns \{function to reset stats window, cleanup func\}
 */
export const createStatsCollector = ({
    input,
    signals: {onCallQuality, onCallQualityStats, onRtcStats},
    interval = 1000,
}: StatsCollectorOptions): StatsCollector => {
    const newCache = (): CacheStats => ({
        callQuality: Quality.GOOD,
        callPacketsStats: [],
        callQualityStats: [],
    });

    let cache = newCache();

    const pushStats = () => {
        void statsFromRTCPeer(input).then(newStats => {
            const [stats, callQuality, callQualityStats] = addDeltaStats(
                newStats,
                cache,
            );
            if (callQuality != cache.callQuality) {
                onCallQuality.emit(callQuality);
                cache.callQuality = callQuality;
            }

            cache.previous = newStats;
            onRtcStats.emit(stats);
            onCallQualityStats.emit(callQualityStats);

            window.pexDebug = {
                ...window.pexDebug,
                callQuality,
                callQualityStats,
                stats,
            };
        });
    };

    let it = window.setInterval(pushStats, interval);
    const clearInterval = () => {
        window.clearInterval(it);
        it = 0;
    };
    const resumeStats = () => {
        if (it === 0) {
            cache = newCache();
            pushStats();
            it = window.setInterval(pushStats, interval);
        }
    };

    return {
        resetStats: () => {
            clearInterval();
            return resumeStats;
        },
        cleanup: clearInterval,
    };
};
