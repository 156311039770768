import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {TextLink, Divider, ThemeProvider} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './ParticipantsPanelFooter.module.scss';

export const ParticipantsPanelFooter: React.FC<{
    guestsMuted?: boolean;
    toggleGuestsMute: () => void;
    openMeetingSettings: () => void;
}> = ({guestsMuted = false, toggleGuestsMute, openMeetingSettings}) => {
    const {t} = useTranslation();

    const meetingSettingsText = t(
        'settings.meeting-settings',
        'Meeting settings',
    );

    const guestsMuteText = guestsMuted
        ? t('media.unmute-all-guests', 'Unmute all Guests')
        : t('media.mute-all-guests', 'Mute all Guests');

    return (
        <ThemeProvider colorScheme="light">
            <div className={styles.wrapper}>
                <Divider />
                <div className={styles.innerWrapper}>
                    <TextLink
                        className={cx(
                            'p-3',
                            'text-center',
                            styles.meetingSettingsLink,
                        )}
                        isTruncated
                        title={meetingSettingsText}
                        variant="inherit"
                        onClick={openMeetingSettings}
                        data-testid={TestId.LinkOpenMeetingSettings}
                    >
                        {meetingSettingsText}
                    </TextLink>
                    <Divider direction="vertical" />
                    <TextLink
                        className={cx(
                            'p-3',
                            'text-center',
                            styles.guestMuteLink,
                        )}
                        isTruncated
                        title={guestsMuteText}
                        variant="inherit"
                        onClick={toggleGuestsMute}
                        data-testid={
                            guestsMuted
                                ? TestId.LinkUnmuteAllGuests
                                : TestId.LinkMuteAllGuests
                        }
                    >
                        {guestsMuteText}
                    </TextLink>
                </div>
            </div>
        </ThemeProvider>
    );
};
