import React from 'react';

import {
    SelfViewSettings,
    SettingsModalBody,
    usePreviewControllerHandler,
} from '@pexip/media-components';
import type {PreviewStreamController} from '@pexip/media';
import type {RenderEffects} from '@pexip/media-processor';

import {usePreviewSegmentationEffects} from '../services/Media.service';
import {useConfig} from '../config';
import {EffectsList} from '../views/EffectsList/EffectsList.view';
import {EffectsControls} from '../views/EffectsControls/EffectsControls.view';

export const Effects: React.FC<{
    closeModal: () => void;
    controller: PreviewStreamController;
}> = ({closeModal, controller}) => {
    const [bgImageUrl] = useConfig('bgImageUrl');
    const segmentationEffects = usePreviewSegmentationEffects();
    const {previewMedia, handleSave, isSaving, updatingPreview} =
        usePreviewControllerHandler({
            close: closeModal,
            controller,
            applyChanges: [segmentationEffects.applyChanges],
        });
    const setEffect = async (videoSegmentation: RenderEffects) => {
        await controller?.media?.applyConstraints({
            video: {videoSegmentation},
        });
        segmentationEffects.setPreview(videoSegmentation);
    };

    return (
        <>
            <SelfViewSettings mediaStream={previewMedia?.stream} />
            <EffectsList
                active={segmentationEffects.preview}
                bgImageUrl={bgImageUrl}
                setEffect={setEffect}
            />
            <SettingsModalBody>
                <EffectsControls
                    isSaving={isSaving}
                    updatingPreview={updatingPreview}
                    cancel={closeModal}
                    save={handleSave}
                />
            </SettingsModalBody>
        </>
    );
};
