import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Box,
    ModalCloseButton as CloseButton,
    CustomTooltip,
    FontVariant,
    List,
    Row,
    Text,
    TextLink,
} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';

import {TestId} from '../../../test/testIds';

import styles from './DeviceDeniedTooltip.module.scss';

export const DeviceDeniedTooltip: React.FC<{
    children: React.ReactNode;
    contentWrapperClassName?: string;
    deniedDevice: DeniedDevices;
    learnHowToGrantAccessURL?: string;
    mainWrapperClassName?: string;
    onClose: () => void;
    onTryAgain?: () => void;
    visible: boolean;
}> = ({
    children,
    contentWrapperClassName,
    deniedDevice,
    learnHowToGrantAccessURL,
    mainWrapperClassName,
    onClose,
    onTryAgain,
    visible,
    ...props
}) => {
    const {t} = useTranslation();
    return (
        <CustomTooltip
            controlledIsVisible={visible}
            isHoverEnabled={false}
            mainWrapperClassName={mainWrapperClassName}
            wrapperClassName={contentWrapperClassName}
            {...props}
            content={
                <Box className={styles.tooltipWrapper}>
                    <List>
                        <Row className="pt-3 pb-1">
                            <Text fontVariant={FontVariant.BodyBold}>
                                <Trans t={t} i18nKey="media.permissions-denied">
                                    Browser permissions denied
                                </Trans>
                            </Text>
                            <CloseButton
                                className={styles.close}
                                onClose={onClose}
                                aria-label={t(
                                    'common.close-tooltip',
                                    'Close tooltip',
                                )}
                            />
                        </Row>
                        <Row className="pt-0">
                            <Text>
                                <Trans
                                    t={t}
                                    i18nKey="media.previously-permissions-denied"
                                >
                                    You have previously denied permission to use
                                    your {{deniedDevice}}.
                                </Trans>{' '}
                                <TextLink
                                    href={learnHowToGrantAccessURL}
                                    target="_blank"
                                    data-testid={
                                        TestId.TextLinkLearnRequestPermissions
                                    }
                                >
                                    <Trans
                                        t={t}
                                        i18nKey="media.learn-how-to-grant-permissions"
                                    >
                                        Learn how to grant access
                                    </Trans>
                                </TextLink>{' '}
                                <Trans
                                    t={t}
                                    i18nKey="media.through-browser-settings"
                                >
                                    through your browser settings
                                </Trans>
                                {deniedDevice === 'microphone-and-camera' && (
                                    <>
                                        {' '}
                                        <Trans t={t} i18nKey="common.and-then">
                                            and then
                                        </Trans>{' '}
                                        <TextLink
                                            className={styles.tryAgain}
                                            onClick={onTryAgain}
                                            data-testid={
                                                TestId.TextLinkTryAgainRequestPermissions
                                            }
                                        >
                                            <Trans
                                                t={t}
                                                i18nKey="common.try-again"
                                            >
                                                Try again
                                            </Trans>
                                        </TextLink>
                                    </>
                                )}
                            </Text>
                        </Row>
                    </List>
                </Box>
            }
            position="topCenter"
        >
            {children}
        </CustomTooltip>
    );
};
