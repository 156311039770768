import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Button,
    Checkbox,
    Divider,
    FontVariant,
    Form,
    Input,
    TextHeading,
} from '@pexip/components';
import {LinkCopy, ManualInvite} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';

export const InviteSomeone: React.FC<{
    addParticipant: (e: React.FormEvent<HTMLFormElement>) => void;
    destination: string;
    handleLinkClick: () => void;
    isCopied: boolean;
    isHost: boolean;
    setDestination: React.Dispatch<React.SetStateAction<string>>;
    setHost: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    addParticipant,
    destination,
    handleLinkClick,
    isCopied,
    isHost,
    setDestination,
    setHost,
}) => {
    const {t} = useTranslation();
    return (
        <>
            <div className="mt-4 ml-4">
                <TextHeading
                    htmlTag="h5"
                    fontVariant={FontVariant.H5}
                    className="pr-8"
                >
                    <Trans t={t} i18nKey="invite.invite-someone">
                        Invite someone to the meeting
                    </Trans>
                </TextHeading>
                <Form onSubmit={addParticipant}>
                    <div className="mt-4 mr-4">
                        <Input
                            label={t(
                                'add-participant.type-details',
                                'Type the participant details here',
                            )}
                            labelModifier="hidden"
                            name="participant-details"
                            onValueChange={setDestination}
                            placeholder={t(
                                'add-participant.type-details',
                                'Type the participant details here',
                            )}
                            showClearButton={!!destination}
                            type="text"
                            value={destination}
                        />
                    </div>

                    <Checkbox
                        className="mt-3"
                        colorScheme="dark"
                        checked={isHost}
                        label={t(
                            'add-participant.can-control',
                            'Can control a meeting (Host)',
                        )}
                        name="is-host"
                        onChange={e => setHost(e.currentTarget.checked)}
                    />

                    <div className="mt-2 mb-4 mr-4">
                        <Button
                            modifier="fullWidth"
                            type="submit"
                            variant="obvious"
                            size="large"
                            isDisabled={!destination}
                            data-testid={TestId.ButtonAddParticipant}
                        >
                            <Trans t={t} i18nKey="add-participant.submit">
                                Add participant
                            </Trans>
                        </Button>
                    </div>
                </Form>
            </div>

            <Divider />

            <ManualInvite
                canInvite
                inviteLinkBox={
                    <LinkCopy
                        className="mt-2"
                        onClick={handleLinkClick}
                        isCopied={isCopied}
                        link={window.location.href}
                    />
                }
            />
        </>
    );
};
