import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Icon,
    IconTypes,
    Text,
    TextHeading,
    FontVariant,
    List,
    Button,
    useTouchDevice,
    useMdUp,
} from '@pexip/components';

import {PreflightJoinSteps} from '../../enums';
import type {PreflightTipsInfo} from '../../types';
/*
t('media.proceed-without-[camera]', 'Proceed without camera')
t('media.proceed-without-[microphone]', 'Proceed without microphone')
t('media.proceed-without-[speaker]', 'Proceed without speaker')
t('media.help-check-[camera]-again', 'Check camera again')
t('media.help-check-[microphone]-again', 'Check microphone again')
t('media.help-check-[speaker]-again', 'Check speaker again')
*/

const usePreflightTipsText = () => {
    const {t} = useTranslation();

    const desktopTips: Partial<
        Record<PreflightJoinSteps, PreflightTipsInfo[]>
    > = useMemo(
        () => ({
            [PreflightJoinSteps.Camera]: [
                {
                    step: 1,
                    title: t('media.tip-1-header', 'Tip 1'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.camera-help-tip-desktop-1',
                                'Have you selected the correct camera in the dropdown menu?',
                            )}
                        </Text>
                    ),
                },
                {
                    step: 2,
                    title: t('media.help-tip-2-header', 'Tip 2'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.camera-help-tip-desktop-2',
                                'Is something blocking the camera lens?',
                            )}
                        </Text>
                    ),
                },
                {
                    step: 3,
                    title: t('media.help-tip-3-header', 'Tip 3'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.camera-help-tip-desktop-3',
                                'Try closing other programs that might be using the camera (e.g Teams or Slack).',
                            )}
                        </Text>
                    ),
                },
                {
                    step: 4,
                    title: t('media.help-tip-4-header', 'Tip 4'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.camera-help-tip-desktop-4',
                                'If none of the above are relevant, try to restart your browser or computer.',
                            )}
                        </Text>
                    ),
                },
            ],
            [PreflightJoinSteps.Speaker]: [
                {
                    step: 1,
                    title: t('media.help-tip-1-header', 'Tip 1'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.speakers-help-tip-desktop-1',
                                'Make sure the volume is turned up on your device.',
                            )}
                        </Text>
                    ),
                },
                {
                    step: 2,
                    title: t('media.help-tip-2-header', 'Tip 2'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.speakers-help-tip-desktop-2',
                                'Do you have headphones connected to your device?',
                            )}
                        </Text>
                    ),
                },
                {
                    step: 3,
                    title: t('media.help-tip-3-header', 'Tip 3'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.speakers-help-tip-desktop-3',
                                'If you are using a desktop computer, you may need to connect speakers or headphones.',
                            )}
                        </Text>
                    ),
                },
            ],

            [PreflightJoinSteps.Microphone]: [
                {
                    step: 1,
                    title: t('media.tip-1-header', 'Tip 1'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.microphone-help-tip-desktop-1',
                                'Make sure the volume is turned up on your device.',
                            )}
                        </Text>
                    ),
                },
                {
                    step: 2,
                    title: t('media.help-tip-2-header', 'Tip 2'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.microphone-help-tip-desktop-2',
                                'Do you have headphones connected to your device? Make sure the microphone on the headphones is near your mouth.',
                            )}
                        </Text>
                    ),
                },
                {
                    step: 3,
                    title: t('media.help-tip-3-header', 'Tip 3'),
                    text: (
                        <Text
                            fontVariant={FontVariant.Body}
                            className=" text-left"
                        >
                            {t(
                                'media.microphone-help-tip-desktop-3',
                                'If you are using a desktop computer, you may need to connect speakers or headphones.',
                            )}
                        </Text>
                    ),
                },
            ],
        }),
        [t],
    );

    const mobileTips: Partial<Record<PreflightJoinSteps, PreflightTipsInfo[]>> =
        useMemo(
            () => ({
                [PreflightJoinSteps.Camera]: [
                    {
                        step: 1,
                        title: t('media.tip-1-header', 'Tip 1'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.camera-help-tip-mobile-1',
                                    'Is your camera facing the wrong way? Try flipping your camera. Look for this icon',
                                )}{' '}
                                <Icon
                                    source={IconTypes.IconFlipCamera}
                                    style={{marginBottom: '-5px'}}
                                />
                            </Text>
                        ),
                    },
                    {
                        step: 2,
                        title: t('media.help-tip-2-header', 'Tip 2'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.camera-help-tip-mobile-2',
                                    'Is something blocking the camera lens?',
                                )}
                            </Text>
                        ),
                    },
                    {
                        step: 3,
                        title: t('media.help-tip-3-header', 'Tip 3'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.camera-help-tip-mobile-3',
                                    'Try closing other apps that may be using your camera (e.g Teams or WhatsApp etc)',
                                )}
                            </Text>
                        ),
                    },
                    {
                        step: 4,
                        title: t('media.help-tip-4-header', 'Tip 4'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.camera-help-tip-mobile-4',
                                    'Try to restart your device.',
                                )}
                            </Text>
                        ),
                    },
                ],
                [PreflightJoinSteps.Speaker]: [
                    {
                        step: 1,
                        title: t('media.help-tip-1-header', 'Tip 1'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.speakers-help-tip-mobile-1',
                                    'Make sure the volume is turned up on your device.',
                                )}
                            </Text>
                        ),
                    },
                    {
                        step: 2,
                        title: t('media.help-tip-2-header', 'Tip 2'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.speakers-help-tip-mobile-2',
                                    'Do you have headphones connected to your device? Make sure the sound is coming out of intended speaker',
                                )}
                            </Text>
                        ),
                    },
                    {
                        step: 3,
                        title: t('media.help-tip-3-header', 'Tip 3'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.speakers-help-tip-mobile-3',
                                    'Try to restart your device.',
                                )}
                            </Text>
                        ),
                    },
                ],

                [PreflightJoinSteps.Microphone]: [
                    {
                        step: 1,
                        title: t('media.tip-1-header', 'Tip 1'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.microphone-help-tip-mobile-1',
                                    'Make sure the volume is turned up on your device.',
                                )}
                            </Text>
                        ),
                    },
                    {
                        step: 2,
                        title: t('media.help-tip-2-header', 'Tip 2'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.microphone-help-tip-mobile-2',
                                    'Do you have headphones connected to your device? Make sure the microphone of the headphones is near your mouth.',
                                )}
                            </Text>
                        ),
                    },
                    {
                        step: 3,
                        title: t('media.help-tip-3-header', 'Tip 3'),
                        text: (
                            <Text
                                fontVariant={FontVariant.Body}
                                className=" text-left"
                            >
                                {t(
                                    'media.microphone-help-tip-mobile-3',
                                    'Try to restart your device.',
                                )}
                            </Text>
                        ),
                    },
                ],
            }),
            [t],
        );

    return {desktopTips, mobileTips};
};

export interface PreflightTipsProps {
    currentStepIndex: PreflightJoinSteps;
    next: () => void;
    close: () => void;
}

export const PreflightTips: React.FC<
    React.ComponentProps<'div'> & PreflightTipsProps
> = ({currentStepIndex, next, close, className}) => {
    const isMdUp = useMdUp();
    const isTouchDevice = useTouchDevice();
    const tipsText = usePreflightTipsText();
    const steps = isTouchDevice
        ? tipsText.mobileTips[currentStepIndex]
        : tipsText.desktopTips[currentStepIndex];
    const {t} = useTranslation();

    return (
        <div
            className={cx(
                className,
                'relative',
                'flex',
                'flex-direction-column',
                'justify-content-space-between',
                {'mt-12': isMdUp},
            )}
        >
            <List spacing="none">
                <TextHeading
                    htmlTag={FontVariant.H3}
                    className={'text-left mb-4'}
                >
                    {t(
                        'media.we-will-help-you-header',
                        'Don’t worry. We will help you!',
                    )}
                </TextHeading>
                {steps?.map(({step, title, text}) => (
                    <div key={step} className={'mb-4'}>
                        <TextHeading
                            htmlTag={FontVariant.H5}
                            className={' text-left'}
                        >
                            {title}
                        </TextHeading>

                        {text}
                    </div>
                ))}
                <Button
                    rel="noopener noreferrer"
                    variant="primary"
                    className={'my-4'}
                    onClick={close}
                >
                    {t(
                        `media.help-check-${currentStepIndex}-again`,
                        `Try again`,
                    )}
                </Button>
                <Button
                    onClick={() => {
                        next();
                    }}
                    rel="noopener noreferrer"
                    variant="secondary"
                >
                    {t(
                        `media.proceed-without-${currentStepIndex}`,
                        `Proceed without ${PreflightJoinSteps[
                            currentStepIndex
                        ]?.toLowerCase()}`,
                    )}
                </Button>
            </List>
        </div>
    );
};
