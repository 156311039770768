import {isEmpty} from '@pexip/utils';

import type {RouteMatch, Url} from './url';
import type {
    Route,
    Routes,
    MatchedRoute,
    CreateElement,
    Element,
} from './types';
import {createPathMatch, toUrl, joinPath} from './url';
import {depthFirstSearch} from './utils';

/**
 * A recursive function complete the Route.path with basePath
 *
 * @param routes - A list of Routes
 * @param basePath - Parent path, default to '/'
 *
 * @returns A list of Route
 */
export const completeRoutePaths = <T>(
    routes: Routes<T>,
    basePath = '',
): Routes<T> => {
    return routes.map(route => {
        // Skip fallback route as it is not used for matching
        if (route.fallback) {
            return route;
        }
        const path =
            basePath && route.path
                ? joinPath(route.path, basePath)
                : route.path;
        return {
            ...route,
            path,
            children:
                route.children && completeRoutePaths(route.children, path),
        };
    });
};

/**
 * Route matching function
 *
 * @param routes - A list of routes with full path, e.g. "/foo/bar/:boo"
 * @param url - The patch to match with route's path
 *
 * @returns a tree of matched routes
 *
 * @remarks
 * When a route is defined with an empty string of the path, it is considered as
 * a fallback route and this fallback will not be used for route matching but
 * instead be used to be the return route when there is no any other routes
 * matched with the provided url
 */
export const matchRoutes = <T>(
    routes: Routes<T>,
    url: Url | string,
): MatchedRoute<T>[] => {
    const parsedUrl = toUrl(url);
    const matchPath = createPathMatch(parsedUrl.path);
    const fallbackRoute = routes.find(route => route.fallback);

    const matchedRoutes = routes.flatMap(route => {
        // Skip this route matching as it is considered as a fallback route
        if (route.fallback) {
            return [];
        }

        const {params, matched} = matchPath(route.path, {
            exact: Boolean(route.exact),
        });

        // If the parent does not match, there is no need to go deeper
        if (!matched) {
            return [];
        }

        const isExact = matchPath(route.path, {exact: true}).matched;
        const match: RouteMatch = {
            url: parsedUrl,
            params,
            exact: isExact,
            path: route.path,
            fallback: false,
        };

        // If there is no children or it is exact matched,
        // return the node with injected RouteMatch
        if (isExact || !route.children || !route.children.length) {
            return [{route, match}];
        }
        // continue to match the children with path
        return [
            {
                route,
                match,
                children: matchRoutes(route.children, url),
            },
        ];
    });

    if (matchedRoutes.length || !fallbackRoute) {
        return matchedRoutes;
    }

    // Return the fallback
    return [
        {
            route: fallbackRoute,
            match: {
                url: parsedUrl,
                fallback: true,
                params: {},
                exact: false,
                path: fallbackRoute.path,
            },
        },
    ];
};

/**
 * Create elements according to provided routes
 *
 * @param createElement - A function to construct element, e.g. React.createElement
 *
 * @returns a tree of matched elements
 */
export const createRouteElementCreator = <T, U extends Element>(
    createElement: CreateElement<T, U>,
) => {
    /**
     * Create elements according to provided routes
     *
     * @param routes - a list of matched routes
     *
     * @returns a tree of matched routes
     */
    const createElements = (routes: MatchedRoute<T>[]): U[] => {
        if (!routes.length) {
            return [];
        }
        const matchedElements = routes.map(route => {
            if (!route.children?.length) {
                return createElement(route);
            }
            return createElement(route, createElements(route.children));
        });
        return matchedElements;
    };
    return createElements;
};

/**
 * Find exact matched routes form provided matched routes
 *
 * @param matchedRoutes - A list of matched routes to be used for the searching
 *
 * @returns The first route found in the provided routes otherwise `undefined`
 * if it doesn't exist
 */
export const findExactMatched = <T>(
    matchedRoutes: MatchedRoute<T>[],
): MatchedRoute<T> | undefined => {
    return depthFirstSearch(
        matchedRoutes,
        (route: MatchedRoute<T>) => route.match.exact,
    );
};

/**
 * Find the route with provided path from the list of routes
 *
 * @param routes - A list of routes to be used for the searching
 * @param path - The path to look for
 */
export const findRoute = <T>(routes: Route<T>[], path: string) => {
    return depthFirstSearch(routes, (route: Route<T>) => route.path === path);
};

/**
 * Get subroutes from matched routes
 *
 * @param matchedRoutes - A list of matched routes
 * @param getSubroutes - A callback to be called to get subroutes with the path
 * from the matched routes
 *
 * @returns subroutes if any otherwise an empty array
 */
export const getMatchedSubroutes = <T>(
    matchedRoutes: MatchedRoute<T>[],
    getSubroutes: (parentPath: string) => Routes<T>[],
): Routes<T>[] => {
    if (isEmpty(matchedRoutes)) {
        return [];
    }
    return matchedRoutes.flatMap(matched => {
        const subs = getSubroutes(matched.route.path);
        return [
            ...subs,
            ...getMatchedSubroutes(matched.children ?? [], getSubroutes),
        ];
    });
};

/**
 * A matcher to find matched routes with the hint from provided subroutes
 *
 * @param getSubroutes - A function to get subroutes
 */
export const matchRoutesWithSubroutes =
    <T>(getSubroutes: (parentPath: string) => Routes<T>[]) =>
    /**
     * Matching main routes based on the provided url
     *
     * @param mainRoutes - A list of routes to look for
     * @param url - An url to match
     */
    (mainRoutes: Routes<T>, url: Url | string) => {
        const mainMatched = matchRoutes(mainRoutes, url);
        if (findExactMatched(mainMatched)) {
            return mainMatched;
        }
        const subroutes = getMatchedSubroutes(mainMatched, getSubroutes);
        if (isEmpty(subroutes)) {
            return mainMatched;
        }
        const someMatched = subroutes.some(routes => {
            const matched = matchRoutes(routes, url);
            return !!findExactMatched(matched);
        });
        const fallbackRoute = mainRoutes.find(route => route.fallback);
        if (someMatched || !fallbackRoute) {
            return mainMatched;
        }
        return matchRoutes([fallbackRoute], url);
    };
