import React, {useEffect, useState} from 'react';

import type {AudioQualityStats} from '@pexip/peer-connection-stats';
import type {Signal} from '@pexip/signal';

import {StatsModal} from '../views/StatsModal/StatsModal.view';
import type {NormalizedStats} from '../types';

import {AVQualityStats} from './AVQualityStats.viewModel';

export const Stats = ({
    statsSignal,
    callQualityStatsSignal,
    isOpen,
    onClose,
}: {
    statsSignal: Signal<NormalizedStats>;
    callQualityStatsSignal: Signal<{
        inbound: {audio: AudioQualityStats};
        outbound: {audio: AudioQualityStats};
    }>;
    isOpen?: boolean;
    onClose?: () => void;
}) => {
    const [stats, setStats] = useState<NormalizedStats>();

    useEffect(() => statsSignal.add(setStats), [statsSignal]);

    return (
        <StatsModal isOpen={isOpen} onClose={onClose} stats={stats}>
            <AVQualityStats callQualityStatsSignal={callQualityStatsSignal} />
        </StatsModal>
    );
};
