import type {Detach, Signal, ExtractSignalsState} from './types';
import {createSignal} from './signal';

export const combine = <T extends Signal<unknown>[]>(...signals: T) => {
    const latest = Array.from(
        signals,
        _ => undefined,
    ) as ExtractSignalsState<T>;

    let detachCombinedSignals: Detach[] = [];

    const attach = () => {
        detachCombinedSignals = signals.map((signal, i) =>
            signal.add(value => {
                latest[i] = value;
                combinedSignal.emit(latest);
            }),
        );
    };

    const detach = () => {
        detachCombinedSignals.forEach(detach => detach());
    };

    const combinedSignal = createSignal<ExtractSignalsState<T>>();
    const combinedSignalProxy = new Proxy(combinedSignal, {
        get: (target, p: keyof Signal<ExtractSignalsState<T>>, args) => {
            switch (p) {
                case 'add':
                    return (
                        ...args: Parameters<
                            Signal<ExtractSignalsState<T>>['add']
                        >
                    ) => {
                        const value = target[p](...args);
                        if (
                            detachCombinedSignals.length === 0 &&
                            combinedSignal.size > 0
                        ) {
                            attach();
                        }
                        return value;
                    };

                case 'remove':
                    return (
                        ...args: Parameters<
                            Signal<ExtractSignalsState<T>>['remove']
                        >
                    ) => {
                        const value = target[p](...args);
                        if (combinedSignal.size === 0) {
                            detach();
                        }
                        return value;
                    };

                default:
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return --- from lib.es2015 and log
                    return Reflect.get(target, p, args);
            }
        },
    });

    return combinedSignalProxy;
};
