import React, {useCallback} from 'react';

import {push} from '../router';
import {ExpressFlowView} from '../views/ExpressFlow/ExpressFlow.view';
import {FlowType} from '../types';

import {PreflightJoin} from './PreflightJoin.viewModel';
import {PreflightDeviceSelection} from './PreflightDeviceSelection.viewModel';

export const ExpressFlow: React.FC<{
    conferenceAlias: string;
}> = ({conferenceAlias}) => {
    const call = useCallback(() => {
        push(`/m/${conferenceAlias}`);
    }, [conferenceAlias]);

    return (
        <ExpressFlowView
            deviceSelectionComponent={
                <PreflightJoin flowType={FlowType.Express} call={call}>
                    <PreflightDeviceSelection />
                </PreflightJoin>
            }
        />
    );
};
