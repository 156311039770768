/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface NewCandidateMap {
    '200': Status200;
    '403': Status403;
    '502': string;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    result: null;
}
export interface Status403 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result: 'Invalid token';
}
export interface TopLevel {
    /**
     * Representation of address in candidate-attribute format as per RFC5245.
     */
    candidate: string;
    /**
     * The media stream identifier tag.
     */
    mid: string;
    /**
     * The randomly generated username fragment of the ICE credentials.
     */
    ufrag?: string;
    /**
     * The randomly generated password of the ICE credentials.
     */
    pwd?: string;
}

import {
    validateBody,
    validate200,
    validate403,
    validate502,
} from './newCandidateValidation';
type NewCandidateResponse =
    | {status: 200; data: NewCandidateMap['200']}
    | {status: 403; data: NewCandidateMap['403']}
    | {status: 502; data: NewCandidateMap['502']};
interface NewCandidateParams {
    conferenceAlias: string;
    participantUuid: string;
    callUuid: string;
}
export async function newCandidate({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: NewCandidateParams;
    body: NewCandidateMap['Body'];
    host: string;
}): Promise<NewCandidateResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/participants/${params.participantUuid}/calls/${params.callUuid}/new_candidate`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate403(data)) {
            throw new Error(JSON.stringify(validate403.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 502) {
        const data = await res.text();
        if (!validate502(data)) {
            throw new Error(JSON.stringify(validate502.errors));
        }

        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
