import {createSignal} from '@pexip/signal';
import log from '@pexip/logger';

export const logger = log.child({name: 'peer-connection'});

import type {
    LogFn,
    PeerConnection,
    ExtendedRTCPeerConnection,
    PeerConnectionSignals,
    PCSignals,
    GetSignalTypeFromInterface,
    PCRequiredSignals,
    PCOptionalsSignals,
    References,
} from './types';
import {hasICECandidates} from './sdpManager';

/**
 * create a logger with reference attached
 *
 * @param getRefs - A function to the references for logging
 */
export const createRefsLog = (
    getRefs: () => Record<string, unknown>,
): {
    debug: LogFn;
    info: LogFn;
    error: LogFn;
    warn: LogFn;
} => {
    const info = (obj?: Record<string, unknown>) => {
        const refs = getRefs();
        return {...refs, ...obj};
    };
    return {
        debug: (msg, obj) => logger.debug(info(obj), msg),
        info: (msg, obj) => logger.info(info(obj), msg),
        error: (msg, obj) => logger.error(info(obj), msg),
        warn: (msg, obj) => logger.warn(info(obj), msg),
    };
};

/**
 * Get the states from RTCPeerConnection
 *
 * @param pc - the peer connection to get the states
 */
export const getPeerConnectionStates = (
    pc: PeerConnection | ExtendedRTCPeerConnection,
) => ({
    get connectionState() {
        return pc.connectionState;
    },
    get iceConnectionState() {
        return pc.iceConnectionState;
    },
    get iceGatheringState() {
        return pc.iceGatheringState;
    },
    get signalingState() {
        return pc.signalingState;
    },
});

/**
 * Get common states and props from PeerConnection
 *
 * @param pc - The peer connection to get the info
 */
export const getStatesAndProps = (pc: PeerConnection) => {
    return {
        ...getPeerConnectionStates(pc),
        offerOptions: pc.offerOptions,
        answerOptions: pc.answerOptions,
    };
};

export const logReferences = (refs: References) => ({
    module: refs.module,
    references: refs,
});

export const createGetRefs = (pc: PeerConnection) => () => ({
    ...logReferences(pc.references),
    ...getStatesAndProps(pc),
});

/**
 * Compare the provided 2 streams to check if they are the same
 * @param stream1 - the stream to compare
 * @param stream2 - the stream to compare
 */
export const isSameStream = (stream1?: MediaStream, stream2?: MediaStream) => {
    return stream1 && stream2 && stream1.id === stream2.id;
};

/**
 * `ontrack` event handler
 *
 * @param event - the track event
 * @param currentRemoteStreams - current remote streams
 */
export const handleOnTrack = (
    event: RTCTrackEvent,
    currentRemoteStreams: MediaStream[],
): [MediaStream[], MediaStreamTrack[]] => {
    const remoteStreams = event.streams.filter(
        newStream =>
            !currentRemoteStreams.find(stream =>
                isSameStream(newStream, stream),
            ),
    );

    return [remoteStreams, [...(event.streams.length ? [] : [event.track])]];
};

/**
 * Wire the peer connection event with the pre-defined handler and signal
 * accordingly
 *
 * @param wireOptions - Wire event params
 * + `key` - event key
 * + `pc` - peer connection
 * + `signal` - the signal to wire
 */
export const wirePeerConnectionEventHandler = <
    T extends keyof PeerConnectionSignals,
>({
    key,
    pc,
    signal,
}: {
    key: T;
    pc: PeerConnection;
    signal: PeerConnectionSignals[T];
}) => {
    const log = createRefsLog(createGetRefs(pc));

    switch (key) {
        case 'onConnectionStateChange':
            pc.onConnectionStateChange = (event: Event) => {
                log.info('onConnectionStateChange emitted', {
                    event,
                });
                signal.emit(pc.connectionState);
            };
            break;
        case 'onDataChannel':
            pc.onDataChannel = (event: RTCDataChannelEvent) => {
                log.info('onDataChannel emitted', {
                    event,
                });
                signal.emit(event.channel);
            };
            break;
        case 'onIceCandidate':
            pc.onIceCandidate = (event: RTCPeerConnectionIceEvent) => {
                log.info('onIceCandidate emitted', {
                    event,
                });
                signal.emit(event.candidate);
            };
            break;
        case 'onIceCandidateError':
            pc.onIceCandidateError = (event: Event) => {
                log.info('onIceCandidateError emitted', {
                    event,
                });
                // HACK: Type changed with ts update. Is this safe?
                signal.emit(event as RTCPeerConnectionIceErrorEvent);
            };
            break;
        case 'onIceConnectionStateChange':
            pc.onIceConnectionStateChange = (event: Event) => {
                log.info('onIceConnectionStateChange emitted', {
                    event,
                });
                signal.emit(pc.iceConnectionState);
            };
            break;
        case 'onIceGatheringStateChange':
            pc.onIceGatheringStateChange = (event: Event) => {
                log.info('onIceGatheringStateChange emitted', {
                    event,
                });
                signal.emit(pc.iceGatheringState);
            };
            break;
        case 'onNegotiationNeeded':
            pc.onNegotiationNeeded = () => {
                log.info('onNegotiationNeeded emitted');
                signal.emit();
            };
            break;
        case 'onSignalingStateChange':
            pc.onSignalingStateChange = (event: Event) => {
                log.info('onSignalingStateChange emitted', {
                    event,
                });
                signal.emit(pc.signalingState);
            };
            break;
        case 'onTrack':
            pc.onTrack = (event: RTCTrackEvent) => {
                log.info('onTrack emitted', {
                    event,
                });
                signal.emit(event);
            };
            break;
        case 'onRemoteStreams':
            pc.onRemoteStreams = (streams: MediaStream[]) => {
                log.info('onRemoteStreams emitted', {
                    remoteStreams: streams,
                });
                signal.emit(streams);
            };
            break;
        case 'onTransceiverChange':
            pc.onTransceiverChange = () => {
                log.info('onTransceiverChanged');
                signal.emit();
            };
            break;
    }
};

/**
 * Wire the peer connection events with provided signals
 *
 * @param pc - peer connection
 * @param signals - the set of signals to wire
 */
export const wirePeerConnectionEvents = (
    pc: PeerConnection,
    signals: Partial<PeerConnectionSignals>,
) => {
    Object.keys(signals).forEach(eventKey => {
        const signalKey = eventKey as keyof PeerConnectionSignals;
        const signal = signals[signalKey];
        if (signal) {
            wirePeerConnectionEventHandler({
                key: signalKey,
                pc,
                signal,
            });
        }
    });
};

/**
 * Create a general signal with consistent scoped name
 *
 * @param name - Signal name
 * @param crucial - Signify if the signal is unmissable.
 */
export const createPCSignal = <T = undefined>(name: string, crucial = true) =>
    createSignal<T>({
        name: `call:peerConnection:${name}`,
        allowEmittingWithoutObserver: !crucial,
    });

export const REQUIRED_SIGNAL_KEYS = [
    'onOfferRequired',
    'onReceiveAnswer',
    'onReceiveOffer',
    'onOffer',
    'onAnswer',
    'onError',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for peer connection to work
 *
 * @param more - Keys from `PCOptionalsSignals`, @see PCOptionalsSignals
 * @param scope - any scope prefix for the generated signal name, @see Signal
 *
 * The following signals created by default
 *  'onOfferRequired',
 *  'onReceiveAnswer',
 *  'onReceiveOffer',
 *  'onOffer',
 *  'onAnswer',
 *  'onError',
 * @see REQUIRED_SIGNAL_KEYS
 */
export const createPCSignals = <K extends keyof PCOptionalsSignals>(
    more: K[],
    scope = '',
) => {
    const signalScope = scope && [scope, ':'].join('');

    type Signals = PCRequiredSignals & Required<PCOptionalsSignals>;
    type SignalKeys = typeof more[number] | typeof REQUIRED_SIGNAL_KEYS[number];

    return [...REQUIRED_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createPCSignal<
                GetSignalTypeFromInterface<Signals, typeof key>
            >(`${signalScope}${key}`),
        }),
        {} as Pick<Signals, SignalKeys>,
    );
};

/**
 * Handle some core signals for the peer connection.
 *
 * @param peer - The peer connection
 *
 * @returns the signal subscriptions which are needed to be called when closing
 * the peer connection
 */
export const withSignals =
    (peer: PeerConnection) =>
    /**
     * Map signals
     *
     * @param signals - The provided signals to map to the PC events
     */
    ({
        onReceiveAnswer,
        onReceiveIceCandidate,
        onReceiveOffer,
        onOffer,
        onAnswer,
        onError,
        onNegotiationNeeded = createPCSignal('onNegotiationNeeded'),
        onIceCandidateError = createPCSignal<RTCPeerConnectionIceErrorEvent>(
            'onIceCandidateError',
        ),
        onIceGatheringStateChange = createPCSignal<RTCIceGatheringState>(
            'onIceGatheringStateChange',
        ),
        ...pcEventSignals
    }: Omit<PCSignals, 'onOfferRequired'>) => {
        // Wire PeerConnection events to signals
        pcEventSignals &&
            wirePeerConnectionEvents(peer, {
                ...pcEventSignals,
                onNegotiationNeeded,
                onIceCandidateError,
                onIceGatheringStateChange,
            });

        const log = createRefsLog(createGetRefs(peer));
        let waitForICEGatheringComplete = false;

        // Log and emit Error
        const emitError: LogFn = (msg, context) => {
            log.error(msg, {...context, ...getPeerConnectionStates(peer)});
            if (context?.error instanceof Error) {
                onError.emit(context.error);
            }
        };

        const emitLocalDescription = (sdp?: RTCSessionDescriptionInit) => {
            const localDescription = sdp ?? peer.pendingLocalDescription;
            if (shouldEmitLocalDescription(localDescription)) {
                switch (localDescription?.type) {
                    case 'offer':
                        onOffer.emit(localDescription);
                        break;
                    case 'answer':
                        onAnswer.emit(localDescription);
                        break;
                    default:
                        log.error(
                            'Attempt to emit localDescription other than "answer" and "offer"',
                            {localDescription},
                        );
                        throw new Error('Unknown SDP type');
                }
            }
        };

        const shouldEmitLocalDescription = (
            localDescription: RTCSessionDescriptionInit | undefined,
        ) => {
            if (localDescription?.sdp) {
                if (
                    pcEventSignals.onIceCandidate ||
                    hasICECandidates(localDescription.sdp) ||
                    peer.iceGatheringState === 'complete'
                ) {
                    return true;
                } else {
                    waitForICEGatheringComplete = true;
                    return false;
                }
            }
            logger.error('Local Description or SDP inside is undefined', {
                localDescription,
            });
            return false;
        };

        const createOffer = async () => {
            try {
                const offer = await peer.createOffer();
                log.info('emit offer', {offer});
                emitLocalDescription(offer);
            } catch (error: unknown) {
                emitError('createOffer', {error});
            }
        };

        const responseOffer = async (offer: RTCSessionDescriptionInit) => {
            try {
                log.info('handle receiveOffer signal', {offer});
                await peer.receiveOffer(offer);
                const answer = await peer.createAnswer();
                emitLocalDescription(answer);
            } catch (error: unknown) {
                emitError('receiveOffer/createAnswer', {error, offer});
            }
        };

        const signalSubscriptions = [
            onIceGatheringStateChange.add(iceGatheringState => {
                if (
                    iceGatheringState === 'complete' &&
                    waitForICEGatheringComplete
                ) {
                    emitLocalDescription();
                    waitForICEGatheringComplete = false;
                }
            }),

            onNegotiationNeeded.add(() => {
                log.info('handle onNegotiationNeeded signal');
                void createOffer();
            }),

            onIceCandidateError.add(error => {
                emitError('onIceCandidateError', {
                    error: new Error(error.errorText),
                    event: error,
                });
            }),

            onReceiveIceCandidate?.add(candidate => {
                log.info('handle onReceiveIceCandidate signal', {candidate});
                peer.receiveIceCandidate(candidate).catch((error: unknown) => {
                    emitError('receiveIceCandidate', {error, candidate});
                });
            }),

            onReceiveOffer.add(offer => {
                void responseOffer(offer);
            }),

            onReceiveAnswer.add(answer => {
                log.info('handle receiveAnswer signal', {answer});
                peer.receiveAnswer(answer).catch((error: unknown) => {
                    emitError('receiveAnswer', {error, answer});
                });
            }),
        ].flatMap(a => (a ? [a] : []));
        return signalSubscriptions;
    };

/**
 *  workaround to allow echo cancellation in Chromium browsers, due to https://bugs.chromium.org/p/chromium/issues/detail?id=687574.
 *
 * based on https://dev.to/focusedlabs/echo-cancellation-with-web-audio-api-and-chromium-1f8m
 *  and https://gist.github.com/alexciarlillo/4b9f75516f93c10d7b39282d10cd17bc
 */
export const getCreateLoopbackConnectionFn =
    (
        rtcConnection = new RTCPeerConnection(),
        rtcLoopbackConnection = new RTCPeerConnection(),
        loopbackStream = new MediaStream(),
    ) =>
    async (stream: MediaStream) => {
        rtcConnection.onicecandidate = e =>
            e.candidate &&
            rtcLoopbackConnection.addIceCandidate(
                new RTCIceCandidate(e.candidate),
            );
        rtcLoopbackConnection.onicecandidate = e =>
            e.candidate &&
            rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

        rtcLoopbackConnection.ontrack = e => {
            if (e.streams[0]) {
                return e.streams[0]
                    .getTracks()
                    .forEach(track => loopbackStream.addTrack(track));
            }
        };

        stream.getTracks().forEach(function (track) {
            rtcConnection.addTrack(track, stream);
        });

        const offer = await rtcConnection.createOffer();
        await rtcConnection.setLocalDescription(offer);
        await rtcLoopbackConnection.setRemoteDescription(offer);

        const answer = await rtcLoopbackConnection.createAnswer();
        await rtcLoopbackConnection.setLocalDescription(answer);
        await rtcConnection.setRemoteDescription(answer);

        return loopbackStream;
    };
