import {useEffect, useState} from 'react';

export const useTabs = (activeTabIndex: number) => {
    const [activeTab, setActiveTab] = useState(activeTabIndex);

    useEffect(() => {
        setActiveTab(activeTab);
    }, [activeTab]);

    return {
        activeTab,
        setActiveTab,
    };
};
