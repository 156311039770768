import type {RenderEffects} from '@pexip/media-processor';

import {useConfig} from '../config';

const areEffectsApplied = (segmentationEffects: RenderEffects) =>
    segmentationEffects === 'blur' || segmentationEffects === 'overlay';

export const useEffectsApplied = () => {
    const [segmentationEffects] = useConfig('segmentationEffects');

    return areEffectsApplied(segmentationEffects);
};
