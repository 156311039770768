import React, {useMemo} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {
    ConfirmationModal,
    MultistepIndicator,
    useTouchDevice,
    useSmDown,
} from '@pexip/components';
import type {MultistepItem, MultistepIndicatorProps} from '@pexip/components';
import {UserMediaStatus} from '@pexip/media';

import {PermissionsRejected} from '../../views/PermissionsRejected/PermissionsRejected.view';
import {PreflightJoinSteps} from '../../enums';
import {GetStarted} from '../../views/GetStarted/GetStarted.view';
import {DeviceSelectionStep} from '../DeviceSelectionStep/DeviceSelectionStep.view';
import {TestId} from '../../../test/testIds';
import {StepByStepCard} from '../StepByStepCard/StepByStepCard.view';

import styles from './OneTimeDeviceSelection.module.scss';

const videoPermissionsRejected = [
    UserMediaStatus.PermissionsRejectedVideoInput,
    UserMediaStatus.PermissionsRejected,
];
const microphonePermissionsRejected = [
    UserMediaStatus.PermissionsRejectedAudioInput,
    UserMediaStatus.PermissionsRejected,
];

const calculateShowHelp = (
    currentStepIndex: number,
    streamStatus: UserMediaStatus,
) =>
    (videoPermissionsRejected.includes(streamStatus) &&
        currentStepIndex === PreflightJoinSteps.Camera) ||
    (microphonePermissionsRejected.includes(streamStatus) &&
        currentStepIndex === PreflightJoinSteps.Microphone)
        ? true
        : false;

export const OneTimeDeviceSelectionView: React.FC<{
    calculateNext: () => () => void;
    currentStepIndex: number;
    deviceSelectionSteps: React.ReactElement;
    hideHelpAndGoToNextStep: () => void;
    displayInnerStepper: boolean;
    isDisplayInnerStepperDesktop: boolean;
    moveToStep: MultistepIndicatorProps['onStepChange'];
    start: () => void;
    started: boolean;
    steps: MultistepItem[];
    streamStatus: UserMediaStatus;
    next: (next: PreflightJoinSteps) => () => void;
    titleTranslation: string;
    setShowPreflightTips: () => void;
    showPreflightTips: boolean;
    showAccessibilityTips: boolean;
    setShowProceedAnywayModal: (val: boolean) => void;
    showProceedAnywayModal: boolean;
    proceedAnywayModalContent: string;
    scrollToBottomSectionRef: React.RefObject<HTMLDivElement>;
    setShowPermissionsTipsScrollTrigger: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    tryAgain: () => void;
    closeAccessibilityTips: () => void;
    accessibilityTips: React.ReactElement;
}> = ({
    tryAgain,
    closeAccessibilityTips,
    calculateNext,
    currentStepIndex,
    deviceSelectionSteps,
    hideHelpAndGoToNextStep,
    displayInnerStepper,
    isDisplayInnerStepperDesktop,
    moveToStep,
    titleTranslation,
    start,
    started,
    steps,
    streamStatus,
    setShowPreflightTips,
    showPreflightTips,
    showAccessibilityTips,
    showProceedAnywayModal,
    setShowProceedAnywayModal,
    proceedAnywayModalContent,
    scrollToBottomSectionRef,
    setShowPermissionsTipsScrollTrigger,
    accessibilityTips,
}) => {
    const isTouchDevice = useTouchDevice();
    const isSmDown = useSmDown();
    const {t} = useTranslation();
    const shouldShowPermissionsRejectedHelp = useMemo(
        () => calculateShowHelp(currentStepIndex, streamStatus),
        [currentStepIndex, streamStatus],
    );

    const stepperVariant = !displayInnerStepper
        ? 'ordered-list'
        : isTouchDevice
        ? 'line'
        : 'ordered-list';
    const isLineStepper = stepperVariant === 'line';

    const stepper = (
        <MultistepIndicator
            steps={steps}
            currentStepIndex={currentStepIndex}
            onStepChange={moveToStep}
            className={cx(styles.stepper, {
                [styles.lineStepper]: isLineStepper,
                'mb-3': isDisplayInnerStepperDesktop,
            })}
            variant={stepperVariant}
            isStepNameHidden={isDisplayInnerStepperDesktop && isSmDown}
        />
    );

    return (
        <div className={styles.oneTimeDeviceSelection}>
            {currentStepIndex !== PreflightJoinSteps.ReadyToJoin &&
                started &&
                !displayInnerStepper && (
                    <div className={cx('mb-5', styles.ordinalStepperWrapper)}>
                        {stepper}
                    </div>
                )}

            {shouldShowPermissionsRejectedHelp ? (
                <StepByStepCard scrollableElementRef={scrollToBottomSectionRef}>
                    <PermissionsRejected
                        streamStatus={streamStatus}
                        currentStepIndex={currentStepIndex}
                        next={showPreflightTips ? undefined : calculateNext()}
                        setHideHelp={hideHelpAndGoToNextStep}
                        closeTips={setShowPreflightTips}
                        setScrollTrigger={setShowPermissionsTipsScrollTrigger}
                    />
                </StepByStepCard>
            ) : !started ? (
                <GetStarted start={start} className={styles.getStarted} />
            ) : (
                <>
                    {
                        <DeviceSelectionStep
                            stepper={stepper}
                            deviceSelectionStep={deviceSelectionSteps}
                            currentStepIndex={currentStepIndex}
                            titleTranslation={titleTranslation}
                            displayInnerStepper={displayInnerStepper}
                            hideHelpAndGoToNextStep={() =>
                                setShowProceedAnywayModal(true)
                            }
                            accessibilityTips={accessibilityTips}
                            tryAgain={tryAgain}
                            closeAccessibilityTips={closeAccessibilityTips}
                            showPreflightTips={showPreflightTips}
                            showAccessibilityTips={showAccessibilityTips}
                            scrollableElementRef={scrollToBottomSectionRef}
                        />
                    }
                </>
            )}

            <ConfirmationModal
                data-testid={TestId.OneTimeConfirmationModal}
                sizeModifier="medium"
                uniqueTitle={t('common.are-you-sure', 'Are you sure?')}
                withCloseButton
                cancelButtonText={t('common.cancel', 'Cancel')}
                cancelButtonTestid={TestId.ButtonOneTimeConfirmationModalNo}
                confirmButtonText={t('common.yes', 'Yes')}
                confirmButtonTestid={TestId.ButtonOneTimeConfirmationModalYes}
                description={proceedAnywayModalContent}
                isOpen={showProceedAnywayModal}
                onCancel={() => setShowProceedAnywayModal(false)}
                onConfirm={hideHelpAndGoToNextStep}
                title={t('common.are-you-sure', 'Are you sure?')}
            >
                {proceedAnywayModalContent}
            </ConfirmationModal>
        </div>
    );
};

export type OneTimeDeviceSelectionViewProps = React.ComponentProps<
    typeof OneTimeDeviceSelectionView
>;
