import React, {useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {AspectRatio, IconTypes} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {DeviceError} from '@pexip/media-components';
import {TestId as MediaTestId, DeviceSelect} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';
import {StepActions} from '../StepActions/StepActions.view';
import {StepLayout} from '../StepLayout/StepLayout.view';

import styles from './SpeakerStep.module.scss';

const defaultWrapperMargin = 'mb-3';

export const SpeakerStep: React.FC<
    React.PropsWithChildren<{
        device?: MediaDeviceInfoLike;
        deviceError: DeviceError;
        devices: MediaDeviceInfoLike[];
        help: () => void;
        isTouchDevice?: boolean;
        no: () => void;
        onDeviceChange: (device: MediaDeviceInfoLike) => void;
        yes: () => void;
        accessibilityHelpExpanded: boolean;
    }>
> = ({
    children,
    device,
    deviceError,
    devices,
    isTouchDevice,
    no,
    onDeviceChange,
    yes,
    help,
    accessibilityHelpExpanded,
}) => {
    const {t} = useTranslation();

    const isSelectSupported = devices.length > 0 && !deviceError?.title;

    const deviceSelectWrapper = useMemo(
        () =>
            isTouchDevice
                ? defaultWrapperMargin
                : isSelectSupported
                ? defaultWrapperMargin
                : styles.wrapper,
        [isTouchDevice, isSelectSupported],
    );

    return (
        <StepLayout
            startContent={
                <>
                    <div className={deviceSelectWrapper}>
                        <AspectRatio>{children}</AspectRatio>
                    </div>
                    {isSelectSupported && !isTouchDevice && (
                        <DeviceSelect
                            isDisabled={false}
                            label={t(
                                'settings.device-select.select-speaker',
                                'Select speaker',
                            )}
                            data-testid={MediaTestId.SelectAudioOutput}
                            onDeviceChange={onDeviceChange}
                            iconType={IconTypes.IconSpeakerOn}
                            mediaDeviceInfoLike={device}
                            devices={devices}
                        />
                    )}
                </>
            }
            endContent={
                <StepActions
                    yes={yes}
                    no={no}
                    help={help}
                    headerTranslation={
                        <Trans t={t} i18nKey="speaker.do-you-hear-sound">
                            Do you hear the sound?
                        </Trans>
                    }
                    headerTestid={TestId.StepHeaderSpeaker}
                    helpButtonTranslation={
                        <Trans t={t} i18nKey="speaker.cant-hear">
                            I can&apos;t hear due to a disability
                        </Trans>
                    }
                    noTestId={TestId.ButtonSpeakerStepNo}
                    yesTestId={TestId.ButtonSpeakerStepYes}
                    helpTestId={TestId.ButtonSpeakerStepCantHear}
                    accessibilityHelpExpanded={accessibilityHelpExpanded}
                />
            }
        />
    );
};

export type SpeakerStepProps = React.ComponentProps<typeof SpeakerStep>;
