import React from 'react';
import {useTranslation} from 'react-i18next';

import {NotificationTooltip} from '@pexip/components';

import type {DeviceStatusInfo} from '../../types';

import {InputControl} from './InputControl.view';

export type TooltipPosition =
    | 'topLeft'
    | 'topRight'
    | 'topCenter'
    | 'bottomRight'
    | 'bottomCenter'
    | 'bottomLeft'
    | undefined;

export const PreflightInputControl: React.FC<
    React.ComponentProps<typeof InputControl> & {
        className?: string;
        inputStatusInfo: DeviceStatusInfo;
        isTooltipVisible: boolean;
        toolTipTestId?: string;
        setIsTooltipVisible: (value: React.SetStateAction<boolean>) => void;
        shouldShowRetry: boolean;
        testIdCloseButton?: string;
        requestMediaPermissions: () => void;
        audioInputControl?: React.ReactNode;
        tooltipPosition?: TooltipPosition;
    }
> = ({
    inputStatusInfo,
    isTooltipVisible,
    toolTipTestId,
    setIsTooltipVisible,
    shouldShowRetry,
    testIdCloseButton,
    requestMediaPermissions,
    tooltipPosition = 'topLeft',
    audioInputControl,
    ...props
}) => {
    const {t} = useTranslation();

    return (
        <NotificationTooltip
            controlledIsVisible={isTooltipVisible}
            controlledOnChange={setIsTooltipVisible}
            ctaFn={shouldShowRetry ? requestMediaPermissions : undefined}
            ctaText={shouldShowRetry ? t('common.retry', 'Retry') : undefined}
            header={inputStatusInfo.title}
            position={tooltipPosition}
            testIdCloseButton={testIdCloseButton}
            testIdInner={toolTipTestId}
            text={inputStatusInfo.message}
            closeButtonAriaLabel={t('common.close-tooltip', 'Close tooltip')}
        >
            {audioInputControl ? (
                audioInputControl
            ) : (
                <InputControl variant="tertiary" {...props} />
            )}
        </NotificationTooltip>
    );
};
