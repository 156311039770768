import {isArray, isRecord, isString, isBoolean, isNumber} from './types';
import {mergeSortArray} from './mergeSortArray';
import {logger} from './logger';

const selector =
    <T>(select: 'string' | 'boolean' | 'number', defaultValue: T) =>
    (...args: (boolean | string | number | undefined | null)[]): T => {
        for (const arg of args) {
            if (typeof arg === select) {
                // arg must be string, boolean or number here, and it is up to us to match defaultValue to either
                return arg as unknown as T;
            }
        }
        return defaultValue;
    };

const paramToBoolean = (param: string | string[] | null) =>
    isString(param) ? param === 'true' || param === '' : null;
const paramToNumber = (param: string | string[] | null) =>
    isString(param)
        ? !isNaN(Number(param)) && !isNaN(parseFloat(param))
            ? Number(param)
            : null
        : null;

const boolOrNull = (input: unknown) => (isBoolean(input) ? input : null);
const strOrNull = (input: unknown) => (isString(input) ? input : null);
const numberOrNull = (input: unknown) => (isNumber(input) ? input : null);
const recordOrNull = (input: unknown) => (isRecord(input) ? input : null);

/**
 * Takes a generic `Config` object and returns a function to extract values from overrides, URL and localStorage.
 * The returned function expects and object with these properties:
 *
 * key: The name of the property from the base config.
 *
 * local: The value selected from localstorage, this should already have been parsed by JSON.parse and type should satisfy Config[T]
 *
 * override: The value selected from the overrides object, which should be Partial<Config> and the Config[T] should have the same type already
 *
 * params: Either a string or null as returned by URLSearchParams.get, or a string[] as returned by URLSearchParams.getAll, these will be converted to the correct type in this function
 */
export const extractor =
    <Config>(config: Config) =>
    <T extends keyof Config>({
        key,
        local,
        override,
        params,
    }: {
        key: T;
        local: Config[T] | null;
        override?: Config[T];
        params: string | string[] | null;
    }): Config[T] => {
        const value = config[key];

        if (isArray(value)) {
            return mergeSortArray(
                value,
                isArray(local) ? local : null,
                isArray(override) ? override : null,
                params,
            ) as unknown as Config[T];
        }

        if (isBoolean(value)) {
            return selector('boolean', value)(
                paramToBoolean(params),
                boolOrNull(override),
                boolOrNull(local),
            );
        }

        if (isString(value)) {
            return selector('string', value)(
                strOrNull(params),
                strOrNull(override),
                strOrNull(local),
            );
        }

        if (isNumber(value)) {
            return selector('number', value)(
                paramToNumber(params),
                numberOrNull(override),
                numberOrNull(local),
            );
        }

        if (isRecord(value)) {
            let param: Config[T] | null = null;
            try {
                param = isString(params)
                    ? (JSON.parse(params) as Config[T])
                    : null;
            } catch (error: unknown) {
                logger.error({error});
            }

            return {
                ...value,
                ...recordOrNull(local),
                ...override,
                ...recordOrNull(param),
            };
        }

        // I don't think we'll ever get this far, but to satisfy Typescript... -ea
        return value;
    };
