import React from 'react';

import type {AnalyzerNodeInit} from '@pexip/media-processor';
import type {UserMediaStatus} from '@pexip/media';
import {
    AudioMeterButton,
    useAudioMeter,
    useInputsState,
    useDeviceStatusInfo,
} from '@pexip/media-components';

import {TestId} from '../../test/testIds';

export const AudioMeter: React.FC<{
    analyzer: AnalyzerNodeInit;
    miniButtons?: boolean;
    level?: number;
    isAudioInputMuted: boolean;
    onClick: () => void;
    streamStatus: UserMediaStatus | undefined;
    isMobileDevice?: boolean;
}> = ({
    analyzer,
    miniButtons,
    level,
    isAudioInputMuted,
    isMobileDevice,
    onClick,
    streamStatus,
}) => {
    const inputStatusInfo = useDeviceStatusInfo(streamStatus);

    const {audioProps} = useInputsState({
        isAudioInputMuted,
        isVideoInputMuted: false,
        inputStatusInfo,
        isMobileDevice: isMobileDevice || false,
    });

    const max = useAudioMeter(analyzer);

    return (
        <AudioMeterButton
            {...audioProps}
            miniButtons={miniButtons}
            isActive={!audioProps.isActive}
            level={level ? level : max}
            data-testid={
                isAudioInputMuted
                    ? TestId.ButtonAudioMeterMuted
                    : TestId.ButtonAudioMeter
            }
            onClick={onClick}
            isMobileDevice={isMobileDevice}
        />
    );
};
