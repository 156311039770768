import React from 'react';

import {ThemeProvider} from '@pexip/components';

import type {FlowType} from '../types';

import {PreflightMedia} from './PreflightMedia.viewModel';
import {JoinMeeting} from './JoinMeeting.viewModel';

export const PreflightJoin: React.FC<
    React.PropsWithChildren<{
        call: () => void;
        className?: string;
        flowType: FlowType;
    }>
> = ({call, children, flowType, ...props}) => (
    <>
        <ThemeProvider colorScheme="dark">
            <PreflightMedia flowType={flowType} />
        </ThemeProvider>
        {children}
        <JoinMeeting call={call} flowType={flowType} {...props} />
    </>
);
