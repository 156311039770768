import {useCallback, useEffect, useState} from 'react';

import {useSizeObserver} from '@pexip/hooks';

import {isPortrait} from '../utils/isMobileDevice';

export type Orientation = 'portrait' | 'landscape';

const getWindowOrientation = (width: number, height: number): Orientation =>
    isPortrait(width, height) ? 'portrait' : 'landscape';

const initialWindowOrientation = getWindowOrientation(
    window.innerWidth,
    window.innerHeight,
);

export const useWindowOrientation = () => {
    const [orientation, setOrientation] = useState<Orientation>(
        initialWindowOrientation,
    );

    const size = useSizeObserver(document.body);

    const updateOrientation = useCallback(() => {
        setOrientation(
            getWindowOrientation(window.innerWidth, window.innerHeight),
        );
    }, []);

    useEffect(() => {
        window.addEventListener('orientationchange', updateOrientation);

        return () => {
            window.removeEventListener('orientationchange', updateOrientation);
        };
    }, [updateOrientation]);

    useEffect(() => {
        updateOrientation();
    }, [size, updateOrientation]);

    return {
        isPortrait: orientation === 'portrait',
        isLandscape: orientation === 'landscape',
    };
};
