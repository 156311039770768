import type {Signal} from '@pexip/signal';
import {createSignal} from '@pexip/signal';

import {
    getTypeOf,
    isValid,
    isArray,
    isString,
    isRecord,
    isBoolean,
    isNumber,
} from './types';
import {deleteItem, getItem, setItem} from './localStorage';
import {extractor} from './extractor';
import {logger} from './logger';

const clone = <Config>(config: Config) =>
    Object.entries(config).reduce((acc, [key, value]) => {
        if (isArray(value)) {
            return {...acc, [key]: [...value]};
        }
        if (isRecord(value)) {
            return {...acc, [key]: {...value}};
        }
        if (isString(value) || isBoolean(value) || isNumber(value)) {
            return {...acc, [key]: value};
        }
        return acc;
    }, {} as Config);

export class ConfigManager<Config> {
    private loaded = false;
    private currentConfig: Config;
    private signals: {
        [T in keyof Config]?: Signal<Config[T]>;
    } = {};

    constructor(private config: Config, private namespace: string) {
        // Use a copy to freely mutate while still retaining posibility to reset to values from `config`.
        this.currentConfig = clone(config);

        // Runtime validation that all the values is something we can infer
        for (const key in config) {
            if (
                !Object.hasOwnProperty.call(config, key) ||
                !isValid(config[key])
            ) {
                throw new TypeError(
                    `Invalid value for "${key}", must be of 'Valid | Record<string, Valid> where Valid = string | string[] | boolean | number', got "${getTypeOf(
                        config[key],
                    )}"`,
                );
            }
        }
    }

    get<T extends keyof Config>(key: T): Config[T] {
        this.isLoaded();
        return this.currentConfig[key];
    }

    set<T extends keyof Config>({
        key,
        value,
        persist = false,
        emit = true,
    }: {
        key: T;
        value: Config[T];
        persist?: boolean;
        emit?: boolean;
    }) {
        this.isLoaded();
        this.currentConfig[key] = value;
        // HACK: This is not ideal, but couldn't immediatly think of a better way when keeping a redux store in sync.
        if (emit) {
            this.signals[key]?.emit(value);
        }
        if (persist) {
            setItem(this.keyToNamespacedString(key), JSON.stringify(value));
        }
    }

    delete<T extends keyof Config>(key: T) {
        this.isLoaded();
        // reset to default
        this.currentConfig[key] = this.config[key];
        // delete from peristant storage
        deleteItem(this.keyToNamespacedString(key));
    }

    /**
     * Config should be overwritten by "higher" priority levels, arrays and records should be merged.
     *
     * 1. Config - the one for the constructor which sets default values and types
     * 2. LocalStorage - Values we have persisted for the user for use on next visit
     * 3. overrides - passed in, but could be conceived to come from CLI arguments or a file on disk in a desktop app
     * 4. URL - Values from searchParams
     *
     * We check 2-4 for valid values before updating the local mutable config
     */
    load<T extends keyof Config>(
        overrides: Partial<Config>,
        loadConfigFromQuery = true,
    ) {
        if (this.loaded) {
            throw new Error('config has already been loaded');
        }
        this.loaded = true;
        // load config into extractor function for typing and fallback values
        const extractValue = extractor(this.config);
        // extract params from url to pass in values to the extractor
        const {searchParams} = new URL(window.location.href);
        // loop over all the config keys to extract values
        (Object.keys(this.config) as T[]).forEach(key => {
            const value = extractValue({
                key,
                local: this.selectFromLocalStorage(key),
                override: overrides[key],
                params: loadConfigFromQuery
                    ? isArray(this.config[key])
                        ? searchParams.getAll(String(key))
                        : searchParams.get(String(key))
                    : null,
            });
            this.set({key, value});
        });

        return clone(this.currentConfig);
    }

    subscribe<T extends keyof Config>(
        key: T,
        subscriber: (data: Config[T]) => void,
    ) {
        let signal = this.signals[key];
        if (!signal) {
            signal = createSignal<Config[T]>({
                name: `config:${String(key)}`,
            });
            this.signals[key] = signal;
        }
        return signal.add(subscriber);
    }

    /**
     * This fn is based on assumption that if value is not in the localstorage means that user didnt override the defaults.
     * This fns only really work on the runtime as if user change values after without saving it to local storage we will get
     * false positives.
     */
    isDefaultValue<T extends keyof Config>(key: T): boolean {
        return this.selectFromLocalStorage(key) === null;
    }

    private isLoaded() {
        if (!this.loaded) {
            throw new Error(
                'Do not use config before loading, to ensure localeStorage and overrides are set',
            );
        }
    }

    private keyToNamespacedString<T extends keyof Config>(key: T) {
        return `${this.namespace}:${String(key)}`;
    }

    private selectFromLocalStorage<T extends keyof Config>(
        key: T,
    ): Config[T] | null {
        const local = getItem(this.keyToNamespacedString(key)) as
            | Config[T]
            | null;
        if (isString(local)) {
            try {
                const parsed = JSON.parse(local) as unknown as Config[T];
                if (isValid(parsed)) {
                    return parsed;
                }
            } catch (error: unknown) {
                logger.error({error});
            }
        }
        return local;
    }
}
