/**
 * The type to describe the media type of the Transceiver
 */
export const TRANSCEIVER_MEDIA_TYPES = ['audio', 'video'] as const;
export type TransceiverMediaType = typeof TRANSCEIVER_MEDIA_TYPES[number];

/**
 * The type to describe the content of the Transceiver
 */
export const TRANSCEIVER_CONTENT_TYPES = ['main', 'preso'] as const;
export type TransceiverContentType = typeof TRANSCEIVER_CONTENT_TYPES[number];
