/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface ConferenceStatusMap {
    '200': Status200;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    result: Result;
}
export interface Result {
    /**
     * Lock status of the conference
     */
    locked: boolean;
    /**
     * Whether Guests are muted
     */
    guests_muted: boolean;
    /**
     * If the conference has been started
     */
    started: boolean;
    /**
     * If live captions is available for the conference
     */
    live_captions_available?: boolean;
    direct_media?: boolean;
    all_muted?: boolean;
    presentation_allowed?: boolean;
}

import {validate200} from './conferenceStatusValidation';
type ConferenceStatusResponse = {status: 200; data: ConferenceStatusMap['200']};
interface ConferenceStatusParams {
    conferenceAlias: string;
}
export async function conferenceStatus({
    fetcher,
    params,
    host,
}: {
    fetcher: typeof fetch;
    params: ConferenceStatusParams;
    host: string;
}): Promise<ConferenceStatusResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/conference_status`,
        {
            method: 'get',
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
