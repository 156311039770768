import React from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {
    Bar,
    Button,
    Icon,
    IconTypes,
    Row,
    TextHeading,
} from '@pexip/components';

import styles from './StepActions.module.scss';

export const StepActions: React.FC<{
    no: () => void;
    yes: () => void;
    help: () => void;
    headerTranslation: React.ReactNode;
    headerTestid: string;
    helpButtonTranslation: React.ReactNode;
    noTestId: string;
    yesTestId: string;
    helpTestId: string;
    accessibilityHelpExpanded: boolean;
}> = ({
    yes,
    no,
    help,
    headerTranslation,
    headerTestid,
    helpButtonTranslation,
    noTestId,
    yesTestId,
    helpTestId,
    accessibilityHelpExpanded,
}) => {
    const {t} = useTranslation();

    return (
        <>
            <Row
                className={cx(
                    'justify-content-center align-items-center p-0 mt-4',
                    styles.headerRow,
                )}
            >
                <TextHeading
                    htmlTag="h3"
                    className="text-center"
                    data-testid={headerTestid}
                >
                    {headerTranslation}
                </TextHeading>
            </Row>
            <Bar className="mt-1">
                <Button
                    className="mr-1"
                    modifier="fullWidth"
                    onClick={no}
                    data-testid={noTestId}
                >
                    <Trans t={t} i18nKey="common.no">
                        No
                    </Trans>
                </Button>
                <Button
                    className="ml-1"
                    modifier="fullWidth"
                    onClick={yes}
                    data-testid={yesTestId}
                >
                    <Trans t={t} i18nKey="common.yes">
                        Yes
                    </Trans>
                </Button>
            </Bar>
            <Button
                useTextComponent
                modifier="fullWidth"
                variant="text"
                size="compact"
                className={cx('mt-5', styles.accessibilityButton)}
                onClick={help}
                data-testid={helpTestId}
                enhancerEnd={
                    <Icon
                        source={
                            accessibilityHelpExpanded
                                ? IconTypes.IconChevronUp
                                : IconTypes.IconChevronDown
                        }
                    />
                }
            >
                {helpButtonTranslation}
            </Button>
        </>
    );
};
