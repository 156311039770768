import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Modal, Tab, Tabs, ThemeProvider} from '@pexip/components';
import type {VideoLayoutOptionValues} from '@pexip/media-components';
import {InMeetingUIElements, MeetingLayout} from '@pexip/media-components';
import type {Layout} from '@pexip/infinity';

import {usePreviewController} from '../services/Media.service';
import {TestId} from '../../test/testIds';
import {useMe} from '../hooks/useMe';
import {useConfig} from '../config';
import {MeetingSettings} from '../views/MeetingSettings/MeetingSettings.view';
import {useConferenceStatus} from '../hooks/useConferenceStatus';
import {enableInMeetingUIAutoHide} from '../signals/InMeeting.signals';
import {infinityService} from '../services/InfinityClient.service';

import {MediaControl} from './MediaControl.viewModel';
import {Effects} from './Effects.viewModel';

enum ConfirmationModalStates {
    Disconnect = 'DISCONNECT',
    Layout = 'LAYOUT',
    None = 'NONE',
}

export const InMeetingSettings: React.FC<{
    activeTab: number;
    closeModal: () => void;
    disconnectAll: () => void;
    isOpen: boolean;
    lock: (lock: boolean) => void;
    currentHostLayout: Layout;
    setLayout: (layout: Layout) => void;
}> = ({
    activeTab,
    closeModal,
    disconnectAll,
    isOpen,
    lock,
    currentHostLayout,
    setLayout,
}) => {
    const {t} = useTranslation();

    const [
        showLayoutChangeConfirmationModal,
        setShowLayoutChangeConfirmationModal,
    ] = useConfig('showLayoutChangeConfirmationModal');
    const [videoProcessingEnabled] = useConfig('videoProcessing');

    const me = useMe();
    const controller = usePreviewController(isOpen);
    const conferenceStatus = useConferenceStatus();

    useEffect(() => {
        enableInMeetingUIAutoHide.emit({
            type: InMeetingUIElements.settingModal,
            isOpen,
        });
    }, [isOpen]);

    const handleSetLayout = useCallback(
        (layoutSelection: VideoLayoutOptionValues) => {
            setLayout(layoutSelection as unknown as Layout);
        },
        [setLayout],
    );

    const toggleLock = useCallback(() => {
        lock(!conferenceStatus?.locked);
    }, [lock, conferenceStatus?.locked]);

    const closeConfirmationModal = useCallback(() => {
        setConfirmationState(ConfirmationModalStates.None);
    }, []);

    const [confirmationState, setConfirmationState] = useState(
        ConfirmationModalStates.None,
    );

    return (
        <Modal
            data-testid={TestId.ModalSettings}
            isOpen={isOpen}
            onClose={closeModal}
            closeOnOutsideClick={
                confirmationState === ConfirmationModalStates.None
            }
            padding="none"
            sizeModifier="medium"
            uniqueTitle="Settings"
        >
            <ThemeProvider colorScheme="light">
                <Tabs initialActiveTabIndex={activeTab}>
                    <Tab title={t('settings.personal', 'Personal settings')}>
                        {controller && (
                            <MediaControl
                                controller={controller}
                                closeModal={closeModal}
                            />
                        )}
                    </Tab>
                    {videoProcessingEnabled && (
                        <Tab title={t('settings.effects', 'Effects')}>
                            {controller && (
                                <Effects
                                    controller={controller}
                                    closeModal={closeModal}
                                />
                            )}
                        </Tab>
                    )}
                    {Boolean(me?.canControl) &&
                        !infinityService.conferenceFeatureFlags
                            ?.isDirectMedia && (
                            <Tab
                                title={t(
                                    'settings.meeting-layout',
                                    'Meeting layout',
                                )}
                            >
                                <MeetingLayout
                                    closeConfirmation={closeConfirmationModal}
                                    closeModal={closeModal}
                                    openConfirmation={() =>
                                        setConfirmationState(
                                            ConfirmationModalStates.Layout,
                                        )
                                    }
                                    currentLayout={
                                        currentHostLayout as VideoLayoutOptionValues
                                    }
                                    setLayout={handleSetLayout}
                                    setShowLayoutChangeConfirmationModal={
                                        setShowLayoutChangeConfirmationModal
                                    }
                                    showLayoutChangeConfirmationModal={
                                        showLayoutChangeConfirmationModal
                                    }
                                    isConfirmationOpen={
                                        confirmationState ===
                                        ConfirmationModalStates.Layout
                                    }
                                />
                            </Tab>
                        )}
                    {Boolean(me?.canControl) && (
                        <Tab
                            title={t(
                                'settings.meeting-settings',
                                'Meeting settings',
                            )}
                        >
                            <MeetingSettings
                                closeConfirmation={closeConfirmationModal}
                                closeModal={closeModal}
                                openConfirmation={() =>
                                    setConfirmationState(
                                        ConfirmationModalStates.Disconnect,
                                    )
                                }
                                toggleLock={toggleLock}
                                disconnectAll={disconnectAll}
                                isLocked={Boolean(conferenceStatus?.locked)}
                                isConfirmationOpen={
                                    confirmationState ===
                                    ConfirmationModalStates.Disconnect
                                }
                            />
                        </Tab>
                    )}
                </Tabs>
            </ThemeProvider>
        </Modal>
    );
};
