import {endsWithAnyOf, getBaseURI} from '@pexip/utils';

import {EXPRESS_PATH, STEP_BY_STEP_PATH} from '../constants';

export const isAppEntryUrl = (
    url: string,
    node: {baseURI: string} = document,
) => {
    const searchStrings = [EXPRESS_PATH, STEP_BY_STEP_PATH];
    const base = getBaseURI(node);
    if (base !== '/') {
        searchStrings.push(base);
    }
    return endsWithAnyOf(url, searchStrings);
};
