import React from 'react';

import {useTouchDevice} from '@pexip/components';
import {
    onDeviceSelectChange,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    useMediaInputs,
} from '@pexip/media-components';
import type {Media, PreviewInput, UserMediaStatus} from '@pexip/media';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {findVideoInputDevices} from '@pexip/media-control';

import {CameraStep} from '../views/CameraStep/CameraStep.view';
import {mediaService} from '../services/Media.service';
import {logger} from '../logger';
import {config} from '../config';
import {useToggleFaceMode} from '../hooks/useToggleFaceMode';

export const Camera: React.FC<{
    devices: MediaDeviceInfoLike[];
    media: Media;
    next: () => void;
    streamStatus: UserMediaStatus;
    no: () => void;
    help: () => void;
    accessibilityHelpExpanded: boolean;
}> = ({
    devices,
    media,
    next,
    streamStatus,
    no,
    help,
    accessibilityHelpExpanded,
}) => {
    const {selectedAudioInput, selectedVideoInput} = useMediaInputs(media);
    const {videoInputError, setVideoInputError, setAudioInputError} =
        useDeviceErrorMessageState();

    const [isMirrored, toggleFacingMode] = useToggleFaceMode();

    useDeviceErrorMessage(setAudioInputError, setVideoInputError, streamStatus);

    const isTouchDevice = useTouchDevice();

    const onVideoInputChange = onDeviceSelectChange(
        setVideoInputError,
        (input: PreviewInput) => {
            const {videoInput: prevVideoInput} = mediaService.media;
            if (input?.deviceId === prevVideoInput?.deviceId) {
                return;
            }
            const constraints = {
                audio: selectedAudioInput,
                video: {device: {exact: input}},
            };
            logger.debug(constraints, 'Save VideoInput');
            config.set({key: 'videoInput', value: input, persist: true});

            mediaService.getUserMedia(constraints);
        },
    );

    return (
        <CameraStep
            device={selectedVideoInput}
            deviceError={videoInputError}
            devices={findVideoInputDevices(devices)}
            help={help}
            no={no}
            onDeviceChange={onVideoInputChange}
            stream={media?.stream}
            streamStatus={streamStatus}
            yes={next}
            isTouchDevice={isTouchDevice}
            accessibilityHelpExpanded={accessibilityHelpExpanded}
            toggleFacingMode={toggleFacingMode}
            isMirrored={isMirrored}
        />
    );
};
