import React, {useEffect} from 'react';

import type {InMeetingUI} from '@pexip/media-components';
import {
    InMeetingUIElements,
    ChatPanelToggleView,
    useBadgeCounter,
} from '@pexip/media-components';
import type {ButtonProps} from '@pexip/components';

import {useChatMessages} from '../hooks/useChatMessages';
import {enableInMeetingUIAutoHide} from '../signals/InMeeting.signals';

export const ChatPanelToggle: React.FC<
    ButtonProps & {inMeetingUI: InMeetingUI}
> = ({inMeetingUI, ...props}) => {
    const [messages] = useChatMessages();
    const [newMessagesCount, setNewMessagesCount] = useBadgeCounter(messages);

    useEffect(() => {
        if (!inMeetingUI.panelsState.openChatPanel) {
            setNewMessagesCount(0);
        }
    }, [inMeetingUI.panelsState.openChatPanel, setNewMessagesCount]);

    useEffect(
        () =>
            enableInMeetingUIAutoHide.emit({
                type: InMeetingUIElements.unreadChatMessages,
                isOpen: newMessagesCount !== 0,
            }),
        [newMessagesCount],
    );

    return (
        <ChatPanelToggleView
            isPanelOpen={inMeetingUI.panelsState.openChatPanel}
            newMessagesCount={newMessagesCount}
            onClick={() => inMeetingUI.togglePanels('chat')}
            {...props}
            {...inMeetingUI.autoHideProps}
        />
    );
};
