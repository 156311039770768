import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps, ButtonVariant} from '@pexip/components';
import {Tooltip, Button, Icon, IconTypes} from '@pexip/components';

export const InputControl: React.FC<
    ButtonProps & {
        'aria-label': string;
        hasAudioVideoError?: boolean;
        hasError?: boolean;
        icon: React.ReactNode;
        variant?: ButtonVariant;
    }
> = ({
    hasAudioVideoError,
    hasError,
    icon,
    variant: baseVariant = 'translucent',
    isActive,
    ...props
}) => {
    const variant = hasAudioVideoError || hasError ? 'danger' : baseVariant;

    return (
        <Button
            modifier="square"
            size="medium"
            isActive={isActive}
            variant={variant}
            {...props}
        >
            {icon}
        </Button>
    );
};

export const BackgroundBlurInputControl: React.FC<
    ButtonProps & {
        iconSize: React.ComponentProps<typeof Icon>['size'];
        isTooltipVisible?: boolean;
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
    }
> = ({iconSize, isTooltipVisible, tooltipPosition = 'top', ...props}) => {
    const {t} = useTranslation();

    const backgroundEffectLabel = t(
        'media.background-effects-action',
        'Change background effect',
    );

    const control = (
        <InputControl
            {...props}
            aria-label={backgroundEffectLabel}
            icon={
                <Icon source={IconTypes.IconBackgroundBlur} size={iconSize} />
            }
        />
    );

    return isTooltipVisible ? (
        <Tooltip text={backgroundEffectLabel} position={tooltipPosition}>
            {control}
        </Tooltip>
    ) : (
        control
    );
};
