import React, {useEffect, useState} from 'react';

import type {DeviceError} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {AccessibilityDeviceDetectionView} from '../views/AccessibilityDeviceDetection/AccessibilityDeviceDetection.view';
import {config} from '../config';

export const AccessibilityDeviceDetection: React.FC<{
    currentStepIndex: number;
    next: () => () => void;
    devices: MediaDeviceInfoLike[];
    deviceError: DeviceError;
}> = ({currentStepIndex, next, devices, deviceError}) => {
    const [prefersLiveCaptionsOn, setPrefersLiveCaptionsOn] = useState(
        config.get('prefersLiveCaptions'),
    );

    const handleSwitchToggle = () => {
        setPrefersLiveCaptionsOn(prevVal => !prevVal);
    };

    const isSelectSupported = devices.length > 0 && !deviceError?.title;

    useEffect(() => {
        if (config.get('prefersLiveCaptions') !== prefersLiveCaptionsOn) {
            config.set({
                key: 'prefersLiveCaptions',
                value: prefersLiveCaptionsOn,
                persist: true,
            });
        }
    }, [prefersLiveCaptionsOn]);

    return (
        <AccessibilityDeviceDetectionView
            currentStepIndex={currentStepIndex}
            next={next}
            handleSwitchToggle={handleSwitchToggle}
            prefersLiveCaptionsOn={prefersLiveCaptionsOn}
            isSelectSupported={isSelectSupported}
        />
    );
};
