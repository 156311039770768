import type {InitOutput} from '@pexip/denoise/denoise';

import type {Denoise, Clock, Callback} from './types';

export const hasAudioContext = () => typeof AudioContext !== 'undefined';

export const hasCreateGain = (context: BaseAudioContext) =>
    typeof context.createGain !== 'undefined';

export const hasAudioWorkletProcessor = () =>
    typeof AudioWorkletProcessor !== 'undefined' &&
    typeof registerProcessor === 'function';

export const hasAudioWorkletNode = () =>
    typeof AudioWorkletNode !== 'undefined';

export const hasAudioWorklet = () =>
    typeof AudioWorklet !== 'undefined' && hasAudioWorkletNode();

const stopTrack = (track: MediaStreamTrack) => track.stop();

export const stopStreamTracks = (stream?: MediaStream) =>
    stream?.getTracks().forEach(stopTrack);

/**
 * The callback per iteration
 *
 * @param frame - The frame from the source buffer
 * @param channel - The channel number
 * @param input - The input number
 */
type FrameModifyFn = (
    frame: Float32Array,
    channel: number,
    input: number,
) => void;

/**
 * Iterating the provided frames and call the callback with the data and indices
 *
 * @param frames - The source frames to be iterated
 * @param modifierCallback - The callback function to be called per iteration
 */
export const getFrame = (
    frames: Float32Array[][],
    modifierCallback: FrameModifyFn,
) => {
    frames.forEach((channels, i) =>
        channels.forEach((frame, c) => {
            modifierCallback(frame, c, i);
        }),
    );
};

/**
 * Connect the inputs and output without any changes
 */
export const connectInputsOutputs = (
    inputs: Float32Array[][],
    outputs: Float32Array[][],
) => {
    getFrame(outputs, (output, channel_index, input_index) => {
        const input = inputs[input_index]?.[channel_index];
        if (input) {
            output.set(input);
        }
    });
};

/**
 * A wrapper for Denoise from the WASM module, extracted and refactored from the
 * generated denoise.js, which maps the WASM functions to js
 *
 * @param wasm - Wasm module exports with types from `wasm_bindgen` generated
 * @param channelCount - number of channels
 * @param frameSize - frame size
 */
export const createDenoise = (
    wasm: InitOutput,
    channelCount: number,
    frameSize: number,
): Denoise => {
    let ptr = wasm.denoise_new(frameSize, channelCount);
    let freed = false;

    const getInputBufferDataView = () =>
        new Float32Array(
            wasm.memory.buffer,
            wasm.denoise_input(ptr),
            frameSize * channelCount,
        );

    const getOutputBufferDataView = () =>
        new Float32Array(
            wasm.memory.buffer,
            wasm.denoise_output(ptr),
            frameSize * channelCount,
        );

    return {
        vad: (channel: number) => {
            return wasm.denoise_vad(ptr, channel);
        },

        free: () => {
            const tmpPtr = ptr;
            ptr = 0;
            wasm.__wbg_denoise_free(tmpPtr);
            freed = true;
        },

        pipe: (inputs, outputs) => {
            if (freed) {
                return;
            }
            const inputBuffer = getInputBufferDataView();
            // Update inputBuffer accordingly from inputs
            getFrame(inputs, (input, channel) => {
                inputBuffer.set(input, channel * input.length);
            });

            wasm.denoise_process_frames(ptr);

            const outputBuffer = getOutputBufferDataView();
            // Update outputs from outputBuffer accordingly
            getFrame(outputs, (output, channel) => {
                output.set(
                    outputBuffer.slice(
                        channel * output.length,
                        (channel + 1) * output.length,
                    ),
                );
            });
        },
    };
};

/**
 * A function to create `MediaStreamAudioSourceNode` using constructor or factory
 * function depends on the browser supports
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link MediaStreamAudioSourceOptions}
 *
 * @internal
 */
export const createMediaStreamAudioSourceNode = (
    context: AudioContext,
    options: MediaStreamAudioSourceOptions,
) => {
    try {
        const source = new MediaStreamAudioSourceNode(context, options);
        return source;
    } catch {
        return context.createMediaStreamSource(options.mediaStream);
    }
};

/**
 * A function to create `MediaStreamAudioSourceNode` using constructor or factory
 * function depends on the browser supports
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link MediaStreamAudioSourceOptions}
 *
 * @internal
 */
export const createMediaElementSourceNode = (
    context: AudioContext,
    options: MediaElementAudioSourceOptions,
) => {
    try {
        const source = new MediaElementAudioSourceNode(context, options);
        return source;
    } catch {
        return context.createMediaElementSource(options.mediaElement);
    }
};

/**
 * A function to set AudioNodeOptions accordingly
 */
const setAudioNodeOptions = (node: AudioNode, options?: AudioNodeOptions) => {
    if (options?.channelCount) {
        node.channelCount = options.channelCount;
    }
    if (options?.channelCountMode) {
        node.channelCountMode = options.channelCountMode;
    }
    if (options?.channelInterpretation) {
        node.channelInterpretation = options.channelInterpretation;
    }
};

/**
 * A function to create `AnalyserNode` using constructor or factory
 * function depends on the browser supports
 *
 * @param audioContext - @see {@link AudioContext}
 * @param options - @see {@link AnalyserOptions}
 *
 * @internal
 */
export const createAnalyserNode = (
    audioContext: BaseAudioContext,
    options?: AnalyserOptions,
) => {
    try {
        const analyser = new AnalyserNode(audioContext, options);
        return analyser;
    } catch {
        const analyser = audioContext.createAnalyser();
        options?.fftSize && (analyser.fftSize = options.fftSize);
        options?.maxDecibels && (analyser.maxDecibels = options.maxDecibels);
        options?.minDecibels && (analyser.minDecibels = options.minDecibels);
        options?.smoothingTimeConstant &&
            (analyser.smoothingTimeConstant = options.smoothingTimeConstant);
        setAudioNodeOptions(analyser, options);
        return analyser;
    }
};

/**
 * A function to create `GainNode` using constructor or factory
 * function depends on the browser supports
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link GainOptions}
 *
 * @internal
 */
export const createGainNode = (
    context: BaseAudioContext,
    options?: GainOptions,
) => {
    try {
        const volume = new GainNode(context, options);
        return volume;
    } catch {
        const volume = hasCreateGain(context)
            ? context.createGain()
            : (context as webkitAudioContext).createGainNode();
        if (options?.gain) {
            volume.gain.setValueAtTime(options.gain, context.currentTime);
        }
        setAudioNodeOptions(volume, options);
        return volume;
    }
};

/**
 * A function to clone the Audio Track
 *
 * @param stream - Stream to be cloned
 *
 * @internal
 */
export const createMediaStreamAudioClone = (stream: MediaStream) => {
    try {
        const mediaStream = new MediaStream(
            stream.getAudioTracks().map(track => track.clone()),
        );
        return mediaStream;
    } catch {
        return stream.clone();
    }
};

/**
 * A function to create `MediaStreamAudioDestinationNode` using constructor or
 * factory function depends on the browser supports
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link AudioNodeOptions}
 *
 * @internal
 */
export const createMediaStreamAudioDestinationNode = (
    context: AudioContext,
    options?: AudioNodeOptions,
) => {
    try {
        const destination = new MediaStreamAudioDestinationNode(
            context,
            options,
        );
        return destination;
    } catch {
        const destination = context.createMediaStreamDestination();
        setAudioNodeOptions(destination, options);
        return destination;
    }
};

/**
 * A function to create `DelayNode` using constructor or
 * factory function depends on the browser supports
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link DelayOptions}
 *
 * @internal
 */
export const createDelayNode = (
    context: AudioContext,
    options?: DelayOptions,
): DelayNode => {
    try {
        const delay = new DelayNode(context, options);
        return delay;
    } catch {
        const delay = context.createDelay(options?.maxDelayTime);
        if (options?.delayTime !== undefined) {
            delay.delayTime.setValueAtTime(
                options?.delayTime,
                context.currentTime,
            );
        }
        setAudioNodeOptions(delay, options);
        return delay;
    }
};

/**
 * A function to create `ChannelSplitterNode` using constructor or
 * factory function depends on the browser supports
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link ChannelSplitterOptions}
 *
 * @internal
 */
export const createChannelSplitterNode = (
    context: AudioContext,
    options?: ChannelSplitterOptions,
): ChannelSplitterNode => {
    try {
        const node = new ChannelSplitterNode(context, options);
        return node;
    } catch {
        const node = context.createChannelSplitter(options?.numberOfOutputs);
        setAudioNodeOptions(node, options);
        return node;
    }
};

/**
 * A function to create `ChannelMergerNode` using constructor or
 * factory function depends on the browser supports
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link ChannelSplitterOptions}
 *
 * @internal
 */
export const createChannelMergerNode = (
    context: AudioContext,
    options?: ChannelMergerOptions,
): ChannelMergerNode => {
    try {
        const node = new ChannelMergerNode(context, options);
        return node;
    } catch {
        const node = context.createChannelMerger(options?.numberOfInputs);
        setAudioNodeOptions(node, options);
        return node;
    }
};

/**
 * Map mute value to gain value
 *
 * ```
 * `true` -> 0
 * `false` -> 1
 * ```
 */
export const muteToGain = (mute: boolean): number => (mute ? 0 : 1);

/**
 * Calculate the timeout based on the provided data and returns a timeout in
 * milliseconds with compensation added
 *
 * @param targetTime - The target timeout after the compensation
 * @param startTime - The start time of the last execution
 * @param endTime - The end time of the last execution
 */
export const calculateNextTimeout = (
    targetTime: number,
    startTime: number,
    endTime: number,
) => Math.max(targetTime - Math.max(endTime - startTime, 0), 0);

type Timeouts = Pick<WindowOrWorkerGlobalScope, 'setTimeout' | 'clearTimeout'>;

/**
 * @param delayInMS - Delay in terms of milliseconds
 * @param params - The parameters to be passed to the provided callback
 */
type DelayCallback<P extends unknown[], R> = (
    delayInMS: number,
    ...params: P
) => Promise<void | R>;
/**
 * Cancel the delay created by the @see {@link createDelayedCallback}, when it
 * is invoked the ongoing delay will be resolved immediately
 */
type CancelDelay = () => void;

/**
 * Convert a callback to an async callback with delay added
 *
 * @param callback - The callback to be delayed
 * @param options - The options to inject dependences
 *
 * @example
 *
 * ```typescript
 * const getRandom = () => Math.random();
 * const [delayGetRandom, cancelDelayGetRandom] = createDelayedCallback(getRandom);
 *
 * // Delay 500 ms to get the random number
 * const random = await delayGetRandom(500);
 * ```
 */
export const createDelayedCallback = <R, P extends unknown[]>(
    callback: Callback<R, P>,
    {
        setTimeout = window.setTimeout,
        clearTimeout = window.clearTimeout,
    }: Partial<Timeouts> = {},
): [DelayCallback<P, R>, CancelDelay] => {
    const props: {
        timeoutID: number;
        cancel?: () => void;
    } = {
        timeoutID: 0,
    };

    const cancelTimeout = () => {
        if (props.timeoutID) {
            clearTimeout(props.timeoutID);
            props.timeoutID = 0;
        }
    };

    const delayedCallback = async (delayMs: number, ...params: P) => {
        const resolved = await new Promise<R | void>(resolve => {
            cancelTimeout();
            props.timeoutID = setTimeout(() => {
                const result = callback(...params);
                if (result instanceof Promise) {
                    result
                        .then(resolved => resolve(resolved))
                        .catch(e => {
                            throw e;
                        });
                } else {
                    resolve(result);
                }
            }, delayMs);
            props.cancel = resolve;
        });
        return resolved;
    };

    const cancel = () => {
        cancelTimeout();
        props.cancel?.();
    };

    return [delayedCallback, cancel];
};

/**
 * Convert the rate to milliseconds
 */
const rateToMs = (rate: number) => Math.ceil(1000 / rate);

type AsyncCallbackLoopOptions = Timeouts &
    Pick<Performance, 'now'> & {frameRate: number};

/**
 * Create an async callback loop to be called recursively with delay based on
 * the `frameRate`
 *
 * @param callback - The callback to be invoked
 * @param frameRate - The rate to be expected to invoke the `callback`
 */
export const createAsyncCallbackLoop = <
    P extends unknown[],
    R extends Promise<unknown>,
>(
    callback: Callback<R, P>,
    frameRate: number,
    {
        setTimeout = window.setTimeout,
        clearTimeout = window.clearTimeout,
        now = () => performance.now(),
    }: Partial<AsyncCallbackLoopOptions> = {},
) => {
    const props = {
        frameRate,
        targetMs: rateToMs(frameRate),
        prevCalledMs: 0,
        timeoutID: 0,
        stopped: false,
    };
    const [delayedCallback, cancel] = createDelayedCallback(callback, {
        setTimeout,
        clearTimeout,
    });

    const fork = async (...params: P) => {
        if (props.stopped) {
            return;
        }
        const currentMs = now();
        const nextMs = calculateNextTimeout(
            props.targetMs,
            props.prevCalledMs,
            currentMs,
        );
        props.prevCalledMs = currentMs;
        await delayedCallback(nextMs, ...params);
        await fork(...params);
    };

    return {
        start: async (...params: P) => {
            props.prevCalledMs = now();
            props.stopped = false;

            await delayedCallback(0, ...params);

            void fork(...params);
        },
        stop: () => {
            props.stopped = true;
            cancel();
        },
        get frameRate() {
            return props.frameRate;
        },
        set frameRate(value: number) {
            props.frameRate = value;
            props.targetMs = rateToMs(value);
        },
    };
};

export const DEFAULT_THROTTLE_MS = 3000;

/**
 * A function to limit the provided callback being called too frequently, and
 * assuming the function is called repeatably, and NOT for general purpose.
 *
 * @param callback - the callback to be called under the specified time
 * @param throttleMs - the specified time for throttling
 * @param clock - how to get the current time
 */
export const throttleProcess = <P extends unknown[]>(
    callback: Callback<void, P>,
    throttleMs = DEFAULT_THROTTLE_MS,
    clock: Clock = performance,
) => {
    let lastCall = 0;
    return (...params: P) => {
        const now = clock.now();
        if (now - lastCall >= throttleMs) {
            callback(...params);
            lastCall = now;
        }
    };
};

type Unsubscribe = () => void;

/**
 * Subscribe visibilitychange event
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event}
 *
 * @param callback - A callback to be called with `document.hidden` when the event is trigger
 */
export const subscribeVisibilityChangeEvent = (
    callback: (hidden: boolean) => Promise<void>,
): Unsubscribe => {
    const handleEvent = () => {
        callback(document.hidden).catch(error => {
            throw error;
        });
    };
    document.addEventListener('visibilitychange', handleEvent);
    return () => {
        document.removeEventListener('visibilitychange', handleEvent);
    };
};
