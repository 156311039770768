import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Button, FontVariant, Text, TextHeading} from '@pexip/components';

import type {Idp} from '../../types';

export const SelectIdp: React.FC<{
    handleIdp: (chosenIdp: string, redirectUrl?: string) => void;
    idps: Idp[];
}> = ({handleIdp, idps}) => {
    const {t} = useTranslation();
    return (
        <>
            <TextHeading
                htmlTag="h2"
                fontVariant={FontVariant.H4}
                className="mb-2"
            >
                <Trans t={t} i18nKey="idp.sign-in">
                    Sign in
                </Trans>
            </TextHeading>
            <Text fontVariant={FontVariant.H6} variant="subtle">
                <Trans t={t} i18nKey="idp.select-provider">
                    Please select your Identity Provider. This will open another
                    window.
                </Trans>
            </Text>
            {idps.map(({name, redirectUrl, uuid}) => (
                <Button
                    modifier="fullWidth"
                    className="mt-4"
                    size="large"
                    key={uuid}
                    onClick={() => handleIdp(uuid, redirectUrl)}
                >
                    <Trans t={t} i18nKey="idp.sign-in-with">
                        Sign in with {{name}}
                    </Trans>
                </Button>
            ))}
        </>
    );
};
