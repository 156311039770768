import React, {useCallback} from 'react';

import {useMobileDevice} from '@pexip/components';
import {
    PreflightMediaControls,
    useAnalyzer,
    useAudioDetection,
    useDeviceStatusInfo,
} from '@pexip/media-components';
import type {UserMediaStatus} from '@pexip/media';
import {isInitialPermissionsNotGranted} from '@pexip/media';

import {FlowType} from '../types';
import {mediaSignals} from '../signals/Media.signals';
import {
    disableAudioSignalDetection,
    mediaService,
    useLocalMedia,
} from '../services/Media.service';
import {useConfig} from '../config';
import {useEffectsApplied} from '../hooks/useEffectsApplied';
import {FFT_SIZE} from '../constants';

import {AudioMeter} from './AudioMeter.viewModel';

export type SelfviewControlType =
    | 'express'
    | 'step-by-step'
    | 'in-meeting'
    | 'settings';

export const SelfviewControls: React.FC<{
    flowType: FlowType;
    streamStatus?: UserMediaStatus;
    triggerSettingsModal?: () => void;
    toggleBlur?: () => void;
    toggleFacingMode: () => void;
}> = ({
    flowType,
    streamStatus,
    triggerSettingsModal,
    toggleBlur,
    toggleFacingMode,
}) => {
    const inputStatusInfo = useDeviceStatusInfo(streamStatus);
    const silentTooltipInfo = useAudioDetection(
        mediaSignals.onSilentDetected.add,
        disableAudioSignalDetection,
    );
    const isMobileDevice = useMobileDevice();
    const [videoProcessingEnabled] = useConfig('videoProcessing');
    const [isAudioMediaMuted, setAudioInputMuted] =
        useConfig('isAudioInputMuted');
    const [isVideoMediaMuted, setVideoInputMuted] =
        useConfig('isVideoInputMuted');
    const effectsApplied = useEffectsApplied();
    const media = useLocalMedia();
    const analyzer = useAnalyzer(media, FFT_SIZE);
    const shouldShowVideoStatus =
        streamStatus && isInitialPermissionsNotGranted(streamStatus);

    const toggleAudioAndPersist = useCallback(() => {
        setAudioInputMuted(isMuted => !isMuted, true);
    }, [setAudioInputMuted]);

    const toggleVideoAndPersist = useCallback(() => {
        setVideoInputMuted(isMuted => !isMuted, true);
    }, [setVideoInputMuted]);

    const miniButtons = isMobileDevice && flowType === FlowType.Express;

    return (
        <PreflightMediaControls
            isMobileDevice={isMobileDevice}
            miniButtons={miniButtons}
            shouldCenter={flowType === FlowType.StepByStep || isMobileDevice}
            showSettingsButton={flowType === FlowType.Express}
            triggerSettingsModal={triggerSettingsModal}
            inputStatusInfo={inputStatusInfo}
            silentTooltipInfo={silentTooltipInfo}
            isAudioInputMuted={isAudioMediaMuted}
            isVideoInputMuted={isVideoMediaMuted}
            isBlurred={effectsApplied}
            segmentationEnabled={videoProcessingEnabled}
            toggleBlur={toggleBlur}
            requestMediaPermissions={() =>
                mediaService.getUserMedia({audio: true, video: true})
            }
            streamStatus={streamStatus}
            toggleAudioInput={toggleAudioAndPersist}
            toggleVideoInput={toggleVideoAndPersist}
            shouldShowVideoStatus={shouldShowVideoStatus}
            toggleFacingMode={toggleFacingMode}
            audioInputControl={
                analyzer && (
                    <AudioMeter
                        analyzer={analyzer}
                        isAudioInputMuted={isAudioMediaMuted}
                        streamStatus={streamStatus}
                        onClick={toggleAudioAndPersist}
                        isMobileDevice={isMobileDevice}
                        miniButtons={miniButtons}
                    />
                )
            }
        />
    );
};
