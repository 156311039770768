import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import type {RouteMatch} from '@pexip/router';
import {CenterLayout, Section} from '@pexip/components';
import {isDefined} from '@pexip/utils';

import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {Header} from '../viewModels/Header.viewModel';
import {push} from '../router';
import {EXPRESS_PATH} from '../constants';
import {RejoinChooseAnotherMeeting} from '../views/RejoinChooseAnotherMeeting/RejoinChooseAnotherMeeting.view';

export const PostMeeting: React.FC<{match: RouteMatch}> = ({
    match: {
        params: {id},
    },
}) => {
    const {t} = useTranslation();

    const conferenceAlias = isDefined(id);

    const rejoin = useCallback(() => {
        push(`/m/${conferenceAlias}${EXPRESS_PATH}`);
    }, [conferenceAlias]);

    const chooseAnotherMeeting = useCallback(() => {
        push(`/`);
    }, []);

    return (
        <BrandedFullSizeWindow
            padding="small"
            background="secondary"
            flexDirection="column"
            justifyContent="center"
        >
            <Header />
            <CenterLayout>
                <Section
                    className="text-center"
                    title={t(`meeting.you-left`, 'You left the meeting')}
                    subtitle=""
                >
                    <RejoinChooseAnotherMeeting
                        className="mt-7 mb-7"
                        onRejoinClick={rejoin}
                        onChooseAnotherMeetingClick={chooseAnotherMeeting}
                    />
                </Section>
            </CenterLayout>
        </BrandedFullSizeWindow>
    );
};
