import {useCallback, useEffect, useMemo, useReducer, useState} from 'react';

import {multistepIndicatorReducer} from './MultistepIndicator.reducer';
import type {MultistepItem} from './MultistepIndicator.types';
import {getAvailableStepIndex} from './MultistepIndicator.utils';

/**
 * Returns a boolean array of the steps' isCompleted status
 */
const getStepsCompletedStatus = (steps: MultistepItem[]) =>
    // Disabled steps are marked as "completed" since it's not
    // a required step
    steps.map(step => (step.isDisabled ? true : !!step.isCompleted));

const getPrevStepsCompletedStatus = (
    steps: MultistepItem[],
    currentStepIndex: number,
) => getStepsCompletedStatus([...steps].splice(0, currentStepIndex));

const areAllStepsComplete = (stepsCompleteStatus: boolean[]) =>
    stepsCompleteStatus.every(step => step === true);

/**
 * Convenience hook for using steps in MultistepIndicator
 */
export const useMultistepIndicator = ({
    initialSteps = [],
    currentStepIndex: initalCurrenStepIndex = 0,
}: {
    initialSteps: MultistepItem[];
    currentStepIndex?: number;
}) => {
    const initializedSteps = useMemo(() => initialSteps, [initialSteps]);
    const [steps, dispatch] = useReducer(
        multistepIndicatorReducer,
        initializedSteps,
    );

    const [prevStepsCompleted, setPrevStepsCompleted] = useState(false);
    const [allStepsCompleted, setAllStepsCompleted] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState(
        initalCurrenStepIndex,
    );

    useEffect(() => {
        const stepsStatus = getStepsCompletedStatus(steps);
        setAllStepsCompleted(areAllStepsComplete(stepsStatus));
    }, [steps]);

    useEffect(() => {
        const prevSteps = getPrevStepsCompletedStatus(steps, currentStepIndex);
        setPrevStepsCompleted(areAllStepsComplete(prevSteps));
    }, [currentStepIndex, steps]);

    const moveToStep = useCallback(
        (
            stepIndex: number,
            {forceStep}: {forceStep?: boolean} = {forceStep: false},
        ) => {
            const toStepIndex = forceStep
                ? stepIndex
                : getAvailableStepIndex({
                      steps,
                      stepIndex,
                  });
            setCurrentStepIndex(toStepIndex);
        },
        [steps],
    );

    // Reducer actions
    const resetStep = (stepIndex: number) => {
        const initialStep = initialSteps[stepIndex];
        if (initialStep) {
            dispatch({type: 'set-step', stepIndex, step: initialStep});
        }
    };

    const resetAllSteps = () =>
        dispatch({type: 'set-all-steps', steps: initialSteps});

    const updateStepDisabled = (stepIndex: number, isDisabled = true) =>
        dispatch({type: 'disable-step', stepIndex, isDisabled});

    const updateStepActive = (stepIndex: number, isActive = true) =>
        dispatch({type: 'activate-step', stepIndex, isActive});

    const updateStepCompleted = (
        stepIndex: number,
        {
            activateStep = false,
            isCompleted = true,
        }: {activateStep?: boolean; isCompleted?: boolean} = {},
    ) =>
        dispatch({
            type: 'complete-step',
            stepIndex,
            options: {isCompleted, activateStep},
        });

    const state = {
        allStepsCompleted,
        currentStepIndex,
        prevStepsCompleted,
        steps,
    };

    const actions = {
        moveToStep,
        resetAllSteps,
        resetStep,
        updateStepActive,
        updateStepCompleted,
        updateStepDisabled,
    };

    return [state, actions] as const;
};
