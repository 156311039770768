/* FILE GENERATED BY packages/components/design-tokens/build-scripts/colors.js DO NOT CHANGE */

export enum Color {
    Blue40 = '#b1aaff',
    Blue20 = '#d0ccff',
    Red100 = '#ff7273',
    Green100 = '#0ebec7',
    Blue100 = '#4b37ff',
    TextLinkBlueDarkBg = '#00c2ff',
    TextLinkBlueHoverDarkBg = '#52d7ff',
    Blue50 = '#a198ff',
    Green90 = '#30c8cf',
    Green80 = '#51d2d8',
    Green60 = '#8de5e9',
    Green40 = '#baf4f6',
    Red90 = '#ff9697',
    Red80 = '#ffb6b7',
    Red60 = '#ffe3e4',
    Red40 = '#fff7f7',
    Blue90 = '#5d4cff',
    SignalRedHover = '#bc1515',
    DeepBlue100 = '#0a2136',
    DeepBlue90 = '#1e3a54',
    SignalRed = '#e60000',
    SignalGreen = '#2c8500',
    SignalGreen130 = '#205e01',
    DeepBlue80 = '#3c5974',
    DeepBlue50 = '#a5b8ca',
    DeepBlue40 = '#c1d1df',
    DeepBlue30 = '#d8e3ee',
    DeepBlue20 = '#e8f0f8',
    Grey = '#f7f7f7',
    White = '#ffffff',
    Black = '#111111',
    DeepBlue60 = '#728fac',
    DeepBlue70 = '#577490',
    DeepBlue10 = '#f4f7fb',
    DeepBlue5 = '#f8fafc',
    TextLinkBlueWhiteBg = '#032fff',
    TextLinkBlueHoverWhiteBg = '#0023d1',
    SignalOrange130 = '#bc5a00',
    SignalOrange80 = '#fef1e6',
    Green130 = '#00777c',
}
export enum RgbColor {
    Blue40 = '177,170,255',
    Blue20 = '208,204,255',
    Red100 = '255,114,115',
    Green100 = '14,190,199',
    Blue100 = '75,55,255',
    TextLinkBlueDarkBg = '0,194,255',
    TextLinkBlueHoverDarkBg = '82,215,255',
    Blue50 = '161,152,255',
    Green90 = '48,200,207',
    Green80 = '81,210,216',
    Green60 = '141,229,233',
    Green40 = '186,244,246',
    Red90 = '255,150,151',
    Red80 = '255,182,183',
    Red60 = '255,227,228',
    Red40 = '255,247,247',
    Blue90 = '93,76,255',
    SignalRedHover = '188,21,21',
    DeepBlue100 = '10,33,54',
    DeepBlue90 = '30,58,84',
    SignalRed = '230,0,0',
    SignalGreen = '44,133,0',
    SignalGreen130 = '32,94,1',
    DeepBlue80 = '60,89,116',
    DeepBlue50 = '165,184,202',
    DeepBlue40 = '193,209,223',
    DeepBlue30 = '216,227,238',
    DeepBlue20 = '232,240,248',
    Grey = '247,247,247',
    White = '255,255,255',
    Black = '17,17,17',
    DeepBlue60 = '114,143,172',
    DeepBlue70 = '87,116,144',
    DeepBlue10 = '244,247,251',
    DeepBlue5 = '248,250,252',
    TextLinkBlueWhiteBg = '3,47,255',
    TextLinkBlueHoverWhiteBg = '0,35,209',
    SignalOrange130 = '188,90,0',
    SignalOrange80 = '254,241,230',
    Green130 = '0,119,124',
}
