import {useEffect} from 'react';
import type {RefObject} from 'react';

const scrollDown = (scrollableElementRef: RefObject<HTMLDivElement>) => {
    scrollableElementRef.current?.scrollTo({
        top: scrollableElementRef.current.scrollHeight,
        behavior: 'smooth',
    });
};

export const useScrollElementToBottom = (
    scrollableElementRef: RefObject<HTMLDivElement>,
    scrollToBottom: boolean,
) => {
    useEffect(() => {
        if (scrollToBottom) {
            scrollDown(scrollableElementRef);
        }
    }, [scrollToBottom, scrollableElementRef]);

    return scrollableElementRef;
};
