import React from 'react';
import {useTranslation} from 'react-i18next';

import {IconTypes, Bar} from '@pexip/components';
import type {Quality} from '@pexip/peer-connection-stats';

import type {SelfviewProps} from '../Selfview/Selfview.view';
import {TestId} from '../../../test/testIds';
import {SelfviewAudioButton} from '../SelfviewAudioButton/SelfviewAudioButton.view';
import {TransparentCallQualityIndicator} from '../CallQualityIndicator/CallQualityIndicatorButton.view';
import type {AutoHideButtonCallbacks} from '../../types';

import {SelfviewButton} from './FoldedSelfview.view';
import {AspectRatioSelfview} from './AspectRatioSelfview';

import styles from './Selfview.module.scss';

export const InMeetingSelfview: React.FC<
    SelfviewProps & {
        autoHideProps: AutoHideButtonCallbacks;
        isAudioInputMuted?: boolean;
        isAudioInputMissing?: boolean;
        isMobileDevice?: boolean;
        isPhone?: boolean;
        callQualityPosition: 'topRight' | 'bottomRight';
        quality: Quality;
        onCollapseSelfview: () => void;
        onCallQualityClick: () => void;
        onToggleAudioClick: () => void;
        toggleFacingMode?: () => void;
    }
> = ({
    autoHideProps,
    isAudioInputMuted,
    isAudioInputMissing,
    isMobileDevice,
    isPhone,
    quality,
    onCollapseSelfview,
    onCallQualityClick,
    onToggleAudioClick,
    ...props
}) => {
    const {t} = useTranslation();

    return (
        <AspectRatioSelfview
            isMobileDevice={isMobileDevice}
            aspectRatioType="in-meeting"
            {...props}
        >
            {!isMobileDevice && (
                <div className={styles.collapseSelfviewButton}>
                    <SelfviewButton
                        onClick={onCollapseSelfview}
                        data-testid={TestId.ButtonCollapseSelfview}
                        tooltipText={t(
                            'meeting.hide-self-view',
                            'Hide self-view',
                        )}
                        aria-label={t(
                            'meeting.hide-self-view',
                            'Hide self-view',
                        )}
                        icon={IconTypes.IconFullscreenOff}
                        {...autoHideProps}
                    />
                </div>
            )}
            <Bar
                position="absoluteBottom"
                className={styles.inMeetingSelfviewFooter}
            >
                <SelfviewAudioButton
                    isPhone={isPhone}
                    onToggleAudioClick={onToggleAudioClick}
                    isAudioInputMuted={isAudioInputMuted}
                    isAudioInputMissing={isAudioInputMissing}
                    stopPointerDownEventPropagation
                    {...autoHideProps}
                />
                <TransparentCallQualityIndicator
                    quality={quality}
                    isPhone={isPhone}
                    tooltipText={t(
                        'meeting.connection-quality',
                        'Connection quality',
                    )}
                    tooltipPosition="topStart"
                    onClick={onCallQualityClick}
                    className={styles.callQualityIndicatorPosition}
                    tooltipClassName={styles.callQualityIndicatorTooltip}
                    stopPointerDownEventPropagation
                    {...autoHideProps}
                />
            </Bar>
        </AspectRatioSelfview>
    );
};

export type InMeetingSelfviewProps = React.ComponentProps<
    typeof InMeetingSelfview
>;
