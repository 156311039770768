import React, {useLayoutEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {CenterLayout, Section} from '@pexip/components';
import type {RouteMatch} from '@pexip/router';

import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {Header} from '../viewModels/Header.viewModel';
import {TestId} from '../../test/testIds';
import {replace} from '../router';
import {infinityService} from '../services/InfinityClient.service';
import {EXPRESS_PATH, STEP_BY_STEP_PATH} from '../constants';
import {logger} from '../logger';
import {config} from '../config';

const parseLegacyParams = (legacyParams: string) => {
    try {
        const params = new URLSearchParams(legacyParams);
        const bandwidth = params.get('bandwidth');
        const conference = params.get('conference');
        const extension = params.get('extension');
        const join = params.get('join');
        const muteCamera = params.get('muteCamera');
        const muteMicrophone = params.get('muteMicrophone');
        const name = params.get('name');
        const pin = params.get('pin');
        const role = params.get('role');

        const isGuest = role === 'guest';

        if (name) {
            config.set({key: 'displayName', value: name});
        }

        if (bandwidth) {
            config.set({key: 'bandwidth', value: bandwidth});
        }

        if (muteCamera) {
            config.set({key: 'isVideoInputMuted', value: Boolean(muteCamera)});
        }

        if (muteMicrophone) {
            config.set({
                key: 'isAudioInputMuted',
                value: Boolean(muteMicrophone),
            });
        }

        if (conference) {
            replace(
                `/m/${conference}${
                    // Maybe automatically redirect to step by step if guest?
                    join ? '' : isGuest ? STEP_BY_STEP_PATH : EXPRESS_PATH
                }`,
            );
        }

        if (pin) {
            infinityService.setPin(pin);
        }

        if (extension) {
            infinityService.setConferenceExtension(extension);
        }

        if (isGuest) {
            // <role> is guest if you want to allow Guests to automatically join a conference that allows Guests but has no Guest PIN.
            infinityService.setPin();
        }
    } catch (reason) {
        logger.debug({reason}, `Can't parse the legacy params`);
    }
};

export const PageNotFound: React.FC<{match: RouteMatch}> = ({match}) => {
    const {t} = useTranslation();

    useLayoutEffect(() => {
        if (!match.url.fragment) {
            return;
        }

        parseLegacyParams(match.url.fragment.substring(1));
    }, [match.url.fragment]);

    useLayoutEffect(() => {
        if (!match.url.query) {
            return;
        }

        parseLegacyParams(match.url.query);
    }, [match.url.query]);

    return (
        <BrandedFullSizeWindow
            padding="none"
            background="secondary"
            flexDirection="column"
            justifyContent="flexStart"
        >
            <Header />
            <CenterLayout>
                <Section
                    data-testid={TestId.NotFoundMessage}
                    title={t('error.not-found.title', 'Oops! 404 - Not Found')}
                    subtitle={t(
                        'error.not-found.subtitle',
                        "We can't seem to find the page you're looking for.",
                    )}
                />
            </CenterLayout>
        </BrandedFullSizeWindow>
    );
};
