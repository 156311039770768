/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface DisconnectMap {
    '200': Status200;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    /**
     * The result is true if successful, false otherwise.
     */
    result: boolean;
}

import {validate200} from './disconnectValidation';
type DisconnectResponse = {status: 200; data: DisconnectMap['200']};
interface DisconnectParams {
    conferenceAlias: string;
}
export async function disconnect({
    fetcher,
    params,
    host,
}: {
    fetcher: typeof fetch;
    params: DisconnectParams;
    host: string;
}): Promise<DisconnectResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/disconnect`,
        {
            method: 'post',
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
