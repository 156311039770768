import type {UserAgents} from '@pexip/media-components';

import type {BlockedBrowserPermissionsInfo, BrowserType} from '../types';

const MapBrowserNames: Partial<Record<UserAgents, BrowserType>> = {
    Android: 'android',
    Chrome: 'chrome',
    'Edge Chromium': 'edge',
    Firefox: 'firefox',
    'Safari iPhone': 'safari-ios',
    'Safari iPad': 'safari-ipados',
    'Safari Mac': 'safari-macosx',
};

const mapUserAgentToBrowserName = (
    userAgent: UserAgents | undefined,
): BrowserType => {
    if (userAgent) {
        const browserName = MapBrowserNames[userAgent];

        return browserName ? browserName : 'chrome';
    }

    return 'chrome';
};

const browserHelpLinks = {
    android:
        'https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DAndroid',
    chrome: 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en',
    edge: 'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857',
    firefox:
        'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions',
    ['safari-ios']:
        'https://support.apple.com/guide/iphone/control-access-to-hardware-features-iph168c4bbd5/ios',
    ['safari-ipados']:
        'https://support.apple.com/guide/ipad/control-access-to-hardware-features-ipade3312ebe/ipados',
    ['safari-macosx']:
        'https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac',
};

/** NB: translations for these strings occur inside the UnblockHelpSteps.view.tsx */
const textHelpSteps = {
    android: [
        {
            step: 1,
            text: 'In your browser, to the right of the address bar, tap More > Settings',
        },
        {step: 2, text: 'Tap Site Settings'},
        {step: 3, text: 'Tap microphone or camera'},
        {
            step: 4,
            text: 'Tap to turn the microphone or camera on or off.',
        },
    ],
    chrome: [
        {
            step: 1,
            text: 'Select the camera icon in the top bar of your browser',
        },
        {step: 2, text: 'Select "allow"'},
        {step: 3, text: 'Refresh your browser'},
    ],
    edge: [
        {
            step: 1,
            text: 'In Microsoft Edge, select Settings and more... then select settings',
        },
        {
            step: 2,
            text: 'Select Cookies & Site Permissions (on left side)',
        },
        {
            step: 3,
            text: 'Under Site Permissions, select Camera or Microphone',
        },
    ],
    firefox: [
        {
            step: 1,
            text: 'Click the permissions icon that displays in the address bar on the left.',
        },
        {
            step: 2,
            text: 'Click the X next to Allowed , Allowed Temporarily, Blocked or Blocked Temporarily to clear the status so that Firefox will ask again for permission when you next visit the site.',
        },
    ],
    ['safari-ios']: [
        {step: 1, text: 'Go to Settings on your iPhone'},
        {
            step: 2,
            text: 'Scroll down and tap on Safari (or whichever browser you are using)',
        },
        {step: 3, text: 'Locate camera and microphone'},
        {step: 4, text: 'Make sure they are enabled'},
        {
            step: 5,
            text: 'Return to the browser and refresh the page',
        },
    ],
    ['safari-ipados']: [
        {step: 1, text: 'Go to Settings on your iPad'},
        {
            step: 2,
            text: 'Scroll down and tap on Safari (or whichever browser you are using)',
        },
        {step: 3, text: 'Locate camera and microphone'},
        {step: 4, text: 'Make sure they are enabled'},
        {
            step: 5,
            text: 'Return to the browser and refresh the page',
        },
    ],
    ['safari-macosx']: [
        {
            step: 1,
            text: 'In Safari app on your Mac, choose Safari > Preferences, then click Websites',
        },
        {
            step: 2,
            text: 'Camera and Microphone settings are listed on the left',
        },
        {
            step: 3,
            text: 'From here you can select to allow permission',
        },
    ],
};

/** NB: translations for these strings occur inside the MediaModal.view.tsx */
const videoTextOverlaySteps = {
    android: [
        {
            step: 1,
            text: 'Look for this icon in your browser',
            duration: 3010,
        },
        {step: 2, text: 'Tap it', duration: 2900},
        {step: 3, text: 'Scroll down to settings and tap', duration: 6040},
        {
            step: 4,
            text: 'Tap to turn the microphone and camera on',
            duration: 4860,
        },
        {
            step: 5,
            text: 'Pull down to refresh the page',
            duration: 2910,
        },
    ],
    chrome: [
        {
            step: 1,
            text: 'Look for this icon in your browser',
            duration: 3100,
        },
        {
            step: 2,
            text: 'Click it',
            duration: 2950,
        },
        {
            step: 3,
            text: 'Change the settings to "always allow"',
            duration: 3000,
        },
        {
            step: 4,
            text: '..then refresh the page',
            duration: 2740,
        },
    ],
    edge: [
        {
            step: 1,
            text: 'Look for this icon in your browser',
            duration: 3100,
        },
        {
            step: 2,
            text: 'Click it',
            duration: 2950,
        },
        {
            step: 3,
            text: 'Change the settings to "always allow"',
            duration: 3000,
        },
        {
            step: 4,
            text: '...then refresh the page',
            duration: 2740,
        },
    ],
    firefox: [
        {
            step: 1,
            text: 'Look for these icons',
            duration: 2450,
        },
        {step: 2, text: 'Click them', duration: 3050},
        {
            step: 3,
            text: 'Click on the "X" to remove the blockage',
            duration: 3030,
        },
        {step: 4, text: '...then refresh the page', duration: 2510},
    ],
    ['safari-ios']: [
        {
            step: 1,
            text: 'Go in to Settings on your device',
            duration: 2670,
        },
        {
            step: 2,
            text: 'Scroll down to locate Safari (or whichever browser you are using)',
            duration: 5250,
        },
        {step: 3, text: 'Tap it', duration: 2600},
        {
            step: 4,
            text: 'Then locate "Camera" and "Microphone"',
            duration: 5600,
        },
        {
            step: 5,
            text: 'Make sure they are enabled or set to "allow"',
            duration: 6220,
        },
        {
            step: 6,
            text: 'Return to this web browser ...then refresh the page',
            duration: 5150,
        },
    ],
    ['safari-ipados']: [
        {
            step: 1,
            text: 'Go in to Settings on your device',
            duration: 2670,
        },
        {
            step: 2,
            text: 'Scroll down to locate Safari (or whichever browser you are using)',
            duration: 5250,
        },
        {step: 3, text: 'Tap it', duration: 2600},
        {
            step: 4,
            text: 'Then locate "Camera" and "Microphone"',
            duration: 5600,
        },
        {
            step: 5,
            text: 'Make sure they are enabled or set to "allow"',
            duration: 6220,
        },
        {
            step: 6,
            text: 'Return to this web browser ...then refresh the page',
            duration: 5150,
        },
    ],
    ['safari-macosx']: [
        {
            step: 1,
            text: 'Go to settings under Safari',
            duration: 3330,
        },
        {
            step: 2,
            text: 'Click it',
            duration: 2400,
        },
        {
            step: 3,
            text: `Click on "Settings for ${window.location.host}"`,
            duration: 3000,
        },
        {
            step: 4,
            text: 'Hover your mouse over camera and microphone options',
            duration: 4300,
        },
        {
            step: 5,
            text: 'Change the setting to "allow"',
            duration: 2600,
        },
        {
            step: 6,
            text: '...then refresh the page',
            duration: 2100,
        },
    ],
};

export const getBlockedBrowserPermissionsInfo = (
    browserName: UserAgents | undefined,
    buildUrlFn: (browser: BrowserType) => {vp9: string; mp4: string},
): BlockedBrowserPermissionsInfo => {
    const derivedBrowserName: BrowserType =
        mapUserAgentToBrowserName(browserName);
    const mediaUrls = buildUrlFn(derivedBrowserName);

    return {
        vp9: mediaUrls.vp9.toString(),
        mp4: mediaUrls.mp4.toString(),
        browserName: derivedBrowserName,
        link: browserHelpLinks[derivedBrowserName],
        textHelpSteps: textHelpSteps[derivedBrowserName],
        videoTextOverlaySteps: videoTextOverlaySteps[derivedBrowserName],
    };
};
