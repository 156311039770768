import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {Dedupe as DedupeIntegration} from '@sentry/integrations';

import {getFileName, getLogFile} from '@pexip/logger';

import {applicationConfig} from './applicationConfig';
import {logger} from './logger';

// TODO: Find a better way to handle this?
declare const process: {env: Record<string, string>};

const SENTRY_REPORT_MIN_DELAY = 30 * 1000;
const SENTRY_LAST_LOG_KEY = 'infinity-connect:lastError:ts';

function hasEventTarget(
    hint: {event?: {target?: unknown}} | undefined,
): hint is {
    event: {target: unknown};
} {
    return !!hint?.event?.target;
}

export function setupSentry() {
    if (!applicationConfig.sentry) {
        return;
    }
    Sentry.init({
        integrations: [new BrowserTracing(), new DedupeIntegration()],
        release: process.env.BUILD_HASH_FULL,
        ...applicationConfig.sentry,

        beforeBreadcrumb(breadCrumb, hint) {
            if (!hasEventTarget(hint)) {
                return breadCrumb;
            }
            const target = hint.event.target;
            if (target instanceof HTMLElement && target.dataset['testid']) {
                breadCrumb.message = target.dataset['testid'];
            }
            return breadCrumb;
        },
    });

    Sentry.addGlobalEventProcessor((event, hint) => {
        if (event.type !== 'transaction') {
            // Filter out ResizeObserver errors
            // FIXME: #2525
            if (event.message?.includes('ResizeObserver')) {
                return null;
            }
            const lastError = parseInt(
                localStorage.getItem(SENTRY_LAST_LOG_KEY) ?? '0',
                10,
            );
            if (lastError + SENTRY_REPORT_MIN_DELAY > Date.now()) {
                logger.warn({lastError, event}, 'Event has been throttled');
                return null;
            }
            localStorage.setItem(SENTRY_LAST_LOG_KEY, String(Date.now()));

            if (event.event_id) {
                hint.attachments ??= [];
                hint.attachments.push({
                    filename: getFileName('webapp3'),
                    data: getLogFile(),
                    contentType: 'application/json',
                });
            }
        }

        return event;
    });
}
