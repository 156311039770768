import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const brandingLngs = new Map<string, string>();

const importBackend = {
    type: 'backend' as const,
    read<Namespace extends string>(
        language: string,
        namespace: Namespace,
        callback: (errorValue: unknown, translations: null | unknown) => void,
    ) {
        const defaultTranslationsImport = fetch(
            new URL(`./locales/${language}/${namespace}.json`, import.meta.url),
        ).then(req => req.json());

        if (brandingLngs.has(language)) {
            return Promise.all([
                defaultTranslationsImport,
                fetch(`${brandingLngs.get(language)}`).then(r => r.json()),
            ])
                .then(([defaultTranslations, brandedTranslations]) => {
                    callback(null, {
                        ...defaultTranslations,
                        ...brandedTranslations,
                    });
                })
                .catch(err => callback(err, null));
        }

        defaultTranslationsImport
            .then(resources => callback(null, resources))
            .catch(err => callback(err, null));
    },
};

void i18n
    .use(LanguageDetector)
    .use(importBackend)
    .use(initReactI18next)
    .init({
        supportedLngs: [
            'en',
            'cs',
            'cy',
            'da',
            'de',
            'es-ES',
            'es-US',
            'fr-CA',
            'fr-FR',
            'ga',
            'id',
            'it',
            'ja',
            'ko',
            'nb',
            'nl',
            'pl',
            'pt-BR',
            'sv',
            'th',
            'vi',
            'zh-Hans',
            'zh-Hant',
        ],
        fallbackLng: {
            no: ['nb', 'en', 'dev'],
            // Sorry :(
            nn: ['nb', 'en', 'dev'],
        },
        detection: {
            order: ['querystring', 'localStorage', 'navigator'],
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            bindI18nStore: 'added',
            useSuspense: false,
        },
    });
