import React from 'react';
import cx from 'classnames';

import {Scrollbars} from '@pexip/components';

import styles from './ParticipantPanelWrapper.module.scss';

export const ParticipantPanelWrapper: React.FC<
    React.PropsWithChildren<unknown>
> = ({children}) => {
    return (
        <div className={cx(styles.content)}>
            <Scrollbars
                className={cx(styles.scrolls, styles.participant, 'pt-4')}
            >
                {children}
            </Scrollbars>
        </div>
    );
};
