import {useTranslation} from 'react-i18next';
import React from 'react';

import {ActionsRow, Button, ConfirmationModal} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const MeetingSettings: React.FC<{
    closeModal: () => void;
    disconnectAll: () => void;
    isConfirmationOpen: boolean;
    isLocked: boolean;
    toggleLock: () => void;
    openConfirmation: () => void;
    closeConfirmation: () => void;
}> = ({
    closeModal,
    disconnectAll,
    isConfirmationOpen,
    isLocked,
    toggleLock,
    openConfirmation,
    closeConfirmation,
}) => {
    const {t} = useTranslation();

    return (
        <div className="mb-4">
            <ActionsRow
                title={t('settings.lock', 'Lock the meeting')}
                subtitle={t(
                    'settings.lock-desc',
                    'Prevents anyone from automatically joining the meeting',
                )}
            >
                <Button
                    variant="tertiary"
                    onClick={toggleLock}
                    data-testid={
                        isLocked ? TestId.ButtonUnlock : TestId.ButtonLock
                    }
                >
                    {isLocked
                        ? t('settings.unlock', 'Unlock the meeting')
                        : t('settings.lock', 'Lock the meeting')}
                </Button>
            </ActionsRow>
            <ActionsRow
                title={t('settings.disconnect-all', 'Disconnect all')}
                subtitle={t(
                    'settings.disconnect-all-desc',
                    'Disconnects all meeting participants, including you',
                )}
            >
                <Button
                    variant="danger"
                    onClick={openConfirmation}
                    data-testid={TestId.ButtonDisconnectAll}
                >
                    {t('settings.disconnect-all', 'Disconnect all')}
                </Button>
            </ActionsRow>
            <ConfirmationModal
                closeOnOutsideClick={false}
                cancelButtonText={t('common.cancel', 'Cancel')}
                confirmButtonText={t(
                    'settings.disconnect-all',
                    'Disconnect all',
                )}
                isOpen={isConfirmationOpen}
                onCancel={closeConfirmation}
                onClose={closeConfirmation}
                onConfirm={() => {
                    disconnectAll();
                    closeConfirmation();
                    closeModal();
                }}
                title={t(
                    'settings.disconnect-all-confirmation',
                    'Are you sure you want to disconnect all?',
                )}
            />
        </div>
    );
};
