import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {Accordion, FontVariant, List, Text, TextLink} from '@pexip/components';

import type {BlockedBrowserPermissionsInfo} from '../../types';

/*
t('media.help-step-[1]', 'Step 1')
t('media.help-step-[2]', 'Step 2')
t('media.help-step-[3]', 'Step 3')
t('media.help-step-[4]', 'Step 4')
t('media.help-step-[5]', 'Step 5')
t('media.permissions-unblock-help-step-[1]-[android]', 'In your browser, to the right of the address bar, tap More > Settings')
t('media.permissions-unblock-help-step-[2]-[android]', 'Tap Site Settings')
t('media.permissions-unblock-help-step-[3]-[android]', 'Tap microphone or camera')
t('media.permissions-unblock-help-step-[4]-[android]', 'Tap to turn the microphone or camera on or off.')
t('media.permissions-unblock-help-step-[1]-[chrome]', 'Select the camera icon in the top bar of your browser')
t('media.permissions-unblock-help-step-[2]-[chrome]', 'Select "allow"')
t('media.permissions-unblock-help-step-[3]-[chrome]', 'Refresh your browser')
t('media.permissions-unblock-help-step-[1]-[edge]', 'In Microsoft Edge, select Settings and more... then select settings')
t('media.permissions-unblock-help-step-[2]-[edge]', 'Select Cookies & Site Permissions (on left side)')
t('media.permissions-unblock-help-step-[3]-[edge]', 'Under Site Permissions, select Camera or Microphone')
t('media.permissions-unblock-help-step-[1]-[firefox]', 'Click the permissions icon that displays in the address bar on the left.')
t('media.permissions-unblock-help-step-[2]-[firefox]', 'Click the X next to Allowed , Allowed Temporarily, Blocked or Blocked Temporarily to clear the status so that Firefox will ask again for permission when you next visit the site.')
t('media.permissions-unblock-help-step-[1]-[safari-macosx]', 'In Safari app on your Mac, choose Safari > Preferences, then click Websites')
t('media.permissions-unblock-help-step-[2]-[safari-macosx]', 'Camera and Microphone settings are listed on the left')
t('media.permissions-unblock-help-step-[3]-[safari-macosx]', 'From here you can select to allow permission')
t('media.permissions-unblock-help-step-[1]-[safari-ios]', 'Go to Settings on your iPhone')
t('media.permissions-unblock-help-step-[2]-[safari-ios]', 'Scroll down and tap on Safari (or whichever browser you are using)')
t('media.permissions-unblock-help-step-[3]-[safari-ios]', 'Locate camera and microphone')
t('media.permissions-unblock-help-step-[4]-[safari-ios]', 'Make sure they are enabled')
t('media.permissions-unblock-help-step-[5]-[safari-ios]', 'Return to the browser and refresh the page')
t('media.permissions-unblock-help-step-[1]-[safari-ipados]', 'Go to Settings on your iPad')
t('media.permissions-unblock-help-step-[2]-[safari-ipados]', 'Scroll down and tap on Safari (or whichever browser you are using)')
t('media.permissions-unblock-help-step-[3]-[safari-ipados]', 'Locate camera and microphone')
t('media.permissions-unblock-help-step-[4]-[safari-ipados]', 'Make sure they are enabled')
t('media.permissions-unblock-help-step-[5]-[safari-ipados]', 'Return to the browser and refresh the page')
*/
export const UnblockHelpSteps: React.FC<{
    browserSpecificHelpers: BlockedBrowserPermissionsInfo;
    setScrollTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({browserSpecificHelpers, setScrollTrigger}) => {
    const {t} = useTranslation();

    return (
        <div className={'mt-11'}>
            <Accordion
                buttonClassName="pl-9 my-2 text-center"
                className={''}
                title={t(
                    `media.read-steps-label`,
                    `You can also read the steps here`,
                )}
                canOnlyToggleWithTitle
                onToggle={isOpen => {
                    setScrollTrigger(isOpen);
                }}
            >
                <List className={cx('text-left mt-8')} spacing="none">
                    {browserSpecificHelpers.textHelpSteps.map(
                        (step: {step: number; text: string}) => {
                            return (
                                <div key={step.step}>
                                    <Text fontVariant={FontVariant.BodyBold}>
                                        {t(
                                            `media.help-step-${step.step}-header`,
                                            `Step  ${step.step}`,
                                        )}
                                    </Text>
                                    <Text htmlTag="p" className="mb-3">
                                        {t(
                                            `media.permissions-unblock-help-step-${step.step}-${browserSpecificHelpers.browserName}`,
                                            step.text,
                                        )}
                                    </Text>
                                </div>
                            );
                        },
                    )}
                    <Text className={'mt-10 ml-0 text-center'}>
                        {t(
                            'media.permissions-try-this-label',
                            'If that does not work try this:',
                        )}
                    </Text>
                    <TextLink
                        className={'text-center '}
                        fontVariant={FontVariant.Body}
                        isUnderlined={true}
                        href={browserSpecificHelpers.link}
                        target="_blank"
                        aria-label={t(
                            'media.learn-how-to-manage-browser-privacy-settings-label',
                            'How to manage browser privacy settings (opens in a new tab)',
                        )}
                    >
                        {t(
                            'media.learn-how-to-manage-browser-privacy-settings',
                            'Browser privacy settings',
                        )}
                    </TextLink>
                </List>
            </Accordion>
        </div>
    );
};
