import React from 'react';

import {Box, ThemeProvider} from '@pexip/components';

import styles from './StepByStepCard.module.scss';

export const StepByStepCard: React.FC<{
    children: React.ReactElement;
    scrollableElementRef?: React.RefObject<HTMLDivElement>;
}> = ({children, scrollableElementRef}) => {
    return (
        <ThemeProvider colorScheme="light">
            <Box
                padding="medium"
                className={styles.stepByStepCard}
                ref={scrollableElementRef}
            >
                {children}
            </Box>
        </ThemeProvider>
    );
};
