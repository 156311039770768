import {useCallback, useEffect, useRef, useState} from 'react';

import {useConfig, config} from '../config';
import {infinityService} from '../services/InfinityClient.service';
import {infinityClientSignals} from '../signals/InfinityClient.signals';

import {useConferenceStatus} from './useConferenceStatus';

export type LiveCaptionsAPI = {
    data: string | undefined;
    isEnabled: boolean;
    toggleCaptions: () => void;
};

export const useLiveCaptions = () => {
    const [liveCaptionsEnabled, setLiveCaptionsEnabled] = useConfig(
        'prefersLiveCaptions',
    );
    const [captions, setCaptions] = useState('');
    const previousCaptions = useRef('');
    const timerRef = useRef<number>();

    const toggleCaptions = useCallback(() => {
        const enable = !liveCaptionsEnabled;
        setLiveCaptionsEnabled(enable);
        void infinityService.liveCaptions({enable});

        if (!enable) {
            setCaptions('');
        }
    }, [liveCaptionsEnabled, setLiveCaptionsEnabled]);

    useEffect(() => {
        if (!liveCaptionsEnabled) {
            return;
        }
        const detachLiveCaptions = infinityClientSignals.onLiveCaptions.add(
            newCaptions => {
                // update captions if the sentence is incrementing or final
                if (
                    newCaptions.isFinal ||
                    newCaptions.data.length > previousCaptions.current.length
                ) {
                    setCaptions(newCaptions.data);
                }
                // clear captions if the sentence hasn't changed in timeout duration
                clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setCaptions('');
                }, 5000);

                previousCaptions.current = newCaptions.data;
            },
        );

        return () => {
            clearTimeout(timerRef.current);
            detachLiveCaptions();
        };
    }, [liveCaptionsEnabled, setCaptions]);

    return {
        data: captions,
        isEnabled: liveCaptionsEnabled,
        toggleCaptions,
    };
};

export const useLiveCaptionsEnabled = () => {
    const isLiveCaptionsAvailable = Boolean(
        useConferenceStatus()?.liveCaptionsAvailable,
    );
    const [showLiveCaptionsFeature] = useConfig('showLiveCaptionsFeature');

    return isLiveCaptionsAvailable && showLiveCaptionsFeature;
};

export const initLiveCaptionsPreference = () => {
    if (config.get('prefersLiveCaptions')) {
        void infinityService.liveCaptions({
            enable: true,
        });
    }
};
