import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Modal, notificationToastSignal, ThemeProvider} from '@pexip/components';

import {transferParticipantSignal} from '../signals/Participant.signals';
import {infinityService} from '../services/InfinityClient.service';
import {Transfer} from '../views/Transfer/Transfer.view';

export const TransferParticipant: React.FC = () => {
    const {t} = useTranslation();
    const [identity, setIdentity] = useState<string>();
    const [destination, setDestination] = useState('');
    const [pin, setPin] = useState('');
    const [isHost, setHost] = useState(false);

    useEffect(() => transferParticipantSignal.add(setIdentity), []);

    const transferParticipant = useCallback(() => {
        setIdentity(undefined);
        setPin('');
        setHost(false);
        setDestination('');

        if (!identity) {
            return;
        }

        infinityService
            .transfer({
                participantUuid: identity,
                destination,
                pin,
                role: isHost ? 'host' : 'guest',
            })
            .then(res =>
                notificationToastSignal.emit([
                    {
                        message:
                            res?.status === 200 && res.data.result
                                ? t(
                                      'transfer.success',
                                      'Successfully transfered participant',
                                  )
                                : t(
                                      'transfer.fail',
                                      `Can't transfer participant`,
                                  ),
                    },
                ]),
            )
            .catch(() =>
                notificationToastSignal.emit([
                    {
                        message: t(
                            'transfer.error',
                            'Error occurred while transferring participant',
                        ),
                    },
                ]),
            );
    }, [destination, identity, isHost, pin, t]);

    return (
        <ThemeProvider colorScheme="light">
            <Modal
                isOpen={Boolean(identity)}
                onClose={() => setIdentity(undefined)}
                padding="medium"
                withCloseButton
            >
                <Transfer
                    setDestination={setDestination}
                    setHost={setHost}
                    setPin={setPin}
                    pin={pin}
                    isHost={isHost}
                    destination={destination}
                    transferParticipant={transferParticipant}
                />
            </Modal>
        </ThemeProvider>
    );
};
