export const validate200 = validate13;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {$ref: '#/properties/200/definitions/Result'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {
                    type: 'string',
                    description: '`call_uuid` is used to control the call',
                },
                url: {
                    type: 'string',
                    description:
                        'Insecure (rtmp://) RTMP URL that can be connected to by the client',
                },
                secure_url: {
                    type: 'string',
                    description:
                        'Secure (rtmps://) RTMP URL that can be connected to by the client',
                },
            },
            required: ['call_uuid', 'url', 'secure_url'],
            title: 'Result',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {$ref: '#/properties/200/definitions/Result'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {
            type: 'string',
            description: '`call_uuid` is used to control the call',
        },
        url: {
            type: 'string',
            description:
                'Insecure (rtmp://) RTMP URL that can be connected to by the client',
        },
        secure_url: {
            type: 'string',
            description:
                'Secure (rtmps://) RTMP URL that can be connected to by the client',
        },
    },
    required: ['call_uuid', 'url', 'secure_url'],
    title: 'Result',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema12.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.call_uuid === undefined &&
                                            (missing1 = 'call_uuid')) ||
                                        (data1.url === undefined &&
                                            (missing1 = 'url')) ||
                                        (data1.secure_url === undefined &&
                                            (missing1 = 'secure_url'))
                                    ) {
                                        validate11.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'call_uuid' ||
                                                    key1 === 'url' ||
                                                    key1 === 'secure_url'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.call_uuid !== undefined) {
                                                const _errs8 = errors;
                                                if (
                                                    typeof data1.call_uuid !==
                                                    'string'
                                                ) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/call_uuid',
                                                            schemaPath:
                                                                '#/properties/200/definitions/Result/properties/call_uuid/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs8 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data1.url !== undefined) {
                                                    const _errs10 = errors;
                                                    if (
                                                        typeof data1.url !==
                                                        'string'
                                                    ) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/url',
                                                                schemaPath:
                                                                    '#/properties/200/definitions/Result/properties/url/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs10 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data1.secure_url !==
                                                        undefined
                                                    ) {
                                                        const _errs12 = errors;
                                                        if (
                                                            typeof data1.secure_url !==
                                                            'string'
                                                        ) {
                                                            validate11.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/result/secure_url',
                                                                        schemaPath:
                                                                            '#/properties/200/definitions/Result/properties/secure_url/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'string',
                                                                        },
                                                                        message:
                                                                            'must be string',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs12 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate11.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
export const validate400 = validate15;
const schema17 = {type: 'string'};
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate15.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate15.errors = vErrors;
    return errors === 0;
}
export const validate403 = validate16;
const schema18 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {type: 'string', enum: ['Invalid token']},
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [{$ref: '#/properties/403/definitions/403'}],
};
const schema14 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {type: 'string', enum: ['Invalid token']},
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate16(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema14.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (!(data1 === 'Invalid token')) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema14.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath: '#/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate16.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate16.errors = vErrors;
    return errors === 0;
}
export const validate500 = validate17;
const schema20 = {type: 'string'};
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate17.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validateBody = validate18;
const schema21 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_type: {
                    type: 'string',
                    enum: ['RTMP'],
                    description: '`RTMP` for an RTMP call.',
                },
                present: {
                    type: 'string',
                    enum: ['send', 'receive'],
                    description:
                        'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
                },
                streaming: {
                    type: 'boolean',
                    description:
                        'Optional field. Set to true if this is to be treated as a streaming participant for recording purposes.',
                },
                bandwidth: {
                    type: 'number',
                    description:
                        'Optional field. If supplied it provides a maximum incoming / outgoing bandwidth in kbps.',
                },
            },
            required: ['call_type'],
            title: 'TopLevel',
        },
    },
    oneOf: [{$ref: '#/properties/Body/definitions/TopLevel'}],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_type: {
            type: 'string',
            enum: ['RTMP'],
            description: '`RTMP` for an RTMP call.',
        },
        present: {
            type: 'string',
            enum: ['send', 'receive'],
            description:
                'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
        },
        streaming: {
            type: 'boolean',
            description:
                'Optional field. Set to true if this is to be treated as a streaming participant for recording purposes.',
        },
        bandwidth: {
            type: 'number',
            description:
                'Optional field. If supplied it provides a maximum incoming / outgoing bandwidth in kbps.',
        },
    },
    required: ['call_type'],
    title: 'TopLevel',
};
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.call_type === undefined && (missing0 = 'call_type')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_type' ||
                            key0 === 'present' ||
                            key0 === 'streaming' ||
                            key0 === 'bandwidth'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.call_type !== undefined) {
                        let data0 = data.call_type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/properties/Body/definitions/TopLevel/properties/call_type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'RTMP')) {
                            const err2 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.call_type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.present !== undefined) {
                            let data1 = data.present;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/present',
                                    schemaPath:
                                        '#/properties/Body/definitions/TopLevel/properties/present/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (!(data1 === 'send' || data1 === 'receive')) {
                                const err4 = {
                                    instancePath: instancePath + '/present',
                                    schemaPath:
                                        '#/properties/Body/definitions/TopLevel/properties/present/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.present.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.streaming !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.streaming !== 'boolean') {
                                    const err5 = {
                                        instancePath:
                                            instancePath + '/streaming',
                                        schemaPath:
                                            '#/properties/Body/definitions/TopLevel/properties/streaming/type',
                                        keyword: 'type',
                                        params: {type: 'boolean'},
                                        message: 'must be boolean',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.bandwidth !== undefined) {
                                    const _errs11 = errors;
                                    if (!(typeof data.bandwidth == 'number')) {
                                        const err6 = {
                                            instancePath:
                                                instancePath + '/bandwidth',
                                            schemaPath:
                                                '#/properties/Body/definitions/TopLevel/properties/bandwidth/type',
                                            keyword: 'type',
                                            params: {type: 'number'},
                                            message: 'must be number',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err7 = {
                instancePath,
                schemaPath: '#/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err7];
            } else {
                vErrors.push(err7);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err8 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err8];
        } else {
            vErrors.push(err8);
        }
        errors++;
        validate18.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
