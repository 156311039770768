import {Color} from '@pexip/components';

export const randomNumber = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

export const animateProgress = (currentProgress: number) =>
    `linear-gradient(to right, ${Color.DeepBlue20} ${currentProgress}%, ${Color.DeepBlue10} ${currentProgress}% 100%)`;

export const getClampLevelFn =
    (factor = 1) =>
    (level: number) =>
        Math.round(level / factor) / 100;
