import React, {useState} from 'react';

import {Audio, FontVariant, useTouchDevice} from '@pexip/components';
import {
    onDeviceSelectChange,
    TestYourMicStages,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    useMediaInputs,
    useSelectAudioOutput,
    YouCanSayTitle,
} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {findAudioInputDevices} from '@pexip/media-control';
import type {Media, PreviewInput, UserMediaStatus} from '@pexip/media';

import {MicrophoneStep} from '../views/MicrophoneStep/MicrophoneStep.view';
import {
    useChromiumLoopbackConnection,
    useMicrophoneMonitorAnalyzer,
} from '../hooks/useMicrophoneMonitorAnalyzer';
import {mediaSignals} from '../signals/Media.signals';
import {logger} from '../logger';
import {config, useConfig} from '../config';
import {mediaService} from '../services/Media.service';
import {BARS_NUMBER} from '../constants';
import {
    useAnimateFrequencyBars,
    useByteFrequencyData,
} from '../hooks/useAnimateFrequencyBars';
import {FrequencyBarsAudioMeterWithContent} from '../views/OneTimeDeviceSelection/FrequencyBarsAudioMeterWithContent.view';
import {MicrophoneStepTip} from '../views/MicrophoneStep/MicrophoneStepTip.view';

const BARS_WRAPPER_ID = 'step-by-step-mic-test-bars-wrapper-id';

export const Microphone: React.FC<
    React.PropsWithChildren<{
        devices: MediaDeviceInfoLike[];
        media: Media;
        next: () => void;
        streamStatus: UserMediaStatus;
        no: () => void;
        help: () => void;
        accessibilityHelpExpanded: boolean;
    }>
> = ({
    devices,
    media,
    next,
    streamStatus,
    no,
    help,
    accessibilityHelpExpanded,
}) => {
    const [savedAudioOutput] = useConfig('audioOutput');
    const [isEnabled, setEnabled] = useState(true);
    const isTouchDevice = useTouchDevice();

    const {selectedAudioOutput} = useSelectAudioOutput(
        devices,
        savedAudioOutput,
    );
    const {selectedAudioInput, selectedVideoInput} = useMediaInputs(media);
    const {setVideoInputError, audioInputError, setAudioInputError} =
        useDeviceErrorMessageState();

    useDeviceErrorMessage(setAudioInputError, setVideoInputError, streamStatus);

    const {analyzer, outputStream} = useMicrophoneMonitorAnalyzer({
        media,
        mediaChangeSubscribe: mediaSignals.onMediaChanged.add,
        isEnabled,
    });

    const microphoneFrequencyData = useByteFrequencyData(analyzer, BARS_NUMBER);

    useAnimateFrequencyBars({
        currentStage: TestYourMicStages.Recording,
        microphoneFrequencyData,
        barsWrapperId: BARS_WRAPPER_ID,
    });

    const srcObject = useChromiumLoopbackConnection(outputStream);

    const onAudioInputChange = onDeviceSelectChange(
        setAudioInputError,
        (input: PreviewInput) => {
            const {audioInput: prevAudioInput} = mediaService.media;
            if (input?.deviceId === prevAudioInput?.deviceId) {
                return;
            }
            const constraints = {
                audio: {device: {exact: input}},
                video: selectedVideoInput,
            };
            logger.debug(constraints, 'Save AudioInput');
            config.set({key: 'audioInput', value: input, persist: true});

            mediaService.getUserMedia(constraints);
        },
    );

    return (
        <MicrophoneStep
            help={help}
            device={selectedAudioInput}
            deviceError={audioInputError}
            devices={findAudioInputDevices(devices)}
            no={no}
            onDeviceChange={onAudioInputChange}
            yes={next}
            isTouchDevice={isTouchDevice}
            accessibilityHelpExpanded={accessibilityHelpExpanded}
        >
            <Audio
                srcObject={srcObject}
                sinkId={selectedAudioOutput?.deviceId || ''}
                autoPlay
            />
            <FrequencyBarsAudioMeterWithContent
                barsWrapperId={BARS_WRAPPER_ID}
                //FIXME: do we need event listener for touchscreen too?
                onClick={() => setEnabled(enabled => !enabled)}
                shouldDismiss
            >
                <MicrophoneStepTip>
                    <YouCanSayTitle
                        titleVariant={FontVariant.Body}
                        countTitleVariant={FontVariant.H5}
                    />
                </MicrophoneStepTip>
            </FrequencyBarsAudioMeterWithContent>
        </MicrophoneStep>
    );
};
