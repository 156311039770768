import type {MediaDeviceRequest} from '@pexip/media-control';
import {
    muteStreamTrack,
    stopMediaStream,
    extractConstraintsWithKeys,
} from '@pexip/media-control';
import {isEmpty} from '@pexip/utils';
import type {AudioGraph, AudioNodeInit} from '@pexip/media-processor';
import {
    createAudioGraph,
    createAudioGraphProxy,
    createChannelMergerGraphNode,
    createStreamDestinationGraphNode,
    createStreamSourceGraphNode,
} from '@pexip/media-processor';

import type {Process, Media} from './types';
import {logger} from './logger';
import {applyExtendedConstraints, shallowCopy, wrapToJSON} from './utils';

interface AudioStreamProcessorProps {
    mixWithAdditionalMedia?: boolean;
    merger?: AudioNodeInit<ChannelMergerNode, ChannelMergerNode>;
    displaySource?: AudioNodeInit<
        MediaStreamAudioSourceNode,
        MediaStreamAudioSourceNode
    >;
    audioGraph?: AudioGraph;
}

const FEATURE_KEYS: ['mixWithAdditionalMedia'] = ['mixWithAdditionalMedia'];
type FeaturePropKeys = typeof FEATURE_KEYS[number];
type FeatureProps = Pick<AudioStreamProcessorProps, FeaturePropKeys>;

const getAudioConstraints = extractConstraintsWithKeys(FEATURE_KEYS);

export const updateFeatureProps = (
    constraints: MediaDeviceRequest['audio'],
    props: FeatureProps,
) => {
    const extracted = getAudioConstraints(constraints);
    return FEATURE_KEYS.reduce((accm, key) => {
        const [feature] = extracted[key];
        if (feature !== undefined && props[key] !== feature) {
            props[key] = feature;
            return {...accm, [key]: feature};
        }
        return accm;
    }, {} as FeatureProps);
};

/**
 * Create a Audio Mixing Processor and will own the stream passed-in
 */
export const createAudioMixingProcess = (
    getCurrrentMedia: () => MediaStream | undefined,
    scope = 'mixer',
): Process<Promise<Media>> => {
    const props: AudioStreamProcessorProps = {};
    return async mediaP => {
        const media = await mediaP;
        updateFeatureProps(media.constraints?.audio, props);

        const [mainTrack] = media.stream?.getAudioTracks() ?? [];
        const displayStream = getCurrrentMedia();
        const [displayTrack] = displayStream?.getAudioTracks?.() ?? [];

        const applyConstraints: Media['applyConstraints'] =
            applyExtendedConstraints(media, async constraints => {
                if (isEmpty(constraints.audio)) {
                    return;
                }
                const features = updateFeatureProps(constraints.audio, props);
                logger.debug(
                    {scope, constraints: constraints.audio, features},
                    'apply audio mixing constraints',
                );
                if (
                    isEmpty(features) ||
                    (props.audioGraph &&
                        ['closed', 'closing'].includes(props.audioGraph.state))
                ) {
                    return;
                }
                if (features.mixWithAdditionalMedia) {
                    const newStream = getCurrrentMedia();
                    const [newTrack] =
                        getCurrrentMedia()?.getAudioTracks() ?? [];
                    if (!newTrack) {
                        return;
                    }
                    if (!props.audioGraph || !props.merger) {
                        // TODO: Should update the media stream directly
                        // return replace(media.stream, newTrack);
                        logger.debug(
                            {scope},
                            'Should update the media stream directly',
                        );
                        return;
                    }
                    if (props.displaySource && props.merger) {
                        if (
                            newStream ===
                            props.displaySource.audioNode?.mediaStream
                        ) {
                            return;
                        }
                        props.audioGraph.disconnect([
                            props.displaySource,
                            props.merger,
                        ]);
                    }
                    if (newStream) {
                        props.displaySource =
                            createStreamSourceGraphNode(newStream);
                        props.audioGraph.connect([
                            props.displaySource,
                            props.merger,
                        ]);
                    }
                } else if (features.mixWithAdditionalMedia === false) {
                    // Unmixing
                    if (props.displaySource) {
                        props.displaySource.release();
                        props.audioGraph?.disconnect([props.displaySource]);
                        props.displaySource = undefined;
                    }
                }

                return Promise.resolve();
            });

        if (!media.stream) {
            return media;
        }

        if (!mainTrack && displayTrack) {
            media.stream?.addTrack(displayTrack);
            return shallowCopy(media, {
                muteAudio: () => {
                    /* Do Nothing */
                },
                applyConstraints,
            });
        }

        try {
            const mainSource = createStreamSourceGraphNode(media.stream);
            props.displaySource =
                displayStream && createStreamSourceGraphNode(displayStream);
            props.merger = createChannelMergerGraphNode();
            const destination = createStreamDestinationGraphNode();
            const initialAudioNodeConnection = [
                [mainSource, props.merger],
                [props.merger, destination],
            ];
            logger.debug(
                {initialAudioNodeConnection, scope},
                'Initial AudioNodeConnection',
            );
            props.audioGraph = createAudioGraphProxy(
                createAudioGraph(initialAudioNodeConnection),
                {
                    connect: (target, args) => {
                        logger.debug({scope, target, args}, 'connect nodes');
                    },
                    disconnect: (target, args) => {
                        logger.debug({scope, target, args}, 'disconnect nodes');
                    },
                },
            );
            if (props.displaySource) {
                props.audioGraph.connect([props.displaySource, props.merger]);
            }
            const tracks = [
                ...(destination?.node?.stream.getAudioTracks() ?? []),
                ...(media.stream?.getVideoTracks().map(t => t.clone()) ?? []),
            ];
            const stream = new MediaStream(tracks);

            const release = async () => {
                logger.debug({scope}, 'Release Media');
                stopMediaStream(stream);
                // Release Props
                await props.audioGraph?.release();
                await media.release();
                props.audioGraph = undefined;
                props.merger = undefined;
                props.displaySource = undefined;
            };
            const muteAudio = (mute: boolean) => {
                media.muteAudio(mute);
                // TODO: dbl check if this is safe
                muteStreamTrack(mainSource.audioNode?.mediaStream)(
                    mute,
                    'audio',
                );
            };

            const prevGetSettings = media.getSettings;
            return wrapToJSON(
                shallowCopy(media, {
                    stream,
                    applyConstraints,
                    muteAudio,
                    release,
                    getSettings: () => {
                        const {audio, video} = prevGetSettings();
                        const mixWithAdditionalMedia =
                            !!props.mixWithAdditionalMedia;
                        const audioSettings = {
                            mixWithAdditionalMedia,
                        };
                        const settings = {
                            audio: audio.map(settings => ({
                                ...settings,
                                ...audioSettings,
                            })),
                            video,
                        };
                        logger.debug(
                            {scope, settings: audioSettings},
                            'get audio mixing processor settings',
                        );
                        return settings;
                    },
                }),
            );
        } catch (error: unknown) {
            logger.error(
                {scope, error},
                'Unable to use WebAudio, return the raw media instead',
            );
            return media;
        }
    };
};
