import React, {useCallback, useLayoutEffect, useRef} from 'react';
import cx from 'classnames';

import {useIsInWindowBottomHalf, useIsInWindowRightHalf} from '@pexip/hooks';

import type {InMeetingDraggablePosition} from '../InMeetingDraggable/InMeetingDraggable.view';
import {InMeetingDraggable} from '../InMeetingDraggable/InMeetingDraggable.view';

import type {FoldedSelfviewProps} from './FoldedSelfview.view';
import {FoldedSelfview} from './FoldedSelfview.view';
import type {InMeetingSelfviewProps} from './InMeetingSelfview.view';
import {InMeetingSelfview} from './InMeetingSelfview.view';

import styles from './Selfview.module.scss';

export const DraggableFoldableInMeetingSelfview: React.FC<
    InMeetingSelfviewProps &
        FoldedSelfviewProps & {
            isFolded: boolean;
            floatRoot?: React.RefObject<HTMLDivElement>;
            isSidePanelVisible: boolean;
        }
> = ({isFolded, floatRoot, isSidePanelVisible, ...props}) => {
    const ref = useRef<HTMLDivElement>(null);
    const alignWithFloatRoot = useRef<(() => void) | null>(null);

    const {isInBottomHalf, update: updateIsInBottomHalf} =
        useIsInWindowBottomHalf();
    const {isInRightHalf, update: updateIsInRightHalf} =
        useIsInWindowRightHalf();

    const onPositionChangeCb = useCallback(
        (el: HTMLDivElement, position: InMeetingDraggablePosition) => {
            updateIsInBottomHalf(el);
            updateIsInRightHalf(el);
            if (position.floatRootOverflow.right) {
                el.style.removeProperty('left');
            }
        },
        [updateIsInBottomHalf, updateIsInRightHalf],
    );

    useLayoutEffect(() => {
        // using layout effect here because we are fiddling with the element's dimensions
        // https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
        if (ref.current && floatRoot?.current && alignWithFloatRoot.current) {
            alignWithFloatRoot.current();
        }
    }, [floatRoot, isFolded]);

    const getClassNames = () =>
        isFolded
            ? {[styles.foldedSelfviewWrapper]: true}
            : {
                  [styles.pip]: true,
              };

    const notificationTooltipPos = isInBottomHalf
        ? isInRightHalf
            ? 'topLeft'
            : 'topRight'
        : isInRightHalf
        ? 'bottomLeft'
        : 'bottomRight';

    const selfviewBtnTooltipPos = isInBottomHalf ? 'top' : 'bottom';

    return (
        <InMeetingDraggable
            floatRoot={floatRoot}
            isSidePanelVisible={isSidePanelVisible}
            isPhone={props.isPhone}
            onRepositionSideEffect={onPositionChangeCb}
            shouldCaptureClick={false}
            className={cx(styles.inMeetingSelfviewPosition, getClassNames())}
            ref={ref}
            alignWithFloatRootTrigger={alignWithFloatRoot}
        >
            {isFolded ? (
                <FoldedSelfview
                    {...props}
                    notificationTooltipPos={notificationTooltipPos}
                    selfviewBtnTooltipPos={selfviewBtnTooltipPos}
                />
            ) : (
                <InMeetingSelfview {...props} />
            )}
        </InMeetingDraggable>
    );
};
