import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Bar,
    Button,
    FontVariant,
    Form,
    Input,
    Text,
    TextHeading,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {ResponsiveBox} from '../ResponsiveBox/ResponsiveBox.view';

export const FreeDialStep: React.FC<{
    meetingId: string;
    onMeetingIdChange: (meetingId: string) => void;
    next: (meetingId: string) => void;
}> = ({meetingId, onMeetingIdChange, next}) => {
    const {t} = useTranslation();

    return (
        <ResponsiveBox>
            <TextHeading
                htmlTag="h2"
                fontVariant={FontVariant.H4}
                className="mb-2"
            >
                <Trans t={t} i18nKey="free-dial.join">
                    Join Meeting
                </Trans>
            </TextHeading>
            <Text fontVariant={FontVariant.H6} variant="tinted">
                <Trans t={t} i18nKey="free-dial.how-to-find">
                    You can find the Meeting ID in the meeting invite in your
                    calendar or email
                </Trans>
            </Text>
            <Form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    next(meetingId.trim());
                }}
            >
                <Input
                    autoComplete="number"
                    className="mt-4 mb-4"
                    label={t('free-dial.meeting-id', 'Meeting ID')}
                    name="meeting-id"
                    placeholder={t('free-dial.placeholder', `e.g. 'meet.bob'`)}
                    type="text"
                    value={meetingId}
                    onValueChange={onMeetingIdChange}
                    data-testid={TestId.InputFreeDialId}
                />

                <Bar>
                    <Button
                        modifier="fullWidth"
                        type="submit"
                        size="large"
                        isDisabled={!meetingId.trim()}
                        data-testid={TestId.ButtonFreeDialNext}
                    >
                        <Trans t={t} i18nKey="common.next">
                            Next
                        </Trans>
                    </Button>
                </Bar>
            </Form>
        </ResponsiveBox>
    );
};

export type FreeDialStepProps = React.ComponentProps<typeof FreeDialStep>;
