import type {RefObject} from 'react';
import React from 'react';

import {CenterLayout, FontVariant, Text} from '@pexip/components';
import type {InMeetingUI, usePresentation} from '@pexip/media-components';
import {
    useMeetingStageLayout,
    MeetingStageContainer,
    PresentationMeetingVideo,
    StreamStatus,
} from '@pexip/media-components';
import type {SplashScreen} from '@pexip/infinity';

import {TestId} from '../../test/testIds';
import type {LiveCaptionsAPI} from '../hooks/useLiveCaptions';
import {LiveCaptionsWrapper} from '../views/LiveCaptions/LiveCaptionsWrapper.view';

import {InMeetingMainStream} from './InMeetingMainStream.viewModel';

export const MeetingStage = React.forwardRef<
    HTMLDivElement,
    {
        presentation: ReturnType<typeof usePresentation>;
        inMeetingUI: InMeetingUI;
        splashScreen?: SplashScreen;
        stream?: MediaStream;
        captions: LiveCaptionsAPI;
    }
>(
    (
        {
            inMeetingUI,
            presentation: {
                isPresenting,
                remoteIsPresenting,
                localIsPresenting,
                localMediaStream,
                remoteMediaStream,
                isPrimaryExpanded: expandPrimaryVideo,
                poppedOut: {
                    isPresentationPoppedOut,
                    remotePopOutRef,
                    setIsPresentationPoppedOut,
                },
                emphasis: presentationEmphasis,
                setEmphasis,
            },
            stream,
            captions,
            splashScreen,
        },
        ref,
    ) => {
        const {
            meetingStreamStatus,
            presoStreamStatus,
            setIsMeetingVideoBrowserPip,
            setIsPresentationBrowserPip,
            handleMeetingVideoClick,
            handlePresentationVideoClick,
        } = useMeetingStageLayout({
            expandPrimaryVideo,
            isPresenting,
            isPresentationPoppedOut,
            presentationEmphasis,
            setEmphasis,
        });

        return (
            <MeetingStageContainer
                isSidePanelVisible={inMeetingUI.showSidePanel}
                isPrimaryVideoExpanded={
                    meetingStreamStatus === StreamStatus.Expanded ||
                    presoStreamStatus === StreamStatus.Expanded
                }
                ref={ref}
            >
                {splashScreen && 'text' in splashScreen ? (
                    <CenterLayout>
                        <Text fontVariant={FontVariant.H2}>
                            {splashScreen.text}
                        </Text>
                    </CenterLayout>
                ) : (
                    <InMeetingMainStream
                        status={meetingStreamStatus}
                        handleVideoClick={handleMeetingVideoClick}
                        data-testid={TestId.InMeetingVideoWrapper}
                        mediaStream={stream}
                        onPictureInPictureChange={setIsMeetingVideoBrowserPip}
                        isSidePanelVisible={inMeetingUI.showSidePanel}
                        floatRoot={ref as RefObject<HTMLDivElement>}
                    />
                )}
                {(isPresentationPoppedOut || isPresenting) && (
                    <PresentationMeetingVideo
                        status={presoStreamStatus}
                        localIsPresenting={localIsPresenting}
                        localMediaStream={localMediaStream}
                        remoteIsPresenting={remoteIsPresenting}
                        remoteMediaStream={remoteMediaStream}
                        remotePopOut={remotePopOutRef.current}
                        handleVideoClick={handlePresentationVideoClick}
                        setIsPresentationBrowserPip={
                            setIsPresentationBrowserPip
                        }
                        setIsPresentationPoppedOut={setIsPresentationPoppedOut}
                        isSidePanelVisible={inMeetingUI.showSidePanel}
                        floatRoot={ref as RefObject<HTMLDivElement>}
                    />
                )}
                <LiveCaptionsWrapper captions={captions.data} />
            </MeetingStageContainer>
        );
    },
);

MeetingStage.displayName = 'MeetingStage';
