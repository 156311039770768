import {useEffect} from 'react';

import {animateProgress} from '../utils/frequencyBarsAudioMeter';

export const useFrequencyBarsBackgroundProgress = (
    totalPlaybackDuration: number,
    currentPlaybackDuration: number,
    progressBackgroundId: string,
) => {
    useEffect(() => {
        const progressBackground =
            document.getElementById(progressBackgroundId);

        if (progressBackground) {
            progressBackground.style.background = animateProgress(
                (currentPlaybackDuration / totalPlaybackDuration) * 100 || 0,
            );
        }
    }, [currentPlaybackDuration, progressBackgroundId, totalPlaybackDuration]);
};
