import React from 'react';

import {FreeDial} from '../viewModels/FreeDial.viewModel';
import {Header} from '../viewModels/Header.viewModel';
import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {BrandedInfoCard} from '../viewModels/BrandedInfoCard.viewModel';
import {PreflightLayout} from '../views/PreflightLayout/PreflightLayout.view';

export const Home: React.FC = () => (
    <BrandedFullSizeWindow
        padding="none"
        background="image"
        flexDirection="column"
        justifyContent="flexStart"
    >
        <Header />
        <PreflightLayout
            startContent={<BrandedInfoCard />}
            endContent={<FreeDial />}
        />
    </BrandedFullSizeWindow>
);
