import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {notificationToastSignal} from '@pexip/components';
import type {InMeetingUI} from '@pexip/media-components';
import {
    useResetCopyMeetingLink,
    InMeetingUIElements,
    InvitationModalWithTransform,
    copyTextToClipboard,
} from '@pexip/media-components';

import {logger} from '../logger';
import {infinityService} from '../services/InfinityClient.service';
import {enableInMeetingUIAutoHide} from '../signals/InMeeting.signals';
import {InviteSomeone} from '../views/InviteSomeone/InviteSomeone.view';

export const AddParticipant: React.FC<{inMeetingUI: InMeetingUI}> = ({
    inMeetingUI: {panelsState, togglePanels},
}) => {
    const {t} = useTranslation();

    const [destination, setDestination] = useState('');
    const [isHost, setHost] = useState(false);
    const [isCopied, setCopied] = useState(false);

    const isOpen = panelsState.openAddParticipant;
    const shouldTransform =
        panelsState.openChatPanel || panelsState.openParticipantPanel;

    useResetCopyMeetingLink(setCopied, isCopied);

    useEffect(
        () =>
            enableInMeetingUIAutoHide.emit({
                type: InMeetingUIElements.inviteModal,
                isOpen,
            }),
        [isOpen],
    );

    const addParticipant = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (destination.trim().length === 0) {
                return;
            }

            setDestination('');
            infinityService
                .dial({destination, role: isHost ? 'HOST' : 'GUEST'})
                .then(result => {
                    if (!result || result.data.result.length === 0) {
                        notificationToastSignal.emit([
                            {
                                message: t(
                                    'add-participant.failed',
                                    'Failed to add this participant',
                                ),
                            },
                        ]);
                    }
                })
                .catch((reason: unknown) => {
                    logger.warn({reason}, `Can't dial`);
                });
        },
        [destination, isHost, t],
    );

    const handleLinkClick = useCallback(() => {
        copyTextToClipboard(window.location.href)
            .then(() => {
                setCopied(true);
            })
            .catch((reason: unknown) => {
                logger.error({reason}, 'Copy link to clipboard failed');
            });
    }, []);

    return (
        <InvitationModalWithTransform
            isOpen={isOpen}
            shouldTransform={shouldTransform}
            onClose={() => togglePanels('add')}
            closeOnOutsideClick={false}
        >
            <InviteSomeone
                addParticipant={addParticipant}
                destination={destination}
                handleLinkClick={handleLinkClick}
                isCopied={isCopied}
                isHost={isHost}
                setDestination={setDestination}
                setHost={setHost}
            />
        </InvitationModalWithTransform>
    );
};
