import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {AspectRatio, IconTypes} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {DeviceError} from '@pexip/media-components';
import {
    currentBrowserName,
    getLearnAboutRequestPermissionsUrl,
    TestId as MediaTestId,
    MissingDeviceAlert,
    DeviceSelect,
} from '@pexip/media-components';
import type {UserMediaStatus} from '@pexip/media';

import {TestId} from '../../../test/testIds';
import {StepActions} from '../StepActions/StepActions.view';
import {StepLayout} from '../StepLayout/StepLayout.view';

export const MicrophoneStep: React.FC<
    React.PropsWithChildren<{
        device?: MediaDeviceInfoLike;
        deviceError: DeviceError;
        devices: MediaDeviceInfoLike[];
        isTouchDevice?: boolean;
        help: () => void;
        no: () => void;
        onDeviceChange: (device: MediaDeviceInfoLike) => void;
        stream?: MediaStream;
        streamStatus?: UserMediaStatus;
        yes: () => void;
        accessibilityHelpExpanded: boolean;
    }>
> = ({
    children,
    device,
    deviceError,
    devices,
    isTouchDevice,
    onDeviceChange,
    yes,
    no,
    help,
    accessibilityHelpExpanded,
}) => {
    const {t} = useTranslation();

    const [showErrorTooltip, setShowErrorTooltip] = useState(false);

    const learnHowToGrantAccessURL =
        getLearnAboutRequestPermissionsUrl(currentBrowserName);

    return (
        <StepLayout
            startContent={
                <>
                    <div className="mb-3">
                        <AspectRatio>{children}</AspectRatio>
                    </div>
                    {!isTouchDevice ? (
                        deviceError.deniedDevice ||
                        (deviceError.title && !devices.length) ? (
                            <MissingDeviceAlert
                                className="mb-1"
                                title={deviceError.title}
                                tooltip={deviceError.description}
                                deniedDevice={deviceError.deniedDevice}
                                learnHowToGrantAccessURL={
                                    learnHowToGrantAccessURL
                                }
                                data-testid={
                                    MediaTestId.MissingAudioDeviceAlert
                                }
                                setIsTooltipVisible={setShowErrorTooltip}
                                isTooltipVisible={showErrorTooltip}
                                icon={IconTypes.IconMicrophoneOn}
                            />
                        ) : (
                            <DeviceSelect
                                isDisabled={false}
                                label={t(
                                    'settings.device-select.select-microphone',
                                    'Select microphone',
                                )}
                                data-testid={MediaTestId.SelectAudioInput}
                                errorText={deviceError.title}
                                errorTextTestId={
                                    MediaTestId.SelectAudioInputErrorText
                                }
                                hasError={!!deviceError.title}
                                onDeviceChange={onDeviceChange}
                                iconType={IconTypes.IconMicrophoneOn}
                                mediaDeviceInfoLike={device}
                                devices={devices}
                            />
                        )
                    ) : null}
                </>
            }
            endContent={
                <StepActions
                    yes={yes}
                    no={no}
                    help={help}
                    headerTranslation={
                        <Trans t={t} i18nKey="microphone.do-you-hear-yourself">
                            Do you hear yourself?
                        </Trans>
                    }
                    headerTestid={TestId.StepHeaderMicrophone}
                    helpButtonTranslation={
                        <Trans t={t} i18nKey="microphone.cant-hear">
                            I can&apos;t hear due to a disability
                        </Trans>
                    }
                    noTestId={TestId.ButtonMicrophoneStepNo}
                    yesTestId={TestId.ButtonMicrophoneStepYes}
                    helpTestId={TestId.ButtonMicrophoneStepCantHear}
                    accessibilityHelpExpanded={accessibilityHelpExpanded}
                />
            }
        />
    );
};

export type MicrophoneStepProps = React.ComponentProps<typeof MicrophoneStep>;
