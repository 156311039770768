import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {Box, Button, Icon, IconTypes} from '@pexip/components';

import {stopMouseEventPropagation} from '../../utils/domEventsHandles';

import styles from './SelfviewAudioButton.module.scss';

export const SelfviewAudioButton: React.FC<{
    isAudioInputMuted?: boolean;
    isAudioInputMissing?: boolean;
    isPhone?: boolean;
    onToggleAudioClick: () => void;
    stopPointerDownEventPropagation?: boolean;
}> = ({
    isAudioInputMuted,
    isAudioInputMissing,
    isPhone = false,
    onToggleAudioClick,
    stopPointerDownEventPropagation = false,
    ...props
}) => {
    const {t} = useTranslation();

    if (isPhone) {
        return (
            <Box
                className={cx(styles.audioButton, styles.phone)}
                colorScheme="light"
            >
                <Button
                    aria-label={
                        isAudioInputMuted
                            ? t('media.unmute-microphone', 'Unmute microphone')
                            : t('media.mute-microphone', 'Mute microphone')
                    }
                    size="none"
                    noHover
                    onClick={onToggleAudioClick}
                    onPointerDownCapture={stopMouseEventPropagation(
                        stopPointerDownEventPropagation,
                    )}
                    {...props}
                >
                    <Icon
                        source={
                            isAudioInputMuted
                                ? IconTypes.IconMicrophoneOff
                                : IconTypes.IconMicrophoneOn
                        }
                        className={styles.audioInputMutedIcon}
                        size="mini"
                    />
                </Button>
            </Box>
        );
    }

    return (
        <Button
            aria-label={
                isAudioInputMuted
                    ? t('media.unmute-microphone', 'Unmute microphone')
                    : t('media.mute-microphone', 'Mute microphone')
            }
            variant={
                isAudioInputMuted || isAudioInputMissing
                    ? 'primary'
                    : 'tertiary'
            }
            colorScheme={isAudioInputMissing ? 'light' : 'dark'}
            size="none"
            onClick={onToggleAudioClick}
            onPointerDownCapture={stopMouseEventPropagation(
                stopPointerDownEventPropagation,
            )}
            className={cx(
                styles.audioButton,
                isAudioInputMissing && styles.disabled,
            )}
            isDisabled={isAudioInputMissing}
            {...props}
        >
            <Icon
                source={
                    isAudioInputMuted || isAudioInputMissing
                        ? IconTypes.IconMicrophoneOff
                        : IconTypes.IconMicrophoneOn
                }
                size="compact"
            />
        </Button>
    );
};

export type SelfviewAudioButtonProps = React.ComponentProps<
    typeof SelfviewAudioButton
>;
