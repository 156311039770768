import React from 'react';

import type {InMeetingParticipant} from '@pexip/media-components';
import {ParticipantRow} from '@pexip/media-components';

import {useSpeaking} from '../hooks/useSpeaking';

export const Participant: React.FC<{
    canControl?: boolean;
    currentUserId?: string;
    isHost?: boolean;
    participant: InMeetingParticipant;
    handleRemoveUserRequest: (
        participant: InMeetingParticipant,
        displayName: string,
    ) => void;
    handleTransfer: (identity: string) => void;
}> = ({
    canControl = false,
    currentUserId,
    handleRemoveUserRequest,
    handleTransfer,
    isHost = false,
    participant,
}) => {
    const isSpeaking = useSpeaking(participant.identity);

    return (
        <ParticipantRow
            canPerformHostActions={canControl}
            displayName={participant.displayName ?? 'User'}
            handleRemoveUserRequest={handleRemoveUserRequest}
            handleTransfer={handleTransfer}
            isCurrentPeer={participant.identity === currentUserId}
            isHost={isHost}
            isSpeaking={isSpeaking}
            participant={participant}
        />
    );
};
