import React from 'react';
import cx from 'classnames';

import styles from './ImageWrapper.module.scss';

export const ImageWrapper: React.FC<
    React.ComponentProps<'div'> & {backgroundUrl: string}
> = ({backgroundUrl, className, ...props}) => (
    <div
        style={{
            backgroundImage: `url('${backgroundUrl}')`,
        }}
        className={cx(styles.imageWrapper, className)}
        {...props}
    />
);

export type ImageWrapperProps = React.ComponentProps<typeof ImageWrapper>;
