export const audioMimTypes = [
    'audio/ogg; codecs=opus',
    'audio/webm; codecs=opus',
    'audio/mp4; codecs=avc1',
];

export const getMimeType = () =>
    audioMimTypes.find(mimeType => MediaRecorder.isTypeSupported(mimeType));

export const createAudioMediaRecorderFn =
    (chunks: Blob[] = [], blobPropertyBagType = 'audio/ogg; codecs=opus') =>
    (
        setObjectURL: React.Dispatch<React.SetStateAction<string>>,
        recorder: MediaRecorder,
    ) => {
        if (!recorder) {
            return;
        }

        recorder.ondataavailable = e => {
            chunks.push(e.data);

            if (recorder.state === 'inactive') {
                setObjectURL(
                    URL.createObjectURL(
                        new Blob(chunks, {
                            type: blobPropertyBagType,
                        }),
                    ),
                );
            }
        };

        recorder.onstop = () => {
            chunks = [];
        };

        return recorder;
    };
