import {useCallback, useEffect, useState} from 'react';

import {stopMediaStream} from '@pexip/media-control';
import {createAudioMediaRecorderFn, getMimeType} from '@pexip/media-components';

const createAudioMediaRecorder = createAudioMediaRecorderFn([], getMimeType());

const getClonedAudioStream = (stream: MediaStream) => {
    const clonedStream = new MediaStream(
        stream.getAudioTracks().map(track => {
            const clone = track.clone();
            clone.enabled = true;
            return clone;
        }),
    );

    return clonedStream;
};

/**
 * Initializes `MediaRecorder` based on a clone of the existing `MediaStream`, and
 * returns `MediaRecorder` to allow start/stop recording an audio.
 */
export const useAudioRecording = (stream?: MediaStream) => {
    const [clonedAudioStream, setClonedAudioStream] = useState<MediaStream>();
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
    const [objectURL, setObjectURL] = useState('');

    useEffect(() => {
        if (stream) {
            const newStream = getClonedAudioStream(stream);

            setClonedAudioStream(newStream);

            const recorder = createAudioMediaRecorder(
                setObjectURL,
                new MediaRecorder(newStream),
            );

            setMediaRecorder(recorder);
        }

        return () => {
            setMediaRecorder(undefined);
        };
    }, [stream]);

    useEffect(
        () => () => {
            stopMediaStream(clonedAudioStream);
        },
        [clonedAudioStream],
    );

    useEffect(
        () => () => {
            if (objectURL) {
                URL.revokeObjectURL(objectURL);
            }
        },
        [objectURL],
    );

    const cleanup = useCallback(() => {
        setObjectURL('');
    }, []);

    return {objectURL, mediaRecorder, cleanup};
};
