import React from 'react';

import {Box, ThemeProvider} from '@pexip/components';

import styles from './ExpressFlow.module.scss';

export const ExpressFlowView: React.FC<{
    deviceSelectionComponent: React.ReactElement;
}> = ({deviceSelectionComponent}) => {
    return (
        <ThemeProvider colorScheme="light">
            <Box className={styles.deviceSelectionBox} padding="medium">
                {deviceSelectionComponent}
            </Box>
        </ThemeProvider>
    );
};

export type ExpressFlowViewProps = React.ComponentProps<typeof ExpressFlowView>;
