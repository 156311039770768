/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface DialMap {
    '200': Status200;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    /**
     * The response is an array of UUIDs of new participants
     */
    result: string[];
}
export interface TopLevel {
    /**
     * The level of privileges the participant has in the conference.
     */
    role: 'HOST' | 'GUEST';
    /**
     * The target address to call.
     */
    destination: string;
    /**
     * The protocol to use to place the outgoing call
     */
    protocol: 'sip' | 'h323' | 'rtmp' | 'mssip' | 'auto';
    /**
     * This additional parameter can be specified for RTMP calls to send the presentation stream to a separate RTMP destination.
     */
    presentation_url?: string;
    /**
     * Identifies the dialed participant as a streaming or recording device.
     */
    streaming?: 'yes' | 'no';
    /**
     * An optional DTMF sequence to transmit after the call to the dialed participant starts.
     */
    dtmf_sequence?: string;
    /**
     * Optional field that specifies what the calling display name should be.
     */
    source_display_name?: string;
    /**
     * Optional field that specifies the source URI (must be a valid URI for the conference).
     */
    source?: string;
    /**
     * Optional field that limits the media content of the call.
     */
    call_type?: 'video' | 'video-only' | 'audio';
    /**
     * Determines whether the conference continues when all other non-ADP participants have disconnected. Default: `keep_conference_alive` for non-streaming participants, and `keep_conference_alive_never` for streaming participants.
     */
    keep_conference_alive?:
        | 'keep_conference_alive'
        | 'keep_conference_alive_if_multiple'
        | 'keep_conference_alive_never';
    /**
     * An optional friendly name for this participant. This may be used instead of the participant's alias in participant lists and as a text overlay in some layout configurations.
     */
    remote_display_name?: string;
    /**
     * Optional text to use instead of remote_display_name as the participant name overlay text.
     */
    text?: string;
}

import {validateBody, validate200} from './dialValidation';
type DialResponse = {status: 200; data: DialMap['200']};
interface DialParams {
    conferenceAlias: string;
}
export async function dial({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: DialParams;
    body: DialMap['Body'];
    host: string;
}): Promise<DialResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/dial`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
