import cx from 'classnames';
import React from 'react';

import {Text} from '../Text/Text';
import {FontVariant} from '../../../../design-tokens/generated/constants';
import type {InteractiveElementProps} from '../InteractiveElement/InteractiveElement';
import {InteractiveElement} from '../InteractiveElement/InteractiveElement';
import type {HeadingElement} from '../../../types/elements';
import {TextHeading} from '../TextHeading/TextHeading';

import styles from './CallToAction.module.scss';

export const CallToAction: React.FC<
    InteractiveElementProps & {
        className?: string;
        enhancerStart?: React.ReactChild;
        isSmall?: boolean;
        modifier?: 'fullWidth';
        subTitle?: string;
        title: string;
        titleHtmlTag?: HeadingElement;
    }
> = ({
    className,
    enhancerStart,
    isSmall,
    modifier,
    subTitle = '',
    title = '',
    titleHtmlTag,
    ...props
}) => (
    <InteractiveElement
        className={cx(
            styles.callToAction,
            styles.focus,
            modifier && styles[modifier],
            {
                [styles.small]: isSmall,
            },
            className,
        )}
        {...props}
    >
        {enhancerStart && (
            <span className={styles.enhancerStart}>{enhancerStart}</span>
        )}
        <span className={styles.text}>
            {titleHtmlTag ? (
                <TextHeading
                    htmlTag={titleHtmlTag}
                    fontVariant={FontVariant.H5}
                >
                    {title}
                </TextHeading>
            ) : (
                <Text fontVariant={FontVariant.H5}>{title}</Text>
            )}
            {subTitle && !isSmall && (
                <Text fontVariant={FontVariant.Body} variant="subtle">
                    {subTitle}
                </Text>
            )}
        </span>
    </InteractiveElement>
);

export type CallToActionProps = React.ComponentProps<typeof CallToAction>;
