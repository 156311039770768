/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface DtmfParticipantMap {
    '200': Status200;
    '403': Status403;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    /**
     * The result is true if successful, false otherwise.
     */
    result: boolean;
}
export interface Status403 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result: 'Invalid token';
}
export interface TopLevel {
    /**
     * The DTMF digits to send.
     */
    digits: string;
}

import {
    validateBody,
    validate200,
    validate403,
} from './dtmfParticipantValidation';
type DtmfParticipantResponse =
    | {status: 200; data: DtmfParticipantMap['200']}
    | {status: 403; data: DtmfParticipantMap['403']};
interface DtmfParticipantParams {
    conferenceAlias: string;
    participantUuid: string;
}
export async function dtmfParticipant({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: DtmfParticipantParams;
    body: DtmfParticipantMap['Body'];
    host: string;
}): Promise<DtmfParticipantResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/participants/${params.participantUuid}/dtmf`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate403(data)) {
            throw new Error(JSON.stringify(validate403.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
