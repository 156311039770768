/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface UpdateMap {
    '200': Status200;
    '403': Status403;
    '502': string;
    '503': string;
    '520': string;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result: string | Result;
}
export interface Result {
    call_uuid: string;
    /**
     * SDP answer from the mcu
     */
    sdp: string;
}
export interface Status403 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result: null | 'Invalid token';
}
export interface TopLevel {
    /**
     * The new SDP.
     */
    sdp?: string;
    /**
     * Set to true if this participant can be sent FECC messages; false if not.
     */
    fecc_supported?: boolean & string;
}

import {
    validateBody,
    validate200,
    validate403,
    validate502,
    validate503,
    validate520,
} from './updateValidation';
type UpdateResponse =
    | {status: 200; data: UpdateMap['200']}
    | {status: 403; data: UpdateMap['403']}
    | {status: 502; data: UpdateMap['502']}
    | {status: 503; data: UpdateMap['503']}
    | {status: 520; data: UpdateMap['520']};
interface UpdateParams {
    conferenceAlias: string;
    participantUuid: string;
    callUuid: string;
}
export async function update({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: UpdateParams;
    body: UpdateMap['Body'];
    host: string;
}): Promise<UpdateResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/participants/${params.participantUuid}/calls/${params.callUuid}/update`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate403(data)) {
            throw new Error(JSON.stringify(validate403.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 502) {
        const data = await res.text();
        if (!validate502(data)) {
            throw new Error(JSON.stringify(validate502.errors));
        }

        return {status: res.status, data};
    }
    if (res.status === 503) {
        const data = await res.text();
        if (!validate503(data)) {
            throw new Error(JSON.stringify(validate503.errors));
        }

        return {status: res.status, data};
    }
    if (res.status === 520) {
        const data = await res.text();
        if (!validate520(data)) {
            throw new Error(JSON.stringify(validate520.errors));
        }

        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
