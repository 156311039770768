import React from 'react';
import {useTranslation} from 'react-i18next';

import {
    CallToAction,
    Group,
    Icon,
    IconTypes,
    useMdDown,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './RejoinChooseAnotherMeeting.module.scss';

export const RejoinChooseAnotherMeeting: React.FC<
    React.ComponentProps<'div'> & {
        shouldShowRejoin?: boolean;
        onRejoinClick: () => void;
        onChooseAnotherMeetingClick: () => void;
    }
> = ({
    shouldShowRejoin = true,
    className,
    onRejoinClick,
    onChooseAnotherMeetingClick,
}) => {
    const {t} = useTranslation();
    const isMdDown = useMdDown();
    return (
        <Group className={className} spacing={isMdDown ? 'none' : 'medium'}>
            {shouldShowRejoin && (
                <CallToAction
                    className={styles.rejoinButton}
                    data-testid={TestId.ButtonRejoin}
                    enhancerStart={<Icon source={IconTypes.IconArrowRight} />}
                    onClick={onRejoinClick}
                    title={t('error.rejoin', 'Rejoin')}
                />
            )}
            <CallToAction
                className={styles.chooseAnotherMeetingButton}
                data-testid={TestId.ButtonChooseAnother}
                enhancerStart={<Icon source={IconTypes.IconPexipLogo} />}
                onClick={onChooseAnotherMeetingClick}
                title={t('error.choose-another', 'Choose another meeting')}
            />
        </Group>
    );
};

export type RejoinChooseAnotherMeetingProps = React.ComponentProps<
    typeof RejoinChooseAnotherMeeting
>;
