export const validate200 = validate13;
const schema15 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {$ref: '#/properties/200/definitions/Result'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                token: {
                    type: 'string',
                    description:
                        'The new authentication token for future requests.',
                },
                role: {
                    type: 'string',
                    enum: ['HOST', 'GUEST'],
                    description:
                        'Whether the participant is connecting as a `HOST` or a `GUEST`.',
                },
                expires: {
                    type: 'string',
                    description: 'Validity lifetime in seconds.',
                },
            },
            required: ['token', 'expires'],
            title: 'Result',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {$ref: '#/properties/200/definitions/Result'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        token: {
            type: 'string',
            description: 'The new authentication token for future requests.',
        },
        role: {
            type: 'string',
            enum: ['HOST', 'GUEST'],
            description:
                'Whether the participant is connecting as a `HOST` or a `GUEST`.',
        },
        expires: {type: 'string', description: 'Validity lifetime in seconds.'},
    },
    required: ['token', 'expires'],
    title: 'Result',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema12.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.token === undefined &&
                                            (missing1 = 'token')) ||
                                        (data1.expires === undefined &&
                                            (missing1 = 'expires'))
                                    ) {
                                        validate11.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'token' ||
                                                    key1 === 'role' ||
                                                    key1 === 'expires'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.token !== undefined) {
                                                const _errs8 = errors;
                                                if (
                                                    typeof data1.token !==
                                                    'string'
                                                ) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/token',
                                                            schemaPath:
                                                                '#/properties/200/definitions/Result/properties/token/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs8 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data1.role !== undefined) {
                                                    let data3 = data1.role;
                                                    const _errs10 = errors;
                                                    if (
                                                        typeof data3 !==
                                                        'string'
                                                    ) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/role',
                                                                schemaPath:
                                                                    '#/properties/200/definitions/Result/properties/role/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    if (
                                                        !(
                                                            data3 === 'HOST' ||
                                                            data3 === 'GUEST'
                                                        )
                                                    ) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/role',
                                                                schemaPath:
                                                                    '#/properties/200/definitions/Result/properties/role/enum',
                                                                keyword: 'enum',
                                                                params: {
                                                                    allowedValues:
                                                                        schema13
                                                                            .properties
                                                                            .role
                                                                            .enum,
                                                                },
                                                                message:
                                                                    'must be equal to one of the allowed values',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs10 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data1.expires !==
                                                        undefined
                                                    ) {
                                                        const _errs12 = errors;
                                                        if (
                                                            typeof data1.expires !==
                                                            'string'
                                                        ) {
                                                            validate11.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/result/expires',
                                                                        schemaPath:
                                                                            '#/properties/200/definitions/Result/properties/expires/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'string',
                                                                        },
                                                                        message:
                                                                            'must be string',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs12 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate11.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
export const validate403 = validate15;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {type: 'string', enum: ['Invalid token']},
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [{$ref: '#/properties/403/definitions/403'}],
};
const schema14 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {type: 'string', enum: ['Invalid token']},
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema14.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (!(data1 === 'Invalid token')) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema14.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath: '#/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
