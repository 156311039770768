import React from 'react';

import {
    RemoveUserConfirmation,
    useRemoveParticipant,
} from '@pexip/media-components';

import {removeParticipantSignal} from '../signals/Participant.signals';

export const RemoveUser: React.FC = () => {
    const {isOpen, userName, onCancel, onConfirm, description} =
        useRemoveParticipant(removeParticipantSignal);
    return (
        <RemoveUserConfirmation
            isOpen={isOpen}
            description={description}
            userName={userName}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
};
