import React from 'react';

import type {ButtonVariant, MenuContent} from '@pexip/components';
import {AboutModal, Menu, UserMenuButton} from '@pexip/components';
import type {AutoHideButtonCallbacks} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';

export const UserMenuView: React.FC<{
    menuContent: MenuContent;
    autoHideProps?: AutoHideButtonCallbacks;
    version: string;
    variant?: ButtonVariant;
    isAboutOpen: boolean;
    setAboutOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    autoHideProps,
    isAboutOpen,
    menuContent,
    version,
    variant,
    setAboutOpen,
}) => {
    return (
        <>
            <UserMenuButton
                position="bottomRight"
                content={<Menu menuContent={menuContent} />}
                data-testid={TestId.UserMenuBtn}
                variant={variant}
                {...autoHideProps}
            />
            <AboutModal
                isOpen={isAboutOpen}
                onClose={() => setAboutOpen(false)}
                version={version}
                data-testid={TestId.UserMenuAboutPexipModal}
            />
        </>
    );
};

export type UserMenuViewProps = React.ComponentProps<typeof UserMenuView>;
