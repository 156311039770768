import React from 'react';
import cx from 'classnames';

import {
    TextHeading,
    Sheet,
    useMdDown,
    useTouchDevice,
    ThemeProvider,
} from '@pexip/components';

import {PreflightJoinSteps} from '../../enums';
import {PreflightTips} from '../PreflightTips/PreflightTips.view';
import {StepByStepCard} from '../StepByStepCard/StepByStepCard.view';

import styles from './DeviceSelectionStep.module.scss';

export const DeviceSelectionStep: React.FC<{
    currentStepIndex: number;
    displayInnerStepper: boolean;
    deviceSelectionStep: React.ReactElement;
    stepper: React.ReactElement;
    titleTranslation: string;
    hideHelpAndGoToNextStep: () => void;
    setHideUserHelpers?: () => void;
    tryAgain: () => void;
    closeAccessibilityTips: () => void;
    showPreflightTips: boolean;
    showAccessibilityTips: boolean;
    scrollableElementRef: React.RefObject<HTMLDivElement>;
    accessibilityTips: React.ReactElement;
}> = ({
    currentStepIndex,
    displayInnerStepper,
    deviceSelectionStep,
    stepper,
    titleTranslation,
    hideHelpAndGoToNextStep,
    showPreflightTips,
    showAccessibilityTips,
    tryAgain,
    closeAccessibilityTips,
    scrollableElementRef,
    accessibilityTips,
}) => {
    const isMdDown = useMdDown();
    const isTouchDevice = useTouchDevice();

    if (currentStepIndex === PreflightJoinSteps.ReadyToJoin) {
        return (
            <div className={styles.readyToJoinWrapper}>
                {deviceSelectionStep}
            </div>
        );
    }

    const preflightTips = (
        <PreflightTips
            className={cx({'pb-3': isMdDown})}
            currentStepIndex={currentStepIndex}
            next={hideHelpAndGoToNextStep}
            close={tryAgain}
        />
    );

    const content = (
        <>
            {displayInnerStepper && (
                <div
                    className={cx(styles.stepperWrapper, {
                        'mt-5': !isTouchDevice,
                        'mt-4': isTouchDevice && isMdDown,
                    })}
                >
                    {stepper}
                    <TextHeading htmlTag="h4" className="mb-5">
                        {titleTranslation}
                    </TextHeading>
                </div>
            )}

            {deviceSelectionStep}

            {showAccessibilityTips && !isMdDown && accessibilityTips}
            {showPreflightTips && !isMdDown && preflightTips}
            {isMdDown && (
                <>
                    <Sheet
                        withCloseButton
                        isOpen={showPreflightTips}
                        onClose={tryAgain}
                        className={styles.preflightTipsSheet}
                    >
                        {preflightTips}
                    </Sheet>
                    <Sheet
                        withCloseButton
                        isOpen={showAccessibilityTips}
                        onClose={closeAccessibilityTips}
                        className={styles.preflightTipsSheet}
                    >
                        {accessibilityTips}
                    </Sheet>
                </>
            )}
        </>
    );
    if (isMdDown) {
        return (
            <ThemeProvider colorScheme="light">
                <div className={styles.mobileContainer}>{content}</div>
            </ThemeProvider>
        );
    }
    return (
        <StepByStepCard scrollableElementRef={scrollableElementRef}>
            {content}
        </StepByStepCard>
    );
};

export type DeviceSelectionStepProps = React.ComponentProps<
    typeof DeviceSelectionStep
>;
