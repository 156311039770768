import React from 'react';

import type {NetworkState, usePresentation} from '@pexip/media-components';
import {NetworkAlert, MeetingWrapper} from '@pexip/media-components';
import type {Layout, SplashScreen} from '@pexip/infinity';

import {MeetingRoom} from '../viewModels/MeetingRoom.viewModel';
import {RemoveUser} from '../viewModels/RemoveUser.viewModel';

export const InMeeting: React.FC<{
    disconnectAll: () => void;
    leave: () => void;
    lock: (lock: boolean) => void;
    presentation: ReturnType<typeof usePresentation>;
    raiseHand: (raise: boolean) => void;
    setLayout: (layout: Layout) => void;
    networkState: NetworkState;
    splashScreen?: SplashScreen;
    stream?: MediaStream;
}> = ({
    disconnectAll,
    leave,
    lock,
    presentation,
    raiseHand,
    setLayout,
    networkState,
    splashScreen,
    stream,
}) => (
    <>
        <NetworkAlert networkState={networkState} />
        <MeetingWrapper>
            <MeetingRoom
                disconnectAll={disconnectAll}
                leave={leave}
                lock={lock}
                presentation={presentation}
                raiseHand={raiseHand}
                setLayout={setLayout}
                networkState={networkState}
                stream={stream}
                splashScreen={splashScreen}
            />
            <RemoveUser />
        </MeetingWrapper>
    </>
);
