import React from 'react';

import {List} from '@pexip/components';

import styles from './StepLayout.module.scss';

export const StepLayout: React.FC<{
    startContent: React.ReactElement;
    endContent: React.ReactElement;
}> = ({startContent, endContent}) => {
    return (
        <List spacing="none" className={styles.stepLayout}>
            <div className={styles.startContent}>
                <div className={styles.startContentItem}>{startContent}</div>
            </div>
            <div>{endContent}</div>
        </List>
    );
};

export type StepLayoutProps = React.ComponentProps<typeof StepLayout>;
