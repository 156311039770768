import React from 'react';
import cx from 'classnames';

import type {InteractiveElementProps} from '@pexip/components';
import {Text, InteractiveElement} from '@pexip/components';

import styles from './Effect.module.scss';

export const Effect: React.FC<
    InteractiveElementProps & {active?: boolean; name: string}
> = ({active = false, className, children, name, ...props}) => (
    <InteractiveElement
        className={cx(
            'align-items-center',
            'd-flex',
            'flex-direction-column',
            'mb-2',
            styles.container,
            className,
        )}
        {...props}
    >
        <div
            className={cx(
                'align-items-center',
                'd-flex',
                'justify-content-center ',
                'mb-2',
                styles.element,
                active && styles.active,
            )}
        >
            {children}
        </div>
        <Text htmlTag="span">{name}</Text>
    </InteractiveElement>
);

export type EffectProps = React.ComponentProps<typeof Effect>;
