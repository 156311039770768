import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {notificationToastSignal} from '@pexip/components';
import {usePrevious} from '@pexip/hooks';

import {TestId} from '../../test/testIds';

import {useConferenceStatus} from './useConferenceStatus';

const isGuestMutedChanged = (
    prevGuestsMuted?: boolean,
    guestsMuted?: boolean,
) => guestsMuted !== prevGuestsMuted;

export const useAllGuestsMutedByHost = () => {
    const {t} = useTranslation();

    const conferenceStatus = useConferenceStatus();
    const prevGuestsMuted = usePrevious(conferenceStatus?.guestsMuted);
    const isMuteStateChanged = useMemo(
        () =>
            isGuestMutedChanged(prevGuestsMuted, conferenceStatus?.guestsMuted),
        [conferenceStatus?.guestsMuted, prevGuestsMuted],
    );

    useEffect(() => {
        if (prevGuestsMuted === undefined || !isMuteStateChanged) {
            return;
        }

        if (conferenceStatus?.guestsMuted) {
            notificationToastSignal.emit([
                {
                    message: t(
                        'media.all-guests-muted-by-host',
                        'All Guests muted by Host',
                    ),
                    testid: TestId.ToastAllGuestsMutedByHost,
                    timeout: 5000,
                },
            ]);
        } else {
            notificationToastSignal.emit([
                {
                    message: t(
                        'media.all-guests-unmuted-by-host',
                        'All Guests unmuted by Host',
                    ),
                    testid: TestId.ToastAllGuestsUnmutedByHost,
                    timeout: 5000,
                },
            ]);
        }
    }, [conferenceStatus?.guestsMuted, isMuteStateChanged, prevGuestsMuted, t]);
};
