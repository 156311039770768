import React from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {InteractiveElement, FontVariant, Text} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './ChatPanelInputButtons.module.scss';

export const ChatPanelInputSendButton: React.FC<{
    disabled: boolean;
}> = ({disabled}) => {
    const {t} = useTranslation();
    return (
        <InteractiveElement
            className={cx('pl-3', 'pr-3', styles.sendButton)}
            disabled={disabled}
            type="submit"
        >
            <Text
                className={styles.text}
                variant={disabled ? 'disabled' : 'standard'}
                data-testid={TestId.ButtonChatSend}
                fontVariant={FontVariant.BodyBold}
            >
                <Trans t={t} i18nKey="common.send">
                    Send
                </Trans>
            </Text>
        </InteractiveElement>
    );
};
