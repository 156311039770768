import React from 'react';

import {FullSizeWindow} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './MeetingWrapper.module.scss';

export const MeetingWrapper: React.FC<
    React.ComponentProps<typeof FullSizeWindow>
> = ({...props}) => (
    <FullSizeWindow
        padding="none"
        background="secondary"
        flexDirection="column"
        justifyContent="center"
        className={styles.fullSizeWindow}
        data-testid={TestId.MeetingWrapper}
        data-meeting-wrapper
        {...props}
    />
);
