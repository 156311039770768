import React, {useState} from 'react';

import {FreeDialStep} from '../views/FreeDialStep/FreeDialStep.view';
import {push} from '../router';
import {EXPRESS_PATH} from '../constants';

export const FreeDial: React.FC = () => {
    const [meetingId, setMeetingId] = useState('');

    const next = (meetingId: string) => {
        push(`/m/${meetingId}${EXPRESS_PATH}`);
    };

    return (
        <FreeDialStep
            meetingId={meetingId}
            onMeetingIdChange={setMeetingId}
            next={next}
        />
    );
};
