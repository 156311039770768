export const validate200 = validate11;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'array',
                    description:
                        'The response is an array of UUIDs of new participants',
                    items: {type: 'string'},
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'array',
            description:
                'The response is an array of UUIDs of new participants',
            items: {type: 'string'},
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema12.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (errors === _errs7) {
                                if (Array.isArray(data1)) {
                                    var valid3 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        const _errs9 = errors;
                                        if (typeof data1[i0] !== 'string') {
                                            const err3 = {
                                                instancePath:
                                                    instancePath +
                                                    '/result/' +
                                                    i0,
                                                schemaPath:
                                                    '#/properties/200/definitions/200/properties/result/items/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err3];
                                            } else {
                                                vErrors.push(err3);
                                            }
                                            errors++;
                                        }
                                        var valid3 = _errs9 === errors;
                                        if (!valid3) {
                                            break;
                                        }
                                    }
                                } else {
                                    const err4 = {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/properties/200/definitions/200/properties/result/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath: '#/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
export const validateBody = validate12;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                role: {
                    type: 'string',
                    enum: ['HOST', 'GUEST'],
                    description:
                        'The level of privileges the participant has in the conference.',
                },
                destination: {
                    type: 'string',
                    description: 'The target address to call.',
                },
                protocol: {
                    type: 'string',
                    enum: ['sip', 'h323', 'rtmp', 'mssip', 'auto'],
                    description:
                        'The protocol to use to place the outgoing call',
                },
                presentation_url: {
                    type: 'string',
                    description:
                        'This additional parameter can be specified for RTMP calls to send the presentation stream to a separate RTMP destination.',
                },
                streaming: {
                    type: 'string',
                    enum: ['yes', 'no'],
                    default: 'no',
                    description:
                        'Identifies the dialed participant as a streaming or recording device.',
                },
                dtmf_sequence: {
                    type: 'string',
                    description:
                        'An optional DTMF sequence to transmit after the call to the dialed participant starts.',
                },
                source_display_name: {
                    type: 'string',
                    description:
                        'Optional field that specifies what the calling display name should be.',
                },
                source: {
                    type: 'string',
                    description:
                        'Optional field that specifies the source URI (must be a valid URI for the conference).',
                },
                call_type: {
                    type: 'string',
                    enum: ['video', 'video-only', 'audio'],
                    default: ['video'],
                    description:
                        'Optional field that limits the media content of the call.',
                },
                keep_conference_alive: {
                    type: 'string',
                    enum: [
                        'keep_conference_alive',
                        'keep_conference_alive_if_multiple',
                        'keep_conference_alive_never',
                    ],
                    description:
                        'Determines whether the conference continues when all other non-ADP participants have disconnected. Default: `keep_conference_alive` for non-streaming participants, and `keep_conference_alive_never` for streaming participants.',
                },
                remote_display_name: {
                    type: 'string',
                    description:
                        "An optional friendly name for this participant. This may be used instead of the participant's alias in participant lists and as a text overlay in some layout configurations.",
                },
                text: {
                    type: 'string',
                    description:
                        'Optional text to use instead of remote_display_name as the participant name overlay text.',
                },
            },
            required: ['role', 'destination', 'protocol'],
            title: 'TopLevel',
        },
    },
    oneOf: [{$ref: '#/properties/Body/definitions/TopLevel'}],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        role: {
            type: 'string',
            enum: ['HOST', 'GUEST'],
            description:
                'The level of privileges the participant has in the conference.',
        },
        destination: {
            type: 'string',
            description: 'The target address to call.',
        },
        protocol: {
            type: 'string',
            enum: ['sip', 'h323', 'rtmp', 'mssip', 'auto'],
            description: 'The protocol to use to place the outgoing call',
        },
        presentation_url: {
            type: 'string',
            description:
                'This additional parameter can be specified for RTMP calls to send the presentation stream to a separate RTMP destination.',
        },
        streaming: {
            type: 'string',
            enum: ['yes', 'no'],
            default: 'no',
            description:
                'Identifies the dialed participant as a streaming or recording device.',
        },
        dtmf_sequence: {
            type: 'string',
            description:
                'An optional DTMF sequence to transmit after the call to the dialed participant starts.',
        },
        source_display_name: {
            type: 'string',
            description:
                'Optional field that specifies what the calling display name should be.',
        },
        source: {
            type: 'string',
            description:
                'Optional field that specifies the source URI (must be a valid URI for the conference).',
        },
        call_type: {
            type: 'string',
            enum: ['video', 'video-only', 'audio'],
            default: ['video'],
            description:
                'Optional field that limits the media content of the call.',
        },
        keep_conference_alive: {
            type: 'string',
            enum: [
                'keep_conference_alive',
                'keep_conference_alive_if_multiple',
                'keep_conference_alive_never',
            ],
            description:
                'Determines whether the conference continues when all other non-ADP participants have disconnected. Default: `keep_conference_alive` for non-streaming participants, and `keep_conference_alive_never` for streaming participants.',
        },
        remote_display_name: {
            type: 'string',
            description:
                "An optional friendly name for this participant. This may be used instead of the participant's alias in participant lists and as a text overlay in some layout configurations.",
        },
        text: {
            type: 'string',
            description:
                'Optional text to use instead of remote_display_name as the participant name overlay text.',
        },
    },
    required: ['role', 'destination', 'protocol'],
    title: 'TopLevel',
};
const func2 = Object.prototype.hasOwnProperty;
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.role === undefined && (missing0 = 'role')) ||
                (data.destination === undefined &&
                    (missing0 = 'destination')) ||
                (data.protocol === undefined && (missing0 = 'protocol'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!func2.call(schema13.properties, key0)) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.role !== undefined) {
                        let data0 = data.role;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/properties/Body/definitions/TopLevel/properties/role/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'HOST' || data0 === 'GUEST')) {
                            const err2 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/properties/Body/definitions/TopLevel/properties/role/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema13.properties.role.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.destination !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.destination !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/destination',
                                    schemaPath:
                                        '#/properties/Body/definitions/TopLevel/properties/destination/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.protocol !== undefined) {
                                let data2 = data.protocol;
                                const _errs9 = errors;
                                if (typeof data2 !== 'string') {
                                    const err4 = {
                                        instancePath:
                                            instancePath + '/protocol',
                                        schemaPath:
                                            '#/properties/Body/definitions/TopLevel/properties/protocol/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                if (
                                    !(
                                        data2 === 'sip' ||
                                        data2 === 'h323' ||
                                        data2 === 'rtmp' ||
                                        data2 === 'mssip' ||
                                        data2 === 'auto'
                                    )
                                ) {
                                    const err5 = {
                                        instancePath:
                                            instancePath + '/protocol',
                                        schemaPath:
                                            '#/properties/Body/definitions/TopLevel/properties/protocol/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema13.properties.protocol
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.presentation_url !== undefined) {
                                    const _errs11 = errors;
                                    if (
                                        typeof data.presentation_url !==
                                        'string'
                                    ) {
                                        const err6 = {
                                            instancePath:
                                                instancePath +
                                                '/presentation_url',
                                            schemaPath:
                                                '#/properties/Body/definitions/TopLevel/properties/presentation_url/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.streaming !== undefined) {
                                        let data4 = data.streaming;
                                        const _errs13 = errors;
                                        if (typeof data4 !== 'string') {
                                            const err7 = {
                                                instancePath:
                                                    instancePath + '/streaming',
                                                schemaPath:
                                                    '#/properties/Body/definitions/TopLevel/properties/streaming/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err7];
                                            } else {
                                                vErrors.push(err7);
                                            }
                                            errors++;
                                        }
                                        if (
                                            !(data4 === 'yes' || data4 === 'no')
                                        ) {
                                            const err8 = {
                                                instancePath:
                                                    instancePath + '/streaming',
                                                schemaPath:
                                                    '#/properties/Body/definitions/TopLevel/properties/streaming/enum',
                                                keyword: 'enum',
                                                params: {
                                                    allowedValues:
                                                        schema13.properties
                                                            .streaming.enum,
                                                },
                                                message:
                                                    'must be equal to one of the allowed values',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err8];
                                            } else {
                                                vErrors.push(err8);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                    if (valid2) {
                                        if (data.dtmf_sequence !== undefined) {
                                            const _errs15 = errors;
                                            if (
                                                typeof data.dtmf_sequence !==
                                                'string'
                                            ) {
                                                const err9 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/dtmf_sequence',
                                                    schemaPath:
                                                        '#/properties/Body/definitions/TopLevel/properties/dtmf_sequence/type',
                                                    keyword: 'type',
                                                    params: {type: 'string'},
                                                    message: 'must be string',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err9];
                                                } else {
                                                    vErrors.push(err9);
                                                }
                                                errors++;
                                            }
                                            var valid2 = _errs15 === errors;
                                        } else {
                                            var valid2 = true;
                                        }
                                        if (valid2) {
                                            if (
                                                data.source_display_name !==
                                                undefined
                                            ) {
                                                const _errs17 = errors;
                                                if (
                                                    typeof data.source_display_name !==
                                                    'string'
                                                ) {
                                                    const err10 = {
                                                        instancePath:
                                                            instancePath +
                                                            '/source_display_name',
                                                        schemaPath:
                                                            '#/properties/Body/definitions/TopLevel/properties/source_display_name/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err10];
                                                    } else {
                                                        vErrors.push(err10);
                                                    }
                                                    errors++;
                                                }
                                                var valid2 = _errs17 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data.source !== undefined) {
                                                    const _errs19 = errors;
                                                    if (
                                                        typeof data.source !==
                                                        'string'
                                                    ) {
                                                        const err11 = {
                                                            instancePath:
                                                                instancePath +
                                                                '/source',
                                                            schemaPath:
                                                                '#/properties/Body/definitions/TopLevel/properties/source/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err11];
                                                        } else {
                                                            vErrors.push(err11);
                                                        }
                                                        errors++;
                                                    }
                                                    var valid2 =
                                                        _errs19 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data.call_type !==
                                                        undefined
                                                    ) {
                                                        let data8 =
                                                            data.call_type;
                                                        const _errs21 = errors;
                                                        if (
                                                            typeof data8 !==
                                                            'string'
                                                        ) {
                                                            const err12 = {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/call_type',
                                                                schemaPath:
                                                                    '#/properties/Body/definitions/TopLevel/properties/call_type/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            };
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err12,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err12,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                        if (
                                                            !(
                                                                data8 ===
                                                                    'video' ||
                                                                data8 ===
                                                                    'video-only' ||
                                                                data8 ===
                                                                    'audio'
                                                            )
                                                        ) {
                                                            const err13 = {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/call_type',
                                                                schemaPath:
                                                                    '#/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                                                keyword: 'enum',
                                                                params: {
                                                                    allowedValues:
                                                                        schema13
                                                                            .properties
                                                                            .call_type
                                                                            .enum,
                                                                },
                                                                message:
                                                                    'must be equal to one of the allowed values',
                                                            };
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err13,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err13,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                        var valid2 =
                                                            _errs21 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (
                                                            data.keep_conference_alive !==
                                                            undefined
                                                        ) {
                                                            let data9 =
                                                                data.keep_conference_alive;
                                                            const _errs23 =
                                                                errors;
                                                            if (
                                                                typeof data9 !==
                                                                'string'
                                                            ) {
                                                                const err14 = {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/keep_conference_alive',
                                                                    schemaPath:
                                                                        '#/properties/Body/definitions/TopLevel/properties/keep_conference_alive/type',
                                                                    keyword:
                                                                        'type',
                                                                    params: {
                                                                        type: 'string',
                                                                    },
                                                                    message:
                                                                        'must be string',
                                                                };
                                                                if (
                                                                    vErrors ===
                                                                    null
                                                                ) {
                                                                    vErrors = [
                                                                        err14,
                                                                    ];
                                                                } else {
                                                                    vErrors.push(
                                                                        err14,
                                                                    );
                                                                }
                                                                errors++;
                                                            }
                                                            if (
                                                                !(
                                                                    data9 ===
                                                                        'keep_conference_alive' ||
                                                                    data9 ===
                                                                        'keep_conference_alive_if_multiple' ||
                                                                    data9 ===
                                                                        'keep_conference_alive_never'
                                                                )
                                                            ) {
                                                                const err15 = {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/keep_conference_alive',
                                                                    schemaPath:
                                                                        '#/properties/Body/definitions/TopLevel/properties/keep_conference_alive/enum',
                                                                    keyword:
                                                                        'enum',
                                                                    params: {
                                                                        allowedValues:
                                                                            schema13
                                                                                .properties
                                                                                .keep_conference_alive
                                                                                .enum,
                                                                    },
                                                                    message:
                                                                        'must be equal to one of the allowed values',
                                                                };
                                                                if (
                                                                    vErrors ===
                                                                    null
                                                                ) {
                                                                    vErrors = [
                                                                        err15,
                                                                    ];
                                                                } else {
                                                                    vErrors.push(
                                                                        err15,
                                                                    );
                                                                }
                                                                errors++;
                                                            }
                                                            var valid2 =
                                                                _errs23 ===
                                                                errors;
                                                        } else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (
                                                                data.remote_display_name !==
                                                                undefined
                                                            ) {
                                                                const _errs25 =
                                                                    errors;
                                                                if (
                                                                    typeof data.remote_display_name !==
                                                                    'string'
                                                                ) {
                                                                    const err16 =
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/remote_display_name',
                                                                            schemaPath:
                                                                                '#/properties/Body/definitions/TopLevel/properties/remote_display_name/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'string',
                                                                            },
                                                                            message:
                                                                                'must be string',
                                                                        };
                                                                    if (
                                                                        vErrors ===
                                                                        null
                                                                    ) {
                                                                        vErrors =
                                                                            [
                                                                                err16,
                                                                            ];
                                                                    } else {
                                                                        vErrors.push(
                                                                            err16,
                                                                        );
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid2 =
                                                                    _errs25 ===
                                                                    errors;
                                                            } else {
                                                                var valid2 =
                                                                    true;
                                                            }
                                                            if (valid2) {
                                                                if (
                                                                    data.text !==
                                                                    undefined
                                                                ) {
                                                                    const _errs27 =
                                                                        errors;
                                                                    if (
                                                                        typeof data.text !==
                                                                        'string'
                                                                    ) {
                                                                        const err17 =
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/text',
                                                                                schemaPath:
                                                                                    '#/properties/Body/definitions/TopLevel/properties/text/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            };
                                                                        if (
                                                                            vErrors ===
                                                                            null
                                                                        ) {
                                                                            vErrors =
                                                                                [
                                                                                    err17,
                                                                                ];
                                                                        } else {
                                                                            vErrors.push(
                                                                                err17,
                                                                            );
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    var valid2 =
                                                                        _errs27 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err18 = {
                instancePath,
                schemaPath: '#/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err18];
            } else {
                vErrors.push(err18);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err19 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err19];
        } else {
            vErrors.push(err19);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
