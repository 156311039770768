import {useState, useEffect, useCallback} from 'react';

import type {InMeetingParticipant} from '@pexip/media-components';
import type {Participant} from '@pexip/infinity';

import {infinityService} from '../services/InfinityClient.service';
import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {getParticipantListGroups} from '../utils/getParticipantListGroups';
import {config} from '../config';

export const useParticipants = () => {
    const toggleMyCamera = () =>
        config.set({
            key: 'isVideoInputMuted',
            value: !config.get('isVideoInputMuted'),
        });

    const getParticipantGroups = useCallback(
        (participants: Participant[]) =>
            getParticipantListGroups(participants, infinityService, {
                toggleCamera: toggleMyCamera,
            }),
        [],
    );

    const [participants, setParticipants] = useState<{
        inMeeting: InMeetingParticipant[];
        waitingInLobby: InMeetingParticipant[];
        raisedHand: InMeetingParticipant[];
        external: InMeetingParticipant[];
    }>(getParticipantGroups(infinityService.participants));

    useEffect(
        () =>
            infinityClientSignals.onParticipants.add(participants =>
                setParticipants(getParticipantGroups(participants)),
            ),
        [getParticipantGroups],
    );

    return participants;
};
