import type {
    IPsySegClass,
    IPsySegBuf,
    IColorSpaceType,
    IPsySegSetupInfo,
} from '@pexip/bg-blur';
import {
    PsySegSetupInfo,
    ColorSpaceType,
    PsySegBuf,
    psy_seg_remove_background,
    psy_seg_create,
    psy_seg_destroy,
} from '@pexip/bg-blur';

import type {Canvas} from '../../types';
import type {WasmPaths} from '../load';
import {loadWasms} from '../load';
import type {
    Segmenter,
    ProcessStatus,
    ProcessInputType,
    SegmentationModel,
} from '../types';
import {PROCESSING_HEIGHT, PROCESSING_WIDTH} from '../constants';
import {
    toSegmentation,
    createOffscreenCanvas,
    getCanvasRenderingContext2D,
} from '../utils';

type PSColorSpaceType = IColorSpaceType['COLOR_SPACE_RGBA'];

// Constants
const RELOAD_DNN_MODEL = false;

interface Props {
    setupInfo?: IPsySegSetupInfo;
    colorspace: PSColorSpaceType;
    segmenter?: IPsySegClass;
    inputBuffer: IPsySegBuf;
    outputBuffer: IPsySegBuf;
    bgBuffer?: IPsySegBuf;
    reload: boolean;
    width: number;
    height: number;
    internalCanvas?: Canvas;
    status: ProcessStatus;
    success: boolean;
    assertLoaded: boolean;
}

interface Options {
    width: number;
    height: number;
    reload: boolean;
    assertLoaded: boolean;
}

export const createSegmenter = (
    paths: WasmPaths[],
    {
        width = PROCESSING_WIDTH,
        height = PROCESSING_HEIGHT,
        reload = RELOAD_DNN_MODEL,
        assertLoaded = false,
    }: Partial<Options> = {},
): Segmenter => {
    const props: Props = {
        width,
        height,
        colorspace: ColorSpaceType().COLOR_SPACE_RGBA, // Only Support RGBA
        inputBuffer: PsySegBuf(width, height, 4, null),
        outputBuffer: PsySegBuf(width, height, 4, null),
        status: 'created',
        reload,
        success: false,
        assertLoaded,
    };

    const setInputBufferData = (input: ProcessInputType) => {
        if (input instanceof ImageData) {
            props.inputBuffer.data = input;
        } else {
            if (!props.internalCanvas) {
                props.internalCanvas = createOffscreenCanvas(
                    input.width,
                    input.height,
                );
            }
            const context = getCanvasRenderingContext2D(props.internalCanvas);
            context.drawImage(input, 0, 0, input.width, input.height);
            props.inputBuffer.data =
                context?.getImageData(0, 0, input.width, input.height) ?? null;
        }
    };

    const segmentPerson = async (input: ProcessInputType) => {
        if (!props.segmenter) {
            throw new Error(
                'Processor is not opened, please call open() method first',
            );
        }
        props.status = 'processing';
        setInputBufferData(input);
        props.success = await psy_seg_remove_background(
            props.segmenter,
            props.inputBuffer,
            props.colorspace,
            props.outputBuffer,
        );
        props.status = 'idle';
    };

    return {
        get model() {
            return 'personify' as SegmentationModel;
        },
        get width() {
            return props.width;
        },
        get height() {
            return props.height;
        },
        get status() {
            return props.status;
        },
        open: async () => {
            props.status = 'opening';
            if (!props.assertLoaded) {
                await loadWasms(paths);
                props.assertLoaded = true;
            }
            props.setupInfo = PsySegSetupInfo(width, height);
            props.segmenter = await psy_seg_create(
                props.setupInfo,
                props.reload,
            );
            props.status = 'opened';
        },

        process: async input => {
            await segmentPerson(input);
            if (!props.success || !props.outputBuffer.data) {
                return [];
            }
            return [
                toSegmentation(
                    props.outputBuffer.data,
                    'imagedata',
                    () => 'person',
                ),
            ];
        },

        close: () => {
            props.inputBuffer.data = null;
            props.outputBuffer.data = null;
            props.internalCanvas = undefined;
            props.status = 'closed';
        },

        destroy: async () => {
            props.status = 'destroying';
            if (props.segmenter) {
                await psy_seg_destroy(props.segmenter);
                props.segmenter = undefined;
            }
            props.assertLoaded = false;
            props.status = 'destroyed';
        },
    };
};
