import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icon, IconTypes} from '@pexip/components';
import type {RenderEffects} from '@pexip/media-processor';

import {TestId} from '../../../test/testIds';
import {Effect} from '../Effect/Effect.view';
import {ImageWrapper} from '../ImageWrapper/ImageWrapper.view';

export const EffectsList: React.FC<{
    active: RenderEffects;
    bgImageUrl: string;
    setEffect: (videoSegmentation: RenderEffects) => Promise<void>;
}> = ({active, bgImageUrl, setEffect}) => {
    const {t} = useTranslation();
    return (
        <div className="d-flex mb-2">
            <Effect
                name={t('media.effect.none', 'None')}
                onClick={() => setEffect('none')}
                active={active === 'none'}
                data-testid={TestId.SelectEffectNone}
            >
                <Icon source={IconTypes.IconBlock} />
            </Effect>
            <Effect
                name={t('media.effect.blur', 'Blur')}
                onClick={() => setEffect('blur')}
                active={active === 'blur'}
                className="ml-2"
                data-testid={TestId.SelectEffectBlur}
            >
                <Icon source={IconTypes.IconBackgroundBlur} />
            </Effect>
            <Effect
                name={t('media.effect.replace', 'Replace')}
                onClick={() => setEffect('overlay')}
                active={active === 'overlay'}
                className="ml-2"
                data-testid={TestId.SelectEffectOverlay}
            >
                <ImageWrapper backgroundUrl={bgImageUrl} />
            </Effect>
        </div>
    );
};
