export const validate200 = validate13;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {$ref: '#/properties/200/definitions/Result'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                locked: {
                    type: 'boolean',
                    description: 'Lock status of the conference',
                },
                guests_muted: {
                    type: 'boolean',
                    description: 'Whether Guests are muted',
                },
                started: {
                    type: 'boolean',
                    description: 'If the conference has been started',
                },
                live_captions_available: {
                    type: 'boolean',
                    description:
                        'If live captions is available for the conference',
                },
                direct_media: {type: 'boolean'},
                all_muted: {type: 'boolean'},
                presentation_allowed: {type: 'boolean'},
            },
            required: ['locked', 'guests_muted', 'started'],
            title: 'Result',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {$ref: '#/properties/200/definitions/Result'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        locked: {type: 'boolean', description: 'Lock status of the conference'},
        guests_muted: {
            type: 'boolean',
            description: 'Whether Guests are muted',
        },
        started: {
            type: 'boolean',
            description: 'If the conference has been started',
        },
        live_captions_available: {
            type: 'boolean',
            description: 'If live captions is available for the conference',
        },
        direct_media: {type: 'boolean'},
        all_muted: {type: 'boolean'},
        presentation_allowed: {type: 'boolean'},
    },
    required: ['locked', 'guests_muted', 'started'],
    title: 'Result',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema12.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.locked === undefined &&
                                            (missing1 = 'locked')) ||
                                        (data1.guests_muted === undefined &&
                                            (missing1 = 'guests_muted')) ||
                                        (data1.started === undefined &&
                                            (missing1 = 'started'))
                                    ) {
                                        validate11.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'locked' ||
                                                    key1 === 'guests_muted' ||
                                                    key1 === 'started' ||
                                                    key1 ===
                                                        'live_captions_available' ||
                                                    key1 === 'direct_media' ||
                                                    key1 === 'all_muted' ||
                                                    key1 ===
                                                        'presentation_allowed'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.locked !== undefined) {
                                                const _errs8 = errors;
                                                if (
                                                    typeof data1.locked !==
                                                    'boolean'
                                                ) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/locked',
                                                            schemaPath:
                                                                '#/properties/200/definitions/Result/properties/locked/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs8 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (
                                                    data1.guests_muted !==
                                                    undefined
                                                ) {
                                                    const _errs10 = errors;
                                                    if (
                                                        typeof data1.guests_muted !==
                                                        'boolean'
                                                    ) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/guests_muted',
                                                                schemaPath:
                                                                    '#/properties/200/definitions/Result/properties/guests_muted/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'boolean',
                                                                },
                                                                message:
                                                                    'must be boolean',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs10 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data1.started !==
                                                        undefined
                                                    ) {
                                                        const _errs12 = errors;
                                                        if (
                                                            typeof data1.started !==
                                                            'boolean'
                                                        ) {
                                                            validate11.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/result/started',
                                                                        schemaPath:
                                                                            '#/properties/200/definitions/Result/properties/started/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'boolean',
                                                                        },
                                                                        message:
                                                                            'must be boolean',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs12 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (
                                                            data1.live_captions_available !==
                                                            undefined
                                                        ) {
                                                            const _errs14 =
                                                                errors;
                                                            if (
                                                                typeof data1.live_captions_available !==
                                                                'boolean'
                                                            ) {
                                                                validate11.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/result/live_captions_available',
                                                                            schemaPath:
                                                                                '#/properties/200/definitions/Result/properties/live_captions_available/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'boolean',
                                                                            },
                                                                            message:
                                                                                'must be boolean',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid2 =
                                                                _errs14 ===
                                                                errors;
                                                        } else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (
                                                                data1.direct_media !==
                                                                undefined
                                                            ) {
                                                                const _errs16 =
                                                                    errors;
                                                                if (
                                                                    typeof data1.direct_media !==
                                                                    'boolean'
                                                                ) {
                                                                    validate11.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/result/direct_media',
                                                                                schemaPath:
                                                                                    '#/properties/200/definitions/Result/properties/direct_media/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'boolean',
                                                                                },
                                                                                message:
                                                                                    'must be boolean',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid2 =
                                                                    _errs16 ===
                                                                    errors;
                                                            } else {
                                                                var valid2 =
                                                                    true;
                                                            }
                                                            if (valid2) {
                                                                if (
                                                                    data1.all_muted !==
                                                                    undefined
                                                                ) {
                                                                    const _errs18 =
                                                                        errors;
                                                                    if (
                                                                        typeof data1.all_muted !==
                                                                        'boolean'
                                                                    ) {
                                                                        validate11.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/result/all_muted',
                                                                                    schemaPath:
                                                                                        '#/properties/200/definitions/Result/properties/all_muted/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'boolean',
                                                                                    },
                                                                                    message:
                                                                                        'must be boolean',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid2 =
                                                                        _errs18 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                                if (valid2) {
                                                                    if (
                                                                        data1.presentation_allowed !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs20 =
                                                                            errors;
                                                                        if (
                                                                            typeof data1.presentation_allowed !==
                                                                            'boolean'
                                                                        ) {
                                                                            validate11.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/result/presentation_allowed',
                                                                                        schemaPath:
                                                                                            '#/properties/200/definitions/Result/properties/presentation_allowed/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'boolean',
                                                                                        },
                                                                                        message:
                                                                                            'must be boolean',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid2 =
                                                                            _errs20 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid2 =
                                                                            true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate11.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
