import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Bar,
    Button,
    FontVariant,
    Form,
    Input,
    Text,
    TextHeading,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const EnterPin: React.FC<{
    errorText?: string;
    isHost?: boolean;
    onSubmit: ({pin}: {pin: string}) => void;
}> = ({errorText, isHost = false, onSubmit}) => {
    const {t} = useTranslation();
    const [pin, setPin] = useState('');

    const type = isHost
        ? t('common.host', 'Host')
        : t('common.meeting', 'Meeting');

    return (
        <>
            <TextHeading
                htmlTag="h2"
                fontVariant={FontVariant.H4}
                className="mb-2"
            >
                <Trans t={t} i18nKey="pin.what-is-the-pin">
                    What&apos;s the PIN for this meeting?
                </Trans>
            </TextHeading>
            <Text fontVariant={FontVariant.H6} variant="subtle">
                <Trans t={t} i18nKey="pin.how-to-find">
                    You can find the PIN in the meeting invite in your calendar
                    or email
                </Trans>
            </Text>
            <Form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    onSubmit({pin});
                }}
            >
                <Input
                    autoComplete="number"
                    className="mt-4 mb-4"
                    hasError={Boolean(errorText)}
                    errorText={errorText}
                    label={t('pin.label', {
                        defaultValue: '{{type}} PIN',
                        type,
                    })}
                    name="pin"
                    placeholder={t('pin.placeholder', `e.g. '1234'`)}
                    type="password"
                    value={pin}
                    onValueChange={setPin}
                    data-testid={TestId.InputPin}
                />

                <Bar>
                    <Button
                        modifier="fullWidth"
                        type="submit"
                        size="large"
                        data-testid={TestId.ButtonSetPin}
                    >
                        <Trans t={t} i18nKey="common.next">
                            Next
                        </Trans>
                    </Button>
                </Bar>
            </Form>
        </>
    );
};
