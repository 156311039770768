/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface CallsRtmpParticipantMap {
    '200': Status200;
    '400': string;
    '403': Status403;
    '500': string;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    result: Result;
}
export interface Result {
    /**
     * `call_uuid` is used to control the call
     */
    call_uuid: string;
    /**
     * Insecure (rtmp://) RTMP URL that can be connected to by the client
     */
    url: string;
    /**
     * Secure (rtmps://) RTMP URL that can be connected to by the client
     */
    secure_url: string;
}
export interface Status403 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result: 'Invalid token';
}
export interface TopLevel {
    /**
     * `RTMP` for an RTMP call.
     */
    call_type: 'RTMP';
    /**
     * Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.
     */
    present?: 'send' | 'receive';
    /**
     * Optional field. Set to true if this is to be treated as a streaming participant for recording purposes.
     */
    streaming?: boolean;
    /**
     * Optional field. If supplied it provides a maximum incoming / outgoing bandwidth in kbps.
     */
    bandwidth?: number;
}

import {
    validateBody,
    validate200,
    validate400,
    validate403,
    validate500,
} from './callsRtmpParticipantValidation';
type CallsRtmpParticipantResponse =
    | {status: 200; data: CallsRtmpParticipantMap['200']}
    | {status: 400; data: CallsRtmpParticipantMap['400']}
    | {status: 403; data: CallsRtmpParticipantMap['403']}
    | {status: 500; data: CallsRtmpParticipantMap['500']};
interface CallsRtmpParticipantParams {
    conferenceAlias: string;
    participantUuid: string;
}
export async function callsRtmpParticipant({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: CallsRtmpParticipantParams;
    body: CallsRtmpParticipantMap['Body'];
    host: string;
}): Promise<CallsRtmpParticipantResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/participants/${params.participantUuid}/calls`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 400) {
        const data = await res.text();
        if (!validate400(data)) {
            throw new Error(JSON.stringify(validate400.errors));
        }

        return {status: res.status, data};
    }
    if (res.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate403(data)) {
            throw new Error(JSON.stringify(validate403.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 500) {
        const data = await res.text();
        if (!validate500(data)) {
            throw new Error(JSON.stringify(validate500.errors));
        }

        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
