import React from 'react';
import {useTranslation} from 'react-i18next';

import {Modal, FontVariant, Text} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {useVideoTextOverlay} from '../../hooks/useVideoTextOverlay';

import styles from './MediaModal.module.scss';

export const MediaModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    src: {
        img?: string;
        video?: {
            mp4?: string;
            vp9?: string;
        };
    };
    videoTextOverlaySteps: {step: number; text: string; duration: number}[];
    altTag: string;
    browserName: string;
}> = ({isOpen, onClose, src, altTag, videoTextOverlaySteps, browserName}) => {
    const {t} = useTranslation();
    const {index, setIndex, videoRef, currentStep} = useVideoTextOverlay({
        isOpen,
        videoTextOverlaySteps,
    });
    const currentHelpText = currentStep.text;

    const onVideoEnded = async () => {
        if (videoRef.current) {
            setIndex(0);
            await videoRef.current?.play();
        }
    };

    return (
        <Modal
            data-testid={TestId.MediaModal}
            isOpen={isOpen}
            padding="none"
            sizeModifier="auto"
            uniqueTitle="MediaModal"
            closeOnOutsideClick
            onClose={onClose}
        >
            {src.video && (
                <video
                    autoPlay
                    muted
                    playsInline
                    className={styles.video}
                    onEnded={onVideoEnded}
                    ref={videoRef}
                >
                    <source type="video/webm" src={src.video.vp9} />
                    <source type="video/mp4" src={src.video.mp4} />
                </video>
            )}
            {!src.video && src.img && <img src={src.img} alt={altTag} />}
            {videoTextOverlaySteps && (
                <div className={styles.captionsContainer}>
                    <div className={styles.captions}>
                        <Text fontVariant={FontVariant.H3} className={'mr-4'}>
                            {currentStep.step}
                        </Text>
                        <Text fontVariant={FontVariant.H6}>
                            {t(
                                `media.permissions-unblock-video-overlay-step-${index}-${browserName}`,
                                {
                                    defaultValue: currentHelpText,
                                    hostName: window.location.host,
                                },
                            )}
                        </Text>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export type MediaModalProps = React.ComponentProps<typeof MediaModal>;
