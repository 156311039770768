import React, {useCallback, useEffect, useState} from 'react';
import {captureException} from '@sentry/react';

import type {
    InMeetingUI,
    NetworkState,
    SelfviewDisplayMode,
} from '@pexip/media-components';
import {
    createVADHook,
    InMeetingUIElements,
    Stats,
    isMobileDevice,
    useDeviceStatusInfo,
    useInputsState,
    DraggableFoldableInMeetingSelfview,
    exitPiP,
    calculateDisplayMode,
    useCallQuality,
    useCallQualityToast,
    ToastSpeakingWhileMuted,
} from '@pexip/media-components';

import {useConfig, config} from '../config';
import {
    getStreamQuality,
    useLocalMedia,
    useStreamStatus,
} from '../services/Media.service';
import {logger} from '../logger';
import {callSignals} from '../signals/Call.signals';
import {mediaSignals} from '../signals/Media.signals';
import {enableInMeetingUIAutoHide} from '../signals/InMeeting.signals';
import {useToggleFaceMode} from '../hooks/useToggleFaceMode';

const setVad = (isVad: boolean) => {
    config.set({key: 'vad', value: isVad});
};

const useVAD = createVADHook(setVad, mediaSignals.onVAD.add);

export const InMeetingLocalStream: React.FC<{
    inMeetingUI: InMeetingUI;
    meetingStageRef: React.RefObject<HTMLDivElement>;
    showSelfviewTooltip: boolean;
    networkState: NetworkState;
    setShowSelfviewTooltip: (shouldShow: boolean) => void;
    openSettings: () => void;
}> = ({
    inMeetingUI,
    meetingStageRef,
    showSelfviewTooltip,
    networkState,
    setShowSelfviewTooltip,
    openSettings,
}) => {
    const [isVideoInputMuted, setVideoInputMuted] =
        useConfig('isVideoInputMuted');
    const [isAudioInputMuted, setAudioInputMuted] =
        useConfig('isAudioInputMuted');
    const [username] = useConfig('displayName');
    const [showSelfviewHiddenTooltip, setShowSelfviewHiddenTooltip] = useConfig(
        'showSelfviewHiddenTooltip',
    );
    const [expandSelfView, setExpandSelfview] = useConfig('showSelfView');
    const [isMirrored, toggleFacingMode] = useToggleFaceMode();

    const media = useLocalMedia();
    const streamStatus = useStreamStatus();

    const inputStatusInfo = useDeviceStatusInfo(streamStatus);

    const {audioProps, videoProps} = useInputsState({
        isAudioInputMuted,
        isVideoInputMuted,
        inputStatusInfo,
        isMobileDevice: isMobileDevice(),
    });

    const isAudioInputMissing =
        audioProps.hasError || audioProps.hasAudioVideoError;
    const isVideoInputMissing =
        videoProps.hasError || videoProps.hasAudioVideoError;
    const [displayMode, setDisplayMode] = useState<SelfviewDisplayMode>(
        calculateDisplayMode(
            expandSelfView,
            isVideoInputMuted || isVideoInputMissing,
        ),
    );

    const [statsAreOpen, setStatsOpen] = useState(false);
    const openStats = useCallback(() => {
        setStatsOpen(true);
    }, []);
    const closeStats = useCallback(() => {
        setStatsOpen(false);
    }, []);

    const callQuality = useCallQuality({
        getStreamQuality,
        callQualitySignal: callSignals.onCallQuality,
        networkState,
    });
    useCallQualityToast({
        callQuality,
        networkState,
        getStreamQuality,
        onCallQualityAlertClick: openSettings,
    });

    const onToggleAudioClick = useCallback(() => {
        setAudioInputMuted(isMuted => !isMuted);
    }, [setAudioInputMuted]);
    const onToggleVideoClick = useCallback(() => {
        setVideoInputMuted(isMuted => !isMuted);
    }, [setVideoInputMuted]);

    const collapseSelfview = useCallback(() => {
        setExpandSelfview(false, true);

        if (showSelfviewHiddenTooltip) {
            setShowSelfviewTooltip(true);
            setShowSelfviewHiddenTooltip(false, true);
        }

        exitPiP().catch((reason: unknown) => {
            captureException(reason);
            logger.error({reason}, `Can't exit PiP`);
        });
    }, [
        setExpandSelfview,
        showSelfviewHiddenTooltip,
        setShowSelfviewTooltip,
        setShowSelfviewHiddenTooltip,
    ]);

    useEffect(() => {
        enableInMeetingUIAutoHide.emit({
            type: InMeetingUIElements.statsModal,
            isOpen: statsAreOpen,
        });
    }, [statsAreOpen]);

    useEffect(() => {
        setDisplayMode(
            calculateDisplayMode(
                expandSelfView,
                isVideoInputMuted || isVideoInputMissing,
            ),
        );
    }, [expandSelfView, isVideoInputMissing, isVideoInputMuted]);

    const [voiceDetected, setVoiceDetected] = useVAD(isAudioInputMuted);

    return (
        <>
            <DraggableFoldableInMeetingSelfview
                isFolded={displayMode === 'collapsed'}
                floatRoot={meetingStageRef}
                localMediaStream={media.stream}
                isVideoInputMuted={isVideoInputMuted}
                isVideoInputMissing={isVideoInputMissing}
                isAudioInputMuted={isAudioInputMuted}
                isAudioInputMissing={isAudioInputMissing}
                shouldShowUserAvatar={false}
                username={username}
                isMobileDevice={isMobileDevice()}
                autoHideProps={inMeetingUI.autoHideProps}
                callQualityPosition="bottomRight"
                quality={callQuality}
                onCollapseSelfview={collapseSelfview}
                onExpandSelfview={() => {
                    setExpandSelfview(true, true);
                }}
                onCallQualityClick={openStats}
                onToggleAudioClick={onToggleAudioClick}
                onToggleVideoClick={onToggleVideoClick}
                isSidePanelVisible={inMeetingUI.showSidePanel}
                showSelfviewTooltip={showSelfviewTooltip}
                setShowSelfviewTooltip={setShowSelfviewTooltip}
                toggleFacingMode={toggleFacingMode}
                isMirrored={isMirrored}
            />
            <ToastSpeakingWhileMuted
                voiceDetected={voiceDetected}
                setVoiceDetected={setVoiceDetected}
            />
            {statsAreOpen && (
                <Stats // avoid subscribing to signals unnecessarily
                    isOpen={statsAreOpen}
                    onClose={closeStats}
                    statsSignal={callSignals.onRtcStats}
                    callQualityStatsSignal={callSignals.onCallQualityStats}
                />
            )}
        </>
    );
};
