import {useEffect} from 'react';
import {v4 as uuid} from 'uuid';

import type {Participant} from '@pexip/infinity';
import type {ChatActivityType, ChatMessage} from '@pexip/media-components';
import {toTime, getGroupActivityMessages} from '@pexip/media-components';

import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {infinityService} from '../services/InfinityClient.service';

const getMissingParticipants = (
    participantA: Participant[],
    participantB: Participant[],
) => {
    const [missing] = participantB.filter(
        participant =>
            !participantA.some(
                ({identity}) => participant.identity === identity,
            ),
    );

    return missing;
};

const getActivityMessage =
    (activityType: ChatActivityType) =>
    (
        prevParticipants: Participant[],
        nextParticipants: Participant[],
        message: string,
    ) => {
        const missing = getMissingParticipants(
            prevParticipants,
            nextParticipants,
        );

        if (missing) {
            return {
                activityType,
                displayName: missing.displayName,
                id: uuid(),
                identity: missing.identity,
                message,
                type: 'activity',
                at: new Date(),
                timestamp: toTime(new Date()),
            } as ChatMessage;
        }
    };

const getUpdateMessagesFn =
    (
        setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
        currentParticipants: Participant[],
    ) =>
    (
        chatActivityType: ChatActivityType,
        nextParticipants: Participant[],
        message: string,
    ) => {
        const chatActivityMessage =
            chatActivityType === 'joined'
                ? getActivityMessage('joined')(
                      currentParticipants,
                      nextParticipants,
                      message,
                  )
                : getActivityMessage('left')(
                      nextParticipants,
                      currentParticipants,
                      message,
                  );

        if (chatActivityMessage) {
            setMessages(messages =>
                getGroupActivityMessages(chatActivityType)(
                    messages,
                    chatActivityMessage,
                ),
            );

            currentParticipants = nextParticipants;
        }
    };

export const useActivityChatMessages = (
    setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
) => {
    useEffect(() => {
        const updateMessages = getUpdateMessagesFn(
            setMessages,
            infinityService.participants,
        );

        const detachParticipantJoined =
            infinityClientSignals.onParticipantJoined.add(
                nextJoinedParticipants => {
                    updateMessages(
                        'joined',
                        nextJoinedParticipants,
                        'has joined',
                    );
                },
            );

        const detachParticipantLeft =
            infinityClientSignals.onParticipantLeft.add(
                nextLeftParticipants => {
                    updateMessages('left', nextLeftParticipants, 'has left');
                },
            );

        return () => {
            detachParticipantJoined();
            detachParticipantLeft();
        };
    }, [setMessages]);
};
