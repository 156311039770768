import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import type {Signal} from '@pexip/signal';

import type {DeviceStatusInfo} from '../types';

const EASE_OUT_TIMEOUT = 3000;

/**
 * Subscribe `onSilentDetected` signal and set the notice info accordingly
 *
 * @returns Tooltip info or `undefined` when it is not applicable
 */
export const useAudioDetection = (
    silentDetectedSubscribe: Signal<boolean>['add'],
    disableAudioSignalDetection: () => void,
) => {
    const {t} = useTranslation();
    const [silentTooltipInfo, setSilentTooltipInfo] =
        useState<DeviceStatusInfo>();

    useEffect(() => {
        let ignore = false;
        const unsubscribe = silentDetectedSubscribe(silent => {
            if (silent) {
                // FIXME: maybe better to use some sort of enum so all the apps can implement it themselves
                setSilentTooltipInfo({
                    title: t(
                        'device-status.no-audio-detected.title',
                        'No audio detected from microphone',
                    ),
                    message: t(
                        'device-status.no-audio-detected.message',
                        'We are not able to detect any sound from your microphone. Please check your settings',
                    ),
                });
            } else {
                setSilentTooltipInfo({
                    title: t(
                        'device-status.audio-detected.title',
                        'Audio detected from microphone',
                    ),
                    message: t(
                        'device-status.audio-detected.message',
                        'Great, now we have sound from you!',
                    ),
                });
                setTimeout(() => {
                    if (ignore) {
                        return;
                    }
                    setSilentTooltipInfo(undefined);
                }, EASE_OUT_TIMEOUT);
                disableAudioSignalDetection();
            }
        });
        return () => {
            unsubscribe();
            ignore = true;
        };
    }, [disableAudioSignalDetection, silentDetectedSubscribe, t]);

    return silentTooltipInfo;
};
