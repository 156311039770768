import React, {useCallback, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Button, FontVariant, Text} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import type {InMeetingParticipant} from '../../types';
import {ParticipantRowActions} from '../ParticipantRowActions/ParticipantRowActions.view';
import type {RowActionState} from '../ParticipantRowActions/ParticipantRowActions.module';

import {MultiCallList} from './MultiCallList.view';

import styles from './ParticipantRow.module.scss';

export const ParticipantRow: React.FC<
    Pick<RowActionState, 'isSpeaking'> & {
        displayName: string;
        handleRemoveUserRequest: (
            participant: InMeetingParticipant,
            displayName: string,
        ) => void;
        handleTransfer?: (identity: string) => void;
        isCurrentPeer: boolean;
        canPerformHostActions?: boolean;
        isHost: boolean;
        participant: InMeetingParticipant;
    }
> = ({
    displayName,
    handleRemoveUserRequest,
    handleTransfer,
    isCurrentPeer,
    canPerformHostActions = true,
    isHost,
    isSpeaking,
    participant,
}) => {
    const {t} = useTranslation();
    const [isHover, setIsHover] = useState(false);

    const onMouseEnter = useCallback(() => setIsHover(true), []);
    const onMouseLeave = useCallback(() => setIsHover(false), []);

    const {calls} = participant;

    const shouldDisplayChildCalls = useMemo(
        () => Boolean(calls && calls?.length > 1),
        [calls],
    );

    return (
        <div>
            <div
                key={participant.identity}
                className={styles.participantRow}
                onMouseEnter={onMouseEnter}
                onFocus={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onBlur={onMouseLeave}
                data-testid={TestId.ParticipantRow}
            >
                <div className={styles.participant}>
                    <Text
                        fontVariant={FontVariant.Body}
                        isTruncated
                        title={displayName}
                    >
                        {displayName}
                    </Text>
                </div>
                <div className={styles.actionsWrapper}>
                    <div className={styles.scrim} />
                    {participant.participantType === 'admit' ? (
                        <Button
                            aria-label={t(
                                'meeting.admit-participant',
                                'Admit participant',
                            )}
                            onClick={participant?.admit}
                            variant="secondary"
                            size="small"
                            data-testid={TestId.ButtonAdmitParticipant}
                        >
                            <Trans t={t} i18nKey="meeting.admit">
                                Admit
                            </Trans>
                        </Button>
                    ) : (
                        <ParticipantRowActions
                            participant={participant}
                            canPerformHostActions={canPerformHostActions}
                            isHost={isHost}
                            isSpeaking={
                                shouldDisplayChildCalls ? false : isSpeaking
                            }
                            isHover={isHover}
                            isCurrentPeer={isCurrentPeer}
                            handleRemoveUserRequest={handleRemoveUserRequest}
                            handleTransfer={handleTransfer}
                            displayName={displayName}
                            hasChildCalls={shouldDisplayChildCalls}
                            onMouseLeave={onMouseLeave}
                        />
                    )}
                </div>
            </div>
            {shouldDisplayChildCalls && (
                <MultiCallList calls={participant.calls} />
            )}
        </div>
    );
};
