export const validate200 = validate13;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'array',
                    items: {$ref: '#/properties/200/definitions/Participant'},
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Participant: {
            type: 'object',
            additionalProperties: false,
            properties: {
                api_url: {type: 'string'},
                buzz_time: {
                    type: 'number',
                    description:
                        'A Unix timestamp of when this participant raised their hand, otherwise zero.',
                },
                call_direction: {
                    type: 'string',
                    enum: ['in', 'out'],
                    description:
                        'Either `in` or `out` as to whether this is an inbound or outbound call.',
                },
                call_tag: {
                    type: 'string',
                    description:
                        'An optional call tag that is assigned to this participant.',
                },
                disconnect_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant can be disconnected, `NO` if not.',
                },
                display_name: {
                    type: 'string',
                    description: 'The display name of the participant.',
                },
                encryption: {
                    type: 'string',
                    enum: ['On', 'Off'],
                    description:
                        '`On` or `Off` as to whether this participant is connected via encrypted media.',
                },
                external_node_uuid: {
                    type: 'string',
                    description:
                        'The UUID of an external node e.g. a Skype for Business / Lync meeting associated with an external participant. This allows grouping of external participants as the UUID will be the same for all participants associated with that external node.',
                },
                fecc_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if this participant can be sent FECC messages; `NO` if not.',
                },
                has_media: {
                    type: 'boolean',
                    description:
                        'Boolean indicating whether the user has media capabilities.',
                },
                is_audio_only_call: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description: 'Set to `YES` if the call is audio only.',
                },
                is_external: {
                    type: 'boolean',
                    description:
                        'Boolean indicating if it is an external participant, e.g. coming in from a Skype for Business / Lync meeting.',
                },
                is_muted: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant is administratively audio muted.',
                },
                is_presenting: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant is the current presenter.',
                },
                is_streaming_conference: {
                    type: 'boolean',
                    description:
                        'Boolean indicating whether this is a streaming/recording participant.',
                },
                is_video_call: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the call has video capability.',
                },
                is_video_muted: {
                    type: 'boolean',
                    description:
                        'Boolean indicating whether this participant is administratively video muted.',
                },
                is_video_silent: {
                    type: 'boolean',
                    description:
                        'When participant is considered silent. Eg when frozen, black or outside of the frame.',
                },
                is_main_video_dropped_out: {type: 'boolean'},
                local_alias: {
                    type: 'string',
                    description:
                        'The calling or `from` alias. This is the alias that the recipient would use to return the call.',
                },
                mute_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant can be muted, `NO` if not.',
                },
                needs_presentation_in_mix: {type: 'boolean'},
                overlay_text: {
                    type: 'string',
                    description:
                        'Text that may be used as an alternative to display_name as the participant name overlay text.',
                },
                presentation_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the presentation is supported, `NO` if not.',
                },
                protocol: {
                    type: 'string',
                    enum: ['api', 'webrtc', 'sip', 'rtmp', 'h323', 'mssip'],
                    description:
                        'The call protocol. (Note that the protocol is always reported as `api` when an Infinity Connect client dials in to Pexip Infinity.)',
                },
                role: {
                    type: 'string',
                    enum: ['chair', 'guest'],
                    description:
                        'The level of privileges the participant has in the conference',
                },
                rx_presentation_policy: {
                    type: 'string',
                    enum: ['ALLOW', 'DENY'],
                    description:
                        'Set to `ALLOW` if the participant is administratively allowed to receive presentation, or `DENY` if disallowed.',
                },
                service_type: {
                    type: 'string',
                    enum: [
                        'connecting',
                        'waiting_room',
                        'ivr',
                        'conference',
                        'lecture',
                        'gateway',
                        'test_call',
                    ],
                },
                spotlight: {
                    type: 'number',
                    description:
                        'A Unix timestamp of when this participant was spotlighted, if spotlight is used.',
                },
                start_time: {
                    type: 'number',
                    description:
                        'A Unix timestamp of when this participant joined (UTC).',
                },
                transfer_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if this participant can be transferred into another VMR; `NO` if not.',
                },
                uuid: {
                    type: 'string',
                    description:
                        'The UUID of this participant, to use with other operations.',
                },
                uri: {
                    type: 'string',
                    description:
                        'The URI of the participant. Empty for Guest participants.',
                },
                vendor: {
                    type: 'string',
                    description:
                        'The vendor identifier of the browser/endpoint with which the participant is connecting. Empty for Guest participants.',
                },
            },
            required: [
                'buzz_time',
                'call_direction',
                'disconnect_supported',
                'display_name',
                'encryption',
                'external_node_uuid',
                'fecc_supported',
                'has_media',
                'is_audio_only_call',
                'is_external',
                'is_muted',
                'is_presenting',
                'is_streaming_conference',
                'is_video_call',
                'is_video_muted',
                'is_video_silent',
                'local_alias',
                'mute_supported',
                'overlay_text',
                'protocol',
                'role',
                'rx_presentation_policy',
                'spotlight',
                'start_time',
                'transfer_supported',
                'uuid',
            ],
            title: 'Participant',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'array',
            items: {$ref: '#/properties/200/definitions/Participant'},
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        api_url: {type: 'string'},
        buzz_time: {
            type: 'number',
            description:
                'A Unix timestamp of when this participant raised their hand, otherwise zero.',
        },
        call_direction: {
            type: 'string',
            enum: ['in', 'out'],
            description:
                'Either `in` or `out` as to whether this is an inbound or outbound call.',
        },
        call_tag: {
            type: 'string',
            description:
                'An optional call tag that is assigned to this participant.',
        },
        disconnect_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant can be disconnected, `NO` if not.',
        },
        display_name: {
            type: 'string',
            description: 'The display name of the participant.',
        },
        encryption: {
            type: 'string',
            enum: ['On', 'Off'],
            description:
                '`On` or `Off` as to whether this participant is connected via encrypted media.',
        },
        external_node_uuid: {
            type: 'string',
            description:
                'The UUID of an external node e.g. a Skype for Business / Lync meeting associated with an external participant. This allows grouping of external participants as the UUID will be the same for all participants associated with that external node.',
        },
        fecc_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if this participant can be sent FECC messages; `NO` if not.',
        },
        has_media: {
            type: 'boolean',
            description:
                'Boolean indicating whether the user has media capabilities.',
        },
        is_audio_only_call: {
            type: 'string',
            enum: ['YES', 'NO'],
            description: 'Set to `YES` if the call is audio only.',
        },
        is_external: {
            type: 'boolean',
            description:
                'Boolean indicating if it is an external participant, e.g. coming in from a Skype for Business / Lync meeting.',
        },
        is_muted: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant is administratively audio muted.',
        },
        is_presenting: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant is the current presenter.',
        },
        is_streaming_conference: {
            type: 'boolean',
            description:
                'Boolean indicating whether this is a streaming/recording participant.',
        },
        is_video_call: {
            type: 'string',
            enum: ['YES', 'NO'],
            description: 'Set to `YES` if the call has video capability.',
        },
        is_video_muted: {
            type: 'boolean',
            description:
                'Boolean indicating whether this participant is administratively video muted.',
        },
        is_video_silent: {
            type: 'boolean',
            description:
                'When participant is considered silent. Eg when frozen, black or outside of the frame.',
        },
        is_main_video_dropped_out: {type: 'boolean'},
        local_alias: {
            type: 'string',
            description:
                'The calling or `from` alias. This is the alias that the recipient would use to return the call.',
        },
        mute_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant can be muted, `NO` if not.',
        },
        needs_presentation_in_mix: {type: 'boolean'},
        overlay_text: {
            type: 'string',
            description:
                'Text that may be used as an alternative to display_name as the participant name overlay text.',
        },
        presentation_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the presentation is supported, `NO` if not.',
        },
        protocol: {
            type: 'string',
            enum: ['api', 'webrtc', 'sip', 'rtmp', 'h323', 'mssip'],
            description:
                'The call protocol. (Note that the protocol is always reported as `api` when an Infinity Connect client dials in to Pexip Infinity.)',
        },
        role: {
            type: 'string',
            enum: ['chair', 'guest'],
            description:
                'The level of privileges the participant has in the conference',
        },
        rx_presentation_policy: {
            type: 'string',
            enum: ['ALLOW', 'DENY'],
            description:
                'Set to `ALLOW` if the participant is administratively allowed to receive presentation, or `DENY` if disallowed.',
        },
        service_type: {
            type: 'string',
            enum: [
                'connecting',
                'waiting_room',
                'ivr',
                'conference',
                'lecture',
                'gateway',
                'test_call',
            ],
        },
        spotlight: {
            type: 'number',
            description:
                'A Unix timestamp of when this participant was spotlighted, if spotlight is used.',
        },
        start_time: {
            type: 'number',
            description:
                'A Unix timestamp of when this participant joined (UTC).',
        },
        transfer_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if this participant can be transferred into another VMR; `NO` if not.',
        },
        uuid: {
            type: 'string',
            description:
                'The UUID of this participant, to use with other operations.',
        },
        uri: {
            type: 'string',
            description:
                'The URI of the participant. Empty for Guest participants.',
        },
        vendor: {
            type: 'string',
            description:
                'The vendor identifier of the browser/endpoint with which the participant is connecting. Empty for Guest participants.',
        },
    },
    required: [
        'buzz_time',
        'call_direction',
        'disconnect_supported',
        'display_name',
        'encryption',
        'external_node_uuid',
        'fecc_supported',
        'has_media',
        'is_audio_only_call',
        'is_external',
        'is_muted',
        'is_presenting',
        'is_streaming_conference',
        'is_video_call',
        'is_video_muted',
        'is_video_silent',
        'local_alias',
        'mute_supported',
        'overlay_text',
        'protocol',
        'role',
        'rx_presentation_policy',
        'spotlight',
        'start_time',
        'transfer_supported',
        'uuid',
    ],
    title: 'Participant',
};
const func2 = Object.prototype.hasOwnProperty;
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema12.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        let data2 = data1[i0];
                                        const _errs6 = errors;
                                        const _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (
                                                data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)
                                            ) {
                                                let missing1;
                                                if (
                                                    (data2.buzz_time ===
                                                        undefined &&
                                                        (missing1 =
                                                            'buzz_time')) ||
                                                    (data2.call_direction ===
                                                        undefined &&
                                                        (missing1 =
                                                            'call_direction')) ||
                                                    (data2.disconnect_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'disconnect_supported')) ||
                                                    (data2.display_name ===
                                                        undefined &&
                                                        (missing1 =
                                                            'display_name')) ||
                                                    (data2.encryption ===
                                                        undefined &&
                                                        (missing1 =
                                                            'encryption')) ||
                                                    (data2.external_node_uuid ===
                                                        undefined &&
                                                        (missing1 =
                                                            'external_node_uuid')) ||
                                                    (data2.fecc_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'fecc_supported')) ||
                                                    (data2.has_media ===
                                                        undefined &&
                                                        (missing1 =
                                                            'has_media')) ||
                                                    (data2.is_audio_only_call ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_audio_only_call')) ||
                                                    (data2.is_external ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_external')) ||
                                                    (data2.is_muted ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_muted')) ||
                                                    (data2.is_presenting ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_presenting')) ||
                                                    (data2.is_streaming_conference ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_streaming_conference')) ||
                                                    (data2.is_video_call ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_video_call')) ||
                                                    (data2.is_video_muted ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_video_muted')) ||
                                                    (data2.is_video_silent ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_video_silent')) ||
                                                    (data2.local_alias ===
                                                        undefined &&
                                                        (missing1 =
                                                            'local_alias')) ||
                                                    (data2.mute_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'mute_supported')) ||
                                                    (data2.overlay_text ===
                                                        undefined &&
                                                        (missing1 =
                                                            'overlay_text')) ||
                                                    (data2.protocol ===
                                                        undefined &&
                                                        (missing1 =
                                                            'protocol')) ||
                                                    (data2.role === undefined &&
                                                        (missing1 = 'role')) ||
                                                    (data2.rx_presentation_policy ===
                                                        undefined &&
                                                        (missing1 =
                                                            'rx_presentation_policy')) ||
                                                    (data2.spotlight ===
                                                        undefined &&
                                                        (missing1 =
                                                            'spotlight')) ||
                                                    (data2.start_time ===
                                                        undefined &&
                                                        (missing1 =
                                                            'start_time')) ||
                                                    (data2.transfer_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'transfer_supported')) ||
                                                    (data2.uuid === undefined &&
                                                        (missing1 = 'uuid'))
                                                ) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/' +
                                                                i0,
                                                            schemaPath:
                                                                '#/properties/200/definitions/Participant/required',
                                                            keyword: 'required',
                                                            params: {
                                                                missingProperty:
                                                                    missing1,
                                                            },
                                                            message:
                                                                "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                } else {
                                                    const _errs9 = errors;
                                                    for (const key1 in data2) {
                                                        if (
                                                            !func2.call(
                                                                schema13.properties,
                                                                key1,
                                                            )
                                                        ) {
                                                            delete data2[key1];
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (
                                                            data2.api_url !==
                                                            undefined
                                                        ) {
                                                            const _errs10 =
                                                                errors;
                                                            if (
                                                                typeof data2.api_url !==
                                                                'string'
                                                            ) {
                                                                validate11.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/result/' +
                                                                                i0 +
                                                                                '/api_url',
                                                                            schemaPath:
                                                                                '#/properties/200/definitions/Participant/properties/api_url/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'string',
                                                                            },
                                                                            message:
                                                                                'must be string',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid3 =
                                                                _errs10 ===
                                                                errors;
                                                        } else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (
                                                                data2.buzz_time !==
                                                                undefined
                                                            ) {
                                                                const _errs12 =
                                                                    errors;
                                                                if (
                                                                    !(
                                                                        typeof data2.buzz_time ==
                                                                        'number'
                                                                    )
                                                                ) {
                                                                    validate11.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/result/' +
                                                                                    i0 +
                                                                                    '/buzz_time',
                                                                                schemaPath:
                                                                                    '#/properties/200/definitions/Participant/properties/buzz_time/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'number',
                                                                                },
                                                                                message:
                                                                                    'must be number',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid3 =
                                                                    _errs12 ===
                                                                    errors;
                                                            } else {
                                                                var valid3 =
                                                                    true;
                                                            }
                                                            if (valid3) {
                                                                if (
                                                                    data2.call_direction !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data2.call_direction;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        typeof data5 !==
                                                                        'string'
                                                                    ) {
                                                                        validate11.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/result/' +
                                                                                        i0 +
                                                                                        '/call_direction',
                                                                                    schemaPath:
                                                                                        '#/properties/200/definitions/Participant/properties/call_direction/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    if (
                                                                        !(
                                                                            data5 ===
                                                                                'in' ||
                                                                            data5 ===
                                                                                'out'
                                                                        )
                                                                    ) {
                                                                        validate11.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/result/' +
                                                                                        i0 +
                                                                                        '/call_direction',
                                                                                    schemaPath:
                                                                                        '#/properties/200/definitions/Participant/properties/call_direction/enum',
                                                                                    keyword:
                                                                                        'enum',
                                                                                    params: {
                                                                                        allowedValues:
                                                                                            schema13
                                                                                                .properties
                                                                                                .call_direction
                                                                                                .enum,
                                                                                    },
                                                                                    message:
                                                                                        'must be equal to one of the allowed values',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data2.call_tag !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs16 =
                                                                            errors;
                                                                        if (
                                                                            typeof data2.call_tag !==
                                                                            'string'
                                                                        ) {
                                                                            validate11.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/result/' +
                                                                                            i0 +
                                                                                            '/call_tag',
                                                                                        schemaPath:
                                                                                            '#/properties/200/definitions/Participant/properties/call_tag/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'string',
                                                                                        },
                                                                                        message:
                                                                                            'must be string',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs16 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid3
                                                                    ) {
                                                                        if (
                                                                            data2.disconnect_supported !==
                                                                            undefined
                                                                        ) {
                                                                            let data7 =
                                                                                data2.disconnect_supported;
                                                                            const _errs18 =
                                                                                errors;
                                                                            if (
                                                                                typeof data7 !==
                                                                                'string'
                                                                            ) {
                                                                                validate11.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/result/' +
                                                                                                i0 +
                                                                                                '/disconnect_supported',
                                                                                            schemaPath:
                                                                                                '#/properties/200/definitions/Participant/properties/disconnect_supported/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            if (
                                                                                !(
                                                                                    data7 ===
                                                                                        'YES' ||
                                                                                    data7 ===
                                                                                        'NO'
                                                                                )
                                                                            ) {
                                                                                validate11.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/result/' +
                                                                                                i0 +
                                                                                                '/disconnect_supported',
                                                                                            schemaPath:
                                                                                                '#/properties/200/definitions/Participant/properties/disconnect_supported/enum',
                                                                                            keyword:
                                                                                                'enum',
                                                                                            params: {
                                                                                                allowedValues:
                                                                                                    schema13
                                                                                                        .properties
                                                                                                        .disconnect_supported
                                                                                                        .enum,
                                                                                            },
                                                                                            message:
                                                                                                'must be equal to one of the allowed values',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid3 =
                                                                                _errs18 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid3 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid3
                                                                        ) {
                                                                            if (
                                                                                data2.display_name !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs20 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data2.display_name !==
                                                                                    'string'
                                                                                ) {
                                                                                    validate11.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/result/' +
                                                                                                    i0 +
                                                                                                    '/display_name',
                                                                                                schemaPath:
                                                                                                    '#/properties/200/definitions/Participant/properties/display_name/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'string',
                                                                                                },
                                                                                                message:
                                                                                                    'must be string',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid3 =
                                                                                    _errs20 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid3 =
                                                                                    true;
                                                                            }
                                                                            if (
                                                                                valid3
                                                                            ) {
                                                                                if (
                                                                                    data2.encryption !==
                                                                                    undefined
                                                                                ) {
                                                                                    let data9 =
                                                                                        data2.encryption;
                                                                                    const _errs22 =
                                                                                        errors;
                                                                                    if (
                                                                                        typeof data9 !==
                                                                                        'string'
                                                                                    ) {
                                                                                        validate11.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/result/' +
                                                                                                        i0 +
                                                                                                        '/encryption',
                                                                                                    schemaPath:
                                                                                                        '#/properties/200/definitions/Participant/properties/encryption/type',
                                                                                                    keyword:
                                                                                                        'type',
                                                                                                    params: {
                                                                                                        type: 'string',
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be string',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    if (
                                                                                        !(
                                                                                            data9 ===
                                                                                                'On' ||
                                                                                            data9 ===
                                                                                                'Off'
                                                                                        )
                                                                                    ) {
                                                                                        validate11.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/result/' +
                                                                                                        i0 +
                                                                                                        '/encryption',
                                                                                                    schemaPath:
                                                                                                        '#/properties/200/definitions/Participant/properties/encryption/enum',
                                                                                                    keyword:
                                                                                                        'enum',
                                                                                                    params: {
                                                                                                        allowedValues:
                                                                                                            schema13
                                                                                                                .properties
                                                                                                                .encryption
                                                                                                                .enum,
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be equal to one of the allowed values',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid3 =
                                                                                        _errs22 ===
                                                                                        errors;
                                                                                } else {
                                                                                    var valid3 =
                                                                                        true;
                                                                                }
                                                                                if (
                                                                                    valid3
                                                                                ) {
                                                                                    if (
                                                                                        data2.external_node_uuid !==
                                                                                        undefined
                                                                                    ) {
                                                                                        const _errs24 =
                                                                                            errors;
                                                                                        if (
                                                                                            typeof data2.external_node_uuid !==
                                                                                            'string'
                                                                                        ) {
                                                                                            validate11.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/result/' +
                                                                                                            i0 +
                                                                                                            '/external_node_uuid',
                                                                                                        schemaPath:
                                                                                                            '#/properties/200/definitions/Participant/properties/external_node_uuid/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'string',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be string',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid3 =
                                                                                            _errs24 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid3 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid3
                                                                                    ) {
                                                                                        if (
                                                                                            data2.fecc_supported !==
                                                                                            undefined
                                                                                        ) {
                                                                                            let data11 =
                                                                                                data2.fecc_supported;
                                                                                            const _errs26 =
                                                                                                errors;
                                                                                            if (
                                                                                                typeof data11 !==
                                                                                                'string'
                                                                                            ) {
                                                                                                validate11.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/result/' +
                                                                                                                i0 +
                                                                                                                '/fecc_supported',
                                                                                                            schemaPath:
                                                                                                                '#/properties/200/definitions/Participant/properties/fecc_supported/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'string',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be string',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            if (
                                                                                                !(
                                                                                                    data11 ===
                                                                                                        'YES' ||
                                                                                                    data11 ===
                                                                                                        'NO'
                                                                                                )
                                                                                            ) {
                                                                                                validate11.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/result/' +
                                                                                                                i0 +
                                                                                                                '/fecc_supported',
                                                                                                            schemaPath:
                                                                                                                '#/properties/200/definitions/Participant/properties/fecc_supported/enum',
                                                                                                            keyword:
                                                                                                                'enum',
                                                                                                            params: {
                                                                                                                allowedValues:
                                                                                                                    schema13
                                                                                                                        .properties
                                                                                                                        .fecc_supported
                                                                                                                        .enum,
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be equal to one of the allowed values',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid3 =
                                                                                                _errs26 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid3 =
                                                                                                true;
                                                                                        }
                                                                                        if (
                                                                                            valid3
                                                                                        ) {
                                                                                            if (
                                                                                                data2.has_media !==
                                                                                                undefined
                                                                                            ) {
                                                                                                const _errs28 =
                                                                                                    errors;
                                                                                                if (
                                                                                                    typeof data2.has_media !==
                                                                                                    'boolean'
                                                                                                ) {
                                                                                                    validate11.errors =
                                                                                                        [
                                                                                                            {
                                                                                                                instancePath:
                                                                                                                    instancePath +
                                                                                                                    '/result/' +
                                                                                                                    i0 +
                                                                                                                    '/has_media',
                                                                                                                schemaPath:
                                                                                                                    '#/properties/200/definitions/Participant/properties/has_media/type',
                                                                                                                keyword:
                                                                                                                    'type',
                                                                                                                params: {
                                                                                                                    type: 'boolean',
                                                                                                                },
                                                                                                                message:
                                                                                                                    'must be boolean',
                                                                                                            },
                                                                                                        ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid3 =
                                                                                                    _errs28 ===
                                                                                                    errors;
                                                                                            } else {
                                                                                                var valid3 =
                                                                                                    true;
                                                                                            }
                                                                                            if (
                                                                                                valid3
                                                                                            ) {
                                                                                                if (
                                                                                                    data2.is_audio_only_call !==
                                                                                                    undefined
                                                                                                ) {
                                                                                                    let data13 =
                                                                                                        data2.is_audio_only_call;
                                                                                                    const _errs30 =
                                                                                                        errors;
                                                                                                    if (
                                                                                                        typeof data13 !==
                                                                                                        'string'
                                                                                                    ) {
                                                                                                        validate11.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/result/' +
                                                                                                                        i0 +
                                                                                                                        '/is_audio_only_call',
                                                                                                                    schemaPath:
                                                                                                                        '#/properties/200/definitions/Participant/properties/is_audio_only_call/type',
                                                                                                                    keyword:
                                                                                                                        'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    if (
                                                                                                        !(
                                                                                                            data13 ===
                                                                                                                'YES' ||
                                                                                                            data13 ===
                                                                                                                'NO'
                                                                                                        )
                                                                                                    ) {
                                                                                                        validate11.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/result/' +
                                                                                                                        i0 +
                                                                                                                        '/is_audio_only_call',
                                                                                                                    schemaPath:
                                                                                                                        '#/properties/200/definitions/Participant/properties/is_audio_only_call/enum',
                                                                                                                    keyword:
                                                                                                                        'enum',
                                                                                                                    params: {
                                                                                                                        allowedValues:
                                                                                                                            schema13
                                                                                                                                .properties
                                                                                                                                .is_audio_only_call
                                                                                                                                .enum,
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be equal to one of the allowed values',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid3 =
                                                                                                        _errs30 ===
                                                                                                        errors;
                                                                                                } else {
                                                                                                    var valid3 =
                                                                                                        true;
                                                                                                }
                                                                                                if (
                                                                                                    valid3
                                                                                                ) {
                                                                                                    if (
                                                                                                        data2.is_external !==
                                                                                                        undefined
                                                                                                    ) {
                                                                                                        const _errs32 =
                                                                                                            errors;
                                                                                                        if (
                                                                                                            typeof data2.is_external !==
                                                                                                            'boolean'
                                                                                                        ) {
                                                                                                            validate11.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/result/' +
                                                                                                                            i0 +
                                                                                                                            '/is_external',
                                                                                                                        schemaPath:
                                                                                                                            '#/properties/200/definitions/Participant/properties/is_external/type',
                                                                                                                        keyword:
                                                                                                                            'type',
                                                                                                                        params: {
                                                                                                                            type: 'boolean',
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be boolean',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid3 =
                                                                                                            _errs32 ===
                                                                                                            errors;
                                                                                                    } else {
                                                                                                        var valid3 =
                                                                                                            true;
                                                                                                    }
                                                                                                    if (
                                                                                                        valid3
                                                                                                    ) {
                                                                                                        if (
                                                                                                            data2.is_muted !==
                                                                                                            undefined
                                                                                                        ) {
                                                                                                            let data15 =
                                                                                                                data2.is_muted;
                                                                                                            const _errs34 =
                                                                                                                errors;
                                                                                                            if (
                                                                                                                typeof data15 !==
                                                                                                                'string'
                                                                                                            ) {
                                                                                                                validate11.errors =
                                                                                                                    [
                                                                                                                        {
                                                                                                                            instancePath:
                                                                                                                                instancePath +
                                                                                                                                '/result/' +
                                                                                                                                i0 +
                                                                                                                                '/is_muted',
                                                                                                                            schemaPath:
                                                                                                                                '#/properties/200/definitions/Participant/properties/is_muted/type',
                                                                                                                            keyword:
                                                                                                                                'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message:
                                                                                                                                'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            if (
                                                                                                                !(
                                                                                                                    data15 ===
                                                                                                                        'YES' ||
                                                                                                                    data15 ===
                                                                                                                        'NO'
                                                                                                                )
                                                                                                            ) {
                                                                                                                validate11.errors =
                                                                                                                    [
                                                                                                                        {
                                                                                                                            instancePath:
                                                                                                                                instancePath +
                                                                                                                                '/result/' +
                                                                                                                                i0 +
                                                                                                                                '/is_muted',
                                                                                                                            schemaPath:
                                                                                                                                '#/properties/200/definitions/Participant/properties/is_muted/enum',
                                                                                                                            keyword:
                                                                                                                                'enum',
                                                                                                                            params: {
                                                                                                                                allowedValues:
                                                                                                                                    schema13
                                                                                                                                        .properties
                                                                                                                                        .is_muted
                                                                                                                                        .enum,
                                                                                                                            },
                                                                                                                            message:
                                                                                                                                'must be equal to one of the allowed values',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid3 =
                                                                                                                _errs34 ===
                                                                                                                errors;
                                                                                                        } else {
                                                                                                            var valid3 =
                                                                                                                true;
                                                                                                        }
                                                                                                        if (
                                                                                                            valid3
                                                                                                        ) {
                                                                                                            if (
                                                                                                                data2.is_presenting !==
                                                                                                                undefined
                                                                                                            ) {
                                                                                                                let data16 =
                                                                                                                    data2.is_presenting;
                                                                                                                const _errs36 =
                                                                                                                    errors;
                                                                                                                if (
                                                                                                                    typeof data16 !==
                                                                                                                    'string'
                                                                                                                ) {
                                                                                                                    validate11.errors =
                                                                                                                        [
                                                                                                                            {
                                                                                                                                instancePath:
                                                                                                                                    instancePath +
                                                                                                                                    '/result/' +
                                                                                                                                    i0 +
                                                                                                                                    '/is_presenting',
                                                                                                                                schemaPath:
                                                                                                                                    '#/properties/200/definitions/Participant/properties/is_presenting/type',
                                                                                                                                keyword:
                                                                                                                                    'type',
                                                                                                                                params: {
                                                                                                                                    type: 'string',
                                                                                                                                },
                                                                                                                                message:
                                                                                                                                    'must be string',
                                                                                                                            },
                                                                                                                        ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                if (
                                                                                                                    !(
                                                                                                                        data16 ===
                                                                                                                            'YES' ||
                                                                                                                        data16 ===
                                                                                                                            'NO'
                                                                                                                    )
                                                                                                                ) {
                                                                                                                    validate11.errors =
                                                                                                                        [
                                                                                                                            {
                                                                                                                                instancePath:
                                                                                                                                    instancePath +
                                                                                                                                    '/result/' +
                                                                                                                                    i0 +
                                                                                                                                    '/is_presenting',
                                                                                                                                schemaPath:
                                                                                                                                    '#/properties/200/definitions/Participant/properties/is_presenting/enum',
                                                                                                                                keyword:
                                                                                                                                    'enum',
                                                                                                                                params: {
                                                                                                                                    allowedValues:
                                                                                                                                        schema13
                                                                                                                                            .properties
                                                                                                                                            .is_presenting
                                                                                                                                            .enum,
                                                                                                                                },
                                                                                                                                message:
                                                                                                                                    'must be equal to one of the allowed values',
                                                                                                                            },
                                                                                                                        ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid3 =
                                                                                                                    _errs36 ===
                                                                                                                    errors;
                                                                                                            } else {
                                                                                                                var valid3 =
                                                                                                                    true;
                                                                                                            }
                                                                                                            if (
                                                                                                                valid3
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    data2.is_streaming_conference !==
                                                                                                                    undefined
                                                                                                                ) {
                                                                                                                    const _errs38 =
                                                                                                                        errors;
                                                                                                                    if (
                                                                                                                        typeof data2.is_streaming_conference !==
                                                                                                                        'boolean'
                                                                                                                    ) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath:
                                                                                                                                        instancePath +
                                                                                                                                        '/result/' +
                                                                                                                                        i0 +
                                                                                                                                        '/is_streaming_conference',
                                                                                                                                    schemaPath:
                                                                                                                                        '#/properties/200/definitions/Participant/properties/is_streaming_conference/type',
                                                                                                                                    keyword:
                                                                                                                                        'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'boolean',
                                                                                                                                    },
                                                                                                                                    message:
                                                                                                                                        'must be boolean',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid3 =
                                                                                                                        _errs38 ===
                                                                                                                        errors;
                                                                                                                } else {
                                                                                                                    var valid3 =
                                                                                                                        true;
                                                                                                                }
                                                                                                                if (
                                                                                                                    valid3
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        data2.is_video_call !==
                                                                                                                        undefined
                                                                                                                    ) {
                                                                                                                        let data18 =
                                                                                                                            data2.is_video_call;
                                                                                                                        const _errs40 =
                                                                                                                            errors;
                                                                                                                        if (
                                                                                                                            typeof data18 !==
                                                                                                                            'string'
                                                                                                                        ) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath:
                                                                                                                                            instancePath +
                                                                                                                                            '/result/' +
                                                                                                                                            i0 +
                                                                                                                                            '/is_video_call',
                                                                                                                                        schemaPath:
                                                                                                                                            '#/properties/200/definitions/Participant/properties/is_video_call/type',
                                                                                                                                        keyword:
                                                                                                                                            'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'string',
                                                                                                                                        },
                                                                                                                                        message:
                                                                                                                                            'must be string',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        if (
                                                                                                                            !(
                                                                                                                                data18 ===
                                                                                                                                    'YES' ||
                                                                                                                                data18 ===
                                                                                                                                    'NO'
                                                                                                                            )
                                                                                                                        ) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath:
                                                                                                                                            instancePath +
                                                                                                                                            '/result/' +
                                                                                                                                            i0 +
                                                                                                                                            '/is_video_call',
                                                                                                                                        schemaPath:
                                                                                                                                            '#/properties/200/definitions/Participant/properties/is_video_call/enum',
                                                                                                                                        keyword:
                                                                                                                                            'enum',
                                                                                                                                        params: {
                                                                                                                                            allowedValues:
                                                                                                                                                schema13
                                                                                                                                                    .properties
                                                                                                                                                    .is_video_call
                                                                                                                                                    .enum,
                                                                                                                                        },
                                                                                                                                        message:
                                                                                                                                            'must be equal to one of the allowed values',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid3 =
                                                                                                                            _errs40 ===
                                                                                                                            errors;
                                                                                                                    } else {
                                                                                                                        var valid3 =
                                                                                                                            true;
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        valid3
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            data2.is_video_muted !==
                                                                                                                            undefined
                                                                                                                        ) {
                                                                                                                            const _errs42 =
                                                                                                                                errors;
                                                                                                                            if (
                                                                                                                                typeof data2.is_video_muted !==
                                                                                                                                'boolean'
                                                                                                                            ) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath:
                                                                                                                                                instancePath +
                                                                                                                                                '/result/' +
                                                                                                                                                i0 +
                                                                                                                                                '/is_video_muted',
                                                                                                                                            schemaPath:
                                                                                                                                                '#/properties/200/definitions/Participant/properties/is_video_muted/type',
                                                                                                                                            keyword:
                                                                                                                                                'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'boolean',
                                                                                                                                            },
                                                                                                                                            message:
                                                                                                                                                'must be boolean',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid3 =
                                                                                                                                _errs42 ===
                                                                                                                                errors;
                                                                                                                        } else {
                                                                                                                            var valid3 =
                                                                                                                                true;
                                                                                                                        }
                                                                                                                        if (
                                                                                                                            valid3
                                                                                                                        ) {
                                                                                                                            if (
                                                                                                                                data2.is_video_silent !==
                                                                                                                                undefined
                                                                                                                            ) {
                                                                                                                                const _errs44 =
                                                                                                                                    errors;
                                                                                                                                if (
                                                                                                                                    typeof data2.is_video_silent !==
                                                                                                                                    'boolean'
                                                                                                                                ) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath:
                                                                                                                                                    instancePath +
                                                                                                                                                    '/result/' +
                                                                                                                                                    i0 +
                                                                                                                                                    '/is_video_silent',
                                                                                                                                                schemaPath:
                                                                                                                                                    '#/properties/200/definitions/Participant/properties/is_video_silent/type',
                                                                                                                                                keyword:
                                                                                                                                                    'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message:
                                                                                                                                                    'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid3 =
                                                                                                                                    _errs44 ===
                                                                                                                                    errors;
                                                                                                                            } else {
                                                                                                                                var valid3 =
                                                                                                                                    true;
                                                                                                                            }
                                                                                                                            if (
                                                                                                                                valid3
                                                                                                                            ) {
                                                                                                                                if (
                                                                                                                                    data2.is_main_video_dropped_out !==
                                                                                                                                    undefined
                                                                                                                                ) {
                                                                                                                                    const _errs46 =
                                                                                                                                        errors;
                                                                                                                                    if (
                                                                                                                                        typeof data2.is_main_video_dropped_out !==
                                                                                                                                        'boolean'
                                                                                                                                    ) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath:
                                                                                                                                                        instancePath +
                                                                                                                                                        '/result/' +
                                                                                                                                                        i0 +
                                                                                                                                                        '/is_main_video_dropped_out',
                                                                                                                                                    schemaPath:
                                                                                                                                                        '#/properties/200/definitions/Participant/properties/is_main_video_dropped_out/type',
                                                                                                                                                    keyword:
                                                                                                                                                        'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message:
                                                                                                                                                        'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid3 =
                                                                                                                                        _errs46 ===
                                                                                                                                        errors;
                                                                                                                                } else {
                                                                                                                                    var valid3 =
                                                                                                                                        true;
                                                                                                                                }
                                                                                                                                if (
                                                                                                                                    valid3
                                                                                                                                ) {
                                                                                                                                    if (
                                                                                                                                        data2.local_alias !==
                                                                                                                                        undefined
                                                                                                                                    ) {
                                                                                                                                        const _errs48 =
                                                                                                                                            errors;
                                                                                                                                        if (
                                                                                                                                            typeof data2.local_alias !==
                                                                                                                                            'string'
                                                                                                                                        ) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath:
                                                                                                                                                            instancePath +
                                                                                                                                                            '/result/' +
                                                                                                                                                            i0 +
                                                                                                                                                            '/local_alias',
                                                                                                                                                        schemaPath:
                                                                                                                                                            '#/properties/200/definitions/Participant/properties/local_alias/type',
                                                                                                                                                        keyword:
                                                                                                                                                            'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'string',
                                                                                                                                                        },
                                                                                                                                                        message:
                                                                                                                                                            'must be string',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid3 =
                                                                                                                                            _errs48 ===
                                                                                                                                            errors;
                                                                                                                                    } else {
                                                                                                                                        var valid3 =
                                                                                                                                            true;
                                                                                                                                    }
                                                                                                                                    if (
                                                                                                                                        valid3
                                                                                                                                    ) {
                                                                                                                                        if (
                                                                                                                                            data2.mute_supported !==
                                                                                                                                            undefined
                                                                                                                                        ) {
                                                                                                                                            let data23 =
                                                                                                                                                data2.mute_supported;
                                                                                                                                            const _errs50 =
                                                                                                                                                errors;
                                                                                                                                            if (
                                                                                                                                                typeof data23 !==
                                                                                                                                                'string'
                                                                                                                                            ) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath:
                                                                                                                                                                instancePath +
                                                                                                                                                                '/result/' +
                                                                                                                                                                i0 +
                                                                                                                                                                '/mute_supported',
                                                                                                                                                            schemaPath:
                                                                                                                                                                '#/properties/200/definitions/Participant/properties/mute_supported/type',
                                                                                                                                                            keyword:
                                                                                                                                                                'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'string',
                                                                                                                                                            },
                                                                                                                                                            message:
                                                                                                                                                                'must be string',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            if (
                                                                                                                                                !(
                                                                                                                                                    data23 ===
                                                                                                                                                        'YES' ||
                                                                                                                                                    data23 ===
                                                                                                                                                        'NO'
                                                                                                                                                )
                                                                                                                                            ) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath:
                                                                                                                                                                instancePath +
                                                                                                                                                                '/result/' +
                                                                                                                                                                i0 +
                                                                                                                                                                '/mute_supported',
                                                                                                                                                            schemaPath:
                                                                                                                                                                '#/properties/200/definitions/Participant/properties/mute_supported/enum',
                                                                                                                                                            keyword:
                                                                                                                                                                'enum',
                                                                                                                                                            params: {
                                                                                                                                                                allowedValues:
                                                                                                                                                                    schema13
                                                                                                                                                                        .properties
                                                                                                                                                                        .mute_supported
                                                                                                                                                                        .enum,
                                                                                                                                                            },
                                                                                                                                                            message:
                                                                                                                                                                'must be equal to one of the allowed values',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid3 =
                                                                                                                                                _errs50 ===
                                                                                                                                                errors;
                                                                                                                                        } else {
                                                                                                                                            var valid3 =
                                                                                                                                                true;
                                                                                                                                        }
                                                                                                                                        if (
                                                                                                                                            valid3
                                                                                                                                        ) {
                                                                                                                                            if (
                                                                                                                                                data2.needs_presentation_in_mix !==
                                                                                                                                                undefined
                                                                                                                                            ) {
                                                                                                                                                const _errs52 =
                                                                                                                                                    errors;
                                                                                                                                                if (
                                                                                                                                                    typeof data2.needs_presentation_in_mix !==
                                                                                                                                                    'boolean'
                                                                                                                                                ) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath:
                                                                                                                                                                    instancePath +
                                                                                                                                                                    '/result/' +
                                                                                                                                                                    i0 +
                                                                                                                                                                    '/needs_presentation_in_mix',
                                                                                                                                                                schemaPath:
                                                                                                                                                                    '#/properties/200/definitions/Participant/properties/needs_presentation_in_mix/type',
                                                                                                                                                                keyword:
                                                                                                                                                                    'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message:
                                                                                                                                                                    'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid3 =
                                                                                                                                                    _errs52 ===
                                                                                                                                                    errors;
                                                                                                                                            } else {
                                                                                                                                                var valid3 =
                                                                                                                                                    true;
                                                                                                                                            }
                                                                                                                                            if (
                                                                                                                                                valid3
                                                                                                                                            ) {
                                                                                                                                                if (
                                                                                                                                                    data2.overlay_text !==
                                                                                                                                                    undefined
                                                                                                                                                ) {
                                                                                                                                                    const _errs54 =
                                                                                                                                                        errors;
                                                                                                                                                    if (
                                                                                                                                                        typeof data2.overlay_text !==
                                                                                                                                                        'string'
                                                                                                                                                    ) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath:
                                                                                                                                                                        instancePath +
                                                                                                                                                                        '/result/' +
                                                                                                                                                                        i0 +
                                                                                                                                                                        '/overlay_text',
                                                                                                                                                                    schemaPath:
                                                                                                                                                                        '#/properties/200/definitions/Participant/properties/overlay_text/type',
                                                                                                                                                                    keyword:
                                                                                                                                                                        'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'string',
                                                                                                                                                                    },
                                                                                                                                                                    message:
                                                                                                                                                                        'must be string',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid3 =
                                                                                                                                                        _errs54 ===
                                                                                                                                                        errors;
                                                                                                                                                } else {
                                                                                                                                                    var valid3 =
                                                                                                                                                        true;
                                                                                                                                                }
                                                                                                                                                if (
                                                                                                                                                    valid3
                                                                                                                                                ) {
                                                                                                                                                    if (
                                                                                                                                                        data2.presentation_supported !==
                                                                                                                                                        undefined
                                                                                                                                                    ) {
                                                                                                                                                        let data26 =
                                                                                                                                                            data2.presentation_supported;
                                                                                                                                                        const _errs56 =
                                                                                                                                                            errors;
                                                                                                                                                        if (
                                                                                                                                                            typeof data26 !==
                                                                                                                                                            'string'
                                                                                                                                                        ) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath:
                                                                                                                                                                            instancePath +
                                                                                                                                                                            '/result/' +
                                                                                                                                                                            i0 +
                                                                                                                                                                            '/presentation_supported',
                                                                                                                                                                        schemaPath:
                                                                                                                                                                            '#/properties/200/definitions/Participant/properties/presentation_supported/type',
                                                                                                                                                                        keyword:
                                                                                                                                                                            'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'string',
                                                                                                                                                                        },
                                                                                                                                                                        message:
                                                                                                                                                                            'must be string',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        if (
                                                                                                                                                            !(
                                                                                                                                                                data26 ===
                                                                                                                                                                    'YES' ||
                                                                                                                                                                data26 ===
                                                                                                                                                                    'NO'
                                                                                                                                                            )
                                                                                                                                                        ) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath:
                                                                                                                                                                            instancePath +
                                                                                                                                                                            '/result/' +
                                                                                                                                                                            i0 +
                                                                                                                                                                            '/presentation_supported',
                                                                                                                                                                        schemaPath:
                                                                                                                                                                            '#/properties/200/definitions/Participant/properties/presentation_supported/enum',
                                                                                                                                                                        keyword:
                                                                                                                                                                            'enum',
                                                                                                                                                                        params: {
                                                                                                                                                                            allowedValues:
                                                                                                                                                                                schema13
                                                                                                                                                                                    .properties
                                                                                                                                                                                    .presentation_supported
                                                                                                                                                                                    .enum,
                                                                                                                                                                        },
                                                                                                                                                                        message:
                                                                                                                                                                            'must be equal to one of the allowed values',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid3 =
                                                                                                                                                            _errs56 ===
                                                                                                                                                            errors;
                                                                                                                                                    } else {
                                                                                                                                                        var valid3 =
                                                                                                                                                            true;
                                                                                                                                                    }
                                                                                                                                                    if (
                                                                                                                                                        valid3
                                                                                                                                                    ) {
                                                                                                                                                        if (
                                                                                                                                                            data2.protocol !==
                                                                                                                                                            undefined
                                                                                                                                                        ) {
                                                                                                                                                            let data27 =
                                                                                                                                                                data2.protocol;
                                                                                                                                                            const _errs58 =
                                                                                                                                                                errors;
                                                                                                                                                            if (
                                                                                                                                                                typeof data27 !==
                                                                                                                                                                'string'
                                                                                                                                                            ) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath:
                                                                                                                                                                                instancePath +
                                                                                                                                                                                '/result/' +
                                                                                                                                                                                i0 +
                                                                                                                                                                                '/protocol',
                                                                                                                                                                            schemaPath:
                                                                                                                                                                                '#/properties/200/definitions/Participant/properties/protocol/type',
                                                                                                                                                                            keyword:
                                                                                                                                                                                'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'string',
                                                                                                                                                                            },
                                                                                                                                                                            message:
                                                                                                                                                                                'must be string',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            if (
                                                                                                                                                                !(
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'api' ||
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'webrtc' ||
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'sip' ||
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'rtmp' ||
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'h323' ||
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'mssip'
                                                                                                                                                                )
                                                                                                                                                            ) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath:
                                                                                                                                                                                instancePath +
                                                                                                                                                                                '/result/' +
                                                                                                                                                                                i0 +
                                                                                                                                                                                '/protocol',
                                                                                                                                                                            schemaPath:
                                                                                                                                                                                '#/properties/200/definitions/Participant/properties/protocol/enum',
                                                                                                                                                                            keyword:
                                                                                                                                                                                'enum',
                                                                                                                                                                            params: {
                                                                                                                                                                                allowedValues:
                                                                                                                                                                                    schema13
                                                                                                                                                                                        .properties
                                                                                                                                                                                        .protocol
                                                                                                                                                                                        .enum,
                                                                                                                                                                            },
                                                                                                                                                                            message:
                                                                                                                                                                                'must be equal to one of the allowed values',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid3 =
                                                                                                                                                                _errs58 ===
                                                                                                                                                                errors;
                                                                                                                                                        } else {
                                                                                                                                                            var valid3 =
                                                                                                                                                                true;
                                                                                                                                                        }
                                                                                                                                                        if (
                                                                                                                                                            valid3
                                                                                                                                                        ) {
                                                                                                                                                            if (
                                                                                                                                                                data2.role !==
                                                                                                                                                                undefined
                                                                                                                                                            ) {
                                                                                                                                                                let data28 =
                                                                                                                                                                    data2.role;
                                                                                                                                                                const _errs60 =
                                                                                                                                                                    errors;
                                                                                                                                                                if (
                                                                                                                                                                    typeof data28 !==
                                                                                                                                                                    'string'
                                                                                                                                                                ) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath:
                                                                                                                                                                                    instancePath +
                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                    i0 +
                                                                                                                                                                                    '/role',
                                                                                                                                                                                schemaPath:
                                                                                                                                                                                    '#/properties/200/definitions/Participant/properties/role/type',
                                                                                                                                                                                keyword:
                                                                                                                                                                                    'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                },
                                                                                                                                                                                message:
                                                                                                                                                                                    'must be string',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                if (
                                                                                                                                                                    !(
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'chair' ||
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'guest'
                                                                                                                                                                    )
                                                                                                                                                                ) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath:
                                                                                                                                                                                    instancePath +
                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                    i0 +
                                                                                                                                                                                    '/role',
                                                                                                                                                                                schemaPath:
                                                                                                                                                                                    '#/properties/200/definitions/Participant/properties/role/enum',
                                                                                                                                                                                keyword:
                                                                                                                                                                                    'enum',
                                                                                                                                                                                params: {
                                                                                                                                                                                    allowedValues:
                                                                                                                                                                                        schema13
                                                                                                                                                                                            .properties
                                                                                                                                                                                            .role
                                                                                                                                                                                            .enum,
                                                                                                                                                                                },
                                                                                                                                                                                message:
                                                                                                                                                                                    'must be equal to one of the allowed values',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid3 =
                                                                                                                                                                    _errs60 ===
                                                                                                                                                                    errors;
                                                                                                                                                            } else {
                                                                                                                                                                var valid3 =
                                                                                                                                                                    true;
                                                                                                                                                            }
                                                                                                                                                            if (
                                                                                                                                                                valid3
                                                                                                                                                            ) {
                                                                                                                                                                if (
                                                                                                                                                                    data2.rx_presentation_policy !==
                                                                                                                                                                    undefined
                                                                                                                                                                ) {
                                                                                                                                                                    let data29 =
                                                                                                                                                                        data2.rx_presentation_policy;
                                                                                                                                                                    const _errs62 =
                                                                                                                                                                        errors;
                                                                                                                                                                    if (
                                                                                                                                                                        typeof data29 !==
                                                                                                                                                                        'string'
                                                                                                                                                                    ) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath:
                                                                                                                                                                                        instancePath +
                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                        i0 +
                                                                                                                                                                                        '/rx_presentation_policy',
                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                        '#/properties/200/definitions/Participant/properties/rx_presentation_policy/type',
                                                                                                                                                                                    keyword:
                                                                                                                                                                                        'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                    },
                                                                                                                                                                                    message:
                                                                                                                                                                                        'must be string',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    if (
                                                                                                                                                                        !(
                                                                                                                                                                            data29 ===
                                                                                                                                                                                'ALLOW' ||
                                                                                                                                                                            data29 ===
                                                                                                                                                                                'DENY'
                                                                                                                                                                        )
                                                                                                                                                                    ) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath:
                                                                                                                                                                                        instancePath +
                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                        i0 +
                                                                                                                                                                                        '/rx_presentation_policy',
                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                        '#/properties/200/definitions/Participant/properties/rx_presentation_policy/enum',
                                                                                                                                                                                    keyword:
                                                                                                                                                                                        'enum',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        allowedValues:
                                                                                                                                                                                            schema13
                                                                                                                                                                                                .properties
                                                                                                                                                                                                .rx_presentation_policy
                                                                                                                                                                                                .enum,
                                                                                                                                                                                    },
                                                                                                                                                                                    message:
                                                                                                                                                                                        'must be equal to one of the allowed values',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid3 =
                                                                                                                                                                        _errs62 ===
                                                                                                                                                                        errors;
                                                                                                                                                                } else {
                                                                                                                                                                    var valid3 =
                                                                                                                                                                        true;
                                                                                                                                                                }
                                                                                                                                                                if (
                                                                                                                                                                    valid3
                                                                                                                                                                ) {
                                                                                                                                                                    if (
                                                                                                                                                                        data2.service_type !==
                                                                                                                                                                        undefined
                                                                                                                                                                    ) {
                                                                                                                                                                        let data30 =
                                                                                                                                                                            data2.service_type;
                                                                                                                                                                        const _errs64 =
                                                                                                                                                                            errors;
                                                                                                                                                                        if (
                                                                                                                                                                            typeof data30 !==
                                                                                                                                                                            'string'
                                                                                                                                                                        ) {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath:
                                                                                                                                                                                            instancePath +
                                                                                                                                                                                            '/result/' +
                                                                                                                                                                                            i0 +
                                                                                                                                                                                            '/service_type',
                                                                                                                                                                                        schemaPath:
                                                                                                                                                                                            '#/properties/200/definitions/Participant/properties/service_type/type',
                                                                                                                                                                                        keyword:
                                                                                                                                                                                            'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message:
                                                                                                                                                                                            'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        if (
                                                                                                                                                                            !(
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'connecting' ||
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'waiting_room' ||
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'ivr' ||
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'conference' ||
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'lecture' ||
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'gateway' ||
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'test_call'
                                                                                                                                                                            )
                                                                                                                                                                        ) {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath:
                                                                                                                                                                                            instancePath +
                                                                                                                                                                                            '/result/' +
                                                                                                                                                                                            i0 +
                                                                                                                                                                                            '/service_type',
                                                                                                                                                                                        schemaPath:
                                                                                                                                                                                            '#/properties/200/definitions/Participant/properties/service_type/enum',
                                                                                                                                                                                        keyword:
                                                                                                                                                                                            'enum',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            allowedValues:
                                                                                                                                                                                                schema13
                                                                                                                                                                                                    .properties
                                                                                                                                                                                                    .service_type
                                                                                                                                                                                                    .enum,
                                                                                                                                                                                        },
                                                                                                                                                                                        message:
                                                                                                                                                                                            'must be equal to one of the allowed values',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid3 =
                                                                                                                                                                            _errs64 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    } else {
                                                                                                                                                                        var valid3 =
                                                                                                                                                                            true;
                                                                                                                                                                    }
                                                                                                                                                                    if (
                                                                                                                                                                        valid3
                                                                                                                                                                    ) {
                                                                                                                                                                        if (
                                                                                                                                                                            data2.spotlight !==
                                                                                                                                                                            undefined
                                                                                                                                                                        ) {
                                                                                                                                                                            const _errs66 =
                                                                                                                                                                                errors;
                                                                                                                                                                            if (
                                                                                                                                                                                !(
                                                                                                                                                                                    typeof data2.spotlight ==
                                                                                                                                                                                    'number'
                                                                                                                                                                                )
                                                                                                                                                                            ) {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath:
                                                                                                                                                                                                instancePath +
                                                                                                                                                                                                '/result/' +
                                                                                                                                                                                                i0 +
                                                                                                                                                                                                '/spotlight',
                                                                                                                                                                                            schemaPath:
                                                                                                                                                                                                '#/properties/200/definitions/Participant/properties/spotlight/type',
                                                                                                                                                                                            keyword:
                                                                                                                                                                                                'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'number',
                                                                                                                                                                                            },
                                                                                                                                                                                            message:
                                                                                                                                                                                                'must be number',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid3 =
                                                                                                                                                                                _errs66 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        } else {
                                                                                                                                                                            var valid3 =
                                                                                                                                                                                true;
                                                                                                                                                                        }
                                                                                                                                                                        if (
                                                                                                                                                                            valid3
                                                                                                                                                                        ) {
                                                                                                                                                                            if (
                                                                                                                                                                                data2.start_time !==
                                                                                                                                                                                undefined
                                                                                                                                                                            ) {
                                                                                                                                                                                const _errs68 =
                                                                                                                                                                                    errors;
                                                                                                                                                                                if (
                                                                                                                                                                                    !(
                                                                                                                                                                                        typeof data2.start_time ==
                                                                                                                                                                                        'number'
                                                                                                                                                                                    )
                                                                                                                                                                                ) {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath:
                                                                                                                                                                                                    instancePath +
                                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                                    i0 +
                                                                                                                                                                                                    '/start_time',
                                                                                                                                                                                                schemaPath:
                                                                                                                                                                                                    '#/properties/200/definitions/Participant/properties/start_time/type',
                                                                                                                                                                                                keyword:
                                                                                                                                                                                                    'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                                },
                                                                                                                                                                                                message:
                                                                                                                                                                                                    'must be number',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid3 =
                                                                                                                                                                                    _errs68 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            } else {
                                                                                                                                                                                var valid3 =
                                                                                                                                                                                    true;
                                                                                                                                                                            }
                                                                                                                                                                            if (
                                                                                                                                                                                valid3
                                                                                                                                                                            ) {
                                                                                                                                                                                if (
                                                                                                                                                                                    data2.transfer_supported !==
                                                                                                                                                                                    undefined
                                                                                                                                                                                ) {
                                                                                                                                                                                    let data33 =
                                                                                                                                                                                        data2.transfer_supported;
                                                                                                                                                                                    const _errs70 =
                                                                                                                                                                                        errors;
                                                                                                                                                                                    if (
                                                                                                                                                                                        typeof data33 !==
                                                                                                                                                                                        'string'
                                                                                                                                                                                    ) {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath:
                                                                                                                                                                                                        instancePath +
                                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                                        i0 +
                                                                                                                                                                                                        '/transfer_supported',
                                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                                        '#/properties/200/definitions/Participant/properties/transfer_supported/type',
                                                                                                                                                                                                    keyword:
                                                                                                                                                                                                        'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message:
                                                                                                                                                                                                        'must be string',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    if (
                                                                                                                                                                                        !(
                                                                                                                                                                                            data33 ===
                                                                                                                                                                                                'YES' ||
                                                                                                                                                                                            data33 ===
                                                                                                                                                                                                'NO'
                                                                                                                                                                                        )
                                                                                                                                                                                    ) {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath:
                                                                                                                                                                                                        instancePath +
                                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                                        i0 +
                                                                                                                                                                                                        '/transfer_supported',
                                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                                        '#/properties/200/definitions/Participant/properties/transfer_supported/enum',
                                                                                                                                                                                                    keyword:
                                                                                                                                                                                                        'enum',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        allowedValues:
                                                                                                                                                                                                            schema13
                                                                                                                                                                                                                .properties
                                                                                                                                                                                                                .transfer_supported
                                                                                                                                                                                                                .enum,
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message:
                                                                                                                                                                                                        'must be equal to one of the allowed values',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid3 =
                                                                                                                                                                                        _errs70 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                } else {
                                                                                                                                                                                    var valid3 =
                                                                                                                                                                                        true;
                                                                                                                                                                                }
                                                                                                                                                                                if (
                                                                                                                                                                                    valid3
                                                                                                                                                                                ) {
                                                                                                                                                                                    if (
                                                                                                                                                                                        data2.uuid !==
                                                                                                                                                                                        undefined
                                                                                                                                                                                    ) {
                                                                                                                                                                                        const _errs72 =
                                                                                                                                                                                            errors;
                                                                                                                                                                                        if (
                                                                                                                                                                                            typeof data2.uuid !==
                                                                                                                                                                                            'string'
                                                                                                                                                                                        ) {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath:
                                                                                                                                                                                                            instancePath +
                                                                                                                                                                                                            '/result/' +
                                                                                                                                                                                                            i0 +
                                                                                                                                                                                                            '/uuid',
                                                                                                                                                                                                        schemaPath:
                                                                                                                                                                                                            '#/properties/200/definitions/Participant/properties/uuid/type',
                                                                                                                                                                                                        keyword:
                                                                                                                                                                                                            'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message:
                                                                                                                                                                                                            'must be string',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        var valid3 =
                                                                                                                                                                                            _errs72 ===
                                                                                                                                                                                            errors;
                                                                                                                                                                                    } else {
                                                                                                                                                                                        var valid3 =
                                                                                                                                                                                            true;
                                                                                                                                                                                    }
                                                                                                                                                                                    if (
                                                                                                                                                                                        valid3
                                                                                                                                                                                    ) {
                                                                                                                                                                                        if (
                                                                                                                                                                                            data2.uri !==
                                                                                                                                                                                            undefined
                                                                                                                                                                                        ) {
                                                                                                                                                                                            const _errs74 =
                                                                                                                                                                                                errors;
                                                                                                                                                                                            if (
                                                                                                                                                                                                typeof data2.uri !==
                                                                                                                                                                                                'string'
                                                                                                                                                                                            ) {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath:
                                                                                                                                                                                                                instancePath +
                                                                                                                                                                                                                '/result/' +
                                                                                                                                                                                                                i0 +
                                                                                                                                                                                                                '/uri',
                                                                                                                                                                                                            schemaPath:
                                                                                                                                                                                                                '#/properties/200/definitions/Participant/properties/uri/type',
                                                                                                                                                                                                            keyword:
                                                                                                                                                                                                                'type',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message:
                                                                                                                                                                                                                'must be string',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            var valid3 =
                                                                                                                                                                                                _errs74 ===
                                                                                                                                                                                                errors;
                                                                                                                                                                                        } else {
                                                                                                                                                                                            var valid3 =
                                                                                                                                                                                                true;
                                                                                                                                                                                        }
                                                                                                                                                                                        if (
                                                                                                                                                                                            valid3
                                                                                                                                                                                        ) {
                                                                                                                                                                                            if (
                                                                                                                                                                                                data2.vendor !==
                                                                                                                                                                                                undefined
                                                                                                                                                                                            ) {
                                                                                                                                                                                                const _errs76 =
                                                                                                                                                                                                    errors;
                                                                                                                                                                                                if (
                                                                                                                                                                                                    typeof data2.vendor !==
                                                                                                                                                                                                    'string'
                                                                                                                                                                                                ) {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath:
                                                                                                                                                                                                                    instancePath +
                                                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                                                    i0 +
                                                                                                                                                                                                                    '/vendor',
                                                                                                                                                                                                                schemaPath:
                                                                                                                                                                                                                    '#/properties/200/definitions/Participant/properties/vendor/type',
                                                                                                                                                                                                                keyword:
                                                                                                                                                                                                                    'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message:
                                                                                                                                                                                                                    'must be string',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid3 =
                                                                                                                                                                                                    _errs76 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            } else {
                                                                                                                                                                                                var valid3 =
                                                                                                                                                                                                    true;
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                validate11.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/result/' +
                                                            i0,
                                                        schemaPath:
                                                            '#/properties/200/definitions/Participant/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'object',
                                                        },
                                                        message:
                                                            'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                } else {
                                    validate11.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/properties/result/type',
                                            keyword: 'type',
                                            params: {type: 'array'},
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
