import type {DragEvent} from 'react';
import React from 'react';
import cx from 'classnames';

import {Box} from '../Box/Box';

import styles from './DragAndDrop.module.scss';

export const DragAndDrop: React.FC<
    React.PropsWithChildren<{
        className?: string;
        onDrop?: (
            e: DragEvent<HTMLDivElement>,
            files?: File[],
            items?: DataTransferItem[],
        ) => void;
        onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
    }>
> = ({className, children, onDrop, onDragOver, ...props}) => {
    const onDropCallback = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        onDrop?.(
            e,
            e.dataTransfer.files && Array.from(e.dataTransfer.files),
            e.dataTransfer.items && Array.from(e.dataTransfer.items),
        );
    };

    const onDragOverCallback = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        onDragOver?.(e);
    };

    return (
        <Box
            className={cx(styles.dragAndDropWrapper, className)}
            onDrop={onDropCallback}
            onDragOver={onDragOverCallback}
            {...props}
        >
            {children}
        </Box>
    );
};

export type DragAndDropProps = React.ComponentProps<typeof DragAndDrop>;
