import React from 'react';

import {Bar} from '@pexip/components';
import {MeetingScrim} from '@pexip/media-components';

export const MeetingHeaderView: React.FC<{
    header: React.ReactElement;
}> = ({header}) => (
    <MeetingScrim>
        <Bar position="absoluteTop">{header}</Bar>
    </MeetingScrim>
);
