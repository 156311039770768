/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface CallsWebrtcParticipantMap {
    '200': Status200;
    '400': string;
    '403': Status403;
    '500': string;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    result: Result;
}
export interface Result {
    /**
     * `call_uuid` is used to control the call
     */
    call_uuid: string;
    /**
     * SDP answer from the mcu
     */
    sdp: string;
    turn?: Turn[];
}
export interface Turn {
    urls: string[];
    username: string;
    credential: string;
}
export interface Status403 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result: 'Invalid token';
}
export interface TopLevel {
    /**
     * `WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.
     */
    call_type: 'WEBRTC' | 'RTMP';
    /**
     * Contains the SDP of the sender.
     */
    sdp: string;
    /**
     * Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.
     */
    present?: 'send' | 'receive';
    /**
     * Set to true if this participant can be sent FECC messages; false if not.
     */
    fecc_supported?: boolean;
}

import {
    validateBody,
    validate200,
    validate400,
    validate403,
    validate500,
} from './callsWebrtcParticipantValidation';
type CallsWebrtcParticipantResponse =
    | {status: 200; data: CallsWebrtcParticipantMap['200']}
    | {status: 400; data: CallsWebrtcParticipantMap['400']}
    | {status: 403; data: CallsWebrtcParticipantMap['403']}
    | {status: 500; data: CallsWebrtcParticipantMap['500']};
interface CallsWebrtcParticipantParams {
    conferenceAlias: string;
    participantUuid: string;
}
export async function callsWebrtcParticipant({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: CallsWebrtcParticipantParams;
    body: CallsWebrtcParticipantMap['Body'];
    host: string;
}): Promise<CallsWebrtcParticipantResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/participants/${params.participantUuid}/calls`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 400) {
        const data = await res.text();
        if (!validate400(data)) {
            throw new Error(JSON.stringify(validate400.errors));
        }

        return {status: res.status, data};
    }
    if (res.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate403(data)) {
            throw new Error(JSON.stringify(validate403.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 500) {
        const data = await res.text();
        if (!validate500(data)) {
            throw new Error(JSON.stringify(validate500.errors));
        }

        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
