import type {DragEvent} from 'react';
import React, {forwardRef} from 'react';

import {FontVariant} from '../../../../design-tokens/constants';
import {DragAndDrop} from '../../elements/DragAndDrop/DragAndDrop.view';
import {FileUpload} from '../../elements/FileUpload/FileUpload.view';
import {Icon, IconTypes} from '../../elements/Icon/Icon';
import {Text} from '../../elements/Text/Text';
import {Row} from '../List/Row';

import styles from './DragAndUploadFiles.module.scss';

const fallbackSubTitle = 'or drag and drop';

export const DragAndUploadFiles = forwardRef<
    HTMLInputElement,
    {
        accept?: string;
        ariaLabel?: string;
        onChange: () => void;
        onDrop?: (
            e: DragEvent<HTMLDivElement>,
            files?: File[],
            items?: DataTransferItem[],
        ) => void;
        multiple?: boolean;
        title?: string;
        subTitle?: string;
    }
>(
    (
        {
            accept,
            ariaLabel,
            onChange,
            onDrop,
            multiple = false,
            title,
            subTitle = fallbackSubTitle,
            ...props
        },
        ref,
    ) => (
        <DragAndDrop onDrop={onDrop} {...props}>
            <FileUpload
                accept={accept}
                className={styles.fileUpload}
                onChange={onChange}
                multiple={multiple}
                ref={ref}
                ariaLabel={ariaLabel}
            >
                <div className={styles.content}>
                    <div className={styles.button}>
                        <Icon source={IconTypes.IconAdd} />
                    </div>
                    {title && (
                        <Row className="pb-0">
                            <Text fontVariant={FontVariant.H5}>{title}</Text>
                        </Row>
                    )}
                    {subTitle && (
                        <Row className="pt-0">
                            <Text fontVariant={FontVariant.Body}>
                                {subTitle}
                            </Text>
                        </Row>
                    )}
                </div>
            </FileUpload>
        </DragAndDrop>
    ),
);

DragAndUploadFiles.displayName = 'DragAndUploadFiles';

export type DragAndUploadFilesProps = React.ComponentProps<
    typeof DragAndUploadFiles
>;
