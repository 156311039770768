import {stopStreamTracks} from '../utils';
import type {Transform} from '../types';

import type {InputFrame} from './types';
import {AbortReason} from './constants';
import type {ProcessVideoTrack} from './videoStreamTrackProcessor';

export interface VideoProcessor {
    open(): Promise<void>;
    /**
     * Process the video and return a MediaStream
     */
    process: (source: MediaStream) => Promise<MediaStream>;
    /**
     * Stop the process of video segmentation
     */
    close(): void;
    /**
     * Destroy the segmentation wasm instance
     */
    destroy: () => Promise<void>;
}

interface Props {
    processing: boolean;
    outputStream?: MediaStream;
    abortController?: AbortController;
}

export const createVideoProcessor = (
    transformers: Transform<InputFrame, InputFrame>[],
    processTrack: ProcessVideoTrack,
): VideoProcessor => {
    const props: Props = {
        processing: false,
    };

    const open: VideoProcessor['open'] = async () => {
        await Promise.all(transformers.map(transformer => transformer.init()));
    };

    const process: VideoProcessor['process'] = async source => {
        const [track] = source.getVideoTracks();
        if (!track) {
            return source;
        }
        if (props.processing) {
            throw new Error('Cannot process when it is already processing');
        }
        props.abortController = new AbortController();
        const trackGenerated = await processTrack(track, transformers, {
            signal: props.abortController.signal,
        });

        props.outputStream = new MediaStream([
            trackGenerated,
            ...source.getAudioTracks().map(track => track.clone()),
        ]);
        props.processing = true;
        return props.outputStream;
    };

    const close: VideoProcessor['close'] = () => {
        if (!props.processing) {
            return;
        }
        props.abortController?.abort(AbortReason.Close);
        stopStreamTracks(props.outputStream);
        props.outputStream = undefined;
        props.processing = false;
    };

    const destroy: VideoProcessor['destroy'] = async () => {
        close();
        await Promise.all(
            transformers.map(transformer => transformer.destroy()),
        );
    };

    return {
        open,
        process,
        close,
        destroy,
    };
};
