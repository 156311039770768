import type {Signal, SignalVariant} from '@pexip/signal';
import {createSignal} from '@pexip/signal';

import type {
    IceCandidate,
    CallSignals,
    InfinityCallSignalsOptional as CallSignalsOptional,
    InfinitySignals,
    InfinitySignalsOptional,
    PresentationEvent,
    InfinityEventSignalsOptional,
    EventSignals,
    RTCParticipantEvent,
    SignalName,
} from './types';
import {CallType} from './types';

/**
 * Create a general signal with consistent scoped name
 *
 * @param name - Signal name
 * @param scope - The scope of the signal for better logging
 * @param variant - The variant of the signal @see Signal @defaultValue 'generic'
 */
const createInfinitySignal = <T = undefined>(
    name: SignalName,
    scope = '',
    variant: SignalVariant = 'generic',
) =>
    createSignal<T>({
        name: `infinity/${scope}:${name}`,
        allowEmittingWithoutObserver: allowEmittingWithoutObserver(name),
        variant,
    });

const allowEmittingWithoutObserver = (signal: SignalName) =>
    [
        'onRtcStats',
        'onCallQualityStats',
        'onCallQuality',
        'onRequestedLayout',
        'onStage',
        'onPresentationFrame',
    ].includes(signal);

const REQUIRED_INFINITY_SIGNAL_KEYS = [
    'onConnected',
    'onDisconnected',
    'onAnswer',
    'onPresentationAnswer',
    'onFailedRequest',
    'onRetryQueueFlushed',
    'onPinRequired',
    'onError',
    'onIdp',
    'onRedirect',
    'onParticipants',
    'onParticipantJoined',
    'onParticipantLeft',
    'onMessage',
    'onMe',
    'onRequestedLayout',
    'onStage',
    'onConferenceStatus',
    'onTransfer',
    'onRaiseHand',
    'onLiveCaptions',
    'onSplashScreen',
    'onNewOffer',
    'onIceCandidate',
    'onUpdateSdp',
    'onPeerDisconnect',
    'onExtension',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for infinity client to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `InfinitySignalsOptional`, @see InfinitySignalsOptional
 *
 * The following signals created by default
 * - 'onConnected',
 * - 'onAnswer',
 *
 * @see REQUIRED_INFINITY_SIGNAL_KEYS
 */
export const createInfinityClientSignals = <
    K extends keyof InfinitySignalsOptional,
>(
    more: K[],
    scope = '',
) => {
    const signalScope = scope && [scope, ':'].join('');
    type SignalKeys =
        | typeof more[number]
        | typeof REQUIRED_INFINITY_SIGNAL_KEYS[number];
    return [...REQUIRED_INFINITY_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createInfinitySignal<
                InfinitySignals[typeof key] extends Signal<infer S> ? S : never
            >(key, signalScope),
        }),
        {} as Pick<Required<InfinitySignals>, SignalKeys>,
    );
};

const REQUIRED_CALL_SIGNAL_KEYS = [
    'onCallConnected',
    'onRemoteStream',
    'onRemotePresentationStream',
    'onPresentationConnectionChange',
    'onRtcStats',
    'onCallQualityStats',
    'onCallQuality',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for call to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `CallSignalsOptional`, @see CallSignalsOptional
 *
 * The following signals created by default
 * - 'onRemoteStream',
 *
 * @see REQUIRED_CALL_SIGNAL_KEYS
 */
export const createCallSignals = <K extends keyof CallSignalsOptional>(
    more: K[],
    scope = '',
) => {
    const callScope = 'call';
    const signalScope = scope && [scope, ':', callScope, ':'].join('');
    type SignalKeys =
        | typeof more[number]
        | typeof REQUIRED_CALL_SIGNAL_KEYS[number];
    return [...REQUIRED_CALL_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createInfinitySignal<
                CallSignals[typeof key] extends Signal<infer S> ? S : never
            >(key, signalScope),
        }),
        {} as Pick<Required<CallSignals>, SignalKeys>,
    );
};

const REQUIRED_EVENT_SIGNAL_KEYS = [
    'onCallDisconnected',
    'onPresentationStart',
    'onPresentationStop',
    'onPresentationEnded',
    'onPresentationFrame',
    'onParticipantCreate',
    'onParticipantUpdate',
    'onParticipantDelete',
    'onMessage',
    'onConferenceUpdate',
    'onStageUpdate',
    'onLayoutUpdate',
    'onDisconnect',
    'onParticipantSyncBegin',
    'onParticipantSyncEnd',
    'onRefer',
    'onHold',
    'onFecc',
    'onRefreshToken',
    'onLiveCaptions',
    'onPeerDisconnect',
    'onNewOffer',
    'onUpdateSdp',
    'onNewCandidate',
    'onSplashScreen',
] as const;
/**
 * Create and return all required and optional (if specified with `more`),
 * signals for call to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `InfinityEventSignalsOptional`, @see InfinityEventSignalsOptional
 *
 * The following signals created by default
 * - 'onRemoteStream',
 *
 * @see REQUIRED_EVENT_SIGNAL_KEYS
 */
export const createEventSignals = <
    K extends keyof InfinityEventSignalsOptional,
>(
    more: K[],
    scope = '',
) => {
    const callScope = 'events';
    const signalScope = scope && [scope, ':', callScope, ':'].join('');
    type SignalKeys =
        | typeof more[number]
        | typeof REQUIRED_EVENT_SIGNAL_KEYS[number];
    return [...REQUIRED_EVENT_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createInfinitySignal<
                EventSignals[typeof key] extends Signal<infer S> ? S : never
            >(key, signalScope),
        }),
        {} as Pick<Required<EventSignals>, SignalKeys>,
    );
};

/**
 * Convert `RTCIceCandidate` into Infinity ICE candidate data structure.
 */
export const toIceCandidate = (
    iceCandidate: RTCIceCandidate | null,
): IceCandidate => ({
    candidate: iceCandidate?.candidate ?? '',
    mid: iceCandidate?.sdpMid ?? '0',
    ...(iceCandidate?.usernameFragment && {
        ufrag: iceCandidate.usernameFragment,
    }),
});

export const getPresenter = (presentationEvent: PresentationEvent) => {
    const {presenter_name, presenter_uri} = presentationEvent;
    if (presenter_name && presenter_uri) {
        return `${presenter_name} <${presenter_uri}>`;
    }

    return presentationEvent.presenter_uri || '';
};

const canControl = (participant: RTCParticipantEvent) => {
    if (!participant.service_type) {
        return false;
    }

    return (
        ['conference', 'lecture'].includes(participant.service_type) &&
        participant.role === 'chair'
    );
};

export const normalizeParticipant = (participant: RTCParticipantEvent) =>
    ({
        callType:
            participant.is_audio_only_call === 'YES'
                ? CallType.audio
                : participant.is_video_call === 'YES'
                ? CallType.video
                : CallType.api,
        canControl: canControl(participant),
        canDisconnect: participant.disconnect_supported === 'YES',
        canFecc: participant.fecc_supported === 'YES',
        canMute: participant.mute_supported === 'YES',
        canRaiseHand: participant.service_type !== 'gateway',
        canSpotlight: participant.service_type !== 'gateway',
        canTransfer: participant.transfer_supported === 'YES',
        displayName:
            participant.display_name ||
            participant?.uri?.replace('sip:', '') ||
            'User',
        handRaisedTime: participant.buzz_time,
        identity: participant.uuid, //FIXME: Change to be userId
        isCameraMuted: participant.is_video_muted,
        isEndpoint: participant?.uri?.includes('sip'),
        isExternal: participant.is_external,
        isGateway: participant.service_type === 'gateway',
        isHost: participant.role === 'chair',
        isMainVideoDroppedOut: Boolean(participant.is_main_video_dropped_out),
        isMuted: participant.is_muted === 'YES',
        isPresenting: participant.is_presenting === 'YES',
        isSpotlight: participant.spotlight > 0,
        isStreaming: participant.is_streaming_conference,
        isVideoSilent: participant.is_video_silent,
        isWaiting: participant.service_type === 'waiting_room',
        needsPresentationInMix: Boolean(participant.needs_presentation_in_mix),
        protocol: participant.protocol,
        raisedHand: participant.buzz_time > 0,
        role: participant.role,
        rxPresentation: participant.rx_presentation_policy === 'ALLOW',
        serviceType: participant.service_type,
        spotlightOrder: participant.spotlight,
        startAt: new Date(participant.start_time * 1000),
        startTime: participant.start_time,
        uri: participant.uri ?? '',
        uuid: participant.uuid,
    } as const);

export const getDirection = (tracks: MediaStreamTrack[] = []) =>
    tracks.length > 0 ? 'sendrecv' : 'recvonly';
