export const validate200 = validate13;
const schema15 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/properties/200/definitions/200'}],
};
const schema12 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath: '#/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema12.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath: '#/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
export const validateBody = validate14;
const schema17 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                config: {$ref: '#/properties/Body/definitions/Config'},
            },
            required: ['config'],
            title: 'TopLevel',
        },
        Config: {
            type: 'object',
            additionalProperties: false,
            properties: {
                enable: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Determines whether silent video detection (no faces or movement) is disabled (false) or enabled (true).',
                },
                silent_after: {
                    type: 'number',
                    default: 15,
                    description:
                        "The minimum number of seconds the participant's video has to silent before it is considered for removal from the video mix (it may take longer than this before the video is actually removed). Values from 1-120.",
                },
                require_no_faces: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Determines whether or not detected faces in the video are taken into consideration:\ntrue: a participant cannot be marked as silent if a face is detected.\nfalse: face-detection is ignored.',
                },
                reactivate_after: {
                    type: 'number',
                    default: 2,
                    description:
                        'The minimum number of seconds of moving video that is required before marking the participant as no longer silent. Values 1-5.',
                },
            },
            required: [],
            title: 'Config',
        },
    },
    oneOf: [{$ref: '#/properties/Body/definitions/TopLevel'}],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {config: {$ref: '#/properties/Body/definitions/Config'}},
    required: ['config'],
    title: 'TopLevel',
};
const schema14 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        enable: {
            type: 'boolean',
            default: true,
            description:
                'Determines whether silent video detection (no faces or movement) is disabled (false) or enabled (true).',
        },
        silent_after: {
            type: 'number',
            default: 15,
            description:
                "The minimum number of seconds the participant's video has to silent before it is considered for removal from the video mix (it may take longer than this before the video is actually removed). Values from 1-120.",
        },
        require_no_faces: {
            type: 'boolean',
            default: true,
            description:
                'Determines whether or not detected faces in the video are taken into consideration:\ntrue: a participant cannot be marked as silent if a face is detected.\nfalse: face-detection is ignored.',
        },
        reactivate_after: {
            type: 'number',
            default: 2,
            description:
                'The minimum number of seconds of moving video that is required before marking the participant as no longer silent. Values 1-5.',
        },
    },
    required: [],
    title: 'Config',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.config === undefined && (missing0 = 'config')) {
                validate11.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'config')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.config !== undefined) {
                        let data0 = data.config;
                        const _errs3 = errors;
                        if (errors === _errs3) {
                            if (
                                data0 &&
                                typeof data0 == 'object' &&
                                !Array.isArray(data0)
                            ) {
                                const _errs5 = errors;
                                for (const key1 in data0) {
                                    if (
                                        !(
                                            key1 === 'enable' ||
                                            key1 === 'silent_after' ||
                                            key1 === 'require_no_faces' ||
                                            key1 === 'reactivate_after'
                                        )
                                    ) {
                                        delete data0[key1];
                                    }
                                }
                                if (_errs5 === errors) {
                                    if (data0.enable !== undefined) {
                                        const _errs6 = errors;
                                        if (typeof data0.enable !== 'boolean') {
                                            validate11.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/config/enable',
                                                    schemaPath:
                                                        '#/properties/Body/definitions/Config/properties/enable/type',
                                                    keyword: 'type',
                                                    params: {type: 'boolean'},
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid2 = _errs6 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                    if (valid2) {
                                        if (data0.silent_after !== undefined) {
                                            const _errs8 = errors;
                                            if (
                                                !(
                                                    typeof data0.silent_after ==
                                                    'number'
                                                )
                                            ) {
                                                validate11.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/config/silent_after',
                                                        schemaPath:
                                                            '#/properties/Body/definitions/Config/properties/silent_after/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'number',
                                                        },
                                                        message:
                                                            'must be number',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid2 = _errs8 === errors;
                                        } else {
                                            var valid2 = true;
                                        }
                                        if (valid2) {
                                            if (
                                                data0.require_no_faces !==
                                                undefined
                                            ) {
                                                const _errs10 = errors;
                                                if (
                                                    typeof data0.require_no_faces !==
                                                    'boolean'
                                                ) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/config/require_no_faces',
                                                            schemaPath:
                                                                '#/properties/Body/definitions/Config/properties/require_no_faces/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs10 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (
                                                    data0.reactivate_after !==
                                                    undefined
                                                ) {
                                                    const _errs12 = errors;
                                                    if (
                                                        !(
                                                            typeof data0.reactivate_after ==
                                                            'number'
                                                        )
                                                    ) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/config/reactivate_after',
                                                                schemaPath:
                                                                    '#/properties/Body/definitions/Config/properties/reactivate_after/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'number',
                                                                },
                                                                message:
                                                                    'must be number',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs12 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath + '/config',
                                        schemaPath:
                                            '#/properties/Body/definitions/Config/type',
                                        keyword: 'type',
                                        params: {type: 'object'},
                                        message: 'must be object',
                                    },
                                ];
                                return false;
                            }
                        }
                    }
                }
            }
        } else {
            validate11.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate14(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate11(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate11.errors
                : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate14.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate14.errors = vErrors;
    return errors === 0;
}
