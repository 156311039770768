import React from 'react';
import cx from 'classnames';

import type {
    ButtonVariant,
    ColorScheme,
    TextVariant,
} from '../../../types/variants';
import {FontVariant} from '../../../../design-tokens/constants';
import type {
    ButtonModifiersValues,
    ExtendedSizeModifier,
} from '../../../types/sizes';
import type {
    InteractiveElementHTMLTag,
    InteractiveElementProps,
} from '../InteractiveElement/InteractiveElement';
import {InteractiveElement} from '../InteractiveElement/InteractiveElement';
import {Spinner} from '../Spinner/Spinner';
import {Text} from '../Text/Text';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './Button.module.scss';

const ButtonChildrenWrapper: React.FC<
    React.PropsWithChildren<{
        colorScheme: ColorScheme;
        fontVariant?: FontVariant;
        textVariant?: TextVariant;
        useTextComponent?: boolean;
    }>
> = ({
    children,
    colorScheme,
    fontVariant = FontVariant.BodyBold,
    textVariant = 'inherit',
    useTextComponent,
}) => (
    <div className={styles.buttonChildren}>
        {useTextComponent ? (
            <Text
                className={styles.textComponent}
                colorScheme={colorScheme}
                fontVariant={fontVariant}
                variant={textVariant}
            >
                {children}
            </Text>
        ) : (
            <>{children}</>
        )}
    </div>
);

export const Button: React.FC<
    InteractiveElementProps & {
        colorScheme?: ColorScheme;
        enhancerEnd?: React.ReactChild;
        enhancerEndClassName?: string;
        enhancerStart?: React.ReactChild;
        enhancerStartClassName?: string;
        fontVariant?: FontVariant;
        hideChildren?: boolean;
        htmlTag?: InteractiveElementHTMLTag;
        isActive?: boolean;
        isDisabled?: boolean;
        isLoading?: boolean;
        modifier?: ButtonModifiersValues;
        noBorderRadius?: boolean;
        noEnhancerPadding?: boolean;
        noHover?: boolean;
        size?: ExtendedSizeModifier;
        textVariant?: TextVariant;
        useTextComponent?: boolean;
        variant?: ButtonVariant;
    }
> = ({
    children,
    className,
    fontVariant,
    textVariant,
    colorScheme,
    enhancerEnd,
    enhancerEndClassName,
    enhancerStart,
    enhancerStartClassName,
    hideChildren = false,
    htmlTag,
    isActive,
    isDisabled,
    isLoading,
    modifier,
    noBorderRadius = false,
    noEnhancerPadding,
    noHover = false,
    size = 'medium',
    variant = 'primary',
    useTextComponent,
    ...props
}) => {
    const shouldDisable = isDisabled || isLoading;

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => {
                const currentColorScheme = colorScheme ?? defaultColorScheme;

                return (
                    <InteractiveElement
                        className={cx(
                            styles.button,
                            styles[currentColorScheme],
                            styles[variant],
                            styles[size],
                            modifier && styles[modifier],
                            {
                                [styles.disabled]: shouldDisable,
                                [styles.noBorderRadius]: noBorderRadius,
                                [styles.noHover]: noHover || shouldDisable,
                            },
                            className,
                        )}
                        disabled={shouldDisable}
                        data-active={isActive && !shouldDisable}
                        htmlTag={htmlTag}
                        {...props}
                    >
                        <div
                            className={cx(styles.buttonContent, {
                                [styles.loading]: isLoading,
                                [styles.textComponent]:
                                    !useTextComponent && variant === 'text',
                            })}
                        >
                            {enhancerStart && (
                                <span
                                    className={cx(
                                        styles.enhancerStart,
                                        {'pr-0': noEnhancerPadding},
                                        enhancerStartClassName,
                                    )}
                                >
                                    {enhancerStart}
                                </span>
                            )}

                            {!hideChildren && (
                                <ButtonChildrenWrapper
                                    colorScheme={currentColorScheme}
                                    fontVariant={fontVariant}
                                    textVariant={textVariant}
                                    useTextComponent={useTextComponent}
                                >
                                    {children}
                                </ButtonChildrenWrapper>
                            )}
                            {enhancerEnd && (
                                <span
                                    className={cx(
                                        styles.enhancerEnd,
                                        {'pl-0': noEnhancerPadding},
                                        enhancerEndClassName,
                                    )}
                                >
                                    {enhancerEnd}
                                </span>
                            )}
                        </div>
                        {isLoading && (
                            <div className={styles.loadingIcon}>
                                <Spinner sizeModifier="compact" />
                            </div>
                        )}
                    </InteractiveElement>
                );
            }}
        </ThemeConsumer>
    );
};

export type ButtonProps = React.ComponentProps<typeof Button>;
