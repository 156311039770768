import {useCallback, useState} from 'react';

import {toggleFacingMode as toggle} from '../services/Media.service';

export const useToggleFaceMode = () => {
    const [isMirrored, setIsMirrored] = useState(false);

    const toggleFacingMode = useCallback(() => {
        toggle();
        setIsMirrored(val => !val);
    }, []);

    return [isMirrored, toggleFacingMode] as const;
};
