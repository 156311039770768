import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    TextHeading,
    FontVariant,
    Form,
    Input,
    Checkbox,
    Button,
} from '@pexip/components';

export const Transfer: React.FC<{
    destination: string;
    isHost: boolean;
    pin: string;
    setDestination: React.Dispatch<React.SetStateAction<string>>;
    setHost: React.Dispatch<React.SetStateAction<boolean>>;
    setPin: React.Dispatch<React.SetStateAction<string>>;
    transferParticipant: () => void;
}> = ({
    destination,
    isHost,
    pin,
    setDestination,
    setHost,
    setPin,
    transferParticipant,
}) => {
    const {t} = useTranslation();
    return (
        <>
            <TextHeading htmlTag="h5" fontVariant={FontVariant.H5}>
                <Trans t={t} i18nKey="transfer.transfer-participant">
                    Transfer participant
                </Trans>
            </TextHeading>
            <Form onSubmit={transferParticipant}>
                <Input
                    autoComplete="number"
                    className="mt-4 mb-4"
                    label={t('transfer.meeting-id', 'Meeting ID')}
                    labelModifier="inline"
                    name="meeting-id"
                    placeholder={t('transfer.placeholder', `e.g. 'meet.bob'`)}
                    type="text"
                    onValueChange={setDestination}
                    value={destination}
                />

                <Input
                    autoComplete="number"
                    className="mt-4 mb-4"
                    label={t('transfer.pin', 'Pin')}
                    labelModifier="inline"
                    name="pin"
                    placeholder={t('transfer.pin-placeholder', `e.g. '1234'`)}
                    type="password"
                    onValueChange={setPin}
                    value={pin}
                />

                <Checkbox
                    colorScheme="dark"
                    checked={isHost}
                    label={t(
                        'add-participant.can-control',
                        'Can control a meeting (Host)',
                    )}
                    name="is-host"
                    onChange={e => setHost(e.currentTarget.checked)}
                />

                <div className="mt-4">
                    <Button
                        modifier="fullWidth"
                        type="submit"
                        size="large"
                        isDisabled={!destination}
                    >
                        <Trans t={t} i18nKey="add-participant.transfer">
                            Transfer
                        </Trans>
                    </Button>
                </div>
            </Form>
        </>
    );
};
