import React, {useCallback} from 'react';

import type {RouteMatch} from '@pexip/router';
import {isDefined} from '@pexip/utils';
import {isInitialPermissionsNotGranted, isInitial} from '@pexip/media';
import {FullscreenSpinner, notificationToastSignal} from '@pexip/components';
import {
    RequestAccess,
    useDeviceStatusInfo,
    useFallbackDeviceNotifier,
} from '@pexip/media-components';

import {config} from '../config';
import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {mediaService, useStreamStatus} from '../services/Media.service';
import {Header} from '../viewModels/Header.viewModel';
import {PreflightLayout} from '../views/PreflightLayout/PreflightLayout.view';
import {ExpressFlow} from '../viewModels/ExpressFlow.viewModel';
import {BrandedInfoCard} from '../viewModels/BrandedInfoCard.viewModel';
import {mediaSignals} from '../signals/Media.signals';

export const Preflight: React.FC<{
    match: RouteMatch;
}> = ({
    match: {
        params: {id},
    },
}) => {
    const conferenceAlias = isDefined(id);
    const streamStatus = useStreamStatus();
    const initialPermStateNotGranted =
        isInitialPermissionsNotGranted(streamStatus);
    const deviceStatusInfo = useDeviceStatusInfo(streamStatus);

    const handleMessageFallback = useCallback((message: string) => {
        if (config.get('lastFallBackMsg') !== message) {
            notificationToastSignal.emit([{message}]);
            config.set({
                key: 'lastFallBackMsg',
                value: message,
                persist: true,
            });
        }
    }, []);

    useFallbackDeviceNotifier(
        mediaSignals.onMediaChanged.add,
        handleMessageFallback,
    );

    if (isInitial(streamStatus)) {
        return <FullscreenSpinner />;
    }

    return (
        <BrandedFullSizeWindow
            padding="none"
            background="image"
            flexDirection="column"
            justifyContent="flexStart"
        >
            <Header />

            <PreflightLayout
                startContent={<BrandedInfoCard />}
                endContent={
                    initialPermStateNotGranted ? (
                        <RequestAccess
                            requestAccess={() =>
                                mediaService.getUserMedia({
                                    audio: true,
                                    video: true,
                                })
                            }
                            title={deviceStatusInfo.title}
                            message={deviceStatusInfo.message}
                        />
                    ) : (
                        <ExpressFlow conferenceAlias={conferenceAlias} />
                    )
                }
            />
        </BrandedFullSizeWindow>
    );
};
