import React from 'react';

import type {RouteMatch} from '@pexip/router';
import {isDefined} from '@pexip/utils';
import {isInitialPermissionsNotGranted} from '@pexip/media';
import {RequestAccess, useDeviceStatusInfo} from '@pexip/media-components';

import {mediaService, useStreamStatus} from '../services/Media.service';
import {OneTimeDeviceSelection} from '../viewModels/OneTimeDeviceSelection.viewModel';
import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {Header} from '../viewModels/Header.viewModel';
import {PreflightLayout} from '../views/PreflightLayout/PreflightLayout.view';
import {BrandedInfoCard} from '../viewModels/BrandedInfoCard.viewModel';

export const OneTime: React.FC<{match: RouteMatch}> = ({
    match: {
        params: {id},
    },
}) => {
    const conferenceAlias = isDefined(id);

    const streamStatus = useStreamStatus();
    const initialPermState = isInitialPermissionsNotGranted(streamStatus);
    const deviceStatusInfo = useDeviceStatusInfo(streamStatus);

    return (
        <BrandedFullSizeWindow
            padding="none"
            background="image"
            flexDirection="column"
            justifyContent="flexStart"
        >
            <Header />
            <PreflightLayout
                startContent={<BrandedInfoCard />}
                endContent={
                    initialPermState ? (
                        <RequestAccess
                            requestAccess={() =>
                                mediaService.getUserMedia({
                                    audio: true,
                                    video: true,
                                })
                            }
                            title={deviceStatusInfo.title}
                            message={deviceStatusInfo.message}
                        />
                    ) : (
                        <OneTimeDeviceSelection
                            conferenceAlias={conferenceAlias}
                            streamStatus={streamStatus}
                        />
                    )
                }
            />
        </BrandedFullSizeWindow>
    );
};
