import React, {useCallback} from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {PreviewInput} from '@pexip/media';
import {isInitialPermissions} from '@pexip/media';
import {
    currentBrowserName,
    getLearnAboutRequestPermissionsUrl,
    DevicesSelection,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    useMediaInputs,
    useSelectAudioOutput,
    onDeviceSelectChange,
} from '@pexip/media-components';

import {
    mediaService,
    useDevices,
    useLocalMedia,
    useStreamStatus,
} from '../services/Media.service';
import {logger} from '../logger';
import {config, useConfig} from '../config';

import {OutputAudioTester} from './OutputAudioTester.viewModel';
import {TestYourMicrophone} from './TestYourMicrophone.viewModel';

export const PreflightDeviceSelection: React.FC = () => {
    const media = useLocalMedia();
    const {selectedAudioInput, selectedVideoInput} = useMediaInputs(media);
    const devices = useDevices();
    const [savedAudioOutput, setSavedAudioOutput] = useConfig('audioOutput');
    const {selectedAudioOutput} = useSelectAudioOutput(
        devices,
        savedAudioOutput,
    );
    const {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    } = useDeviceErrorMessageState();

    const learnHowToGrantAccessURL =
        getLearnAboutRequestPermissionsUrl(currentBrowserName);

    const onVideoInputChange = onDeviceSelectChange(
        setVideoInputError,
        (input: PreviewInput) => {
            const {videoInput: prevVideoInput} = mediaService.media;
            if (
                isInitialPermissions(mediaService.media.status) ||
                input?.deviceId === prevVideoInput?.deviceId
            ) {
                return;
            }
            const constraints = {
                audio: selectedAudioInput,
                video: {device: {exact: input}},
            };
            logger.debug(constraints, 'Save VideoInput');
            config.set({key: 'videoInput', value: input, persist: true});

            mediaService.getUserMedia(constraints);
        },
    );

    const onAudioInputChange = onDeviceSelectChange(
        setAudioInputError,
        (input: PreviewInput) => {
            const {audioInput: prevAudioInput} = mediaService.media;
            if (
                isInitialPermissions(mediaService.media.status) ||
                input?.deviceId === prevAudioInput?.deviceId
            ) {
                return;
            }
            const constraints = {
                audio: {device: {exact: input}},
                video: selectedVideoInput,
            };
            logger.debug(constraints, 'Save AudioInput');
            config.set({key: 'audioInput', value: input, persist: true});

            mediaService.getUserMedia(constraints);
        },
    );

    const onAudioOutputChange = useCallback(
        (device: MediaDeviceInfoLike) => {
            setSavedAudioOutput(device, true);
        },
        [setSavedAudioOutput],
    );

    const streamStatus = useStreamStatus();
    useDeviceErrorMessage(setAudioInputError, setVideoInputError, streamStatus);

    return (
        <DevicesSelection
            devices={devices}
            videoInputError={videoInputError}
            audioInputError={audioInputError}
            videoInput={selectedVideoInput}
            audioOutput={selectedAudioOutput}
            audioInput={selectedAudioInput}
            onVideoInputChange={onVideoInputChange}
            onAudioInputChange={onAudioInputChange}
            onAudioOutputChange={onAudioOutputChange}
            learnHowToGrantAccessURL={learnHowToGrantAccessURL}
            inputAudioTester={
                <TestYourMicrophone
                    devices={devices}
                    onAudioInputChange={onAudioInputChange}
                    onAudioOutputChange={onAudioOutputChange}
                    selectedAudioInput={selectedAudioInput}
                    selectedAudioOutput={selectedAudioOutput}
                />
            }
            outputAudioTester={
                <OutputAudioTester
                    sinkId={selectedAudioOutput?.deviceId || ''}
                />
            }
            sizeModifier="small"
        />
    );
};
