import {useEffect, useState} from 'react';

import type {ConferenceStatus} from '@pexip/infinity';

import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {infinityService} from '../services/InfinityClient.service';

export const useConferenceStatus = () => {
    const [conferenceStatus, setConferenceStatus] = useState<
        ConferenceStatus | undefined
    >(infinityService.conferenceStatus);

    useEffect(
        () => infinityClientSignals.onConferenceStatus.add(setConferenceStatus),
        [],
    );

    return conferenceStatus;
};
