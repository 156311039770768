import {createPushToTalkMuteToastHook} from '@pexip/media-components';

import {logger} from '../logger';
import {pushToTalkSignal} from '../signals/Media.signals';

import {useAllGuestsMutedByHost} from './useAllGuestsMutedByHost';

const usePushToTalkMuteToast = createPushToTalkMuteToastHook(
    pushToTalkSignal,
    logger,
);

export const useMuteToasts = () => {
    useAllGuestsMutedByHost();
    usePushToTalkMuteToast();
};
