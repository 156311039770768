import type {ChatActivityType, ChatMessage} from '@pexip/media-components';
import {buildGroupedActivityMessage} from '@pexip/media-components';

const FIVE_MINUTES = 5 * 60000;

export const isSameActivityType = (
    previousMessage: ChatMessage,
    lastActivityMessages: ChatMessage,
) => previousMessage?.activityType === lastActivityMessages.activityType;

export const isActivityInTimeRange = (
    previousMessage: ChatMessage,
    lastActivityMessages: ChatMessage,
) => {
    if (previousMessage?.at && lastActivityMessages.at) {
        const lastMessageTime = lastActivityMessages.at;
        const timeRange = new Date(previousMessage.at.getTime() + FIVE_MINUTES);

        if (timeRange >= lastMessageTime) {
            return true;
        }
    }

    return false;
};

const shouldAppendActivityMessage = (
    lastMessage: ChatMessage,
    newMessage: ChatMessage,
) =>
    lastMessage?.type === 'activity' &&
    isSameActivityType(lastMessage, newMessage) &&
    isActivityInTimeRange(lastMessage, newMessage);

const removeLastMessage = (messages: ChatMessage[]) =>
    messages.slice(0, messages.length - 1);

export const getGroupActivityMessages =
    (activityType: ChatActivityType) =>
    (messages: ChatMessage[], newMessage: ChatMessage) => {
        const lastMessage = messages[messages.length - 1];

        if (
            lastMessage &&
            shouldAppendActivityMessage(lastMessage, newMessage)
        ) {
            return [
                ...removeLastMessage(messages),
                buildGroupedActivityMessage(
                    activityType,
                    lastMessage,
                    newMessage,
                ),
            ];
        }

        return [...messages, {...newMessage, activityType}];
    };
