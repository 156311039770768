export enum TestId {
    AudioMeterSettings = 'audio-meter-settings',
    BadgeCounterParticipantPanelButton = 'badge-counter-participant-panel-button',
    ButtonAdmitParticipant = 'button-admit-participant',
    ButtonAudioMeter = 'button-audio-meter',
    ButtonAudioMeterMuted = 'button-audio-meter-muted',
    ButtonBackgroundEffectsOff = 'button-background-effects-off',
    ButtonBackgroundEffectsOn = 'button-background-effects-on',
    ButtonChat = 'button-chat',
    ButtonChatSend = 'button-chat-send',
    ButtonCloseInvitationModal = 'button-invitation-modal',
    ButtonCollapseSelfview = 'button-collapse-selfview',
    ButtonCopyMeetingLink = 'button-copy-meeting-link',
    ButtonDeviceSettings = 'button-device-settings',
    ButtonDismiss = 'button-dismiss',
    ButtonDragFoldedSelfview = 'button-drag-folded-selfview',
    ButtonExpandSelfview = 'button-hide-selfview',
    ButtonFlipCamera = 'button-flip-camera',
    ButtonInviteMemberSearchRow = 'button-invite-member-search-row',
    ButtonKickOffParticipant = 'button-kick-off-participant',
    ButtonMeetingAudioInput = 'button-meeting-audioinput',
    ButtonMeetingAudioInputMuted = 'button-meeting-audioinput-muted',
    ButtonMeetingBackgroundEffectsOff = 'button-meeting-background-effects-off',
    ButtonMeetingBackgroundEffectsOn = 'button-meeting-background-effects-on',
    ButtonMeetingVideoInput = 'button-meeting-videoinput',
    ButtonMeetingVideoInputMuted = 'button-meeting-videoinput-muted',
    ButtonMuteParticipant = 'button-mute-participant',
    ButtonParticipants = 'button-participants',
    ButtonPeerMuted = 'button-peer-muted',
    ButtonPeerMutedCamera = 'button-peer-muted-camera',
    ButtonPeerUnmuteCamera = 'button-peer-unmute-camera',
    ButtonPeerPresenting = 'button-peer-presenting',
    ButtonQuality = 'button-quality',
    ButtonRemoveUserCancel = 'button-remove-user-cancel',
    ButtonRemoveUserConfirm = 'button-remove-user-confirm',
    ButtonRequestPermissions = 'button-request-permissions',
    ButtonSelfviewQuality = 'button-selfview-quality',
    ButtonSettingsCancel = 'button-settings-cancel',
    ButtonSettingsSave = 'button-settings-save',
    ButtonStartPresentation = 'button-start-presentation',
    ButtonStealPresentationCancel = 'button-steal-presentation-cancel',
    ButtonStealPresentationConfirm = 'button-steal-presentation-confirm',
    ButtonStopPresentation = 'button-stop-presentation',
    ButtonTestAudioInputAgain = 'button-test-audio-input-again',
    ButtonTestAudioInputStart = 'button-test-audio-input-start',
    ButtonTestAudioInputTryAgain = 'button-test-audio-input-try-again',
    ButtonToggleAudioSelfview = 'button-toggle-audio-in-folded-selfview',
    ButtonToggleVideoSelfview = 'button-toggle-video-in-folded-selfview',
    ButtonTryAgain = 'button-try-again',
    ButtonVideoInput = 'button-videoinput',
    ButtonVideoinputMuted = 'button-videoinput-muted',
    ChatActivityMessage = 'chat-activity-message',
    ChatEmptyActivityMessage = 'chat-empty-activity-message',
    ChatForm = 'chat-form',
    ChatInput = 'chat-input',
    ChatMessage = 'chat-message',
    ChatMessageLink = 'chat-message-link',
    ChatMessageName = 'chat-message-name',
    ChatMessages = 'chat-messages',
    ChatMessageText = 'chat-message-text',
    ChatPanel = 'chat-panel',
    ChatRemoveActivityMessage = 'chat-remove-activity-message',
    CheckEverythingWorksText = 'check-that-everything-works',
    ConfirmationModalCancelButton = 'confirmation-modal-cancel-button',
    ConfirmationModalConfirmButton = 'confirmation-modal-confirm-button',
    DevicesSelection = 'devices-selection',
    DevicesSelectionBlocked = 'devices-selection-blocked',
    FoldedSelfview = 'folded-selfview',
    HeaderLogo = 'header-logo',
    HeaderSubTitle = 'header-subtitle',
    HeaderTitle = 'header-title',
    InvitationSearchInput = 'invitation-search-input',
    JoinCallDetailsWrapper = 'join-call-details-wrapper',
    LinkMuteAllGuests = 'link-mute-all-guests',
    LinkOpenMeetingSettings = 'link-open-meeting-settings',
    LinkTestAudioInput = 'link-test-audio-input',
    LinkTestAudioOutput = 'link-test-audio-output',
    LinkUnmuteAllGuests = 'link-unmute-all-guests',
    MeetingVideoFull = 'meeting-video-large',
    MeetingVideoPip = 'meeting-video-pip',
    MeetingWrapper = 'meeting-wrapper',
    MissingAudioDeviceAlert = 'missing-audio-device-alert',
    MissingVideoDeviceAlert = 'missing-video-device-alert',
    ModalInvitation = 'modal-invitation',
    ModalLayoutChangeConfirmation = 'modal-layout-change-confirmation',
    ModalRemoveUser = 'modal-remove-user',
    ModalStealPresentation = 'modal-steal-presentation',
    NetworkConnectedState = 'network-connected-state',
    NetworkReconnectedState = 'network-reconnected-state',
    NetworkReconnectingState = 'network-reconnecting-state',
    NotificationToolTipAudioCloseButton = 'notification-tooltip-audio-close-button',
    NotificationToolTipAudioProblem = 'notification-tooltip-audio-problem',
    NotificationToolTipDeviceError = 'notification-tooltip-device-error',
    NotificationTooltipPreviouslyBlocked = 'notification-tooltip-previously-blocked',
    NotificationTooltipSelfviewHidden = 'notification-tooltip-selfview-hidden',
    NotificationToolTipVideoCloseButton = 'notification-tooltip-video-close-button',
    NotificationToolTipVideoProblem = 'notification-tooltip-video-problem',
    ParticipantPanel = 'participant-panel',
    ParticipantRow = 'participant-row',
    PermissionsRequestMessageWrapper = 'permissions-request-message-wrapper',
    PillNewChatMessage = 'new-chat-message-pill',
    PresentationFull = 'presentation-large',
    PresentationPip = 'presentation-pip',
    SearchRow = 'search-row',
    SelectAudioInput = 'select-audio-input',
    SelectAudioInputErrorText = 'select-audio-input-error-text',
    SelectAudioOutput = 'select-audio-output',
    SelectTestAudioInput = 'select-test-audio-input',
    SelectTestAudioInputPlayback = 'select-test-audio-input-playback',
    SelectTestAudioInputSpeakerPlayback = 'select-test-audio-input-speaker-playback',
    SelectVideoInput = 'select-video-input',
    SelectVideoInputErrorText = 'select-video-input-error-text',
    SettingsVideoSelfview = 'settings-video-selfview',
    SpeakingIndicator = 'speaking-indicator',
    TestAudioInputIndicator = 'test-audio-input-indicator',
    TextLinkLearnDismissRequestPermissions = 'text-link-dismiss-learn-request-permissions',
    TextLinkLearnRequestPermissions = 'text-link-learn-request-permissions',
    TextLinkTryAgainRequestPermissions = 'text-link-try-again-request-permissions',
    TextLinkUnblockPermissionsVideo = 'text-link-unblock-permissions-video',
    ToastYouAreTemporarilyUnmuted = 'toast-you-are-temporarily-unmuted',
    ToggleNoiseSuppression = 'toggle-noise-suppression',
    TooltipDeniedDevice = 'tooltip-denied-device',
    VideoMeeting = 'video-meeting',
    VideoPresentation = 'video-presentation',
    VideoSelfview = 'video-selfview',
    VideoSelfviewWrapper = 'video-selfview-wrapper',
    VideoStatus = 'video-status',
    VideoStatusBody = 'video-status-body',
    VideoStatusTitle = 'video-status-title',
}
