import type {
    References,
    EventHandler,
    OnNegotiationNeededHandler,
    OnTrackEventHandler,
    OnRemoteStreamsEventHandler,
    ExtendedRTCPeerConnection,
    PeerConnection,
    MainPeerConnection,
    PCSignals,
    PeerConnectionOptions,
    MainPeerConnectionOptions,
    OnIceCandidateHandler,
    PresentationPeerConnectionOptions,
    PresentationPeerConnection,
    CallTransceivers,
    Transceivers,
    OnTranceiverChangeHandler,
} from './types';
import {RecoveryTimeout} from './types';
import {
    isPreso,
    getMediaLines,
    hasICECandidates,
    SdpTransformManager,
} from './sdpManager';
import type {PexipMediaLine} from './sdpManager';
import type {TransceiverMediaType, TransceiverContentType} from './constants';
import {TRANSCEIVER_CONTENT_TYPES, TRANSCEIVER_MEDIA_TYPES} from './constants';
import {
    createGetRefs,
    createRefsLog,
    getPeerConnectionStates,
    handleOnTrack,
    logger,
    logReferences,
    withSignals,
} from './utils';

type SessionDescriptionInitProp = undefined | RTCSessionDescriptionInit;

/**
 * Wrap RTCPeerConnection with polyfill the old APIs and simplifies the common logics
 *
 * @param options - Configuration for the peer connection
 * @param peerConnection - Inject your own RTCPeerConnection, can be used for
 * testing
 */
export function createPeerConnection(
    options: PeerConnectionOptions = {},
    peerConnection?: ExtendedRTCPeerConnection,
): PeerConnection {
    const props = {
        bandwidth: options.bandwidth ?? 0,
        contentSlides: Boolean(options.transceiversDirection?.preso?.video),
        offerOptions: options.offerOptions,
        answerOptions: options.answerOptions,
        references: {
            module: 'PeerConnection',
            createdAt: new Date().toISOString(),
        } as References,
        packetizationMode: Boolean(options.packetizationMode),
        // Only used when `restartIce` is not available
        iceRestartNeeded: false,
        negotiationNeeded: false,
        negotiationInProgress: false,
        pendingOffer: undefined as SessionDescriptionInitProp,
        transceiversDirection: options.transceiversDirection,
        vp9Disabled: options.vp9Disabled ?? false,
        allow1080p: options.allow1080p,
        allow4kPreso: options.allow4kPreso,
        currentRemoteDescription: undefined as SessionDescriptionInitProp,
    };

    const mainTranseivers = new Proxy({} as Transceivers, {
        set: (target, p: string, value) => {
            const didUpdate = Reflect.set(target, p, value);
            if (didUpdate) {
                eventHandlers.onTransceiverChange?.();
            }
            return didUpdate;
        },
    });
    // TODO if we need updates about preso too, add Proxy for that as well
    const transceivers: CallTransceivers = {main: mainTranseivers, preso: {}};

    let localMainStream: MediaStream | undefined;
    let localPresentationStream: MediaStream | undefined;
    let remoteStreams: MediaStream[] = [];
    let remoteStreamlessTracks: MediaStreamTrack[] = [];
    let remoteMediaLines: PexipMediaLine[] = [];

    const timers: {
        iceConnectionState?: number;
        connectionState?: number;
    } = {};

    const clearTimer = (timerKey: keyof typeof timers, onDone?: () => void) => {
        if (timers[timerKey]) {
            clearTimeout(timers[timerKey]);
            timers[timerKey] = undefined;
            onDone?.();
        }
    };

    const eventHandlers: {
        negotiationNeeded?: OnNegotiationNeededHandler;
        iceConnectionStateChange?: EventHandler;
        signalingStateChange?: EventHandler;
        onTrack?: OnTrackEventHandler;
        onRemoteStreams?: OnRemoteStreamsEventHandler;
        onIceCandidate?: OnIceCandidateHandler;
        onTransceiverChange?: OnTranceiverChangeHandler;
    } = {};

    const peer =
        peerConnection ??
        (new RTCPeerConnection(options.rtcConfig) as ExtendedRTCPeerConnection);

    // HACK: Fix for odd Firefox behavior, see: https://github.com/feross/simple-peer/pull/783
    if (typeof peer.peerIdentity === 'object') {
        peer.peerIdentity.catch(err => {
            logger.error(err);
            peer.close();
        });
    }

    const log = createRefsLog(() => ({
        ...logReferences(props.references),
        ...getPeerConnectionStates(peer),
        offerOptions: props.offerOptions,
        answerOptions: props.answerOptions,
    }));

    /**
     * Sync the track according to the provided `MediaStream`
     *
     * @param stream - the stream that will be used to associate the track
     */
    const syncSenderTrack =
        (stream: MediaStream, contentType: TransceiverContentType = 'main') =>
        /**
         *
         * @param sender - the established sender if available
         * @param track - the track to sync
         */
        async (sender?: RTCRtpSender, track?: MediaStreamTrack) => {
            const localStream =
                contentType === 'main'
                    ? localMainStream
                    : localPresentationStream;

            log.debug('syncSenderTrack', {
                contentType,
                stream,
                sender,
                track,
                localStream,
            });
            if (track) {
                if (sender) {
                    log.debug('replaceTrack', {
                        stream,
                        sender,
                        track,
                        localStream,
                    });
                    sender.setStreams?.(stream);
                    return await sender.replaceTrack(track);
                }
                log.debug('addTransceiver/addTrack', {
                    stream,
                    sender,
                    track,
                    localStream,
                });
                const transceiverType = track.kind as TransceiverMediaType;
                transceivers[contentType][transceiverType] =
                    peer.addTransceiver(track, {
                        streams: [stream],
                        direction:
                            props.transceiversDirection?.[contentType]?.[
                                transceiverType
                            ],
                    });
                return;
            }
            if (sender) {
                log.debug('removeTrack', {
                    stream,
                    sender,
                    track,
                    localStream,
                });
                if (localStream && sender.track) {
                    localStream.removeTrack(sender.track);
                }
                return peer.removeTrack(sender);
            }
            log.warn('[syncSenderTrack] both `sender` and `track` are falsy', {
                stream,
                sender,
                track,
                localStream,
            });
        };

    /**
     * Set the local `MediaStream`, and replace the tracks when there is already
     * one in the process
     *
     * @param mediaStream - the media stream to add
     */
    const setLocalStream = async (
        mediaStream: MediaStream,
        contentType: TransceiverContentType = 'main',
    ) => {
        log.info('call setLocalStream', {mediaStream, contentType});
        if (
            mediaStream.getTracks().some(track => track.readyState === 'live')
        ) {
            if (contentType === 'main') {
                localMainStream = mediaStream;
            }
            if (contentType === 'preso') {
                localPresentationStream = mediaStream;
            }
            const syncTrack = syncSenderTrack(mediaStream, contentType);

            const [newAudioTrack] = mediaStream.getAudioTracks();
            const [newVideoTrack] = mediaStream.getVideoTracks();

            // Order matters for mcu, it prefers audio lines in sdp first
            // Ref: https://github.com/pexip/mcu/issues/28176

            // Let's make sure we are not creating unwanted transceivers
            if (props.transceiversDirection?.[contentType]?.audio) {
                log.info('Sync Audio Track', {contentType});
                await syncTrack(
                    transceivers[contentType].audio?.sender,
                    newAudioTrack,
                );
            }
            log.info('Sync Video Track', {contentType});
            await syncTrack(
                transceivers[contentType].video?.sender,
                newVideoTrack,
            );
        } else {
            log.warn(
                '[setLocalStream] No track or no track is live from the stream',
                {stream: mediaStream, contentType},
            );
        }
    };

    const isAllowedToNegotiate = () => {
        if (props.negotiationInProgress) {
            log.info('Negotiation in progress. Ignoring negotiation request');
            return false;
        }
        return true;
    };

    /**
     * Trigger manual negotiationNeeded event
     */
    const negotiate = () => {
        if (!isAllowedToNegotiate()) {
            return;
        }
        props.negotiationInProgress = true;
        if (eventHandlers.negotiationNeeded) {
            if (peer.signalingState === 'stable') {
                log.info('[negotiate] trigger negotiationneeded manually');
                eventHandlers.negotiationNeeded();
                props.negotiationNeeded = false;
            } else {
                props.negotiationNeeded = true;
                log.info('[negotiate] defer manual negotiation trigger');
            }
        } else {
            log.warn(
                '[negotiate] try to negotiate but no negotiationneeded handler',
            );
        }
    };

    /**
     * Trigger iceRestart manual mode
     */
    const fallbackRestartIce = () => {
        log.info('[restartIce fallback] trigger negotiationneeded manually');
        props.offerOptions = {...props.offerOptions, iceRestart: true};
        props.iceRestartNeeded = false;
        negotiate();
    };

    /**
     * The WebRTC API's `RTCPeerConnection` interface offers the `restartIce()`
     * method to allow a web application to easily request that ICE candidate
     * gathering be redone on both ends of the connection. This simplifies the
     * process by allowing the same method to be used by either the caller or
     * the receiver to trigger an ICE restart.
     *
     * This function includes the logic to check if the latest API is available,
     * and use the fallback, `RTCPeerConnection['createOffer']` with
     * `iceRestart` set to `true`.
     */
    const restartIce = () => {
        if (!eventHandlers.onIceCandidate) {
            // Don't support Vanilla ICE Restart
            return;
        }
        clearTimer('connectionState', () => {
            log.debug('clears connectionStateTimer due to iceRestart');
        });
        if (peer.restartIce !== undefined) {
            log.info('restartIce');
            peer.restartIce();
        } else {
            // Fallback restartIce to a manual mode
            if (peer.signalingState === 'stable') {
                log.info('restartIce fallback');
                fallbackRestartIce();
            } else {
                // Wait for `signalingState` changes to `stable` to restartIce
                props.iceRestartNeeded = true;
                log.info('restartIce fallback deferred');
            }
        }
    };

    const isRemoteMainTrack =
        (transceivers: RTCRtpTransceiver[] = peer.getTransceivers()) =>
        (track: MediaStreamTrack) =>
            transceivers.some(transceiver => {
                const media = remoteMediaLines.find(
                    media =>
                        !isPreso(media) &&
                        String(media.mid) === transceiver.mid,
                );
                if (!media) {
                    return false;
                }
                return (
                    transceiver.receiver.track.kind === track.kind &&
                    transceiver.receiver.track.id === track.id
                );
            });

    // Handle iceConnectionState change event
    peer.oniceconnectionstatechange = event => {
        log.info('oniceconnectionstatechange handler', {event});
        clearTimer('iceConnectionState', () => {
            log.debug('clears the restart ice timer', {event});
        });
        if (peer.iceConnectionState === 'failed') {
            // FIXME: Should update configuration before restarting ICE
            log.debug('restarts ice because it went to failed', {event});
            restartIce();
        } else if (peer.iceConnectionState === 'disconnected') {
            log.debug('scheduls ice restart because it went to disconnected', {
                event,
            });
            timers.iceConnectionState = window.setTimeout(
                restartIce,
                RecoveryTimeout.IceConnectionState,
            );
        }

        if (eventHandlers.iceConnectionStateChange) {
            eventHandlers.iceConnectionStateChange(event);
        }
    };

    // Handle signalingState change event
    peer.onsignalingstatechange = event => {
        log.info('onsignalingstatechange handler', {event});

        if (peer.signalingState === 'stable') {
            if (props.iceRestartNeeded && eventHandlers.onIceCandidate) {
                fallbackRestartIce();
            } else if (props.negotiationNeeded) {
                negotiate();
            }
        }

        if (eventHandlers.signalingStateChange) {
            eventHandlers.signalingStateChange(event);
        }
    };

    // Handler onIceCandidate
    peer.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (!eventHandlers.onIceCandidate) {
            return;
        }
        log.info('onicecandidate handler', {event});

        // Once an agent has received an end-of-candidates
        // indication, it MUST also ignore any newly received candidates
        // https://tools.ietf.org/html/draft-ietf-mmusic-trickle-ice-02#section-9.3
        eventHandlers.onIceCandidate(event);
    };

    // Handle negotiationneeded event
    peer.onnegotiationneeded = () => {
        log.info('onnegotiationneeded handler');
        if (!isAllowedToNegotiate()) {
            return;
        }
        props.negotiationInProgress = true;
        if (eventHandlers.negotiationNeeded) {
            eventHandlers.negotiationNeeded();
        }
    };

    // Handle ontrack event
    peer.ontrack = event => {
        const [newRemoteStreams, newRemoteStreamlessTracks] = handleOnTrack(
            event,
            remoteStreams,
        );
        log.info('ontrack', {
            event,
            newRemoteStreams,
            newRemoteStreamlessTracks,
            remoteStreams,
            remoteStreamlessTracks,
            remoteMediaLines,
        });
        if (newRemoteStreams.length) {
            // Replace remoteStreams with the new one
            remoteStreams = [...remoteStreams, ...newRemoteStreams];
            log.debug('new remote streams', {remoteStreams});
            eventHandlers.onRemoteStreams?.(remoteStreams);
        }
        if (newRemoteStreamlessTracks.length) {
            remoteStreamlessTracks = [
                ...remoteStreamlessTracks,
                ...newRemoteStreamlessTracks,
            ];
            // Collect all the streamless tracks and wrap them into
            // a MediaStream when all the known remote tracks are done
            if (
                [
                    ...remoteStreams.flatMap(stream => stream.getTracks()),
                    ...remoteStreamlessTracks,
                ].length === remoteMediaLines.length
            ) {
                remoteStreams = [
                    ...remoteStreams,
                    new MediaStream(remoteStreamlessTracks),
                ];
                log.debug('new remote streams', {remoteStreams});
                eventHandlers.onRemoteStreams?.(remoteStreams);
            }
        }

        eventHandlers.onTrack?.(event);
    };

    return {
        get iceGatheringState() {
            return peer.iceGatheringState;
        },

        get iceConnectionState() {
            return peer.iceConnectionState;
        },

        get signalingState() {
            return peer.signalingState;
        },

        get connectionState() {
            return peer.connectionState;
        },

        get localStream() {
            return localMainStream;
        },

        get remoteStreams() {
            return remoteStreams;
        },

        get senders() {
            return peer.getSenders();
        },

        get receivers() {
            return peer.getReceivers();
        },

        getTransceiver(
            type: TransceiverMediaType,
            contentType: TransceiverContentType = 'main',
        ) {
            return transceivers[contentType][type];
        },

        get hasICECandidates() {
            return hasICECandidates(
                peer.pendingLocalDescription?.sdp ??
                    peer.currentLocalDescription?.sdp,
            );
        },

        get currentLocalDescription() {
            if (peer.currentLocalDescription !== undefined) {
                return peer.currentLocalDescription;
            }
            return peer.localDescription;
        },

        get pendingLocalDescription() {
            if (peer.pendingLocalDescription !== undefined) {
                return peer.pendingLocalDescription || undefined;
            }
            return peer.signalingState === 'stable' ||
                peer.localDescription === null
                ? undefined
                : peer.localDescription;
        },

        get currentRemoteDescription() {
            if (peer.currentRemoteDescription !== undefined) {
                return peer.currentRemoteDescription;
            }
            return peer.remoteDescription;
        },

        get pendingRemoteDescription() {
            if (peer.pendingRemoteDescription !== undefined) {
                return peer.pendingRemoteDescription;
            }
            return peer.signalingState === 'stable'
                ? null
                : peer.remoteDescription;
        },

        get bandwidth() {
            return props.bandwidth;
        },

        get references() {
            return props.references;
        },

        get offerOptions() {
            return props.offerOptions;
        },

        set offerOptions(newOptions) {
            props.offerOptions = {...props.offerOptions, ...newOptions};
        },

        get answerOptions() {
            return props.answerOptions;
        },

        set answerOptions(newOptions) {
            log.info('set answerOptions', {
                newOptions,
                answerOptions: props.answerOptions,
            });
            props.answerOptions = {...props.answerOptions, ...newOptions};
        },

        set bandwidth(bandwidth) {
            log.info('set bandwidth', {
                newBandwidth: bandwidth,
                bandwidth: props.bandwidth,
            });
            if (props.bandwidth !== bandwidth) {
                props.bandwidth = bandwidth;
                restartIce();
            }
        },

        get contentSlides() {
            return props.contentSlides;
        },

        set contentSlides(contentSlides) {
            log.info('set contentSlides', {
                newContentSlides: contentSlides,
                contentSlides: props.contentSlides,
            });
            props.contentSlides = contentSlides;
        },

        set negotiationNeeded(needed: boolean) {
            if (needed) {
                negotiate();
            }
        },

        getStats: async selector => await peer.getStats(selector),

        setLocalStream,

        setReference(key, value) {
            log.info('call setReference', {
                references: props.references,
                key,
                value,
            });
            props.references[key] = value;

            // It is used in e2e to check if streams that are going out
            // are properly muted, should we find a better way?
            if (value === 'MainPeerConnection') {
                window.pexDebug = {
                    ...window.pexDebug,
                    mainRtcPeer: peer,
                };
            }
        },

        createDataChannel: (label, dataChannelDict) => {
            log.info('call createDataChannel', {label, dataChannelDict});
            return peer.createDataChannel(label, dataChannelDict);
        },

        createOffer: async offerOptions => {
            log.info('call createOffer', {param: offerOptions});

            TRANSCEIVER_CONTENT_TYPES.forEach(contentType =>
                TRANSCEIVER_MEDIA_TYPES.forEach(mediaType => {
                    const direction =
                        props.transceiversDirection?.[contentType]?.[mediaType];
                    if (!transceivers[contentType][mediaType] && direction) {
                        transceivers[contentType][mediaType] =
                            peer.addTransceiver(mediaType, {
                                direction,
                            });
                    }
                }),
            );

            const sdp = await peer.createOffer({
                ...props.offerOptions,
                ...offerOptions,
            });
            const offer = new SdpTransformManager(sdp, {
                contentSlides: props.contentSlides,
                videoAS: props.bandwidth,
                packetizationMode: props.packetizationMode,
                vp9Disabled: props.vp9Disabled,
                allow1080p: props.allow1080p,
                allow4kPreso: props.allow4kPreso,
            }).getSdp();

            log.debug('SDP mangled', {sdp, offer});
            if (!eventHandlers.onIceCandidate) {
                // No trickle ICE, setLocalDescription immediately
                await peer.setLocalDescription(offer);
                log.info('setLocalDescription with offer success', {
                    sdp,
                    offer,
                });
            } else {
                // Stall setting local offer to wait for answer to gather enough
                // information from signalling server
                props.pendingOffer = offer;
            }
            return offer;
        },

        createAnswer: async answerOptions => {
            log.info('call createAnswer', {param: answerOptions});

            const syncStreamTrack = async (
                stream: MediaStream | undefined,
                contentType: TransceiverContentType,
            ) => {
                log.info('Sync tracks with stream on answer', {
                    stream,
                    contentType,
                });
                if (!stream) {
                    return;
                }
                return await Promise.all(
                    stream.getTracks().map(track => {
                        const kind = track.kind as TransceiverMediaType;
                        transceivers[contentType][kind]?.sender.setStreams?.(
                            stream,
                        );
                        return transceivers[contentType][
                            kind
                        ]?.sender.replaceTrack(track);
                    }),
                );
            };

            await syncStreamTrack(localMainStream, 'main');
            await syncStreamTrack(localPresentationStream, 'preso');

            const sdp = await peer.createAnswer({
                ...props.answerOptions,
                ...answerOptions,
            });
            const sdpManager = new SdpTransformManager(sdp, {
                videoAS: props.bandwidth,
                contentSlides: props.contentSlides,
                allow4kPreso: props.allow4kPreso,
            });

            // Polyfill to browsers without RTCRtpSender['setStreams']
            if (!('setStreams' in RTCRtpSender.prototype)) {
                const associateStream = (
                    stream: MediaStream | undefined,
                    contentType: TransceiverContentType,
                ) => {
                    if (!stream) {
                        return;
                    }
                    stream.getTracks().forEach(track => {
                        const kind = track.kind as TransceiverMediaType;
                        const mid = transceivers[contentType][kind]?.mid;
                        if (mid) {
                            sdpManager.addMsidToMline(mid, stream.id);
                        }
                    });
                };
                associateStream(localMainStream, 'main');
                associateStream(localPresentationStream, 'preso');
            }

            const answer = sdpManager.getSdp();
            log.debug('SDP mangled', {sdp, answer});
            await peer.setLocalDescription(answer);
            log.info('setLocalDescription with answer success', {sdp, answer});
            return answer;
        },

        receiveAnswer: async answer => {
            remoteStreams = [];
            remoteStreamlessTracks = [];
            props.negotiationInProgress = false;
            remoteMediaLines = getMediaLines(answer.sdp);
            log.info('call receiveAnswer', {answer});
            if (eventHandlers.onIceCandidate && props.pendingOffer) {
                // Trigger ICE Trickling, @see createOffer
                await peer.setLocalDescription(props.pendingOffer);
                props.pendingOffer = undefined;
            }

            props.currentRemoteDescription = answer;
            return await peer.setRemoteDescription(
                new SdpTransformManager(answer, {
                    videoAS: props.bandwidth,
                    videoTIAS: props.bandwidth * 1000,
                    contentSlides: props.contentSlides,
                }).getSdp(),
            );
        },

        receiveOffer: async offer => {
            remoteStreams = [];
            remoteStreamlessTracks = [];
            remoteMediaLines = getMediaLines(offer.sdp);
            log.info('call receiveOffer', {offer});
            props.currentRemoteDescription = offer;
            await peer.setRemoteDescription(offer);
            const currentTransceivers = peer.getTransceivers();
            log.info('receiveOffer after setRemoteDescription', {
                currentTransceivers,
                transceivers,
            });
            if (currentTransceivers.find(trans => trans.mid === null)) {
                // Need to sync transceivers
                currentTransceivers
                    .filter(trans => trans.mid)
                    .forEach(trans => {
                        const kind = trans.receiver.track
                            .kind as TransceiverMediaType;
                        const contentType = remoteMediaLines.find(
                            media =>
                                isPreso(media) &&
                                String(media.mid) === trans.mid,
                        )
                            ? 'preso'
                            : 'main';
                        const currentTrans = transceivers[contentType][kind];
                        if (currentTrans?.mid === null) {
                            // Found stale transceiver
                            const direction =
                                props.transceiversDirection?.[contentType]?.[
                                    kind
                                ];
                            if (direction) {
                                trans.direction = direction;
                            }
                            currentTrans.stop();
                            transceivers[contentType][kind] = trans;
                            log.info('Sync transceiver', {
                                kind,
                                contentType,
                                oldTransceiver: currentTrans,
                                newTransceiver: trans,
                            });
                        }
                    });
            }
        },

        receiveIceCandidate: async candidate => {
            log.info('call receiveIceCandidate', {candidate});
            return await peer.addIceCandidate(candidate);
        },

        restartIce,

        getConfiguration: () => peer.getConfiguration(),
        setConfiguration:
            typeof peer.setConfiguration === 'function'
                ? rtcConfig => peer.setConfiguration(rtcConfig)
                : undefined,

        isRemoteMainTrack,
        isRemoteMainStream: (stream: MediaStream) => {
            const transceivers = peer.getTransceivers();
            const getIsRemoteMainTrack = isRemoteMainTrack(transceivers);
            log.info('isRemoteMainStream', {
                stream,
                tracks: stream.getTracks(),
                transceivers: transceivers.map(({mid, receiver}) => ({
                    mid,
                    receiverTrack: receiver.track,
                })),
                remoteMediaLines,
            });
            return stream.getTracks().some(getIsRemoteMainTrack);
        },

        close: () => {
            log.info('call close');
            clearTimer('connectionState');
            clearTimer('iceConnectionState');
            peer.close();
            localMainStream = undefined;
            localPresentationStream = undefined;
            remoteStreams = [];
        },

        // Event handlers
        set onConnectionStateChange(handler: EventHandler | undefined) {
            peer.onconnectionstatechange = event => {
                clearTimer('connectionState', () => {
                    log.debug('clears the connection state timer', {event});
                });
                if (peer.connectionState === 'disconnected') {
                    log.debug(
                        'waits for emitting connection state change because it is disconnected',
                        {event},
                    );
                    timers.connectionState = window.setTimeout(() => {
                        log.debug(
                            'emits connection state change after waiting',
                            {event},
                        );
                        handler?.(event);
                    }, RecoveryTimeout.ConnectionState);
                } else {
                    handler?.(event);
                }
            };
        },

        set onDataChannel(handler: RTCPeerConnection['ondatachannel']) {
            peer.ondatachannel = handler;
        },

        set onIceCandidate(handler: OnIceCandidateHandler) {
            eventHandlers.onIceCandidate = handler;
        },

        set onIceCandidateError(
            handler: RTCPeerConnection['onicecandidateerror'],
        ) {
            peer.onicecandidateerror = handler;
        },

        set onIceConnectionStateChange(handler: EventHandler | undefined) {
            eventHandlers.iceConnectionStateChange = handler;
        },

        set onIceGatheringStateChange(handler: EventHandler | undefined) {
            peer.onicegatheringstatechange = handler ?? null;
        },

        set onNegotiationNeeded(handler: OnNegotiationNeededHandler) {
            eventHandlers.negotiationNeeded = handler;
        },

        set onSignalingStateChange(handler: EventHandler | undefined) {
            eventHandlers.signalingStateChange = handler;
        },

        set onTransceiverChange(handler: OnTranceiverChangeHandler) {
            eventHandlers.onTransceiverChange = handler;
        },

        set onTrack(handler: OnTrackEventHandler | undefined) {
            eventHandlers.onTrack = handler;
        },

        set onRemoteStreams(handler: OnRemoteStreamsEventHandler | undefined) {
            eventHandlers.onRemoteStreams = handler;
        },
    };
}

/**
 * Logical layer of the Peer Connection for Call/Main, which connecting Signals
 * and `RTCPeerConnection` events, @see PCSignals
 *
 * @param signals - Provide the required signals for communication
 * @param options - Configuration for the the Peer Connection
 */
export function createMainPeerConnection(
    signals: PCSignals,
    options: MainPeerConnectionOptions = {},
): MainPeerConnection {
    const peer = createPeerConnection({
        transceiversDirection: {
            main: {
                audio: 'sendrecv',
                video: 'sendrecv',
            },
        },
        ...options,
    });
    peer.setReference('module', 'MainPeerConnection');
    const {onOfferRequired, ...restSignals} = signals;
    const log = createRefsLog(createGetRefs(peer));

    let subscriptions = [
        // Handler common signals
        ...withSignals(peer)(restSignals),

        onOfferRequired.add(stream => {
            log.info('handle onOfferRequired signal', {
                localStream: peer.localStream,
                newStream: stream,
            });
            // If peer has local MediaStream, `onnegotiationneeded` event will
            // be triggered when `RTCPeerConnection` is ready.
            // RTCPeerConnection['createOffer'] will be called accordingly by
            // the event.
            if (stream) {
                peer.setLocalStream(stream).catch(e => {
                    logger.error(e, 'setLocalStream failed');
                });
            } else {
                peer.negotiationNeeded = true;
            }
        }),
    ];

    const cleanup = () => {
        subscriptions = subscriptions.flatMap(unsubscribe => {
            unsubscribe();
            return [];
        });
    };

    return {
        get connectionState() {
            return peer.connectionState;
        },
        get iceConnectionState() {
            return peer.iceConnectionState;
        },
        get iceGatheringState() {
            return peer.iceGatheringState;
        },
        get signalingState() {
            return peer.signalingState;
        },
        get localStream() {
            return peer.localStream;
        },

        get remoteStreams() {
            return peer.remoteStreams;
        },

        get senders() {
            return peer.senders;
        },

        get receivers() {
            return peer.receivers;
        },

        get hasICECandidates() {
            return peer.hasICECandidates;
        },

        get bandwidth() {
            return peer.bandwidth;
        },

        get references() {
            return peer.references;
        },

        set bandwidth(bandwidth: number) {
            peer.bandwidth = bandwidth;
        },

        get offerOptions() {
            return peer.offerOptions;
        },

        get answerOptions() {
            return peer.answerOptions;
        },

        set offerOptions(newOptions) {
            peer.offerOptions = newOptions;
        },

        set answerOptions(newOptions) {
            peer.answerOptions = newOptions;
        },

        // Methods
        setLocalStream: peer.setLocalStream,

        setReference(key, value) {
            peer.setReference(key, value);
        },

        getStats: selector => peer.getStats(selector),

        getTransceiver: (type, contentType) =>
            peer.getTransceiver(type, contentType),

        createDataChannel: (label, dataChannelDict) =>
            peer.createDataChannel(label, dataChannelDict),

        restartIce: peer.restartIce,

        getConfiguration: peer.getConfiguration,
        setConfiguration: peer.setConfiguration,

        isRemoteMainStream: peer.isRemoteMainStream,
        isRemoteMainTrack: peer.isRemoteMainTrack,

        close: () => {
            cleanup();
            peer.close();
        },
    };
}

/**
 * Logical layer of the Peer Connection for Presentation, which connecting
 * Signals and `RTCPeerConnection` events, @see PCSignals
 *
 * @param signals - Provide the required signals for communication
 * @param options - Configuration for the the Peer Connection
 */
export function createPresentationPeerConnection(
    signals: PCSignals,
    options: PresentationPeerConnectionOptions = {},
): PresentationPeerConnection {
    if (!options.transceiversDirection?.preso?.video) {
        throw new Error(
            'set transceiversDirection.preso for presentation peer connection',
        );
    }
    const peer = createPeerConnection(options);
    const direction = options.transceiversDirection?.preso?.video;
    peer.setReference('module', `PresoPeerConnection:${direction}`);
    peer.setReference('presentationDirection', `${direction}`);
    peer.contentSlides = true;

    const props = {direction};

    const {onOfferRequired, ...restSignals} = signals;

    const log = createRefsLog(createGetRefs(peer));

    const setPresentationStream = (stream: MediaStream) =>
        peer.setLocalStream(stream, 'preso');

    let subscriptions = [
        // Handler common signals
        ...withSignals(peer)(restSignals),

        onOfferRequired.add(stream => {
            log.info('handle onOfferRequired signal', {stream});
            if (stream) {
                setPresentationStream(stream).catch(() => {
                    logger.error('setLocalStream failed');
                });
            } else {
                if (props.direction === 'sendonly') {
                    signals.onError.emit(
                        new Error('SendPresentationWithoutStreamError'),
                    );
                }
                peer.negotiationNeeded = true;
            }
        }),
    ];

    const cleanup = () => {
        subscriptions = subscriptions.flatMap(unsubscribe => {
            unsubscribe();
            return [];
        });
    };

    return {
        get connectionState() {
            return peer.connectionState;
        },
        get iceConnectionState() {
            return peer.iceConnectionState;
        },
        get iceGatheringState() {
            return peer.iceGatheringState;
        },
        get signalingState() {
            return peer.signalingState;
        },
        get localStream() {
            return peer.localStream;
        },

        get remoteStreams() {
            return peer.remoteStreams;
        },

        get senders() {
            return peer.senders;
        },

        get receivers() {
            return peer.receivers;
        },

        get hasICECandidates() {
            return peer.hasICECandidates;
        },

        get bandwidth() {
            return peer.bandwidth;
        },

        get references() {
            return peer.references;
        },

        set bandwidth(bandwidth: number) {
            peer.bandwidth = bandwidth;
        },

        get offerOptions() {
            return peer.offerOptions;
        },

        get answerOptions() {
            return peer.answerOptions;
        },

        set offerOptions(newOptions) {
            peer.offerOptions = newOptions;
        },

        set answerOptions(newOptions) {
            peer.answerOptions = newOptions;
        },

        // Methods
        setReference(key, value) {
            peer.setReference(key, value);
        },

        getStats: selector => peer.getStats(selector),

        getTransceiver: type => peer.getTransceiver(type),

        createDataChannel: (label, dataChannelDict) =>
            peer.createDataChannel(label, dataChannelDict),

        close: () => {
            cleanup();
            peer.close();
        },

        setLocalStream: setPresentationStream, // we only support presentation stream

        restartIce: peer.restartIce,

        getConfiguration: peer.getConfiguration,
        setConfiguration: peer.setConfiguration,

        isRemoteMainStream: peer.isRemoteMainStream,
        isRemoteMainTrack: peer.isRemoteMainTrack,
    };
}
