export * from 'psyseg-js-sdk';

export const urls = {
    psyseg: new URL('./sdk/wasm/psyseg.js', import.meta.url),
    psysegWasm: new URL('./sdk/wasm/psyseg.wasm', import.meta.url),
    psysegTflite: new URL('./sdk/model/tflite.js', import.meta.url),
    psysegTfliteWasm: new URL('./sdk/model/tflite.wasm', import.meta.url),
    psysegTfliteSimd: new URL('./sdk/model/tflite-simd.js', import.meta.url),
    psysegTfliteSimdWasm: new URL(
        './sdk/model/tflite-simd.wasm',
        import.meta.url,
    ),
    getSelfieBasePath: () =>
        new URL(
            './selfie_segmentation/selfie_segmentation.binarypb',
            import.meta.url,
        ).href.replace(/\/selfie_segmentation\.binarypb$/, ''),
    selfieJs:
        new URL(
            './selfie_segmentation/selfie_segmentation.js',
            import.meta.url,
        ),
    selfieBinarypb: new URL(
        './selfie_segmentation/selfie_segmentation.binarypb',
        import.meta.url,
    ),
    selfieTflite: new URL(
        './selfie_segmentation/selfie_segmentation.tflite',
        import.meta.url,
    ),
    selfieTfliteLanscape: new URL(
        './selfie_segmentation/selfie_segmentation_landscape.tflite',
        import.meta.url,
    ),
    selfieWasnBinJs: new URL(
        './selfie_segmentation/selfie_segmentation_solution_wasm_bin.js',
        import.meta.url,
    ),
    selfieWasnBin: new URL(
        './selfie_segmentation/selfie_segmentation_solution_wasm_bin.wasm',
        import.meta.url,
    ),
    selfieSIMWasnBinData: new URL(
        './selfie_segmentation/selfie_segmentation_solution_simd_wasm_bin.data',
        import.meta.url,
    ),
    selfieSIMDWasnBinJs: new URL(
        './selfie_segmentation/selfie_segmentation_solution_simd_wasm_bin.js',
        import.meta.url,
    ),
    selfieSIMDWasnBin: new URL(
        './selfie_segmentation/selfie_segmentation_solution_simd_wasm_bin.wasm',
        import.meta.url,
    ),
};
