import {useEffect, useState} from 'react';
import i18n from 'i18next';

import {logger} from '../logger';
import {brandingLngs} from '../i18n';
import {config} from '../config';
import {SHADES} from '../constants';

import type {Manifest} from '.';
import {getBrandingPath, DEFAULT, loadBranding} from '.';

const setBrandApplicationConfig = (
    applicationConfig: Manifest['applicationConfig'] = {},
) => {
    for (const [key, value] of Object.entries(applicationConfig)) {
        config.set({key: key as keyof Manifest['applicationConfig'], value});
    }
};

const setBrandDefaultUserConfig = (
    defaultUserConfig: Manifest['defaultUserConfig'] = {},
) => {
    for (const [k, value] of Object.entries(defaultUserConfig)) {
        const key = k as keyof Manifest['defaultUserConfig'];
        // Apply only for unchanged defaults
        if (config.isDefaultValue(key)) {
            config.set({key, value});
        }
    }
};

const setBrandTranslations = (translations: Manifest['translations'] = {}) => {
    for (const [lng, path] of Object.entries(translations)) {
        brandingLngs.set(lng, getBrandingPath(path));
        // FIXME: find better way to reload languages
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- I know what Im doing (maybe)
        delete i18n.services.backendConnector?.state?.[`${lng}|translation`];
    }

    i18n.reloadResources().catch(() => {
        logger.warn(`Can't reload translation`);
    });
};

const setBrandPalette = (colorPalette: Manifest['colorPalette'] = []) => {
    if (colorPalette.length !== SHADES.length) {
        logger.warn(
            'color palette has too many/few elements. check your branding manifest version.',
        );
    }
    colorPalette.forEach((color, idx) => {
        document.documentElement.style.setProperty(
            `--color-brand-${SHADES[idx]}`,
            color,
        );
    });
};

const setBrandBgColor = (backgroundColor: Manifest['backgroundColor']) => {
    if (backgroundColor) {
        document.documentElement.style.setProperty(
            '--full-size-background-secondary',
            backgroundColor,
        );
    }
};

export function useBrandingLoader() {
    const [brand, setBrand] = useState<Manifest>();
    useEffect(() => {
        let ignore = false;
        // TODO: How do we handle missing branding?
        loadBranding()
            .then(brand => {
                if (!ignore) {
                    setBrand(brand);
                    setBrandApplicationConfig(brand.applicationConfig);
                    setBrandDefaultUserConfig(brand.defaultUserConfig);
                    setBrandPalette(brand.colorPalette);
                    setBrandBgColor(brand.backgroundColor);
                    setBrandTranslations(brand.translations);
                }
            })
            .catch((error: unknown) => {
                if (!ignore) {
                    setBrand(DEFAULT);
                }
                logger.warn({error}, 'failed to load branding');
            });
        return () => {
            ignore = true;
        };
    }, []);
    return brand;
}
